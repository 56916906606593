<app-progress-status *ngIf="isProgressDataAvl"
[parentResponse]="progressDetails" [documentsCount]="documentCountList"></app-progress-status>


<div class="card gridCommonCss m-3 p-3 pt-0" *ngIf="isDataLoaded">
  <div class="filterSec col-12 pl-0 pr-0">
    <div class="icon-container">
      <div class="pl-0 pb-0">
      <img class="filter-icon" src="assets/icon/filter.png" alt="download"  style="font-size: 18px; vertical-align: middle;" (click)="op.toggle($event)" />
      </div>
      <div class="text-right pr-0 pb-0">
        <img class="download-icon" src="assets/icon/download.png" alt="download" (click)="downloadFile()" style="font-size: 18px; margin-right: 5px; vertical-align: middle;" />
        <app-col-config [columns]="columns" [mandatoryColumns]="mandatoryColumns"
        (finalColumns)="receiveData($event)" [ConfigKey]="ConfigKey"></app-col-config>
      </div>
  </div>                
</div>
  <!-- <p-dialog header="Configure Fields" [(visible)]="displayDialog" [modal]="true" [draggable]="true"
  [resizable]="false" [style]="{width: '350px'}" [baseZIndex]="1000" appendTo="body"> -->
 


<app-config-table *ngIf="isDataLoaded" [displayedData]="shipRequests" 
[columns]="finalColumns" [sharedViewFunction]="viewShipNow.bind(this)" 
[ConfigKey]="ConfigKey"></app-config-table>

<p-overlayPanel #op [showCloseIcon]="true" [dismissable]="true" [style]="{width: '50%'}">

  <div class="grid p-fluid">
    <div class="col-12 md:col-4">
      <div class="p-field">
        <label for="locationName">Location Name</label>
        <p-dropdown id="locationName" [options]="locationOption" [(ngModel)]="locationName"
          optionLabel="LocationName" placeholder="Select Location Name" filter="true"></p-dropdown>
      </div>
    </div> 
    <div class="col-12 md:col-4">
      <div class="p-inputgroup p-input-icon-right">
        <span class="delivery-title">Ship From Date</span>
        <p-calendar [(ngModel)]="shipFromDate" [iconDisplay]="'input'" [showIcon]="true" placeholder="Select Date"
          [dateFormat]="'dd/mm/yy'" inputId="icondisplay" />

      </div>
    </div>
    <div class="col-12 md:col-4">
      <div class="p-inputgroup p-input-icon-right">
        <span class="delivery-title">Ship To Date</span>
        <p-calendar [(ngModel)]="shipToDate" [iconDisplay]="'input'" [showIcon]="true" placeholder="Select Date"
          [dateFormat]="'dd/mm/yy'" inputId="icondisplay" />
      </div>
    </div>

  </div>
  <div class="grid p-fluid">

    <div class="col-12 md:col-4">
      <div class="p-field">
        <label for="costCenter">Connect To</label>
        <p-dropdown id="feederSystem" [options]="feederSystemOptions" [(ngModel)]="feederSystem" optionLabel="Name"
          placeholder="Select Feeder System" filter="true"></p-dropdown>
      </div>
    </div>

    <div class="button-container apply-div">
        <div class="footer-apply pr-2">
          <p-button  type="button" label="Apply" (click)="OrderFilter()"></p-button>
        </div>
        <div class="footer-reset">
          <p-button  type="button" label="Reset" (click)="ngOnInit()"></p-button>
        </div>
      </div>
    </div>
</p-overlayPanel>