import { Component } from '@angular/core';

@Component({
  selector: 'app-emailprocess',
  templateUrl: './emailprocess.component.html',
  styleUrls: ['./emailprocess.component.scss']
})
export class EmailprocessComponent {

}
