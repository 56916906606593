import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsolidationService } from '../consolidation.service';
import { ERPSHIPService } from '../erpship.service';
import { MessageService } from 'primeng/api';
import { WarningPopupComponent } from '../popup/warning-popup/warning-popup.component';
@Component({
  selector: 'app-consolidation',
  templateUrl: './consolidation.component.html',
  styleUrls: ['./consolidation.component.scss'],providers: [MessageService],
})
export class ConsolidationComponent {
  @ViewChild('dtshiprequest') dtshiprequest: any;
  @ViewChild(WarningPopupComponent) warningPopup: WarningPopupComponent;
  currentConsolidatedTable_Flag = true;
  currentOrdersTable_flag = true;
  isDataLoaded = false;
  consolidationData: any = {};
  newconsolidationflag = false
  statusvalues = [{ "name": "Open" }, { "name": "Ship" }, { "name": "Hold" }, { "name": "Void" }, { "name": "Consolidate" }]
  shipRequestSearch :any= {  };
  fromDate: Date = new Date();
  toDate: Date = new Date();
  status = 'Open';
  shipRequests: any = [];
  isShipRequestDataLoaded = false;
  selectedfields: any = [];


  

  selectedShipRequest: any[] = [];


  consolidationMaster: any[] = [];
  consolidationtemplate:any=[];
  selectedtemplate=''
  property_dialog=false
  shipScreenTemplate:any={}
  xfields:any=[];
  filterfields:any=[];
  openNewFielddialogflag=false
  consolidationtemplatefields:any=[];
  newField:any={}
  fieldnames:any=[];
  selectedfield=''
  consolidationtemplateRecord:any={}
  displayproperties=false
  currentselectedField:any={}
  Datatypes:any=["text","Number","Boolean"];
  UItypes:any=["Textbox","DropDown","CheckBox"];
  currentfield:any={}
  xcolumns = [{ "name": "FeederSystem" }, { "name": "DocumentNumber" }, { "name": "DocumentType" }, { "name": "ERP" }, { "name": "ShipTo_Company" },
  { "name": "ShipTo_Contact" }, { "name": "ShipTo_AddressLine1" }, { "name": "ShipTo_AddressLine2" }, { "name": "ShipTo_City" },
  { "name": "ShipTo_State" }, { "name": "ShipTo_ZipCode" }, { "name": "ShipTo_Country" }]

  constructor(private router: Router, private consolidate: ConsolidationService, public messageService: MessageService,private xfservice: ERPSHIPService) {
    // this.consolidate.processAuth().subscribe((data) => { }, error => {
    //   console.log("No token found")
    //   this.router.navigate(['']);
    // });
  }
  ngOnInit(): void {
    this.xfservice.getconsolidationdata().subscribe((data:any) => {
      this.consolidationData = data;
      this.xfservice.getConsolidationTemplate().subscribe((data:any)=>{
        this.consolidationtemplate=data;
        console.log(JSON.stringify(this.consolidationtemplate))
        this.xfields=[];
        
        
        this.xfservice.getScreenTemplateByName('consolidationscreen').subscribe((data):any=>{
          
          this.consolidationtemplateRecord=data[0].details
        //lert(JSON.stringify(this.consolidationtemplateRecord))
          this.consolidationtemplatefields=this.consolidationtemplateRecord['consolidationtemplatefields']
          //alert(JSON.stringify(this.consolidationtemplatefields))
          this.isDataLoaded = true;
        })
        this.currentselectedField={};

      
      
      })
     
      })
    
  }
  openNewRecord() {


  }
  getShipRequestData() {
    //if(this.shipRequestSearch.fromDate=='' || this.shipRequestSearch.toDate=='')
    //alert('Enter the Dates');
    if (this.status == '')
      alert('Enter Status');


    var fromdate = '';
    var todate = '';
    fromdate += this.fromDate.getFullYear();
    if (this.fromDate.getMonth() < 9)
      fromdate += '-0' + (this.fromDate.getMonth() + 1)
    else
      fromdate += '-' + this.fromDate.getMonth() + 1
    if (this.fromDate.getDate() < 10)
      fromdate += '-0' + (this.fromDate.getDate());
    else
      fromdate += '-' + this.fromDate.getDate();


    todate += this.toDate.getFullYear();
    if (this.toDate.getMonth() < 9)
      todate += '-0' + (this.toDate.getMonth() + 1)
    else
      todate += '-' + this.toDate.getMonth() + 1
    if (this.toDate.getDate() < 10)
      todate += '-0' + (this.toDate.getDate());
    else
      todate += '-' + this.toDate.getDate();
    this.shipRequestSearch.fromDate = fromdate;
    this.shipRequestSearch.toDate = todate;
    this.shipRequestSearch.status = this.status;


    this.consolidate.getShipRequestData(this.shipRequestSearch).subscribe((data: any) => {
      // data.forEach((item:any)=>this.shipRequests.push(item['fields']))


      this.shipRequests = data





      console.log(JSON.stringify(this.shipRequests))




      this.currentConsolidatedTable_Flag= true;
      this.currentOrdersTable_flag = false;

      this.isShipRequestDataLoaded = true


    })
  }
  applyFilterGlobal($event: any, stringVal: any): void {

    this.dtshiprequest.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  openTableProperties() { }
  autoConsolidate() {
if(this.selectedShipRequest.length == 0)
{
  this.warningPopup.showWarning('Please Select atleast 1 record');

}else if ( this.selectedfields.length ==0)
{
  this.warningPopup.showWarning('Please Select Consolidate Fileds');
}


else{
  this.consolidationMaster=[];
  const groupedData = this.selectedShipRequest.reduce((acc: any, curr: any) => {
    var key = "";
    this.selectedfields.forEach((item: any) => {
      key += curr[item] + '_';
    })

    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(curr);
    return acc;
  }, {});



  // Toupdate Consolidated ID to shiprequests
  var cid = 0;
  Object.keys(groupedData).forEach((record: any) => {
    var consolidationData: any = {};
    cid = cid + 1;
    consolidationData['TotalRecords'] = groupedData[record].length;
    consolidationData['Records'] = groupedData[record];
    consolidationData['Parameters'] = this.selectedfields.toString();
      
    consolidationData['ConsolidateId'] =cid;
    this.consolidationMaster.push(consolidationData);

    groupedData[record].forEach((srecord: any) => {
      srecord['ConsolidateId'] = cid;
    })

    this.currentConsolidatedTable_Flag= false;
    this.currentOrdersTable_flag = true;
  });
  

}
    





  }



  saveConsolidate(): void {
    this.xfservice.saveConsolidation(this.consolidationMaster).subscribe((data: any) => {
      this.consolidationMaster=[];
      this.shipRequests=[];
      this.newconsolidationflag= false;
      this.selectedShipRequest=[];
      this.selectedfields=[];
      this.selectedtemplate=''

      this.warningPopup.showWarning('Consolidation Data Successfully Saved.');

    })
  }
  loadFields(){
    this.consolidationtemplate.forEach((item:any)=>{
      if(item.name==this.selectedtemplate)
      this.selectedfields=item.fields
    })
  }
  addFields(){
   this.property_dialog=true
  }
  addNewField(){
    this.consolidationtemplatefields.push(this.newField)
    alert(JSON.stringify(this.consolidationtemplatefields))
    this.consolidationtemplateRecord['consolidationtemplatefields']=this.consolidationtemplatefields
    alert(JSON.stringify(this.consolidationtemplateRecord))
    this.xfservice.saveScreenTemplateByName('consolidationscreen',this.consolidationtemplateRecord).subscribe((data:any)=>
    {
      this.warningPopup.showWarning('New Field Successfully added to consolidationfilter.');
    })
    this.newField={};
    this.openNewFielddialogflag=false;

  }
  loadfieldProperties(data:any){
    let field=data.value;
    this.currentfield=this.consolidationtemplatefields.filter((item:any)=>
        item.fieldName==field
      )
    this.currentselectedField=this.currentfield[0];
    this.displayproperties=false

  }
  updatefieldProperties(record:any){
    // if (this.record.source) {
    //   var sourcedata = this.field.source;
    //   sourcedata = sourcedata.replace('[', '').replace(']', '')
    //   this.field.source = [];
    //   var sourcearray = sourcedata.split(',');
    //   sourcearray.forEach((it: any) => {
    //     this.field.source.push(JSON.parse(it));
    //   })
    // }
 this.xfservice.saveScreenTemplateByName('consolidationscreen',this.consolidationtemplateRecord).subscribe((data:any)=>{
  this.warningPopup.showWarning('Consolidation filter template Successfullyupdated');
  this.xfservice.getScreenTemplateByName('consolidationscreen').subscribe((data:any)=>{
    this.consolidationtemplateRecord=data[0].details
    //lert(JSON.stringify(this.consolidationtemplateRecord))
      this.consolidationtemplatefields=this.consolidationtemplateRecord['consolidationtemplatefields']

  })
 })

  }


}
