import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Inject }  from '@angular/core';
import { DOCUMENT } from '@angular/common'; 
@Component({
  selector: 'app-progress-status',
  templateUrl: './progress-status.component.html',
  styleUrls: ['./progress-status.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ProgressStatusComponent implements OnInit {
  cancelProgress: any;
  shippedProgress: any;
  openProgress: any;
  @ViewChild('totalrecords') ele1: ElementRef;
  @ViewChild('openrecords') ele2: ElementRef;
  @ViewChild('shippedrecords') ele3: ElementRef;
  @ViewChild('cancelledrecords') ele4: ElementRef;
  constructor(private router: Router,
    private myElement: ElementRef
  ) {
  }
  @Input() parentResponse!: any;
  @Input() documentsCount!:any;
  @Input() sharedViewFunction!: () => void;

  isButtonAvl:boolean;

  pageTitle: string = 'Ship Request';
  buttonTitle: string = 'Create Ship Request';
  total: number = 100; // Example total records
  active: number = 80; // Example active records
  inActive: number = 20;
  statusProgressBarClick() {}
  onTitleBtnClick() {
    this.router.navigate(['/home/shiprequestmodal']);
  }
  ngOnInit(): void {
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['parentResponse'] && changes['parentResponse'].currentValue) {
      this.pageTitle=this.parentResponse.pageTitle
      this.isButtonAvl=this.parentResponse.isButtonAvl
      if (this.isButtonAvl){
        this.buttonTitle=this.parentResponse.buttonTitle
      }
      this.cancelProgress = this.parentResponse.shipmentState.find(item => item.code=="CAN" );
      this.shippedProgress = this.parentResponse.shipmentState.find(item => item.code=="SPD" );
      this.openProgress = this.parentResponse.shipmentState.find(item => item.code=="OPN" );
    }
  }
  activeCheck(type:string): void{
    this.ele1.nativeElement.classList.remove('active')
    this.ele2.nativeElement.classList.remove('active')
    this.ele3.nativeElement.classList.remove('active')
    this.ele4.nativeElement.classList.remove('active')
    if(type ==='total'){
      this.ele1.nativeElement.classList.add('active')
    }
    else if(type ==='open'){
      this.ele2.nativeElement.classList.add('active')
    }
    else if(type ==='shipped'){
      this.ele3.nativeElement.classList.add('active')
    }
    else{
      this.ele4.nativeElement.classList.add('active')
    }
 
  }
  callParentView(): void {
    if (this.sharedViewFunction) {
      this.sharedViewFunction(); 
    }
  }
}
