export const GetDateTimeFromNumber = (inputDate: number) => {
    //Check input value
    if (typeof Number(inputDate) != 'number' || isNaN(Number(inputDate)))
        return inputDate;

    // Extract year, month, and day
    const year = Math.floor(inputDate / 10000);
    const month = Math.floor((inputDate % 10000) / 100) - 1; // Subtract 1 because months are 0-based in JavaScript
    const day = inputDate % 100;
    // Create a Date object
    const date = new Date(year, month, day);
    // Convert the Date object to a local datetime string
    const localizedDate = date.toLocaleString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    });
    return new Date(localizedDate);
}

export const GetStatusPayload = (selectedCarrier: string) => {
    let payload: any;
    if(selectedCarrier === 'ups'){
        payload = {
            "CarrierDetails": {
                "Carrier": "UPS",
                "UserId": "6ljUpEbuu1OlOk7ow932lsxXHISUET0WKjTn59GzQ5MRdEbA",
                "Password": "ioZmsfcbrzlWfGh7wGMhqHL6sY4EAaKzZObullipni0cEGJGChjFmGpkcdCWQynK",
                "Reference1": "xyz"
            },
            "Packages": [
                {
                    "TrackingNumber": "1Z30KH8T0331530032"
                }
            ]
        }
    }
    else if(selectedCarrier === 'fedex'){

        payload={
            "CarrierDetails": {
                "Carrier": "FEDEX",
                "UserId": "l74268712d9cbc46c6a246002dd196cd34",
                "Password": "2d6a0bbce81448ea9a24f669a01e1ec9",
                "Reference1": "xyz"
            },
            "Packages": [
                {
                    "TrackingNumber": "1234567890"
                }
            ]
        }
    }
    return payload;
  };

  export const GetStatusImageData = (statusCode: string) => {
    let statusImgData: any;
    if(statusCode?.toUpperCase() === '9E'){
        statusImgData = {
            src:"/assets/images/del.png",
            class:"none",
            alt:"Image Delivered",
            width:"200",
            height:"30",
            id: "imgDelivered"
        };
    }
    else if(statusCode?.toUpperCase() === 'OT'){
        statusImgData = {
            src:"/assets/images/rec.png",
            class:"none",
            alt:"Image Opened",
            width:"200",
            height:"30",
            id:"imgOpened"
        };
    }
    else if(statusCode?.toUpperCase() === 'IT'){
        statusImgData = {
            src:"/assets/images/rec.png",
            class:"none",
            alt:"Image for In Transict",
            width:"200",
            height:"30",
            id: "imgInTransit"
        };
    }
    else if(statusCode?.toUpperCase() === 'AR'){
        statusImgData = {
            src:"/assets/images/del.png",
            class:"none",
            alt:"Image Delivered",
            width:"200",
            height:"30",
            id: "imgDelivered"
        };
    }
    else if(statusCode?.toUpperCase() === 'DP'){
        statusImgData = {
            src:"/assets/images/ten.png",
            class:"none",
            alt:"Image Shipped",
            width:"200",
            height:"30",
            id:"imgShipped"
        };
    }
    else if(statusCode?.toUpperCase() === 'OR'){
        statusImgData = {
            src:"/assets/images/rec-2.png",
            class:"none",
            alt:"Image Cancelled",
            width:"200",
            height:"30",
            id:"imgCancel"
        };
    }
    else if(statusCode?.toUpperCase() === 'OD'){
        statusImgData = {
            src:"/assets/images/rec-2.png",
            class:"none",
            alt:"Image for On FedEx vehicle for delivery",
            width:"200",
            height:"30",
            id:"imgCancel"
        };
    }
    else if(statusCode?.toUpperCase() === 'RR'){
        statusImgData = {
            src:"/assets/images/rec-2.png",
            class:"none",
            alt:"Image for Delivery option requested",
            width:"200",
            height:"30",
            id:"imgCancel"
        };
    }
    else if(statusCode?.toUpperCase() === 'AO'){
        statusImgData = {
            src:"/assets/images/rec-2.png",
            class:"none",
            alt:"Image for Shipment arriving On-Time",
            width:"200",
            height:"30",
            id:"imgCancel"
        };
    }
    else if(statusCode?.toUpperCase() === 'PU'){
        statusImgData = {
            src:"/assets/images/rec-2.png",
            class:"none",
            alt:"Image for Picked up",
            width:"200",
            height:"30",
            id:"imgCancel"
        };
    }
    else if(statusCode?.toUpperCase() === 'XD'){
        statusImgData = {
            src:"/assets/images/rec-2.png",
            class:"none",
            alt:"Image Drop-Off",
            width:"200",
            height:"30",
            id:"imgCancel"
        };
    }
    else if(statusCode?.toUpperCase() === 'MP'){
        statusImgData = {
            src:"/assets/images/rec.png",
            class:"none",
            alt:"Image Opened",
            width:"200",
            height:"30",
        }
    }
    else if(statusCode?.toUpperCase() === 'HP'){
        statusImgData = {
            src:"/assets/images/rec.png",
            class:"none",
            alt:"Image for Ready for recipient pickup",
            width:"200",
            height:"30",
        }
    }
    else if(statusCode?.toUpperCase() === 'HA'){
        statusImgData = {
            src:"/assets/images/rec.png",
            class:"none",
            alt:"Hold at location request accepted",
            width:"200",
            height:"30",
        }
    }
    else if(statusCode?.toUpperCase() === 'OC'){
        statusImgData = {
            src:"/assets/images/rec.png",
            class:"none",
            alt:"Shipment information sent to FedEx",
            width:"200",
            height:"30",
        }
    }
    else {
        statusImgData = {
            src:"",
            class:"none",
            alt:"Unknow status",
            width:"200",
            height:"30",
        }
    }
    return statusImgData;
  };

export const formatTrackingData = (apiResponse: any) => {
    // Step 1: Create an object to group events by date
    const groupedData = {};
    apiResponse.forEach(event => {
      // Extract the date from the DateTime field, ignoring the time
      const date = event.DateTime ? new Date(event.DateTime).toDateString():'';
  
      // If the date doesn't exist in groupedData, initialize it
      if (!groupedData[date]) {
        groupedData[date] = [];
      }
  
      // Push the current event to the corresponding date's array
      groupedData[date].push({
        data: {
          dateTime: event.DateTime ? new Date(event.DateTime).toLocaleString():'',
          activity: event.status,
          location: event.Location
        }
      });
    });

    // Step 2: Sort each date's events by time in descending order
    for (let date in groupedData) {
        groupedData[date].sort((a, b) => new Date(b.data.dateTime).getTime() - new Date(a.data.dateTime).getTime());
    }
  
    // Step 3: Convert groupedData into the desired format
    const result = Object.keys(groupedData)
        .filter(date => date != '')
        .map(date => {
        return {
            data: { dateTime: date },
            children: groupedData[date]
        };
        });
  
    return result;
}
  
  