import { Component,ViewChild,EventEmitter, Output,Input, OnInit } from '@angular/core';
import { ERPSHIPService } from 'src/app/erpship.service';

@Component({
  selector: 'app-address-book-popup',
  templateUrl: './address-book-popup.component.html',
  styleUrl: './address-book-popup.component.scss'
})
export class AddressBookPopupComponent implements OnInit {
  
  @ViewChild('dt2') addrdt: any;
  // @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() display: boolean = false;
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  // @Output() onClose = new EventEmitter<any>();
  value: any; // This is the value to be returned
  showOverlay: boolean = false;

  AddressBook_dialog: boolean = false;
  addressbookcols: any[] = [];
  Master_addressBook: any[] = [];
  addressbookfilters: any[] = [];
  shipment!: any;
  currentAddressBlock: any;
  addressbookgrid: any[] = [];

  displayedArray: any[] = []; // Data to display in the table
  page: number = 0; // Current page index
  rows = 50;
  totalRecords = 0;


  constructor(
    private xfservice: ERPSHIPService
  ){}
  ngOnInit(){
    this.openAddressBook('ShipFrom')
  };
 
  applyFilterGlobal_Address($event: any, stringVal: any): void {
    this.addrdt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  };
  

  selectAddress(rowData: any) {
    this.onSelect.emit(rowData); // Emit the selected row data
    this.close(); 
  };

  close() {
    this.display = false;
  }
  closeDialog(){
    this.display = false;
  }
  

  // handleSidebarHide(){
  //   this.onClose.emit();
  // }

  openAddressBook(fromblock: any): void {
    this.currentAddressBlock = fromblock;
    this.addressbookcols = [];
    this.addressbookfilters = [];
    this.xfservice.getAddressBookTemplate().subscribe((data) => {
      this.addressbookgrid = data[0].fields;
      this.addressbookgrid.forEach((colfield) => {
        // console.log(colfield);
        let col = {
          fieldName: '',
          header: '',
          visible: true,
          editable: false,
          fieldType: '',
          datatype: '',
        };
        col.fieldName = colfield.fieldName;
        col.header = colfield.fieldName;
        col.editable = colfield.editable;
        col.fieldType = colfield.fieldType;
        col.datatype = colfield.datatype;
        this.addressbookfilters.push(colfield.fieldName)
        if (col.fieldName == 'id' || col.fieldName == 'IsActive') {
          col.visible = false;
        }
        if (col.visible) {
          if (col.header==="COMPANY"){
            col.header='COMPANY NAME'
          }else if(col.header=="CONTACT"){
              col.header='CONTACT NAME'
          }else if(col.header=="ADDRESS_LINE1"){
            col.header='ADDRESS LINE1'
          }else if(col.header=="ADDRESS_LINE2"){
            col.header='ADDRESS LINE2'
          }else if(col.header=="AddressLine3"){
            col.header='ADDRESS LINE3'
          }

          if (col.fieldName === "AddressLine3"){
            this.addressbookcols.splice(4, 0, col);
          }else 
          {this.addressbookcols.push(col);}
        }
        
      });
      //console.log('addressbook fields are: ' + JSON.stringify(this.addressbookcols));
      
      this.xfservice.getMasterDataByName('addressBook').subscribe((data) => {
        this.Master_addressBook = data;
        // console.log('Address:' + data)
        this.AddressBook_dialog = true;
        this.totalRecords = data.length;
        this.updateDisplayedData()
      });
    });
  };
    // Update the displayed data based on the current page
    updateDisplayedData() {
      const start = this.page * this.rows;
      const end = Math.min(this.Master_addressBook.length, start + this.rows);
      this.displayedArray = this.Master_addressBook.slice(start, end);
      this.totalRecords = this.Master_addressBook.length;

    }
  
    // Handle page change event from paginator
    onPageChange(page: number) {
      this.page = page;
      this.updateDisplayedData();
    }
}
