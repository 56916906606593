import countries,{currency} from "./commonData";

let signatureOption = [
    { label: "NO SIGNATURE REQUIRED", value: "NoSignatureRequired" },
    { label: "Indirect", value: "Indirect" },
    { label: "Direct", value: "Direct" },
    { label: "Adult", value: "NoSignatureRequired" },
    { label: "Service Default", value: "Service Default" },
  ],
  miscOptions = [
    { label: "LiftGate Pickup", value: "LiftGatePickup" },
    { label: "Limited Access Pickup", value: "LimitedAccessPickup" },
    { label: "LiftGate Delivery", value: "LiftGateDelivery" },
    { label: "Limited Access Delivery", value: "LimitedAccessDelivery" },
    { label: "Pharmacy Delivery", value: "PharmacyDelivery" },
    { label: "Call Before Delivery", value: "CallBeforeDelivery" },
  ],
  indicia = [
    { label: "Media Mail", value: "MediaMail" },
    { label: "Parcel Select", value: "ParcelSelect" },
    { label: "Presorted Bound Printed", value: "PresortedBoundPrinted" },
    { label: "Presorted Standard", value: "PresortedStandard" },
    { label: "Parcel Return", value: "ParcelReturn" },
  ],
  ancillaryEndorsement = [
    { label: "Address Correction", value: "AddressCorrection" },
    {
      label: "Carrier Leave If No Response",
      value: "CarrierLeaveIfNoResponse",
    },
    { label: "Change Service", value: "ChangeService" },
    { label: "Forwarding Service", value: "ForwardingService" },
    { label: "Return Service", value: "ReturnService" },
  ],
  lithiumBatteries = [{ label: "Lithium", value: "Lithium" }];

let fedExFields = [
  {
    name: "signatureOption",
    label: "Signature Option",
    type: "dropdown",
    cls: "",
    options: signatureOption || [],
  },
  {
    name: "miscOptions",
    label: "Misc Options",
    type: "dropdown",
    cls: "",
    options: miscOptions || [],
  },
  {
    name: "indicia",
    label: "Indicia",
    type: "dropdown",
    cls: "",
    options: indicia || [],
  },
  {
    name: "ancillaryEndorsement",
    label: "Ancillary Endorsement",
    type: "dropdown",
    cls: "",
    options: ancillaryEndorsement || [],
  },
  {
    name: "lithiumBatteries",
    label: "Lithium Batteries",
    type: "dropdown",
    cls: "",
    options: lithiumBatteries || [],
  },
  {
    name: "dryIce",
    label: "Dry Ice",
    type: "text",
    cls: "",
  },
  {
    name: "insuranceCharge",
    label: "Insurance Charge",
    type: "text",
    cls: "",
  },
  {
    name: "handlingCost",
    label: "Handling Cost",
    type: "text",
    cls: "",
  },
  {
    name: "freightCharge",
    label: "Freight Charge",
    type: "text",
    cls: "",
  },
  {
    name: "taxes",
    label: "Taxes or Misc Charges",
    type: "text",
    cls: "",
  },
  {
    name: "packingCost",
    label: "Packing Cost",
    type: "text",
    cls: "",
  },
  {
    name: "homeDelivery",
    label: "Home Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "homeDeliveryDate",
    label: "Home Delivery Date",
    type: "date",
    cls: "",
  },
  {
    name: "saturdayPickup",
    label: "Saturday Pickup",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "saturdayDelivery",
    label: "Saturday Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "residentialDelivery",
    label: "Residential Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "InsideDelivery",
    label: "Inside Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "insidePickup",
    label: "Inside Pickup",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "alcohol",
    label: "Alcohol",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "dryIceOnly",
    label: "Dry Ice Only",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "cod",
    label: "COD",
    type: "checkbox",
    cls: "check-container",
    action: true,
    actionCls: "icon-cls",
    formContainerRequirements :{
      containerNames:["COD","COD Address"],
      cls:"nestedContainer-cls"
    },
    popupTitle: "COD Information",
    popupCls: "codPopUp",
    popupFields:[[
      {
        label: "Amount",
        name: "Amount",
        type: "text",
        value: "",
        mandatory:true
      },{
        label: "Add Freight Charges",
        name: "AddFreightCharges",
        type: "text",
        value: "",
      },{
        label: "Transportation Charges",
        name: "TransportationCharges",
        type: "dropdown",
        value: "",
        options:[
          {label:"Cold SubCharge",value:"ColdSubCharge"},
          {label:"Net Charge",value:"NetCharge"},
          {label:"Net Frieght",value:"Net Frieght"},
          {label:"Total Customer Charge",value:"Total Customer Charge"}
        ]
      },{
        label: "Collection Type",
        name: "Collection Type",
        type: "dropdown",
        value: "",
        options:[
          {label:"Any",value:"Any"},
          {label:"Guarenteed Funds",value:"GuarenteedFunds"},
          {label:"Cash",value:"Cash"}
        ]
      }
    ],
      [
        {
          label: "Company Name",
          name: "CompanyName",
          type: "text",
          value: "",
        },{
          label: "Contact Name",
          name: "ContactName",
          type: "text",
          value: "",
          mandatory:true
        },{
          label: "Address Line1",
          name: "AddressLine1",
          type: "text",
          value: "",
          mandatory:true
        },{
          label: "Address Line2",
          name: "AddressLine2",
          type: "text",
          value: "",
        },{
          label: "City",
          name: "City",
          type: "text",
          value: "",
          cls: "equalTwo",
          mandatory:true
        },{
          label: "State",
          name: "State",
          type: "dropdown",
          value: "",
          cls: "equalTwo",
          mandatory:true,
          options:[],
        },{
          label: "Zip/Postal Code",
          name: "PostalCode",
          type: "text",
          value: "",
          cls: "equalTwo",
          mandatory:true
        },{
          label: "Currency",
          name: "Currency",
          type: "dropdown",
          value: "",
          options:currency,
          cls: "equalTwo",
        },{
          label: "Country",
          name: "Country",
          type: "dropdown",
          value: "",
          options:countries,
          mandatory:true
        },
        {
          label: "Phone No",
          name: "PhoneNo",
          type: "text",
          value: "",
        }
      ]
    ]
  },
  {
    name: "brokerSelect",
    label: "Broker Select",
    type: "checkbox",
    cls: "check-container",
    action: true,
    actionCls: "icon-cls",
    popupTitle: "Broker Information",
    popupCls: "brokerPopUp",
    itemCls: "brokerInfo-item-cls",
    popupFields: [[
      {
        label: "ID",
        name: "ID",
        type: "text",
        value: "",
        cls: "brokerInfo-item-cls",
      },
      {
        label: "State / ZIP Code",
        name: "State",
        type: "text",
        value: "",
        cls: "brokerInfo-doubleitem-cls",
      },
      {
        label: "",
        name: "ZIPCode",
        type: "text",
        value: "",
        cls: "brokerInfo-doubleitem-cls",
      },
      {
        label: " Tin Number",
        name: "TinNumber",
        type: "text",
        value: "",
        cls: "brokerInfo-item-cls",
      },

      {
        label: "Company",
        name: "Company",
        type: "text",
        value: "",
        cls: "brokerInfo-item-cls",
      },
      {
        label: "Country Code",
        name: "CountryCode",
        type: "text",
        value: "",
        cls: "brokerInfo-item-cls",
      },
      {
        label: " Email Address",
        name: "EmailAddress",
        type: "text",
        value: "",
        cls: "brokerInfo-item-cls",
      },

      {
        label: "Name",
        name: "Name",
        type: "text",
        value: "",
        cls: "brokerInfo-item-cls",
      },
      {
        label: "Phone Number",
        name: "PhoneNumber",
        type: "text",
        value: "",
        cls: "brokerInfo-item-cls",
      },
      {
        label: " Fax Number",
        name: "FaxNumber",
        type: "text",
        value: "",
        cls: "brokerInfo-item-cls",
      },

      {
        label: "Address Line 1",
        name: "AddressLine1",
        type: "text",
        value: "",
        cls: "brokerInfo-item-cls",
      },
      {
        label: "Account Number",
        name: "AccountNumber",
        type: "text",
        value: "",
        cls: "brokerInfo-item-cls",
      },
      {
        label: " Pager Number",
        name: "PagerNumber",
        type: "text",
        value: "",
        cls: "brokerInfo-item-cls",
      },
      {
        label: "Address Line 2",
        name: "AddressLine2",
        type: "text",
        value: "",
        cls: "brokerInfo-item-cls",
      },
      {
        label: "City",
        name: "City",
        type: "text",
        value: "",
        cls: "brokerInfo-item-cls",
      },
      {
        label: "Broker Type",
        name: "BrokerType",
        type: "dropdown",
        value: "",
        cls: "brokerInfo-item-cls",
        options: [
          {label:"Import",value:"import"},
          {label:"Export",value:"export"}
        ],
      },

      {
        label: "Clearence Brokerage",
        name: "ClearenceBrokerage",
        type: "dropdown",
        value: "",
        cls: "brokerInfo-item-cls",
        options: [
          {label:"Broker Inclusive",value:"BrokerInclusive"},
          {label:"Broker_Inclusive_Non_Resident",value:"Broker_Inclusive_Non_Resident"},
          {label:"Broker_Select",value:"Broker_Select"},
          {label:"Broker_Select_Non_Resident",value:"Broker_Select_Non_Resident"},
          {label:"Broker_Unassigned",value:"Broker_Unassigned"}
        ],
      },
      {
        label: " Tin Type",
        name: "TinType",
        type: "dropdown",
        value: "",
        cls: "brokerInfo-item-cls",
        options: [
          {label:"Personal_State",value:"Personal_State"},
          {label:"Personal_National",value:"Personal_National"},
          {label:"Business_Union",value:"Business_Union"},
          {label:"Business_State",value:"Business_State"}
        ],
      },{}
    ]],
  },
  {
    name: "holdAtLocation",
    label: "Hold at location",
    type: "checkbox",
    cls: "check-container",
    action: true,
    actionCls: "icon-cls",
    popupTitle: "Hold Location Information",
    popupCls: "holdlocPopUp",
    popupFields: [
      [{
        label: "Company Name",
        name: "companyName",
        type: "text",
        value: "",
      },
      {
        label: "State",
        type: "text",
        value: "",
        name: "state",
        cls: "equalTwo",
      },
      {
        label: "Country",
        type: "text",
        value: "",
        name: "country",
        cls: "equalTwo",
      },
      {
        label: "Contact Name",
        type: "text",
        value: "",
        name: "contactName",
      },
      {
        label: "City",
        type: "text",
        value: "",
        name: "city",
      },
      {
        label: "Address Line 1",
        type: "text",
        value: "",
        name: "addressLineOne",
      },
      {
        label: "ZIP Code",
        type: "text",
        value: "",
        name: "zipCode",
      },
      {
        label: "Address Line 2",
        type: "text",
        value: "",
        name: "addressLineTwo",
      },
      {
        label: "Phone No",
        type: "text",
        value: "",
        name: "phoneNo",
      }]
    ],
  },
  {
    name: "returnlabel",
    label: "Return label",
    type: "checkbox",
    cls: "check-container",
    action: true,
    actionCls: "icon-cls",
    popupTitle: "Return Address Information",
    popupCls: "returnLablePopUp",
    popupFields: [
      [{
        label: "Company Name",
        name: "companyName",
        type: "text",
        value: "",
      },
      {
        label: "City",
        type: "text",
        value: "",
        name: "city",
      },
      {
        label: "Contact Name",
        type: "text",
        value: "",
        name: "contactName",
      },
      {
        label: "State",
        type: "text",
        value: "",
        name: "state",
        cls: "equalTwo",
      },
      {
        label: "Country",
        type: "text",
        value: "",
        name: "country",
        cls: "equalTwo",
      },
      {
        label: "Address Line 1",
        type: "text",
        value: "",
        name: "addressLineOne",
      },
      {
        label: "ZIP Code",
        type: "text",
        value: "",
        name: "zipCode",
      },
      {
        label: "Address Line 2",
        type: "text",
        value: "",
        name: "addressLineTwo",
      },
      {
        label: "Phone No",
        type: "text",
        value: "",
        name: "phoneNo",
      },
      {
        label: "Address Line 3",
        type: "text",
        value: "",
        name: "addressLineThree",
      },
      {
        label: "Email",
        type: "text",
        value: "",
        name: "email",
      }]
    ],
  },
  {
    name: "emailNotifications",
    label: "Email Notifications",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "shipAlertEmail",
    label: "Ship Alert Email",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "thirdPartyConsignee",
    label: "Third Party Consignee",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "fedexOneRate",
    label: "Fedex One Rate",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "exceptionNotifications",
    label: "Exception Notifications",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "deliveryNotifications",
    label: "Delivery Notifications",
    type: "checkbox",
    cls: "check-container",
  },
];

export default fedExFields;