import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  // Create a BehaviorSubject to store the sidebar state (default is 'false', meaning closed)
  private sidebarState = new BehaviorSubject<boolean>(false);

  // Observable that can be subscribed to by components
  sidebarState$ = this.sidebarState.asObservable();

  constructor() {}

  // Method to update the sidebar state
  setSidebarState(isOpen: boolean): void {
    this.sidebarState.next(isOpen);
  }
}
