<div class="transform shipnowdev">
  <app-dashboard-header
    (triggerRefresh)="triggerRefresh()"
    (dataEmitter)="receiveData($event)"
  ></app-dashboard-header>
  <app-warning-popup></app-warning-popup>
  <app-confirm-popup></app-confirm-popup>
  <div class="p-2 bg-color" [@.disabled]="true">
    <div class="tab-columns-sec">
      <div class="col-config-icon">
        <app-col-config  class="p-button-rounded p-button-text tab-settings-icon-overlay settings-icon" [columns]="tabs" [mandatoryColumns]="mandatoryTabs"
      (finalColumns)="colConfigReceiveData($event)" [ConfigKey]="ConfigKey"></app-col-config>
      </div>
     
      <!-- Settings Dialog -->
       
      <div class="grid-dev-sec" *ngIf="showSettingsDialog">
        <p-overlayPanel #ot header="Configure Fields" [showCloseIcon]="true" [dismissable]="true" [style]="{ width: '279px' }" [baseZIndex]="1000">
          <span class="columnHeaderCss">Settings</span>
          <div style="margin-top: 10px;height: 250px !important;overflow: auto !important;">
            <!-- Individual Tab Visibility Checkboxes -->
            <div *ngFor="let tab of tempTabs; let i = index">
              <p-checkbox class="pb-3" [(ngModel)]="tab.visible" binary="true" [label]="tab.label" (onChange)="onTabVisibilityChange($event.checked, i)"></p-checkbox>
            </div>
          </div>
          <div class="button-container">
            <div class="overlay-cancel">
              <button type="button" class="btnShipColumnCancel" style="display: none;" (click)="removeFilter()">Cancel</button>
            </div>
            <div class="overlay-apply">
              <button type="button" class="btnShipColumnApply" (click)="applyFilter()">Apply</button>
            </div>
          </div>
        </p-overlayPanel>
      </div>
    </div>
  
    <!-- Mat Tab Group -->
    <mat-tab-group
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="drop($event)"
      class="example-drag-tabs"
      [(selectedIndex)]="selectedTabIndex"
      (selectedIndexChange)="onTabChange($event)"
    >
      <!-- Use ng-container to handle *ngIf condition -->
      <ng-container *ngFor="let tab of tabs; let i = index">
        <ng-container *ngIf="tab.content !== 'AutoPack' && tab.content !== 'AutoScan'">
          <mat-tab *ngIf="tab.visible">
            <ng-template mat-tab-label>
              <!-- Tab Label with Gear Icon -->
              <span cdkDrag cdkDragPreviewClass="background" class="mat-tab-label">
                {{ tab.label }}
              </span>
            </ng-template>
            <!-- Wrap the content with a div to apply the animation -->
       <!-- Wrap the content with a div to apply the animation -->
       <div class="dynamicHeight fade-in" style="overflow: hidden !important">
        <ng-container [ngSwitch]="tab.content">
          <app-ship-to-from
            *ngSwitchCase="'ship-to-from'"
            [refreshFlag]="refreshFlag"
            [shipment]="selectedOrder"
          ></app-ship-to-from>
          <app-routing-billing-detail
            *ngSwitchCase="'routing-billing-detail'"
            [refreshFlag]="refreshFlag"
            [shipment]="selectedOrder"
          ></app-routing-billing-detail>
          <!-- <p *ngSwitchCase="'additional-info'">
            This tab contains Additional Info. This is some dummy text for the
            Additional Info tab.
          </p> -->
          <app-additional-info
          *ngSwitchCase="'additional-info'"
          [refreshFlag]="refreshFlag"
          [shipment]="selectedOrder"
        ></app-additional-info>

          

          <app-ship-now-pack
            *ngSwitchCase="'ship-now-pack'"
            [refreshFlag]="refreshFlag"
            [shipment]="selectedOrder"
          ></app-ship-now-pack>
          <app-international-details
            [shipment]="selectedOrder"
            *ngSwitchCase="'international-details'"
          ></app-international-details>
          <app-documents
            [shipment]="selectedOrder"
            *ngSwitchCase="'documents'"
          ></app-documents>
          <app-charges
            [shipment]="selectedOrder"
            *ngSwitchCase="'charges'"
          ></app-charges>
          <p *ngSwitchCase="'dg'">
            This tab includes Dangerous Goods (DG) details. This is some dummy
            text for the DG tab.
          </p>
        </ng-container>
  
      </div>
  
          </mat-tab>
        </ng-container>
      </ng-container>
    </mat-tab-group>
  </div>
  
  <!-- tab-view.component.html -->

  <footer class="footer pt-1 pb-1">
      <ng-container>
        <div class="button-wrapper" *ngIf="isFooterEnabled">
          <div class="grid col-12 m-0 p-2 pr-3">
              <div class="col p-0"> 
                <button class="validate-button" style="display: none;">Save Profile</button>
                <button class="validate-button" style="display: none;">Shipment Confirmation</button>
                <button class="validate-button" style="display: none;">Hold</button>
              </div>
              <div class="col p-0 text-center">
                <button class="btn-default-css btngetshipments" (click)="onFreightShopClick()">
                  Get Shipping Rates
                </button>
              </div>
              <div class="col p-0">
                <button (click)="onSubmit()" class="btn-default-css btnshpnow">Ship Now</button>
              </div>

          </div>
        </div>
        <div class="button-wrapper" *ngIf="isShippedRecord">
          <div class="grid col-12 m-0 p-2 pr-3">
            <div class="col p-0" >
              <button (click)="ConfirmPopup()" class="btn-default-css btnshpnow">Void</button>
            </div>
          </div>
        </div>
      </ng-container>
  </footer>

  <!-- FreightShop  DIALOG START-->
  <p-dialog [(visible)]="FreightShop_dialog"  styleClass="popupCls" 
    [modal]="true" [style]="{width:'70%',height:'125vh !important'}">
    <ng-template pTemplate="header">
      <span class="font-semibold text-xl">
        Freight Shop
      </span>
    </ng-template>
    <p-table #fsdt [value]="FreightShopResult" scrollHeight="300px" [style]="{ width: '100%' }" [scrollable]="true"
      responsiveLayout="stack" selectionMode="single" [(selection)]="selectedFreightProduct" dataKey="serviceCode"
      [globalFilterFields]="[
        'Carrier',
        'serviceCode',
        'serviceName',
        'publishedFreight',
        'discountFreight'
      ]" [paginator]="false" dataKey="serviceCode" [metaKeySelection]="false" class = "freight-main-table">
      <!-------------------[rows]="10"---------------------->

      <!----------------------------------------->

      <ng-template pTemplate="caption">
        <div style="text-align: right;position: relative;">
          <i class="pi pi-search" style="margin:4px 4px 0 0;position:absolute;top:9px;right:5px"></i>

          <input type="text" pInputText size="50" placeholder="Global Filter"
             style="width:auto">
        </div>
      </ng-template>

      <!---------------------------------------------->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>
            <!-- <p-tableRadioButton></p-tableRadioButton> -->
          </th>
          <th></th>
          <th pSortableColumn="Carrier">
            Carrier<p-sortIcon field="Carrier"></p-sortIcon>
          </th>
          <th>Service</th>
          <th>Description</th>
          <th pSortableColumn="publishedFreight">
            Published Freight<p-sortIcon field="publishedFreight"></p-sortIcon>
          </th>
          <th pSortableColumn="discountFreight">
            Discount Freight<p-sortIcon field="discountFreight"></p-sortIcon>
          </th>
          <th pSortableColumn="expectedDeliveryDate">
            Estimateted Delivery Date<p-sortIcon
              field="expectedDeliveryDate"
            ></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-expanded="expanded">
        <tr>
          <td>
            <!-- <p-tableRadioButton></p-tableRadioButton> -->
            <i
              class="pi pi-check-circle"
              style="font-size: 2rem; cursor: pointer"
              (click)="assigneServiceFromFreightshop(rowData)"
            ></i>
            <!-- <p-radioButton (onClick)="assigneServiceFromFreightshop(rowData)"/> -->
            <!-- <p-tableRadioButton [value]="rowData"></p-tableRadioButton> -->
            <!-- <button pButton type="button" icon="pi pi-check" iconPos="left" class="p-button-rounded p-button-sm"
           (click)="assigneServiceFromFreightshop(rowData)"></button> -->
          </td>
          <td>
            <p-button
              type="button"
              pButton
              pRipple
              [pRowToggler]="rowData"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            ></p-button>
          </td>
          <td>{{ rowData["Carrier"] }}</td>
          <td>{{ rowData["serviceCode"] }}</td>
          <td>{{ rowData["serviceName"] }}</td>
          <td>
            <span class="output-text-green">
              {{ rowData["publishedFreight"] | currency : rowData["currency"] }}
            </span>
          </td>
          <td>
            <span class="output-text-red">
              {{ rowData["discountFreight"] | currency : rowData["currency"] }}
            </span>
          </td>
          <td>{{ rowData["expectedDeliveryDate"] }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-rowData>
        <tr>
          <td colspan="8">
            <div class="p-3">
              <p-table [value]="rowData.surCharges" dataKey="type" [scrollable]="true" responsiveLayout="stack" class = "freight-sub-table">
                <ng-template pTemplate="caption" style = "width:100%">
                 <div style = "width:100%;">Surcharge(s)</div> 
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th>Type</th>
                    <th>Description</th>
                    <th>Amount</th>
                  </tr>
                </ng-template>

                <ng-template
                  pTemplate="body"
                  let-surCharge
                  let-expandedPackage="expanded"
                >
                  <tr>
                    <td>{{ surCharge["type"] }}</td>
                    <td>{{ surCharge["description"] }}</td>
                    <td>
                      {{ surCharge["amount"] | currency : rowData["currency"] }}
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="6">There are SurCharge(s).</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- <button type="button" pButton pRipple label="Select" icon="pi pi-check" class="p-button-sm"
(click)="assigneServiceFromFreightshop('')"></button> -->
  </p-dialog>
  <!-- FreightShop DIALOG  END-->
</div>

<!-- Tabs End  -->
<!-- <p-confirmDialog #cd [visible]="voidPopUpVisible" 
(onHide)="voidPopUpVisible = false" >
    <ng-template pTemplate="headless" let-message>
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <span class="font-bold text-2xl block mb-2 mt-4">
                Cancle Order ?
            </span>
            <p class="mb-0">Are you sure. You want to cancel the Order ?</p>
            <div class="flex align-items-center gap-2 mt-4">
                <button 
                    pButton 
                    label="Yes" 
                    (click)="onVoidConfirm()" 
                    class="w-8rem">
                </button>
                <button 
                    pButton 
                    label="Cancel" 
                    (click)="voidPopUpVisible = false" 
                    class="p-button-outlined w-8rem ">
                </button>
            </div>
        </div>
    </ng-template>
</p-confirmDialog> -->