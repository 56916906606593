import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ERPSHIPService } from 'src/app/erpship.service';
import { GetAllDataService } from 'src/app/services/ship-now/get-all-data.service';

@Component({
  selector: 'app-ship-to',
  templateUrl: './ship-to.component.html',
  styleUrl: './ship-to.component.scss'
})
export class ShipToComponent implements OnInit, OnDestroy {

  showPopUp: boolean = false;
  private ngUnsubscribe = new Subject<void>();
  @Input() shipment : any = {
    ShipTo: {
      COMPANY: '',
      CONTACT: '',
      ADDRESS_LINE1: '',
      ADDRESS_LINE2: '',
      ADDRESS_LINE3: '',
      CITY: '',
      STATE: '',
      ZIPCODE: '',
      COUNTRY: '',
      PHONE: '',
      EMAIL: '',
    }
  };
  @Input() isDisabled = false;
  @Input() addressTypes: any;
  @Input() countries: any;

  constructor(
    private dataService: GetAllDataService,
    private service: ERPSHIPService
  ){}

  ngOnInit(): void {
   this.dataService.shipToFromData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data=>{
    this.shipment = data;
   })
  }

  saveShipTo(): void{
    this.service.updateShipToDetails(this.shipment.ShipTo).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res=>{
      this.showPopUp = false;
    })
  }

  cancelShipTo(): void {
    this.showPopUp = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
}

}
