<div class="routing-bill-sec">
  <form  style="min-height: 647px;" >
    <div class="tab-content-sec">
             <!-- Row for dropdowns and input fields -->
    <div class="row mb-3">  
      <!-- Ship Method Dropdown -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="shipMethod">Ship Method</label>
          <p-dropdown id="shipMethod" [options]="carrierData" optionLabel="CarrierName" value="CarrierName" [(ngModel)]="shipment.CarrierDetails.Carrier"  name='selectedCarrier' [filter]="true"
          filterBy="CarrierName" placeholder="Carrier" (onChange)="onCarrierChange($event)" ></p-dropdown>
        </div>
      </div>
  
      <!-- Service Type Dropdown -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="serviceType">Service Name</label>
          <p-dropdown id="serviceType" [options]="carrierServiceData"  optionLabel="ServiceName" optionValue="ServiceCode" [(ngModel)]="shipment.CarrierDetails.ServiceName" name='selectedCarrierService' [filter]="true"
          filterBy="ServiceName" placeholder="Service Type" ></p-dropdown>
        </div>
      </div>
  
      <!-- Carrier ID Input -->
      <div class="col-md-4">
        <div class="grid">
            <div class="col-9">
              <div class="form-group">
                <label for="carrierId">Carrier ID</label>
                <input  id="carrierId" type="text" pInputText class="form-control" />
              </div>
            </div>
            <div class="col-3 pr-0">
               <button type="button" class="btngetcarrier">Get Carrier</button>
            </div>
        </div>
      </div>
      <!-- Account Number Input -->
      <div class="col-md-2">
        <div class="form-group">
          <label for="accountNumber">Account No</label>
          <input id="accountNumber" type="text" pInputText [(ngModel)]="shipment.CarrierDetails.ShippingAccount" name="accountNumber" class="form-control" />
        </div>
      </div>
    </div>
  
    <!-- Button Row -->
  
  
    <!-- Row for remaining dropdowns and inputs -->
    <div class="row mb-3">
      <!-- Payment Type Dropdown -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="paymentType">Payment Type</label>
            <p-dropdown
            id="paymentType"
            [options]="paymentTypes"
            [(ngModel)]="shipment.CarrierDetails.PaymentType"
            optionLabel="label"  
            name="PaymentType"
            placeholder="Payment Type">
          </p-dropdown>
        </div>
      </div>
  
      <!-- Cost Center Dropdown -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="costCenter">Cost Center</label>
          <p-dropdown id="costCenter" [options]="costCenter" [(ngModel)]="shipment.CarrierDetails.CostCenter"
            optionLabel="description" optionValue="description" name="costcenter" placeholder="Cost Center">></p-dropdown>
        </div>
      </div>
  
      <!-- PO Number Input -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="poNo">PO No</label>
          <input id="poNo" type="text" name="poNo" pInputText class="form-control" [(ngModel)]="shipment.CarrierDetails.Reference1"/>
        </div>
      </div>
  
      <!-- Invoice Number Input -->
      <div class="col-md-3">
        <div class="form-group">
          <label for="invoiceNo">Invoice No</label>
          <input id="invoiceNo" type="text" pInputText name="invoiceNo" class="form-control" [(ngModel)]="shipment.CarrierDetails.Reference2"/>
        </div>
      </div>
    </div>
  
    <!-- Notes Textarea -->
    <div class="row">
      <div class="col-12" style="padding-left:12px !important;padding-right:12px !important;">
        <div class="form-group">
          <label for="notes">Special Instructions</label>
          <textarea id="notes" rows="1" pInputTextarea name="notes"  [(ngModel)]="shipment.CarrierDetails.Note" name="notes"class="form-control"></textarea>
        </div>
      </div>
    </div>
    </div>

  
    <!-- Additional options -->
     <div class="additional-tab-sec mt-3">
      <div class="titleCard">
        <div class="name">
          <!-- <h5>Additional options</h5> -->
          <!-- Add any additional form elements here -->
        </div>
        <p-sidebar [(visible)]="popupVisible" styleClass="popupCls" position="right" [baseZIndex]="10000" [modal]="false"
          [style]="{width:'50%',height:'125vh'}">
          <ng-template pTemplate="header">
            <span class="font-semibold text-xl">
              {{popupHeader}}
            </span>
          </ng-template>
          <ng-template pTemplate="content">
            <div style="display:flex;flex-direction: column; justify-content: space-between;">
              <div>
                <div *ngFor="let fieldArr of popupFields; index as i">
                  <div *ngIf="popupSubcontainer.containerNames[i]" class="subTitle"> {{popupSubcontainer.containerNames[i]}}
                  </div>
                  <div class="routeBill" [ngClass]="popupSubcontainer?.cls">
                    <div class="popupitem" [ngClass]="field?.cls" *ngFor="let field of fieldArr">
                      <label *ngIf="field.type !== 'checkbox'" for={{field.name}}>{{field.label}} <span *ngIf="field.mandatory"
                          style="color:red;font-weight: 700;">*</span></label>
                      <input *ngIf="field.type == 'text'" name="something" type={{field.type}} name={{field.name}} pInputText
                        [(ngModel)]="field.value" />
                      <p-dropdown [filter]="true" placeholder="Select" *ngIf="field.type == 'dropdown'" name={{field.name}}
                        [options]="field.options" (onChange)="onAddDetailsDropdownChange($event,field)"></p-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="footer">
            <div style="height:40px;" class="popup-footer">
              <button class="footerBtn cancelBtn" (click)="onPopUpHide($event)">Cancel</button>
              <button class="footerBtn saveBtn">Save</button>
            </div>
          </ng-template>
        </p-sidebar>
        <div class="routeBill">
          <div class="item details-item" [ngClass]="field.cls" *ngFor = "let field of selectedCarrierData">
            <label  *ngIf = "field.type !== 'checkbox'" for={{field.name}}>{{field.label}}</label>
            <input *ngIf = "field.type == 'text'" type = {{field.type}} name={{field.name}} pInputText [(ngModel)]="additionalOptionsFormData[field.name]" (change)="onAdditioanlOptInputChange($event,field)"/>
            <textarea  *ngIf = "field.type == 'textarea'" name="something" rows="5"cols="30" pInputTextarea [(ngModel)]="field.name"></textarea>
            <div class = "checkbox-action">
              <p-checkbox *ngIf = "field.type == 'checkbox'" name="something" [(ngModel)]="field.name" label={{field.label}} name={{field.name}} value={{field.label}} ></p-checkbox>
              <i [ngClass]="field.actionCls" *ngIf = "field.action" (click)="onEditClick($event,field)" class="fa fa-2x fa-pencil-square-o" style="font-size: 1.5rem"></i>
            </div>
            <p-calendar *ngIf = "field.type == 'date'" name="something" [(ngModel)]="field.name" [showIcon]="true" [showOnFocus]="false" inputId="buttondisplay" [minDate]="todayDate" ></p-calendar>
            <p-dropdown [filter]="true" placeholder="Select" *ngIf="field.type == 'dropdown'"  name="something" name={{field.name}} [options]="field.options" [(ngModel)]="selectedCostCenter" (onChange)="onAddDetailsDropdownChange($event,field)"></p-dropdown>
          </div>
        </div>
        <div *ngIf = "selectedCarrierData.length == 0" class="routeBill" ></div>
      </div>
     </div>

  </form>

</div>
