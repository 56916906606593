<p-dialog
header="Confirmation"
[(visible)]="isPopupVisible"
modal="modal"
[baseZIndex]="5000"
(onHide)="clearWarnings()"
[style]="{ 'width': '350px' }">

<div class="confirmbtn flex flex-column align-items-start">
  <!-- Confirm Modal Content -->
  <div class="flex justify-content-center confirmmsg font-medium m-3 mt-4">
    <i class="pi pi-info-circle text-lg mt-1 mr-2"></i>
    <div class="mt-0">{{ message }}</div>
  </div>
  <hr />
    <div class="flex justify-content-between w-full mt-0">
      <div>
      <p-button label="Yes" class="cust-button accept-button" (click)="accept()"></p-button>
    </div>
    <div>
      <p-button label="No" class="cust-button reject-button" (click)="reject()"></p-button>
    </div>
  </div>
</div>
</p-dialog>