<!-- not-found.component.html -->
<div class="container text-center mt-5">
    <div class="error-page">
      <h1 class="display-1">404</h1>
      <p class="lead">Oops! Page not found.</p>
      <img src="../../../assets/images/404.gif" alt="Error GIF" class="error-gif">
      <p class="mt-4">It seems that the page you are trying to access does not exist or has been moved.</p>
      <a class="btn btn-primary mt-3" routerLink="/">Go to Home</a>
    </div>
  </div>
  