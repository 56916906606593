import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss'],
})
export class AdditionalInfoComponent {
  @Input() shipment!: any;
  @Input() refreshFlag: boolean = false;
  referenceOptions: any[] = [];
  selectedReference: string = '';
  transportationModeOptions: any[] = [];
  selectedTransportationMode: string = '';
  moveCodeOptions: any[] = [];
  selectedMoveCode: string = '';
  shipmentMethodOptions: any[] = [];
  selectedShipmentMethod: string = '';
  reeferOptions: any[] = [];
  selectedReefer: string = '';
}
