// history.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  // Create a BehaviorSubject to hold the current shipment data
  private selectedHistorySubject = new BehaviorSubject<any>(null);
  
  // Expose the BehaviorSubject as an Observable for components to subscribe to
  selectedHistory$ = this.selectedHistorySubject.asObservable();

  setHistory(shipment: any) {
    this.selectedHistorySubject.next(shipment); // Emit new data to subscribers
  }
}
