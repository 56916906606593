import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERPSHIPService } from '../erpship.service';
import { MenuItem, MessageService, TreeNode } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import shippingStatus from './track-data/shippingStatus.json';
import { formatTrackingData, GetDateTimeFromNumber, GetStatusImageData, GetStatusPayload } from './track-data/common-data';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ColumnConfigService } from '../services/column-config-service';
import { filter } from 'rxjs';
interface ModuleConfig {
  [key: string]: string[];
}
@Component({
  selector: 'app-manifest',
  templateUrl: './track-shipment.component.html',
  styleUrls: ['./track-shipment.component.scss'], providers: [MessageService],
})
export class TrackShipmentComponent implements OnInit {

  @ViewChild('dt') dt: any;
  @ViewChild('ot') overlayPanel: OverlayPanel;


  template: any;
  first = 0;
  rows = 50;

  filters:any={};
  
  isDataLoaded: boolean = false;
  passingid: any = '';
  shipRequests: any[] = [];
  Org_shipRequests:any[]=[];
  selectedShipRequest: any[] = [];
  totalRecords = 0;
  tableProperties_dialog: boolean = false;

  xfields: any[] = [];
  xcolumns: any[] = [];
  Carrier='';
  shipDateFrom: Date= new Date();
  shipDateTo: Date=new Date();
  documentType='';
  conditions:any ={}
  filterdialogFlag=false;
  filterFields:any=[];

  //Added fields
  filterForm: FormGroup | undefined;
  locationOptions: any[] = []; // Populate with real data
  supplierLocationOptions: any[] = []; // Populate with real data
  carrierOptions: any[] = []; // Populate with real data
  regionOptions: any[] = []; // Populate with real data
  statusOptions: any[] = []; // Populate with real data
  paymentTypeOptions: any[] = []; // Populate with real data
  shipperOptions: any[] = []; // Populate with real data
  costCenterOptions: any[] = []; // Populate with real data
  countryOptions: any[] = []; // Populate with real data
  feederSystemOptions: any[] = []; // Populate with real data
  filteredColumns: any[] = []; // Start with all columns
  countVisibleTrue: number = 0;
  
  selectedLocation: any;
  selectedSupplierLocation: any;
  selectedCarrier: any;
  selectedRegion: any;
  selectedStatus: any;
  selectedPaymentType: any;
  selectedShipper: any;
  selectedCostCenter: any;
  selectedCountry: any;
  selectedFeederSystem: any;

  items: MenuItem[];
  Track: any;

  //status sidebar
  displaySidebar: boolean = false;
  shippingStatusData: any = [];
  moreItems: any = [];
  travelHistory: any;
  selectedShippingStatusData: any;
  selectedCarrierType: string;

lastDate: string | null = null;
useAlternateColor: boolean = false;
colorCache: { [key: string]: string } = {};

  columns = [
    {
      header: 'Request ID',
      fieldName: 'HeaderInfo.DocumentNumber',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Created Date',
      fieldName: 'HeaderInfo.CreatedDate',
      fieldType: 'Date',
      editable: false,
      visible: true,
      datatype: 'date',
    },
    {
      header: 'Ship Date',
      fieldName: 'HeaderInfo.ShipDate',
      fieldType: 'Date',
      editable: false,
      visible: true,
      datatype: 'date',
    },
    {
      header: 'Carrier Name',
      fieldName: 'CarrierDetails.Carrier',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Service Level',
      fieldName: 'CarrierDetails.ServiceName',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Status',
      fieldName: 'HeaderInfo.Status',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Tracking Number',
      fieldName: 'Packages[0].TrackingNumber',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    
    {
      header: 'Ship To Company',
      fieldName: 'ShipTo.COMPANY',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Contact',
      fieldName: 'ShipTo.CONTACT',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Connected To',
      fieldName: 'HeaderInfo.FeederSystem',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Order Type',
      fieldName: 'HeaderInfo.DocumentType',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    }, 
    {
      header: 'Ship To Address Line 1',
      fieldName: 'ShipTo.ADDRESS_LINE1',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To City',
      fieldName: 'ShipTo.CITY',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To State',
      fieldName: 'ShipTo.STATE',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Country',
      fieldName: 'ShipTo.COUNTRY',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Zipcode',
      fieldName: 'ShipTo.ZIPCODE',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Phone',
      fieldName: 'ShipTo.PHONE',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Email',
      fieldName: 'ShipTo.EMAIL',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Requester Name',
      fieldName: 'HeaderInfo.CreatedUser',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Shipment Type',
      fieldName: 'HeaderInfo.ShipmentType',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Priority Level',
      fieldName: 'HeaderInfo.PriorityLevel',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    }
  ];
  postColumns: any[] = [];
  mandatoryColumns = [
    'Request ID',
    'Created Date',
    'Ship Date',
    'Carrier Name',
    'Service Level',
    'Status',
    'Tracking Number',
    'Ship To Company',
    'Ship To Contact'
  ];
  backupColumns: any[] = [];
  searchQuery: string = '';
  userId: string;
  filterEventData: any;
  filterShipment: any;
  finalColumns: { header: string; fieldName: string; fieldType: string; editable: boolean; visible: boolean; datatype: string; }[];



  getTravelHistory() {
    return [
    {
      data:{dateTime: 'Wed 3/24/2016'},
      children: [
        {
          data:{ dateTime: 'Thu 3/24/2016 9:55 am', activity: 'Delivered', location: 'Idaho Falls, ID US' },
        },
        {
          data:{ dateTime: 'Thu 3/24/2016 9:04 am', activity: 'On FedEx vehicle for delivery', location: 'Idaho Falls, ID US' },
        },
        {
          data:{ dateTime: 'Thu 3/24/2016 9:03 am', activity: 'At local FedEx facility', location: 'Idaho Falls, ID US' },
        },
        { 
          data:{ dateTime: 'Thu 3/24/2016 6:28 am', activity: 'At destination sort facility', location: 'Salt Lake City, UT' },
        },
        {
          data:{ dateTime: 'Thu 3/24/2016 4:31 am', activity: 'Departed FedEx location', location: 'Memphis, TN' },
        },
        { 
          data:{ dateTime: 'Thu 3/24/2016 12:38 am', activity: 'Arrived at FedEx location', location: 'Memphis, TN' },
        }
      ]
    },
    {
      data:{dateTime: 'Wed 3/23/2016'},
      children:[
        { data:{dateTime: 'Wed 3/23/2016 11:05 pm', activity: 'Departed FedEx location', location: 'Newark, NJ' }},
        { data:{dateTime: 'Wed 3/23/2016 9:40 pm', activity: 'Arrived at FedEx location', location: 'Newark, NJ' }},
        { data:{dateTime: 'Wed 3/23/2016 9:08 pm', activity: 'Left FedEx origin facility', location: 'New York, NY' }},
        { data:{dateTime: 'Wed 3/23/2016 4:36 pm', activity: 'Picked up', location: 'New York, NY' }},
        { data:{dateTime: 'Wed 3/23/2016 4:28 pm', activity: 'Picked up', location: 'New York, NY' }},
        { data:{dateTime: 'Wed 3/23/2016 3:33 pm', activity: 'Shipment information sent to FedEx', location: ''}}
      ]
    }
    ];
  }
  popupVisible: boolean = false;
  popupFields : any = [];
  popupHeader : string = "";
  popupCls : string="";
  popupSubcontainer : any = {};
  files: TreeNode<any>[];
  ConfigKey='track'

  onPopUpHide(event:any){
    this.popupHeader = "";
    this.popupVisible = false;
    this.popupFields = [];
    this.popupCls = "";
  }

  OnStatusValueClick(event: any,fieldData: any){
    // console.log(event,fieldData);
    this.popupHeader = fieldData.popupTitle
    this.popupVisible = true;
    this.popupFields = fieldData.popupFields;
    this.popupCls = fieldData.popupCls;
    this.popupSubcontainer = fieldData.formContainerRequirements || {
      cls:"",
      containerNames:[]
    };
  }

  actions = [
    { label: 'Edit', icon: 'pi pi-pencil', command: () => this.edit() },
    { label: 'Delete', icon: 'pi pi-trash', command: () => this.delete() }
  ];
  checked: any;
  checkShippingStatus: boolean = true;

  edit() {
    console.log('Edit action');
  }

  delete() {
    console.log('Delete action');
  }
  save(arg0: string) {
    throw new Error('Method not implemented.');
  }
  onHide() {
    throw new Error('Method not implemented.');
  }
  RecipientPopup() {
    throw new Error('Method not implemented.');
  }
  CostCenterPopup() {
    throw new Error('Method not implemented.');
  }
  ShipperNamePopup() {
    throw new Error('Method not implemented.');
  }
  UserPopup() {
    throw new Error('Method not implemented.');
  }

  onAddDetailsDropdownChange(event: any,data:any){
    // console.log(data);
  };

  displayedArray: any[] = []; // Data to display in the table
  page: number = 0; // Current page index

  searchTrackData(action: string) {
    // Implement your search logic here
  }

  changeStatus(status) {
    this.statusOptions.forEach(item=>{
      item.active = '';
    })
    status.active = 'active'
      // Implement your change status logic here
  }

    constructor(private router: Router, public messageService: MessageService,  private cdr: ChangeDetectorRef,
    private columnConfigService: ColumnConfigService
    ,private xfservice: ERPSHIPService, private fb: FormBuilder, private cd: ChangeDetectorRef, ) {
    this.xfservice.processAuth().subscribe((data) => { }, error => {
      console.log("No token found")
      this.router.navigate(['']);
    });

    // this.shippingStatusData = shippingStatus;
    this.moreItems = [
      {
        label:'Copy',
        command: () => {
          this.Copy();
        }
      },
      {
        label:'View',
        command: () => {
          this.View();
        }
      },
    ]
    this.items = [
      {
          label: 'Copy',
          command: () => {
              this.Copy();
          }
      },
      {
          label: 'View',
          command: () => {
              this.View();
          }
      },
      {
          label: 'Update Status',
          command: () => {
              this.Status();
          }
      }
    ];
  }
  openRecordsCount
  cancelledRecordsCount
  shippedRecordsCount
    //Action from Table
    View() {
      throw new Error('Method not implemented.');
    }
    Status() {
      throw new Error('Method not implemented.');
    }
    Copy() {
      throw new Error('Method not implemented.');
    }
    serviceNames :any;
  ngOnInit(): void {
    // console.log(this.shippingStatusData);
    //Added
    this.filterForm = this.fb.group({
      location: [null, Validators.required],
      supplierLocation: [null, Validators.required], 
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required],
      deliveryNumber: [null],
      status: [null],
      carrier: [null],
      region: [null],
      trackingNumber: [null],
      userName: [null, Validators.required],
      paymentType: [null],
      shipperName: [null],
      costCenter: [null],
      supplier: [null],
      poNumber: [null],
      shipToCompany: [null],
      feederSystem: [null],
      country: [null]
    });
    
    this.xfservice.getLocationMasterData().subscribe((data) => {
      this.countryOptions = data.countrylist;
      this.statusOptions = data.shipmentstatuses;
      const requiredStatuses = [
        "Shipped",
        "In-Transit",
        "Delivered",
        "Cancelled"
      ];
      this.statusOptions = this.statusOptions.filter(status => 
        requiredStatuses.includes(status.description)
      );
      
      console.log(this.statusOptions.sort((a, b) => a.id - b.id));
      this.carrierOptions = data?.carriers;
      this.locationOptions = data?.location;
      this.serviceNames = data?.carrierservices;
    });
    
    // Load options for dropdowns
    this.loadOptions();
    this.shipDateFrom = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 6);
    //Old Code
    this.xfields=[];
    this.xfservice.getScreenTemplateByName('ManifestTable')
    .subscribe((mdata:any)=>{
      if (mdata.length >0)
      {
        // this.xfields=mdata[0].details;
        // Update each object's 'title' property by adding spaces before capital letters
        this.xfields = mdata[0].details.map((detail: { IncludeHeader: string; fields: any[]; }) => ({
          ...detail,
          IncludeHeader: this.addSpacesToTitles(detail.IncludeHeader),
          fields: detail.fields.map((field: { title: string; }) => ({
            ...field,
            title: this.addSpacesToTitles(field.title)
          }))
        }));
      }
     //console.log(JSON.stringify(this.xfields))
      
      this.xfields.forEach((item)=>{
        // console.log(JSON.stringify(item))
        item.fields.forEach((field:any)=>{
          this.xcolumns.push(item.fieldHeader +'.' +field.fieldName)
          if(field['filterField']==true && field['fieldName']!= "ShipDate") 
          {
            this.filters[item.fieldHeader +'.' +field.fieldName]='';
          }
          else if(field['fieldName']=="ShipDate"){
            this.filters['shipDateFrom']='';
            this.filters['shipDateTo']='';
          }
        })
      })
      // console.log(this.filters)
   
      //this.xcolumns=["HeaderInfo.ERP","HeaderInfo.DocumentType"]
      //'----------------------------------------'
      this.xfservice.getScreenTemplateByName('ShipScreen')
      .subscribe((tdata: any) => {
        this.template = tdata[0].details;
        if( this.xfields.length < 1)
        {
          Object.keys(this.template).forEach((key: string) => { 
            if(key != 'FOOTER_LEFT' && key != 'FOOTER_RIGHT' && key!='MasterData'  && key!='Items'  && key!='Packages'  && key!='InternationalDetails' ){
              this.xfields.push({ visible: true, fieldHeader: key, fields: this.template[key].fields }) 
            }
          })
        }
        
        //ShipRequest //shipments
        console.log(' Adding from shiprequest fields')
        this.xfservice
        .getxFormRecordsByFromName('shipments')
        .subscribe((data: any) => {
          let intransistCount = 0;
          let shippedCount = 0;
          let cancelledCount = 0;
          let deleiverRecordsCount = 0;
          let openRecordsCount = 0;
          const filteredData = data?.filter((x:any)=>x.details?.HeaderInfo?.Status?.toLocaleLowerCase() !='open');
          filteredData?.map((item: any) => {
            //  this.shipRequests.push(item.details)
            if (item?.details.CarrierDetails) {
              // Assuming details contains a ServiceCode you want to match dynamically
              const serviceCodeToMatch = item.details.CarrierDetails.ServiceName; // dynamically set ServiceCode            
              // Find the matching service based on ServiceCode
              const matchedService = this.serviceNames.find(service => service.ServiceCode === serviceCodeToMatch);
            
              if (matchedService) {
                // Display the dynamically matched ServiceName            
                const serviceName = matchedService.ServiceName;            
                // Push the details with the matched service name
                this.shipRequests.push({
                  ...item.details, // Spread the existing details object
                  CarrierDetails: {
                    ...item.details.CarrierDetails, // Spread the existing CarrierDetails
                    ServiceName: serviceName // Use the updated ServiceName
                  }
                });
                } else  {
                  this.shipRequests.push({
                  ...item.details, // Spread the existing details object
                  CarrierDetails: {
                    ...item.details.CarrierDetails, // Spread the existing CarrierDetails
                    ServiceName: item.details?.CarrierDetails.ServiceName  // Use the updated ServiceName
                  }   }           
                );
                }
            }

            if (item?.details.HeaderInfo?.Status === 'In-Transit') {
              intransistCount++; // Increment openCount if status is 'Open'
            } else if (item?.details.HeaderInfo?.Status === 'Shipped') {
              shippedCount++; // Increment shippedCount if status is 'Shipped'
            } 
            else if(item?.details.HeaderInfo?.Status === 'Cancelled'){
              cancelledCount++; // Increment shippedCount if
            }
            else if(item?.details.HeaderInfo?.Status === 'Delivered'){
              deleiverRecordsCount++; // Increment shippedCount if
            }
            else if(item?.details.HeaderInfo?.Status === 'Open'){
              openRecordsCount++; // Increment shippedCount if
            }
          });
         
          // this.openRecordsCount = openCount; // Add this property to track open count
          // this.shippedRecordsCount = shippedCount; 
          // this.cancelledRecordsCount = cancelledCount
          this.shipRequests.forEach((items)=>{
            items.buttonItem=this.items
          })
          this.Org_shipRequests = Array.from(this.shipRequests)
          this.totalRecords = filteredData.length;
          this.isDataLoaded = true;
          const countsMap = {
            "Delivered": deleiverRecordsCount,
            "Cancelled": cancelledCount,
            "In-Transit": intransistCount,
            "Shipped": shippedCount,
          };
          this.statusOptions = this.statusOptions.map(status => {
            return {
              ...status,
              count: countsMap[status.description] || 0 // Add count if available, otherwise default to 0
            };
          });
          console.log(this.statusOptions);
        });
      })
      //--------------------------------------------
    })
  }

  //
  OpenStatusbar = (shipRequest: any) => {
    // console.log(shipRequest);
    //
    this.selectedCarrierType = shipRequest.CarrierDetails['Carrier']?.toLocaleLowerCase();
    //
    const payload = GetStatusPayload(this.selectedCarrierType);
   this.filterShipment = {
      carrierName: shipRequest.CarrierDetails.Carrier,
      trackingNumber: shipRequest.Packages[0]?.TrackingNumber,
      service: shipRequest.CarrierDetails.ServiceName,
      deliveredTo: shipRequest.ShipTo.COMPANY,
      shipDate: shipRequest.HeaderInfo.ShipDate ? this.TableformatDate(shipRequest.HeaderInfo.ShipDate): '',
      standardTransit: shipRequest.HeaderInfo.ShipDate ? this.TableformatDate(shipRequest.HeaderInfo.ShipDate)+ ' before '+ this.TableformatTime(shipRequest.HeaderInfo.ShipDate): '',
      delivered: shipRequest.HeaderInfo.ShipDate ? this.TableformatDate(shipRequest.HeaderInfo.ShipDate)+ ' at '+this.TableformatTime(shipRequest.HeaderInfo.ShipDate): '',
      signedBy: shipRequest.ShipTo.CONTACT,
      status: 'Delivered'
    };
    this.xfservice.getTrackShippingStatus(payload, this.selectedCarrierType).subscribe({
      next: (data: any) => {
        const dataResult = data[0].length > 0 ? data[0]: data;
        this.selectedShippingStatusData = dataResult && dataResult.length > 0 && dataResult.map((record: any) => ({
          ...record,
          documentNumber: shipRequest.HeaderInfo?.DocumentNumber,
          trackingNumber: shipRequest.Packages[0]?.TrackingNumber,
          carrierDetails: shipRequest.CarrierDetails,
          shipFrom: shipRequest.ShipFrom,
          shipTo: shipRequest.ShipFrom,
          shipperContact: shipRequest.Shipper['CONTACT'],
          shipDate: shipRequest.HeaderInfo['ShipDate'],
          statusImgData: GetStatusImageData(record.StatusCode),
          DateTime: GetDateTimeFromNumber(record.DateTime) // Update the 'date' column
        }));
        // this.selectedShippingStatusData = data[0];
        this.shippingStatusData = shippingStatus;
        this.filterEventData=this.transformDataList(this.selectedShippingStatusData)
        if(this.selectedShippingStatusData.length > 0){
          //
          Promise.resolve(formatTrackingData(this.selectedShippingStatusData)).then((x) => {
            this.travelHistory = x;
            this.cd.markForCheck();
          });
        }
        this.displaySidebar = true;
      },
      error: (err) => {
        console.error('Error fetching shipping status:', err);
      }
    });
  }

  //track shipping status
  toggleApplications() {
    if (this.travelHistory && this.travelHistory.length > 0) {
      const newFiles = [...this.travelHistory];
      newFiles[0] = { ...newFiles[0], expanded: !newFiles[0].expanded };
      this.travelHistory = newFiles;
    }
  }

  addSpacesToTitles(text: string): string {
    return text.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
  
  loadOptions() {
    // Fetch or initialize dropdown options here
    this.locationOptions = [{ label: 'Elemica', value: 'elemica' }]; // Example data
    this.supplierLocationOptions = [{ label: 'Supplier Location 1', value: 'supplier1' }];
    this.regionOptions = [{ label: 'Region 1', value: 'region1' }];
    this.statusOptions = [{ label: 'Status 1', value: 'status1' }];
    this.paymentTypeOptions = [    { label: 'Sender', value: 'Sender' },
      { label: 'Receipient', value: 'Receipient' },
      { label: 'Third Party', value: 'Third Party' },
      { label: 'Collect', value: 'Collect' }];
    this.shipperOptions = [{ label: 'Shipper 1', value: 'shipper1' }];
    this.costCenterOptions = [{ label: 'Cost Center 1', value: 'costcenter1' }];
    this.countryOptions = [{ label: 'Country 1', value: 'country1' }];
    this.feederSystemOptions = [{ label: 'Feeder System 1', value: 'feedersystem1' }];
  }

  onSubmit() {
    if (this.filterForm?.valid) {
      // Process form data
      console.log(this.filterForm.value);
    }
  }

  // Example of a method to handle dropdown changes
  onDropdownChange(event: any) {
    // Handle dropdown changes
  }
  //Open Table properties
  openTableProperties() {
    this.tableProperties_dialog = true;
  }

  //Save Manifest Table properties
  // Save/Update TemplateChanges
  SaveTemplate(templatename: any): void {
    this.xfields.forEach((item)=>{
      if (item.visible==true)
      {
        if(item.IncludeHeader ==undefined)
           item["IncludeHeader"]=""
      }
    })

    this.xfservice
      .saveScreenTemplateByName(templatename, this.xfields)
      .subscribe((data) => {
        this.tableProperties_dialog = false
        // this.messageService.add({
        //   key: 'tc',
        //   severity: 'success',
        //   summary: 'Template',
        //   detail: templatename + ' Properties are successfully saved.',
        // });
      });

  }

  selectedDateRange: string = '';

  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  applyFilterGlobal($event: any, stringVal: any): void {
    if (($event.target as HTMLInputElement).value) {
      this.shipRequests = this.filterNestedArray(this.Org_shipRequests, ($event.target as HTMLInputElement).value)
    } else {
      this.shipRequests = Array.from(this.Org_shipRequests);
    }
  }

   filterNestedArray(jsonArray: any[], searchTerm: string): any[] {
    return jsonArray.filter(item => {
      if (typeof item === 'object' && item !== null) {
        return Object.values(item).some(value => this.filterNestedArray([value], searchTerm).length > 0);
      }
      return String(item).includes(searchTerm);
    });
  }

  getShipRequest(){
    if(this.Carrier != '')
      this.conditions["Carrier"]="details->'CarrierDetails'->>'Carrier'='" + this.Carrier +"'"

    if(this.documentType !='')
      this.conditions["DocumentType"]="details->'HeaderInfo'->>'DocumentType'='" + this.documentType +"'";
    
    // console.log(JSON.stringify(this.conditions))
  }
  
  applyFilters(){
    var fromdate=''
    var toDate=''
    // console.log(this.filters['shipDateFrom'])
    if(this.filters['shipDateFrom']!='' && this.filters['shipDateto']!=''){
    
      this.shipDateFrom=this.filters['shipDateFrom'];
      this.shipDateTo=this.filters['shipDateTo']
      // console.log(this.shipDateFrom)
    
      fromdate += this.shipDateFrom.getFullYear();
      if((this.shipDateFrom.getMonth() + 1) < 10){
       fromdate += '-0' +  (this.shipDateFrom.getMonth()+1) 
      }
      else
       fromdate += '-' + (this.shipDateFrom.getMonth()+1)
      if(this.shipDateFrom.getDate()<10)
       fromdate += '-0' +this.shipDateFrom.getDate();
      else
       fromdate += '-' + this.shipDateFrom.getDate();
      toDate += this.shipDateTo.getFullYear() ;
      if((this.shipDateTo.getMonth() + 1) < 10){
       toDate += '-0' +  (this.shipDateTo.getMonth()+1) 
      }
      else
       toDate += '-' + (this.shipDateTo.getMonth()+1)
      if(this.shipDateTo.getDate()<10)
       toDate += '-0' +this.shipDateTo.getDate();
      else
       toDate += '-' + this.shipDateTo.getDate();  
   
      this.conditions['shipdate']="details->'HeaderInfo'->>'ShipDate' between '" + fromdate +"' and '" + toDate +"'"
    }

    var keys=Object.keys(this.filters);
    // console.log(keys)
    keys.forEach((item,index)=>{
      var condition='';
      var fields: any =[];
      if(item !="shipDateFrom" && item !="shipDateTo"){
        if(this.filters[item]!=''){
          fields=item.split('.')
          condition += "details->'" + fields[0] + "'->>'" + fields[1] +"'='" + this.filters[item] +"'";
          // console.log(condition)
          this.conditions["condition" + index]=condition
        }
      }
    })
    // console.log(JSON.stringify(this.conditions))
    this.xfservice.getShipRequestByFilters(JSON.stringify(this.conditions)).subscribe((data:any)=>{ 
      this.shipRequests=data
      this.totalRecords = data.length;
      this.isDataLoaded = true;

    });

  }


trackingSteps = [
  { status: 'Cancelled', image: 'assets/images/statusProgress.png' },  // Arrow images for each status
  { status: 'Delivered', image: 'assets/images/statusProgress.png' },
  { status: 'In-Transit', image: 'assets/images/statusProgress.png' },
  { status: 'Shipped', image: 'assets/images/statusProgress.png' }
];

getCurrentStepImage(status): string {
  const currentStep = this.trackingSteps.find(step => step.status === status);
  return currentStep ? currentStep.image : '';
}

// Function to transform and sort data
transformDataList(inputList: any[]): any[] {
  const filteredInputList = inputList.filter(item => item.DateTime !== undefined);
  const groupedData = this.groupByDate(filteredInputList);

  return Object.keys(groupedData)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime()) // Sort by date
    .map(date => ({
      date: this.formatDate(new Date(date)),
      details: this.sortByTime(groupedData[date].map(entry => ({
        time: this.formatTime(new Date(entry.DateTime)),
        location: entry.Location || '', // Handle optional location
        status: entry.status
      })))
    }));
}

groupByDate(inputList: any[]): { [key: string]: any[] } {
  return inputList.reduce((acc, item) => {
    if (!this.isISO8601Format(item.DateTime)){
      item.DateTime=this.convertToISO8601(item.DateTime)
    }
    const dateKey = new Date(item.DateTime).toISOString().split('T')[0]; // Group by date in YYYY-MM-DD format
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(item);
    return acc;
  }, {} as { [key: string]: any[] });
}

convertTo24HourTime(time: string): Date {
  const [timePart, period] = time.split(' ');
  let [hours, minutes] = timePart.split(':').map(Number);

  if (period === 'PM' && hours !== 12) {
    hours += 12;
  }
  if (period === 'AM' && hours === 12) {
    hours = 0;
  }

  return new Date(1970, 0, 1, hours, minutes);
}

sortByTime(details: any[]): any[] {
  return details.sort((a, b) => {
    const timeA = this.convertTo24HourTime(a.time);
    const timeB = this.convertTo24HourTime(b.time);
    return timeA.getTime() - timeB.getTime();
  });
}

formatTime(date: Date): string {
  return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
}


getRowBackgroundColor(date: string): string {
  if (!this.colorCache[date]) {
    if (this.lastDate !== date) {
      this.useAlternateColor = !this.useAlternateColor;
      this.lastDate = date;
    }
    this.colorCache[date] = this.useAlternateColor ? '#f0f0f0' : '#ffffff';
  }
  return this.colorCache[date];
}

isISO8601Format(dateStr) {
  // Regular expression to check for ISO 8601 format
  const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:Z|[+\-]\d{2}:\d{2})$/;
  return iso8601Pattern.test(dateStr);
}

convertToISO8601(dateStr) {
  // Parse the date and time string
  const [datePart, timePart, period] = dateStr.split(/[\s:]+/);
  const [month, day, year] = datePart.split('/').map(Number);
  const [hours, minutes] = [parseInt(timePart, 10), parseInt(timePart, 10)];

  // Adjust hours for AM/PM
  let hour24 = hours;
  if (period === 'PM' && hour24 !== 12) {
      hour24 += 12;
  } else if (period === 'AM' && hour24 === 12) {
      hour24 = 0;
  }

  // Create a Date object
  const date = new Date(year, month - 1, day, hour24, minutes);

  // Convert to ISO string
  let isoString = date.toISOString();

  // Adjust to local time zone offset (replace with actual timezone if known)
  const offset = new Date().getTimezoneOffset() * -1;
  const offsetHours = String(Math.floor(offset / 60)).padStart(2, '0');
  const offsetMinutes = String(offset % 60).padStart(2, '0');
  const offsetSign = offset >= 0 ? '+' : '-';

  // Format ISO 8601 with timezone offset
  isoString = isoString.replace('Z', `${offsetSign}${offsetHours}:${offsetMinutes}`);

  return isoString;
}

TableformatDate(input: string): string {
  const date = new Date(input);
  const options: Intl.DateTimeFormatOptions = { year: '2-digit', month: '2-digit', day: '2-digit' };
  return date.toLocaleDateString('en-US', options).replace(/\//g, '/');
}

TableformatTime(input: string): string {
  const date = new Date(input);
  const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
  return date.toLocaleTimeString('en-US', options);
}


receiveData(data:any) {
  this.finalColumns = data;
}

}
  
function subscribe(arg0: (data: any) => void) {
  throw new Error('Function not implemented.');
}

