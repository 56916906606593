import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { AppMainComponent } from '../app-main/app-main.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChatbotComponent } from '../chatbot/chatbot.component';
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { ERPSHIPService } from '../erpship.service';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ColumnConfigService } from '../services/column-config-service';
import { UserDataService } from '../services/user-data.service';
import { SidebarService } from '../sidebar.service';
import { filter } from 'rxjs/operators'; // Import filter operator

@Component({
  selector: 'app-topbar',
  templateUrl: './app-top-bar.component.html',
  styleUrls: ['./app-top-bar.component.scss'],
})
export class AppTopBarComponent {
  showNavbar: boolean = false;
  isOpen: boolean = false;
  excludedUrls = ['/home/shipnow', '/home/shiprequestmodal','home/copilot','/home/routingguidemodal']; // URLs where sidenav should not work
 
  isDataLoaded: boolean = false;
  filetype = '.jpg, .jpeg, .bmp, .png';
  filename: string = '';
  imagebase64: any = '';
  user: any = {};
  udetails: any = {};
  profileflag: boolean = false;
  titlename: string = '';
  xformItems: any[] = [];
  xforms: any[] = [];
  masterforms: any[] = [];
  profileImage: string = '';
  himenu: any = [];
  hflag: boolean = true;
  chatflag: boolean = false;

  locationpopup_flag: boolean = false;

  items = [
    {
      label: 'Master Data',
      icon: 'pi pi-fw pi-file',
      items: [],
    },
    {
      label: 'Supplier',
      icon: 'pi pi-fw pi-user',
      items: [],
    },
    {
      label: 'Enumerations',
      icon: 'pi pi-fw pi-bar',
      items: [],
    },
  ];
  menuitems: any[] = [];
  
  ngOnInit() {   
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.isOpen = false; // Close the sidebar when navigation ends
      this.updateLayout(); // Update layout classes
    });

    this.menuitems = [
      {
        label: 'Ship Now',
        icon: 'pi pi-bookmark',
        routerLink: ['/home/shipnow'],
      },
      {
        label: 'Ship Requests',
        icon: 'pi pi-bookmark',
        routerLink: ['/home/shiprequest'],
      },
      {
        label: 'Shipping Manifest',
        icon: 'pi pi-bookmark',
        routerLink: ['/home/Manifest'],
      },
      {
        label: 'Track Now',
        icon: 'pi pi-bookmark',
        routerLink: ['/home/TrackShipment'],
      },
      {
        label: 'Quote Now',
        icon: 'pi pi-bookmark',
        routerLink: ['/home/Manifest'],
      },
    ];
  }
  private updateLayout() {
    // Get elements for content and topbar
    const contentWrapper = document.querySelector('.content-wrapper');
    const topbar = document.querySelector('.layout-topbar');

    // Add or remove the 'shifted' class based on sidebar state
    if (this.isOpen) {
      contentWrapper?.classList.add('shifted');
      topbar?.classList.add('shifted');
    } else {
      contentWrapper?.classList.remove('shifted');
      topbar?.classList.remove('shifted');
    }
  }
  constructor(
    public app: AppComponent,
    public appMain: AppMainComponent,
    public chat: ChatbotComponent,
    private router: Router,
    private xfservice: ERPSHIPService,
    private columnConfigService: ColumnConfigService,
    private userContext : UserDataService,
    private sidebarService: SidebarService,
    private cdr: ChangeDetectorRef,
  ) {
    let data = this.userContext.getDecryptedCookie('usercontext')
    this.user['Image'] =
      'iVBORw0KGgoAAAANSUhEUgAAAA4AAAAQCAIAAACp9tltAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAVSURBVChTY/hPNBhVOqp0gJX+/w8AsF2df6BLlVIAAAAASUVORK5CYII=';
    // let cookies = document.cookie;
    // let cparts = cookies.split('=');
    this.udetails = data.body;

    this.xfservice
      .getUserProfile(this.udetails['user_id'])
      .subscribe((data: any) => {
        if (data.length > 0) {
          this.columnConfigService.setProfileDetails(data);
          if (data[0].details.Image) {
            this.profileImage =
              'data:image/png;base64,' + data[0].details.Image;
            this.user = data[0].details;
            this.isDataLoaded = true;
          } else {
            this.profileImage =
              'data:image/png;base64,' +
              'iVBORw0KGgoAAAANSUhEUgAAAA4AAAAQCAIAAACp9tltAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAVSURBVChTY/hPNBhVOqp0gJX+/w8AsF2df6BLlVIAAAAASUVORK5CYII=';
            this.isDataLoaded = true;
          }
        } else {
          this.profileImage =
            'data:image/png;base64,' +
            'iVBORw0KGgoAAAANSUhEUgAAAA4AAAAQCAIAAACp9tltAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAVSURBVChTY/hPNBhVOqp0gJX+/w8AsF2df6BLlVIAAAAASUVORK5CYII=';
          this.isDataLoaded = true;
        }
      });

    /* this.xfservice.getxForms().subscribe((data) => {
       this.xforms = data;
       this.xformItems = [
         {
           label: 'Options',
           items: [],
         },
       ];
       this.xforms.forEach((xf) => {
         if (xf.details.isActive) {
           let formdata = {
             label: '',
             icon: 'pi pi-times',
             routerLink: ['formoperations', xf.details.id],
           };
           formdata.label = xf.details.formName;
           this.xformItems[0].items.push(formdata);
         
         }
       });
     });*/
  }

  processchat() {
    this.chatflag = true;
  }
  processHForms() {
    this.hflag = !this.hflag;
  }
  getForms(): void {
    // console.log("getform si calling");
    this.xfservice.getxForms().subscribe((data: any) => {
      this.xforms = data;
      this.xformItems = [
        {
          label: 'Options',
          items: [],
        },
      ];
      this.masterforms = [];
      this.xforms.forEach((xf) => {
        if (xf.details.isActive) {
          let formdata = {
            label: xf.details.formTitle,
            icon: 'pi ' + xf.details.icon,
            rl: '/home/formoperations/' + xf.details.id,
            routerLink: ['/home/formoperations', xf.details.id],
          };
          // formdata.label = xf.details.formName;
          this.xformItems[0].items.push(formdata);
          if (xf.details.formgroupname == 'MasterData') {
            this.items[0].items.push(formdata as never);
          } else if (xf.details.formgroupname == 'SupplierData') {
            this.items[1].items.push(formdata as never);
          } else {
            this.items[2].items.push(formdata as never);
          }

          this.masterforms.push(formdata);
        }
      });

      this.locationpopup_flag = true;
      //alert('XFORM ITEMS ARE : Opened')
    });
  }

  processTheam() {
    this.appMain.configActive = !this.appMain.configActive;
    this.appMain.configClick = true;
  }

  processLogout(): void {
    window.sessionStorage.removeItem('X-Token');
    this.router.navigate(['']);
  }

  openProfile(): void {
    // let cookies = document.cookie;
    // let cparts = cookies.split('=');
    // this.udetails = JSON.parse(cparts[1])

    this.profileflag = true;
  }

  fileToBase64(file: File) {
    //alert( file.size)
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result !== null && reader.result !== undefined) {
        this.user['Image'] = reader.result.toString().split(',')[1];
        // alert(base64);
      }
    };
    reader.onerror = (error) => {
      alert('Error: ' + error);
    };
  }

  Save() {
    this.user['user_id'] = this.udetails['user_id'];
    this.xfservice.saveProfile(this.user).subscribe((data: any) => {
      if (this.user.Image) {
        this.profileImage = 'data:image/png;base64,' + this.user.Image;
      }

      this.profileflag = false;
    });
  }
 // Check if the current URL is in the excluded URLs list

   toggle() {
    this.isOpen = !this.isOpen; // Toggle the sidebar state

    // Get elements for content and topbar
    const contentWrapper = document.querySelector('.content-wrapper');
    const topbar = document.querySelector('.layout-topbar');

    // Add or remove the 'shifted' class based on sidebar state
    if (this.isOpen) {
      contentWrapper?.classList.add('shifted');
      topbar?.classList.add('shifted');
    } else {
      contentWrapper?.classList.remove('shifted');
      topbar?.classList.remove('shifted');
    }
    this.sidebarService.setSidebarState(this.isOpen);
  }
  shouldShowSidenav(): boolean {
    const isExcludedUrl = this.excludedUrls.some(excludedUrl => this.router.url.startsWith(excludedUrl));
    
    // If not excluded, add more logic here
    if (!isExcludedUrl) {
      return true;
      // Additional logic
      // For example, check if the user is logged in or some other condition
      
    }
    this.isOpen = false;
    return false;
  }
  
  reloadCurrentRoute(route: string) {
    if (this.router.url === route) {
      // Force reload of the current page
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([route]);
      });
    } else {
      // Navigate to the clicked route
      this.router.navigate([route]);
    }
  }
  
}

