import { Component, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dialog-table',
  templateUrl: './dialog-table.component.html',
  styleUrl: './dialog-table.component.scss',
  // encapsulation: ViewEncapsulation.None,
})
export class DialogTableComponent {
  @Input() displayedData: any[] = [];
  @Input() columns: any[] = [];
  @Input() sharedViewFunction!: (data: any) => void;
  @Input() snoFlag: boolean;

  page: number = 0; // Current page index
  rows = 50;
  displayedArray: any;
  totalRecords = 0;

  ngOnInit(){
  
   }

   ngOnChanges(changes: SimpleChanges): void {
    if (changes['displayedData'] && this.displayedData) {
      this.updateDisplayedData()
    
    }
  }

  getValueFromPath(
    object: any,
    path: string,
    defaultValue: any = undefined
  ): any {
    const data = path
      .split('.')
      .reduce((o, i) => {
        if (o === undefined || o === null) {
          return defaultValue;
        }
        const match = i.match(/^(.+?)\[(\d+)\]$/); // Check if key is an array index
        if (match) {
          // If it's an array index
          const [_, arrayKey, index] = match;
          return o[arrayKey]?.[parseInt(index)] ?? defaultValue;
        }
        return o[i] !== undefined ? o[i] : defaultValue;
      }, object);
    return data;
  }

  
  // Update the displayed data based on the current page
  updateDisplayedData() {
    const start = this.page * this.rows;
    const end = Math.min(this.displayedData.length, start + this.rows);
    this.displayedArray = this.displayedData.slice(start, end);
    this.totalRecords=this.displayedArray.length

  }

  
  // Handle page change event from paginator
  onPageChange(page: number) {
    this.page = page;
    this.updateDisplayedData();
  }

  
  customSort(event) {
    const field = event.field;
    const order = event.order;

    this.displayedArray.sort((data1, data2) => {
      const value1 = this.getValueFromPath(data1, field);
      const value2 = this.getValueFromPath(data2, field);

      // Sorting logic
      if (typeof value1 === 'string' && typeof value2 === 'string') {
        return (value1 < value2 ? -1 : 1) * order;
      } else if (typeof value1 === 'number' && typeof value2 === 'number') {
        return (value1 - value2) * order;
      }
      return 0;
    });
  }

  callParentView(data): void {
    if (this.sharedViewFunction) {
      this.sharedViewFunction(data); 
    }
  }
}
