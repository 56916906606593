<p-table *ngIf="(shippingCharges != undefined && shippingCharges.length > 0)" [value]="shippingCharges" class="p-datatable-sm mt-3">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 5% !important;">#</th>
        <th style="width: 25% !important;">Description</th>
        <th style="width: 25% !important;">amount</th>
        <th style="width: 25% !important;">currency</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-i="rowIndex">
      <tr>
        <td>{{ i + 1 }}</td>
        <td>{{ product.description }}</td>
        <td><span style="color: #4ea732;">$</span>{{ product.amount }}</td>
        <td>{{ product.currency }}</td>
      </tr>
    </ng-template>
  </p-table>

  <p class="mt-5" style="text-align: center;" *ngIf="(shippingCharges != undefined && shippingCharges.length === 0)">
    <span class="text-center mr-5" style="color: #999;
    font-size: 18px;
    font-weight: 450;
    ">
    No shipping charges available.
  </span>
</p>
