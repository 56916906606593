<div class="pack-info-container">
    <div class="content">
        <div id="pack-list"></div>
    </div>
    <div class="dialogFooter">
        <div class="grid col-12 p-0 m-0">
            <div class="col-6">
                <button type="submit" class="packbtncancel" (click)="onCancel()">Cancel</button>
            </div>
            <div class="col-6 text-right">
                <button type="submit" class="packBtnContinue" (click)="onContinue()">Confirm and Close</button>
            </div>
        </div>
    </div>    
</div>