<div class="chatbot-wrapper">
  <div class="chatbot-content">
    <!-- Quick Actions (inside scrollable area) -->
    <div class="chat-container p-5"  #chatContainer>
          <!-- Logo at the center -->
          <div class="container">
            <ng-container *ngIf="showLogo">
              <div class="logo-center mt-2" style="text-align: center;">
                <img [@fadeOut]="logoState" src="../../assets/images/copilotlogo1.png" alt="Copilot Logo" />
              </div>
              
              <div class="quick-actions mb-3">
                <div class="action-card" *ngFor="let action of actions">
                  <img [src]="action.image" alt="{{ action.label }}" class="action-image" />
                  <span>{{ action.label }}</span>
                </div>
              </div>
            </ng-container>
          </div>
          
      <!-- Chat Messages -->
      <div *ngFor="let message of messages; let i = index" [ngClass]="{'message-container-closed': isSidebarOpen, 'message-container-open': !isSidebarOpen}">
        
        <!-- Text Message -->
        <div class="message" *ngIf="message.type === 'text'"
             [ngClass]="{from: message.author === 'bot', to: message.author === 'user'}">
          {{ message.content }}
        </div>

        <!-- Custom Text Message -->
        <div *ngIf="message.type === 'custom-text'" [ngClass]="{'detail-message-closed': isSidebarOpen, 'detail-message-open': !isSidebarOpen}">
          <div class="logo-icon">
            <img src="../../assets/images/copilotlogo1.png" alt="Copilot Logo" />
          </div>
          <div class="detail-text">{{ message.content }}</div>
        </div>

        <!-- Image Message -->
        <div *ngIf="message.type === 'image'" class="image-message" (click)="openDetailImage(message.content)">
          <img [src]="message.content" alt="Chatbot Image" />
        </div>

        <!-- JSON Array Grid -->
        <div *ngIf="message.type === 'json-array'" class="json-array-grid">
          <div class="grid-row header-row">
            <div *ngFor="let column of columns" [ngStyle]="{'text-align': column.align}" class="grid-header">
              {{ column.alias }}
              <span *ngIf="column.extraText">{{ column.extraText }}</span>
            </div>
          </div>
          <div *ngFor="let item of message.content; let i = index" class="grid-row">
            <div *ngFor="let column of columns" 
                 [ngStyle]="{'text-align': column.align}" 
                 class="grid-item"
                 [ngClass]="{'lowest-Freight': column.header === 'discountFreight' && item.isLowestlowestDiscountFreight}">
              <span *ngIf="column.header === 'discountFreight' && item.isLowestlowestDiscountFreight">★</span>
              {{ item[column.header] }}
            </div>
          </div>
        </div>

        <!-- JSON Array Weight -->
        <div *ngIf="message.type === 'json-array-Weight'" [innerHTML]="message.content" class="json-Weight-array-grid"></div>

        <!-- JSON Array Track Grid -->
       <!-- Grid Display -->
       <div *ngIf="message.type === 'json-array-track-fedex'" [ngClass]="{'json-array-track-grid-closed': isSidebarOpen, 'json-array-track-grid-open': !isSidebarOpen}">
        <!-- Grid Title and Download Button -->
        <div class="grid-header-container">
          <h3 class="grid-title">
            <!-- Display FedEx logo if the carrier is FedEx -->
            <img *ngIf="carrierNamefedex === 'FedEx'" src="assets/images/fedex.png" alt="FedEx Logo" />

            {{ trackmsgfedex }}
          </h3>
         
        </div>

        <!-- Static Column Headers -->
        <div class="track-grid-grid-row header-row">
          <div class="track-grid-grid-header color-dot-column"></div> <!-- Dot Column Header -->
          <div *ngFor="let column of trackcolumns"
            [ngStyle]="{'text-align': column.align,'width': column.width}" class="track-grid-grid-header">
            {{ column.alias }}
            <span style="text-align: center !important;" *ngIf="column.extraText">{{ column.extraText }}</span>
          </div>
        </div>

        <!-- Dynamic Data Rows -->
        <div *ngFor="let item of message.content; let i = index" class="track-grid-grid-row">
          <!-- Dotted Color Column -->
          <div class="track-grid-grid-item color-dot-column" [ngClass]="getColorClass(item.description)">
            <span class="color-dot" [ngStyle]="{'background-color': item.color}"></span>
          </div>
          <!-- Other Columns -->
          <div *ngFor="let column of trackcolumns"
            [ngStyle]="{'text-align': column.align,'width': column.width}" class="track-grid-grid-item">
            {{ item[column.header] }}
          </div>
         
        </div>

      </div>




      <div *ngIf="message.type === 'json-array-track-ups'" [ngClass]="{'json-array-track-grid-closed': isSidebarOpen, 'json-array-track-grid-open': !isSidebarOpen}">
        <!-- Grid Title and Download Button -->
        <div class="grid-header-container">
          <h3 class="grid-title">
            <!-- Display UPS logo if the carrier is UPS -->
            <img *ngIf="carrierNameups === 'UPS'" src="assets/images/ups.png" alt="UPS Logo" />
            {{ trackmsgups }}
          </h3>
         
        </div>

        <!-- Static Column Headers -->
        <div class="track-grid-grid-row header-row">
          <div class="track-grid-grid-header color-dot-column"></div> <!-- Dot Column Header -->
          <div *ngFor="let column of trackcolumns"
            [ngStyle]="{'text-align': column.align,'width': column.width}" class="track-grid-grid-header">
            {{ column.alias }}
            <span style="text-align: center !important;" *ngIf="column.extraText">{{ column.extraText }}</span>
          </div>
        </div>

        <!-- Dynamic Data Rows -->
        <div *ngFor="let item of message.content; let i = index" class="track-grid-grid-row">
          <!-- Dotted Color Column -->
          <div class="track-grid-grid-item color-dot-column" [ngClass]="getColorClass(item.description)">
            <span class="color-dot" [ngStyle]="{'background-color': item.color}"></span>
          </div>
          <!-- Other Columns -->
          <div *ngFor="let column of trackcolumns"
            [ngStyle]="{'text-align': column.align,'width': column.width}" class="track-grid-grid-item">
            {{ item[column.header] }}
          </div>
         
        </div>

      </div>

        <!-- SVG Content -->
        <div *ngIf="message.type === 'svg-content'">
          <div [id]="'pack-list-'+ i"></div>
        </div>
      </div>
    </div>
    
    <!-- Input Field at the bottom -->
<!-- Input Field at the bottom -->
<!-- <div class="input-group-wrapper"  [ngStyle]="{'margin-left': isSidebarOpen ? '20px' : '10%'}">
  <div class="p-inputgroup p-input-icon-right" type="button" id="button-addon2" (click)="sendMessage()">
    <img class="chain-link-icon" src="assets/images/chain-link.png" (click)="showConnect = !showConnect" />
    <input class="copilotinput" [(ngModel)]="value" [placeholder]="timeOfDay" (keyup.enter)="sendMessage()"
           type="text" aria-label="Recipient's username" aria-describedby="button-addon2" />
    <i class="fa-solid fa-circle-arrow-up" (click)="sendMessage()"></i>
  </div>
</div> -->
<div class="input-group-wrapper"  [ngClass]="{'margin-left-open': isSidebarOpen, 'margin-left-closed': !isSidebarOpen}">
  <div class="p-inputgroup p-input-icon-right">
    <img class="chain-link-icon" src="assets/images/chain-link.png" (click)="showConnect = !showConnect" />
    <input 
      class="copilotinput" 
      [(ngModel)]="value" 
      [placeholder]="timeOfDay" 
      (keyup.enter)="sendMessage()"
      type="text" 
      aria-label="Recipient's username" 
      aria-describedby="button-addon2" />
    <i class="fa-solid fa-circle-arrow-up" (click)="sendMessage()"></i> <!-- Triggers API call when icon is clicked -->
  </div>
</div>



    <!-- Connect Container -->
    <div class="connect-container" *ngIf="showConnect">
      <div class="connect-card" *ngFor="let item of connectList">
        <img [src]="item.icon" alt="{{ item.label }}" />
        <span>{{ item.label }}</span>
      </div>
      <div class="upload-container">
        <img src="assets/images/file-upload.png" alt="file upload" />
        <span>Upload from Computer</span>
      </div>
    </div>
  </div>
</div>

<!-- Dialog for showing large image -->
<p-dialog header="Ship Image" [modal]="true" [(visible)]="openImageDialog"
          [style]="{ width: '400px', minHeight: '600px' }" draggable="false">
  <div class="content">
    <div class="ship-image">
      <img [src]="shipImage" alt="Chatbot Image" />
    </div>
  </div>
</p-dialog>
