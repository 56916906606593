import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AppMainComponent } from './app-main/app-main.component';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { DashBoardsComponent } from './dash-boards/dash-boards.component';
import { DynamicformsComponent } from './dynamicforms/dynamicforms.component';
import { FormOperationsComponent } from './form-operations/form-operations.component';
import { XShipComponent } from './xship/xship.component';
import { ShipRequestComponent } from './ship-request/ship-request.component';
import { CarrierSpecialServiceConfigComponent } from './carrier-special-service-config/carrier-special-service-config.component';
import { shipComponent } from './ship/ship.component';
import { ManifestComponent } from './manifest/manifest.component';
import { ConsolidationComponent } from './consolidation/consolidation.component';
import { ConsolidationTemplateComponent } from './consolidation-template/consolidation-template.component';
import { SloginComponent } from './slogin/slogin.component';
import { RoutingguideComponent } from './routingguide/routingguide.component';
import { RoutingGuideParametersComponent } from './routing-guide-parameters/routing-guide-parameters.component';
import { RGFieldPriorityComponent } from './rgfield-priority/rgfield-priority.component';
import { NewroutingruleComponent } from './newroutingrule/newroutingrule.component';
import { PackshipComponent } from './packship/packship.component';
import { PackShipV2Component } from './pack-ship-v2/pack-ship-v2.component';
import { RoutingGuideDefaultsComponent } from './routing-guide-defaults/routing-guide-defaults.component';
import { ShipNowComponent } from './ship-now/ship-now.component';
import { ProductService } from './pages/dashboard/dashboard-table/productservice';
import { ShipRequestModalComponent } from './ship-request-modal/ship-request-modal.component';
import { TrackShipmentComponent } from './track-shipment/track-shipment.component';
import { CopilotComponent } from './copilot/copilot.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { OrdersComponent } from './orders/orders.component';
import { NewLocationComponent } from './newlocation/newlocation.component';
import { NewUsersComponent } from './newusers/newusers.component';
import { NewErpComponent } from './newerp/newerp.component';
import { NewOrdertypeComponent } from './newordertype/newordertype.component';
import { NewDimensionsComponent } from './newdimensions/newdimensions.component';
import { NewAddressbookComponent } from './newaddressbook/newaddressbook.component';
import { NewProductComponent } from './newproduct/newproduct.component';
import { NewCarrierComponent } from './newcarrier/newcarrier.component';
import { NewCarrieraccountsComponent } from './newcarrieraccounts/newcarrieraccounts.component';
import { NewUserroleComponent } from './newuserrole/newuserrole.component';
import { LocationComponent } from './location/location.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { RoutingGuideComponent } from './routing-guide/routing-guide.component';
import { RoutingGuideModalComponent } from './routing-guide-modal/routing-guide-modal.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'slogin', component: SloginComponent },
  { path: 'payment', component: PaymentComponent },
  {
    path: 'home',
    component: AppMainComponent,
    children: [
      {
        path: 'DashBoard',
        component: DashBoardComponent,
        pathMatch: 'full',
      },
      {
        path: 'payment',
        component: PaymentComponent,
        pathMatch: 'full',
      },
      {
        path: 'DashBoards',
        component: DashBoardsComponent,
        pathMatch: 'full',
      },
      {
        path: 'DynamicForms',
        component: DynamicformsComponent,
        pathMatch: 'full',
      },
      {
        path: 'formoperations/:id',
        component: FormOperationsComponent,
        pathMatch: 'full',
      },
      {
        path: 'ShipNow',
        component: ShipNowComponent,
        pathMatch: 'full',
      },
      { path: 'Manifest', component: ManifestComponent, pathMatch: 'full' },
      { path: 'Ship', component: shipComponent, pathMatch: 'full' },
      { path: 'PackShip', component: PackshipComponent, pathMatch: 'full' },
      { path: 'PackShipV2', component: PackShipV2Component, pathMatch: 'full' },
      // { path: 'ShipNow',    component: ShipNowComponent,   pathMatch: 'full',       },
      { path: 'xShip', component: XShipComponent, pathMatch: 'full' },
      { path: 'xShip/:id', component: XShipComponent, pathMatch: 'full' },
      {
        path: 'shiprequest',
        component: ShipRequestComponent,
        pathMatch: 'full',
      },
      {
        path: 'routingguide',
        component: RoutingGuideComponent,
        pathMatch: 'full',
      },
      {
        path: 'routingguidemodal',
        component: RoutingGuideModalComponent,
        pathMatch: 'full',
      },
      {
        path: 'location',
        component: LocationComponent,
        pathMatch: 'full',
      },
      {
        path: 'SpecialService',
        component: CarrierSpecialServiceConfigComponent,
        pathMatch: 'full',
      },
      {
        path: 'consolidation',
        component: ConsolidationComponent,
        pathMatch: 'full',
      },
      {
        path: 'consolidationTemplate',
        component: ConsolidationTemplateComponent,
        pathMatch: 'full',
      },
      {
        path: 'routingguide',
        component: RoutingguideComponent,
        pathMatch: 'full',
      },
      {
        path: 'RoutingGuideParameters',
        component: RoutingGuideParametersComponent,
        pathMatch: 'full',
      },
      {
        path: 'RoutingGuideDefaults',
        component: RoutingGuideDefaultsComponent,
        pathMatch: 'full',
      },
      {
        path: 'RGFieldPriority',
        component: RGFieldPriorityComponent,
        pathMatch: 'full',
      },
      {
        path: 'newroutingrule',
        component: NewroutingruleComponent,
        pathMatch: 'full',
      },
      {
        path: 'newroutingrule/:id',
        component: NewroutingruleComponent,
        pathMatch: 'full',
      },
      { path: 'shipnow', component: ShipNowComponent, pathMatch: 'full' },
      {
        path: 'shiprequestmodal',
        component: ShipRequestModalComponent,
        pathMatch: 'full',
      },
      {
        path: 'TrackShipment',
        component: TrackShipmentComponent,
        pathMatch: 'full',
      },
      {
        path: 'shiprequestmodal/:id',
        component: ShipRequestModalComponent,
        pathMatch: 'full',
      },
      {
        path: 'copilot',
        component: CopilotComponent,
        pathMatch: 'full',
      },
      {
        path: 'orders',
        component: OrdersComponent,
        pathMatch: 'full',
      },
      {
        path: 'newlocation',
        component: NewLocationComponent,
        pathMatch: 'full',
      },
      {
        path: 'newusers',
        component: NewUsersComponent,
        pathMatch: 'full',
      },
      {
        path: 'newerp',
        component: NewErpComponent,
        pathMatch: 'full',
      },
      {
        path: 'newordertype',
        component: NewOrdertypeComponent,
        pathMatch: 'full',
      },
      {
        path: 'newaddressbook',
        component: NewAddressbookComponent,
        pathMatch: 'full',
      },
      {
        path: 'newproduct',
        component: NewProductComponent,
        pathMatch: 'full',
      },
      {
        path: 'newcarrier',
        component: NewCarrierComponent,
        pathMatch: 'full',
      },
      {
        path: 'newcarrieraccounts',
        component: NewCarrieraccountsComponent,
        pathMatch: 'full',
      },
      {
        path: 'newuserrole',
        component: NewUserroleComponent,
        pathMatch: 'full',
      },
      //  {
      //     path: 'Editor',SpecialService
      //    component: EditorComponent,
      //    pathMatch: 'full',
      //  },
    ],
  },
  { path: '404', component: PageNotFoundComponent, data: { title: 'Page 404' } },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: [ProductService],
})
export class AppRoutingModule {}
