<div  class="table-container mt-2">
    <div class="titleCard">
        <span class="name">
            Business Partners
        </span>
    </div>
    <p-table [value]="products" class=""  [scrollable]="true"  [scrollHeight]="scrollHeight" responsiveLayout="stack" [style]="{'backgroundColor':'#fff'}">
        <ng-template  pTemplate="header">
            <tr>
                <th style="width: 60px;"></th>
                <th>Partner Type</th>
                <th style="width: 200px;">Company Name</th>
                <th>Contact Name</th>
                <th>Address Line 1</th>
                <th>Address Line 2</th>
                <th>City</th>
                <th>State</th>
                <th>Zipcode</th>
                <th>Country</th>
                <th>Phone</th>
                <th>Email</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product let-i="rowIndex">
            <tr>
                <!-- <td>{{ product.partnerType }}</td>
                <td> <i class="pi pi-search iconPosition" (click)="showPopup(i)"></i></td>
                <td>{{ product.company }}</td>
                <td>{{ product.contact }}</td>
                <td>{{ product.addressLine1 }}</td>
                <td>{{ product.addressLine2 }}</td>
                <td>{{ product.city }}</td>
                <td>{{ product.state }}</td>
                <td>{{ product.zipcode }}</td>
                <td>{{ product.country }}</td>
                <td>{{ product.phone }}</td>
                <td>{{ product.email }}</td> -->
                <td> <i class="pi pi-search iconPosition" (click)="showPopup(i)"></i></td>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="overflow-container">
                                <input pInputText [(ngModel)]="product.partnerType" class="textfieldstyling" />
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ product.partnerType }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="overflow-container">
                                <input pInputText [(ngModel)]="product.company" class="textfieldstyling" />
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ product.company }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="overflow-container">
                                <input pInputText [(ngModel)]="product.contact" class="textfieldstyling" />
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ product.contact }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="overflow-container">
                                <input pInputText [(ngModel)]="product.addressLine1" class="textfieldstyling" />
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ product.addressLine1 }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="overflow-container">
                                <input pInputText [(ngModel)]="product.addressLine2" class="textfieldstyling" />
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ product.addressLine2 }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="overflow-container">
                                <input pInputText [(ngModel)]="product.city" class="textfieldstyling" />
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ product.city }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="overflow-container">
                                <input pInputText [(ngModel)]="product.state" class="textfieldstyling" />
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ product.state }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="overflow-container">
                                <input pInputText [(ngModel)]="product.zipcode" class="textfieldstyling" />
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ product.zipcode }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="overflow-container">
                                <input pInputText [(ngModel)]="product.country" class="textfieldstyling" />
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ product.country }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="overflow-container">
                                <input pInputText [(ngModel)]="product.phone" class="textfieldstyling" />
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ product.phone }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td pEditableColumn>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <div class="overflow-container">
                                <input pInputText [(ngModel)]="product.email" class="textfieldstyling" />
                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ product.email }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                
               
            </tr>
        </ng-template>
    </p-table>
    <app-address-book-popup #popup (onSelect)="handleAddressSelect($event)">
   
    </app-address-book-popup>
   </div>