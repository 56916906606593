<div class="table">

    <p-table #dt [value]="displayedArray"  [columns]="columns" id="track-grid"
      [paginator]="false" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
      [resizableColumns]="true" [rowHover]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" dataKey="id"
      [scrollHeight]="scrollHeight" responsiveLayout="stack"
      styleClass="dynamicHeight p-datatable-sm p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem'}"  scrollable="true" (onSort)="customSort($event)" >
  
   
  
      <ng-template pTemplate="header">
        <tr>
          <th hidden>Id</th>
          <th *ngFor="let col of columns | visibleColumns" pSortableColumn="{{col.fieldName}}">
            {{ col.header }}  <p-sortIcon field="{{col.fieldName}}"></p-sortIcon>
          </th>
  
          <th style="position: sticky; right: 0; background: white; z-index: 1; text-align: end; padding-right: 5px;">Actions
          </th>
  
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-shipRequest let-expanded="expanded" let-rowData let-columns="columns">
  
        <tr>
          <td hidden>{{ shipRequest.id }}</td>
          <td *ngFor="let col of columns | visibleColumns">
            <ng-container *ngIf="col.fieldType === 'Textbox' && ConfigKey==='shiprequest'">
              <div *ngIf="col.fieldName === 'HeaderInfo.Status'">
                <ng-container *ngIf="getValueFromPath(rowData, col.fieldName) as pathValue">
                  <div *ngFor="let colour of shipmentState;">
                    <div *ngIf="pathValue === colour.description && colour.code != 'SPD'">
                      <div [ngStyle]="{ 'color': colour.colorpicker }">
                        {{ pathValue }}
                      </div>
                    </div>
                    <div *ngIf="pathValue === colour.description && colour.code === 'SPD'">
                      <div [ngStyle]="{ 'color': colour.colorpicker }"  (click)="callParentOpenStatusbar(shipRequest)">
                        {{ pathValue }}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="col.fieldName!='HeaderInfo.Status' && col.fieldName!='TrackingNumber' ">
                {{ getValueFromPath(rowData, col.fieldName) }}
              </div>
              <div *ngIf="col.fieldName==='TrackingNumber' " style="color: #039d97;">
                <ng-container *ngIf="getValueFromPath(rowData, col.fieldName) as pathValue">
                  <span style="font-size: 20px;" *ngIf="getValueFromPath(rowData, col.fieldName)">&#8942; </span> <span
                    style="vertical-align: text-bottom;">{{ pathValue }} </span>
                </ng-container>
              </div>
            </ng-container>
  
            <ng-container *ngIf="col.fieldType === 'Textbox' && ConfigKey!='shiprequest'">
              <div *ngIf="col.fieldName === 'HeaderInfo.Status'">
                <ng-container *ngIf="getValueFromPath(rowData, col.fieldName) as pathValue">
                  <div class="pe-auto text-success" (click)="callParentOpenStatusbar(shipRequest)">
                    {{ pathValue }}
                  </div>
                </ng-container>
              </div>
              <div *ngIf="col.fieldName!='HeaderInfo.Status' && col.fieldName!='Packages[0].TrackingNumber'">
                {{ getValueFromPath(rowData, col.fieldName) }}
              </div>
              <div *ngIf="col.fieldName==='Packages[0].TrackingNumber'" style="color: #039d97;">
                <ng-container *ngIf="getValueFromPath(rowData, col.fieldName) as pathValue">
                  <span style="font-size: 20px;" *ngIf="getValueFromPath(rowData, col.fieldName)">&#8942; </span> {{
                  pathValue }}
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="col.fieldType === 'Date'">
              {{ getValueFromPath(rowData, col.fieldName) | date: 'MM/dd/yyyy' }}
            </ng-container>
            <ng-container *ngIf="col.fieldType === 'Button'">
              <button (click)="callParentShip(rowData)">Ship</button>
            </ng-container>
            <ng-container *ngIf="col.fieldType === 'inputSwitch'">
              <div *ngIf="col.fieldName==='IsActive' " style="color: #039d97;">
                <div class="p-col-12 custom-col">
                  <div class="switch-container">
                    <p-inputSwitch id="IsActive" [disabled]="true" [ngModel]="getValueFromPath(rowData, col.fieldName)">
                    </p-inputSwitch>
                  </div>
                </div>
  
              </div>
            </ng-container>
          </td>
          <td *ngIf="ConfigKey!='shiprequest'"
          style="position: sticky; right: 0; background: white; z-index: 1; padding-right: 5px;">
            <p-splitButton class="bg-none actionbtn" [style]="{float:'right'}" outlined label="Edit"
              (onClick)="callParentView('info')" styleClass="p-button-text" appendTo="body"
              [model]="shipRequest.buttonItem" text>
            </p-splitButton>
          </td>
          <td *ngIf="ConfigKey==='shiprequest'"
          style="position: sticky; right: 0; background: white; z-index: 1; padding-right: 5px;">
            <p-splitButton *ngIf="shipRequest.isOpenStatus" class="bg-none actionbtn" [style]="{float:'right'}" outlined
              label="Ship Now" (onClick)="callParentShip(shipRequest)" styleClass="p-button-text" appendTo="body"
              [model]="shipRequest.items" text></p-splitButton>
  
            <p-splitButton *ngIf="!shipRequest.isOpenStatus" class="bg-none actionbtn" [style]="{float:'right'}" outlined
              label="View Now" (onClick)="callParentView(shipRequest)" styleClass="p-button-text" appendTo="body"
              [model]="shipRequest.items" text></p-splitButton>
  
          </td>
        </tr>
      </ng-template>
    </p-table>
  
  
  
    <app-paginator [page]="page" [rows]="rows" [totalRecords]="totalRecords" (pageChange)="onPageChange($event)">
    </app-paginator>
  </div>