import { Component, OnInit, ViewChild,Input, OnChanges, SimpleChanges,ElementRef  } from '@angular/core';
import { ShipNowService } from '../../../services/ship-now/ship-now.service';
import { Table } from 'primeng/table';
import { ConfirmationService, MessageService } from 'primeng/api';
import { StorageService } from '../../../services/storage.service';
import { GetAllDataService } from 'src/app/services/ship-now/get-all-data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'src/app/services/ship-now/shared.service';
import { ERPSHIPService } from 'src/app/erpship.service';
import { DialogService } from 'primeng/dynamicdialog';
import { PackInfoComponent } from './pack-info/pack-info.component';
import { ConfirmPopupComponent } from 'src/app/popup/confirm-popup/confirm-popup.component';
import { WarningPopupComponent } from 'src/app/popup/warning-popup/warning-popup.component';
import { OverlayPanel } from 'primeng/overlaypanel';
import { CookieService } from 'ngx-cookie-service';

interface Product {
  product: string;
  totalQuantity: number;
  partialQuantity: number;
  balanceQuantity: number;
}

interface PackedItem {
  HU: string;
  Weight: string;
  WeightUnits: string;
  dimension: string;
  dimensionUnits: string;
  autopack : boolean;
  tracking: string;
  ItemsInfo: Array<{
    product: string;
    partialQuantity: number;
  }>;
  id: number;
}

@Component({
  selector: 'app-ship-now-pack',
  templateUrl: './ship-now-pack.component.html',
  styleUrls: ['./ship-now-pack.component.scss'],
  providers: [DialogService]
})

export class ShipNowPackComponent implements OnInit, OnChanges {
  @ViewChild('packText', { static: false }) packTextElement!: ElementRef;
  @ViewChild(ConfirmPopupComponent) confirmPopup: ConfirmPopupComponent;
  @ViewChild(WarningPopupComponent) warningPopup: WarningPopupComponent;
  @Input() shipment!: any;
  @Input() refreshFlag: boolean = false;
  @ViewChild('adddimension') dimensionOver: OverlayPanel;


  dimensionForm={"LocationId":"","Name":"","Length": null,"Width": null,"Height":null,"Volume":null,"dimensionvalue":"","IsActive":true,"id":null}
  dimensionNameFlag: boolean;

  dialogColumns = [
    {
      header: 'Product Code',
      fieldName: 'ProductNo',
    },
    {
      header: 'Description',
      fieldName: 'Description',
    },
    {
      header: 'EPC-RFID No',
      fieldName: 'epc',
    },
    {
      header: 'Quantity',
      fieldName: 'Quantity',
    }
  ]

  selectedProductColumns=[
    {header:'Product',fieldName: 'product'},
    {header:'Description',fieldName: 'description'},
    {header:'Quantity',fieldName: 'partialQuantity'}
  ]

  package1: any; // or a default value
  weight1: any; // or a default value
  position: string = 'center';
  products: any[] = [];
  displayDialog: boolean = false;
  displayDialogbox: boolean = false;
  productDetails: any = null;
  packDetails: any[] = [];
  secondTableData: any[] = [];
  packedItems: any[] = [];
  newPackedItems :any[] = [];
  displayProductDetails: boolean = false;
  selectedProduct: any = {};
  // showOverlay: boolean = false;
  @ViewChild('dt') dt: Table | undefined;
  productSelectedIndex : any;
  
  displayedArray: any[] = []; // Data to display in the table
  page: number = 0; // Current page index
  rows = 50;
  totalRecords = 0;
  locationData :any;
  locationId: any;
  selectedProductRow=0;
  selectedTableArray:any;
  constructor(private ShipNowService: ShipNowService,
                private cookieService: CookieService,
                private storageService:StorageService,
                private dataService:GetAllDataService,
                private fb: FormBuilder,
                private sharedService : SharedService,
                private erpService : ERPSHIPService, 
                private dialogService: DialogService) { }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.shipment);
    if(this.autopackFlag)
    {
      return;
    }
    if (changes['shipment'] && this.shipment) {
    // Clear existing data
    this.products = [];
    this.packedItems = [];
    // Process shipment items
    const productItem = this.shipment.Items || [];
    for (let i = 0; i < productItem.length; i++) {
      const newProduct = {
        product: productItem[i]['ProductNo'] ?? '',
        description: productItem[i]['Description'] ?? '',
        epc: productItem[i]['epc'] ?? '',
        dimension : productItem[i]['Dimension'],
        totalQuantity: productItem[i]['Quantity'] ?? 0,
        balanceQuantity: productItem[i]['Quantity'] ?? 0,
        partialQuantity: productItem[i]['Partial'] ?? 0,
        s: false,
        isDG: productItem[i]['IsDG'] ?? false,
      };
      this.products.push(newProduct);
    }
    this.addProductRow();

    // Process shipment packages
    const packItem = this.shipment.Packages || [];
    for (let i = 0; i < packItem.length; i++) {
      const packProduct = packItem[i].ItemsInfo || [];
      const packProductList = [];

      for (let j = 0; j < packProduct.length; j++) {
        const newPack = {
          product: packProduct[j]['ProductNo'] ?? '',
          description: packProduct[j]['Description'] ?? '',
          totalQuantity: packProduct[j]['Quantity'] ?? 0,
          balanceQuantity: packProduct[j]['Balance'] ?? 0,
          partialQuantity: packProduct[j]['Partial'] ?? 0,
          isSerial: packProduct[j]['IsSerial'] ?? false,
          isDG: packProduct[j]['IsDG'] ?? false,
        };
        packProductList.push(newPack);
      }

      const newPack = {
        HU: packItem[i]['HU'] ?? '',
        Weight: packItem[i]['Weight'] ?? 0,
        WeightUnits: packItem[i]['Weightunits'] ?? 0,
        dimension: packItem[i]['Dimension'] ?? 0,
        dimensionUnits: packItem[i]['DimensionUnits'] ?? 0,
        class: '',
        tracking: packItem[i]['TrackingNumber']?? '',
        ItemsInfo: packProductList,
      };
      this.packedItems.push(newPack);
    }
    this.dataService.setPackingInfoData(this.shipment.Packages);
  }
    if (changes['refreshFlag'] && changes['refreshFlag'].currentValue) {
      this.products=[]
      this.packedItems=[]
      this.productDetails=[]
      this.ngOnInit()
    }
  
  }
  autopackFlag : boolean = false;
  autoscanFlag : boolean = false;
  form: FormGroup;
  ngOnInit(): void {
    this.retrieveStoredItem();
    this.locationId = JSON.parse(this.cookieService.get('role'))?.LocationId;

    this.sharedService.getShipmentData().subscribe(data => {
      if (data) {
        this.shipment = data;
        let response = this.shipment.Packages;
        for (let i = 0; i < response.length; i++) {
           if(this.packedItems.length > 0)
           {
            this.packedItems = [];
           }
          // Calculate total weight for the current package
          const totalWeight = response[i].ItemsInfo.reduce((sum, item) => {
            const unitWeight = parseFloat(item.UnitWeight || '0');
            return sum + unitWeight;
          }, 0);
          this.packedItems.push({
            HU: response[i].HU,
            Weight: totalWeight.toFixed(2) || '',  // Correct field for weight
            WeightUnits: response[i].Weightunits,
            Dimension: response[i].Dimension || '',  // Correct field for dimension
            DimensionUnits: response[i].DimensionUnits,
            Class: '', // Adjust as needed
            Tracking: response[i].Tracking || '',  // Ensure you have this field if needed
            ItemsInfo: response[i].ItemsInfo,
            Id: response[i].id  // Store the ID for future reference
          });
        }
        
        this.products = [];
        this.addProductRow();
      }
    });
    this.sharedService.getShipmentData().subscribe(data => {
      if (data) {
       this.autopackFlag = data;
      }
    });

      this.addProductRow(); // Automatically add one row on component initialization
    this.form = this.fb.group({
      scanProduct: [''],
      quantity: [''],
      packingOptions: [''],
      dimensions: [''],
      length: [''],
      width: [''],
      height: [''],
      package: ['1'],
      weight: ['1']
    });
    
  }
  
  retrieveStoredItem(value:any=null): void {
    // const storedItem = this.storageService.getItem<any>('data');
    // if (storedItem) {
    //   this.Dimensions = storedItem.dimensions;
    // }
    this.erpService.getLocationMasterData().subscribe((data) => {
      this.locationData = data;
      this.Dimensions = data.dimensions;
      if(this.selectedTableArray&&this.selectedTableArray=='products'){
      this.products[this.selectedProductRow]['dimension']=value
      }else if(this.selectedTableArray&&this.selectedTableArray=='packedItems'){
      this.packedItems[this.selectedProductRow]['dimension']=value
      }
    });
  }


  addProductRow(): void {
    const newProduct = {
      product: '',
      description: '',
      totalQuantity: 0,
      balanceQuantity: 0,
      partialQuantity: 0,
      s: false,
      isDG: false,
    };
    this.products.push(newProduct);
  }
  packingOptions = [
    { label: 'Packing', value: 'Packing' },
    { label: 'Box', value: 'box' },
    { label: 'Bag', value: 'bag' }
  ];
  Dimensions:any = [];
  dimensionsOptions = [
    { label: 'Small', value: 'small' },
    { label: 'Medium', value: 'medium' },
    { label: 'Large', value: 'large' }
  ];
  selectedDimensions: string = "";
  selectedPacking: string = "";

  // deleteProduct(index: number) {
  //   if (this.products.length === 1) {
  //     // Show a warning message and prevent deletion
  //     this.messageService.add({
  //       severity: 'warn',
  //       summary: 'Cannot Delete',
  //       detail: 'Failed to delete.At least one item is required.',
  //       life: 3000
  //     });
  //   } else {
  //     this.products.splice(index, 1);
  //   }
  // }

  deleteProduct(index: number) {
    if (this.products.length === 1) {
      const product = this.products[0];
    
      // Check if the product has no data (all fields are empty or zero)
      if (!product.product && !product.description && product.totalQuantity === 0 && product.balanceQuantity === 0 && product.partialQuantity === 0) {
        // Show a warning message if there is no data
        this.warningPopup.showWarning('Failed to delete. At least one item is required.');
      } else {
        this.confirmPopup.showConfirm(
          'Are you sure you want to delete this product?',
          ()=>{
            // Proceed with the deletion if confirmed
            product.product = '';
            product.description = '';
            product.totalQuantity = 0;
            product.balanceQuantity = 0;
            product.partialQuantity = 0;
            product.isSerial = false;
            product.isDG = false;
          },
          ()=>{
            //handle if neccessary
          },
        );
        // this.confirmationService.confirm({
          
        //   icon: 'pi pi-info-circle',
        //   message: `
        //   <div>
        //     <p>Are you sure you want to delete this product?</p>
        //     <hr />
        //   </div>`,
        //   header: 'Confirmation',
        //   acceptButtonStyleClass: 'p-button-danger p-button-text',
        //   rejectButtonStyleClass: 'p-button-text p-button-text',
        //   acceptIcon: 'none',
        //   rejectIcon: 'none',
        //   accept: () => {
        //     // Proceed with the deletion if confirmed
        //     product.product = '';
        //     product.description = '';
        //     product.totalQuantity = 0;
        //     product.balanceQuantity = 0;
        //     product.partialQuantity = 0;
        //     product.isSerial = false;
        //     product.isDG = false;
        //     // this.messageService.add({
        //     //   severity: 'success',
        //     //   summary: 'Deleted',
        //     //   detail: 'Product deleted successfully.',
        //     //   life: 3000
        //     // });
        //   },
        //   reject: () => {
        //     // Handle rejection if necessary
        //   },
        //   key: 'positionDialog'
        // });
        // Proceed with the deletion or other logic if data exists
      }
    }
    else {
      this.confirmPopup.showConfirm(
        'Are you sure you want to delete this product?',
        ()=>{
          // Proceed with the deletion if confirmed
          this.products.splice(index, 1);
          //handle success message if neccessary
        },
        ()=>{
          //handle if neccessary
        }
      );
      // this.confirmationService.confirm({
      //   icon: 'pi pi-info-circle',
      //   message: `
      //   <div>
      //     <p>Are you sure you want to delete this product?</p>
      //     <hr />
      //   </div>`,
      //   header: 'Confirmation',
      //   acceptButtonStyleClass: 'p-button-danger p-button-text',
      //   rejectButtonStyleClass: 'p-button-text p-button-text',
      //   acceptIcon: 'none',
      //   rejectIcon: 'none',
      //   accept: () => {
      //     // Proceed with the deletion if confirmed
      //     this.products.splice(index, 1);
      //     // this.messageService.add({
      //     //   severity: 'success',
      //     //   summary: 'Deleted',
      //     //   detail: 'Product deleted successfully.',
      //     //   life: 3000
      //     // });
      //   },
      //   reject: () => {
      //     // Handle rejection if necessary
      //   },
      //   key: 'positionDialog'
      // });
    }
  }
  

  deleteProductHU(i: number, hu: number | string) {
    if (!this.shipment || !this.shipment.Packages) {
        return;
    }

    // Find the package by HU
    const index = this.shipment.Packages.findIndex(pkg => pkg.HU == hu);
    if (index !== -1) {
        // Extract ItemsInfo from the package before deletion
        const itemsInfo = this.shipment.Packages[index].ItemsInfo || [];
        // Remove from packedItems
        const packedItemIndex = this.packedItems.findIndex(item => item.HU === hu);
        if (packedItemIndex !== -1) {
            this.packedItems.splice(packedItemIndex, 1);
        } else {
        }

        // Remove the package from the array by creating a new array reference
        this.shipment.Packages = [
            ...this.shipment.Packages.slice(0, index),
            ...this.shipment.Packages.slice(index + 1)
        ];

        // Bind ItemsInfo data to this.products
        this.bindItemsInfoToProducts(itemsInfo);

        this.onInputChange();
        this.dataService.setPackingInfoData(this.shipment.Packages);
    } else {
    }
}

// Method to bind ItemsInfo to this.products
bindItemsInfoToProducts(itemsInfo: any[]): void {
    itemsInfo.forEach(item => {
        const newProduct = {
            product: item.ProductNo || '',
            description: item.Description || '',
            totalQuantity: item.Quantity || 0,
            balanceQuantity: item.Quantity || 0,
            partialQuantity: item.Partial || 0,
            s: item.s || false,
            isDG: item.isDG || false,
        };
        this.products.push(newProduct);
    });

}


deleteAll() {
  if (this.products.length <= 1) {
    // Show error message if trying to delete when there is only one row
    this.warningPopup.showWarning('Failed to delete items. At least one item is required.');
  } else {
    this.confirmPopup.showConfirm(
      'Are you sure you want to delete all the product?',
      ()=>{
        this.products = [];
        this.addProductRow();
        //handle if required alert is neccessary.
      }
    )
    // this.confirmationService.confirm({
    //   icon: 'pi pi-info-circle',
    //   message: `
    //   <div>
    //     <p>Are you sure you want to delete all the product?</p>
    //     <hr />
    //   </div>`,
    //   header: 'Confirmation',
    //   acceptButtonStyleClass: 'p-button-danger p-button-text',
    //   rejectButtonStyleClass: 'p-button-text p-button-text',
    //   acceptIcon: 'none',
    //   rejectIcon: 'none',
    //   accept: () => {
    //     // Proceed with the deletion if confirmed
    //     this.products = [];
    //     this.addProductRow();
    //     // this.messageService.add({
    //     //   severity: 'success',
    //     //   summary: 'Deleted',
    //     //   detail: 'Product deleted successfully.',
    //     //   life: 3000
    //     // });
    //   },
    //   reject: () => {
    //     // Handle rejection if necessary
    //   },
    //   key: 'positionDialog'
    // });
    // Clear all products and add one new row

  }
}

deleteAllHU()
{
  if (this.packedItems.length === 0) {
    // Show error message if trying to delete when there is only one row
    this.warningPopup.showWarning('Nothing to Delete.');
  } else {
    this.confirmPopup.showConfirm(
      'Are you sure you want to delete this item?',
      () => {
        // Proceed with the deletion if confirmed
        // this.packedItems = [];
        let item:any = this.packedItems
     ;
        console.log(item);
        const checkProducts = this.products.filter(x => x.product !== '' && x.description !== '');
        const checkProductExistFlag = checkProducts.length === 0;
        if(!item.autopack)
        {
          if (checkProductExistFlag) {
            // If no valid products exist, map the new item to products
            this.mapDataToProducts(this.packedItems);
          } else {
            // Process each item in ItemsInfo
            item?.[0]?.ItemsInfo.forEach(info => {
              const product = this.products.find(p => p.product === info.product);
        
              if (product) {
                // Update quantities for an existing product
                product.balanceQuantity += info.partialQuantity;
                product.partialQuantity -= info.partialQuantity;
                // Ensure balanceQuantity does not exceed totalQuantity
                product.balanceQuantity = Math.min(product.balanceQuantity, product.totalQuantity);
                // Ensure partialQuantity does not go below zero
                product.partialQuantity = Math.max(product.partialQuantity, 0);
                // Map the updated product data
                this.mapDataToProducts(product);
              } else {
                // If the product is not found, map the new item to products
                this.mapDataToProducts(item);
              }
            });
          }
        }
        // Remove the processed item from packedItems and update data service
        this.packedItems = this.packedItems.filter(packedItem => packedItem !== item);
        this.dataService.setPackingInfoData(this.packedItems);
        this.packedItems = [];
      },
        // this.alertService.showInfo('Deleted', 'All items deleted successfully.');
      () => {
        // this.alertService.showInfo('Cancelled', 'Delete action cancelled.');
      }
    );
    // this.confirmationService.confirm({

    //   icon: 'pi pi-info-circle',
    //   message: `
    //   <div>
    //     <p>Are you sure you want to delete all the handling units?</p>
    //     <hr />
    //   </div>`,
    //   header: 'Confirmation',
    //   acceptButtonStyleClass: 'p-button-danger p-button-text',
    //   rejectButtonStyleClass: 'p-button-text p-button-text',
    //   acceptIcon: 'none',
    //   rejectIcon: 'none',
    //   accept: () => {
    //     // Proceed with the deletion if confirmed
    //     this.packedItems = [];
    //     // this.messageService.add({
    //     //   severity: 'success',
    //     //   summary: 'Deleted',
    //     //   detail: 'Product deleted successfully.',
    //     //   life: 3000
    //     // });
    //   },
    //   reject: () => {
    //     // Handle rejection if necessary
    //   },
    //   key: 'positionDialog'
    // });
    // Clear all products and add one new row

  }
}
  selectedDimension: any;


  dimensionOptions = [
    { label: 'Dimension Option 1', value: 1 },
    { label: 'Dimension Option 2', value: 2 },
    // Add more dimension options as needed
  ];

  searchProducts(): void {
    // this.productSelectedIndex = i;
    this.ShipNowService.getProducts().subscribe((data) => {
      this.productDetails = data;
      this.displayDialog = true;
      // this.showOverlay=true;
      this.updateDisplayedData()
    });
  }
  closeDialog(): void {
    this.displayDialog = false;
    this.productDetails = null;
    // this.showOverlay=false;
  }
  
  close(){
    this.displayDialogbox = false;
  }

  applyFilterGlobal(event: any) {
    if (this.dt) {
      this.dt.filterGlobal(event.target.value, 'contains');
    }
  }
  
  onInputChange() {
    this.dataService.setPackingInfoData(this.packedItems);
    console.log(this.products)
  }
    
  
  packAllItems(): void {
    const dimensionMap = this.Dimensions.reduce((acc, dimension) => {
      acc[dimension.Name] = dimension.dimensionvalue;
      return acc;
    }, {});
    // Prepare valid products by updating partialQuantity to balanceQuantity
    const validProducts = this.products
      .filter(product => 
        product.product &&
        product.totalQuantity > 0 &&
        product.balanceQuantity >= 0 &&
        product.partialQuantity >= 0
      )
      .map(product => {
        const dimensionvalue = dimensionMap[product.dimension] || 'Unknown';
        // Update partialQuantity to balanceQuantity for payload
        return {
          ...product,
          partialQuantity: product.balanceQuantity,
          dimensionvalue: dimensionvalue
        };
      });
      console.log(validProducts);
    if (validProducts.length === 0) {
      this.warningPopup.showWarning('Please add partial quantity for at least one valid product.');
      // this.messageService.add({
      //   severity: 'warn',
      //   summary: 'Validation Error',
      //   detail: 'Please add partial quantity for at least one valid product.',
      //   life: 3000
      // });
      return;
    }

  
    // Prepare the payload
    const payload = {
      ItemsInfo: validProducts,
      SpecialServices: {},
      Weightunits: '',
      DimensionUnits: '',
      Sno: 1,
      IsInternal: true,
    };
  
    this.ShipNowService.packAllItems(payload).subscribe((response) => {
      this.sharedService.updateinternationalHU(response);
        // Process each item in the response
        response.ItemsInfo.forEach(item => {
          const product = this.products.find(p => p.product === item.product);
  
          if (product) {
            if (!product.deducted) {
              product.deducted = true; // Mark as deducted for future saves
            } else if (product.balanceQuantity >= item.partialQuantity) {
              product.balanceQuantity -= item.partialQuantity;
            } else {
              this.warningPopup.showWarning('Balance quantity cannot be negative.');
              // this.messageService.add({
              //   severity: 'error',
              //   summary: 'Error',
              //   detail: 'Balance quantity cannot be negative.',
              //   life: 3000
              // });
              return; // Stop further processing if any product is invalid
            }
          }
        });
  
        // Check if there's already a packed item with the same ID
        let existingPackedItem = this.packedItems.find(packedItem => packedItem.HU === response.HU);
        this.products.forEach(product => {
          product.balanceQuantity = 0; // Reset balance quantity after packing
          product.partialQuantity = 0; // Reset partial quantity
        });
        let productFilter = this.products.filter(x => x.product != '' && x.totalQuantity != '0');
        // Remove the filtered items from the original array
        this.products = this.products.filter(x => !(
          productFilter.some(filteredItem =>
              filteredItem.product === x.product && filteredItem.totalQuantity === x.totalQuantity
          )
        ));
        if (existingPackedItem) {
          // Merge ItemsInfo for existing packed item
          response.ItemsInfo.forEach(item => existingPackedItem.ItemsInfo.push(item));
        } else {
          // Add a new packed item if it doesn't exist
          this.packedItems.push({
            HU: response.HU,
            Weight: response.Weight || '',
            WeightUnits: response.Weightunits,
            dimension: response.Dimension || '',
            dimensionUnits: response.DimensionUnits,
            class: '', // You may need to adjust this field if needed
            tracking: response.tracking || '',
            ItemsInfo: response.ItemsInfo,
            id: response.id  // Store the ID for future reference
          });
          this.dataService.setPackingInfoData(this.packedItems);
        }
      this.dataService.setPackingInfoData(this.packedItems);
      if (this.products.length === 0) {
        for (let i = 0; i < 1; i++) {
          this.products.push({
            product: '',
            description: '',
            totalQuantity: 0,
            balanceQuantity: 0,
            partialQuantity: 0,
            isSerial: false,
            isDG: false,
          });
        }
      }
      // Show success toast
    //  this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Items packed successfully!', life: 3000 });
    }, (error) => {
      // Show error toast
      this.warningPopup.showWarning('Failed to pack items. Please try again.');
      // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to pack items. Please try again.', life: 3000 });
    });
  }
  
  updateArray(originalArray, itemToRemove, newItem) {
    // Remove item based on criteria (e.g., matching 'product' and 'description')
    let updatedArray = originalArray.filter(item => 
        !(item.product === itemToRemove.product && item.description === itemToRemove.description)
    );
    
    // Add new item
    updatedArray.push(newItem);
    
    return updatedArray;
}
  

  removeItem(index: number): void {
    this.secondTableData.splice(index, 1);
  }

  deletePackDetail(index: number): void {
    this.packDetails.splice(index, 1);
  }
  setFocus(i)
  {
    
  }
  // deleteItem(item: any): void {
  //   console.log(item);
  //   // Prepare the payload for the DELETE request
  //   const deletePayload = {
  //     hideAddressBlock: false,
  //     hideCarrierBlock: true,
  //     defaultWorkCentre: "Y921",
  //     defaultStorageBin: "GI-YDO1",
  //     defaultwarehouse: "1710",
  //     user: "venkat",
  //     id: 10,  // Ensure this ID is dynamically set if needed
  //     DocumntNo: "80000702",  // Ensure this document number is dynamically set if needed
  //     HU: item.hu,  // Adjust as per your data structure
  //     StockItemUUID: item.stockItemUUID,  // Adjust as per your data structure
  //     Quantity: item.quantity || 1,  // Default quantity if not provided
  //     AlternativeUnit: "PC",
  //     HUCreateCount: 1,
  //     DeleteQuantity: item.deleteQuantity || 6  // Default delete quantity if not provided
  //   };

  //   this.ShipNowService.deleteItems(deletePayload).subscribe((data) => {
  //     const product = this.products.find(p => p.product === item.class);
  //     console.log(product);
  //     this.mapDataToProducts(item);
  //     if (product) {
  //       product.partialQuantity = 0;
  //       // Update the balance quantity
  //       product.balanceQuantity = (product.totalQuantity - (product.partialQuantity || 0));

  //       // Reset the partial quantity
  //     }

  //     // Remove the item from packedItems
  //     this.packedItems = this.packedItems.filter(p => p !== item);

  //     // Optionally show a notification
  //     this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Item deleted successfully!' });
  //   }, (error) => {
  //     // Show error toast
  //     this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to pack items. Please try again.', life: 3000 });
  //   });

  // }

  selectProduct(product: any, i: number): void {
    console.log(product);
    // this.showOverlay = false;
        // Extract relevant details from the selected product
    const mappedProduct = {
      product: product.ProductNo || '', // Map ProductNo
      description: product.Description || '', // Map Description
      totalQuantity: parseInt(product.Quantity, 10) || 0, // Assuming 'Quantity' is part of the data structure
      balanceQuantity: parseInt(product.Quantity, 10) || 0, // Balance quantity is not available in the JSON, set to 0
      partialQuantity: 0, // Initialize partial quantity as 0
      isSerial: product.IsSerial || false, // Map IsSerial
      isDG: product.IsDG || false, // Map IsDG
      epc: product.epc
    };
    // Check if the product is not empty and index i is valid
    this.products.push(mappedProduct);
    this.products = this.products.filter(x => x.product !== '');
    // Close the dialog
    this.displayDialog = false;
  }
  

  getProducts(item)
  {
    this.selectedProduct = item;
    this.displayProductDetails = true; 
    // this.showOverlay=true;

  }
  getScaleweight(rowIndex: number){

    this.ShipNowService.triggerMeasurement().subscribe(
      (response) => {
        // Step 1: Convert raw response to string if necessary
      const responseStr = response.toString();

      // Step 2: Remove special characters (STX, ETX, CR, LF)
      const cleanedResponse = responseStr.replace(/<STX>|<ETX>|<CR>|<LF>/g, '');

      try {
        // Step 3: Parse the cleaned response as JSON
        const jsonResponse = JSON.parse(cleanedResponse);

        // Step 4: Extract the measurement data (weight, dimensions, etc.)
        const measurement = jsonResponse.measurement;

        // Append the extracted data to the grid
        const weight = measurement.weight;
        const length = measurement.length;
        const width = measurement.width;
        const height = measurement.height;


        // Update the specific row in the gridData array
        this.packedItems[rowIndex].weight = weight;
        this.packedItems[rowIndex].weight_units = measurement.weight_units;
        this.packedItems[rowIndex].dimensions = length + "X" + width + "X" + height;
        this.packedItems[rowIndex].dimension_units = measurement.dimensional_units;


        this.dataService.setPackingInfoData(this.packedItems);

        // Step 5: Update the grid data array
        //this.gridData.push({ weight, length, width, height });
      } catch (error) {
        console.error('Error parsing Cubiscan response:', error);
      }
        console.log('Measurement triggered:', response);
      },
      (error) => {
        console.error('Error triggering measurement:', error);
      }
    );
  }
  productDetailsClose(){
    // this.showOverlay=false;

  }
  manageRows(index: number): void {
    const product = this.products[index]; // Access the product object
    const productName = product.product;  // Access the product name
    const description = product.description; // Access the description
  
    // Check if the row is not empty
    if (productName || description || product.totalQuantity > 0) {
      // If the last row is being edited, add a new empty row
      if (index === this.products.length - 1) {
        this.addProductRow();
      }
    } else if (index < this.products.length - 1) {
      // Remove the row if it's empty and not the last one
      this.products.splice(index, 1);
    }
  
    // Validate product name length
    // if (productName.length > 30) {
    //   this.messageService.add({
    //     severity: 'error',
    //     summary: 'Validation Error',
    //     detail: 'Product name cannot exceed 10 characters.',
    //     life: 3000
    //   });
    //   this.products[index].product = productName.substring(0, 10); // Truncate to 10 characters
    // }
  
    // // Validate description length
    // if (description.length > 200) {
    //   this.messageService.add({
    //     severity: 'error',
    //     summary: 'Validation Error',
    //     detail: 'Description cannot exceed 30 characters.',
    //     life: 3000
    //   });
    //   this.products[index].description = description.substring(0, 30); // Truncate to 30 characters
    // }
  }
  

    // Method to calculate the total balance quantity
    getTotalBalanceQuantity(): number {
      return this.products.reduce((total, product) => {
        return total + (product.balanceQuantity || 0);
      }, 0);
    }
    autoPack() {

        let weight:any = this.form.get('weight').value;
        let packageData : any = this.form.get('package').value;
        if(packageData === "" ||packageData === undefined || packageData === null )
          {
            this.warningPopup.showWarning('Please enter No of Packages.');
            // this.messageService.add({
            //   severity: 'error',
            //   summary: 'Package Quantity Required',
            //   detail: 'Please enter No of Packages.',
            //   life: 3000
            // });
            return;
          }
          
        if(weight === "" ||weight === undefined || weight === null )
        {
          this.warningPopup.showWarning('Please enter the weight.');
          // this.messageService.add({
          //   severity: 'error',
          //   summary: 'Weight Required',
          //   detail: 'Please enter the weight.',
          //   life: 3000
          // });
          return;
        }
      // Get form values
      const values = this.form.value;    
      // Create an array of ItemsInfo based on the form values
      const itemsInfo = [];
      const numberOfPackages = Number(values.package) || 1; // Number of packages
      const weightPerPackage = Number(values.weight) || 0; // Weight per package
    
      for (let i = 0; i < 1; i++) {
        itemsInfo.push({
          product: '',
          description: '',
          totalQuantity: 1, // Default values or calculated as needed
          balanceQuantity: 1,
          partialQuantity: 1,
          dimension :values.dimensions.Name,
          Weight : values.weight,
          isDG: false,
          sNo : 1
        });
      }
    
      // Construct the payload
      const payload = {
        ItemsInfo: itemsInfo,
        SpecialServices: {}, // Assuming empty object for SpecialServices
        Weightunits: values.weightUnits ?? '', // Set Weightunits from form or default to empty string
        DimensionUnits: values.dimensionUnits ?? '', // Set DimensionUnits from form or default to empty string
        Sno: values.Sno ?? 1, // Default to 1 if not provided
        IsInternal: values.IsInternal ?? true, // Default to true if not provided
        Weight: itemsInfo?.[0]?.Weight, // Total weight based on number of packages
        Dimension: values.dimensions?.Name ?? '', // Dimension from dropdown or default
        AutoPack: true, // Always set to true based on your requirement
        NoofPackages: numberOfPackages // Number of packages
      };
      this.ShipNowService.autoPack(payload).subscribe((data) => {
      data.forEach((item) => {
        const packedItem = {
          HU: item.HU, // Use HU from the response
          Weight: item.Weight, // Use Weight from the response
          weightUnits: item.Weightunits ?? '', // Use Weightunits from the response or default to empty
          dimension: item.Dimension, // Use Dimension from the response
          dimensionUnits: item.DimensionUnits ?? '', // Use DimensionUnits from the response or default to empty
          class: '', // Add additional fields as needed
          tracking: '',
          autopack : item.AutoPack,
          ItemsInfo: item.ItemsInfo, // Use ItemsInfo directly from the response
        };
    
        // Push the transformed item into the packedItems array
        this.packedItems.push(packedItem);
      });
          });
      // Optionally, add new item to packedItems if needed
      // for (let i = 0; i < numberOfPackages; i++) {
      //   this.packedItems.push({
      //     HU: values.Sno ?? 1,
      //     Weight: weightPerPackage,
      //     weightUnits: values.weightUnits ?? '', // Default or calculated as needed
      //     dimension: values.dimensions?.Name,
      //     dimensionUnits: values.dimensionUnits ?? '', // Default or calculated as needed
      //     class: '',
      //     tracking: '',
      //     ItemsInfo: itemsInfo // Attach ItemsInfo to each packed item
      //   });
      // }
      // console.log(this.packedItems);
    }
    
    onPackageChange(value: string) {
    }
    
    onWeightChange(value: string) {
    }
    
  // Update the displayed data based on the current page
  updateDisplayedData() {
    const start = this.page * this.rows;
    const end = Math.min(this.productDetails.length, start + this.rows);
    this.displayedArray = this.productDetails.slice(start, end);
    this.totalRecords=this.productDetails.length
  }

  // Handle page change event from paginator
  onPageChange(page: number) {
    this.page = page;
    this.updateDisplayedData();
  }

  // Function to map and add products to the first index of this.products array
mapDataToProducts(data: any): void {
  const itemsInfo = data?.[0]?.ItemsInfo || [];

  // Iterate over the items and add or update products
  itemsInfo.forEach(item => {
      // Check if the product already exists in the array
      const existingProductIndex = this.products.findIndex(p => p.product === item.product);

      if (existingProductIndex !== -1) {
          // Update existing product
          this.products[existingProductIndex] = {
              ...this.products[existingProductIndex],
              ...item
          };
      } else {
          // Add new product at the first index
          this.products.unshift({
            product: item.product,
            description: item.description,
            epc : item.epc,
            dimension : item.dimension,
            totalQuantity: parseInt(item.totalQuantity, 10), // Ensure it's a number
            balanceQuantity: item.partialQuantity,
            partialQuantity: 0, // Ensure it's a number
            s: item.s,
            isDG: item.isDG
        });
      }
  });
}
  getFormValues() {
  return this.shipment
  }

  updateBalanceAfterPacking(product: Product, packedQuantity: number): void {
    // Update the balance quantity after packing
    product.balanceQuantity = product.balanceQuantity - packedQuantity;
    // Ensure balance is not negative
    if (product.balanceQuantity < 0) {
      product.balanceQuantity = 0;
      this.warningPopup.showWarning('Balance quantity cannot be negative.');
      // this.messageService.add({
      //   severity: 'error',
      //   summary: 'Balance Error',
      //   detail: 'Balance quantity cannot be negative.',
      //   life: 3000
      // });
    }
  }
  
  packItem(): void {
    const dimensionMap = this.Dimensions.reduce((acc, dimension) => {
      acc[dimension.Name] = dimension.dimensionvalue;
      return acc;
    }, {});
    const validProducts = this.products
    .filter(product => 
      product.product &&
      product.totalQuantity > 0 &&
      product.balanceQuantity >= 0 &&
      product.partialQuantity > 0
    )
    .map(product => {
      const dimensionvalue = dimensionMap[product.dimension] || 'Unknown';
      // Update partialQuantity to balanceQuantity for payload
      return {
        ...product,
        partialQuantity: product.balanceQuantity,
        dimensionvalue: dimensionvalue
      };
    });
  
    if (validProducts.length === 0) {
      this.warningPopup.showWarning('Please add partial quantity for at least one valid product.');
      // this.messageService.add({
      //   severity: 'warn',
      //   summary: 'Validation Error',
      //   detail: 'Please add partial quantity for at least one valid product.',
      //   life: 3000
      // });
      return;
    }
          // Check if any product has a negative balance quantity
    // Check if any product has a partial quantity greater than the balance quantity
    const hasInvalidPartialQuantity = this.products.some(product => product.partialQuantity > product.balanceQuantity);
    if (hasInvalidPartialQuantity) {
      this.warningPopup.showWarning('Partial quantity cannot be more than the balance quantity. Please correct it before packing.');
      // this.messageService.add({
      //   severity: 'error',
      //   summary: 'Quantity Error',
      //   detail: 'Partial quantity cannot be more than the balance quantity. Please correct it before packing.',
      //   life: 3000
      // });
      return; // Stop execution if any partial quantity is greater than balance quantity
    }
  
    const payload = {
      ItemsInfo: validProducts.map(product => ({
        product: product.product,
        partialQuantity: product.partialQuantity,
        totalQuantity: product.totalQuantity,
        balanceQuantity: product.balanceQuantity,
        description: product.description
      })),
      SpecialServices: {},
      Weightunits: '',
      DimensionUnits: '',
      Sno: 1,
      IsInternal: true,
    };
  
    this.ShipNowService.packItem(payload).subscribe(
      (response) => {
        // Update the products table
        validProducts.forEach(product => {
          this.updateBalanceAfterPacking(product, product.partialQuantity);
          product.partialQuantity = 0; // Reset partial quantity
        });
  
        // Remove products with zero balance from the list
        this.products = this.products.filter(product => product.balanceQuantity > 0);
  
        // Check if there's already a packed item with the same HU
        const existingPackedItem = this.packedItems.find(packedItem => packedItem.HU === response.HU);
  
        if (existingPackedItem) {
          // Merge ItemsInfo for existing packed item
          response.ItemsInfo.forEach(item => existingPackedItem.ItemsInfo.push(item));
        } else {
          // Add a new packed item if it doesn't exist
          this.packedItems.push({
            HU: response.HU,
            Weight: response.Weight || '',
            WeightUnits: response.Weightunits,
            dimension: response.Dimension || '',
            dimensionUnits: response.DimensionUnits,
            tracking: response.tracking || '',
            ItemsInfo: response.ItemsInfo,
            id: response.id
          });
        }
  
        this.dataService.setPackingInfoData(this.packedItems);
        // After all products are packed, create 3 empty rows if the products list is empty
        if (this.products.length === 0) {
          for (let i = 0; i < 1; i++) {
            this.products.push({
              product: '',
              description: '',
              totalQuantity: 0,
              balanceQuantity: 0,
              partialQuantity: 0,
              isSerial: false,
              isDG: false,
            });
          }
        }
      },
      (error) => {
        this.warningPopup.showWarning('Failed to pack items. Please try again.');
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to pack items. Please try again.', life: 3000 });
      }
    );
  }
  
  deleteItem(item: PackedItem): void {
    // Find the most recently added packed item (assuming the last item in packedItems is the most recent)
    // Proceed with deletion of the most recent packed item
    this.confirmPopup.showConfirm(
      'Are you sure you want to delete this packed item?',
      ()=>{
        //Check if there are any existing products with valid product and description
        const checkProducts = this.products.filter(x => x.product !== '' && x.description !== '');
        const checkProductExistFlag = checkProducts.length === 0;
        if(!item.autopack)
        {
          if (checkProductExistFlag) {
            // If no valid products exist, map the new item to products
            this.mapDataToProducts(item);
          } else {
            // Process each item in ItemsInfo
            item.ItemsInfo.forEach(info => {
              const product = this.products.find(p => p.product === info.product);
        
              if (product) {
                // Update quantities for an existing product
                product.balanceQuantity += info.partialQuantity;
                product.partialQuantity -= info.partialQuantity;
                // Ensure balanceQuantity does not exceed totalQuantity
                product.balanceQuantity = Math.min(product.balanceQuantity, product.totalQuantity);
                // Ensure partialQuantity does not go below zero
                product.partialQuantity = Math.max(product.partialQuantity, 0);
                // Map the updated product data
                this.mapDataToProducts(product);
              } else {
                // If the product is not found, map the new item to products
                this.mapDataToProducts(item);
              }
            });
          }
        }
        // Remove the processed item from packedItems and update data service
        this.packedItems = this.packedItems.filter(packedItem => packedItem !== item);
        this.dataService.setPackingInfoData(this.packedItems);
      }
    );
    // this.confirmationService.confirm({
    //   icon: 'pi pi-info-circle',
    //   message: `
    //   <div>
    //     <p>Are you sure you want to delete this packed item?</p>
    //     <hr />
    //   </div>`,
    //   header: 'Confirmation',
    //   acceptButtonStyleClass: 'p-button-danger p-button-text',
    //   rejectButtonStyleClass: 'p-button-text p-button-text',
    //   acceptIcon: 'none',
    //   rejectIcon: 'none',
    //   accept: () => {
    //     // Check if there are any existing products with valid product and description
    //     const checkProducts = this.products.filter(x => x.product !== '' && x.description !== '');
    //     const checkProductExistFlag = checkProducts.length === 0;

    //     if(item.autopack)
    //     {
    //     }
    //     else
    //     {
    //       if (checkProductExistFlag) {
    //         // If no valid products exist, map the new item to products
    //         this.mapDataToProducts(item);
    //       } else {
    //         // Process each item in ItemsInfo
    //         item.ItemsInfo.forEach(info => {
    //           const product = this.products.find(p => p.product === info.product);
        
    //           if (product) {
    //             // Update quantities for an existing product
    //             product.balanceQuantity += info.partialQuantity;
    //             product.partialQuantity -= info.partialQuantity;
        
    //             // Ensure balanceQuantity does not exceed totalQuantity
    //             product.balanceQuantity = Math.min(product.balanceQuantity, product.totalQuantity);
        
    //             // Ensure partialQuantity does not go below zero
    //             product.partialQuantity = Math.max(product.partialQuantity, 0);
        
    //             // Map the updated product data
    //             this.mapDataToProducts(product);
    //           } else {
    //             // If the product is not found, map the new item to products
    //             this.mapDataToProducts(item);
    //           }
    //         });
    //       }
    //     }
      

      
    //     // Remove the processed item from packedItems and update data service
    //     this.packedItems = this.packedItems.filter(packedItem => packedItem !== item);
    //     this.dataService.setPackingInfoData(this.packedItems);
    //   },      
    //   reject: () => {
    //     // Handle rejection, if necessary
    //   },
    //   key: 'positionDialog'    });
  }
  
  updateBalanceQuantity(product: any): void {
    const totalQuantity = product.totalQuantity || 0;
    const balanceQuantity = product.balanceQuantity || 0;
    const partialQuantity = product.partialQuantity || 0;

    // Validate if partial quantity exceeds balance quantity
    if (partialQuantity > balanceQuantity) {
      // Show error toast notification
      this.warningPopup.showWarning('Partial quantity cannot be greater than balance quantity.');
      // this.messageService.add({
      //   severity: 'error',
      //   summary: 'Validation Error',
      //   detail: 'Partial quantity cannot be greater than balance quantity.',
      //   life: 3000
      // });

      // Reset partial quantity or correct it if needed
      product.partialQuantity = balanceQuantity;
    } else {
      // Update balance quantity if partial quantity is valid
      product.balanceQuantity = totalQuantity - partialQuantity;
    }
  }

  openPackInfoDialog(packType: string) {
    const dimensionMap = this.Dimensions.reduce((acc, dimension) => {
      acc[dimension.Name] = dimension.dimensionvalue;
      return acc;
    }, {});
    // Prepare valid products by updating partialQuantity to balanceQuantity
    const validProducts = this.products
      .filter(product => 
        product.product &&
        product.totalQuantity > 0 &&
        product.balanceQuantity >= 0 &&
        product.partialQuantity >= 0
      )
      .map(product => {
        const dimensionvalue = dimensionMap[product.dimension] || 'Unknown';
        // Update partialQuantity to balanceQuantity for payload
        return {
          ...product,
          partialQuantity: product.balanceQuantity,
          dimensionvalue: dimensionvalue
        };
      });
      console.log(validProducts);
    if (validProducts.length === 0) {
      this.warningPopup.showWarning('Please add partial quantity for at least one valid product.');
      // this.messageService.add({
      //   severity: 'warn',
      //   summary: 'Validation Error',
      //   detail: 'Please add partial quantity for at least one valid product.',
      //   life: 3000
      // });
      return;
    }

   
    let data = {
      Dimension: this.form.value.dimensions,
      UnitWeight: this.form.value.weight,
      //products: this.products.filter((item) => item.product && item.totalQuantity > 0 )
      products: validProducts
    }
    const ref = this.dialogService.open(PackInfoComponent, {
      header: "AI Cartonization & Auto Pack",
      width: '90%',
      height: '100%',
      baseZIndex: 1,
      closeOnEscape: false,
      contentStyle: {height: 'calc(100% - 83px)'},
      style: { 'min-width': '600px', 'min-height': '660px'},
      data: data,
    });
    // ref.onClose.subscribe((data) => {
    //   // if(data) {
    //   //   this.packItem();
    //   // }
    // })

    // When the dialog is closed, trigger the packItem() function
    ref.onClose.subscribe((response: any) => {
      if (response && response.pack) {
        if(packType=="Pack"){
          this.packItem(); // Call packItem function after closing the dialog
        }
        else{
          this.packAllItems(); // Call packItem function after closing the dialog
        }
      }
    });
  }

  onRowClick(rowIndex: number): void {
    if (rowIndex === this.products.length - 1 && this.products[rowIndex].product) {
      // If it's the last row, add a new row
      this.addNewRow();
    }
  }
  addNewRow(): void {
    const newRow = {
      sno: this.products.length + 1, // Automatically increment S.No
      product: '',
      description: '',
      totalQuantity: 0,
      balanceQuantity:0,
      partialQuantity:0
    };
    this.products.push(newRow);
  }
  validateInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^[0-9]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }
  saveDimension(){
    this.dimensionNameFlag=false
    this.Dimensions.forEach((item)=>{
      if(item.Name===this.dimensionForm.Name){
        this.dimensionForm.Name=null
        this.dimensionNameFlag=true
      }
    })
    if(!this.dimensionNameFlag){
      this.dimensionForm.LocationId=this.locationId
    const dimesionValue=this.dimensionForm.Length+'x'+this.dimensionForm.Width+'x'+this.dimensionForm.Height
    this.dimensionForm.dimensionvalue=dimesionValue
    this.erpService.saveRecordData('dimensions',this.dimensionForm).subscribe(
      (response) => {
     this.retrieveStoredItem(this.dimensionForm.Name);
      this.dimensionOver.hide();
            })

    }
      }
  
  cancelDimension(){
    this.dimensionOver.hide()
  }
  

  dimensionOnInputChange(field) {
    const value = this.dimensionForm[field];
    if (value < 0 || value > 999 ) {
      this.dimensionForm[field]=null
    }
    this.isdimensionFormValid();
   
  }
  restDimensionForm(){
    this.dimensionForm={"LocationId":"","Name":"","Length": null,"Width": null,"Height":null,"Volume":null,"dimensionvalue":"","IsActive":true,"id":null}

  }
isdimensionFormValid(): boolean {
  return this.dimensionForm.Name && 
         this.dimensionForm.Length !== null && 
         this.dimensionForm.Width !== null && 
         this.dimensionForm.Height !== null;
}
addDimensionOpen(event: Event,i,tableArray){
    if (this.dimensionOver) {
      this.dimensionOver.show(event);
      this.selectedProductRow=i
      this.selectedTableArray=tableArray
}
}
}