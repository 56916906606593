<ng-container *ngIf="(shippingDocuments != undefined && shippingDocuments.length > 0)">

<p-table [value]="shippingDocuments" class="p-datatable-sm mt-3">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 5% !important;">#</th>
        <th>Document Provider</th>
        <th>Document Type</th>
        <th>Description</th>
        <th style="width:25% !important;text-align:center !important;">Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-i="rowIndex">
      <tr>
        <td>{{ i + 1 }}</td>
        <td>{{ product.contentType === 'LABEL' ? 'Label' : 'Report'| titlecase }} </td>
        <td>{{ product.docType | uppercase  }}</td>
        <td>{{ product.contentType | titlecase }}</td>
        <td>
          <span style="display: flex;align-items: center;justify-content: center;">
            <img src="/assets/images/ph_eye-bold.png" class="fa-icon fa-icon-white mr-2" (click)="previewDocument(product.encodedLabel, product.docType,product.contentType)">
            <img src="/assets/images/material-symbols_download.png" class="fa-icon fa-icon-white mr-2" (click)="downloadDocument(product.encodedLabel, product.description, product.docType)">
            <img src="/assets/images/uil_print.png" class="fa-icon fa-icon-white mr-2" (click)="printDocument(product.encodedLabel, product.docType)">
        </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
  
<!-- Dialog for PDF Preview -->
<p-dialog [(visible)]="isPdfDialogVisible" showHeader="false" [modal]="true"
[style]="{'width': '75%'}" class="PackingSlipdialog" styleClass="p-fluid" (onHide)="onPdfDialogHide()">
<div class="sidebar-header">
  <h3>{{contentType}}</h3>
  <button type="button" pButton icon="pi pi-times" (click)="onPdfDialogHide()"
    class="ui-button-secondary ui-button-sm close-button"></button>
</div>
    <div>
      <iframe *ngIf="isPdfDialogVisible" [src]="previewUrl" style="width: 100%; height:90vh"></iframe>
    </div>
  </p-dialog>
  <!-- Dialog for Image Preview -->
  <p-dialog  [(visible)]="isImageDialogVisible" showHeader="false" [modal]="true" 
  [style]="{'width': '25%'}" styleClass="p-fluid" class="labeldialog" (onHide)="onImageDialogHide()">
  <div class="sidebar-header">
    <h3>{{contentType}}</h3>
    <button type="button" pButton icon="pi pi-times" (click)="onImageDialogHide()"
      class="ui-button-secondary ui-button-sm close-button"></button>
  </div> 
  <div style="width: 100%; display: flex; align-items: center; justify-content: center;">
      <img *ngIf="isImageDialogVisible" [src]="previewUrl" style="width: 40vh; padding-top: 5px !important; object-fit: contain;" />
    </div>
  </p-dialog>
</ng-container>
  
  <p class="mt-5" style="text-align: center;" *ngIf="(shippingDocuments != undefined && shippingDocuments.length === 0)">
    <span class="text-center mr-5" style="color: #999;
    font-size: 18px;
    font-weight: 450;
    ">
    No Documents available.
    </span>
    </p>
