import { ShipNowService } from 'src/app/services/ship-now/ship-now.service';

import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ɵsetAllowDuplicateNgModuleIdsForTest,
  ElementRef,
  Output,
  EventEmitter,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { Table } from 'primeng/table';
import { HttpClient, withJsonpSupport } from '@angular/common/http';
import { ERPSHIPService } from 'src/app/erpship.service';
import { MessageService } from 'primeng/api';
import { ShipRequestToShipNowService } from '../../../services/ship-now/shiprequesttoshipnow.service';
import { Observable, forkJoin } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { JsonPipe, Location } from '@angular/common';
import {
  FormGroup,
  FormControl,
  ValidatorFn,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { ThisReceiver } from '@angular/compiler';

import { filter, take } from 'rxjs/operators';
import { HistoryService } from 'src/app/services/ship-now/history.service';
import { GetAllDataService } from 'src/app/services/ship-now/get-all-data.service';
import { WarningPopupComponent } from 'src/app/popup/warning-popup/warning-popup.component';
import { SharedService } from 'src/app/services/ship-now/shared.service';
import { UserDataService } from 'src/app/services/user-data.service';
import {CookieService} from 'ngx-cookie-service';
import { OverlayPanel } from 'primeng/overlaypanel';


@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
})
export class DashboardHeaderComponent implements OnInit {
  // Define an EventEmitter property with the @Output decorator
  @Output() dataEmitter = new EventEmitter<string>();
  @ViewChild('cursorFocus') cursorFocus!: ElementRef;
  @Output() triggerRefresh = new EventEmitter<void>();
  @ViewChild(WarningPopupComponent) warningPopup: WarningPopupComponent;
  showWarning: boolean = false;
  @ViewChild('ot') overlayPanel: OverlayPanel;

  columns = [
    {
      header: 'Requests ID',
      fieldName: 'details.HeaderInfo.DocumentNumber',
    },
    {
      header: 'Ship Date',
      fieldName: 'details.HeaderInfo.ShipDate',
    },
    {
      header: 'Carrier Name',
      fieldName: 'details.CarrierDetails.Carrier',
    },
    {
      header: 'Service Level',
      fieldName: 'details.Packages[0].ItemsInfo[0].Class',
    },
    {
      header: 'Ship To Company',
      fieldName: 'details.ShipTo.COMPANY',
    },
    {
      header: 'Ship To Contact Name',
      fieldName: 'details.ShipTo.CONTACT',
    },
    {
      header: 'Country',
      fieldName: 'details.ShipTo.COUNTRY',
    },
    {
      header: 'Ship To Zipcode',
      fieldName: 'details.ShipTo.ZIPCODE',
    },
    
    {
      header: 'Tracking Number',
      fieldName: 'details.Packages[0].TrackingNumber',
    },
    {
      header: 'Status',
      fieldName: 'details.TrackingStatus',
    }
  ]

  showSidebar: boolean = false;
  //filters
  history_filters: any = {};
  history_filter_xfields: any[] = [];
  activeButton: string = ''; // Track the active button
  shiphistoryloading: boolean = false;
  openorderloading: boolean = false;
  validateAddressloading: boolean = false;

  // filters 

   // Define the form group
   filterForm: FormGroup;
   rowStart = 0; // Starting row index, can be dynamically set
   rowEnd = 50;  // Ending row index, can be dynamically set
   filteredCarrierOptions: any[] = [];  // Filtered Carrier options based on selected location
   filteredERPOptions : any;

   // Dropdown and other options
   locationOptions = [
     { label: 'Location 1', value: 'location1' },
     { label: 'Location 2', value: 'location2' },
     { label: 'Location 3', value: 'location3' }
   ];
   carrierOptions = [];
   statusOptions = [];
  //shipmentLevelSpecialServices
  shipmentLevelSpecialServices: any = {};
  packageLevelSpecialServices: any = {};
  specialServiceData: any = {};

  displayedArray: any[] = []; // Data to display in the table
  page: number = 0; // Current page index
  rows = 50;
  totalRecords = 0;

  //TESTING PURPOSE & DELETE LATAR
  isSSLoded: boolean = true;
  ssdetails: any = {
    holdAtLocationDetail: {
      locationId: '',
      locationContactAndAddress: {
        address: {
          streetLines: ['', ''],
          city: '',
          stateOrProvinceCode: '',
          postalCode: '',
          countryCode: '',
          residential: false,
        },
        contact: {
          personName: '',
          emailAddress: '',
          phoneNumber: '',
          phoneExtension: '',
          companyName: '',
          faxNumber: '',
        },
      },
      locationType: '',
    },
  };

  //------------------------- TESTING PURPORSE END

  //dialog flags
  HistorytableProperties_dialog: boolean = false;

  history_Dialog: boolean = false;

  //history orders

  historyOrders: any[] = [];
  Org_historyOrders: any[] = [];
  selectedHistoryOrder: any;

  //for History
  history_xfields: any[] = [];
  history_xcolumns: any[] = [];
  global_history_xcolumns: any[] = [];

  //  for Location
  xfields: any[] = [];
  xcolumns: any[] = [];

  //
  parameterId = -1;
  saveButtontitle = 'Save';

  //-----------------------------------
  dataSourceList: any[] = [];

  currentSelctedField: any = {};

  clearShip: any = {};
  shipment: any = {};

  //super master data
  Original_LocationMasterData: any;
  LocationMasterData: any;

  isDataLoaded: boolean = false;
  packShipDef: any = {};
  paymentTypes: any[] = [];

  shippingDocuments = [
    { DocumentProvider: '', DocumentType: '', Description: '', Action: '' },
  ];
  shippingCharges = [
    { SNo: '', ChargeDescription: '', Charge: '', Currency: '' },
  ];
  userName : any;
  form: FormGroup;
  today: Date;
  connections: any = [
    { LocationId: '1', Name: 'Manual', IsActive: true, id: 1 },
  ];
  filters: any;
  deliveries: any;
  listVisible: boolean = false;
  menuVisible: boolean = false;
  searchDialogVisible: boolean = false;
  showOverlay: boolean = false;
  filterHistoryOrders: any;
  locationId: any;
  constructor(
    private router: Router,
    private xfservice: ERPSHIPService,
    public messageService: MessageService,
    private route: ActivatedRoute,
    private shipNowService: ShipNowService,
    private HistoryService: HistoryService,
    private dataService: GetAllDataService,
    private rowDataFromShipRequest: ShipRequestToShipNowService,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private userContext : UserDataService,
    private fb: FormBuilder,
    private cookieService: CookieService,

  ) {
    this.xfservice.getLocationMasterData().subscribe((data) => {
      this.xfservice.getPackShipDefaults().subscribe((defaultdata: any) => {
        this.packShipDef = defaultdata;
      });

      data['paymentTypes'] = this.paymentTypes;
      this.LocationMasterData = data;
      this.Original_LocationMasterData = JSON.parse(JSON.stringify(data));

      Object.keys(this.Original_LocationMasterData).forEach((keyName) => {
        let keydata: any = {};
        keydata['name'] = keyName;
        this.dataSourceList.push(keydata);
      });

      this.xfields =
        this.Original_LocationMasterData.LocationTemplate[0].details.fields;
      this.xcolumns = [];
      this.xfields.forEach((item) => {
        this.xcolumns.push(item.fieldName);
      });

      //for historytemplates

      this.history_xfields = this.LocationMasterData['HistoryTable'][0].details;

      this.history_xfields.forEach((xitem) => {
        xitem.fields.forEach((field: any) => {
          this.history_xcolumns.push(xitem.fieldHeader + '.' + field.fieldName);
        });
      });

      var cchip = JSON.stringify(this.shipment);
      this.global_history_xcolumns = this.getKeys(this.shipment);
      this.clearShip = JSON.parse(cchip);
      this.currentSelctedField =
        this.LocationMasterData['FieldModel'][0].details;
      this.route.params.subscribe((params) => {
        this.parameterId = -2;
        this.parameterId = params['id'];
        if (this.parameterId == -2 || this.parameterId == undefined) {
          this.isDataLoaded = true;
        } else if (this.parameterId.toString().startsWith('COPY')) {
          this.saveButtontitle = 'Save';
          var prm = this.parameterId.toString().split('-');
          this.xfservice
            .getxFormRecordsByFromName('ShipRequest/' + prm[1])
            .subscribe((data) => {
              data[0].details.id = -1;
              this.shipment = data[0].details;
              this.shipment.HeaderInfo.ShipDate = new Date();
              var cchip = JSON.stringify(this.shipment);
              this.clearShip = JSON.parse(cchip);
              this.isDataLoaded = true;
            });

          this.isDataLoaded = true;
        } else if (this.parameterId > -1) {
          this.saveButtontitle = 'Update';
          this.xfservice
            .getxFormRecordsByFromName('ShipRequest/' + this.parameterId)
            .subscribe((data) => {
              this.shipment = data[0].details;
              // this.shipment.HeaderInfo.ShipDate = new Date();

              var cchip = JSON.stringify(this.shipment);
              this.clearShip = JSON.parse(cchip);
            });
        }
        this.history_filter_xfields =
          this.LocationMasterData.HistoryTable[0].details;

        this.history_filter_xfields.forEach((item) => {
          item.fields.forEach((field: any) => {
            if (
              field['filterField'] == true &&
              field['fieldName'] != 'ShipDate'
            ) {
              this.history_filters[item.fieldHeader + '.' + field.fieldName] =
                '';
            } else if (field['fieldName'] == 'ShipDate') {
              this.history_filters['shipDateFrom'] = '';
              this.history_filters['shipDateTo'] = '';
            }
          });
        });
      });
    });

    this.form = new FormGroup({
      pickupDate: new FormControl('', Validators.required),
      shipDate: new FormControl('', Validators.required),
      selectedConnection: new FormControl('', Validators.required),
      selectedFilter: new FormControl('', Validators.required),
      selectedDelivery: new FormControl('', Validators.required),
      searchTerm: new FormControl('', Validators.required),
      searchTermPopup: new FormControl('', Validators.required),
    });
    this.userName = this.userContext.getDecryptedCookie('usercontext');
  }
  shipmentFromShip : any;
  searchId: number;
  async ngOnInit() {
    this.locationId=JSON.parse(this.cookieService.get('role'))?.LocationId
    this.initializeForm();
    await this.getShippingData();
     // Listen to changes in the location dropdown
     this.filterForm.get('locationid').valueChanges.subscribe((selectedLocationId) => {
      this.filterCarriersByLocation(selectedLocationId);
      this.filterERPByLocation(selectedLocationId);
    });
    this.today = new Date();
    this.form.patchValue({
      pickupDate: this.today,
      shipDate: this.today,
      searchTerm:''
    });
    this.searchId = this.rowDataFromShipRequest.getData();
    this.searchTerm = this.searchId;
    this.getFilterData()
    this.form.controls['selectedFilter'].setValue('Delivery Order');
    this.form.controls['selectedConnection'].setValue('Manual');
    this.form.valueChanges.subscribe((value) => {
      this.populateData();
    });
    let dataFromShipRequest = this.rowDataFromShipRequest.getShipFromData();
    if(dataFromShipRequest)
    {
      this.shipment = dataFromShipRequest;
    } 
  }
  applyFilter(): void {
    const payload = this.transformData(this.filterForm.value);
    this.getShipments(payload)
  }

  transformData(data: any): any {
    // Extract the IDs from nested objects and construct the payload
    const transformedPayload = {
      user_id: this.userName.body.user_id,
      filters: {
        fromdate: this.formatIsoDateToPostgres(data.fromdate || new Date().toISOString()),  // Direct assignment if the date is already in desired format
        todate:  this.formatIsoDateToPostgres(data.todate || new Date().toISOString()),      // Direct assignment if the date is already in desired format
        carrier: data.carrier === 'Select' ? '' : data.carrier || "",  // Extracting the id from nested object
        status: data.status === 'Select' ? '' : data.status || "",    // Extracting the id from nested object
        feedersystem: data.feedersystem === 'Select' ? '' : data.feedersystem || "",  // Extracting the id from nested object
        locationid: data.locationid || "",
        deliveryno: data.deliveryno || "",
        shiptocompany: data.shiptocompany || "",
        rowstart: this.page,
        rowend: this.rows
      }
    };
  
    return transformedPayload;
  }


 formatIsoDateToPostgres(isoDateString: string): string {
  const date = new Date(isoDateString);

  // Get local time offset in minutes and adjust the date
  const offsetMinutes = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() - offsetMinutes);

  // Format the date as YYYY-MM-DD HH:MM:SS
  return date.toISOString().slice(0, 19).replace('T', ' ');
}


  removeFilter(): void {
    // Reset the form fields to default values
    this.filterForm.reset({
      location: '',
      carrier: '',
      fromdate: '',
      todate: '',
      feederSystem: '',
      deliveryNumber: '',
      shipToCompany: '',
      status: ''
    });
    const currentDate = new Date();
    const nextWeekDate = new Date();
    nextWeekDate.setDate(currentDate.getDate() - 6);

    this.filterForm.get('locationid').patchValue(this.xhistoryLocationId);
    this.filterForm.get('status').patchValue("Shipped");
    this.filterForm.get('fromdate').patchValue(nextWeekDate);
    this.filterForm.get('todate').patchValue(currentDate);
    // Log that filters have been removed
    // Reset the filtered data or perform any additional logic as needed
  }
  ngAfterViewInit(): void {
    // Focus the input field
    this.cursorFocus.nativeElement.focus();
  }
  
  handleSidebarHide(){
    this.showOverlay = false;
    this.history_Dialog = false;
  }
  locationIdData : any;
  xhistoryLocationId : any;
  services :any;
  async getShippingData() {
    try {
      this.shipNowService
        .getShippingData()
        .toPromise()
        .then((data) => {
          this.connections = this.connections.concat(data.erp);
          this.connections=this.sortByField(this.connections,'Name')
          this.filters = this.sortByField(data.ordertypes,'Name');
          this.carrierOptions = data.carriers;
          this.filteredERPOptions = this.connections;
          this.statusOptions = data.shipmentstatuses;
          this.services = data.carrierservices;
          this.statusOptions = [
            { description: "Select", id: "" },
            ...data.shipmentstatuses
          ];
          this.locationIdData = data.location;
          // this.locationIdData = [
          //   { LocationName: "Select", LocationId: "" },
          //   ...data.location
          // ];
          this.xhistoryLocationId = data.xLocationId;
          this.filterCarriersByLocation(this.xhistoryLocationId);
          this.filterERPByLocation(this.xhistoryLocationId);
          this.filterForm.get('locationid').patchValue(this.xhistoryLocationId);
          this.filterForm.get('status').patchValue("Shipped");
        })
        .catch((error) => {
        });
    } catch (error) {
    }
  }

  toggleList() {
    this.listVisible = !this.listVisible;
  }

  selectFilter(filter: any) {
    this.form.controls['selectedFilter'].setValue(filter);
    this.listVisible = false;
  }

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }

  navigateToDashboard() {
    this.triggerRefresh.emit(); // Emit event to parent
    this.rowDataFromShipRequest.setData('');
    this.ngOnInit()
    this.router.navigate(['home/DashBoards']);
  }

  refreshPage() {
    window.location.reload();
  }

  showSearchPopup(): void {
    this.searchDialogVisible = true;
  }

  onDialogHide(): void {
    // Handle dialog hide event if needed
  }

  performSearch(): void {
    const searchValue = this.form.get('searchTermPopup').value;
    // Perform your search logic here
    this.searchDialogVisible = false; // Close the dialog after search
  }

  //select shipment or order
  selectOrderorShipment(ssd: any) {
    // this.shipment = this.clearShip;
    // console.log
    // this.shipment = this.selectedHistoryOrder.details;
    console.log(ssd);
    this.rowDataFromShipRequest.setFromURLData('shiprequest');

    this.shipment = ssd.details;
    this.shipment.HeaderInfo.ShipDate = new Date(
      this.shipment.HeaderInfo.ShipDate
    );

    if (this.shipment.CarrierDetails.Carrier) {
      this.xfservice
        .getSpecialServicesByCarrier(this.shipment.CarrierDetails.Carrier)
        .subscribe((data: any) => {
          this.specialServiceData = data;
          this.isSSLoded = true;
        });
    }
    this.sharedService.updateTrackingStatus(this.shipment?.HeaderInfo?.Status);
    this.sharedService.updateShipment(this.shipment);
    this.sharedService.updatefromURL('shiprequest');

    this.dataEmitter.emit(this.shipment);
    this.updateHeaderFormData(this.shipment);
    this.history_Dialog = false;
    this.showOverlay = false;
  }
  isCarrierObject(carrier: any): boolean {
    // Check if carrier is an object and contains 'CarrierName'
    return carrier && typeof carrier === 'object' && carrier.CarrierName;
  }
  
  async getFilterData(){ 
    this.xfservice.getxFormRecordsByFromName('shiprequest').subscribe((shiprequestdata) => {
      this.xfservice.getxFormRecordsByFromName('shipments').subscribe((shipmentsdata) => {
      this.filterHistoryOrders=[...shiprequestdata,...shipmentsdata];
      if (this.searchId) {
        this.searchFilter();
      }
    });});  
  }

  formattedPayload:any;
  private isInitialized = false;  // Track whether initialization has occurred

  getShipments(payload) {
    if (!this.isInitialized) {  // Run initialization logic only once
      const currentDate = new Date();
      const nextWeekDate = new Date();
      nextWeekDate.setDate(currentDate.getDate() - 6);
  
      // Initialize filter form with default values
      this.filterForm.get('locationid').patchValue(this.xhistoryLocationId);
      this.filterForm.get('status').patchValue('Shipped');
      this.filterForm.get('fromdate').patchValue(nextWeekDate);
      this.filterForm.get('carrier').patchValue('');
      this.filterForm.get('feedersystem').patchValue('');
  
      // Update the payload with the initial form values
      payload = this.filterForm.value;
  
      // Construct the formatted payload
      this.formattedPayload = {
        user_id: this.userName.body.user_id,
        filters: {
          fromdate: this.filterForm.get('fromdate').value,
          todate: this.filterForm.get('todate').value,
          carrier: this.filterForm.get('carrier').value,
          status: this.filterForm.get('status').value,
          feedersystem: this.filterForm.get('feedersystem').value,
          locationid: this.filterForm.get('locationid').value,
          deliveryno: this.filterForm.get('deliveryno').value,
          shiptocompany: this.filterForm.get('shiptocompany').value,
          rowstart: 0,
          rowend: 50
        }
      };
  
      // Mark as initialized after the first payload setup
      this.isInitialized = true;
    } else {
      // If already initialized, use the updated payload from the form
      payload = this.filterForm.value;
  
      // Construct the formatted payload based on updated form values
      this.formattedPayload = {
        user_id: this.userName.body.user_id,
        filters: {
          fromdate: this.filterForm.get('fromdate').value,
          todate: this.filterForm.get('todate').value,
          carrier: this.filterForm.get('carrier').value,
          status: this.filterForm.get('status').value,
          feedersystem: this.filterForm.get('feedersystem').value,
          locationid: this.filterForm.get('locationid').value,
          deliveryno: this.filterForm.get('deliveryno').value,
          shiptocompany: this.filterForm.get('shiptocompany').value,
          rowstart: 0,
          rowend: 50
        }
      };
    }
  
    // Check if the payload has changed before making the API request
    this.xfservice.getdatafromfilters(this.formattedPayload).subscribe(
      (data) => {
        this.historyOrders = data;
        this.Org_historyOrders = data;
  
        // Update the displayed data
      },
      (error) => {
        // Handle errors if needed
      }
    );
  }
  
  
  openHistoryTableProperties() {
    this.HistorytableProperties_dialog = true;
  }

  applyHistoryFilters(status: any, event: any, element: any): void {
    var fromdate = '';
    var toDate = '';
    var conditions: any = {};
    if (
      this.history_filters['shipDateFrom'] != '' &&
      this.history_filters['shipDateto'] != ''
    ) {
      var shipDateFrom = this.history_filters['shipDateFrom'];
      var shipDateTo = this.history_filters['shipDateTo'];
      fromdate += shipDateFrom.getFullYear();
      if (shipDateFrom.getMonth() + 1 < 10) {
        fromdate += '-0' + (shipDateFrom.getMonth() + 1);
      } else fromdate += '-' + (shipDateFrom.getMonth() + 1);
      if (shipDateFrom.getDate() < 10)
        fromdate += '-0' + shipDateFrom.getDate();
      else fromdate += '-' + shipDateFrom.getDate();
      toDate += shipDateTo.getFullYear();
      if (shipDateTo.getMonth() + 1 < 10) {
        toDate += '-0' + (shipDateTo.getMonth() + 1);
      } else toDate += '-' + (shipDateTo.getMonth() + 1);
      if (shipDateTo.getDate() < 10) toDate += '-0' + shipDateTo.getDate();
      else toDate += '-' + shipDateTo.getDate();

      conditions['shipdate'] =
        "details->'HeaderInfo'->>'ShipDate' between '" +
        fromdate +
        "' and '" +
        toDate +
        "'";
    }
    var keys = Object.keys(this.history_filters);
    // alert(keys.length)
    keys.forEach((item, index) => {
      var condition = '';
      var fields: any = [];
      var dataConditions = '';
      if (item != 'shipDateFrom' && item != 'shipDateTo') {
        if (this.history_filters[item] != '') {
          fields = item.split('.');
          condition +=
            "upper(details->'" +
            fields[0] +
            "'->>'" +
            fields[1] +
            "')=upper('" +
            this.history_filters[item] +
            "')";

          dataConditions =
            dataConditions +
            ' ' +
            "upper(details->'" +
            fields[0] +
            "'->>'" +
            fields[1] +
            "')=upper('" +
            this.history_filters[item] +
            "')";
          conditions['condition' + index] = condition;
        }
      }
    });

    this.xfservice
      .getShipmentstByFilters(JSON.stringify(conditions))
      .subscribe((data: any) => {
        this.Org_historyOrders = data;
        this.historyOrders = data;
        element.hide(event);

        this.isDataLoaded = true;

      });
  }
  openHistoryDialog() {
    // this.historyOrders = [];
    this.history_Dialog = true;
    this.showOverlay = true;
    this.getShipments('')

  }

  getKeys(obj: any): string[] {
    const keys: string[] = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keys.push(key);
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          const nestedKeys = this.getKeys(obj[key]);
          keys.push(...nestedKeys.map((nestedKey) => `${key}.${nestedKey}`));
        }
      }
    }
    return keys;
  }

  applyManifestFilterGlobal($event: any, stringVal: any): void {
    if (($event.target as HTMLInputElement).value) {
      this.historyOrders = this.filterNestedArray(
        this.Org_historyOrders,
        ($event.target as HTMLInputElement).value
      );
    } else {
      this.historyOrders = Array.from(this.Org_historyOrders);
    }

  }

  filterNestedArray(jsonArray: any[], searchTerm: string): any[] {
    return jsonArray.filter((item) => {
      if (typeof item === 'object' && item !== null) {
        return Object.values(item).some(
          (value) => this.filterNestedArray([value], searchTerm).length > 0
        );
      }
      return String(item).includes(searchTerm);
    });
  }

  filterShipment(search) {
    var filterData = this.filterHistoryOrders.filter(
      (item) => item.details?.HeaderInfo?.DocumentNumber === search.toString()
    );    
    if (filterData.length > 0) {
      this.shipment = filterData[0].details;
      const value = this.form.get('selectedFilter')?.value;
      this.searchTerm = this.shipment.HeaderInfo.DocumentNumber;
      this.dataEmitter.emit(this.shipment);
      this.updateHeaderFormData(this.shipment);
    } else {
      // this.dataEmitter.emit(this.shipment);
      this.warningPopup.showWarning('Order record not found');
    }
  }

  updateHeaderFormData(data) {
    this.form.get('selectedFilter')?.setValue(data['HeaderInfo']['DocumentType']);
    this.form.get('searchTerm')?.setValue(data['HeaderInfo']['DocumentNumber']);
    this.form.get('selectedConnection')?.setValue(data['HeaderInfo']['FeederSystem']);
  }

  searchFilter() {
    // const searchTerm = !this.form.get('searchTerm')?.value
    //   ? this.searchId.toString()
    //   : this.form.get('searchTerm')?.value;

    let searchTerm = this.form.get('searchTerm')?.value;
    searchTerm = typeof searchTerm == 'string' ? searchTerm.trim() : searchTerm;
    if (!searchTerm) {
      this.showWarning = true;
      this.warningPopup.showWarning('Please Enter Document Number');
    } else {
      this.filterShipment(searchTerm);
    }
  }

  shipmentTypes: any[] = [
    { label: 'Open', value: 'Open' },
    { label: 'Planning', value: 'Planning' },
    { label: 'Planned', value: 'Planned' },
    { label: 'Shipped', value: 'Shipped' },
    { label: 'Cancelled', value: 'Cancelled' },
    { label: 'Ship Request Profiles', value: 'Ship Request Profiles' },
  ];
  selectedShipmentType: string = 'Shipped';

  shipments:any[] = [];

  openTimelineSidebar(event)
  {  
    this.showSidebar = true;
    this.getTimelineData();
    setTimeout(() => {
      this.showOverlaypopup(event);
    }, 0);
  }
  showOverlaypopup(event: Event) {
    if (this.overlayPanel) {
      this.overlayPanel.toggle(event); // Pass the event to correctly position it
    } else {
      console.error('Overlay panel is not available yet');
    }
  }
  

  getTimelineData(): void {
    try {
      const payload = {
       "user_id": this.userName.body.user_id,
       "filters":
        {
        "status": this.selectedShipmentType,
        "locationid":this.xhistoryLocationId
        }
        }
      this.shipNowService.getHistoryData(payload).toPromise()
        .then(data => {
          this.shipments = data;
          
          // this.connections = data.erp;
          // this.filters = data.ordertypes;
        })
        .catch((error) => {
        });
    } catch (error) {
    }
  }
  getServiceName(serviceCode: string): string {
    const service = this.services.find(s => s.ServiceCode === serviceCode);
    return service ? service.ServiceName : ''; // Return a default value if not found
  }
// Filtered shipments based on selectedShipmentType, sorted by ShipDate, and limited to the top 20 recent
get filteredShipments() {
  return this.shipments
    .filter(shipment => 
      shipment && shipment.details && shipment.details.HeaderInfo.Status === this.selectedShipmentType
    )
    .sort((a, b) => new Date(b.details.HeaderInfo.ShipDate).getTime() - new Date(a.details.HeaderInfo.ShipDate).getTime())
    .slice(0, 20);  // Get only the top 20 recent shipments
}
  

onShipmentTypeChange(event: any): void {
  console.log(event.value);  // Check the selected value
  this.selectedShipmentType = event.value;  // Assign the selected value to your model
  this.getTimelineData();  // Trigger API call
}
  searchTerm: any;
  selectHistory(shipment: any) {
    const shipDate = new Date(shipment.details.HeaderInfo.ShipDate);
    const feederSystem = shipment.details.HeaderInfo.FeederSystem;
    // Log to ensure data is set
    const documentType = shipment.details.HeaderInfo.DocumentType;
    // const selectedFilter = this.filters.find(
    //   (filter) => filter.Name === documentType
    // );
    this.form.controls['selectedFilter'].setValue(documentType);
    this.HistoryService.setHistory(shipment);
    this.searchTerm = shipment.details.HeaderInfo.DocumentNumber;
    this.form.patchValue({
      pickupDate: shipDate,
      shipDate: this.today,
      selectedConnection: feederSystem,
    });
  }

  populateData() {
    let erp = this.form.get('selectedConnection').value;
    let documentid: any = this.form.get('selectedFilter').value;

    const constructPayload = {
      FeederSystem: erp,
      DocumentNumber: this.form.get('searchTerm').value,
      DocumentType: documentid,
      ShipDate: this.history_filters?.shipDateFrom,
      ShipmentType: erp || '', // If there's a form control for ShipmentType
      Location: this.locationId, // If there's a form control for Location
      ERP: erp,
      TotalWeight: '020', // Default to "020" if not set
    };
    this.dataService.setDashboardData(constructPayload);
  }
  
  refreshForm() {
    this.triggerRefresh.emit(); // Emit event to parent
    this.ngOnInit()
    window.location.reload();
  }


  applyCustomFilter(event: Event, field: string) {
    const inputValue = (event.target as HTMLInputElement).value.toLowerCase();

    this.historyOrders = this.Org_historyOrders.filter(order => {
        // Access the nested property using a helper function
        const fieldValue = this.resolveField(order, field);
        return fieldValue?.toString().toLowerCase().includes(inputValue);
    });

    this.cdr.detectChanges();  // Ensure Angular updates the UI
}

// Helper method to safely access nested properties
private resolveField(obj: any, path: string) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}

  getFormValues() {
    return {'HeaderInfo':{pickupDate: this.form.get('pickupDate')?.value
      ,shipDate: this.form.get('shipDate')?.value,
      connectTo: this.form.get('selectedConnection')?.value,
      selectedFilter: this.form.get('selectedFilter')?.value,
      documentNumber: this.form.get('searchTerm')?.value,
      selectedDelivery: this.form.get('selectedDelivery')?.value}}
  }
  initializeForm() {
    const currentDate = new Date();
    const nextWeekDate = new Date();
    nextWeekDate.setDate(currentDate.getDate() - 6);

    this.filterForm = this.fb.group({
      locationid: [''],  // Default to id '1' (Venkat Inc)
      carrier: [''],
      fromdate: [nextWeekDate],
      todate: [currentDate],
      feedersystem: [''],
      deliveryno: [''],
      shiptocompany: [''],
      status: ['']
    });
  }
  updateRowStartEnd(start: number, end: number) {
    // Update rowstart and rowend dynamically
    this.rowStart = start;
    this.rowEnd = end;
    this.filterForm.get('filters').get('rowstart').setValue(this.rowStart);
    this.filterForm.get('filters').get('rowend').setValue(this.rowEnd);
  }

  filterCarriersByLocation(locationId: number) {
    // Filter carrier options based on selected location
    this.filteredCarrierOptions = this.carrierOptions.filter(carrier => carrier.LocationId == locationId);
    this.filteredCarrierOptions = [
      { CarrierName: "Select", Id: "" },
      ...this.filteredCarrierOptions
    ];
    // Reset carrier selection when the location changes
    this.filterForm.get('carrier').patchValue(null);
  }
  filterERPByLocation(locationId: number) {
    this.filteredERPOptions = this.connections.filter(carrier => carrier.LocationId == locationId);
    this.filteredERPOptions = [
      { Name: "Select", Id: "" },
      ...this.filteredERPOptions
    ];
    // Reset carrier selection when the location changes
    this.filterForm.get('feedersystem').patchValue(null);
  }
  minShipToDate: Date | null = null;

  onFromDateSelect(event: any): void {
    const selectedFromDate = event;
    this.minShipToDate = selectedFromDate ? new Date(selectedFromDate) : null;
  }


  sortByField<T>(array: T[], fieldName: keyof T, ascending: boolean = true): T[] {
    return array.sort((a, b) => {
      const valueA = a[fieldName];
      const valueB = b[fieldName];
      if (valueA === undefined || valueB === undefined) {
        return 0;
      }
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }
      if (valueA < valueB) return ascending ? -1 : 1;
      if (valueA > valueB) return ascending ? 1 : -1;
      return 0;
    });
  }


}
