<div class="international-sec">
  <form class="p-3">
    <div class="row mb-3">
      <div class="col-md-2">
        <div class="form-group">
          <label for="INTDutiesTaxes">INT Duties/Taxes</label>
          <p-dropdown id="INTDutiesTaxes" [options]="INTDutiesTaxes"></p-dropdown>
        </div>
      </div>
  
      <div class="col-md-2">
        <div class="form-group">
          <label for="PayorZipCode">Payor Zip Code</label>
          <input id="PayorZipCode" type="text" pInputText class="form-control" />
        </div>
      </div>
  
      <div class="col-md-2">
        <div class="form-group">
          <label for="ITNXTNSEDEEI">ITN/XTN (SED/EEI)</label>
          <input id="ITNXTNSEDEEI" type="text" pInputText class="form-control" />
        </div>
      </div>
  
      <div class="col-md-2">
        <div class="form-group">
          <label for="ATLASMRN">ATLAS MRN No</label>
          <input id="ATLASMRN" type="text" pInputText class="form-control" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="TermsOfShipment">Terms of Shipment</label>
          <p-dropdown id="TermsOfShipment" [options]="TermsOfShipment"></p-dropdown>
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="DocumentsContentType">Documents Content Type</label>
          <p-dropdown id="DocumentsContentType" [options]="DocumentsContentType"></p-dropdown>
        </div>
      </div>
    </div>
  
    <div class="row mb-3">
        <div class="col-md-2">
          <div class="form-group">
            <label for="DutiesAccount">Duties Account No</label>
            <input id="DutiesAccount" type="text" pInputText class="form-control" />
        </div>
        </div>
    
        <div class="col-md-2">
          <div class="form-group">
            <label for="ShipFromTax">Ship From Tax No</label>
            <input id="ShipFromTax" type="text" pInputText class="form-control" />
        </div>
        </div>
    
        <div class="col-md-2">
          <div class="form-group">
            <label for="BookingConfirm">Booking Confirm No</label>
            <input id="BookingConfirm" type="text" pInputText class="form-control" />
          </div>
        </div>
    
        <div class="col-md-2">
          <div class="form-group">
            <label for="ShipToTax">Ship To Tax No</label>
            <input id="ShipToTax" type="text" pInputText class="form-control" />
          </div>
        </div>
  
        <div class="col-md-2">
          <div class="form-group">
            <label for="ReasonForExport">Reason for Export</label>
            <p-dropdown id="ReasonForExport" [options]="ReasonForExport" ></p-dropdown>
          </div>
        </div>
  
        <div class="col-md-2">
          <div class="form-group">
            <label for="FreightForwarderTax">Freight Forwarder Tax No</label>
            <input id="FreightForwarderTax" type="text" pInputText class="form-control" />
          </div>
        </div>
      </div>
  
    <div class="row mb-3">
        <div class="col-md-2">
          <div class="form-group">
            <label for="PayorCountryCode">Payor Country Code</label>
            <input id="PayorCountryCode" type="text" pInputText class="form-control" />
          </div>
        </div>
    
        <div class="col-md-2">
          <div class="form-group">
            <label for="Permit">Permit No</label>
            <input id="Permit" type="text" pInputText class="form-control" />
          </div>
        </div>
    
        <div class="col-md-2">
          <div class="form-group">
            <label for="B13AFilingOption">B13A Filing Option</label>
            <p-dropdown id="B13AFilingOption" [options]="B13AFilingOption"></p-dropdown>
          </div>
        </div>
    
        <div class="col-md-2">
          <div class="form-group">
            <label for="CustomDecVal">Customs Declared Value</label>
            <input id="CustomDecVal" type="text" pInputText class="form-control" />
          </div>
        </div>
  
        <div class="col-md-2">
          <div class="form-group">
            <label for="CompliStatement">Compli. Statement</label>
            <input id="CompliStatement" type="text" pInputText class="form-control" />
          </div>
        </div>
  
      </div>
  </form>
  <div class="titleCard mt-3">
    <div class="name">
    <!-- <div class="row">
      <div class="col-md-1">
       <span>H U</span>
      </div>
      <div class="col-md-1">
        <span>product no</span>     
      </div>
      <div class="col-md-1">
          <span>License NO </span>  
      </div>
      <div class="col-md-1">
          <span>E C C</span>
      </div>
      <div class="col-md-1">
          <span>Description</span>
      </div>
      <div class="col-md-1">
          <span>U O M</span>
      </div>
      <div class="col-md-1">
          <span>CURRENCY</span>
      </div>
      <div class="col-md-1">
          <span>Country of MFR</span>
      </div>
      <div class="col-md-1">
          <span>Harmonized</span>
      </div>
      <div class="col-md-1">
          <span>Weight</span>
      </div>
      <div class="col-md-1">
          <span>Quantity</span>
      </div>
      <div class="col-md-1">
          <span>Unit Value</span>
      </div>
    </div> -->
    <p-table [value]="packedItems" class="p-datatable-sm mt-3">
      <!-- Updated Table Header -->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 5%;">H.U.</th>
          <th>Product No</th>
          <th>License No</th>
          <th>ECCN</th>
          <th>Description</th>
          <th>UOM</th>
          <th>Currency</th>
          <th>Country of MFR</th>
          <th>Harmonized Code</th>
          <th>Weight</th>
          <th>Quantity</th>
          <th>Unit Value</th>
          <!-- <th>Action</th> -->
        </tr>
      </ng-template>
    
      <!-- Updated Table Body with Data -->
      <ng-template pTemplate="body" let-item let-i="rowIndex">
        <tr *ngFor="let product of item.ItemsInfo; let j = index">
          <td>{{ item.HU }}</td>
          <td>{{ product.product }}</td>
          <td>{{ item.licenseNo }}</td>
          <td>{{ item.ecc }}</td>
          <td>{{ product.description }}</td>
          <td>{{ item.uom }}</td>
          <td>{{ item.currency }}</td>
          <td>{{ item.countryOfMFR }}</td>
          <td>{{ item.harmonized }}</td>
          <td>
            <!-- Input field for Weight -->
            <input pInputText type="text" [(ngModel)]="packedItems[i].weight" />
          </td>
          <td>
            <!-- Input field for Quantity -->
            <input pInputText type="text" [(ngModel)]="packedItems[i].ItemsInfo[j].totalQuantity" />
          </td>
          <td>
            <!-- Input field for Unit Value -->
            <input pInputText type="text" [(ngModel)]="packedItems[i].unitValue" />
          </td>
          <!-- <td>
            <img
              (click)="deleteProductHU(i, item.HU)"
              class="btn-img ml-3"
              src="../../assets/icon/material-symbols_delete-outline.png"
            />
          </td> -->
        </tr>
      </ng-template>
    </p-table>
        
    <div *ngIf="(packedItems!= undefined && packedItems.length === 0)" class="row text-center mt-5 mr-5" >
        <span class="text-center mr-5" style="color: #999;
        font-size: 18px;
        font-weight: 450;
        ">
          No data available.
        </span>
    </div>
      <!-- Add any additional form elements here -->
    </div>
  </div>
</div>
