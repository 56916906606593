import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrl: './confirm-popup.component.scss'
})
export class ConfirmPopupComponent {
    title: any;
    message: any;
    isPopupVisible = false;
    mode: 'warning' | 'confirm' = 'warning'; // New mode property to switch between "warning" and "confirm"
    
    // Accept/Reject handlers for confirm dialogs
    acceptCallback: (() => void) | null = null;
    rejectCallback: (() => void) | null = null;
  
    constructor(private messageService: MessageService, private confirmationService: ConfirmationService) {}
  
    // Method to show confirm dialog
    showConfirm(message: string, accept: () => void, reject?: () => void) {
      this.isPopupVisible = true;
      this.message = message;
      this.acceptCallback = accept;
      this.rejectCallback = reject || null;  // Optionally set reject handler
    }
  
    // Clear or close the modal
    clearWarnings() {
      this.isPopupVisible = false;
      this.messageService.clear();
    }
  
    // Method to handle confirm acceptance
    accept() {
      if (this.acceptCallback) {
        this.acceptCallback();
      }
      this.clearWarnings();
    }
  
    // Method to handle confirm rejection
    reject() {
      if (this.rejectCallback) {
        this.rejectCallback();
      }
      this.clearWarnings();
    }
  }