<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<p-toast position="bottom-center" key="c" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{ message.summary }}</h4>
                <p>{{ message.detail }}</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" pButton label="Yes" class="p-button-success"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton label="No" class="p-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>

<div class="card">
    <p-toolbar styleClass="p-mb-4">
        <ng-template pTemplate="left">
            <button pButton label="New" icon="pi pi-plus" (click)="newconsolidationflag=true
            "></button>

            
        </ng-template>

    </p-toolbar>
    <p-table #dt *ngIf="isDataLoaded" [value]="consolidationData" responsiveLayout="scroll"
        [tableStyle]="{'min-width': '75rem'}" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50]" [rowHover]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" dataKey="id" [scrollable]="true"
        responsiveLayout="stack" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped ">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th style="width: 5rem"></th>
                <th pSortableColumn="id">id <p-sortIcon field="id"></p-sortIcon></th>
                <th pSortableColumn="TotalRecords">TotalRecords <p-sortIcon field="TotalRecords"></p-sortIcon></th>
                <th pSortableColumn="Parameters">Parameters <p-sortIcon field="Parameters"></p-sortIcon></th>
            </tr>

        </ng-template>
        <ng-template pTemplate="body" let-record let-expanded="expanded">
            <tr>
                <td>
                    <p-tableCheckbox [value]="record"></p-tableCheckbox>

                </td>
                <td>
                    <button type="button" pButton pRipple [pRowToggler]="record"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>

                </td>
                <td>{{ record['id'] }}</td>
                <td>{{ record['TotalRecords'] }}</td>
                <td>{{ record['Parameters'] }}</td>
            </tr>

        </ng-template>
        <ng-template pTemplate="rowexpansion" let-record>
            <tr>
                <td colspan="7">
                    <div class="p-3">
                        <p-table [value]="record.Records" dataKey="id">
                            <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="id">Id <p-sortIcon field="id"></p-sortIcon></th>
                <th pSortableColumn="FeederSystem">FeederSystem <p-sortIcon field="FeederSystem"></p-sortIcon></th>
                <th pSortableColumn="DocumentNumber">DocumentNumber <p-sortIcon field="DocumentNumber"></p-sortIcon>
                </th>
                <th pSortableColumn="DocumentType">DocumentType <p-sortIcon field="DocumentType"></p-sortIcon></th>
                <th pSortableColumn="ERP">ERP <p-sortIcon field="ERP"></p-sortIcon></th>
                <th pSortableColumn="ShipTo_Company">ShipTo_Company <p-sortIcon field="ShipTo_Company"></p-sortIcon>
                </th>
                <th pSortableColumn="ShipTo_Contact">ShipTo_Contact <p-sortIcon field="ShipTo_Contact"></p-sortIcon>
                </th>
                <th pSortableColumn="ShipTo_AddressLine1">ShipTo_AddressLine1 <p-sortIcon
                        field="ShipTo_AddressLine1"></p-sortIcon></th>
                <th pSortableColumn="ShipTo_AddressLine2">ShipTo_AddressLine2 <p-sortIcon
                        field="ShipTo_AddressLine2"></p-sortIcon></th>
                <th pSortableColumn="ShipTo_City">ShipTo_City <p-sortIcon field="ShipTo_City"></p-sortIcon></th>
                <th pSortableColumn="ShipTo_State">ShipTo_State <p-sortIcon field="ShipTo_State"></p-sortIcon></th>
                <th pSortableColumn="ShipTo_ZipCode">ShipTo_ZipCode <p-sortIcon field="ShipTo_ZipCode"></p-sortIcon>
                </th>
                <th pSortableColumn="ShipTo_Country">ShipTo_Country <p-sortIcon field="ShipTo_Country"></p-sortIcon>
                </th>


            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-shiprecord>
            <tr>
                <td>{{ shiprecord.id}}</td>
                <td>{{shiprecord.FeederSystem}}</td>
                <td>{{shiprecord.DocumentNumber}}</td>
                <td>{{shiprecord.DocumentType}}</td>
                <td>{{shiprecord.ERP}}</td>
                <td>{{shiprecord.ShipTo_Company}}</td>
                <td>{{shiprecord.ShipTo_Contact}}</td>
                <td>{{shiprecord.ShipTo_AddressLine1}}</td>
                <td>{{shiprecord.ShipTo_AddressLine2}}</td>
                <td>{{shiprecord.ShipTo_City}}</td>
                <td>{{shiprecord.ShipTo_State}}</td>
                <td>{{shiprecord.ShipTo_ZipCode}}</td>
                <td>{{shiprecord.ShipTo_Country}}</td>

            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="6">There are no order for this product yet.</td>
            </tr>
        </ng-template>
    </p-table>
</div>
</td>
</tr>
</ng-template>

</p-table>
</div>
<p-dialog [style]="{width:'90%' , height:'90%'}" [(visible)]="newconsolidationflag" [modal]="true" styleClass="p-fluid">
    <div class="card">
    <p-toolbar styleClass="p-mb-4">
        <ng-template pTemplate="left">
            <p-button icon="pi pi-filter" (click)="op.toggle($event)"></p-button>
        </ng-template>
        <ng-template pTemplate="right">
            <p-dropdown class="mr-1" [style]="{width:'200px'}" [options]="consolidationtemplate" [showClear]="true" optionValue="name" optionLabel="name" [autoDisplayFirst]="false" (onChange)="loadFields()" [(ngModel)]="selectedtemplate"></p-dropdown>


            <p-multiSelect [style]="{width:'200px'}" class="mr-1" [options]="xcolumns" optionLabel="name"
                optionValue="name" [(ngModel)]="selectedfields"></p-multiSelect>
            <button pButton class="mr-1" label="Auto Consolidate" (click)="autoConsolidate()"></button>

        </ng-template>
    </p-toolbar>
    <p-table #dtshiprequest [value]="shipRequests" [tableStyle]="{'min-width': '75rem'}"
        [(selection)]="selectedShipRequest" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50]" [rowHover]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" dataKey="id" [scrollable]="true"
        responsiveLayout="stack" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
        *ngIf="isShipRequestDataLoaded" [columns]="xcolumns" [hidden]="currentOrdersTable_flag">
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">


                <div class="ml-auto mr-2">
                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                    <input type="text" pInputText size="50" placeholder="Global Filter"
                        (input)="applyFilterGlobal($event, 'contains')" style="width:auto">
                </div>

            </div>
        </ng-template>

        <ng-template pTemplate="header" let-column>
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="id">Id
                    <p-sortIcon field="id"></p-sortIcon>
                </th>

                <th>ConsolidationId</th>



                <th pSortableColumn="FeederSystem">FeederSystem
                    <p-sortIcon field="FeederSystem"></p-sortIcon>
                </th>
                <th pSortableColumn="DocumentNumber">DocumentNumber
                    <p-sortIcon field="DocumentNumber"></p-sortIcon>
                </th>
                <th pSortableColumn="DocumentType">DocumentType
                    <p-sortIcon field="DocumentType"></p-sortIcon>
                </th>
                <th pSortableColumn="COMPANY">COMPANY
                    <p-sortIcon field="COMPANY"></p-sortIcon>
                </th>
                <th pSortableColumn="CONTACT">CONTACT
                    <p-sortIcon field="CONTACT"></p-sortIcon>
                </th>
                <th pSortableColumn="ADDRESS_LINE1">ADDRESS_LINE1
                    <p-sortIcon field="ADDRESS_LINE1"></p-sortIcon>
                </th>
                <th pSortableColumn="ADDRESS_LINE2">ADDRESS_LINE2
                    <p-sortIcon field="ADDRESS_LINE2"></p-sortIcon>
                </th>
                <th pSortableColumn="ADDRESS_LINE3">ADDRESS_LINE3
                    <p-sortIcon field="ADDRESS_LINE3"></p-sortIcon>
                </th>
                <th pSortableColumn="CITY">CITY
                    <p-sortIcon field="CITY"></p-sortIcon>
                </th>
                <th pSortableColumn="STATE">STATE
                    <p-sortIcon field="STATE"></p-sortIcon>
                </th>
                <th pSortableColumn="ZIPCODE">ZIPCODE
                    <p-sortIcon field="ZIPCODE"></p-sortIcon>
                </th>
                <th pSortableColumn="COUNTRY">COUNTRY
                    <p-sortIcon field="COUNTRY"></p-sortIcon>
                </th>
                
            </tr>

        </ng-template>
        <ng-template pTemplate="body" let-shipRequest let-columns="xcolumns">
            <tr>
                <td>
                    <p-tableCheckbox [value]="shipRequest"></p-tableCheckbox>
                </td>
                <td>{{shipRequest.id}}</td>
                <td>{{shipRequest['ConsolidateId']}}</td>
                <td>{{shipRequest.FeederSystem}}</td>
                <td>{{shipRequest.DocumentNumber}}</td>
                <td>{{shipRequest.DocumentType}}</td>
                <td>{{shipRequest.ERP}}</td>
                <td>{{shipRequest.ShipTo_Company}}</td>
                <td>{{shipRequest.ShipTo_Contact}}</td>
                <td>{{shipRequest.ShipTo_AddressLine1}}</td>
                <td>{{shipRequest.ShipTo_AddressLine2}}</td>
                <td>{{shipRequest.ShipTo_City}}</td>
                <td>{{shipRequest.ShipTo_State}}</td>
                <td>{{shipRequest.ShipTo_ZipCode}}</td>
                <td>{{shipRequest.ShipTo_Country}}</td>

            </tr>


        </ng-template>
    </p-table>


    <!--consolidated table-->


    <p-table #dt *ngIf="isDataLoaded" [value]="consolidationMaster" responsiveLayout="scroll"
        [tableStyle]="{'min-width': '75rem'}" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50]" [rowHover]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" dataKey="id" [scrollable]="true"
        responsiveLayout="stack" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
        [hidden]="currentConsolidatedTable_Flag">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th style="width: 5rem"></th>
                <th pSortableColumn="id">id <p-sortIcon field="id"></p-sortIcon></th>
                <th pSortableColumn="TotalRecords">TotalRecords <p-sortIcon field="TotalRecords"></p-sortIcon></th>
                <th pSortableColumn="Parameters">Parameters <p-sortIcon field="Parameters"></p-sortIcon></th>
            </tr>

        </ng-template>
        <ng-template pTemplate="body" let-record let-expanded="expanded">
            <tr>
                <td>
                    <p-tableCheckbox [value]="record"></p-tableCheckbox>

                </td>
                <td>
                    <button type="button" pButton pRipple [pRowToggler]="record"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>

                </td>
                <td>{{ record['ConsolidateId'] }}</td>
                <td>{{ record['TotalRecords'] }}</td>
                <td>{{ record['Parameters'] }}</td>
            </tr>

        </ng-template>
        <ng-template pTemplate="rowexpansion" let-record>
            <tr>
                <td colspan="7">
                    <div class="p-3">
                        <p-table [value]="record.Records" dataKey="id">
                            <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="id">Id <p-sortIcon field="id"></p-sortIcon></th>
                <th pSortableColumn="FeederSystem">FeederSystem <p-sortIcon field="FeederSystem"></p-sortIcon></th>
                <th pSortableColumn="DocumentNumber">DocumentNumber <p-sortIcon field="DocumentNumber"></p-sortIcon>
                </th>
                <th pSortableColumn="DocumentType">DocumentType <p-sortIcon field="DocumentType"></p-sortIcon></th>
                <th pSortableColumn="ERP">ERP <p-sortIcon field="ERP"></p-sortIcon></th>
                <th pSortableColumn="ShipTo_Company">ShipTo_Company <p-sortIcon field="ShipTo_Company"></p-sortIcon>
                </th>
                <th pSortableColumn="ShipTo_Contact">ShipTo_Contact <p-sortIcon field="ShipTo_Contact"></p-sortIcon>
                </th>
                <th pSortableColumn="ShipTo_AddressLine1">ShipTo_AddressLine1 <p-sortIcon
                        field="ShipTo_AddressLine1"></p-sortIcon></th>
                <th pSortableColumn="ShipTo_AddressLine2">ShipTo_AddressLine2 <p-sortIcon
                        field="ShipTo_AddressLine2"></p-sortIcon></th>
                <th pSortableColumn="ShipTo_City">ShipTo_City <p-sortIcon field="ShipTo_City"></p-sortIcon></th>
                <th pSortableColumn="ShipTo_State">ShipTo_State <p-sortIcon field="ShipTo_State"></p-sortIcon></th>
                <th pSortableColumn="ShipTo_ZipCode">ShipTo_ZipCode <p-sortIcon field="ShipTo_ZipCode"></p-sortIcon>
                </th>
                <th pSortableColumn="ShipTo_Country">ShipTo_Country <p-sortIcon field="ShipTo_Country"></p-sortIcon>
                </th>


            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-shiprecord>
            <tr>
                <td>{{shiprecord.ConsolidateId}}</td>
                <td>{{shiprecord.FeederSystem}}</td>
                <td>{{shiprecord.DocumentNumber}}</td>
                <td>{{shiprecord.DocumentType}}</td>
                <td>{{shiprecord.ERP}}</td>
                <td>{{shiprecord.ShipTo_Company}}</td>
                <td>{{shiprecord.ShipTo_Contact}}</td>
                <td>{{shiprecord.ShipTo_AddressLine1}}</td>
                <td>{{shiprecord.ShipTo_AddressLine2}}</td>
                <td>{{shiprecord.ShipTo_City}}</td>
                <td>{{shiprecord.ShipTo_State}}</td>
                <td>{{shiprecord.ShipTo_ZipCode}}</td>
                <td>{{shiprecord.ShipTo_Country}}</td>

            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="6">There are no order for this product yet.</td>
            </tr>
        </ng-template>
    </p-table>
    </div>
    </td>
    </tr>
    </ng-template>

    </p-table>





    <!-- --------------- ---------------- ------------------ -------------  -->





     </div> 
    <button type="submit" pButton pRipple label="Save" icon="pi pi-check" class="p-button-sm"  (click)="saveConsolidate()"></button>
    <!-- <button [disabled]="masterForm.invalid" type="submit" pButton pRipple [label]="formOperationType"
    icon="pi pi-check" class="p-button-sm"></button> -->
    <!-- <button pButton label="Save" (click)="saveConsolidate()"></button> -->
</p-dialog>

<!-- Overlay Panel for Filter-->
<p-overlayPanel #op [showCloseIcon]="true" [dismissable]="true" [style]="{width: '450px'}">
    <p-toolbar styleClass="p-mb-2">
        <ng-template pTemplate="left"></ng-template>
        <ng-template pTemplate="right">
            <button pButton icon="pi pi-cog" (click)="addFields()"></button>
        </ng-template>
    </p-toolbar>

    <div class="card">
        <div class="grid p-fluid mt-2">
            <div class="field col-12 md:col-6">
    <span class="p-float-label">
        <p-calendar inputId="fromdate" class="mr-1" [(ngModel)]="fromDate"></p-calendar>
        <label for="fromdate">FromDate</label>
    </span>
    </div>
    <div class="field col-12 md:col-6">
    <span class="p-float-label">
        <p-calendar inputId="todate" class="mr-1" [(ngModel)]="toDate"></p-calendar>
        <label for="todate">ToDate</label>
    </span>
    </div>
    <div class="field col-12 md:col-6">
    <span class="p-float-label">
        <p-dropdown [options]="statusvalues" class="mr-1" optionLabel="name" optionValue="name"
            [showClear]="true" id="status" [autoDisplayFirst]="false" [(ngModel)]="status">
        </p-dropdown>
        <label for="status">Status</label>
    </span>
</div>
    <ng-template ngFor let-field [ngForOf]="consolidationtemplatefields" let-i="index">
        <!--TEXTBOX  [required]="field.required"  -->
        <ng-template [ngIf]="field.fieldType == 'Textbox'">
          <div class="field col-12  {{ 'md:' + field.width }}  ">
            <span class="p-float-label">
              <input [type]="field.datatype" pInputText id="text" [(ngModel)]="shipRequestSearch['fieldName']"/>
              <label for="text">{{ field.title }}</label>
              </span>
              </div>
        </ng-template>
        <ng-template [ngIf]="field.fieldType == 'DropDown'">
            <div class="field col-12  {{ 'md:' + field.width }}  ">
              <span class="p-float-label">
              <p-dropdown [options]="field.defaultValue" [autoDisplayFirst]="false" [showClear]="true" id="basic"></p-dropdown>
              <label for="basic">{{field.title}}</label>
                </span>
                </div>
          </ng-template>

        </ng-template>
    <button pButton class="mr-1"  icon="pi pi-search" label="GetData" (click)="getShipRequestData()"></button>
    </div>
   </div>


</p-overlayPanel>
<p-sidebar [(visible)]="property_dialog" position="right" [baseZIndex]="10000">
    <div class="grid p-fluid mt-2">
       
        <div class="field col-12 md:col-9">
            <p-dropdown [options]="consolidationtemplatefields" optionValue="fieldName"  optionLabel="fieldName" (onChange)="loadfieldProperties($event)"
              [autoDisplayFirst]="false" ></p-dropdown>

        </div>
        <div class="field col-12 md:col-3">
            <button pButton icon="pi pi-plus" (click)="openNewFielddialogflag=true"></button>
            
            </div>
        </div>
        <div class="card" [hidden]="displayproperties">
            <h5>FieldProperties</h5>
            <div class="grid p-fluid mt-3" >
            <div class="field md:col-12">
                <span class="p-float-label">
                    <input id="title" type="text" pInputText [(ngModel)]="currentselectedField.title" >
                    <label for="title">Title</label>
                  </span>
            </div>
            <div class="field md:col-12">
                <span class="p-float-label">
                <p-dropdown [options]="UItypes" [autoDisplayFirst]="false" 
                [(ngModel)]="currentselectedField.fieldType" id="fieldtype"></p-dropdown>
                 <label for="fieldtype">UIType</label>
                </span>

            </div>
            <div class="field md:col-12">
                <span class="p-float-label">
                <p-dropdown [options]="Datatypes"  [autoDisplayFirst]="false" 
                  [(ngModel)]="currentselectedField.datatype" id="datatype"></p-dropdown>
                 <label for="datatype">DataType</label>
                </span>

            </div>
            <div class="field md:col-12">
                <span class="p-float-label">
                    <input id="width" type="text" pInputText [(ngModel)]="currentselectedField.width" >
                    <label for="width"> Field Width(Ex: col-3,col-4)</label>
                  </span>
            </div>
            <div class="field md:col-12">
                <span class="p-float-label">
                    <input id="default" type="text" pInputText [(ngModel)]="currentselectedField.defaultValue">
                    <label for="default">Default Value</label>

                </span>
            </div>
            
            </div>
            <button pButton label="Update" (click)="updatefieldProperties(currentselectedField)"></button>

            </div>

</p-sidebar>
<p-dialog [(visible)]="openNewFielddialogflag" [style]="{width: '50%'}">

 <div class="grid p-fluid mt-2">
        <div class="field col-12 md:col-6">
            <span class="p-float-label">
                <input type="text" pInputText id="name" [(ngModel)]="newField['fieldName']">
                <label for="name">Field Name</label>
            </span>
            
             </div>
            <div class="field col-12 md:col-3">
                <button pButton label="Add" (click)="addNewField()"></button>
            </div>
           
            </div>
</p-dialog>
<app-warning-popup></app-warning-popup>