<div class="ship-now-sec">
  <div class="p-3">
    <form [formGroup]="form">
      <div class="grid">
        <div class="col-3">
            <div class="grid">
                <div class="col-3">
                  <label>Scan Product</label>
                </div>
                <div class="col-4">
                  <input pInputText formControlName="scanProduct" id="scanProduct" type="text" class="form-control" />
                </div>                
                <div class="col-2">
                  <label>Quantity</label>
                </div>
                <div class="col-3">
                <input pInputText formControlName="quantity" id="quantity" type="number" min="0"  (keypress)="validateInput($event)"  class="form-control" />
                </div>
            </div>
        </div>
        <div class="col-1">
             &nbsp;
        </div>
        <div class="col-8">
            <div class="grid">
              <div class="col-4">
                <div class="grid">
                  <div class="col-6">
                    <p-dropdown formControlName="packingOptions" [options]="packingOptions"></p-dropdown>
                  </div>
                  <div class="col-6">
                    <p-dropdown formControlName="dimensions" optionLabel="Name" optionValue="dimensionvalue" placeholder="Select"  [options]="Dimensions"></p-dropdown>
                  </div>
              </div>
              </div>
              <div class="col-4">
                <div class="grid">            
                  <div class="col-4">
                    <input pInputText formControlName="length"  placeholder="L" id="length" type="number" min="0"  (keypress)="validateInput($event)" class="form-control" />
                  </div>
                  <div class="col-4">
                    <input pInputText formControlName="width"  placeholder="W" id="width"  type="number" min="0"  (keypress)="validateInput($event)" class="form-control" />
                  </div>
                  <div class="col-4">
                    <input pInputText formControlName="height"   placeholder="H" id="height" type="number" min="0"  (keypress)="validateInput($event)" class="form-control" />
                  </div> 
                </div>
              </div>
              <div class="col-4">
                  <div class="grid">    
                    <div class="col-8">    
                      <div class="grid">            
                        <div class="col-6">
                          <input formControlName="package" pInputText  type="number" min="0"  (keypress)="validateInput($event)" />
                       </div>
                       <div class="col-6">
                         <input formControlName="weight" pInputText  type="number" min="0"  (keypress)="validateInput($event)" />
                       </div>
                      </div>  
                    </div>  
                    <div class="col-4">
                      <button  class="btn-default-css btnAutoPack" (click)="autoPack()">Auto Pack</button>
                    </div> 
                  </div>
              </div>
            </div>
        </div>

    </div>
    </form>
    
  
    <div>
      <div class="productGrid">
        <p-table [value]="products" class="p-datatable-sm mt-3" scrollable="true" [scrollHeight]="'400px'"  [style]="{'height':'25vh','overflow':'auto'}">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 5% !important">#</th>
            <th style="width: 250px !important">Product Code</th>
            <th style="width: 310px !important">Description</th>
            <th style="width: 200px !important">EPC-RFID No</th>

            <th  class="align-center" style="width: 150px !important">Dimensions</th>
            <th class="align-center" style="width: 120px !important">Total Qty</th>
            <th class="align-center" style="width: 120px !important">Balance Qty</th>
            <th class="align-center" style="width: 120px !important">Partial Qty</th>
            <th style="width: 120px !important;text-align: center;" >Is Serial</th>
            <th  style="width: 120px !important;text-align: center;">Is DG</th>
            <th style="width: 120px !important;text-align: center;"> 
             <img (click)="deleteAll()" class="btn-img" style="margin-left:13px !important;" src="../../assets/icon/material-symbols_delete-outline.png">
            </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product let-i="rowIndex">
            <tr>
              <td>{{ i + 1 }}</td>
              <td (click)="onRowClick(i)" pEditableColumn>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <div class="overflow-container">
                          <input 
                          pInputText 
                          (focus)="setFocus(i)" 
                          [(ngModel)]="product.product" 
                          name="product{{ i }}" 
                          (input)="manageRows(i)" 
                          maxlength="30" 
                          ngClass=""
                          #productInput="ngModel"
                          required
                          placeholder="Product Name" />
                        </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{ product.product }}
                    </ng-template>
                </p-cellEditor>
              </td>
              <td  (click)="onRowClick(i)" pEditableColumn>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <div class="overflow-container">
                          <input 
                          pInputText 
                          [(ngModel)]="product.description" 
                          name="description{{ i }}" 
                          (input)="manageRows(i)" 
                          maxlength="200" 
                          #descriptionInput="ngModel"
                          required
                          placeholder="Description" />
                        </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{ product.description }}
                    </ng-template>
                </p-cellEditor>
              </td>
              <td  (click)="onRowClick(i)" pEditableColumn>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <div class="overflow-container">
                          <input 
                          pInputText 
                          [(ngModel)]="product.epc" 
                          name="description{{ i }}" 
                          (input)="manageRows(i)" 
                          maxlength="200" 
                          #descriptionInput="ngModel"
                          required
                          placeholder="Description" />
                        </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{ product.epc }}
                    </ng-template>
                </p-cellEditor>
              </td>
              <td  (click)="onRowClick(i)" pEditableColumn>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <div class="overflow-container">
                      <div class="dimensionDropdown">
                        <p-dropdown
                        optionLabel="Name" placeholder="select" [options]="Dimensions" optionValue="Name" [(ngModel)]="product.dimension" (onChange)="onInputChange()" appendTo="body">
                    </p-dropdown>
                    <i class="pi pi-plus" (click)="addDimensionOpen($event,i,'products')"
                        style="cursor: pointer; color:gray; padding-top: 12px;"></i>
                  </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="output">
                    {{ product.dimension }}
                </ng-template>
                </p-cellEditor>
              </td>


            <td pEditableColumn (click)="onRowClick(i)" class="align-right">
              <p-cellEditor>
                  <ng-template pTemplate="input">
                      <div class="overflow-container">
                        <input  type="number" min="0"  (keypress)="validateInput($event)" maxlength='10000'pInputText [(ngModel)]="product.totalQuantity" (input)="updateBalanceQuantity(product)" />
                      </div>
                  </ng-template>
                  <ng-template pTemplate="output">
                      {{ product.totalQuantity }}
                  </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn (click)="onRowClick(i)" class="align-right">
              <p-cellEditor>
                  <ng-template pTemplate="input">
                      <div class="overflow-container">
                        <input  type="number" min="0"  (keypress)="validateInput($event)" pInputText [(ngModel)]="product.balanceQuantity" readonly/>
                      </div>
                  </ng-template>
                  <ng-template pTemplate="output">
                      {{ product.balanceQuantity }}
                  </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn (click)="onRowClick(i)" class="align-right">
              <p-cellEditor>
                  <ng-template pTemplate="input">
                      <div class="overflow-container">
                        <input type="number" min="0"  (keypress)="validateInput($event)" pInputText [(ngModel)]="product.partialQuantity"  />
                      </div>
                  </ng-template>
                  <ng-template pTemplate="output">
                      {{ product.partialQuantity }}
                  </ng-template>
              </p-cellEditor>
            </td>
              <td (click)="onRowClick(i)" style="text-align: center;"><input type="checkbox" [(ngModel)]="product.s" /></td>
              <td  (click)="onRowClick(i)" style="text-align: center;"><input type="checkbox" [(ngModel)]="product.isDG" /></td>
              <td style="text-align: center;">
                  <img (click)="deleteProduct(i)" class="btn-img" src="../../assets/icon/material-symbols_delete-outline.png">
              </td>
            </tr>
          </ng-template>
        </p-table>
        
      <button type="button" (click)="searchProducts()" class="m-2 btn-addressbook">Add Product</button>
      
      </div>
      <div class="row p-3 mr-5" style="background-color:#fff;">   
        <div class="text-center mt-1">
          <button (click)="openPackInfoDialog('Pack')" class="btn-default-css btnPack mr-3">
            <span class="d-flex align-items-center">
                <!-- <img src="../../assets/icon/pajamas_package.png" class="icon-img">  -->
                Pack
            </span>
        </button>
        <!-- (click)="packAllItems()" -->
        <button (click)="openPackInfoDialog('PackAll')" class="btn-default-css btnPackall mr-3">
          <span  class="d-flex align-items-center">
              <!-- <img src="../../assets/icon/pajamas_package.png" class="icon-img">  -->
              Pack All
          </span>
      </button>   
       <!-- <span class="total-bal mr-5">
        TOTAL BAL QTY: {{ getTotalBalanceQuantity() }}
       </span>      -->
        </div>
        </div>


      <div class="mt-1 packeditemsgrid">
        <p-table [value]="packedItems" 
        [responsiveLayout]="'scroll'"
        [style]="{'height':'15vh','overflow':'auto'}"
        class="p-datatable-sm mt-3 mb-5"
        [scrollable]="true"
        scrollHeight="flex" scrollable="true" [scrollHeight]="'400px'" class="p-datatable-sm mt-3 mb-5">
       <!-- Table Header -->
     <!-- Table Header -->
     <ng-template pTemplate="header">
       <tr>
         <th style="width: 5% !important">#</th>
       <th style="width: 150px !important">H.U.</th>
       <th style="width: 120px !important">Weight</th>
       <th *ngIf="packedItems.length > 0" style="width: 40px !important"></th>
       <th style="width: 120px !important" >Weight Units</th>
       <th style="width: 250px !important" >Dimensions</th>
       <th style="width: 250px !important">Dimension Units</th>
       <th style="width: 150px !important">Class</th>
       <th>Tracking#</th>
       <th style="width: 120px !important;text-align: center;" >
        <img (click)="deleteAllHU()" class="btn-img" style="margin-left: 13px !important;" src="../../assets/icon/material-symbols_delete-outline.png">
         </th>
       <!-- <th style="width: 150px !important" >
           Action
         </th> -->
       </tr>
     </ng-template>
     
     <!-- Table Body with Data -->
     <ng-template pTemplate="body" let-item let-i="rowIndex">
       <tr *ngIf="packedItems.length > 0">
         <td>{{ i + 1 }}</td>
         
         <td>
               <a href="javascript:void(0)" (click)="getProducts(item)">{{ item.HU }}</a>
         </td>      
       <td pEditableColumn class="align-right">
         <p-cellEditor>
             <ng-template pTemplate="input">
                 <div class="overflow-container">
                   <input pInputText type="number" min="0"  (keypress)="validateInput($event)" [(ngModel)]="item.Weight" (ngModelChange)="onInputChange()">
                 </div>
             </ng-template>
             <ng-template pTemplate="output">
                 {{ item.Weight }}
             </ng-template>
         </p-cellEditor>
       </td>
       <td *ngIf="packedItems.length > 0" style="cursor: pointer;position: relative;right: 5px;" (click)="getScaleweight(i)" pEditableColumn class="align-right">
         <i class="pi pi-box" style="color: #888;"></i>
       </td>    
       <td pEditableColumn>
         <p-cellEditor>
             <ng-template pTemplate="input">
                 <div class="overflow-container">
                   <input pInputText maxlength="3"  type="text" [(ngModel)]="item.WeightUnits" (ngModelChange)="onInputChange()">
                 </div>
             </ng-template>
             <ng-template pTemplate="output">
                 {{ item.WeightUnits }}
             </ng-template>
         </p-cellEditor>
       </td>
       <td pEditableColumn>
         <p-cellEditor>
             <ng-template pTemplate="input">
              <div class="dimensionDropdown">
                <p-dropdown
                optionLabel="Name" editable="true" [options]="Dimensions" optionValue="Name" [(ngModel)]="item.dimension" (onChange)="onInputChange()" appendTo="body">
            </p-dropdown>
            <i class="pi pi-plus" (click)="addDimensionOpen($event,i,'packedItems')"
                style="cursor: pointer; color:gray; padding-top: 12px;"></i>
          </div>
                 
             </ng-template>
             <ng-template pTemplate="output">
                 {{ item.dimension }}
             </ng-template>
         </p-cellEditor>
       </td>
       <td pEditableColumn>
         <p-cellEditor>
             <ng-template pTemplate="input">
                 <div class="overflow-container">
                   <input pInputText type="text" maxlength="2" [(ngModel)]="item.dimensionUnits" (ngModelChange)="onInputChange()">
   
                 </div>
             </ng-template>
             <ng-template pTemplate="output">
                 {{ item.dimensionUnits }}
             </ng-template>
         </p-cellEditor>
       </td>
       <td pEditableColumn>
         <p-cellEditor>
             <ng-template pTemplate="input">
                 <div class="overflow-container">
                   <input pInputText type="text" maxlength="20" [(ngModel)]="item.class" (ngModelChange)="onInputChange()">
   
                 </div>
             </ng-template>
             <ng-template pTemplate="output">
                 {{ item.class }}
             </ng-template>
         </p-cellEditor>
       </td>
       <td pEditableColumn>
         <p-cellEditor>
             <ng-template pTemplate="input">
                 <div class="overflow-container">
                   <input pInputText maxlength="50" [disabled]="true" type="text" [(ngModel)]="item.tracking" (ngModelChange)="onInputChange()">
   
                 </div>
             </ng-template>
             <ng-template pTemplate="output">
                 {{ item.tracking }}
             </ng-template>
         </p-cellEditor>
       </td>
         <td style="text-align: center;">
           <img (click)="deleteItem(item)" class="btn-img" src="../../assets/icon/material-symbols_delete-outline.png">
         </td>
         <!-- <td>
           <img  (click)="getProducts(item)" class="btn-img ml-5" src="../../assets/icon/tabler_search.png">
         </td> -->
       </tr>
     </ng-template>
     
       
     </p-table>
  <div *ngIf="packedItems.length === 0" class="row text-center mt-5 mr-5" >
      <span class="text-center mr-5" style="color: #999;
      font-size: 18px;
      font-weight: 450;
      ">
        No data available.
      </span>
  </div>
  <!-- No Data Template -->
    
      </div>
       
      <p-dialog showHeader="false" [(visible)]="displayDialog" [modal]="true"  
          [style]="{'width': '75%'}">
          <div class="dialog-header">
            <div class="grid m-0">
                <div class="col-10 py-0">
                    <h4 class="mb-0">Product Details</h4>
                </div>
                <div class="col-2 py-0 text-right">
                    <img src="../../../../assets/images/close-icon.png" class="dialog-close" (click)="close()" />
                </div>
            </div>
        </div>
        <ng-container *ngIf="productDetails">
          <div class="py-1 px-3">
            <div class="col-4" style="text-align: right">
              <input type="text" pInputText size="50" placeholder="Global Filter"
                     (input)="applyFilterGlobal($event)" style="width:auto">
            </div>
            <app-dialog-table [snoFlag]="true" [displayedData]="productDetails" [sharedViewFunction]="selectProduct.bind(this)"  [columns]="dialogColumns"></app-dialog-table>
            <!-- Table with pagination and template reference variable #dt -->
          </div>
         
        </ng-container>
      </p-dialog>
      
      <p-dialog showHeader="false" [(visible)]="displayProductDetails" [modal]="true"
          [style]="{'width': '75%'}"> 
          <div class="dialog-header">
            <div class="grid m-0">
                <div class="col-10 py-0">
                    <h4 class="mb-0">Product Details</h4>
                </div>
                <div class="col-2 py-0 text-right">
                    <img src="../../../../assets/images/close-icon.png" class="dialog-close" (click)="displayProductDetails=false" />
                </div>
            </div>
        </div>
        <app-dialog-table [displayedData]="selectedProduct.ItemsInfo" [snoFlag]="false"
        [columns]="selectedProductColumns"></app-dialog-table>
      </p-dialog>
      
      
      
      <!-- Delete All Button -->
    
      <!-- Table ends  -->
      
    </div>
  </div>
  <p-overlayPanel #adddimension  (onHide)="restDimensionForm()"  [showCloseIcon]="true" [dismissable]="true" [style]="{ width: '300px' }" [baseZIndex]="1000">
    <span style="font-weight: 600; font-size: 1.25rem;">Add Dimension</span>
    <div style="margin-top: 10px;">
        <label for="name">Name<p style="color: red; display: contents;">*</p></label>
        <input id="name" type="text" maxlength="30" pInputText [(ngModel)]="dimensionForm.Name" placeholder="Enter Name" (input)="isdimensionFormValid()" />
        <p *ngIf="dimensionNameFlag" style="color: red;">Dimension name already exist</p>
        <div class="dimensionform py-4">
            <input id="length" type="number" min="0" max="999" pInputText [(ngModel)]="dimensionForm.Length" placeholder="L"  (input)="dimensionOnInputChange('Length')"  (keypress)="validateInput($event)"  />

            <input id="width" type="number" min="0" max="999" pInputText [(ngModel)]="dimensionForm.Width" placeholder="W" (input)="dimensionOnInputChange('Width')"   (keypress)="validateInput($event)"  />
    
            <input id="height" type="number" min="0" max="999" pInputText [(ngModel)]="dimensionForm.Height" placeholder="H"  (input)="dimensionOnInputChange('Height')"  (keypress)="validateInput($event)"   />
        </div>
       
    </div>
    <div class="button-container">
      <div class="overlay-apply">
        <button type="button" class="btnShipColumnApply" (click)="saveDimension()"  [disabled]="!isdimensionFormValid()" >Save</button>
      </div>
    </div>
  </p-overlayPanel>
  <p-confirmDialog
    key="positionDialog"
    [position]="position"
  ></p-confirmDialog>
  <app-warning-popup></app-warning-popup>
  <app-confirm-popup></app-confirm-popup>
</div>


