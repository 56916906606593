<div class="ship-to-dev" style="height: 98vh;">
  <div class="row p-2 pl-0 pt-0">
    <div class="col-md-6 pr-0">
      <div class="form-section">
        <p-card class="titleTypoh3 ">
          <div class="header-container">
            <div class="grid col-12 p-0 m-0">
              <div class="col-8 p-0">  
                <div class="p-card-title align-btm title-border">
                      Ship From <span><img src="assets/images/ph_address-book-bold.png" (click)="showPopup('ShipFrom')" class="fa-icon"></span>
                <span class="pt-1" style="color: #514f4f;font-weight: normal;padding-left: 5px;font-size: 14px;"> - {{shipment.ShipFrom.LOCATIONNAME ? shipment.ShipFrom.LOCATIONNAME : shipment.ShipFrom.COMPANY}}</span>
                </div>
              </div>
              <div class="col-4 p-0">
              </div>
            </div>
          </div>
          <div class="form-grid">
            <div style="display: none;">               
            <div class="form-group">
              <label for="locationFrom">Location Id</label>
              <div class="p-inputgroup p-input-icon-right">   
                <i class="pi pi-search" (click)="showPopup('ShipFrom')"></i>
                <input pInputText maxlength="35"  placeholder="Location Name" id="locationFrom" [(ngModel)]="shipment.ShipFrom.LOCATIONID" (ngModelChange)="onInputChange()" [disabled]="isDisabled" />
              </div>
            </div>
            <div class="form-group">
              <label for="">Location Name</label>
              <input pInputText maxlength="35"  placeholder="Location Name" id="locationFrom" [(ngModel)]="shipment.ShipFrom.LOCATIONNAME" (ngModelChange)="onInputChange()" [disabled]="isDisabled" />
          </div>  
            </div>       
            <div class="form-group">
              <label for="company">Company Name</label>
              <input pInputText id="company" maxlength="35" [(ngModel)]="shipment.ShipFrom.COMPANY" (ngModelChange)="onInputChange()" [disabled]="isDisabled"/>
            </div>         
            <div class="form-group">
              <label for="contact">Contact Name</label>
              <input pInputText id="contact" maxlength="35" [(ngModel)]="shipment.ShipFrom.CONTACT" (ngModelChange)="onInputChange()" [disabled]="isDisabled"/>
            </div>
            <div class="form-group">
              <label for="company">Address Line 1</label>
              <input pInputText id="addressLine1" maxlength="35" [(ngModel)]="shipment.ShipFrom.ADDRESS_LINE1" (ngModelChange)="onInputChange()" [disabled]="isDisabled"/>
            </div>
            <div class="form-group">
              <label for="contact">Address Line 2</label>
              <input pInputText id="addressLine2" maxlength="35" [(ngModel)]="shipment.ShipFrom.ADDRESS_LINE2" (ngModelChange)="onInputChange()" [disabled]="isDisabled"/>
            </div>
            <div class="grid m-0">
              <div class="col-6 pt-0 pl-0">
                <div class="form-group">
                  <label for="city">City</label>
                  <input pInputText id="city" (ngModelChange)="onInputChange()" maxlength="35" [(ngModel)]="shipment.ShipFrom.CITY" [disabled]="isDisabled"/>
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="form-group">             
                  <label for="stateFrom">State</label>             
                     <!-- <p-dropdown [(ngModel)]="shipment.ShipFrom.STATE"[options]="states" ></p-dropdown> -->
                     <input (ngModelChange)="onInputChange()" pInputText id="State" maxlength="3" [(ngModel)]="shipment.ShipFrom.STATE" />
  
                </div>
              </div>
            </div>
            <div class="grid m-0">
                 <div class="col-6 pt-0 pl-0">
                  <div class="form-group">
                    <label for="Zipcode">Zip/Postal Code</label>
                    <input (ngModelChange)="onInputChange()" pInputText id="Zipcode" maxlength="10" [(ngModel)]="shipment.ShipFrom.ZIPCODE" [disabled]="isDisabled"/>
                  </div>
                 </div>
                 <div class="col-6 p-0">
                  <div class="form-group">
                    <label for="countryFrom">Country</label>
                    <p-dropdown optionValue="code" [(ngModel)]="shipment.ShipFrom.COUNTRY"  [options]="countries" (ngModelChange)="onInputChange()"
                         id="ShipFromCountry" optionLabel="name" (onChange)="changeCountryShipFrom($event)" [filter]="true" [disabled]="isDisabled"></p-dropdown>
                  </div>
                 </div>
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input (ngModelChange)="onInputChange()" pInputText id="email" maxlength="50" [(ngModel)]="shipment.ShipFrom.EMAIL" [disabled]="isDisabled"/>
            </div>
            <div class="grid m-0">
                <div class="col-6 pt-0 pl-0">
                  <div class="form-group">
                    <label for="phone">Phone No</label>
                    <input (ngModelChange)="onInputChange()" pInputText id="phone" maxlength="15" [(ngModel)]="shipment.ShipFrom.PHONE" [disabled]="isDisabled"/>
                  </div>
                </div>
                <div class="col-6 p-0">
                  <div class="form-group">
                    <label for="contact">Address Type</label>
                    <p-dropdown (ngModelChange)="onInputChange()" [options]="addressTypes" [(ngModel)]="shipment.ShipFrom.ADDRESS_TYPE" [disabled]="isDisabled"></p-dropdown>
                  </div>
                </div>
            </div>         
            <!-- Uncomment if needed -->
            <!--
            <div class="form-group">
              <label for="addressTypeFrom">Address Type</label>
              <p-dropdown [options]="addressTypes"></p-dropdown>
            </div>
            -->
          </div>
        </p-card>
  
      </div>
      
     </div>  
   
     <div class="col-md-6 pl-0 pr-0">
       <div class="form-section">
         <p-card class="titleTypoh3 ">
           <div class="header-container" style="margin-top: -40px !important;">
             <diV class="grid col-12 m-0 p-0">
                  <div class="col p-0">
                    <div class="p-card-title align-btm title-border p-inputgroup p-input-icon-right" style="margin-top: 8px !important;margin-bottom: 0px;">Ship To <span class="p-2"><img src="assets/images/ph_address-book-bold.png" (click)="showPopup('ShipTo')" class="fa-icon"></span> 
                      <span class="ship-to pl-1 pt-1"><i (click)="showPopupShipTo()" class="fa-regular fa-plus mt-0"></i></span>
                    </div>               
                  </div>
                  <div class="col p-0 d-flex justify-content-end">
                    <button class="btn-default-css btn-validate-address">Validate Address</button>
                  </div>
             </diV>
           </div>
           <div class="form-grid">
             <div class="form-group">
               <label for="locationFrom">Company Name</label>
               <div class="p-inputgroup p-input-icon-right">
                <!-- <i class="pi pi-search" (click)="openAddressBook('ShipTo')"></i> -->  
                 <input (ngModelChange)="onInputChange()" pInputText id="companyNameTo" maxlength="35" [(ngModel)]="shipment.ShipTo.COMPANY" [disabled]="isDisabled"/>
               </div>
             </div>
             <div class="form-group">
               <label for="locationName">Contact Name</label>
               <input  pInputText id="displayName" maxlength="35" [(ngModel)]="shipment.ShipTo.CONTACT" (ngModelChange)="onInputChange()" [disabled]="isDisabled"/>
             </div>            
             <div class="form-group">
               <label for="company">Address Line 1</label>
               <input pInputText id="addressLine1" maxlength="35" [(ngModel)]="shipment.ShipTo.ADDRESS_LINE1" (ngModelChange)="onInputChange()" [disabled]="isDisabled"/>
             </div>
             <div class="form-group">
               <label for="contact">Address Line 2</label>
               <input pInputText id="addressLine2" maxlength="35" [(ngModel)]="shipment.ShipTo.ADDRESS_LINE2" (ngModelChange)="onInputChange()" [disabled]="isDisabled"/>
             </div>
             <div class="form-group" style="display: none;">
                <label for="AddressLine 3">Address Line 3</label>
                <input pInputText id="addressLine3" maxlength="35" [(ngModel)]="shipment.ShipTo.ADDRESS_LINE3" (ngModelChange)="onInputChange()" [disabled]="isDisabled"/>
             </div>
             <div class="grid m-0">
                 <div class="col-6 pt-0 pl-0">
                  <div class="form-group">
                    <label for="city">City</label>
                    <input pInputText id="city" maxlength="35" [(ngModel)]="shipment.ShipTo.CITY" (ngModelChange)="onInputChange()" [disabled]="isDisabled"/>
                  </div>
                 </div>
                 <div class="col-6 p-0">
                  <div class="form-group">
                    <label for="stateFrom">State</label>
                   <!-- <p-dropdown [(ngModel)]="shipment.ShipTo.STATE" [options]="Master_states" [disabled]="isDisabled"></p-dropdown> -->
                   <input pInputText id="State" maxlength="3" [(ngModel)]="shipment.ShipTo.STATE" (ngModelChange)="onInputChange()" [disabled]="isDisabled"/>
  
                  </div>
                 </div>
             </div>        
             <div class="grid m-0">
                  <div class="col-6 pt-0 pl-0">
                    <div class="form-group">
                      <label for="Zipcode">Zip Code</label>
                      <input pInputText id="Zipcode" maxlength="10" [(ngModel)]="shipment.ShipTo.ZIPCODE" (ngModelChange)="onInputChange()" [disabled]="isDisabled"/>
                    </div>
                  </div>
                  <div class="col-6 p-0">
                    <div class="form-group">
                      <label for="countryFrom">Country</label>
                      <p-dropdown optionLabel="name" optionValue="code" id="countryTo" [style]="{'minWidth':'100px'}" [(ngModel)]="shipment.ShipTo.COUNTRY" (ngModelChange)="onInputChange()" [options]="countries" (onChange)="changeCountryShipTo($event)" [filter]="true" [disabled]="isDisabled"></p-dropdown>
                    </div>
                  </div>
             </div>
             <div class="form-group">
               <label for="city">Email</label>
               <input pInputText id="email" maxlength="50"  [(ngModel)]="shipment.ShipTo.EMAIL" (ngModelChange)="onInputChange()" [disabled]="isDisabled"/>
             </div>
             <div class="grid m-0">
                  <div class="col-6 pt-0 pl-0 pb-0">
                    <div class="form-group">
                      <label for="phone">Phone No</label>
                      <input pInputText id="phone" maxlength="20"  [(ngModel)]="shipment.ShipTo.PHONE" (ngModelChange)="onInputChange()" [disabled]="isDisabled"/>
                    </div>
                  </div>
                  <div class="col-6 p-0">
                    <div class="form-group">
                      <label for="addressTypeFrom">Address Type</label>
                      <p-dropdown  [options]="addressTypes" [(ngModel)]="shipment.ShipTo.ADDRESS_TYPE" (ngModelChange)="onInputChange()" [disabled]="isDisabled"></p-dropdown>
                    </div>
                  </div>
             </div>
           </div>
         </p-card>
      
     </div>  
   </div>
   <app-address-book-popup #popup (onSelect)="handleAddressSelect($event)" (onClose)="handlePopupClose()">
  
   </app-address-book-popup>
   <app-ship-to #shipToPopup  [addressTypes]="addressTypes" [isDisabled]="isDisabled" [countries]="countries" (onClose)="handlePopupClose()"></app-ship-to>

  </div>
  <div class="row">
    <app-dashboard-table  [shipment]="shipment"></app-dashboard-table>
   </div>
</div>

