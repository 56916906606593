<!-- Topbar -->
<div class="layout-topbar" style="background-color: #f0f1f8 !important;" *ngIf="isDataLoaded && shouldShowSidenav()">
  <div class="topbar-content">
    <!-- Sidebar toggle button (icon) -->
    <div class="nav-icon" (click)="toggle()" [ngClass]="{'close': !isOpen}">
      <img src="assets/images/nav-icon.png" alt="Menu Icon">
    </div>
    <!-- Title -->
    <div routerLink="/home/DashBoards" class="title">ShipperCopilot 6.4</div>
  </div>
  <ul class="topbar-menu fadeInDown" [ngClass]="{'topbar-menu-visible': appMain.topbarMenuActive}">


    <li #profile class="user-profile" *ngIf="app.profileMode==='popup'||appMain.isHorizontal()"
        [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === profile}">

      <a href="#" (click)="appMain.onTopbarItemClick($event,profile)">

        <p-avatar [image]="profileImage" size="large" shape="circle">
        </p-avatar>


      </a>

      <ul class="fadeInDown">
        <li role="menuitem">
          <a href="#" (click)="appMain.onTopbarSubItemClick($event)" (click)="openProfile()">
            <i class="pi pi-user"></i>
            <span>Profile</span>
          </a>
        </li>

        <li role="menuitem">
          <a href="#" (click)="appMain.onTopbarSubItemClick($event)" (click)="processLogout()">
            <i class="pi pi-sign-out"></i>
            <span>Sign out</span>
          </a>
        </li>
      </ul>
    </li>
    <li>
      <a>
        <i class="fa-regular fa-bell" style="font-size: 22px;margin-top: 9px;"></i>
      </a>
    </li>

    <li #settings [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === settings}">
      <a href="#" (click)="appMain.onTopbarItemClick($event,settings)">
        <i class="topbar-icon pi pi-cog" style="font-size: 20px !important"
           styleClass="p-button-rounded p-button-text"></i>
        <span class="topbar-item-name">Settings</span>

      </a>
      <ul class="fadeInDown">
        <li role="menuitem">
          <a href="#" style="display: none;" (click)="appMain.onTopbarSubItemClick($event)"
             (click)="processTheam()">
            <i class="pi pi-pencil"></i>
            <span>Change Theme</span>

          </a>
        </li>
        <li role="menuitem">
          <a routerLink='/home/SpecialService' (click)="appMain.onTopbarSubItemClick($event)">
            <i class=" pi pi-cog"></i>
            <span>Special Services</span>
          </a>
        </li>
        <li role="menuitem">
          <a routerLink='/home/consolidationTemplate' (click)="appMain.onTopbarSubItemClick($event)">
            <i class=" pi pi-cog"></i>
            <span>Consolidation Template</span>
          </a>
        </li>

        <li role="menuitem">
          <a routerLink='/home/DynamicForms' (click)="appMain.onTopbarSubItemClick($event)">
            <i class=" pi pi-file"></i>
            <span>Dynamic Forms</span>
          </a>
        </li>

        <li role="menuitem">
          <a routerLink='/home/RoutingGuideParameters' (click)="appMain.onTopbarSubItemClick($event)">
            <i class=" pi pi-file"></i>
            <span>Routing Guide Settings</span>
          </a>
        </li>

        <li role="menuitem">
          <a routerLink='/home/RoutingGuideDefaults' (click)="appMain.onTopbarSubItemClick($event)">
            <i class=" pi pi-file"></i>
            <span>Routing Guide Defaults</span>
          </a>
        </li>

        <li role="menuitem">
          <a routerLink='/home/RGFieldPriority' (click)="appMain.onTopbarSubItemClick($event)">
            <i class=" pi pi-file"></i>
            <span>Routing Filed Priority</span>
          </a>
        </li>

      </ul>
    </li>



    <li #masterConfig2 [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === masterConfig2}">
      <!-- <a href="#" (click)="appMain.onTopbarItemClick($event,masterConfig2)" (click)="locop.toggle($event)" (click)="getForms()">
        <i class="topbar-icon pi pi-plus"></i>
        <span class="topbar-item-name">Settings</span>
    </a> -->
      <!-- {{'V 1.x'}} -->
      <a href="#" (click)="appMain.onTopbarItemClick($event,masterConfig2)" (click)="locop.show($event)"
         (click)="getForms()">
        <i class="topbar-icon pi pi-list" style="font-size: 20px !important"></i>
        <span class="topbar-item-name">Settings</span>
      </a>


    </li>

    <li #masters [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === masters}">
      <a href="#" (click)="appMain.onTopbarItemClick($event,masters)">
        <i class="topbar-icon pi pi-plus" style="font-size: 20px !important" styleClass="p-button-rounded p-button-text"></i>
        <span class="topbar-item-name"></span>
      </a>
      <ul class="fadeInDown mastersScreensCss">
        <div class="grid m-0">
          <div class="col pl-0">
            <ul class="menu-items-css" style="list-style-type: none;padding-left: 0px;">
              <li role="menuitem">
                <a routerLink="newlocation">
                  <span>Locations </span>
                </a>
              </li>
              <li role="menuitem">
                <a routerLink="newusers">
                  <span>Users</span>
                </a>
              </li>
              <li role="menuitem">
                <a routerLink="newerp">
                  <span>ERP</span>
                </a>
              </li>
              <li role="menuitem">
                <a routerLink="newordertype">
                  <span>Order Types</span>
                </a>
              </li>
              <li role="menuitem">
                <a routerLink="newdimensions">
                  <span>Dimensions</span>
                </a>
              </li>
              <li role="menuitem">
                <a routerLink="newaddressbook">
                  <span>Address Book</span>
                </a>
              </li>
              <li role="menuitem">
                <a routerLink="newproduct">
                  <span>Product</span>
                </a>
              </li>
              <li role="menuitem">
                <a routerLink="newcarrier">
                  <span>Carrier</span>
                </a>
              </li>
              <li role="menuitem">
                <a routerLink="newcarrieraccounts">
                  <span>Carrier Accounts</span>
                </a>
              </li>
              <li role="menuitem">
                <a routerLink="newuserrole">
                  <span>Users Roles</span>
                </a>
              </li>
<!-- 
              <li role="menuitem">
                <a><span>Dimensions</span></a>
              </li>
              <li role="menuitem"><a><span>Payment Types</span></a></li>
              <li role="menuitem"><a><span>Cost Center</span></a></li>
              <li role="menuitem"><a><span>Shipment Status</span></a></li>
              <li role="menuitem"><a><span>Return Addresses</span></a></li>
              <li role="menuitem"><a><span>Incoterms</span></a></li>
              <li role="menuitem"><a><span>Order Types</span></a></li>
              <li role="menuitem"><a><span>Advanced Routing Guide</span></a></li>
              <li role="menuitem"><a><span>Handling Charges</span></a></li>
              <li role="menuitem"><a><span>Reasons Codes</span></a></li>
              <li role="menuitem"><a><span>LTL Classes</span></a></li>
              <li role="menuitem"><a><span>NMFC</span></a></li>
              <li role="menuitem"><a><span>Tracking URLS</span></a></li>
              <li role="menuitem"><a><span>Commodity Description</span></a></li>
              <li role="menuitem"><a><span>Embargo</span></a></li>
              <li role="menuitem"><a><span>MOT</span></a></li>
              <li role="menuitem"><a><span>Surcharges</span></a></li>
              <li role="menuitem"><a><span>Dimension Factor</span></a></li>
              <br>
              <li role="menuitem"><a><span>ACE AESDirect</span></a></li>
              <li role="menuitem"><a><span>DG License Configuration</span></a></li>
              <li role="menuitem"><a><span>Suppliers</span></a></li>
              <li role="menuitem"><a><span>Supplier Location</span></a></li>
              <li role="menuitem"><a><span>Supplier User Registration </span></a></li>
              <li role="menuitem"><a><span>Supplier Advanced Routing Guide</span></a></li> -->
            </ul>
          </div>
          <div class="col">
            <ul class="menu-items-css" style="list-style-type: none;padding-left: 0px;">
              <li role="menuitem">
                <a>
                  <span>Company Settings</span>
                </a>
              </li>
              <li role="menuitem">
                <a>
                  <span>Prefix Format</span>
                </a>
              </li>

              <li role="menuitem">
                <a>
                  <span>Products</span>
                </a>
              </li>

              <li role="menuitem">
                <a><span>Regions</span></a>
              </li>
              <li role="menuitem"><a><span>Trailor</span></a></li>
              <li role="menuitem"><a><span>DG Template</span></a></li>
              <li role="menuitem"><a><span>DG MOT Configuration</span></a></li>
              <li role="menuitem"><a><span>Forms and Labels</span></a></li>
              <br>
              <li role="menuitem"><a><span>Users</span></a></li>
              <li role="menuitem"><a><span>Roles</span></a></li>
              <li role="menuitem"><a><span>Packing Material</span></a></li>
              <li role="menuitem"><a><span>Cartonization Rules</span></a></li>
              <br>
              <li role="menuitem"><a><span>Messages</span></a></li>
              <li role="menuitem"><a><span>Package Types</span></a></li>
              <li role="menuitem"><a><span>Container </span></a></li>
              <li role="menuitem"><a><span>Container Type</span></a></li>
              <li role="menuitem"><a><span>Freight Forwarder</span></a></li>
              <li role="menuitem"><a><span>Carrier Communication</span></a></li>
              <li role="menuitem"><a><span>Third Party Configuration</span></a></li>
              <li role="menuitem"><a><span>LTL Class NMFC</span></a></li>
              <li role="menuitem"><a><span>Carrier Rates</span></a></li>
              <li role="menuitem"><a><span>Ports</span></a></li>
              <br>
            </ul>
          </div>
          <div class="col">
            <ul class="menu-items-css" style="list-style-type: none;padding-left: 0px;">
              <li role="menuitem">
                <a>
                  <span>Import Data</span>
                </a>
              </li>
              <li role="menuitem">
                <a>
                  <span>EFA Tolerance Levels</span>
                </a>
              </li>

              <li role="menuitem">
                <a>
                  <span>Integration Manager</span>
                </a>
              </li>

              <li role="menuitem">
                <a><span>EDI Configuration</span></a>
              </li>
              <li role="menuitem"><a><span>SMTP Configuration</span></a></li>
              <li role="menuitem"><a><span>Generic Email API Configuration</span></a></li>
              <li role="menuitem"><a><span>System Configuration</span></a></li>
              <li role="menuitem"><a><span>ERP Configuration</span></a></li>
              <br>
              <li role="menuitem"><a><span>E-mail Templates</span></a></li>
              <li role="menuitem"><a><span>Registration API</span></a></li>
              <li role="menuitem"><a><span>Access License API</span></a></li>
              <li role="menuitem"><a><span>Printer Configuration</span></a></li>
              <li role="menuitem"><a><span>Field Permissions</span></a></li>
            </ul>
          </div>
        </div>
      </ul>
    </li>
  </ul>
</div>

<!-- Sidebar -->
<div *ngIf="isDataLoaded && shouldShowSidenav()" class="sidebar" [ngClass]="{'open': isOpen}">
  <!-- <div class="sidenav-header">
      <div class="header-icon" (click)="toggle()">
          <img src="assets/images/nav-icon.png" alt="Edit">
      </div>
      <div class="header-icon">
          <img src="assets/images/create-new.png" alt="Edit">
      </div>
  </div> -->
  <div class="sidenav-content mt-3">
    <!-- <div style="border-top: 2px solid #d9d9d9;border-bottom: 1px;height: 12px;"></div>
    <div style="background-color: #fff;  height: 12px;border-top: 2px solid #d9d9d9;"></div> -->

    <div class="copilot-cls" style="margin-top: -10px;" (click)="reloadCurrentRoute('/home/copilot')" routerLinkActive="active-link" routerLink='/home/copilot'>
      <div class="copilot-icon">
        <img src="assets/images/copilot.png" alt="Edit">
      </div>
      <div class="sidenav-title">ShipperCopilot</div>
    </div>
    <div class="wrap">
      <div class="links">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
    <div class="fields-container">
      <div class="sidenav-field" routerLinkActive="active-link" (click)="reloadCurrentRoute('/home/orders')" routerLink='/home/orders'>
        <div class="d-flex">
          <div class="field-icon">
            <img src="assets/images/order_icon.png" alt="Edit">
          </div>
          <div class="sidenav-title">Orders</div>
        </div>
        <i class="pi pi-bookmark"></i>
      </div>
      <div class="sidenav-field" routerLinkActive="active-link" (click)="reloadCurrentRoute('/home/shiprequest')" routerLink='/home/shiprequest'>
        <div class="d-flex">
          <div class="field-icon">
            <img src="assets/images/shiprequest_icon.png" alt="Edit">
          </div>
          <div class="sidenav-title">Ship Request</div>
        </div>
        <i class="pi pi-bookmark"></i>
      </div>


      <div class="wrap">
        <div class="links">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
      <!-- <div class="wrap">
          <div class="links">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div> -->

      <div class="sidenav-field" routerLinkActive="active-link" (click)="reloadCurrentRoute('/home/shipnow')" routerLink='/home/shipnow'>
        <div class="d-flex">
          <div class="field-icon">
            <img src="assets/images/shipnow_icon.png" alt="Edit">
          </div>
          <div class="sidenav-title">Ship Now </div>
        </div>
        <i class="pi pi-bookmark"></i>
      </div>
      <div class="sidenav-field" routerLinkActive="active-link" (click)="reloadCurrentRoute('/home/quotenow')" routerLink='/home/quotenow'>
        <div class="d-flex">
          <div class="field-icon">
            <img src="assets/images/quotenow.png" alt="Edit">
          </div>
          <div class="sidenav-title">Quote Now</div>
        </div>
        <i class="pi pi-bookmark"></i>
      </div>
      <div class="sidenav-field" routerLinkActive="active-link" (click)="reloadCurrentRoute('/home/TrackShipment')" routerLink='/home/TrackShipment'>
        <div class="d-flex">
          <div class="field-icon">
            <img src="assets/images/track_icon.png" alt="Edit">
          </div>
          <div class="sidenav-title">Track Now</div>
        </div>
        <i class="pi pi-bookmark"></i>
      </div>


      <!-- <div class="wrap">
          <div class="links">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div> -->
      <div class="wrap">
        <div class="links">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>

      <div class="sidenav-field" routerLinkActive="active-link" (click)="reloadCurrentRoute('/home/Manifest')" routerLink='/home/Manifest'>
        <div class="d-flex">
          <div class="field-icon">
            <img src="assets/images/track_icon.png" alt="Edit">
          </div>
          <div class="sidenav-title">Manifest</div>
        </div>
        <i class="pi pi-bookmark"></i>
      </div>

      <div class="wrap">
        <div class="links">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
      <!-- <div class="wrap">
              <div class="links">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </div> -->



      <div class="sidenav-field" routerLinkActive="active-link" (click)="reloadCurrentRoute('/home/quick')" routerLink='/home/quick'>
        <div class="d-flex">
          <div class="field-icon">
            <img src="assets/images/quickship_icon.png" alt="Edit">
          </div>
          <div class="sidenav-title">Quickship</div>
        </div>
        <i class="pi pi-bookmark"></i>
      </div>
      <div class="sidenav-field" routerLinkActive="active-link" (click)="reloadCurrentRoute('/home/batchship')" routerLink='/home/batchship'>
        <div class="d-flex">
          <div class="field-icon">
            <img src="assets/images/batchship_icon.png" alt="Edit">
          </div>
          <div class="sidenav-title">Batch Ship</div>
        </div>
        <i class="pi pi-bookmark"></i>
      </div>


      <div class="wrap">
        <div class="links">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
      <!-- <div class="wrap">
              <div class="links">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </div> -->

      <div class="sidenav-field" routerLinkActive="active-link" (click)="reloadCurrentRoute('/home/rfid')" routerLink='/home/rfid'>
        <div class="d-flex">
          <div class="field-icon">
            <img src="assets/images/RFID_icon.png" alt="Edit">
          </div>
          <div class="sidenav-title">RFID Nexus</div>
        </div>
        <i class="pi pi-bookmark"></i>
      </div>
      <!-- <div class="wrap">
                  <div class="links">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </div>
                </div> -->
      <div class="wrap">
        <div class="links">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>

      <div class="sidenav-field"  routerLinkActive="active-link" (click)="reloadCurrentRoute('/home/DashBoards')" routerLink='/home/DashBoards'>
        <div class="d-flex">
          <div class="field-icon">
            <img src="assets/images/dashboard_icon.png" alt="Edit">
          </div>
          <div class="sidenav-title">Dashboard</div>
        </div>
        <i class="pi pi-bookmark"></i>
      </div>
      <div class="wrap">
        <div class="links">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
      <!-- <div class="wrap">
                  <div class="links">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </div>
                </div> -->

      <div class="sidenav-field" routerLinkActive="active-link" (click)="reloadCurrentRoute('/home/returns')" routerLink='/home/returns'>
        <div class="d-flex">
          <div class="field-icon">
            <img src="assets/images/returns_icon.png" alt="Edit">
          </div>
          <div class="sidenav-title">Returns</div>
        </div>
        <i class="pi pi-bookmark"></i>
      </div>
      <div class="sidenav-field" routerLinkActive="active-link" (click)="reloadCurrentRoute('/home/routingguide')" routerLink='/home/routingguide'>
        <div class="d-flex">
          <div class="field-icon">
            <img src="assets/images/returns_icon.png" alt="Edit">
          </div>
          <div class="sidenav-title">Routing Guide</div>
        </div>
        <i class="pi pi-bookmark"></i>
      </div>
    </div>
  </div>
</div>

<!-- Main Content Wrapper -->
<div class="content-wrapper" [ngClass]="{'shifted': isOpen}">
  <router-outlet></router-outlet>
</div>


<div *ngIf="isDataLoaded && shouldShowSidenav()" class="footer-Section" style="color:#b4afaf;position: fixed !important;bottom: 0 !important;width: 100% !important;background: #292929 !important;height: 40px !important;line-height: 25px;">
  <div class="grid m-0">
    <div class="col text-center">
      <div class="m-0 d-flex align-items-center" style="justify-content: center;">
        <span class="material-symbols-outlined" style="font-size: 16px;padding-right: 5px;">copyright</span><span class="footer-lbl" style="font-size: 13px;font-family: AvenirNextCyrMedium !important;">2024 eshipjet.ai, Inc. All Rights Reserved.</span>
      </div>
    </div>
  </div>
</div>
<app-config></app-config>

<p-overlayPanel [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" #locop>
  <p-panelMenu [model]="items" [style]="{'width':'300px'}"></p-panelMenu>
</p-overlayPanel>

<p-dialog [(visible)]="chatflag" [style]="{ width: '70%' }" [header]="'Profile : ' + udetails['user_id']  "
          [modal]="true" styleClass="p-fluid">
  <app-chatbot></app-chatbot>
</p-dialog>



<p-dialog [(visible)]="profileflag" [style]="{ width: '70%' }" [header]="'Profile : ' + udetails['user_id']  "
          [modal]="true" styleClass="p-fluid">

  <div class="grid p-fluid">
    <div class="col-12 md:col-12 mt-3" hidden="true">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="user['user_id']" />
        <label>First Name</label>
      </span>
    </div>
    <div class="col-12 md:col-6 mt-3">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="user['FirstName']" />
        <label>First Name</label>
      </span>
    </div>
    <div class="col-12 md:col-6 mt-3">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="user['LastName']" />
        <label>Last Name</label>
      </span>
    </div>
    <div class="col-12 md:col-6 mt-3">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="user['Address1']" />
        <label>Address1</label>
      </span>
    </div>

    <div class="col-12 md:col-6 mt-3">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="user['Address2']" />
        <label>Address2</label>
      </span>
    </div>
    <div class="col-12 md:col-6 mt-3">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="user['City']" />
        <label>City</label>
      </span>
    </div>
    <div class="col-12 md:col-6 mt-3">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="user['State']" />
        <label>State</label>
      </span>
    </div>
    <div class="col-12 md:col-6 mt-3">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="user['ZipCode']" />
        <label>Zip Code</label>
      </span>
    </div>
    <div class="col-12 md:col-6 mt-3">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="user['Country']" />
        <label>Country</label>
      </span>
    </div>
    <div class="col-12 md:col-6 mt-3">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="user['Phone']" />
        <label>Phone</label>
      </span>
    </div>
    <div class="col-12 md:col-6 mt-3">
      <span class="p-float-label">
        <input type="text" pInputText [(ngModel)]="user['E-mail']" />
        <label>E-mail</label>
      </span>
    </div>


    <p-image [src]="'data:image/png;base64,' + user['Image']" alt="Image" width="150" [preview]="true"></p-image>
    <p-fileUpload name="myfile" [maxFileSize]="50000" accept="{{filetype}}" mode="basic" chooseLabel="Select Image"
                  (onSelect)="fileToBase64($event.files[0])"></p-fileUpload>



  </div>
  <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-sm" (click)="Save()"></button>
</p-dialog>
