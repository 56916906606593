import { Component, OnInit, Renderer2, ElementRef,ViewChild, AfterViewChecked, Input, NgZone } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Message,CopilotchatbotService} from './copilotchatbot.service';
import { ERPSHIPService } from '../erpship.service';
import { MessageService } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { UserDataService } from '../services/user-data.service';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { DialogModule } from 'primeng/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SidebarService } from '../sidebar.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-copilot',
  standalone: true,
  imports: [CommonModule,FormsModule, DialogModule],
  templateUrl: './copilot.component.html',
  styleUrl: './copilot.component.scss',
  providers: [CopilotchatbotService,ERPSHIPService,MessageService],
  animations: [
    trigger('fadeOut', [
      state('visible', style({
        opacity: 1,
      })),
      state('hidden', style({
        opacity: 0,
      })),
      transition('visible => hidden', [
        animate('0.5s ease-out')
      ]),
      transition('hidden => visible', [
        animate('0.5s ease-in')
      ])
    ])
  ]
})
export class CopilotComponent implements OnInit,AfterViewChecked  {
  @ViewChild('chatContainer') private chatContainer: ElementRef;
  isCollapsed = false;
  isSidebarOpen = false;
  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
  }
  showLogo: boolean = true;
  newChat() {}
  actions = [
    {
      label: 'Delayed Shipments',
      image: '../../assets/images/delayedshipment.png',
    },
    {
      label: 'High Priority Shipments',
      image: '../../assets/images/highpriorityshipment.png',
    },
    {
      label: 'Total Shipments processed today',
      image: '../../assets/images/lucide_truck.png',
    },
    {
      label: 'High Value Shipments',
      image: '../../assets/images/solar_box-outline.png',
    },
  ];
  connectList = [
    {
      label: 'Connect to SAP S/4 HANA',
      icon: 'assets/images/sap.png',
    },
    {
      label: 'Connect to Sage',
      icon: 'assets/images/sage.png',
    },
    {
      label: 'Connect to Microsoft Dynamics 365',
      icon: 'assets/images/microsoft-dynamics.png',
    },
    {
      label: 'Connect to Salesforce',
      icon: 'assets/images/salesforce.png',
    },
    {
      label: 'Connect to Infor M3',
      icon: 'assets/images/infor.png',
    },
    {
      label: 'Connect to Oracle EBS Cloud',
      icon: 'assets/images/oracle.png',
    },
  ];
 
  // Additional logic to handle chat input and communication with the backend

  messages: Message[] = [];

  base64Image: string = '';
  showImage: boolean = false;
  trackmsgfedex: any = '';
  trackmsgups: any = '';
  carrierNamefedex: string = '';
  carrierNameups: string = '';

  clearShip: any = {};
  shipment: any = {};
  checkspc: Boolean;
  trackmsg: any = '';
  //super master data
  Original_LocationMasterData: any;
  LocationMasterData: any;

  gridData: any[] = [];
  lowestPrice: number | null = null;  

  
  value: any;
  username: String;
  timeOfDay: string = '';
 
  columns: { header: string; alias: string; align: string; extraText?: string }[] = []; // Array to define columns
  lowestValueIndex: number = 0; 
  userData : any;
  userid : any;
  respusername: any;
  trackcolumns: { header: string; alias: string; align: string; extraText?: string, width: string, height: string }[] = [];


  colorDots: string[] = ['#ff0000', '#ffa500', '#ffff00', '#008000', '#0000ff', '#4b0082', '#ee82ee']; // Color dots array
  expandedRowIndex: number | null = null; // Keep track of the expanded row

  showGrid: boolean = false;
  showConnect: boolean = false;
  openImageDialog: boolean = false;
  shipImage: any;
  carrierName: string = '';
  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  constructor( 
    public CopilotchatbotService: CopilotchatbotService,
    private xfservice: ERPSHIPService,
    public messageService: MessageService,
    private userContext : UserDataService,
    private http: HttpClient,
    private renderer: Renderer2, private el: ElementRef,
    private sanitizer: DomSanitizer,
    private sidebarService: SidebarService,
    private ngZone: NgZone
) {
   this.userData = this.userContext.getDecryptedCookie('usercontext');
}


get logoState() {
  return this.showLogo ? 'visible' : 'hidden';
}

 ngOnInit(): void {
  this.sidebarService.sidebarState$.subscribe((state: boolean) => {
    this.isSidebarOpen = state;
    console.log('Sidebar state changed:', state);  // Check if state change is triggered
    this.ngZone.run(() => {
      if (this.isSidebarOpen) {
        this.renderer.addClass(document.body, 'sidebar-open');
      } else {
        this.renderer.removeClass(document.body, 'sidebar-open');
      }
    });
  });
  this.getUserDetails();
  this.xfservice.getUserProfile(this.userid).subscribe((val)=>{
    const objdata = val;
    this.respusername = objdata[0].details?.FirstName;
    this.timeOfDay = this.getTimeOfDay(); 
  });

  this.CopilotchatbotService.conversation.subscribe((val) => {
  this.messages = this.messages.concat(val);
  
  if (this.messages[1].type === 'json-array' && this.messages[1].content.length > 0) {

    const values = this.messages[1].content.map(item => parseFloat(item['discountFreight']));
    const minValue = Math.min(...values);
    
    this.lowestValueIndex = values.indexOf(minValue);
   
  }
  this.CopilotchatbotService.messages = this.messages;
  
 });

 this.columns = [
  { header: 'Carrier', alias: 'Carrier', align: 'left' },
  { header: 'serviceName', alias: 'Service Name', align: 'left' },
  { header: 'publishedFreight', alias: 'Published Rate$', align: 'right' },
  { header: 'discountFreight', alias: 'Discounted Rate$', align: 'right' },
  { header: 'transitDay', alias: 'Estimated Delivery Days', align: 'center' },
  { header: 'expectedDeliveryDate', alias: 'Arrival On', align: 'center' }
];

this.trackcolumns = [
  { header: 'start', alias: 'Date Timestamp', align: 'center', width: '40%', height: '37px' },
  { header: 'description', alias: 'Status', align: 'left', width: '40%', height: '37px' },
  { header: 'location', alias: 'Location', align: 'left', width: '40%', height: '37px' }
];

}

ngOnDestroy() {
  // Remove the class when the component is destroyed to avoid potential issues
  document.body.classList.remove('sidebar-open');
}
getUserDetails(): any {
  // let cookies = document.cookie;
  // let cparts = cookies.split('=');
  var udetails = this.userData.body;
  this.userid = udetails.user_id;
  return udetails.user_id;
}

//Get The time of the Day
getTimeOfDay(): string {
  const currentHour = new Date().getHours(); // Get the current hour (0-23)

  if (currentHour >= 5 && currentHour < 12) {
    return 'Good morning ' + this.respusername +', I\'m ready for the task';
  } else if (currentHour >= 12 && currentHour < 17) {
    return 'Good Afternoon ' + this.respusername +', I\'m ready for the task';
  } else if (currentHour >= 17 && currentHour < 21) {
    return 'Good Evening ' + this.respusername +', I\'m ready for the task';
  } else {
    return 'Good Evening ' + this.respusername +', I\'m ready for the task';
  }
}

svgContent: string = "";
svgResponse: String = "";
getColorClass(description: string): string {
  switch (description) {
    case 'Delivered':
      return 'green-dot';
    case 'PENDING':
      return 'yellow-dot';
    case 'IN TRANSIT':
      return 'blue-dot';
    default:
      return 'default-dot'; // fallback class
  }
}

onInputChange() {
  // Hide the logo when the user types anything in the input field
  this.showLogo = this.value === '';
}
sendMessage() {
  this.showLogo = this.value === '';
  this.gridData = [];
  if(this.value.includes(' ')){
    this.checkspc = true;
  }
  if(this.checkspc){
    const words: string[] = this.value.split(" ");
    if(this.value.includes('TRACK') || this.value.includes('Track') || this.value.includes('track')){
      this.messages.push({ author: 'user', content: this.value ,type:'text'});
      if(words[1] == "DL0000000085"){
        
        //setTimeout(()=>{
          this.carrierNamefedex = "FedEx";
          let custommsg: any = "Tracking details provided for Delivery "+ words[1] +" by ShipperCopilot.";
          //this.trackmsg = words[1];
          //DL456789098765
          this.trackmsgfedex = "411819246142";
          this.messages.push({ author: 'user', content: custommsg ,type:'custom-text'});
        //}, 200);
        const data  = {"shipmentStatus":[{"result":{"pro":531041028,"billOfLadingNumber":638157062344974300,"purchaseOrder":"NS","status":"DELIVERED","statusDateTime":"2023-04-08T03:50:10","comment":"","consignee":{"name":"MARKS FARM","address1":"6314 CANNAN RD","address2":"","city":"LOWVILLE","state":"NY","zip":133674802},"shipper":{"name":"ARMOR ANIMAL HEALTH","address1":"3910 N GEORGE ST EXT","address2":"","city":"MANCHESTER","state":"PA","zip":17345},"billTo":{"name":"ARMOR ANIMAL HEALTH","address1":"3910 N GEORGE ST EXT","address2":"","city":"MANCHESTER","state":"PA","zip":17345},"interlineFrom":"","interlineFromPro":"","interlineTo":"","interlineToPro":"","shipmentDetails":{"weight":333,"pieces":1},"statusHistory":{"statusDetail":[{"description":"PICKED UP","location":"MANCHESTER, PA","start":"2023-03-29 14:15:00","end":"2023-03-29T14:15:00"},{"description":"DEPARTED TERMINAL","location":"YORK, PA","start":"2023-03-29 19:48:00","end":"2023-03-29T19:48:00"},{"description":"ARRIVED AT TERMINAL","location":"SYRACUSE, NY","start":"2023-03-30 00:37:00","end":"2023-03-30T00:37:00"},{"description":"OUT FOR DELIVERY","location":"SYRACUSE, NY","start":"2023-03-30 09:03:00","end":"2023-03-30T09:03:00"},{"description":"OUT FOR DELIVERY","location":"SYRACUSE, NY","start":"2023-03-31 08:50:00","end":"2023-03-31T08:50:00"},{"description":"DELIVERED","location":"LOWVILLE, NY","start":"2023-03-31 14:22:00","end":"2023-03-31T14:22:00"},{"description":"ETA WINDOW","location":"LOWVILLE, NY","start":"2023-03-31 16:22:00","end":"2023-03-31T17:22:00"}]}}},{"result":{"pro":531041028456789,"errorMessage":"We are unable to find this shipment."}}]};
        const result = data.shipmentStatus[0]?.result;
        const arraygridData = [
          { description: 'Picked up', location: 'SUWANEE, GA, US 30024', start: '2024-09-11 19:15:00'},
          { description: 'Arrived at FedEx location', location: 'SUWANEE, GA, US 30024', start: '2024-09-11 19:36:00'},
          { description: 'Shipment arriving On-Time', location: 'SUWANEE, GA, US 30024', start: '2024-09-11 19:46:00'},
          { description: 'Left FedEx origin facility', location: 'SUWANEE, GA, US 30024', start: '2024-09-11 20:34:00'},
          { description: 'Arrived at FedEx location', location: 'CHARLOTTE NC US 28214', start: '2024-09-12 03:13:00'},
          
          { description: 'Departed FedEx Location', location: 'CHARLOTTE NC US 28214', start: '2024-09-12 10:31:00'},
          { description: 'At local FedEx facility', location: 'LADSON SC US 29456', start: '2024-09-13 06:31:00'},
          { description: 'On FedEx vehicle for delivery', location: 'LADSON SC US 29456', start: '2024-09-13 18:35:00'},
          { description: 'Delivered', location: 'Huger SC US 29450', start: '2024-09-13 19:05:00'}
          
          
          // Additional rows here...
        ].map((item, index) => ({
          ...item,
          colorClass: this.getColorClass(item.description) // Assign color dots
        }));
      
        //this.showGrid = true;
        this.messages.push({ author: 'bot', content: arraygridData, type:'json-array-track-fedex' });
        
      }
      else if(words[1] == "DL0000000086"){
        
        //setTimeout(()=>{
          this.carrierNamefedex = "FedEx";
          let custommsg: any = "Tracking details provided for Delivery "+ words[1] +" by ShipperCopilot.";
          //this.trackmsg = words[1];
          //DL456789098765
          this.trackmsgfedex = "415685896990";
          this.messages.push({ author: 'user', content: custommsg ,type:'custom-text'});
        //}, 200);
        const data  = {"shipmentStatus":[{"result":{"pro":531041028,"billOfLadingNumber":638157062344974300,"purchaseOrder":"NS","status":"DELIVERED","statusDateTime":"2023-04-08T03:50:10","comment":"","consignee":{"name":"MARKS FARM","address1":"6314 CANNAN RD","address2":"","city":"LOWVILLE","state":"NY","zip":133674802},"shipper":{"name":"ARMOR ANIMAL HEALTH","address1":"3910 N GEORGE ST EXT","address2":"","city":"MANCHESTER","state":"PA","zip":17345},"billTo":{"name":"ARMOR ANIMAL HEALTH","address1":"3910 N GEORGE ST EXT","address2":"","city":"MANCHESTER","state":"PA","zip":17345},"interlineFrom":"","interlineFromPro":"","interlineTo":"","interlineToPro":"","shipmentDetails":{"weight":333,"pieces":1},"statusHistory":{"statusDetail":[{"description":"PICKED UP","location":"MANCHESTER, PA","start":"2023-03-29 14:15:00","end":"2023-03-29T14:15:00"},{"description":"DEPARTED TERMINAL","location":"YORK, PA","start":"2023-03-29 19:48:00","end":"2023-03-29T19:48:00"},{"description":"ARRIVED AT TERMINAL","location":"SYRACUSE, NY","start":"2023-03-30 00:37:00","end":"2023-03-30T00:37:00"},{"description":"OUT FOR DELIVERY","location":"SYRACUSE, NY","start":"2023-03-30 09:03:00","end":"2023-03-30T09:03:00"},{"description":"OUT FOR DELIVERY","location":"SYRACUSE, NY","start":"2023-03-31 08:50:00","end":"2023-03-31T08:50:00"},{"description":"DELIVERED","location":"LOWVILLE, NY","start":"2023-03-31 14:22:00","end":"2023-03-31T14:22:00"},{"description":"ETA WINDOW","location":"LOWVILLE, NY","start":"2023-03-31 16:22:00","end":"2023-03-31T17:22:00"}]}}},{"result":{"pro":531041028456789,"errorMessage":"We are unable to find this shipment."}}]};
        const result = data.shipmentStatus[0]?.result;
        this.gridData = [
          { description: 'Picked up', location: 'MANCHESTER, PA', start: '2023-03-29 14:15:00'},
          { description: 'Departed Terminal', location: 'YORK, PA', start: '2023-03-29 19:48:00'},
          { description: 'Arrived At Terminal', location: 'YORK, PA', start: '2023-03-29 19:48:00'},
          { description: 'Out For Delivery', location: 'YORK, PA', start: '2023-03-29 19:48:00'},
          { description: 'Delivered', location: 'YORK, PA', start: '2023-03-29 19:48:00'},
          { description: 'Eta Window', location: 'YORK, PA', start: '2023-03-29 19:48:00'}
          
          
          // Additional rows here...
        ].map((item, index) => ({
          ...item,
          colorClass: this.getColorClass(item.description) // Assign color dots
        }));
      
        //this.showGrid = true;
        this.messages.push({ author: 'bot', content: this.gridData, type:'json-array-track-fedex' });
      }
      else if(words[1] == "DL0000000087"){
        
        //setTimeout(()=>{
          this.carrierNamefedex = "FedEx";
          let custommsg: any = "Tracking details provided for Delivery "+ words[1] +" by ShipperCopilot.";
          //this.trackmsg = words[1];
          //DL456789098765
          this.trackmsgfedex = "778488243438";
          this.messages.push({ author: 'user', content: custommsg ,type:'custom-text'});
        //}, 200);
        const data  = {"shipmentStatus":[{"result":{"pro":531041028,"billOfLadingNumber":638157062344974300,"purchaseOrder":"NS","status":"DELIVERED","statusDateTime":"2023-04-08T03:50:10","comment":"","consignee":{"name":"MARKS FARM","address1":"6314 CANNAN RD","address2":"","city":"LOWVILLE","state":"NY","zip":133674802},"shipper":{"name":"ARMOR ANIMAL HEALTH","address1":"3910 N GEORGE ST EXT","address2":"","city":"MANCHESTER","state":"PA","zip":17345},"billTo":{"name":"ARMOR ANIMAL HEALTH","address1":"3910 N GEORGE ST EXT","address2":"","city":"MANCHESTER","state":"PA","zip":17345},"interlineFrom":"","interlineFromPro":"","interlineTo":"","interlineToPro":"","shipmentDetails":{"weight":333,"pieces":1},"statusHistory":{"statusDetail":[{"description":"PICKED UP","location":"MANCHESTER, PA","start":"2023-03-29 14:15:00","end":"2023-03-29T14:15:00"},{"description":"DEPARTED TERMINAL","location":"YORK, PA","start":"2023-03-29 19:48:00","end":"2023-03-29T19:48:00"},{"description":"ARRIVED AT TERMINAL","location":"SYRACUSE, NY","start":"2023-03-30 00:37:00","end":"2023-03-30T00:37:00"},{"description":"OUT FOR DELIVERY","location":"SYRACUSE, NY","start":"2023-03-30 09:03:00","end":"2023-03-30T09:03:00"},{"description":"OUT FOR DELIVERY","location":"SYRACUSE, NY","start":"2023-03-31 08:50:00","end":"2023-03-31T08:50:00"},{"description":"DELIVERED","location":"LOWVILLE, NY","start":"2023-03-31 14:22:00","end":"2023-03-31T14:22:00"},{"description":"ETA WINDOW","location":"LOWVILLE, NY","start":"2023-03-31 16:22:00","end":"2023-03-31T17:22:00"}]}}},{"result":{"pro":531041028456789,"errorMessage":"We are unable to find this shipment."}}]};
        const result = data.shipmentStatus[0]?.result;
        this.gridData = [
          { description: 'Picked up', location: 'FREMONT CA, US 94538', start: '2024-09-11 19:15:00'},
          { description: 'Arrived at FedEx location', location: 'FREMONT CA, US 94538', start: '2024-09-11 19:36:00'},
          { description: 'Shipment arriving On-Time', location: 'FREMONT CA, US 94538', start: '2024-09-11 19:46:00'},
          { description: 'Left FedEx origin facility', location: 'FREMONT CA, US 94538', start: '2024-09-11 20:34:00'},
          { description: 'Customs Clearance - In Progress', location: 'SAN FRANCISCO CA US 94016', start: '2024-09-13 03:13:00'},
          { description: 'Customs Clearance - Complete', location: 'SAN FRANCISCO CA US 94016', start: '2024-09-13 06:02:00'},
          { description: 'Departed FedEx Location', location: 'SAN FRANCISCO CA US 94016', start: '2024-09-13 10:31:00'},
          { description: 'At local FedEx facility', location: 'HYDERABAD TS IND 500086', start: '2024-09-15 06:31:00'},
          { description: 'On FedEx vehicle for delivery', location: 'HYDERABAD TS IND 500086', start: '2024-09-15 18:35:00'},
          { description: 'Delivered', location: 'HYDERABAD TS IND 500018', start: '2024-09-15 19:05:00'}
          
          
          // Additional rows here...
        ].map((item, index) => ({
          ...item,
          colorClass: this.getColorClass(item.description) // Assign color dots
        }));
      
        //this.showGrid = true;
        this.messages.push({ author: 'bot', content: this.gridData, type:'json-array-track-fedex' });
      }
      else if(words[1] == "DL0000000088") {
      
      //setTimeout(()=>{
        this.carrierNameups = "UPS";
        const words: string[] = this.value.split(" ");
        let custommsg: any = "Tracking details provided for Delivery "+ words[1] +" by ShipperCopilot.";
        //this.trackmsg = words[1];
        //DL456789098765
        this.trackmsgups = "1Z846A626673015944";
        this.messages.push({ author: 'user', content: custommsg ,type:'custom-text'});
      //}, 200);
      const data  = {"shipmentStatus":[{"result":{"pro":531041028,"billOfLadingNumber":638157062344974300,"purchaseOrder":"NS","status":"DELIVERED","statusDateTime":"2023-04-08T03:50:10","comment":"","consignee":{"name":"MARKS FARM","address1":"6314 CANNAN RD","address2":"","city":"LOWVILLE","state":"NY","zip":133674802},"shipper":{"name":"ARMOR ANIMAL HEALTH","address1":"3910 N GEORGE ST EXT","address2":"","city":"MANCHESTER","state":"PA","zip":17345},"billTo":{"name":"ARMOR ANIMAL HEALTH","address1":"3910 N GEORGE ST EXT","address2":"","city":"MANCHESTER","state":"PA","zip":17345},"interlineFrom":"","interlineFromPro":"","interlineTo":"","interlineToPro":"","shipmentDetails":{"weight":333,"pieces":1},"statusHistory":{"statusDetail":[{"description":"PICKED UP","location":"MANCHESTER, PA","start":"2023-03-29 14:15:00","end":"2023-03-29T14:15:00"},{"description":"DEPARTED TERMINAL","location":"YORK, PA","start":"2023-03-29 19:48:00","end":"2023-03-29T19:48:00"},{"description":"ARRIVED AT TERMINAL","location":"SYRACUSE, NY","start":"2023-03-30 00:37:00","end":"2023-03-30T00:37:00"},{"description":"OUT FOR DELIVERY","location":"SYRACUSE, NY","start":"2023-03-30 09:03:00","end":"2023-03-30T09:03:00"},{"description":"OUT FOR DELIVERY","location":"SYRACUSE, NY","start":"2023-03-31 08:50:00","end":"2023-03-31T08:50:00"},{"description":"DELIVERED","location":"LOWVILLE, NY","start":"2023-03-31 14:22:00","end":"2023-03-31T14:22:00"},{"description":"ETA WINDOW","location":"LOWVILLE, NY","start":"2023-03-31 16:22:00","end":"2023-03-31T17:22:00"}]}}},{"result":{"pro":531041028456789,"errorMessage":"We are unable to find this shipment."}}]};
      const result = data.shipmentStatus[0]?.result;
      const arraygridDataups = [
        { description: 'Shipment Created', location: 'New York, NY, USA', start: '2024-09-05 08:12:00'},
        { description: 'Origin Scan', location: 'New York, NY, USA', start: '2024-09-05 17:30:00'},
        { description: 'Departure Scan', location: 'New York, NY, USA', start: '2024-09-05 21:00:00'},
        { description: 'Arrival Scan', location: 'Louisville, KY, USA', start: '2024-09-06 02:15:00'},
        { description: 'Departure Scan (International Hub)', location: 'Louisville, KY, USA', start: '2024-09-06 04:30:00'},
        { description: 'Customs Clearance - In Progress', location: 'London, UK', start: '2024-09-07 08:45:00'},
        
        { description: 'Customs Clearance - Completed', location: 'London, UK', start: '2024-09-07 12:15:00'},
        { description: 'Arrival Scan', location: 'London, UK', start: '2024-09-07 02:30:00'},
        { description: 'Out for Delivery', location: 'London, UK', start: '2024-09-08 09:30:00'},
        { description: 'Delivered', location: 'London, UK', start: '2024-09-08 05:5:00'}
        
        // Additional rows here...
      ].map((item, index) => ({
        ...item,
        colorClass: this.getColorClass(item.description) // Assign color dots
      }));
    
      //this.showGrid = true;
      this.messages.push({ author: 'bot', content: arraygridDataups, type:'json-array-track-ups' });
      this.gridData = [];
      }
      else if(words[1] == "DL0000000089") {
        
      //setTimeout(()=>{
        this.carrierNameups = "UPS";
        const words: string[] = this.value.split(" ");
        let custommsg: any = "Tracking details provided for Delivery "+ words[1] +" by ShipperCopilot.";
        //this.trackmsg = words[1];
        //DL456789098765
        this.trackmsgups = "1ZY1668V0398808549";
        this.messages.push({ author: 'user', content: custommsg ,type:'custom-text'});
      //}, 200);
      const data  = {"shipmentStatus":[{"result":{"pro":531041028,"billOfLadingNumber":638157062344974300,"purchaseOrder":"NS","status":"DELIVERED","statusDateTime":"2023-04-08T03:50:10","comment":"","consignee":{"name":"MARKS FARM","address1":"6314 CANNAN RD","address2":"","city":"LOWVILLE","state":"NY","zip":133674802},"shipper":{"name":"ARMOR ANIMAL HEALTH","address1":"3910 N GEORGE ST EXT","address2":"","city":"MANCHESTER","state":"PA","zip":17345},"billTo":{"name":"ARMOR ANIMAL HEALTH","address1":"3910 N GEORGE ST EXT","address2":"","city":"MANCHESTER","state":"PA","zip":17345},"interlineFrom":"","interlineFromPro":"","interlineTo":"","interlineToPro":"","shipmentDetails":{"weight":333,"pieces":1},"statusHistory":{"statusDetail":[{"description":"PICKED UP","location":"MANCHESTER, PA","start":"2023-03-29 14:15:00","end":"2023-03-29T14:15:00"},{"description":"DEPARTED TERMINAL","location":"YORK, PA","start":"2023-03-29 19:48:00","end":"2023-03-29T19:48:00"},{"description":"ARRIVED AT TERMINAL","location":"SYRACUSE, NY","start":"2023-03-30 00:37:00","end":"2023-03-30T00:37:00"},{"description":"OUT FOR DELIVERY","location":"SYRACUSE, NY","start":"2023-03-30 09:03:00","end":"2023-03-30T09:03:00"},{"description":"OUT FOR DELIVERY","location":"SYRACUSE, NY","start":"2023-03-31 08:50:00","end":"2023-03-31T08:50:00"},{"description":"DELIVERED","location":"LOWVILLE, NY","start":"2023-03-31 14:22:00","end":"2023-03-31T14:22:00"},{"description":"ETA WINDOW","location":"LOWVILLE, NY","start":"2023-03-31 16:22:00","end":"2023-03-31T17:22:00"}]}}},{"result":{"pro":531041028456789,"errorMessage":"We are unable to find this shipment."}}]};
      const result = data.shipmentStatus[0]?.result;
      this.gridData = [
        { description: 'Shipper created a label', location: 'New York, NY, USA', start: '2024-09-11 18:44:00'},
        { description: 'Departed from Facility', location: 'New York, NY, USA', start: '2024-09-11 20:10:00'},
        { description: 'Arrived at Facility', location: 'New Stanton PA, US', start: '2024-09-11 23:44:00'},
        { description: 'Departed from Facility', location: 'New Stanton PA, US', start: '2024-09-12 03:48:00'},
        { description: 'Arrived at Facility', location: 'Sharonville OH, US', start: '2024-09-12 09:19:00'},
        { description: 'Departed from Facility', location: 'Sharonville OH, US', start: '2024-09-12 14:23:00'},
        
        { description: 'Arrived at Facility', location: 'Lexington KY, US', start: '2024-09-12 15:57:00'},
        { description: 'Processing at UPS Facility', location: 'Lexington KY, US', start: '2024-09-12 19:12:00'},
        { description: 'Out For Delivery Today', location: 'Lexington KY, US', start: '2024-09-13 09:05:00'},
        { description: 'Delivered', location: 'Paris KY US', start: '2024-09-13 10:15:00'}
        
        // Additional rows here...
      ].map((item, index) => ({
        ...item,
        colorClass: this.getColorClass(item.description) // Assign color dots
      }));
    
      //this.showGrid = true;
      this.messages.push({ author: 'bot', content: this.gridData, type:'json-array-track-ups' });
      }
      else if(words[1] == "DL0000000090") {
        
      //setTimeout(()=>{
        this.carrierNameups = "UPS";
        const words: string[] = this.value.split(" ");
        let custommsg: any = "Tracking details provided for Delivery "+ words[1] +" by ShipperCopilot.";
        //this.trackmsg = words[1];
        //DL456789098765
        this.trackmsgups = "1ZX580110134528031";
        this.messages.push({ author: 'user', content: custommsg ,type:'custom-text'});
      //}, 200);
      const data  = {"shipmentStatus":[{"result":{"pro":531041028,"billOfLadingNumber":638157062344974300,"purchaseOrder":"NS","status":"DELIVERED","statusDateTime":"2023-04-08T03:50:10","comment":"","consignee":{"name":"MARKS FARM","address1":"6314 CANNAN RD","address2":"","city":"LOWVILLE","state":"NY","zip":133674802},"shipper":{"name":"ARMOR ANIMAL HEALTH","address1":"3910 N GEORGE ST EXT","address2":"","city":"MANCHESTER","state":"PA","zip":17345},"billTo":{"name":"ARMOR ANIMAL HEALTH","address1":"3910 N GEORGE ST EXT","address2":"","city":"MANCHESTER","state":"PA","zip":17345},"interlineFrom":"","interlineFromPro":"","interlineTo":"","interlineToPro":"","shipmentDetails":{"weight":333,"pieces":1},"statusHistory":{"statusDetail":[{"description":"PICKED UP","location":"MANCHESTER, PA","start":"2023-03-29 14:15:00","end":"2023-03-29T14:15:00"},{"description":"DEPARTED TERMINAL","location":"YORK, PA","start":"2023-03-29 19:48:00","end":"2023-03-29T19:48:00"},{"description":"ARRIVED AT TERMINAL","location":"SYRACUSE, NY","start":"2023-03-30 00:37:00","end":"2023-03-30T00:37:00"},{"description":"OUT FOR DELIVERY","location":"SYRACUSE, NY","start":"2023-03-30 09:03:00","end":"2023-03-30T09:03:00"},{"description":"OUT FOR DELIVERY","location":"SYRACUSE, NY","start":"2023-03-31 08:50:00","end":"2023-03-31T08:50:00"},{"description":"DELIVERED","location":"LOWVILLE, NY","start":"2023-03-31 14:22:00","end":"2023-03-31T14:22:00"},{"description":"ETA WINDOW","location":"LOWVILLE, NY","start":"2023-03-31 16:22:00","end":"2023-03-31T17:22:00"}]}}},{"result":{"pro":531041028456789,"errorMessage":"We are unable to find this shipment."}}]};
      const result = data.shipmentStatus[0]?.result;
      this.gridData = [
        { description: 'Shipper created a label', location: 'Erie PA, US', start: '2024-09-11 18:44:00'},
        { description: 'Departed from Facility', location: 'Erie PA, US', start: '2024-09-11 20:10:00'},
        { description: 'Arrived at Facility', location: 'New Stanton PA, US', start: '2024-09-11 23:44:00'},
        { description: 'Departed from Facility', location: 'New Stanton PA, US', start: '2024-09-12 03:48:00'},
        { description: 'Arrived at Facility', location: 'Sharonville OH, US', start: '2024-09-12 09:19:00'},
        { description: 'Departed from Facility', location: 'Sharonville OH, US', start: '2024-09-12 14:23:00'},
        
        { description: 'Arrived at Facility', location: 'Lexington KY, US', start: '2024-09-12 15:57:00'},
        { description: 'Processing at UPS Facility', location: 'Lexington KY, US', start: '2024-09-12 19:12:00'},
        { description: 'Out For Delivery Today', location: 'Lexington KY, US', start: '2024-09-13 09:05:00'},
        { description: 'Delivered', location: 'PARIS KY US', start: '2024-09-13 10:15:00'}
        
        // Additional rows here...
      ].map((item, index) => ({
        ...item,
        colorClass: this.getColorClass(item.description) // Assign color dots
      }));
    
      //this.showGrid = true;
      this.messages.push({ author: 'bot', content: this.gridData, type:'json-array-track-ups' });
      }
      else{
        const words: string[] = this.value.split(" ");
        let custommsg: any = "I am unable to locate the tracking number "+ words[1] +".";
        //this.trackmsg = words[1];
        //this.trackmsg = "";
        this.messages.push({ author: 'user', content: custommsg ,type:'custom-text'});
      }
    }
    else if(this.value.includes('Dim') || this.value.includes('Dims') || this.value.includes('Weight') || this.value.includes('Dimension') || this.value.includes('Weight')){
      this.getScaleweight();
    }
    else{
      this.CopilotchatbotService.getBotAnswer(this.value);
    }
  }else{
    if(this.value.includes('Dim') || this.value.includes('Dims') || this.value.includes('Weight') || this.value.includes('Dimension') || this.value.includes('Weight')){
      this.getScaleweight();
    }else{
      this.CopilotchatbotService.getBotAnswer(this.value);
    }
    
  }
  this.value = '';
  this.timeOfDay = 'I\'m ready for the task';
    // Scroll after the message is added
    setTimeout(() => this.scrollToBottom(), 0); // Scroll after view has updated
}


  // Scroll to the bottom of the chat container
  private scrollToBottom(): void {
    try {
      const container = this.chatContainer.nativeElement;
      container.scrollTop = container.scrollHeight;
    } catch (err) {
      console.error('Error scrolling to bottom:', err);
    }
  }

  // Lifecycle hook to scroll after every view check
  ngAfterViewChecked() {
    // Ensure we scroll to bottom after the view has been updated
    this.scrollToBottom();
  }




getScaleweight(){

  this.messages.push({ author: 'user', content: this.value ,type:'text'});
  this.messages.push({ author: 'user', content: "Weight and dimensions capturing from ShipperCopilot." ,type:'custom-text'});
   this.CopilotchatbotService.triggerMeasurement().subscribe(
     (response) => {
      console.log("FirstScale_Response" + response);
      // Step 1: Convert raw response to string if necessary
    const responseStr = response.toString();
    console.log("SecondScale_Response" + responseStr);
    // Step 2: Remove special characters (STX, ETX, CR, LF)
    const cleanedResponse = responseStr.replace(/<STX>|<ETX>|<CR>|<LF>/g, '');
    console.log("ThirdScale_Response" + cleanedResponse);
    try {
      // Step 3: Parse the cleaned response as JSON
     const jsonResponse = JSON.parse(cleanedResponse);
     console.log("FourthScale_Response" + JSON.parse(jsonResponse));
      // Step 4: Extract the measurement data (weight, dimensions, etc.)
      const measurement = jsonResponse.measurement;

      // Append the extracted data to the grid
      const weight = measurement.weight;
      const length = measurement.length;
      const width = measurement.width;
      const height = measurement.height;

      console.log("FINALScale_Response" + weight + "_" + length + "_" + width + "_" + height);


      const htmlContent = `
      <table style="border-collapse: collapse; width: 100%;">
        <thead>
          <tr>
            <th style="border: 1px solid #ddd; padding: 8px;">Weight</th>
            <th style="border: 1px solid #ddd; padding: 8px;">Length</th>
            <th style="border: 1px solid #ddd; padding: 8px;">Width</th>
            <th style="border: 1px solid #ddd; padding: 8px;">Height</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="border: 1px solid #ddd; padding: 8px;">${weight}</td>
            <td style="border: 1px solid #ddd; padding: 8px;">${length}</td>
            <td style="border: 1px solid #ddd; padding: 8px;">${width}</td>
            <td style="border: 1px solid #ddd; padding: 8px;">${height}</td>
          </tr>
        </tbody>
      </table>
    `;

    // Sanitize the HTML content
    //this.messageContent = this.sanitizer.bypassSecurityTrustHtml(htmlContent);

      // const weight = 10;
      // const length = 11;
      // const width = 12;
      // const height = 13;
      // this.gridData = [{Weight:weight, Length: length,Width: width,Height: height}]
      console.log("SixthScale_Response");
      
      this.messages.push({ author: 'bot', content: this.sanitizer.bypassSecurityTrustHtml(htmlContent) ,type:'json-array-Weight'});
      // Step 5: Update the grid data array
      //this.gridData.push({ weight, length, width, height });
    } catch (error) {
      console.error('Error parsing Cubiscan response:', error);
    }
     // console.log('Measurement triggered:', response);
    },
    (error) => {
      console.error('Error triggering measurement:', error);
    }
  );
}

setSVGContent(svgString: string) {
  this.svgContent = "svgString";
}

openDetailImage(image: string):void{
  this.openImageDialog = true;
  this.shipImage = image;
}


}
