import { Component, Input, Output, EventEmitter,ViewEncapsulation  } from '@angular/core';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss'],  
})
export class WarningPopupComponent {
  title: any;
  constructor(private messageService: MessageService) {}
  isPopupVisible = false;
  message:any;
  showWarning(message: any) {
    this.isPopupVisible = true;
    this.message=message;
  }

  clearWarnings() {
    this.messageService.clear();
    this.isPopupVisible = false;
  }
}
