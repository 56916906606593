import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { saveAs } from 'file-saver-es';
import { StorageService } from 'src/app/services/storage.service';
@Component({
  selector: 'app-charges',
  templateUrl: './charges.component.html',
  styleUrl: './charges.component.scss'
})
export class ChargesComponent implements OnInit {
  shippingCharges :any [] = [];
  @Input() shipment!: any;
  constructor(private sanitizer: DomSanitizer,private storageService:StorageService){}
 ngOnInit(): void {
  //  const data:any = this.storageService.getItem('shipmentData');
  //  if(data)
  //  {
  //   this.shippingCharges = data.shippingCharges;
  //  }
 }

 ngOnChanges(changes: SimpleChanges): void {
  if (changes['shipment'] && changes['shipment'].currentValue) { 
    this.shippingCharges = changes['shipment'].currentValue.shippingCharges || [];
  }}
// shippingCharges = [
//   {
//       "description": "Discount Freight",
//       "amount": 16.06,
//       "currency": "USD"
//   },
//   {
//       "description": "FedEx Ground Fuel",
//       "amount": 0.84,
//       "currency": "USD"
//   },
//   {
//     "description": "Discount Freight",
//     "amount": 16.06,
//     "currency": "USD"
// },
// {
//     "description": "FedEx Ground Fuel",
//     "amount": 0.84,
//     "currency": "USD"
// },
// {
//   "description": "Discount Freight",
//   "amount": 16.06,
//   "currency": "USD"
// },
// {
//   "description": "FedEx Ground Fuel",
//   "amount": 0.84,
//   "currency": "USD"
// },
// {
//   "description": "Discount Freight",
//   "amount": 16.06,
//   "currency": "USD"
// },
// {
//   "description": "FedEx Ground Fuel",
//   "amount": 0.84,
//   "currency": "USD"
// },
// {
//   "description": "Discount Freight",
//   "amount": 16.06,
//   "currency": "USD"
// },
// {
//   "description": "FedEx Ground Fuel",
//   "amount": 0.84,
//   "currency": "USD"
// },
// {
//   "description": "Discount Freight",
//   "amount": 16.06,
//   "currency": "USD"
// },
// {
//   "description": "FedEx Ground Fuel",
//   "amount": 0.84,
//   "currency": "USD"
// }
// ]

}
