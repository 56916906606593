import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ColumnConfigService } from '../services/column-config-service';
import { MessageService } from 'primeng/api';
import { StorageService } from '../services/storage.service';
import { UserDataService } from '../services/user-data.service';
import { ERPSHIPService } from '../erpship.service';
interface ModuleConfig {
  [key: string]: string[];
}

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrl: './location.component.scss'
})
export class LocationComponent {
  locationdata={
    "LocationName": "",
    "Contact": "",
    "LocationId": "",
    "AddressLine1": "",
    "AddressLine2": "",
    "City": "",
    "State": "",
    "ZipCode": "",
    "Country": "",
    "Phone": "",
    "Email": null,
    "weightunit": null,
    "dimensionunit": null,
    "IsActive": true,
    "id": "",
    "Currency": null,
    "Plant": null
}
  ConfigKey='location'
  locationName: string = '';
  
  routingGuide: boolean = false;
  addressValidation: boolean = false;

  states = [{ label: 'CA', value: 'CA' }, { label: 'NY', value: 'NY' }];
  countries = [{ label: 'USA', value: 'USA' }, { label: 'Canada', value: 'Canada' }];
  weightUnits = [{ label: 'KGS', value: 'KGS' }, { label: 'LBS', value: 'LBS' }];
  currencies = [{ label: 'USD', value: 'USD' }, { label: 'EUR', value: 'EUR' }];
  dimensionUnits = [{ label: 'CM', value: 'CM' }, { label: 'IN', value: 'IN' }];

 @ViewChild('ot') overlayPanel: OverlayPanel;
  first = 0;
  rows = 50;
  isDataLoaded: boolean = false;
  passingid: any = '';
  items: any[] = [];
  totalRecords = 0;
  shipToDate: any;
  shipFromDate:any;

  Carrier: any;
  costCenter: any;
  user: any;
  Status: any;
  feederSystemOptions = [];
  page: number = 0; // Current page index
  displayDialog: boolean = false;
  searchQuery: string = '';
  selectedColumns: any[] = [];
  scrollHeight = '400px';

  displayColumnPopup: boolean = false;
  addLocationFlag:boolean=false;

  columns = [
    {
      header: 'Location Id',
      fieldName: 'LocationId',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Location Name',
      fieldName: 'LocationName',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Contact Name',
      fieldName: 'Contact',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Email',
      fieldName: 'Email',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Phone',
      fieldName: 'Phone',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Currency',
      fieldName: 'Currency',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Dimension Unit',
      fieldName: 'dimensionunit',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Weight Unit',
      fieldName: 'weightunit',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Plant',
      fieldName: 'Plant',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Country',
      fieldName: 'Country',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
   
    {
      header: 'State',
      fieldName: 'City',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Zip Code',
      fieldName: 'ZipCode',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Address Line1',
      fieldName: 'AddressLine1',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Address Line2',
      fieldName: 'AddressLine2',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'IsActive',
      fieldName: 'IsActive',
      fieldType: 'inputSwitch',
      editable: false,
      visible: false,
      datatype: 'string',
    }
  ];
  
  postColumns: any[] = [];
  mandatoryColumns = [
    'Location Id',
    'Location Name',
    'Contact Name',
    'Email',
    'City'
  ];
  cancelProgress: any;
  shippedProgress: any;
  openProgress: any;
  showSidebarOverlay: boolean = false;
  displayedLocation: any;
  loctionFormBtn:  any;
  filteredColumns: any;
  finalColumns: any;


  constructor(
    private xfservice: ERPSHIPService,
    private columnConfigService: ColumnConfigService,

  ) {
  }
  
  
  delete(shipRequest) {
    console.log('delete functionality ');
  }
  

  generateItems = (record) => {
    // this.cdr.detectChanges();
    return [
      {
        label: 'Delete',
        command: () => {
          this.delete(record);
        },
      }
    ];
  };

 
  countVisibleTrue: number = 0;
  locationData : any;
  openRecordsCount
  cancelledRecordsCount
  shippedRecordsCount
  documentCountList :any;
  ngOnInit(): void {
   // this.locationData = this.storageService.getItem('data');

    // Call the service method to post the column conf
    this.xfservice.getxFormRecordsByFromName('location').subscribe((data) => {
      var locationData = data.map(item=> item.details)
      if(locationData != undefined)
        {
          
            // Initialize counters
            let openCount = 0;
            let shippedCount = 0;
            let cancelledCount = 0;
      
            locationData.forEach((item: any) => {
              item.buttonItem=this.generateItems(item)
      
            
            this.documentCountList = {
              'totalRecords': this.totalRecords || 0,
              'openRecords' : this.openRecordsCount|| 0,
              'shippedRecords' : this.shippedRecordsCount|| 0,
              'cancelledRecords': this.cancelledRecordsCount || 0
            }
            this.isDataLoaded = true;
          });
          this.locationData=locationData
        }
    });
  }


  statusProgressBarClick(){}
  locationSidebar(){
    this.addLocationFlag=true
    this.showSidebarOverlay = true;
    this.loctionFormBtn="Save";


  }
  handleSidebarHide(){
    this.showSidebarOverlay = false;
    this.addLocationFlag = false;
  }
  editLocation(shipRequest){
    console.log(shipRequest)
    this.locationSidebar()
    this.loctionFormBtn='Update';
    this.locationdata=shipRequest
  }
  saveLocation(){
    this.xfservice.postLocation(this.locationdata).subscribe(
      (response) => {
        
        this.ngOnInit()
        this. handleSidebarHide()
      },
      (error) => {

      }
    );
  }
  cancelSidebar(){
    this. handleSidebarHide()

  }

 
  
receiveData(data:any) {
  this.finalColumns = data;
}

  
}
