<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>
<div class="main-container pagedetails-header">
  <div class="titleContainer col-12 pt-0 pl-0">

    <div class="col-4 pl-0 pt-0">
      <div class="pageTitle">Total ship from Locations</div>
    </div>
    <div class="col-8 text-right pt-0 pb-0 pr-0">
      <button class="buttonTitle mt-3" (click)="locationSidebar()">Add Location</button>
    </div>

  </div>
  <div class="pageStatus">
    <div class="totalRec-container" (click)="statusProgressBarClick()">
      <div class="count">{{ documentCountList?.totalRecords || 0 }}</div>
      <div class="thumbtitle">Locations</div>

      <div class="totalProgressbar-cls" style="background-color:rgb(32, 172, 246) !important;"></div>

    </div>
    <div class="open-container" (click)="statusProgressBarClick()">
      <div class="count">{{ documentCountList?.openRecords || 0 }}</div>
      <div class="thumbtitle">Active</div>
      <div class="openProgressbar-cls" style="background-color: rgb(70, 211, 240);"></div>
    </div>
    <div class="shipped-container" (click)="statusProgressBarClick()">
      <div class="count">{{ documentCountList?.shippedRecords || 0 }}</div>
      <div class="thumbtitle">Inactive</div>
      <div class="shippedProgressbar-cls" style="background-color: rgb(31, 152, 222);"></div>
    </div>
  </div>
</div>

<div class="grid-dev-sec">

  <div class="card gridCommonCss m-3 p-3 pt-0" *ngIf="isDataLoaded">
    <div class="filterSec">
      <div class="grid m-0 pt-1 pb-0">
        <div class="col pl-0 pb-0">
          <i class="pi pi-filter mr-0" style="font-size: 18px; vertical-align: middle;" (click)="op.toggle($event)"></i>
        </div>
        <div class="col text-right pr-0 pb-0">
          <i class="pi pi-file-excel" style="font-size: 18px;padding-right: 10px;vertical-align: middle;"></i>
          <app-col-config [columns]="columns" [mandatoryColumns]="mandatoryColumns"
          (finalColumns)="receiveData($event)" [ConfigKey]="ConfigKey">

          </app-col-config>
        </div>
      </div>
    </div>
    <app-config-table *ngIf="isDataLoaded" [displayedData]="locationData" 
    [columns]="finalColumns" [sharedViewFunction]="editLocation.bind(this)" 
    [ConfigKey]="ConfigKey"></app-config-table>
  

    <p-overlayPanel #op [showCloseIcon]="true" [dismissable]="true" [style]="{width: '50%'}">

      <div class="grid p-fluid">
        <div class="col-12 md:col-3">
          <div class="p-field">
            <label for="locationName">Location Name</label>
            <p-dropdown id="locationName" [options]="feederSystemOptions" [(ngModel)]="locationName"
              optionLabel="Location Name" placeholder="Select Location Name"></p-dropdown>
          </div>
        </div>
        <div class="col-12 md:col-3">
          <div class="p-field">
            <label for="Carrier">Carrier</label>
            <p-dropdown id="Carrier" [options]="feederSystemOptions" [(ngModel)]="Carrier" optionLabel="Carrier"
              placeholder="Select"></p-dropdown>
          </div>
        </div>
        <div class="col-12 md:col-3">
          <div class="p-inputgroup p-input-icon-right">
            <span class="delivery-title">Ship From Date</span>
            <p-calendar [(ngModel)]="shipFromDate" [iconDisplay]="'input'" [showIcon]="true" placeholder="Select Date"
              [dateFormat]="'dd/mm/yy'" inputId="icondisplay" />

          </div>
        </div>
        <div class="col-12 md:col-3">
          <div class="p-inputgroup p-input-icon-right">
            <span class="delivery-title">Ship To Date</span>
            <p-calendar [(ngModel)]="shipToDate" [iconDisplay]="'input'" [showIcon]="true" placeholder="Select Date"
              [dateFormat]="'dd/mm/yy'" inputId="icondisplay" />
          </div>
        </div>

      </div>
      <div class="grid p-fluid">

        <div class="col-12 md:col-3">
          <div class="p-field">
            <label for="costCenter">Cost Center</label>
            <p-dropdown id="costCenter" [options]="feederSystemOptions" [(ngModel)]="costCenter"
              optionLabel="Cost Center" placeholder="Select Cost Center"></p-dropdown>
          </div>
        </div>
        <div class="col-12 md:col-3">
          <div class="p-inputgroup p-input-icon-right">
            <label for="user">User</label>
            <div class="search-container">
              <input id="user" type="text" pInputText [(ngModel)]="user" maxlength="30" />
              <i class="pi pi-search search-icon"></i>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-3">
          <div class="p-field">
            <label for="Status">Status</label>
            <p-dropdown id="Status" [options]="feederSystemOptions" [(ngModel)]="Status" optionLabel="Status"
              placeholder="Select Status"></p-dropdown>
          </div>
        </div>
      </div>

      <div class="grid p-d-flex">
        <div class="col-12 md:col-4">
          <!-- <p-button class="p-d-flex justify-content-start footer-cancel" label="Cancel"></p-button>           -->
        </div>
        <div class="col-12 md:col-4">
        </div>
        <div class="col-12 md:col-4 footer-apply apply-div">
          <p-button label="Apply"></p-button>
        </div>
      </div>
    </p-overlayPanel>
  </div>
</div>
<div *ngIf="showSidebarOverlay" class="custom-overlay"></div>
<p-sidebar [(visible)]="addLocationFlag" position="right" (onHide)="handleSidebarHide()" [modal]="false"
  [style]="{'width': '50%', 'height': '125vh'}" styleClass="ui-sidebar-lg">
  <div class="p-fluid custom-form-container">
    <p-card header="" class="p-mb-3">
      <div class="p-grid custom-row">

        <div class="p-col-12 custom-col">
          <div class="switch-container">
            <label for="status">Status</label>
            <p-inputSwitch id="status" [(ngModel)]="locationdata.IsActive"></p-inputSwitch>
          </div>
        </div>


        <div class="p-col-12 custom-col">
          <div class="p-field">
            <label for="locationId">Location Code</label>
            <input pInputText id="locationId" type="text" [(ngModel)]="locationdata.LocationId">
          </div>
        </div>
        <div class="p-col-12 custom-col">
          <div class="p-field">
            <label for="locationName">Location Name</label>
            <input pInputText id="locationName" type="text" [(ngModel)]="locationdata.LocationName">
          </div>
        </div>
        <div class="p-col-12 custom-col">
          <div class="p-field">
            <label for="contactName">Contact Name</label>
            <input pInputText id="contactName" type="text" [(ngModel)]="locationdata.Contact">
          </div>
        </div>

        <div class="p-col-6 custom-col">
          <div class="p-field">
            <label for="addressLine1">Address Line 1</label>
            <input pInputText id="addressLine1" type="text" [(ngModel)]="locationdata.AddressLine1">
          </div>
        </div>
        <div class="p-col-6 custom-col">
          <div class="p-field">
            <label for="addressLine2">Address Line 2</label>
            <input pInputText id="addressLine2" type="text" [(ngModel)]="locationdata.AddressLine2">
          </div>
        </div>

        <div class="p-col-6 custom-col">
          <div class="p-field">
            <label for="city">City</label>
            <input pInputText id="city" type="text" [(ngModel)]="locationdata.City">
          </div>
        </div>

        <div class="p-col-6 custom-col">
          <div class="p-field">
            <label for="state">State</label>
            <p-dropdown [options]="states" [(ngModel)]="locationdata.State"></p-dropdown>
          </div>
        </div>

        <div class="p-col-6 custom-col">
          <div class="p-field">
            <label for="zip">Zip/Postal Code</label>
            <input pInputText id="zip" type="text" [(ngModel)]="locationdata.ZipCode">
          </div>
        </div>

        <div class="p-col-6 custom-col">
          <div class="p-field">
            <label for="country">Country</label>
            <p-dropdown [options]="countries" [(ngModel)]="locationdata.Country"></p-dropdown>
          </div>
        </div>

        <div class="p-col-6 custom-col">
          <div class="p-field">
            <label for="phone">Phone</label>
            <input pInputText id="phone" type="text" [(ngModel)]="locationdata.Phone">
          </div>
        </div>
        <div class="p-col-6 custom-col">
          <div class="p-field">
            <label for="email">Email</label>
            <input pInputText id="email" type="email" [(ngModel)]="locationdata.Email">
          </div>
        </div>
        <div class="p-col-6 custom-col">
          <div class="p-field">
            <label for="weightUnit">Weight Units</label>
            <p-dropdown id="weightUnit" [options]="weightUnits" [(ngModel)]="locationdata.weightunit"></p-dropdown>
          </div>
        </div>
        <div class="p-col-6 custom-col">
          <div class="p-field">
            <label for="currency">Currency</label>
            <input pInputText id="currency" type="text" [(ngModel)]="locationdata.Currency">
          </div>
        </div>
        <div class="p-col-6 custom-col">
          <div class="p-field">
            <label for="dimensionUnits">Dimension Units</label>
            <p-dropdown [options]="dimensionUnits" [(ngModel)]="locationdata.dimensionunit"></p-dropdown>
          </div>
        </div>
        <div class="p-col-12 custom-col">
          <div class="switch-container">
            <label for="routingGudie">Routing Gudie</label>
            <p-inputSwitch id="routingGudie"></p-inputSwitch>
          </div>
        </div>
        <div class="p-col-12 custom-col">
          <div class="switch-container">
            <label for="addressValidation">Address Validation</label>
            <p-inputSwitch id="addressValidation"></p-inputSwitch>
          </div>
        </div>
      </div>
    </p-card>
  </div>





  <div class="p-mt-3">
    <button (click)="cancelSidebar()" pButton type="button" label="Cancel" class="p-button-secondary"></button>
    <button (click)="saveLocation()" pButton type="button" label="{{loctionFormBtn}}" class="p-button-primary"></button>

  </div>

</p-sidebar>