import { Component,Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import fedExFields from "./additional-info-details-data/fedExFields";
import upsFields from "./additional-info-details-data/upsFields";
import uspsFields from "./additional-info-details-data/uspsFields";
import ltlGeneric from "./additional-info-details-data/ltlGeneric";
import { ERPSHIPService } from '../../../../app/erpship.service';
import { GetAllDataService } from 'src/app/services/ship-now/get-all-data.service';
import { ShipNowService } from 'src/app/services/ship-now/ship-now.service';

@Component({
  selector: 'app-routing-billing-detail',
  templateUrl: './routing-billing-detail.component.html',
  styleUrl: './routing-billing-detail.component.scss'
})
export class RoutingBillingDetailComponent implements OnChanges,OnInit {
  @Input() shipment!: any;
  @Input() refreshFlag: boolean = false;
  todayDate: Date = new Date();
  Original_LocationMasterData: any;
  carrierData: any [] = [];
  allCarrierServicesData:any [] = [];
  carrierServiceData : any []=[];
  detailsData : any = {};
  selectedCarrierData : any []=[];
  
  
  selectedShipMethod: string = "";
  selectedServiceType: string = "";
  carrierId: string = "";
  selectedPaymentType: string = "";
  selectedCostCenter: string = "";
  poNo: string = "";
  invoiceNo: string = "";
  popupVisible: boolean = false;
  popupFields : any = [];
  popupHeader : string = "";
  popupCls : string="";
  popupSubcontainer : any = {};
  additionalOptionsFormData : any = {};
  isConsLoaded:boolean=false;
  costCenter :any = [];
  constructor( private xfservice: ERPSHIPService,private dataService:GetAllDataService,private shipNowService:ShipNowService) {
    this.todayDate = new Date();
    this.detailsData = {
      'UPS': upsFields,
      'FedEx':fedExFields,
      'USPS':uspsFields,
      'YRC':ltlGeneric
    };
    this.xfservice.getLocationMasterData().subscribe((data) => {
      this.Original_LocationMasterData = JSON.parse(JSON.stringify(data));
      this.carrierData = this.Original_LocationMasterData?.carriers.filter((x: any) => x.LocationId == data['xLocationId']);
      this.carrierData=this.sortByField(this.carrierData,'CarrierName')
      this.allCarrierServicesData = this.Original_LocationMasterData?.carrierservices.filter((x: any) => x.LocationId == data['xLocationId']);
      this.costCenter = this.Original_LocationMasterData?.costcenter;
      // this.selectedCarrierData = fedExFields;
      // this.shipment.CarrierDetails.Carrier = this.carrierData.find((rec)=>rec.CarrierName == "FedEx");
      this.isConsLoaded=true
      // Method to manually trigger ngOnChanges
      const changes: SimpleChanges = {
        shipment: {
          previousValue: undefined,
          currentValue: this.shipment,
          firstChange: false,
          isFirstChange: () => false
        }}
     this.ngOnChanges(changes)    
    });
  }
 

  ngOnChanges(changes: SimpleChanges): void {
    this.dataService.setRoutingAndBillingData(this.shipment)

    if (changes['shipment'] && this.shipment && this.isConsLoaded) {
      const carrierName = this.shipment['CarrierDetails']?.['Carrier'];
      const selectedCarrier = this.carrierData.find(item => item.CarrierName === carrierName);
      const paymentTypeValue = this.shipment['CarrierDetails']?.['PaymentType'];
      const selectedPayment = this.paymentTypes.find(item => item.value === paymentTypeValue);
     
      this.shipment.CarrierDetails.Carrier = selectedCarrier;
      this.shipment.CarrierDetails.PaymentType = selectedPayment;
      this.shipment.CarrierDetails.CostCenter = this.shipment.CarrierDetails.CostCenter
      this.onCarrierChange(selectedCarrier)
      const ServiceCode = this.shipment['CarrierDetails']?.['ServiceName']
      console.log(ServiceCode);
      console.log(this.carrierServiceData);
      let carrierServiceName = this.carrierServiceData.filter(item => item.ServiceCode  === ServiceCode);
      this.shipment.CarrierDetails.ServiceName= carrierServiceName?.[0]?.ServiceCode;
      console.log( this.shipment.CarrierDetails.ServiceName);

      this.shipment.CarrierDetails.PaymentType = this.paymentTypes[0];
    this.shipment.CarrierDetails.ShippingAccount=this.shipment['CarrierDetails']['ShippingAccount']
    this.shipment.CarrierDetails.Note=this.shipment['CarrierDetails']['Note']

  }
  if (changes['refreshFlag'] && changes['refreshFlag'].currentValue) {
    this.shipment.CarrierDetails.Note=''
    this.shipment.CarrierDetails.Reference2=''
    this.shipment.CarrierDetails.Reference1=''
    this.shipment.CarrierDetails.PaymentType=''
    this.shipment.CarrierDetails.ShippingAccount=''
    this.shipment.CarrierDetails.ServiceName=''
    this.shipment.CarrierDetails.Carrier=''
    this.selectedCarrierData=[]
    this.carrierServiceData=[]
  }
  } 
 onCarrierChange(event){
    if(event != undefined)
    {
      const value = event?.value?.CarrierName || event.CarrierName;
      this.selectedCarrierData = this.detailsData[value] || [];
      this.carrierServiceData = this.allCarrierServicesData.filter((rec)=>{
        return rec.CarrierName == value;
      });
      this.carrierServiceData=this.sortByField(this.carrierServiceData,'ServiceName')
      console.log(this.carrierServiceData)
      const locationDetails=this.Original_LocationMasterData.carrieraccounts.filter(item=> 
        item.CarrierName==value && item.LocationId==this.shipment.CarrierDetails.Carrier.LocationId )
      if (locationDetails.length>0){
        this.shipment.CarrierDetails.ShippingAccount=locationDetails[0].Account
        this.shipment.CarrierDetails.Carrier.UserId=locationDetails[0].UserId
        this.shipment.CarrierDetails.Carrier.Password=locationDetails[0].Password
      }
    }
  }
  onEditClick(event: any,fieldData: any){
    this.popupHeader = fieldData.popupTitle
    this.popupVisible = true;
    this.popupFields = fieldData.popupFields;
    this.popupCls = fieldData.popupCls;
    this.popupSubcontainer = fieldData.formContainerRequirements || {
      cls:"",
      containerNames:[]
    };
  };
  onPopUpHide(event:any){
    this.popupHeader = "";
    this.popupVisible = false;
    this.popupFields = [];
    this.popupCls = "";
  }
  onAddDetailsDropdownChange(event,data:any){
  };
  onAdditioanlOptInputChange(evt,data:any){
  }
  shipMethods = [
    { label: 'Ship Method 1', value: 'Ship Method 1' },
    { label: 'Ship Method 2', value: 'Ship Method 2' }
  ];

  serviceTypes = [
    { label: 'Service 1', value: 'Service 1' },
    { label: 'Service 2', value: 'Service 2' }
  ];

  paymentTypes = [
    { label: 'Sender', value: 'Sender' },
    { label: 'Receipient', value: 'Receipient' },
    { label: 'Third Party', value: 'Third Party' },
    { label: 'Collect', value: 'Collect' }
  ];

  costCenters = [
    { label: 'Cost Centre 1', value: 'Cost Centre 1' },
    { label: 'Cost Centre 2', value: 'Cost Centre 2' }
  ];

  ngOnInit() {
    this.shipment = {
      CarrierDetails: {
        Carrier: "",
        ServiceName: "",
        PaymentType: 'Sender',
        ShippingAccount: "",
        BillingAccount: "",
        BillingCountry: "",
        BillingZipCode: "",
        Reference1: "",
        Reference2: "",
        Reference3: "",
        Reference4: "",
        Note: "",
        UserId: "",
        Password: "",
        AccessKey: "",
        CostCenter : ""
      }
    }
    this.shipNowService.freightShopRowData.subscribe(data => {
      if (data) {
        this.onCarrierChange({CarrierName:data.Carrier});
        const selectedCarrier = this.carrierData.find(item => item.CarrierName === data.Carrier);
        this.shipment.CarrierDetails.Carrier = selectedCarrier;
        this.shipment.CarrierDetails.ServiceName= this.carrierServiceData.find(item => item.ServiceCode  === data.serviceCode);
        this.shipment.CarrierDetails.ShippingAccount = data.AccountNumber;
      }
    });
  }
  getFormValues() {
    return this.shipment
  }

  sortByField<T>(array: T[], fieldName: keyof T, ascending: boolean = true): T[] {
    return array.sort((a, b) => {
      const valueA = a[fieldName];
      const valueB = b[fieldName];
  
      if (valueA === undefined || valueB === undefined) {
        return 0;
      }
  
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }
  
      if (valueA < valueB) return ascending ? -1 : 1;
      if (valueA > valueB) return ascending ? 1 : -1;
      return 0;
    });
  }
}
