import { Component,ViewChild,Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProductService } from './productservice';
import { AddressBookPopupComponent } from 'src/app/popup/address-book-popup/address-book-popup.component';

@Component({
  selector: 'app-dashboard-table',

  templateUrl: './dashboard-table.component.html',
  styleUrl: './dashboard-table.component.scss'
})
export class DashboardTableComponent implements OnChanges {
  products!: any[];
  @Input() shipment!: any;
  @ViewChild(AddressBookPopupComponent) popup: AddressBookPopupComponent;
  selectedProduct: any;
  selectedProductIndex: number | null = null;
  // showOverlay: boolean = false;
  constructor(private productService: ProductService) {}

  ngOnInit() {
    this.setScrollHeight()
      this.productService.getProductsMini().then((data) => {
          this.products = data;
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['shipment'] && this.shipment) {
      this.updateProductDate(this.shipment.ShipFrom, 0);
      this.updateProductDate(this.shipment.ShipFrom, 1);
      // Ensure 'ShipFrom' is defined and is an array
      // if (Array.isArray(this.shipment.ShipFrom)) {
      //   if (this.shipment.ShipFrom[0]) {
      //     this.updateProductDate(this.shipment.ShipFrom, 0);
      //   }
      //   if (this.shipment.ShipFrom[1]) {
      //     this.updateProductDate(this.shipment.ShipFrom, 1);
      //   }
      // } else {
      //   console.warn("ShipFrom is not an array or is undefined.");
      // }
    }
  }
  
  
  showPopup( index: number) {
    this.selectedProductIndex = index; // Store the index of the product
    this.popup.display = true;
    // this.showOverlay=true;
  };

  handleAddressSelect(address: any) {
    this.updateProductDate(address,this.selectedProductIndex)
    // this.showOverlay=false;
  };

  updateProductDate(data, index) {
    // Check if `this.products` is initialized and is an array
    if (!Array.isArray(this.products)) {
      // console.error('Products array is not initialized or not an array.');
      return;
    }
  
    // Check if the provided index is valid
    if (index < 0 || index >= this.products.length) {
      // console.error('Index out of range:', index);
      return;
    }
  
    // Update product data if checks pass
    this.products[index]['addressLine1'] = data['ADDRESS_LINE1'];
    this.products[index]['addressLine2'] = data['ADDRESS_LINE2'];
    this.products[index]['city'] = data['CITY'];
    this.products[index]['company'] = data['COMPANY'];
    this.products[index]['contact'] = data['CONTACT'];
    this.products[index]['country'] = data['COUNTRY'];
    this.products[index]['email'] = data['EMAIL'];
    this.products[index]['phone'] = data['PHONE'];
    this.products[index]['state'] = data['STATE'];
    this.products[index]['zipcode'] = data['ZIPCODE'];
    this.products[index]['id'] = data['LOCATIONID'];
  }
  scrollHeight = '260px'
  setScrollHeight() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width >= 1200 && height <= 800) {
      this.scrollHeight = '395px';
    } else {
      this.scrollHeight = '525px';
    }
  }
  
}