import { Component } from '@angular/core';

@Component({
  selector: 'app-centeraltower',
  templateUrl: './centeraltower.component.html',
  styleUrls: ['./centeraltower.component.scss']
})
export class CenteraltowerComponent {

}
