import { Component, OnInit, ViewChild } from '@angular/core';
//import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERPSHIPService } from '../erpship.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-routingguide',
  templateUrl: './routingguide.component.html',
  styleUrls: ['./routingguide.component.scss'],providers: [MessageService],

})
export class RoutingguideComponent {
  newRule_flag = false;
  locations = [];
  rules:any=[];
  ruledata = {
    RuleName: null,
    LocationId: null,
    Priority: null,
    ValidForm: new Date(),
    ValidTo: new Date(),
    IsActive: true,
    tabledata: []
  };

  xfields: any[] = [];
  tabledata: any[] = [];
  condition = ['=', '>', '<', '>=', '<=', '!=', 'Contains', 'Starts With', 'Ends With', 'In Between'];
  tableRecord = { "fieldName": '', 'Condition': '', 'fromRange': '', 'toRange': '' }
  isDataLoaded=false;

  constructor(private router: Router, public messageService: MessageService, private xfservice: ERPSHIPService) {
    this.xfservice.processAuth().subscribe((data) => { }, error => {
      console.log("No token found")
      this.router.navigate(['']);
    });
  }
  ngOnInit(): void {
    this.xfservice.getScreenTemplateByName('RoutingGuideFields').subscribe((data: any) => {
      this.xfields = data[0].details;
      this.xfields.forEach((element: any) => {
        if (element.visible == true) {
          element['fields'].forEach((item: any) => {
            if (item['TableVisible'] == true) {
              this.tableRecord.fieldName = element.fieldHeader + '->' + item.fieldName;
              this.tabledata.push(JSON.parse(JSON.stringify(this.tableRecord)));


            }

          })
        }
      })
      this.xfservice.getxFormRecordsByFromName("location").subscribe(data => {
        var result = [];
        result = data;
  
        result.forEach((item: any) => {
          this.locations.push(item['details'] as never)
  
        })
        this.xfservice.getRGRules().subscribe((data:any)=>{
          this.rules=data
          console.log(this.rules)
          this.isDataLoaded=true
         })
       
      })
    })
    

  }
  saveRule() {
    this.ruledata.tabledata = this.tabledata as never;
   // console.log(JSON.stringify(this.ruledata))
    this.xfservice.saveRGRules(this.ruledata).subscribe((data: any) => {
      // this.messageService.add({
      //   key: 'tc',
      //   severity: 'success',
      //   summary: 'Template',
      //   detail: 'Routing Guide Rules are successfully saved.',
      // });
      this.xfservice.getRGRules().subscribe((data:any)=>{  
        this.rules=data

      })
    }
    )
    this.newRule_flag=false;
   
  }
  editRecord(data:any){
    this.ruledata=data;
    this.tabledata = this.ruledata.tabledata;
    this.ruledata.ValidForm = new Date(data.ValidForm) 
    this.ruledata.ValidTo = new Date(data.ValidTo) 
    this.newRule_flag=true;
  }
}
