import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ColumnConfigService } from '../services/column-config-service';
import { MessageService } from 'primeng/api';
import { StorageService } from '../services/storage.service';
import { UserDataService } from '../services/user-data.service';
import { ERPSHIPService } from '../erpship.service';
interface ModuleConfig {
  [key: string]: string[];
}

@Component({
  selector: 'app-col-config',
  templateUrl: './col-config.component.html',
  styleUrl: './col-config.component.scss',
})
export class ColConfigComponent {
  @ViewChild('ot') overlayPanel: OverlayPanel;
  @Input() columns: any[] = [];
  @Input() mandatoryColumns: any[] = [];
  @Input() ConfigKey: any;
  @Output() finalColumns = new EventEmitter<any>();

  userId: string;
  scrollHeight = '400px';
  filteredColumns: any[] = []; // Start with all columns
  countVisibleTrue: number = 0;
  backupColumns: any[] = [];
  postColumns: any[] = [];
  searchQuery = '';
  selectAll: boolean = true;

  constructor(
    private router: Router,
    private xfservice: ERPSHIPService,
    private cdr: ChangeDetectorRef,
    private columnConfigService: ColumnConfigService,
    private messageService: MessageService,
    private storageService: StorageService,
    private userContext: UserDataService
  ) {
    let data = this.userContext.getDecryptedCookie('usercontext');
    this.userId = data.body.user_id;
    this.xfservice.processAuth().subscribe(
      (data) => {},
      (error) => {
        console.log('No token found');
        this.router.navigate(['']);
      }
    );
  }

  ngOnInit(): void {
    this.setScrollHeight();
    console.log(this.userId);
    // this.locationData = this.storageService.getItem('data');
    this.getColumnConfiguration();
  }

  getColumnConfiguration() {
    this.xfservice.getUserProfile(this.userId).subscribe((item) => {
      if (item && item?.[0]?.details) {
        let columnConfig: ModuleConfig[] = item?.[0]?.details?.columnconfig;
        //this.userId = item[0].details.user_id;
        let visibleColumnList;
        if (columnConfig) {
          const locationConfigKey=this.ConfigKey
          var trackIndex;
          for (let i = 0; i < columnConfig.length; i++){
           if(columnConfig[i][locationConfigKey]) {
             trackIndex=i
          }
          }
  
          if (locationConfigKey && columnConfig[trackIndex][locationConfigKey]) {
            const locationConfig = columnConfig[trackIndex][locationConfigKey]; // This will give you the array of headers
            // Now you can work with locationConfig
          }
  
          if (locationConfigKey && columnConfig[trackIndex][locationConfigKey]) {
            visibleColumnList = this.columns.map((columnItem) => {
              let isVisible = false;
  
              // Check if the header exists in the 'location' array
              if (
                columnConfig[trackIndex][locationConfigKey].includes(
                  columnItem.header
                )
              ) {
                isVisible = true;
              }
  
              // Set visibility based on whether the header is found
              if (isVisible) {
                this.countVisibleTrue++;
                columnItem.visible = true;
              } else {
                this.countVisibleTrue--;
                columnItem.visible = false;
              }
              return columnItem; // Ensure each object is returned to update the array
            });
  
            // Check if columnConfig is a valid object and update columns
            if (typeof columnConfig === 'object' && columnConfig !== null) {
              this.filteredColumns = visibleColumnList.map((col) => ({
                ...col,
              }));
              this.columns = visibleColumnList.map((col) => ({ ...col }));
              this.backupColumns = visibleColumnList.map((col) => ({ ...col }));
              this.cdr.detectChanges(); // Manually trigger change detection
            }
          } 
          else {
            console.log("inside else")
            // Handle missing or invalid columnConfig
            if (this.userId) {
              console.log(
              );
              this.postDefaultColumnConfiguration(this.userId);
            } else {
              console.error(
                'User ID is missing, cannot post default column configuration.'
              );
            }
          }
        } 
        
        this.filteredColumns = [...this.columns];
        this.filteredColumns.forEach((item)=>
          {
            if( item.visible ==false){
              this.selectAll=false
            }
          })
      this.finalColumns.emit(this.filteredColumns);
      }
      
    });
  }
  
  postDefaultColumnConfiguration(user) {
    //  const userId = user; // Replace with actual user ID
    this.postColumns = this.columns
      .filter((item) => item.visible) // Filter the columns where visible is true
      .map((item) => item.header);
    const defaultColumnConfig: ModuleConfig[] = this.postColumns;
    this.columnConfigService
      .postColumnConfig(this.userId, this.ConfigKey, defaultColumnConfig)
      .subscribe(
        (response) => {
          console.log(
            'Default column configuration posted successfully:',
            response
          );
          // this.columnConfig = this.columns; // Set the posted data as the current config
        },
        (error) => {
          console.error('Error posting default column configuration:', error);
        }
      );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.setScrollHeight();
  }

  setScrollHeight() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width >= 1200 && height <= 800) {
      this.scrollHeight = '430px';
    } else {
      this.scrollHeight = '580px';
    }
  }

  // Apply changes to columns and postColumns only when Apply is clicked
  applyColumnConfig(): void {
    const visibleCount = this.filteredColumns.filter((c) => c.visible).length;
    if (visibleCount <= 1) {
      this.filteredColumns = this.filteredColumns.map((item) => {
        if (this.mandatoryColumns.includes(item.header)) {
          item.visible = true; // Make mandatory columns visible
        }
        return item; // Return the modified column object
      });
    }

    // Sync filteredColumns with main columns array
    this.columns.forEach((originalCol) => {
      const matchingFilteredCol = this.filteredColumns.find(
        (col) => col.header === originalCol.header
      );
      if (matchingFilteredCol) {
        originalCol.visible = matchingFilteredCol.visible; // Sync visibility status
      }
    });

    // Update postColumns with only the visible headers after applying
    this.postColumns = this.columns
      .filter((col) => col.visible)
      .map((col) => col.header);

    // Create a backup to restore later if needed
    this.backupColumns = this.columns.map((col) => ({ ...col }));

    // Optionally trigger change detection by reassigning columns
    this.columns = this.backupColumns.map((col) => ({ ...col }));

    this.postDefaultColumnConfiguration(this.userId);

    this.searchQuery = '';
    this.filteredColumns = [...this.columns];

    // Hide the overlay after applying changes
    this.overlayPanel.hide();
    this.finalColumns.emit(this.filteredColumns);
  }

  cancelColumnConfig(): void {
    // this.columns = this.backupColumns.map((col) => ({ ...col }));
    // this.filteredColumns = [...this.columns];
    this.searchQuery = '';
    this.filteredColumns = this.columns.map((col) => ({ ...col }));
    // this.displayDialog = false;
    this.overlayPanel.hide();
  }
  filterColumns() {
    console.log('Search Query:', this.searchQuery); // Debugging
    if (this.searchQuery) {
      this.filteredColumns = this.columns.filter((col) =>
        col.header.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    } else {
      this.filteredColumns = this.columns;
    }
    console.log('Filtered Columns:', this.filteredColumns); // Debugging
  }

  showOverlay(event: Event) {
    if (this.overlayPanel) {
      this.cancelColumnConfig();
      this.overlayPanel.show(event);
      // Call your function here when the panel is shown
      setTimeout(() => {
        if (this.overlayPanel && this.overlayPanel.container) {
          this.overlayPanel.container.style.top = '120px';
          this.overlayPanel.container.style.left = '79%';
          this.overlayPanel.container.style.width = '300px';
        }
      //   const viewportHeight = window.innerHeight;
      // const overlayElement = this.overlayPanel.container;

      // if (overlayElement) {
      //   // Get the position of the target (the img element)
      //   const targetRect = (event.target as HTMLElement).getBoundingClientRect();
      //   const spaceAbove = targetRect.top;
      //   const spaceBelow = viewportHeight - targetRect.bottom;

      //   // Set max height for overlay
      //   if (spaceBelow > spaceAbove) {
      //     overlayElement.style.maxHeight = `${spaceBelow - 20}px`;
      //   } else {
      //     overlayElement.style.maxHeight = `${spaceAbove - 20}px`;
      //   }
      // }
      }, 0);
    }
  }
  




  SelectAll(event) {
    if (event) {
      this.filteredColumns.forEach((item) => {
        item.visible = true;
      });
    } else {
      this.filteredColumns = this.filteredColumns.map((item) => {
        if (this.mandatoryColumns.includes(item.header)) {
          item.visible = true; // Make mandatory columns visible
        } else {
          item.visible = false;
        }

        return item; // Return the modified column object
      });
    }
  }
}
