import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { HttpClient } from '@angular/common/http';
import { ERPSHIPService } from '../erpship.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Location,DatePipe  } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { GalleriaThumbnails } from 'primeng/galleria';
import { ShipNowService } from 'src/app/services/ship-now/ship-now.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { UserDataService } from '../services/user-data.service';
import { WarningPopupComponent } from '../popup/warning-popup/warning-popup.component';
import { AddressBookPopupComponent } from '../popup/address-book-popup/address-book-popup.component';
import { CookieService } from 'ngx-cookie-service';
import { v4 as uuidv4 } from 'uuid'; // If you want to use UUID
declare var Razorpay: any; // Declare Razorpay globally

export interface DropdownOption {
  label: string;
  value: any;
}

@Component({
  selector: 'app-routing-guide-modal',
  templateUrl: './routing-guide-modal.component.html',
  styleUrl: './routing-guide-modal.component.scss',
  providers: [MessageService, ConfirmationService,DatePipe],

})
export class RoutingGuideModalComponent {

  @ViewChild('dt1') dt: any;
  @ViewChild('dt2') addrdt: any;
  @ViewChild('fsdt') freightdt: any;
  @ViewChild('loc') loc: any;
  @ViewChild('requiredData') requiredData: any;
  @ViewChild('manifestdt') manifestdata: any;
  @ViewChild('ot') overlayPanel: OverlayPanel;
  @ViewChild(WarningPopupComponent) warningPopup: WarningPopupComponent;
  @ViewChild(AddressBookPopupComponent) popup: AddressBookPopupComponent;
  @ViewChild('adddimension') dimensionOver: OverlayPanel;
  
  routingGuide:any;
  FreightShop_dialog: boolean = false;
  selectedFreightProduct: any = {};
  
  isFooterButtonEnable: boolean = true;

  //empty model

  shipment: any = {}; //super master data
  isDataLoaded: boolean = false;
  
  saveButtontitle = 'Save';
  showOverlayPopup: boolean;
  
  closeShipScreen(): void {
    // this.location.back();
    this.router.navigate(['home/routingguide']);
  }

  shipmentTypes: any[] = [
    { label: 'Open', value: 'Open' },
    { label: 'Planning', value: 'Planning' },
    { label: 'Planned', value: 'Planned' },
    { label: 'Shipped', value: 'Shipped' },
    { label: 'Cancelled', value: 'Cancelled' },
    { label: 'Ship Request Profiles', value: 'Ship Request Profiles' },
  ];
  selectedShipmentType: string = 'Shipped';
  shipments: any[] = [];

  locationId:any;
  userContext:any;

  // Filtered shipments based on selectedShipmentType, sorted by ShipDate, and limited to the top 20 recent
  get filteredShipments() {
    return this.shipments
      .filter(
        (shipment) =>
          shipment &&
          shipment.details &&
          shipment.details.TrackingStatus === this.selectedShipmentType
      )
      .sort(
        (a, b) =>
          new Date(b.details.HeaderInfo.ShipDate).getTime() -
          new Date(a.details.HeaderInfo.ShipDate).getTime()
      )
      .slice(0, 20); // Get only the top 20 recent shipments
  }
  constructor(
    private confirmationService: ConfirmationService,
    private xfservice: ERPSHIPService,
    private router: Router,
    private location: Location,
    public messageService: MessageService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private shipNowService: ShipNowService,
    private cookieService: CookieService,
    private datePipe: DatePipe,
    private userdata: UserDataService,
    private ngZone: NgZone
    
  ) {
    // Initialize with current date
  }

  ngOnInit() {
    this.resetform()
    this.locationId = JSON.parse(this.cookieService.get('role'))?.LocationId;
    this.userContext = this.userdata.getDecryptedCookie('usercontext');
    
    
    this.xfservice
      .getScreenTemplateByName('ShipMasterProductDialog')
      .subscribe((data) => {
        // Assuming the new field should be added to allColumns

      });
  }
  getTimelineData(): void {
    try {
      this.shipNowService
        .getTimelineData()
        .toPromise()
        .then((data) => {
          this.shipments = data;
          // this.connections = data.erp;
          // this.orderTypeFilters = data.ordertypes;
        })
        .catch((error) => {
          console.error('Error fetching shipping data:', error);
        });
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  }
  Save(type:any): void {
  }
  confirmDelete(rowIndex: number) {
    this.confirmationService.confirm({
      icon: 'pi pi-info-circle',
      message: `
      <div>
        <p>Do you want to delete this record?</p>
        <hr />
      </div>`,
      header: 'Confirmation',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text p-button-text',
      acceptIcon: 'none',
      rejectIcon: 'none',

      accept: () => {
        this.deleteProduct(rowIndex);
        // this.messageService.add({
        //   severity: 'info',
        //   summary: 'Confirmed',
        //   detail: 'Record deleted',
        // });
      },
    });
  }
  deleteProduct(rowIndex: number) {
    this.products.splice(rowIndex, 1);
    // this.messageService.add({
    //   severity: 'info',
    //   summary: 'Confirmed',
    //   detail: 'Product deleted',
    // });
  }
  // Save Ship Request end
  getKeys(obj: any): string[] {
    const keys: string[] = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keys.push(key);
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          const nestedKeys = this.getKeys(obj[key]);
          keys.push(...nestedKeys.map((nestedKey) => `${key}.${nestedKey}`));
        }
      }
    }
    return keys;
  }
  applyFilterGlobal_Address($event: any, stringVal: any): void {
    this.addrdt.filterGlobal(
      ($event.target as HTMLInputElement).value,
      stringVal
    );
  }
  // Data for the table
  products: any[] = [{}];
  allProducts: any[] = [];
  // All available columns
  allColumns: any[] = [];
  dimensionOptions: DropdownOption[] = [];
  mandatoryColumns = [
    'productno',
    'epc',
    'description',
    'quantity',
    'unitweight',
    'dimension',
    'insurance',
  ];
  // Columns selected by the user
  selectedColumns: string[] = [];
  numberOfFixedColumns = 4;
  defaultSelectedColumns: any[] = [];
  isColumnVisible(field: string): boolean {
    let fieldLowerCase = field.toLowerCase();
    return this.selectedColumns.includes(fieldLowerCase);
  }
  isColAvailable(val: any): boolean {
    const isAvailable = !this.selectedColumns.includes(
      val.fieldName.toLowerCase()
    );
    return isAvailable;
  }
  // Toggle column visibility when a checkbox is clicked
  toggleColumnVisibility(columnName: string, isVisible: boolean): void {
    if (this.mandatoryColumns.includes(columnName.toLowerCase())) {
      return; // Prevent toggling mandatory columns
    }

    if (isVisible) {
      this.selectedColumns.push(columnName.toLowerCase());
    } else {
      this.selectedColumns = this.selectedColumns.filter(
        (col) => col !== columnName.toLowerCase()
      );
    }
  }
  applyColumnConfiguration(): void {
    this.selectedColumns = [
      ...new Set([...this.mandatoryColumns, ...this.selectedColumns]),
    ];
  }

  onRowClick(rowIndex: number): void {
    if (
      rowIndex === this.products.length - 1 &&
      this.products[rowIndex].ProductNo
    ) {
      // If it's the last row, add a new row
      this.addNewRow();
    }
  }
  addNewRow(): void {
    const newRow = {
      sno: this.products.length + 1, // Automatically increment S.No
      itemNo: '',
      productNo: '',
      description: '',
    };
    this.products.push(newRow);
  }

  // Method to handle "Select All" change
  selectAll: boolean = false;
  onSelectAllChange(isChecked: boolean): void {
    this.allColumns.forEach((col) => {
      this.toggleColumnVisibility(col.fieldName, isChecked);
    });

    // Ensure default columns are always visible even if unchecked
  }

  showOverlay(event: Event) {
    if (this.overlayPanel) {
      this.overlayPanel.show(event);
      // Call your function here when the panel is shown
      this.getTimelineData();
    }
  }
  selectHistory(shipment: any) {
    shipment.details.HeaderInfo.ShipDate = new Date(
      shipment.details.HeaderInfo.ShipDate
    );
    this.shipment = shipment.details;
    this.overlayPanel.hide();
  }

  getHeaderStyle(columnName: string): any {
    if (
      columnName == 'ProductNo' ||
      columnName == 'epc' ||
      columnName == 'UnitWeight' ||
      columnName == 'Quantity'
    ) {
      return { width: '150px' };
    } else if (columnName == 'Dimension') {
      return { width: '300px' };
    } else {
      return { width: 'auto' };
    }
  }

  getCellStyle(columnName: string): any {
    switch (columnName) {
      case 'Quantity':
      case 'UnitWeight':
        return { textAlign: 'right' }; // Align text to the right
      default:
        return { textAlign: 'left' }; // Default alignment
    }
  }
  // new save
  //Action from Table
  onSave() {
    debugger
    this.xfservice.postRoutingGuide(this.routingGuide).subscribe(
      (data) => {})
  }
  status() {
    throw new Error('Method not implemented.');
  }
  saveClose() {
    this.Save('save');
    this.closeShipScreen();
    // this.router.navigate(['/home/shiprequestmodal', 'COPY-' + shipRequest.id]);
  }

  saveNew(){}
  

  saveOptions = [
    {
      label: 'Save and New',
      command: () => {
        this.saveNew();
      },
    },
    {
      label: 'Save and Close',
      command: () => {
        this.saveClose();
      },
    },
  ];
  handleAddressSelect(address: any) {
      this.shipment = {
        ...this.shipment,
        ShipTo: address,
      };
    
    }
    // this.showOverlay = false;
  
  handlePopupClose() {
    this.showOverlayPopup = false;
  }

  resetform(){
    this.routingGuide={
      Header: { applicableDays: '', priority: '', statusRuleType: '', shipFrom: '', shipTo: '', notes: '' },
      ShipFrom: { fromCity: '', fromRegion: '', fromState: '', fromMinZip: '', fromMaxZip: '', fromCountry: '' },
      ShipTo: { toCity: '', toRegion: '', toState: '', toMinZip: '', toMaxZip: '', toCountry: '' },
      ShipmentCritieria: {
          shippingPoint: '', vendorId: '', shipToPlantId: '', customerId: '', serviceLevel: '', hazmatFlag: '', FTZFlag: '',
          maxWeight: '', minWeight: '', minLength: '', maxLength: '', minWidth: '', maxWidth: '', minHeight: '', maxHeight: '', minGirthHeight: '',
          maxGirthHeight: '', minVolume: '', maxVolume: '', incoTerms: '', orderType: ''
      },
      ShipmentMethodDetails: {
          primaryCarrier: '', primaryServiceLevel: '', primaryTransitTime: '', paymentType: '', firstLegCarrier: '',
          firstLegServiceLevel: '', firstLegTransitTime: '', freightAccNum: '', exportBroker: '', exportBrokerService: '', exportBrokerTransitTime: '',
          primaryMOT: '', importBroker: '', importBrokerServiceLevel: '', importBrokerTransitTime: '', primaryDimfactory: '', lastLegCarrier: '',
          lastLegServiceLevel: '', lastLegCarrierTransitTime: '',
      },
      BillingDetails: {
          billToContact: '', billToAddress1: '', billToAddress2: '', billToCity: '', billToState: '', billToCountry: '',
          billToZip: '', billToPhone: '', billToCompany: ''
      }}
  }
}
