import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'visibleColumns',
})
export class VisibleColumnsPipe implements PipeTransform {
  transform(columns: any[]): any[] {
    return columns?.filter((col) => col.visible);
  }
}
