import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {  HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ConsolidationService {
  sourceurl: string = 'http://localhost:4500';
  constructor(private http: HttpClient) { }
  
  getShipRequestData(record:any):Observable<any>
  
  {
    //alert(JSON.stringify(record))
      const headers={ 'content-type': 'application/json',
      'X-CSRF-Token': 'Fetch',
        
      }
      return this.http.post(this.sourceurl + '/consolidation',record,{headers,});
  }
}
