<div class="edit-dashboard-conatiner">
    <div class="content">
        <div *ngFor="let item of itemsList">
            <div class="checkbox-container">
                <p-checkbox id="{{item.label}}" [binary]="true" [(ngModel)]="item.value"></p-checkbox>
                <label for="{{item.label}}">{{item.label}}</label>
            </div>
        </div>
    </div>
    <div class="footer-container">
        <button type="button" class="btn-cls cancel-btn" (click)="onCancel()">Cancel</button>
        <button type="submit" class="btn-cls save-btn" (click)="onSave()">Save</button>
    </div>
</div>