<div class="dashboard-container">
    <div class="dashboard-area">
        <div class="shipping-container">
            <div class="shipping-area">
                <div>
                    <button class="btn_Edit" (click)="editDashboard()">
                        <img src="assets/images/edit-icon.png" alt="Edit">
                    </button>
                </div>
                <div class="shipping-wrapper">
                    <div class="shipping-card" [ngClass]="{'active-shipping-card': item.active}" *ngFor="let item of dashboardList">
                        <div class="dev_img_sec">
                            <img src="{{item.icon}}" class="img-fluid" alt="{{item.label}}" />
                        </div>
                        <a routerLink='{{item.routePath}}' class="btn btn_common_Css">{{item.label}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>