<div class="additional-info-css">
    <div class="grid m-0 mb-3">
        <!-- Row 1 -->
        
        <div class="col-3 pt-0 pl-0">
            <label for="customer">Customer No</label>
            <input pInputText id="customer" />
        </div>
        <div class="col-3 pt-0">
            <label for="po">PO No</label>
            <input pInputText id="po" />
        </div>
        <div class="col-3 pt-0">
            <label for="invoice">Invoice No</label>
            <input id="invoice" type="text" pInputText />
        </div>
        <div class="col-3 pt-0">
            <label for="salesorder">SO No</label>
            <input pInputText id="salesorder" />
        </div>
</div>
<div class="grid m-0 mb-3">
    <div class="col-3 pt-0 pl-0">
        <label for="references">References</label>
        <p-dropdown class="ngprime-dropdown" [options]="referenceOptions" [(ngModel)]="selectedReference"
            id="references" [style]="{ minWidth: '50px', width: '80%' }"></p-dropdown>
    </div>

    <div class="col-3 pt-0">
        <label for="prefix"> Prefix </label>
        <input pInputText id="prefix" />
    </div>
    <div class="col-3 pt-0">
        <label for="incoterm">Incoterm Location</label>
        <input pInputText id="incoterm" />
    </div>
    <div class="col-3 pt-0">
        <label for="transportationmode">Transportation Mode</label>
        <p-dropdown [options]="transportationModeOptions" [(ngModel)]="selectedTransportationMode"
            id="transportationmode" [style]="{ minWidth: '50px', width: '80%' }"></p-dropdown>
    </div>
</div>
<div class="grid m-0 mb-3">

    <div class="col-3 pt-0 pl-0">
        <label for="movecode">Move Code</label>
        <p-dropdown [options]="moveCodeOptions" [(ngModel)]="selectedMoveCode" id="movecode"
            [style]="{ minWidth: '50px', width: '80%' }"></p-dropdown>
    </div>
    <div class="col-3 pt-0">
        <label for="shipmentmethod">Shipment Method</label>
        <p-dropdown [options]="shipmentMethodOptions" [(ngModel)]="selectedShipmentMethod" id="shipmentmethod"
            [style]="{ minWidth: '50px', width: '80%' }"></p-dropdown>
    </div>
    <div class="col-3 pt-0">
        <label for="Reefer">Reefer</label>
        <p-dropdown [options]="reeferOptions" [(ngModel)]="selectedReefer" id="Reefer"
            [style]="{ minWidth: '50px', width: '80%' }"></p-dropdown>
    </div>
</div>  

        <div class="field col-12 p-0">
            <label for="notes">Notes</label>
            <textarea id="notes" type="text" rows="4"
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full inputColor p-inputtext"></textarea>
        </div>
     </div>
