import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-edit-dashboard',
  templateUrl: './edit-dashboard.component.html',
  styleUrl: './edit-dashboard.component.scss',
})
export class EditDashboardComponent implements OnInit {
  itemsList: any[] = [];

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this.itemsList = [
      {
        label: 'Ship Now',
        value: false,
      },
      {
        label: 'Track Now',
        value: false,
      },
      {
        label: 'Quote Now',
        value: false,
      },
      {
        label: 'Command Center',
        value: false,
      },
      {
        label: 'Freight Shop',
        value: false,
      },
      {
        label: 'Collaboration Dashboard',
        value: false,
      },
      {
        label: 'ADV Collaboration Dashboard',
        value: false,
      },
      {
        label: 'Ship Request',
        value: false,
      },
      {
        label: 'ACE AESDirect',
        value: false,
      },
      {
        label: 'Batch Shipping',
        value: false,
      },
      {
        label: 'Denied Address',
        value: false,
      },
    ];
    this.getCheckedList();
  }

  getCheckedList() {
    this.itemsList.forEach((item) => {
      const matchingItem = this.config.data.find(
        (data) => data.label === item.label
      );
      if (matchingItem) {
        item.value = true;
      }
    });
  }

  onCancel() {
    this.ref.close();
  }

  onSave() {
    this.ref.close();
  }
}
