<!-- ADDRESSBOOK  DIALOG START-->
 <div class="addressBookPopup">
  <p-dialog [(visible)]="display" [modal]="true" showHeader="false" 
  [style]="{'width': '75%', 'height': '120vh'}">
  <div class="dialog-header">
    <div class="grid m-0">
        <div class="col-10 py-0">
            <h4 class="mb-0">Address Book</h4>
        </div>
        <div class="col-2 py-0 text-right">
            <img src="../../../../assets/images/close-icon.png" class="dialog-close" (click)="closeDialog()" />
        </div>
    </div>
</div>

  <div class="py-1 px-3">
    <div class="col-5 p-inputgroup p-input-icon-right">
      <i class="pi pi-search" style="padding-right: 10px;"></i>
      <input type="text" pInputText size="50" placeholder="Global Filter" (input)="applyFilterGlobal_Address($event, 'contains')" 
         style="width:auto">
    </div>
  </div>


  <app-dialog-table [displayedData]="displayedArray"
  [sharedViewFunction]="selectAddress.bind(this)" [snoFlag]="false"
  [columns]="addressbookcols"></app-dialog-table>

</p-dialog>
 </div>


<!-- ADDRESSBOOK DIALOG  END-->