import { Component } from '@angular/core';

declare var Razorpay: any; // Declare Razorpay globally

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent {
  constructor() { }

  pay() {
    const options: any = {
      key: 'rzp_test_PJciikE8jLbDYX', // Enter the Key ID generated from the Dashboard
      amount: 50, // Amount is in paise, so 50000 means ₹500
      currency: 'USD',
      name: 'EshipJet AI',
      description: 'Test Transaction',
      image: 'assets/images/company-logo.png',
      order_id: '', // You can create an order in your backend and get the order_id
      handler: (response: any) => {
        // Handle payment success
        alert(`Payment successful: ${response.razorpay_payment_id}`);
      },
      prefill: {
        name: 'Customer Name',
        email: 'customer@example.com',
        contact: '9999999999'
      },
      notes: {
        address: 'note value'
      },
      theme: {
        color: '#F37254'
      }
    };

    const razorpay = new Razorpay(options);
    razorpay.open();
    razorpay.on('payment.failed', (response: any) => {
      // Handle payment failure
      alert(`Payment failed: ${response.error.description}`);
    });
  }
}
