import { Component, OnInit, AfterViewInit, ViewChild, ɵsetAllowDuplicateNgModuleIdsForTest } from '@angular/core';
import { Table } from 'primeng/table'
import { HttpClient } from '@angular/common/http';
import { ERPSHIPService } from '../erpship.service';
import { MessageService } from 'primeng/api';
import { Observable, forkJoin } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import { CodeModel } from '../code-model';
import { ActivatedRoute, Router } from '@angular/router';
import { JsonPipe, Location } from '@angular/common'
import {
  FormGroup,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ThisReceiver } from '@angular/compiler';


import { filter, take } from 'rxjs/operators';
import {
  MonacoEditorComponent,
  MonacoEditorConstructionOptions,
  MonacoEditorLoaderService,
  MonacoStandaloneCodeEditor
} from '@materia-ui/ngx-monaco-editor';
import { UserDataService } from '../services/user-data.service';
import { WarningPopupComponent } from '../popup/warning-popup/warning-popup.component';




@Component({
  selector: 'app-xship',
  templateUrl: './xship.component.html',
  styleUrls: ['./xship.component.scss'],
  providers: [MessageService],
})
export class XShipComponent implements OnInit {
  @ViewChild(WarningPopupComponent) warningPopup: WarningPopupComponent;
  @ViewChild('dt1') dt: any;
  @ViewChild('dt2') addrdt: any;
  @ViewChild('fsdt') freightdt: any;
  @ViewChild('loc') loc: any;
  @ViewChild('requiredData') requiredData: any;
  @ViewChild('manifestdt') manifestdata: any;
  @ViewChild(MonacoEditorComponent, { static: false })
  autoPack = false;
  autoShip = false
  currentSelectedLocationid: any = {};

  testmsg = 'testmessage here'
  isdis: boolean = false;


  //filters
  history_filters:any={};
  history_filter_xfields:any[]=[];


  //loading flags
  shiploading: boolean = false;
  rateloading: boolean = false;
  addressvalidationload: boolean = false;


  addressResult: any = {
    'Address': {},
    'CarrierDetails': {},
    'VerifiedAddress': {}
  }

  //TESTING PURPOSE & DELETE LATAR
  isSSLoded: boolean = true;
  ssdetails: any = {
    "holdAtLocationDetail": {
      "locationId": "",
      "locationContactAndAddress": {
        "address": {
          "streetLines": [
            "",
            ""
          ],
          "city": "",
          "stateOrProvinceCode": "",
          "postalCode": "",
          "countryCode": "",
          "residential": false
        },
        "contact": {
          "personName": "",
          "emailAddress": "",
          "phoneNumber": "",
          "phoneExtension": "",
          "companyName": "",
          "faxNumber": ""
        }
      },
      "locationType": ""
    }
  };


  //------------------------- TESTING PURPORSE END

  // for Event Editor
  theme = 'vs-dark';


  eventsDetails: any = {};

  //dialog flags
  HistorytableProperties_dialog: boolean = false;
  packageSpecialService_Dialog: boolean = false;
  newField_Dialog: boolean = false;
  newGridColumns_Dialog: boolean = false;
  event_Dialog: boolean = false;
  history_Dialog: boolean = false;
  LabelPreview_Dialog: boolean = false;
  AddressValidation_Dialog: boolean = false;
  currentEventType: any; // Describes Before_event or After_Event

  currentlabeldata: any = '';


  // new Grid Column property

  newColumn: any = {};

  //newField releated
  newField: any = null;

  //history orders

  historyOrders: any[] = [];
  Org_historyOrders: any[] = [];
  selectedHistoryOrder: any;

  //for History
  history_xfields: any[] = [];
  history_xcolumns: any[] = [];
  global_history_xcolumns: any[] = [];

  //  for Location 
  xformMetaData: any;
  xfields: any[] = [];
  xcolumns: any[] = [];

  allForms: any[] = [];

  //
  parameterId = -1;
  saveButtontitle = 'Save';

  //Default values - ENUM
  //------------------------------------
  fieldUITypes = [
    { name: 'Textbox', code: 'Textbox' },
    { name: 'Checkbox', code: 'Checkbox' },
    { name: 'Dropdown', code: 'Dropdown' },
    { name: 'label', code: 'label' },
    { name: 'Button', code: 'Button' }

  ];

  // weightUnits=[{name : 'LBS', code :'LBS'},{name : 'KGS', code :'KGS'}]
  // dimensionUnits=[{name : 'IN', code :'IN'},{name : 'CM', code :'CM'}]

  fieldDataTypes = [
    { name: 'text', code: 'text' },
    { name: 'number', code: 'number' },
  ];

  iconPositions = [
    { name: 'left', code: 'left' },
    { name: 'right', code: 'right' },
  ];

  iconList = [];
  //-----------------------------------
  dataSourceList: any[] = [];
  currentSelectedTableData = [];
  currentSelectedTable: any = '';

  currentSelectedBlock: any = [];
  currentSelected_Block_Field_Name: any;
  currentSelected_Block_Name: any;
  propertyFieldSelected: boolean = false;
  currentSelctedField: any = {}



  ShipScreen: any;
  //empty model

  clearShip: any = {};
  shipment: any = {};

  //super master data
  Original_LocationMasterData: any;
  LocationMasterData: any;


  //MasterData
  Master_products: any[] = [];
  Master_feederSystem: any[] = [];
  Master_orderTypes: any[] = [];
  Master_countries: any[] = [];
  Master_states: any[] = [];
  Master_cities: any[] = [];
  Master_dimensions: any[] = [];
  Master_addressBook: any[] = [];

  //grids & popups

  //Itemcols: any[] = [];

  productsgrid: any[] = [];
  productscol: any[] = [];

  //packcols: any[] = [];

  addressbookfilters: any[] = [];
  addressbookcols: any[] = [];
  addressbookgrid: any[] = [];

  // dialog flags
  AddressBook_dialog: boolean = false;
  FreightShop_dialog: boolean = false;
  MasterProduct_dialog: boolean = false;
  hudetails_dialog: boolean = false;
  property_dialog: boolean = false;
  tableProperties_dialog: boolean = false;
  test_dialog: boolean = false;



  FreightShopResult: any[] = [];
  currentAddressBlock: any;
  selectedHu: any;
  selectedHUItems: any[] = [];
  isDataLoaded: boolean = false;
  package: any = {};


  //MONACO EDITOR RELEATED
  editorOptions: MonacoEditorConstructionOptions = {
    theme: 'myCustomTheme',
    language: 'typescript',
    roundedSelection: true,
    autoIndent: 'full'
  };

  code: any = this.getCode();
  shippingDocuments = [{ "DocumentProvider": "", "DocumentType": "", "Description": "", "Action": "" }]
  shippingCharges = [{ "SNo": "", "ChargeDescription": "", "Charge": "", "Currency": "" }]
  userData : any;


  constructor(
    private router: Router,
    private http: HttpClient,
    private xfservice: ERPSHIPService, public messageService: MessageService,
    private primengConfig: PrimeNGConfig, private route: ActivatedRoute, private location: Location,
    private monacoLoaderService: MonacoEditorLoaderService,
    private userContext : UserDataService

  ) {
     this.userData = this.userContext.getDecryptedCookie('usercontext');
    //this.isdis= true;

    this.xfservice.processAuth().subscribe((data) => { }, error => {
      this.router.navigate(['']);
    });
    //MONACO EDITOR SETTINGS
    this.monacoLoaderService.isMonacoLoaded$
      .pipe(
        filter(isLoaded => !!isLoaded),
        take(1)
      )
      .subscribe(() => {

        this.registerMonacoCustomTheme();
      });

    //console.log("Rcalling Local Master Data")

      this.xfservice.getLocationMasterData().subscribe((data) => {


      data['paymentTypes'] = this.paymentTypes;

      this.LocationMasterData = data;


      // console.log("Received Local Master Data & Location id is : " + this.currentSelectedLocationid)
      this.Original_LocationMasterData = JSON.parse(JSON.stringify(data));


      let rowdata = this.Original_LocationMasterData.location.filter((item: any) => item.LocationId == data['xLocationId'])


      this.currentSelectedLocationid = rowdata[0]

      // alert( JSON.stringify(this.currentSelectedLocationid ));
      // alert(data['xLocationId'])
      //console.log("Initial Location is : " + JSON.stringify(this.currentSelectedLocationid) + '   ' + this.currentSelectedLocationid.weightunit)
      Object.keys(this.Original_LocationMasterData).forEach(keyName => {
        let keydata: any = {};
        keydata['name'] = keyName;
        this.dataSourceList.push(keydata)
      });


      this.xfields = this.Original_LocationMasterData.LocationTemplate[0].details.fields;
      this.xcolumns = [];
      this.xfields.forEach(item => {
        this.xcolumns.push(item.fieldName);
      });

      //for historytemplates

      this.history_xfields = this.LocationMasterData['HistoryTable'][0].details


      this.history_xfields.forEach((xitem) => {
        xitem.fields.forEach((field: any) => {
          this.history_xcolumns.push(xitem.fieldHeader + '.' + field.fieldName)
        });

      });
      //screen templates
      this.ShipScreen = this.LocationMasterData['ShipScreenTemplate'][0].details;
      this.package['ItemsInfo'] = [];
      this.package['SpecialServices'] = {};


      this.package['Weightunits'] = this.currentSelectedLocationid['weightunit']
      this.package['DimensionUnits'] = this.currentSelectedLocationid['dimensionunit']

      this.shipment = this.LocationMasterData['ShipModel'][0].details;

      this.shipment.HeaderInfo.ShipDate = new Date();
      // alert(      this.shipment.HeaderInfo.ShipDate )
      const dtoken: any = JSON.stringify(JSON.parse(window.atob(window.sessionStorage.getItem('X-Token')!.split('.')[1])))

      this.shipment.HeaderInfo['Location'] = JSON.parse(dtoken).LocationId

      this.updateShipFrom();


      var cchip = JSON.stringify(this.shipment);


      this.global_history_xcolumns = this.getKeys(this.shipment)
      console.log(this.global_history_xcolumns)


      this.clearShip = JSON.parse(cchip);
      this.currentSelctedField = this.LocationMasterData['FieldModel'][0].details;


      this.route.params.subscribe((params) => {
        this.parameterId = -2
        this.parameterId = params['id'];


        if (this.parameterId == -2 || this.parameterId == undefined) {
          this.isDataLoaded = true;

        }
        else if (this.parameterId.toString().startsWith('COPY')) {

          this.saveButtontitle = 'Save'
          var prm = this.parameterId.toString().split("-");
          this.xfservice.getxFormRecordsByFromName('ShipRequest/' + prm[1]).subscribe((data) => {
            data[0].details.id = -1;
            this.shipment = data[0].details;
            this.shipment.HeaderInfo.ShipDate = new Date();

            var cchip = JSON.stringify(this.shipment);
            this.clearShip = JSON.parse(cchip);
            this.isDataLoaded = true;

          });

          this.isDataLoaded = true;

        } else if (this.parameterId > -1) {
          this.saveButtontitle = 'Update'
          this.xfservice.getxFormRecordsByFromName('ShipRequest/' + this.parameterId).subscribe((data) => {
            this.shipment = data[0].details;
            // this.shipment.HeaderInfo.ShipDate = new Date();

            var cchip = JSON.stringify(this.shipment);
            this.clearShip = JSON.parse(cchip);

            this.processChange('CarrierDetails', 'Carrier', this.shipment.CarrierDetails['Carrier'], '', '')
            this.isDataLoaded = true;

          });
        }



        //preparing history filter field data - start
        //--------------------------------------------
        this.history_filter_xfields=this.LocationMasterData.HistoryTable[0].details;

        this.history_filter_xfields.forEach((item)=>{
          // console.log(JSON.stringify(item))
          item.fields.forEach((field:any)=>{
           // this.xcolumns.push(item.fieldHeader +'.' +field.fieldName)
            if(field['filterField']==true && field['fieldName']!= "ShipDate") 
            {
             this.history_filters[item.fieldHeader +'.' +field.fieldName]='';
            }
            else if(field['fieldName']=="ShipDate"){
             this.history_filters['shipDateFrom']='';
             this.history_filters['shipDateTo']='';
            }
          })
         })


        //-------------------------------------------------
        //End history filed preparation

        this.xfservice.getxAllForms().subscribe((data) => {
          this.allForms = data;
        });

      });
    });
  }
  // ngAfterViewInit(){
  //    alert('After init')
  //  }

  assignSepecialServices() {
    // alert(JSON.stringify(this.shipment))
    // console.log( this.LocationMasterData['globalcarrierspecialservice'])
  }
  changeLabel(title: any) {
    alert(JSON.stringify(this.ShipScreen.HeaderInfo.fields[0]))
  }

  clear() {
    this.shipment = this.clearShip;
    this.shipment.HeaderInfo.ShipDate = new Date();
    this.isdis = false;
  }
  // //   getDataSourceList() {
  // //     var sourceList: any[] = [];
  // //     Object.keys(this.Original_LocationMasterData).forEach(keyName => {
  // //       let keydata = {};
  // //       keydata['name'] = keyName;
  // //       sourceList.push(keydata)
  // //     });
  // // console.log('Source List is : ' + JSON.stringify(sourceList))
  // //     return sourceList;
  // //   }


  // getSourceFieldsList() {
  //   if (this.currentSelctedField.source !== undefined) {
  //     var opNames: any[] = [];
  //     Object.keys(this.Original_LocationMasterData).forEach(keyName => {
  //       let keydata = {};
  //       keydata['name'] = keyName;
  //       opNames.push(keydata)
  //     });
  //     return opNames;
  //   } else {
  //     return [];
  //   }
  // }


  getKeys(obj: any): string[] {
    const keys: string[] = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keys.push(key);
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          const nestedKeys = this.getKeys(obj[key]);
          keys.push(...nestedKeys.map(nestedKey => `${key}.${nestedKey}`));
        }
      }
    }
    return keys;
  }


  getOptioneNames() {
    if (this.currentSelctedField.source) {
      var opNames: any[] = [];
      //console.log(JSON.stringify(this.currentSelctedField.source))
      Object.keys(this.Original_LocationMasterData[this.currentSelctedField.source][0]).forEach(keyName => {
        //console.log(keyName)
        let keydata: any = {};
        keydata['name'] = keyName;
        opNames.push(keydata)
      });
      return opNames;
    } else {
      return [];
    }
  }


  getUserDetails(): any {
    // let cookies = document.cookie;
    // let cparts = cookies.split('=');
    var udetails = this.userData.body;
    return udetails.Role;
  }
  //deletePackage

  // deletePackage(prd: any): void {
  //   console.log('Package Info is : ' + JSON.stringify(prd));
  // }

  onEditComplete(event: any) {
    event.data.Partial = parseInt(event.data.Partial);
    if (event.data.Balance < event.data.Partial) {
      console.log('calling error');
      //this.showError('Invalid Partial Quantity.');
    }
  }



  showHUDetails(hudetails: any): void {
    this.selectedHu = hudetails;
    this.selectedHUItems = this.selectedHu.ItemsInfo;
    console.log('Columns: ' + this.productscol)
    this.hudetails_dialog = true;

  }






  masterData: any;
  paymentTypes: any[] = [];
  billingFieldsDisable: boolean = true;
  carriers: any[] = [];
  ServiceList: any[] = [];
  AccountList: any[] = [];
  closeShipScreen(): void {

    this.location.back()
  }
  getData(): void {
    console.log(this.shipment);
  }

  showngmodelchange() { }
  ngOnInit() {

    // this.isdis= false;
    this.paymentTypes = [
      { name: 'Sender', code: 'Sender' },
      { name: 'Receipient', code: 'Receipient' },
      { name: 'Third Party', code: 'ThirdParty' },
      { name: 'Collect', code: 'Collect' },
    ];


    //to enable carrier special services--------------------
    this.assignSepecialServices();
    //-----------------------------------
  }

  applyHistoryFilters(status:any,event: any, element: any):void{
   
    var fromdate=''
    var toDate=''
    var conditions:any={};
   // console.log(this.history_filters['shipDateFrom'])
    if(this.history_filters['shipDateFrom']!='' && this.history_filters['shipDateto']!=''){
    
      var shipDateFrom=this.history_filters['shipDateFrom'];
      var shipDateTo=this.history_filters['shipDateTo']
     // console.log(this.shipDateFrom)
     
      fromdate += shipDateFrom.getFullYear();
      if((shipDateFrom.getMonth() + 1) < 10){
       fromdate += '-0' +  (shipDateFrom.getMonth()+1) 
      }
      else
       fromdate += '-' + (shipDateFrom.getMonth()+1)
      if(shipDateFrom.getDate()<10)
       fromdate += '-0' +shipDateFrom.getDate();
      else
       fromdate += '-' + shipDateFrom.getDate();
      toDate += shipDateTo.getFullYear() ;
      if((shipDateTo.getMonth() + 1) < 10){
       toDate += '-0' +  (shipDateTo.getMonth()+1) 
      }
      else
       toDate += '-' + (shipDateTo.getMonth()+1)
      if(shipDateTo.getDate()<10)
       toDate += '-0' +shipDateTo.getDate();
      else
       toDate += '-' + shipDateTo.getDate();  
   
    
    conditions['shipdate']="details->'HeaderInfo'->>'ShipDate' between '" + fromdate +"' and '" + toDate +"'"
    }
    var keys=Object.keys(this.history_filters);
   // alert(keys.length)
    keys.forEach((item,index)=>{
     
      var condition='';
      var fields: any =[];
      var dataConditions ='';
      if(item !="shipDateFrom" && item !="shipDateTo"){
        if(this.history_filters[item]!=''){
          fields=item.split('.')
          condition += "upper(details->'" + fields[0] + "'->>'" + fields[1] +"')=upper('" + this.history_filters[item] +"')";

          dataConditions = dataConditions + ' ' + "upper(details->'" + fields[0] + "'->>'" + fields[1] +"')=upper('" + this.history_filters[item] +"')"
         // console.log(condition)
          conditions["condition" + index]=condition
        }
      }
    })

  
  // alert(JSON.stringify(conditions))
    this.xfservice.getShipmentstByFilters(JSON.stringify(conditions)).subscribe((data:any)=>{ 
      this.Org_historyOrders= data;
      this.historyOrders= data;
      element.hide(event);

    this.isDataLoaded = true;
      
    })

  }

  onPaymentTypeChange(): void {
    if (
      this.shipment.CarrierDetails.PaymentType == 'Receipient' ||
      this.shipment.CarrierDetails.PaymentType == 'ThirdParty' ||
      this.shipment.CarrierDetails.PaymentType == 'Collect'
    ) {
      this.billingFieldsDisable = false;
    } else {
      this.billingFieldsDisable = true;
    }
  }

  // DELETE HU START 
  deleteHu(hudetails: any): void {
    hudetails.ItemsInfo.forEach((p: any) => {
      const pidata = this.shipment.Items.filter((pi: any) => {
        if (p.Sno == pi.Sno) {
          pi.Balance = pi.Balance + parseInt(p.Partial);

          return pi;
        }
      })
      if (pidata.length == 0) {
        p.Balance = parseInt(p.Partial);
        p.Partial = 0;
        this.shipment.Items.push(p);
      }



      this.shipment.Packages = this.shipment.Packages.filter((item: any) => item !== hudetails)
      this.deleteIntlItems(p);
    })
  }

  // DELETE HU END



  //CREATE HU START
  createHu(): void {


    var Before_userExist_code = 'return true';
    var After_userExist_code = 'return true';
    var cfield = this.ShipScreen['FOOTER_LEFT'].fields.filter((item: any) => item.fieldName == 'Pack')[0];
    if (cfield) {
      if (cfield['Before_event']) {
        Before_userExist_code = atob(cfield['Before_event'])
      }
      if (cfield['After_event']) {
        After_userExist_code = atob(cfield['After_event'])
      }
    }



    var func = new Function("return " + "function (messageProcess,template,value,model) { " + Before_userExist_code + " }")();
    if (func(this.messageService, this.ShipScreen, null, this.shipment)) {

      //console.log('Selected Products are');
      let current_package = JSON.parse(JSON.stringify(this.package));
      this.shipment.Items.forEach((p: any) => {
        if (p.Partial > 0) {
          current_package['ItemsInfo'].push(Object.assign({}, p));
          this.processIntlItems(p);
        }
      });



      current_package['Sno'] = this.shipment.Packages.length + 1;

      console.log('-----> Current package is : ' + JSON.stringify(current_package))
      this.xfservice.createHU(current_package).subscribe((data) => {
        current_package.HU = data.HU;
        this.shipment.Packages.push(current_package);
        this.shipment.Items.forEach((p: any) => {
          if (p.Partial > 0) {
            p.Balance = p.Balance - p.Partial;
            p.Partial = 0;
          }
        });
        this.shipment.Items = this.shipment.Items.filter((p: any) => { return p.Balance > 0 });
      });
    }


    //after User Exist
    if (After_userExist_code) {
      var after_func = new Function("return " + "function (messageProcess,template,value,model) { " + After_userExist_code + " }")();
      after_func(this.messageService, this.ShipScreen, null, this.shipment)

    }

  }
  // CREATE HU END


  // PACK ALL HU START
  createHu_packall(): void {


    var Before_userExist_code = 'return true';
    var After_userExist_code = 'return true';
    var cfield = this.ShipScreen['FOOTER_LEFT'].fields.filter((item: any) => item.fieldName == 'PackAll')[0];
    if (cfield) {
      if (cfield['Before_event']) {
        Before_userExist_code = atob(cfield['Before_event'])
      }
      if (cfield['After_event']) {
        After_userExist_code = atob(cfield['After_event'])
      }
    }
    var func = new Function("return " + "function (messageProcess,template,value,model) { " + Before_userExist_code + " }")();
    if (func(this.messageService, this.ShipScreen, null, this.shipment)) {
      let current_package = JSON.parse(JSON.stringify(this.package));
      this.shipment.Items.forEach((p: any) => {
        p.Partial = p.Balance;
        current_package['ItemsInfo'].push(Object.assign({}, p));
        this.processIntlItems(p);


      });
      current_package['Sno'] = this.shipment.Packages.length + 1;
      this.xfservice.createHU(current_package).subscribe((data) => {
        current_package.HU = data.HU;
        this.shipment.Packages.push(current_package);
        this.shipment.Items = []
      });

    }

    if (After_userExist_code) {
      var after_func = new Function("return " + "function (messageProcess,template,value,model) { " + After_userExist_code + " }")();
      after_func(this.messageService, this.ShipScreen, null, this.shipment)

    }

  }
  // PACK ALL END


  //Imyternational Item Processing 
  processIntlItems(item: any) {
    if (this.shipment.InternationalDetails.ItemInfo) { } else {
      this.shipment.InternationalDetails['ItemInfo'] = [];
    }


    const existingitem = this.shipment.InternationalDetails.ItemInfo.filter((it: any) => it.ItemNo == item.ItemNo)
    if (existingitem.length > 0) {
      //existingitem[0].Balance = parseInt(existingitem[0].Balance )-parseInt(item.Partial);

      existingitem[0].Partial = parseInt(existingitem[0].Partial) + parseInt(item.Partial);


    } else {
      this.shipment.InternationalDetails['ItemInfo'].push(Object.assign({}, item))
    }


    // 
    //alert ( JSON.stringify(item))
  }
  deleteIntlItems(item: any) {

    const deletableItem = this.shipment.InternationalDetails.ItemInfo.filter((it: any) => it.ItemNo == item.ItemNo)

    if (deletableItem.length > 0) {
      deletableItem[0].Partial = parseInt(deletableItem[0].Partial) - parseInt(item.Partial);
      this.shipment.InternationalDetails.ItemInfo = this.shipment.InternationalDetails.ItemInfo.filter((it: any) => it.Partial > 0)
    }


  }


  // delete ITEM START
  deleteItem(selecteditem: any): void {
    this.shipment.Items = this.shipment.Items.filter((item: any) => item !== selecteditem)
  }
  // delete ITEM END


  // open Master Products
  openMasterProducts(): void {
    this.xfservice
      .getScreenTemplateByName('ShipMasterProductDialog')
      .subscribe((data) => {

        this.productscol = [];
        this.productsgrid = data[0].details;
        this.productsgrid.forEach((colfield) => {
          //console.log(colfield.fieldName);
          let col = {
            field: '',
            header: '',
            visible: true,
            editable: true,
            fieldType: '',
            datatype: '',
          };
          col.field = colfield.fieldName;
          col.header = colfield.header;
          col.visible = colfield.visible;
          col.editable = colfield.editable;
          col.fieldType = colfield.fieldType;
          col.datatype = colfield.datatype;
          if (col.field == 'Quantity' || col.field == 'Balance' || col.field == 'Partial' || col.field == 'IsSerial' || col.field == 'IsBatch' || col.field == 'IsStackable') {

          } else {
            this.productscol.push(col);
          }
        });

      });


    this.xfservice
      .getMasterDataByName('products')
      .subscribe((data) => {

        // console.log("Products are 1:" + JSON.stringify(data))
        this.Master_products = data;
        var i = 1;
        this.Master_products.forEach((p: any) => {
          p["Sno"] = i++;

        })
        //  console.log("Products are 22:" + JSON.stringify(this.Master_products));
        this.MasterProduct_dialog = true;
      });



  }

  addProduct(data: any): void {
    var sitem = Object.assign({}, data);
    var max = 0;
    this.shipment.Items.forEach((p: any) => { if (p.Sno > max) max = p.Sno; })
    sitem.Sno = max + 1;
    this.shipment.Items.push(sitem);
    this.MasterProduct_dialog = false;
  }


  assignAddress(data: any): void {

    this.shipment[this.currentAddressBlock].COMPANY = data.COMPANY;
    this.shipment[this.currentAddressBlock].CONTACT = data.CONTACT;
    this.shipment[this.currentAddressBlock].ADDRESS_LINE1 = data.ADDRESS_LINE1;
    this.shipment[this.currentAddressBlock].ADDRESS_LINE2 = data.ADDRESS_LINE2;
    this.shipment[this.currentAddressBlock].CITY = data.CITY;
    this.shipment[this.currentAddressBlock].STATE = data.STATE;
    this.shipment[this.currentAddressBlock].ZIPCODE = data.ZIPCODE;
    this.shipment[this.currentAddressBlock].COUNTRY = data.COUNTRY;
    this.shipment[this.currentAddressBlock].PHONE = data.PHONE;
    this.shipment[this.currentAddressBlock].EMAIL = data.EMAIL;
    this.AddressBook_dialog = false;

  }


  // PRODUCT GLOBAL FILTER START
  applyFilterGlobal($event: any, stringVal: any): void {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  applyFilterGlobal_Location($event: any, stringVal: any): void {
    this.loc.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  applyFilterGlobal_Address($event: any, stringVal: any): void {
    this.addrdt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }


  applyManifestFilterGlobal($event: any, stringVal: any): void {

    
if(($event.target as HTMLInputElement).value)
{


  this.historyOrders= this.filterNestedArray( this.Org_historyOrders,($event.target as HTMLInputElement).value)

  // // this.historyOrders = this.Org_historyOrders.filter(item =>
  // //   Object.values(item).some(value =>
  // //     typeof value === 'string' && value.includes(($event.target as HTMLInputElement).value)
  // //   )
  // // );

  // console.log('----' + ($event.target as HTMLInputElement).value)
  // console.log(this.Org_historyOrders)
  // console.log(this.historyOrders)
}else{
  this.historyOrders = Array.from(this.Org_historyOrders)
}

  


    //this.manifestdata.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
   
  }





  applyFilterGlobal_FreightShop($event: any, stringVal: any): void {
    this.freightdt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  //PRODUCT GLOBAL FILETER END 





   filterNestedArray(jsonArray: any[], searchTerm: string): any[] {
    return jsonArray.filter(item => {
      if (typeof item === 'object' && item !== null) {
        return Object.values(item).some(value => this.filterNestedArray([value], searchTerm).length > 0);
      }
      return String(item).includes(searchTerm);
    });
  }





  //COUNTRY CHNAGE START

  changeCountry(event: any): void {
    console.log(event.value);
    //to get all countries
    this.xfservice
      .getMasterCountries("country_code='" + event.value + "'")
      .subscribe((data) => {
        this.Master_states = data;
        console.log(this.Master_countries)
      });
  }
  //COUNTRY CHANGE END


  assigneServiceFromFreightshop(data: any) {
    alert(JSON.stringify(data))
  }



  //ProcessFreightShop

  processFreightShop(): void {
    this.rateloading = true;

    if (this.shipment.CarrierDetails.Carrier) {

      var currentCarrier = this.LocationMasterData.carrieraccounts.filter((accDetails: any) => accDetails.Account == this.shipment.CarrierDetails.ShippingAccount)
      if (currentCarrier.length > 0) {
        this.shipment.CarrierDetails['UserId'] = currentCarrier[0].UserId
        this.shipment.CarrierDetails['Password'] = currentCarrier[0].Password
      }
      this.xfservice
        .processRateShop(this.shipment, this.shipment.CarrierDetails['Carrier'] + 'Rate')
        .subscribe((data) => {
          this.rateloading = false;
          if (data.status == "Error") {
            data.Errors.forEach((err: any) => {
              this.warningPopup.showWarning(err);
            })
          } else {
            this.FreightShopResult = data;
            this.FreightShop_dialog = true;
          }
        });

    } else {
      //Freight Shop All Carriers
      //-------------------------------------------------------------
      //this.rateloading = true;

      var activeCarriers = this.LocationMasterData.carriers.filter((carrierDetails: any) => carrierDetails.IsActive == true)
      //console.log(activeCarriers)
      if (activeCarriers.length > 0) {
        //console.log('Carrier Accounts are : ' ) ; console.log(this.LocationMasterData.carrieraccounts)
        this.LocationMasterData.carrieraccounts = this.Original_LocationMasterData.carrieraccounts.filter((ca: any) => ca.LocationId == this.currentSelectedLocationid['LocationId'] && ca.IsActive == true)

        const activeCarrierAccounts = this.LocationMasterData.carrieraccounts.filter(
          (accDetails: any) => activeCarriers.some((carrierDetails: any) => carrierDetails.CarrierName == accDetails.CarrierName && accDetails.IsActive == true));
        // console.log(activeCarrierAccounts)

        if (activeCarrierAccounts.length > 0) {

          var rateRequest: any[] = [];//JSON.parse(JSON.stringify(this.shipment))//Object.assign({},this.shipment);
          var rateResponse: Observable<any>[] = []
          activeCarrierAccounts.forEach((acc: any, index: number) => {
            rateRequest.push(JSON.parse(JSON.stringify(this.shipment)))

            rateRequest[index].CarrierDetails.Carrier = acc.CarrierName;
            rateRequest[index].CarrierDetails.UserId = acc.UserId;
            rateRequest[index].CarrierDetails.Password = acc.Password;
            rateRequest[index].CarrierDetails.ShippingAccount = acc.Account;
            rateRequest[index].CarrierDetails.PaymentType = 'Sender';



            rateResponse.push(this.xfservice.processRateShop(rateRequest[index], rateRequest[index].CarrierDetails['Carrier'] + 'Rate'))

          })

          //  rateResponse.forEach((res:Observable<any>)=> res.subscribe((data:any)=>{
          //   this.FreightShopResult = this.FreightShopResult.concat(data);
          //   this.FreightShop_dialog = true;
          //   console.log(data)
          // }))
          this.rateloading = false;
          forkJoin(rateResponse).subscribe((res) => {
            this.rateloading = false;
            // console.log(new Date())
            //     console.log('Response 1:', JSON.stringify(res));
            this.FreightShopResult = [];
            res.forEach(rateres => {
              this.FreightShopResult = this.FreightShopResult.concat(rateres);
            })
            this.FreightShop_dialog = true;

          }, error => {
            // alert('An error occurred:' + error);
          });


        }



      }


      // if (currentCarrier.length > 0) {
      //   this.shipment.CarrierDetails['UserId'] = currentCarrier[0].UserId
      //   this.shipment.CarrierDetails['Password'] = currentCarrier[0].Password
      // }
      // this.xfservice
      //   .processRateShop(this.shipment, this.shipment.CarrierDetails['Carrier'] + 'Rate')
      //   .subscribe((data) => {
      //     this.rateloading = false;
      //     if (data.status == "Error") {
      //       data.Errors.forEach((err: any) => {
      //         this.messageService.add({
      //           key: 'tc',
      //           severity: 'error',
      //           summary: 'Error',
      //           detail: err,
      //         });
      //       })

      //     } else {
      //       this.FreightShopResult = data;
      //       this.FreightShop_dialog = true;
      //     }
      //   });










      //----------------------------------------------------------------

    }


  }





  
  //Save Ship Request Start
  Save(): void {

  
    this.xfservice
      .saveShipRequest(this.shipment)
      .subscribe((data) => {
       
        this.shipment = Object.assign({}, this.clearShip);
        // this.messageService.add({
        //   key: 'tc',
        //   severity: 'success',
        //   summary: 'Ship Request',
        //   detail: 'ShipRequest Successfully saved',
        // });
      });

  }

  // Save Ship Request end

  //Show Label

  showLabel(rdata: any) {

    if (rdata['docType'] == 'PDF') {

      const byteArray = new Uint8Array(atob(rdata.encodedLabel).split('').map(char => char.charCodeAt(0)));

      var pblob = new Blob([byteArray], { type: 'application/pdf' });

      const url = window.URL.createObjectURL(pblob);
      window.open(url, "_blank");

    }
    else {
      if (rdata.encodedLabel) {
        this.currentlabeldata = 'data:image/png;base64,' + rdata.encodedLabel;
        this.LabelPreview_Dialog = true;
      }

    }


  }



  printLabel(rdata: any) {
    alert(rdata)
  }
  //Save Ship Request Start

  Ship(): void {
    this.shiploading = true;
    // this.shipment.CarrierDetails['UserId'] = 'l70c717f3eaf284dc9af42169e93874b6e'
    // this.shipment.CarrierDetails['Password'] = '7f271bf486084e8f8073945bb7e6a020'
    var currentCarrier = this.LocationMasterData.carrieraccounts.filter((accDetails: any) => accDetails.Account == this.shipment.CarrierDetails.ShippingAccount)
    if (currentCarrier.length > 0) {
      this.shipment.CarrierDetails['UserId'] = currentCarrier[0].UserId
      this.shipment.CarrierDetails['Password'] = currentCarrier[0].Password
    }
    this.xfservice
      .BookShipment(this.shipment, this.shipment.CarrierDetails.Carrier)
      .subscribe((data) => {
        this.shiploading = false;
        if (data.status == "Error") {
          data.Errors.forEach((err: any) => {
            this.warningPopup.showWarning(err);
          })
        }
        else if (data.status == "Success") {
          this.shipment = data;
          var manifestship = JSON.parse(JSON.stringify(this.shipment));
          this.shipment = this.clearShip;
          this.xfservice
            .SaveShipment(manifestship)
            .subscribe((sdata) => {
              // this.messageService.add({
              //   key: 'tc',
              //   severity: 'success',
              //   summary: 'Shipment',
              //   detail: 'Shipment Processed Successfully',
              // });

            });
        }
      },
      (error) => {
        this.shiploading = false;
        this.warningPopup.showWarning(error.message);
      }
    );
  }

  // Save Ship Request end



  //Address Book Popup START

  openAddressBook(fromblock: any): void {
    this.currentAddressBlock = fromblock;
    this.addressbookcols = [];
    this.addressbookfilters = [];
    this.xfservice.getAddressBookTemplate().subscribe((data) => {


      this.addressbookgrid = data[0].fields;

      this.addressbookgrid.forEach((colfield) => {
        // console.log(colfield);
        let col = {
          field: '',
          header: '',
          visible: true,
          editable: false,
          fieldType: '',
          datatype: '',
        };
        col.field = colfield.fieldName;
        col.header = colfield.fieldName;
        col.editable = colfield.editable;
        col.fieldType = colfield.fieldType;
        col.datatype = colfield.datatype;
        this.addressbookfilters.push(colfield.fieldName)
        if (col.field == 'id' || col.field == 'IsActive') {
          col.visible = false;
        }
        if (col.visible) {
          this.addressbookcols.push(col);
        }
      });
      //console.log('addressbook fields are: ' + JSON.stringify(this.addressbookcols));

      this.xfservice.getMasterDataByName('addressBook').subscribe((data) => {
        this.Master_addressBook = data;
        // console.log('Address:' + data)
        this.AddressBook_dialog = true;
      });
    });






  }

  //Address Book popup end



  specialServiceData: any = {}

  processChange(blockname: any, sourcefield: any, value: any, effectedfields: string, mappingfield: string) {
    // code to get event releated to source field

    //alert(sourcefield);
    var Before_userExist_code = 'return true';
    var After_userExist_code = 'return true';
    var cfield = this.ShipScreen[blockname].fields.filter((item: any) => item.fieldName == sourcefield)[0];

    if (cfield) {
      if (cfield['Before_event']) {
        Before_userExist_code = atob(cfield['Before_event'])
      }

      // Field based logic -> carrier field
      //-------------------------------------------------
      if (sourcefield == 'Carrier') {

        //console.log( 'Current selectttd Location is : ' +  JSON.stringify(this.currentSelectedLocationid ))
        this.shipment.CarrierDetails.PaymentType = 'Sender';
        this.LocationMasterData.carrieraccounts = [];
        if (this.shipment.CarrierDetails.Carrier) {
          this.LocationMasterData.carrieraccounts = this.Original_LocationMasterData.carrieraccounts.filter((ca: any) => ca.CarrierName == value && ca.LocationId == this.currentSelectedLocationid['LocationId'])
          if (this.LocationMasterData.carrieraccounts.length > 0) {
            this.shipment.CarrierDetails.ShippingAccount = this.LocationMasterData.carrieraccounts[0].Account;
            this.shipment.CarrierDetails.UserId = this.LocationMasterData.carrieraccounts[0].UserId;
            this.shipment.CarrierDetails.Password = this.LocationMasterData.carrieraccounts[0].Password;
            this.shipment.CarrierDetails['AccessKey'] = this.LocationMasterData.carrieraccounts[0].AccessKey;
          }

        } else {
          this.LocationMasterData.carrieraccounts = this.Original_LocationMasterData.carrieraccounts.filter((ca: any) => ca.LocationId == this.currentSelectedLocationid['LocationId'] && ca.IsActive == true)

        }

        console.log(this.LocationMasterData.carrieraccounts)
        //  console.log( this.LocationMasterData.carrieraccounts)

        //  console.log(this.LocationMasterData.carrieraccounts[0].Account)
        //  console.log(this.LocationMasterData.carrieraccounts[0].UserId)
        //  console.log(this.LocationMasterData.carrieraccounts[0].Password)


        // alert(value)
      }



      //-------------------------------------------------------------





      if (cfield['After_event']) {
        After_userExist_code = atob(cfield['After_event'])
      }

      // need to move on inital call @ location master data
      //-------------------------------------------------------------------
      if (sourcefield == 'Carrier') {
        this.xfservice.getSpecialServicesByCarrier(value).subscribe((data: any) => {


          this.specialServiceData = data;
          this.isSSLoded = true;
          // console.log(this.specialServiceData)

        })
      }
      //-------------------------------------------------------------------
    }

    //Get Special Servises for selected carrier



    //Default code condition to run standard functionality
    //var func = new Function("return " + "function ('template','org_masterdata','masterdata') { " + userExist_code + " }")();
    var func = new Function("return " + "function (messageProcess,template,value,model) { " + Before_userExist_code + " }")();
    if (func(this.messageService, this.ShipScreen, value, this.shipment)) {



      // alert('After call: ' + JSON.stringify(this.ShipScreen['CarrierDetails'].fields))

      if (effectedfields) {
        var ef_fields = [];
        if (effectedfields.indexOf(",") > 0) {
          ef_fields = effectedfields.split(",");
        } else {
          ef_fields.push(effectedfields);
        }

        ef_fields.forEach(item => {
          this.LocationMasterData[item] = this.Original_LocationMasterData[item].filter((mf: any) => mf[mappingfield] === value)
        });

      }

    }

    //after User Exist
    if (After_userExist_code) {
      var after_func = new Function("return " + "function (messageProcess,template,value,model,orgMasterData,currentMasterData) { " + After_userExist_code + " }")();
      after_func(this.messageService, this.ShipScreen, value, this.shipment, this.Original_LocationMasterData,
        this.LocationMasterData)

    }


  }



  //Process Button Click
  processButtonClick(blockname: any, sourcefield: any, value: any, effectedfields: string, mappingfield: string) {


    // code to get event releated to source field
    var Before_userExist_code = 'return true';
    var After_userExist_code = 'return true';
    var cfield = this.ShipScreen[blockname].fields.filter((item: any) => item.fieldName == sourcefield)[0];
    if (cfield) {
      if (cfield['Before_event']) {
        Before_userExist_code = atob(cfield['Before_event'])
      }
      if (cfield['After_event']) {
        After_userExist_code = atob(cfield['After_event'])
      }
    }





    //var func = new Function("return " + "function ('template','org_masterdata','masterdata') { " + userExist_code + " }")();
    var func = new Function("return " + "function (messageProcess,template,value,model) { " + Before_userExist_code + " }")();
    if (func(this.messageService, this.ShipScreen, value, this.shipment)) {
      if (effectedfields) {
        var ef_fields = [];
        if (effectedfields.indexOf(",") > 0) {
          ef_fields = effectedfields.split(",");
        } else {
          ef_fields.push(effectedfields);
        }
        ef_fields.forEach(item => {
          this.LocationMasterData[item] = this.Original_LocationMasterData[item].filter((mf: any) => mf[mappingfield] === value)
        });
      }
    }

    //Default code condition to run standard functionality
    //------------------------------------------------------------------------------

    if (blockname == 'ShipTo' && sourcefield == 'Validate Address') {
      this.addressvalidationload = true;
      this.addressResult.Address = this.shipment.ShipTo;
      this.addressResult.CarrierDetails = this.shipment.CarrierDetails;
      this.xfservice
        .processAddressValidation(this.addressResult)
        .subscribe((data) => {
          this.addressvalidationload = false;
          console.log('Address Result is : ' + JSON.stringify(data));
          this.addressResult = data;
          this.AddressValidation_Dialog = true;
        })
      // alert(blockname + '   ' + sourcefield)
    }




    //--------------------------------------------------------------------------------
    //after User Exist
    if (After_userExist_code) {
      var after_func = new Function("return " + "function (messageProcess,template,value,model) { " + After_userExist_code + " }")();
      after_func(this.messageService, this.ShipScreen, value, this.shipment)

    }


  }



  //Update Verified address
  updateWithVerifiedAddress(): void {
    this.shipment.ShipTo.ADDRESS_LINE1 = this.addressResult.VerifiedAddress['ADDRESS_LINE1']
    this.shipment.ShipTo.ADDRESS_LINE2 = this.addressResult.VerifiedAddress['ADDRESS_LINE2']
    this.shipment.ShipTo.CITY = this.addressResult.VerifiedAddress['CITY']
    this.shipment.ShipTo.STATE = this.addressResult.VerifiedAddress['STATE']
    this.shipment.ShipTo.ZIPCODE = this.addressResult.VerifiedAddress['ZIPCODE']
    this.shipment.ShipTo.COUNRTY = this.addressResult.VerifiedAddress['COUNRTY']
    this.addressResult.VerifiedAddress = {}
    this.AddressValidation_Dialog = false;
  }

  // TABLE PROPERTIES 
  openTableProperties(tableproperties: any, tname: any): void {
    this.currentSelectedTableData = tableproperties
    this.currentSelectedTable = tname;
    this.tableProperties_dialog = true
  }


  //BlockProperties
  openBlockProperties(blockname: any): void {
    this.currentSelectedBlock = this.ShipScreen[blockname].fields;
    this.currentSelected_Block_Name = blockname;
    this.property_dialog = true;
    // console.log('BLOCk DATA : ' + JSON.stringify(this.currentSelectedBlock))
  }


  onKeyUp(event: any, fn: any, bn: any): void {
  }

  loadFieldProperties() {

    this.currentSelctedField = this.ShipScreen[this.currentSelected_Block_Name].fields.filter((item: any) => { return item.fieldName == this.currentSelected_Block_Field_Name })[0];
    this.propertyFieldSelected = true

  }


  // Save/Update TemplateChanges
  SaveTemplate(templatename: any): void {

    this.xfservice
      .saveScreenTemplateByName(templatename, this.ShipScreen)
      .subscribe((data) => {

        this.tableProperties_dialog = false
        // this.messageService.add({
        //   key: 'tc',
        //   severity: 'success',
        //   summary: 'Template',
        //   detail: templatename + ' Properties are successfully saved.',
        // });
        this.property_dialog = false;

      });

  }


  SaveHistoryTemplate(): void {

    this.xfservice
      .saveScreenTemplateByName('HistoryTable', this.history_xfields)
      .subscribe((data) => {

        this.tableProperties_dialog = false
        // this.messageService.add({
        //   key: 'tc',
        //   severity: 'success',
        //   summary: 'Template',
        //   detail: 'HistoryTable' + ' Properties are successfully saved.',
        // });
        this.property_dialog = false;

      });

  }



  SaveRequiredMasterData(templatename: any, event: any, element: any): void {

    this.xfservice
      .saveScreenTemplateByName(templatename, this.ShipScreen)
      .subscribe((data) => {

        this.tableProperties_dialog = false
        // this.messageService.add({
        //   key: 'tc',
        //   severity: 'success',
        //   summary: 'Template',
        //   detail: templatename + ' Properties are successfully saved.',
        // });
        element.hide(event);
      });

  }

  updateShipFrom() {


    let rowdata = this.Original_LocationMasterData.location.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location)
    //console.log('ROW DATA IS: ' + JSON.stringify(rowdata));

    this.currentSelectedLocationid = rowdata[0];
    //console.log('Selected Location Is : ' + JSON.stringify(this.currentSelectedLocationid) + ' ---------> ' + this.currentSelectedLocationid.weightunit)
    this.package['Weightunits'] = this.currentSelectedLocationid['weightunit']
    this.package['DimensionUnits'] = this.currentSelectedLocationid['dimensionunit']
    this.shipment.ShipFrom.COMPANY = rowdata[0].LocationName;
    this.shipment.ShipFrom.CONTACT = rowdata[0].Contact;
    this.shipment.ShipFrom.ADDRESS_LINE1 = rowdata[0].AddressLine1;
    this.shipment.ShipFrom.ADDRESS_LINE2 = rowdata[0].AddressLine2;
    this.shipment.ShipFrom.CITY = rowdata[0].City;
    this.shipment.ShipFrom.STATE = rowdata[0].State;
    this.shipment.ShipFrom.ZIPCODE = rowdata[0].ZipCode;
    this.shipment.ShipFrom.COUNTRY = rowdata[0].Country;
    this.shipment.ShipFrom.PHONE = rowdata[0].Phone;
    this.shipment.ShipFrom.EMAIL = rowdata[0].Email;



    this.shipment.Shipper.COMPANY = rowdata[0].LocationName;
    this.shipment.Shipper.CONTACT = rowdata[0].Contact;
    this.shipment.Shipper.ADDRESS_LINE1 = rowdata[0].AddressLine1;
    this.shipment.Shipper.ADDRESS_LINE2 = rowdata[0].AddressLine2;
    this.shipment.Shipper.CITY = rowdata[0].City;
    this.shipment.Shipper.STATE = rowdata[0].State;
    this.shipment.Shipper.ZIPCODE = rowdata[0].ZipCode;
    this.shipment.Shipper.COUNTRY = rowdata[0].Country;
    this.shipment.Shipper.PHONE = rowdata[0].Phone;
    this.shipment.Shipper.EMAIL = rowdata[0].Email;



    this.LocationMasterData.carriers = this.Original_LocationMasterData.carriers.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.carrieraccounts = this.Original_LocationMasterData.carrieraccounts.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.carrierservices = this.Original_LocationMasterData.carrierservices.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.dimensions = this.Original_LocationMasterData.dimensions.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.erp = this.Original_LocationMasterData.erp.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.ordertypes = this.Original_LocationMasterData.ordertypes.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
  }


  //Assigne Location 
  assignLocation(rowdata: any, event: any, element: any) {

    // console.log('Selected Location is: ' + JSON.stringify(rowdata))

    this.currentSelectedLocationid = rowdata;
    this.shipment.HeaderInfo.Location = rowdata.LocationId;

    this.shipment.ShipFrom.COMPANY = rowdata.LocationName;
    this.shipment.ShipFrom.CONTACT = rowdata.Contact;
    this.shipment.ShipFrom.ADDRESS_LINE1 = rowdata.AddressLine1;
    this.shipment.ShipFrom.ADDRESS_LINE2 = rowdata.AddressLine2;
    this.shipment.ShipFrom.CITY = rowdata.City;
    this.shipment.ShipFrom.STATE = rowdata.State;
    this.shipment.ShipFrom.ZIPCODE = rowdata.ZipCode;
    this.shipment.ShipFrom.COUNTRY = rowdata.Country;
    this.shipment.ShipFrom.PHONE = rowdata.Phone;
    this.shipment.ShipFrom.EMAIL = rowdata.Email;


    this.shipment.Shipper.COMPANY = rowdata.LocationName;
    this.shipment.Shipper.CONTACT = rowdata.Contact;
    this.shipment.Shipper.ADDRESS_LINE1 = rowdata.AddressLine1;
    this.shipment.Shipper.ADDRESS_LINE2 = rowdata.AddressLine2;
    this.shipment.Shipper.CITY = rowdata.City;
    this.shipment.Shipper.STATE = rowdata.State;
    this.shipment.Shipper.ZIPCODE = rowdata.ZipCode;
    this.shipment.Shipper.COUNTRY = rowdata.Country;
    this.shipment.Shipper.PHONE = rowdata.Phone;
    this.shipment.Shipper.EMAIL = rowdata.Email;

    this.LocationMasterData.carriers = this.Original_LocationMasterData.carriers.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.carrieraccounts = this.Original_LocationMasterData.carrieraccounts.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.carrierservices = this.Original_LocationMasterData.carrierservices.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.dimensions = this.Original_LocationMasterData.dimensions.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.erp = this.Original_LocationMasterData.erp.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    this.LocationMasterData.ordertypes = this.Original_LocationMasterData.ordertypes.filter((item: any) => item.LocationId == this.shipment.HeaderInfo.Location);
    element.hide(event)
  }


  openPackageSpecialService(rowdata: any) {
    this.selectedHu = rowdata;
    this.packageSpecialService_Dialog = true;
  }


  fieldValueChange(blockName: any, fieldname: any, fieldValue: any) {

    var cfield = this.ShipScreen[blockName].fields.filter((it: any) => it.fieldName == fieldname)
    // console.log('Field Template is : ' + JSON.stringify(cfield))
    // console.log('Changing @ '+ blockName + ' --> ' + fieldname + ' @ ' + fieldValue  )
  }


  //new field diaploopen 
  processNewField() {

    this.newField_Dialog = true;
  }

  //Adding new filed 
  addNewField() {
    var isfieldexist = []
    isfieldexist = this.ShipScreen[this.currentSelected_Block_Name].fields.filter((item: any) => item.fieldName == this.newField)
    if (isfieldexist.length > 0) {
      this.warningPopup.showWarning('Field Name with: ' + this.newField + ' Is Already Existed');

    } else {
      var newfieldmodel = Object.assign({}, this.LocationMasterData['FieldModel'][0].details)
      newfieldmodel.fieldName = this.newField;
      newfieldmodel.title = this.newField;
      newfieldmodel.standard = false;
      newfieldmodel.UDF = true;
      this.ShipScreen[this.currentSelected_Block_Name].fields.push(newfieldmodel);
      this.SaveTemplateWithDescription('ShipScreen', 'New Field : ' + this.newField + ' added to ' + this.currentSelected_Block_Name)
      this.newField_Dialog = false;
    }
  }


  //adding New Button

  addNewButton() {
    var isfieldexist = []
    isfieldexist = this.ShipScreen[this.currentSelected_Block_Name].fields.filter((item: any) => item.fieldName == this.newField)
    if (isfieldexist.length > 0) {
      this.warningPopup.showWarning('Field Name with: ' + this.newField + ' Is Already Existed');

    } else {
      var newfieldmodel = Object.assign({}, this.LocationMasterData['FieldModel'][0].details)
      newfieldmodel.fieldName = this.newField;
      newfieldmodel.title = this.newField;
      newfieldmodel.fieldType = "Button"
      newfieldmodel.standard = false;
      newfieldmodel.UDF = true;
      this.ShipScreen[this.currentSelected_Block_Name].fields.push(newfieldmodel);
      this.SaveTemplateWithDescription('ShipScreen', 'New Button : ' + this.newField + ' added to ' + this.currentSelected_Block_Name)
      this.newField_Dialog = false;
    }
  }


  SaveTemplateWithDescription(templatename: any, description: any): void {
    this.xfservice
      .saveScreenTemplateByNameAndDescription(templatename, this.ShipScreen, description)
      .subscribe((data) => {
        // this.messageService.add({
        //   key: 'tc',
        //   severity: 'success',
        //   summary: 'Template',
        //   detail: description,
        // });
      });
  }

  //Delete Field from currentselected block
  deleteFieldFromBlock() {
    this.xfservice
      .deleteFieldFromBlock(this.currentSelctedField.fieldName, this.currentSelected_Block_Name, 'Deleted ' + this.currentSelctedField.fieldName + ' from ' + this.currentSelected_Block_Name, 'ShipScreen')
      .subscribe((data) => {
        this.ShipScreen = data[0].details;
        // this.messageService.add({
        //   key: 'tc',
        //   severity: 'success',
        //   summary: 'Template',
        //   detail: 'Deleted ' + this.currentSelctedField.fieldName + ' from ' + this.currentSelected_Block_Name
        // });

        this.property_dialog = false;
      });
  }

  //Open Event Dialog

  openEventDialog(eventType: any) {
    this.event_Dialog = true;
    this.currentEventType = eventType;

    this.code = '';
    if (this.currentSelctedField[eventType]) {
      this.code = atob(this.currentSelctedField[eventType]);
    }

  }


  onCodeChanged(value: any) {
    this.code = value;

  }


  //MONACO RELEATED FUNCTIONS ---------------------

  mergeOptions(partialOptions: any) {
    return {
      ...this.editorOptions,
      ...partialOptions
    };
  }

  editorInit(editor: MonacoStandaloneCodeEditor) {
    // Programatic content selection example
    editor.setSelection({
      startLineNumber: 1,
      startColumn: 1,
      endColumn: 50,
      endLineNumber: 3
    });
  }

  getCode() {
    return (

      "console.log('This is Working')"
    );
  }


  registerMonacoCustomTheme() {
    monaco.editor.defineTheme('myCustomTheme', {
      base: 'vs-dark', // can also be vs or hc-black, vs-dark
      inherit: true, // can also be false to completely replace the builtin rules
      rules: [
        {
          token: 'comment',
          foreground: 'ffa500',
          fontStyle: 'italic underline'
        },
        { token: 'comment.js', foreground: '008800', fontStyle: 'bold' },
        { token: 'comment.css', foreground: '0000ff' } // will inherit fontStyle from `comment` above
      ],
      colors: {}
    });
  }
  //MONACO RELEATED FUNCTIONS END 

  saveEvent(blockname: any, fieldname: any) {
    // alert('Event Created for : @' + blockname + '  -->  ' + fieldname)

    this.currentSelctedField[this.currentEventType] = btoa(this.code);
    this.event_Dialog = false;
  }



  //Open new colum property

  openNewColumProperty() {
    this.newGridColumns_Dialog = true;
  }


  //adding new column to grid

  addnewColumnToGrid() {
    //alert( this.currentSelectedTable +  ' ' + JSON.stringify(this.newColumn));

    alert('Data is : ' + JSON.stringify(this.ShipScreen));
    this.ShipScreen[this.currentSelectedTable].columns.push(Object.assign({}, this.newColumn));
    this.newColumn = {};

    this.currentSelectedTableData = this.ShipScreen[this.currentSelectedTable].columns;
  }


  //open History Dialog 

  openHistoryDialog() {
    this.historyOrders = [];
    this.history_Dialog = true;
  }

  openHistoryTableProperties() {
    this.HistorytableProperties_dialog = true;
  }

  getShipments(fromobject: any) {


    this.xfservice
      .getxFormRecordsByFromName(fromobject)
      .subscribe((data) => {
        this.historyOrders = data;
        this.Org_historyOrders = data;
      });



  }
  getShipReqByDocNo() {
    var docno = this.shipment.HeaderInfo.DocumentNumber
    this.xfservice.getShipRequest(docno).subscribe(data => {
      //alert(JSON.stringify(data[0]))

      this.shipment = data[0].details;
      this.shipment.HeaderInfo.ShipDate = new Date(this.shipment.HeaderInfo.ShipDate)
      if (this.autoShip == true) {
        this.Ship();

      }
      else if (this.autoPack == true) {
        this.createHu_packall()
      }



    })

  }


  //select shipment or order 
  selectOrderorShipment() {
    this.shipment = this.clearShip;
    this.shipment = this.selectedHistoryOrder.details;
    this.shipment.HeaderInfo.ShipDate = new Date(this.shipment.HeaderInfo.ShipDate)

    //Getting Special Service data for  carrier
    //---------------------------------------------------------

    if (this.shipment.CarrierDetails.Carrier) {
      this.xfservice.getSpecialServicesByCarrier(this.shipment.CarrierDetails.Carrier).subscribe((data: any) => {
        this.specialServiceData = data;
        this.isSSLoded = true;
      })
    }

    //----------------------------------------------
    console.log('Selectd Shipment is : ')
    console.log(this.shipment)

    this.history_Dialog = false;
    //alert(this.shipment.status)
    if (this.shipment['status'] == 'Success') {
      this.isdis = true
    } else {
      this.isdis = false

      this.shipment.Shipper = this.shipment.ShipFrom;

    }
  }




}

