let currency = [
    { label: "ANG", value: "ANG" },
    { label: "EUR", value: "EUR" },
    { label: "AED", value: "AED" },
    { label: "AFN", value: "AFN" },
    { label: "XCD", value: "XCD" },
    { label: "ALL", value: "ALL" },
    { label: "AMD", value: "AMD" },
    { label: "AOA", value: "AOA" },
    { label: "NOK", value: "NOK" },
    { label: "ARS", value: "ARS" },
    { label: "USD", value: "USD" },
    { label: "AUD", value: "AUD" },
    { label: "AWG", value: "AWG" },
    { label: "AZN", value: "AZN" },
    { label: "BAM", value: "BAM" },
    { label: "BBD", value: "BBD" },
    { label: "BDT", value: "BDT" },
    { label: "XOF", value: "XOF" },
    { label: "BGN", value: "BGN" },
    { label: "BHD", value: "BHD" },
    { label: "BIF", value: "BIF" },
    { label: "BMD", value: "BMD" },
    { label: "BND", value: "BND" },
    { label: "BOB", value: "BOB" },
    { label: "BRL", value: "BRL" },
    { label: "BSD", value: "BSD" },
    { label: "BTN", value: "BTN" },
    { label: "BWP", value: "BWP" },
    { label: "BYR", value: "BYR" },
    { label: "BZD", value: "BZD" },
    { label: "CAD", value: "CAD" },
    { label: "CDF", value: "CDF" },
    { label: "XAF", value: "XAF" },
    { label: "CHE", value: "CHE" },
    { label: "NZD", value: "NZD" },
    { label: "CLP", value: "CLP" },
    { label: "RMB", value: "RMB" },
    { label: "COP", value: "COP" },
    { label: "CRC", value: "CRC" },
    { label: "CUP", value: "CUP" },
    { label: "CVE", value: "CVE" },
    { label: "CZK", value: "CZK" },
    { label: "DJF", value: "DJF" },
    { label: "DKK", value: "DKK" },
    { label: "DOP", value: "DOP" },
    { label: "DZD", value: "DZD" },
    { label: "EEK", value: "EEK" },
    { label: "EGP", value: "EGP" },
    { label: "MAD", value: "MAD" },
    { label: "ERN", value: "ERN" },
    { label: "ETB", value: "ETB" },
    { label: "FJD", value: "FJD" },
    { label: "FKP", value: "FKP" },
    { label: "GBP", value: "GBP" },
    { label: "GEL", value: "GEL" },
    { label: "GHC", value: "GHC" },
    { label: "GIP", value: "GIP" },
    { label: "GMD", value: "GMD" },
    { label: "GNF", value: "GNF" },
    { label: "GTQ", value: "GTQ" },
    { label: "GYD", value: "GYD" },
    { label: "HKD", value: "HKD" },
    { label: "HNL", value: "HNL" },
    { label: "HRK", value: "HRK" },
    { label: "HTG", value: "HTG" },
    { label: "HUF", value: "HUF" },
    { label: "IDR", value: "IDR" },
    { label: "ILS", value: "ILS" },
    { label: "INR", value: "INR" },
    { label: "IQD", value: "IQD" },
    { label: "IRR", value: "IRR" },
    { label: "ISK", value: "ISK" },
    { label: "JMD", value: "JMD" },
    { label: "JOD", value: "JOD" },
    { label: "JPY", value: "JPY" },
    { label: "KES", value: "KES" },
    { label: "KGS", value: "KGS" },
    { label: "KHR", value: "KHR" },
    { label: "KMF", value: "KMF" },
    { label: "KPW", value: "KPW" },
    { label: "KRW", value: "KRW" },
    { label: "KWD", value: "KWD" },
    { label: "KYD", value: "KYD" },
    { label: "KZT", value: "KZT" },
    { label: "LAK", value: "LAK" },
    { label: "LBP", value: "LBP" },
    { label: "CHF", value: "CHF" },
    { label: "LYD", value: "LYD" },
    { label: "LKR", value: "LKR" },
    { label: "LRD", value: "LRD" },
    { label: "LSL", value: "LSL" },
    { label: "LTL", value: "LTL" },
    { label: "LVL", value: "LVL" },
    { label: "MDL", value: "MDL" },
    { label: "MGA", value: "MGA" },
    { label: "MKD", value: "MKD" },
    { label: "MMK", value: "MMK" },
    { label: "MNT", value: "MNT" },
    { label: "MOP", value: "MOP" },
    { label: "MRO", value: "MRO" },
    { label: "MUR", value: "MUR" },
    { label: "MVR", value: "MVR" },
    { label: "MWK", value: "MWK" },
    { label: "MXN", value: "MXN" },
    { label: "MYR", value: "MYR" },
    { label: "MZN", value: "MZN" },
    { label: "NAD", value: "NAD" },
    { label: "XPF", value: "XPF" },
    { label: "NGN", value: "NGN" },
    { label: "NIO", value: "NIO" },
    { label: "NPR", value: "NPR" },
    { label: "OMR", value: "OMR" },
    { label: "PAB", value: "PAB" },
    { label: "PEN", value: "PEN" },
    { label: "PGK", value: "PGK" },
    { label: "PHP", value: "PHP" },
    { label: "PKR", value: "PKR" },
    { label: "PLN", value: "PLN" },
    { label: "PYG", value: "PYG" },
    { label: "QAR", value: "QAR" },
    { label: "RSD", value: "RSD" },
    { label: "RON", value: "RON" },
    { label: "RUB", value: "RUB" },
    { label: "RWF", value: "RWF" },
    { label: "SAR", value: "SAR" },
    { label: "SBD", value: "SBD" },
    { label: "SCR", value: "SCR" },
    { label: "SDD", value: "SDD" },
    { label: "SEK", value: "SEK" },
    { label: "SGD", value: "SGD" },
    { label: "SHP", value: "SHP" },
    { label: "SLL", value: "SLL" },
    { label: "SOS", value: "SOS" },
    { label: "SRD", value: "SRD" },
    { label: "STD", value: "STD" },
    { label: "SVC", value: "SVC" },
    { label: "SYP", value: "SYP" },
    { label: "SZL", value: "SZL" },
    { label: "THB", value: "THB" },
    { label: "TJS", value: "TJS" },
    { label: "TMT", value: "TMT" },
    { label: "TND", value: "TND" },
    { label: "TOP", value: "TOP" },
    { label: "TRY", value: "TRY" },
    { label: "TTD", value: "TTD" },
    { label: "TWD", value: "TWD" },
    { label: "TZS", value: "TZS" },
    { label: "UAH", value: "UAH" },
    { label: "UGX", value: "UGX" },
    { label: "UYU", value: "UYU" },
    { label: "UZS", value: "UZS" },
    { label: "VEB", value: "VEB" },
    { label: "VND", value: "VND" },
    { label: "VUV", value: "VUV" },
    { label: "WST", value: "WST" },
    { label: "YER", value: "YER" },
    { label: "ZAR", value: "ZAR" },
    { label: "ZMK", value: "ZMK" },
    { label: "ZWD", value: "ZWD" },
  ],
  freight = [
     
    { label: "Prepaid", value: "Prepaid" },
    { label: "Collect", value: "Collect" },
  ],
  signOpt = [
     
    { label: "DIRECT", value: "DIRECT" },
    { label: "Named Receiver", value: "NamedReceiver" },
    { label: "Content Signature", value: "ContentSignature" },
    { label: "Delivery Signature", value: "DeliverySignature" },
    { label: "No Signature Required", value: "NoSignatureRequired" },
    { label: "Delivery Signature", value: "DeliverySignature" },
    { label: "eProof Of Delivery", value: "eProofOfDelivery" },
  ],
  collectionType = [
     
    { label: "Any", value: "Any" },
    { label: "Guaranteed Founds", value: "GuaranteedFounds" },
    { label: "Cash", value: "Cash" },
  ];

let ltlGeneric = [
  {
    name: "CODAmount",
    label: "COD Amount",
    type: "text",
    cls: "",
  },
  {
    name: "Currency",
    label: "Currency",
    type: "dropdown",
    options: currency || [],
    cls: "",
  },
  {
    name: "DeclaredValue",
    label: "Declared Value",
    type: "text",
    cls: "",
  },
  {
    name: "Currency",
    label: "Currency",
    type: "dropdown",
    options: currency || [],
    cls: "",
  },
  {
    name: "PickupDate",
    label: "Pickup Date",
    type: "date",
  },
  {
    name: "PickupReadyTime",
    label: "Pickup Ready Time",
    type: "text",
    cls: "",
  },
  {
    name: "PickupCloseTime",
    label: "Pickup Close Time",
    type: "text",
    cls: "",
  },
  {
    name: "FreightTerm",
    label: "Freight Term",
    type: "dropdown",
    options: freight || [],
    cls: "",
  },
  {
    name: "FreightGuaranteeType",
    label: "Freight Guarantee Type",
    type: "dropdown",
    options: freight || [],
    cls: "",
  },
  {
    name: "FreightDate/time",
    label: "FreightDate/time",
    type: "date",
  },
  {
    name: "ContactName",
    label: "Contact Name",
    type: "text",
    cls: "",
  },
  {
    name: "Phone#",
    label: "Phone #",
    type: "text",
    cls: "",
  },
  {
    name: "SignatureOption",
    label: "Signature Option",
    type: "dropdown",
    options: signOpt || [],
    cls: "",
  },
  {
    name: "CollectionType",
    label: "Collection Type",
    type: "dropdown",
    options: collectionType || [],
    cls: "",
  },
  {
    name: "InsidePickup",
    label: "Inside Pickup",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "SpecialInstructions:",
    label: "Special Instructions:",
    type: "textarea",
    cls: "textarea-cls",
  },
  {
    name: "LiftGatePickup",
    label: "Lift Gate Pickup",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "ResidentialPickup",
    label: "Residential Pickup",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "LimitedAccessPickup",
    label: "Limited Access Pickup",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "TradeShowPickup",
    label: "Trade Show Pickup",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "SecureShipmentDriver",
    label: "Secure Shipment Driver",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "Exhibition",
    label: "Exhibition",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "ConstructionSitePickup",
    label: "Construction Site Pickup",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "DonotbreakdownPallets",
    label: "Do not break down Pallets",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "DonotStack",
    label: "Do not Stack",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "Food",
    label: "Food",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "TopLoadonly",
    label: "Top Load only",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "InsideDelivery",
    label: "Inside Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "LiftGateDelivery",
    label: "Lift Gate Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "ResidentialDelivery",
    label: "Residential Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "CallBeforeDelivery",
    label: "Call Before Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "LimitedAccessDelivery",
    label: "Limited Access Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "TradeShowDelivery",
    label: "Trade Show Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "ConstructionSiteDelivery",
    label: "Construction Site Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "ExhibitionDelivery",
    label: "Exhibition Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "FlatbedDelivery",
    label: "Flatbed Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "DeliveryNotification",
    label: "Delivery Notification",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "FreezeProducts",
    label: "Freeze Products",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "EmailNotifications",
    label: "Email Notifications",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "HazardousMaterials",
    label: "Hazardous Materials",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "MarkingorTagging",
    label: "Marking or Tagging",
    type: "checkbox",
    cls: "check-container",
  },
];
 export default ltlGeneric;