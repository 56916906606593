import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Input() page: number = 0; // Current page index
  @Input() rows: number = 50; // Records per page
  @Input() totalRecords: number = 0; // Total records
  @Output() pageChange = new EventEmitter<number>(); // EventEmitter to notify page changes

  // Calculate total number of pages
  get totalPages(): number {
    return Math.ceil(this.totalRecords / this.rows);
  }

  // Navigate to the first page
  onFirst() {
    if (this.page !== 0) {
      this.page = 0;
      this.emitPageChange();
    }
  }

  // Navigate to the previous page
  onPrevious() {
    if (this.page > 0) {
      this.page--;
      this.emitPageChange();
    }
  }

  // Navigate to the next page
  onNext() {
    if (this.page < this.totalPages - 1) {
      this.page++;
      this.emitPageChange();
    }
  }

  // Navigate to the last page
  onLast() {
    if (this.page !== this.totalPages - 1) {
      this.page = this.totalPages - 1;
      this.emitPageChange();
    }
  }

  // Emit page change event with the page number
  private emitPageChange() {
    this.pageChange.emit(this.page);
  }

  // Display range of the current page
  get currentPageRange(): string {
    const start = this.page * this.rows + 1;
    const end = Math.min(this.totalRecords, (this.page + 1) * this.rows);
    return `${start}-${end} of ${this.totalRecords}`;
  }
}
