import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private baseUrl: string = environment.apiUrl;
  private AcumaticaUrl: string='https://eshipjet-stage-erp-fvckgpg7cfe3f3gk.eastus2-01.azurewebsites.net/';

  constructor(private http: HttpClient) { }

  getOrderData(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/orders/getorders`,data);
  }

  getOrderAcumaticaData(): Observable<any> {
    return this.http.get<any>(`${this.AcumaticaUrl}api/GetDeliveryDetails?ErpName=Test&Environment=Qa&Operation=GetDeliveryInfo`);
  }
  
}
