<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<app-progress-status *ngIf="isProgressDataAvl"
  [parentResponse]="progressDetails" [documentsCount]="documentCountList"
  [sharedViewFunction]="modalOpenClick.bind(this)"
  ></app-progress-status>

<div class="grid-dev-sec">  

  <div class="card gridCommonCss m-3 p-3 pt-0" *ngIf="isDataLoaded">
    <div class="filterSec col-12 pl-0 pr-0">
      <div class="icon-container">
          <div class="pl-0 pb-0">
            <img class="filter-icon" src="assets/icon/filter.png" alt="download"  style="font-size: 18px; vertical-align: middle;" (click)="op.toggle($event)" />
          </div>
          <div class="text-right pr-0 pb-0">
            <img class="download-icon" src="assets/icon/download.png" alt="download" (click)="downloadFile()" style="font-size: 18px; margin-right: 5px; vertical-align: middle;" />
            <app-col-config [columns]="columns" [mandatoryColumns]="mandatoryColumns"
            (finalColumns)="receiveData($event)" [ConfigKey]="ConfigKey"></app-col-config>
          </div>
      </div>    
      <app-config-table *ngIf="isDataLoaded" [displayedData]="[]" 
      [columns]="finalColumns" [sharedViewFunction]="viewShipNow.bind(this)" [sharedShipFunction]="ship.bind(this)"
      [shipmentState]="progressDetails.shipmentState" [ConfigKey]="ConfigKey"></app-config-table>            
  </div>

     <!-- <div class="col-12 pb-0 pr-0 text-right">
      <button pButton icon="pi pi-filter" type="button" (click)="op.toggle($event)"></button>
      <button pButton type="button" pButton pRipple icon="pi pi-file" (click)="downloadFile()" pTooltip="CSV" tooltipPosition="bottom"></button>
      <button pButton  class="pt-0"  type="button" (click)="showOverlay($event)" icon="pi pi-cog"></button>
     </div> -->
  </div>
</div>
<p-overlayPanel #op [showCloseIcon]="true" [dismissable]="true" [style]="{width: '50%'}">

  <div class="grid p-fluid">
    <div class="col-12 md:col-3">
      <div class="p-field">
        <label for="locationName">Location Name</label>
        <p-dropdown id="locationName" [options]="feederSystemOptions" [(ngModel)]="locationName"
          optionLabel="Location Name" placeholder="Select Location Name"></p-dropdown>
      </div>
    </div>
    <div class="col-12 md:col-3">
      <div class="p-field">
        <label for="Carrier">Carrier</label>
        <p-dropdown id="Carrier" [options]="feederSystemOptions" [(ngModel)]="Carrier" optionLabel="Carrier"
          placeholder="Select"></p-dropdown>
      </div>
    </div>
    <div class="col-12 md:col-3">
      <div class="p-inputgroup p-input-icon-right">
        <span class="delivery-title">Ship From Date</span>
        <p-calendar [(ngModel)]="shipFromDate" [iconDisplay]="'input'" [showIcon]="true" placeholder="Select Date"
          [dateFormat]="'dd/mm/yy'" inputId="icondisplay" />

      </div>
    </div>
    <div class="col-12 md:col-3">
      <div class="p-inputgroup p-input-icon-right">
        <span class="delivery-title">Ship To Date</span>
        <p-calendar [(ngModel)]="shipToDate" [iconDisplay]="'input'" [showIcon]="true" placeholder="Select Date"
          [dateFormat]="'dd/mm/yy'" inputId="icondisplay" />
      </div>
    </div>

  </div>
  <div class="grid p-fluid">

    <div class="col-12 md:col-3">
      <div class="p-field">
        <label for="costCenter">Cost Center</label>
        <p-dropdown id="costCenter" [options]="feederSystemOptions" [(ngModel)]="costCenter" optionLabel="Cost Center"
          placeholder="Select Cost Center"></p-dropdown>
      </div>
    </div>
    <div class="col-12 md:col-3">
      <div class="p-inputgroup p-input-icon-right">
        <label for="user">User</label>
        <div class="search-container">
          <input id="user" type="text" pInputText [(ngModel)]="user" maxlength="30" />
          <i class="pi pi-search search-icon"></i>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-3">
      <div class="p-field">
        <label for="Status">Status</label>
        <p-dropdown id="Status" [options]="feederSystemOptions" [(ngModel)]="Status" optionLabel="Status"
          placeholder="Select Status"></p-dropdown>
      </div>
    </div>
  </div>

  <div class="d-flex p-fluid justify-content-end">
    <div class="flex flex-end">
        <div class="p-d-flex footer-apply pr-2">
            <p-button  label="Apply"></p-button>
        </div>
        <div class="p-d-flex footer-reset">
            <p-button styleClass="text-primary" label="Reset"></p-button>
        </div>
    </div>
  </div>
</p-overlayPanel>
