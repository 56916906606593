<div class="layout-wrapper" (click)="onLayoutClick()" [ngClass]="{
        'layout-static': isStatic(),
        'layout-overlay': isOverlay(),
        'layout-overlay-active': overlayMenuActive,
        'layout-horizontal': isHorizontal(),
        'layout-slim': isSlim(),
        'layout-static-inactive': staticMenuDesktopInactive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-menu-dark': app.darkMenu,
        'layout-menu-light': !app.darkMenu,
        'p-input-filled': app.inputStyle === 'filled',
        'p-ripple-disabled': !app.ripple
    }">
    <div class="main-content">
        <app-topbar *ngIf="showNavbar"></app-topbar>
    </div>
</div>
