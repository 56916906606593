<div class="main-container pagedetails-header">
  <div class="titleContainer col-12 pt-0 pl-0">
   
        <div class="col-4 pl-0 pt-0">  
            <div class="pageTitle">{{ pageTitle }}</div>
        </div>
        <div *ngIf="isButtonAvl" class="col-8 text-right pb-0 pr-0 pt-3">
            <button class="buttonTitle mt-3" (click)="callParentView()">{{ buttonTitle }}</button>
          <!-- <p-splitButton label="Create Ship Request" class="buttonTitle"  [style]="{'color':'#fff'}" (click)="onTitleBtnClick()" /> -->
            <!-- <button class="buttonTitle" (click)="onTitleBtnClick()">{{ buttonTitle }}</button> -->
        </div>
    
  </div>
  <div class="pageStatus">
    <div class="totalRec-container" (click)="statusProgressBarClick()">
      <div class="count">{{ documentsCount?.totalRecords || 0 }}</div>
      <div class="thumbtitle">Total</div>

      <div class="totalProgressbar-cls" #totalrecords (click)="activeCheck('total')" style="background-color:rgb(32, 172, 246) !important;"></div>

    </div>
    <div class="open-container" (click)="statusProgressBarClick()">
      <div class="count">{{ documentsCount?.openRecords || 0 }}</div>
      <div class="thumbtitle">Open</div>
      <div class="openProgressbar-cls" #openrecords (click)="activeCheck('open')" [ngStyle]="{'background-color': openProgress.colorpicker}"></div>
    </div>
    <div class="shipped-container" (click)="statusProgressBarClick()">
      <div class="count">{{ documentsCount?.shippedRecords  || 0 }}</div>
      <div class="thumbtitle">Shipped</div>
      <div class="shippedProgressbar-cls" #shippedrecords (click)="activeCheck('shipped')" [ngStyle]="{'background-color': shippedProgress.colorpicker}"></div>
    </div>
    <div class="cancelled-container" (click)="statusProgressBarClick()">
      <div class="count">{{ documentsCount?.cancelledRecords  || 0 }}</div>
      <div class="thumbtitle">Cancelled</div>
      <div class="cancelledprogressbar-cls" #cancelledrecords (click)="activeCheck('cancelled')" [ngStyle]="{'background-color': cancelProgress.colorpicker}" ></div>
    </div>
  </div>
</div>