
<div class="login-container">
    <div class="companyTittleSec">
        <h1>JPMORGAN CHASE&CO.</h1>
    </div>
    <div class="login-fields-container">

        <div class="logo-container">
            <img src="assets/images/company-logo.png" alt="Image">
        </div>
        <div class="login-form-container">
            <h1 class="title">Sign In</h1>
            <hr>
            <div class="field-container email-container">
                <input id="email" type="text" [(ngModel)]="payload.login" pInputText placeholder=" " autofocus>
                <label for="email">Email/Phone</label>
            </div>
            <div class="field-container">
                <input id="password" [(ngModel)]="payload.password" [type]="!showPassword ? 'password': 'text'"
                    placeholder=" " pInputText (keydown.enter)="processlogin()">
                <label for="password">Password</label>
                <i class="pi eye-icon" [ngClass]="!showPassword ? 'pi-eye-slash': 'pi-eye'"
                    (click)="togglePassword()"></i>
            </div>
            <div class="checkbox-container">
                <p-checkbox id="rememberme1" [binary]="true"></p-checkbox>
                <label for="rememberme1">Remember Me</label>
            </div>
            <button pButton label="Sign In" class="signIn-btn" (click)="processlogin()">
                <img class="icon-lock" src="assets/images/signin_lock.png" alt="Image">

            </button>
            <div class="forgot-password">
                <a>Forgot Password?</a>
            </div>
            <div class="horizontal-bar"></div>
            <div class="contact-container">
                <span>New to eshipjet.ai?</span>
                <a>Contact Sales</a>
            </div>
        </div>
    </div>
    <div class="footer-container">
        <div class="version">Version : 6.4</div>
        <div class="copyright-content">
            <span class="material-symbols-outlined">
                copyright
            </span>
            <div>2024 eshipjet.ai, Inc. All rights Reserved.</div>
        </div>
        <div class="privacy">Privacy Policy</div>
    </div>
</div>






<!-- <div  class="login-body">
  <div class="login-panel"> 
       
    </div>

    <div class="login-content">
        <div class=" w-full lg:w-5">
            <div class="text-center mb-5">

                <img src="assets/images/companylogo.jpg" alt="Image" width= "90" height="150"  class="mb-3">

                <div class="text-900 text-3xl font-medium mb-3"></div>
                <span class="text-600 font-medium line-height-3">Don't have an account?</span>
                <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" (click)="openRegister()">Create today!</a>
            </div>
        
            <div>
                <label for="email1" class="block text-900 font-medium mb-2">Email/User Name</label>
                <input id="email1" type="text" [(ngModel)]="payload.login" placeholder="Email /User Name" pInputText class="w-full mb-3">
        
                <label for="password1" class="block text-900 font-medium mb-2">Password</label>
                <input id="password1" [(ngModel)]="payload.password" type="password" placeholder="Password" pInputText class="w-full mb-3">
        
                <div class="flex align-items-center justify-content-between mb-6">
                    <div class="flex align-items-center">
                        <p-checkbox id="rememberme1" [binary]="true" styleClass="mr-2"></p-checkbox>
                        <label for="rememberme1" class="text-900">Remember me</label>
                    </div>
                    <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot password?</a>
                </div>
        
                <button pButton pRipple label="Sign In" icon="pi pi-user" class="w-full" (click)="processlogin()"></button>
            </div>
        </div>
    </div> -->



<!-- <div class="login-content">
        

        <h1><span>SIGN IN</span> PRODUCTNAME</h1>
        <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
            <div>

                <img src="assets/images/companylogo.jpg" alt="Product Logo" />

            </div>
            
            <div *ngIf="!isValid" class="alert alert-danger">
                <p-message severity="error" text="Invalid UserID or Password"></p-message>
            </div>
            <div class="login-input-wrapper">
                <input type="text" placeholder="Username" formControlName="login" pInputText >
                <i class="pi pi-user"></i>
            </div>

            <div class="login-input-wrapper">
                <input type="password" placeholder="Password" formControlName="password" pInputText>
                <i class="pi pi-lock"></i>
            </div>
            <button pButton type="submit" label="Sign In" [disabled]="loginForm.invalid" class="mr-2"></button>
            <button pButton type='Button' label="Register" (click)="openRegister()"></button>
        </form>
    </div> -->
<!-- </div> -->

<p-dialog [(visible)]="openRegisterFormDialog" [style]="{ width: '70%' }" header="Register" [modal]="true"
    styleClass="p-fluid">

    <p-header>Register</p-header>
    <div class="card">
        <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="company" pInputText [(ngModel)]="newcompany['company']">
                    <label for="company">Company Name</label>

                </span>
            </div>

            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="contact" pInputText [(ngModel)]="newcompany['contact']">
                    <label for="contact">Contact Name</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="Addrln1" pInputText [(ngModel)]="newcompany['addrln1']">
                    <label for="Addrln1">Address Line1</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="addrln2" pInputText [(ngModel)]="newcompany['addrln2']">
                    <label for="addrln2">Address Line2</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="city" pInputText [(ngModel)]="newcompany['city']">
                    <label for="city">City</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="state" pInputText [(ngModel)]="newcompany['state']">
                    <label for="state">State/Province Code</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="country" pInputText [(ngModel)]="newcompany['country']">
                    <label for="country">Country</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="zipcode" pInputText [(ngModel)]="newcompany['zipcode']">
                    <label for="zipcode">ZipCode</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="e-mail" pInputText [(ngModel)]="newcompany['e-mail']">
                    <label for="e-mail">E-mail</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="phoneno" pInputText [(ngModel)]="newcompany['phoneno']">
                    <label for="phoneno">Phone Number</label>

                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" id="fax" pInputText [(ngModel)]="newcompany['fax']">
                    <label for="fax">Fax Number</label>

                </span>
            </div>
        </div>
        <button pButton label="Register" (click)="saveCompany()"></button>
    </div>

</p-dialog>
<app-warning-popup></app-warning-popup>