<form [formGroup]="form">
  <div class="main-dev-sec">
        <div class="add-ship-request-title">
          <span class="tittle-icon-css vertical-align-middle">
              <img src="../../assets/images/timeline-icon.png" (click)="openTimelineSidebar($event)" class="timeline-img" />
          </span>
          <span class="main-tittle-css vertical-align-middle">
              Ship Now
          </span>
          <!-- <i class="pi pi-clock timeline-icon" (click)="showOverlay($event)" style="cursor: pointer; padding-left: 5px;"></i> -->
          <span>
              <img src="../../assets/images/close-icon.png" class="close-icon-img vertical-align-middle"
              (click)="navigateToDashboard()" />
          </span>
      </div>
      <div class="add-ship-request-header">
          <div class="grid m-0">
              <div class="col-5 p-0">
                <div class="grid m-0">
                    <div class="col-4 p-0">
                      <div class="menu-dropdown-sec">                          
                      <span><img src="../../assets/images/attchment-icon.png" class="attachmentimagecss" /></span>  
                      <p-dropdown formControlName="selectedConnection" class="custom-dropdown mr-2 attachment-dropdown" [options]="connections"
                      placeholder="Select" optionLabel="Name" optionValue="Name">
                    </p-dropdown>
                      </div>
                    </div>
                    <div class="col-6 pb-0 pt-0 pl-0">
                      <div class="grid m-0">
                        <div class="col-6 p-0 px-2">
                          <p-dropdown class="deliveryOrderCss"
                          id="selectedFilter"
                          formControlName="selectedFilter" 
                          [options]="filters"
                          optionLabel="Name"
                          optionValue="Name"
                          placeholder="Select an option"
                          ></p-dropdown>
                         <!-- <div class="custom-dropdown-container">
                           <label (click)="toggleList()" class="lbl-css custom-dropdown-label">
                             {{ form.controls['selectedFilter'].value?.Name || 'Select an option' }}
                           </label>
                   
                           <ul *ngIf="listVisible" class="custom-dropdown-list">
                             <li *ngFor="let filter of filters" (click)="selectFilter(filter)">
                               {{ filter.Name }}
                             </li>
                           </ul>
                         </div> -->
                        </div>
                        <div class="col-6 p-0">
                         <div class="search-container">
                           <!-- Search Input Field -->
                           <input #cursorFocus type="text" maxlength="40" formControlName="searchTerm" [(ngModel)]="searchTerm" placeholder="Search..."
                             class="p-inputtext p-component" (keyup.enter)="searchFilter()" />
                   
                           <!-- Search Icon to Trigger Popup -->
                           <i class="pi pi-search search-icon" (click)="openHistoryDialog()">
                           </i>
                   
                           <!-- test -->
                   
                           <!-- HISTORY FILTER PANEL-->
                   
                   
                           <p-overlayPanel #historyop [showCloseIcon]="true" [dismissable]="true" [style]="{width: '450px'}">
                   
                             <div class="grid p-fluid">
                   

                               <div class="field col-12 md:col-6">
                                 <span class="p-float-label">
                                   <p-calendar inputId="from"></p-calendar>
                                   <label for="from">From Date</label>
                   
                                 </span>
                               </div>
                               <div class="field col-12 md:col-6">
                                 <span class="p-float-label">
                                   <p-calendar inputId="to"></p-calendar>
                                   <label for="to">To Date</label>
                                 </span>
                               </div>
                             </div>
                             <div class="grid p-fluid mt-1">
                               <ng-template ngFor let-xf [ngForOf]="history_filter_xfields" let-i="index">
                                 <ng-template ngFor let-field [ngForOf]="xf.fields" let-j="index">
                                   <ng-template [ngIf]="field['filterField']==true && field['fieldName']!='ShipDate'">
                   
                                     <div class="field col-12 md:col-6">
                                       <span class="p-float-label">
                                         <input type="text" id="inputtext" pInputText>
                                         <label for="inputtext">{{ field.title }}</label>
                                       </span>
                   
                   
                                     </div>
                                   </ng-template>
                                 </ng-template>
                               </ng-template>
                             </div>
                   
                             <!-- <div class="flex align-items-bottom-right"> -->
                             <div class="grid p-fluid mt-1">
                               <p-button label="Get Shipments" (click)="applyHistoryFilters('shipments',$event , historyop)"
                                 class="mr-1"></p-button>
                               <p-button label="Get Open Orders" (click)="applyHistoryFilters('openorders', $event , historyop)"
                                 class="mr-1"></p-button>
                   
                             </div>
                   
                   
                           </p-overlayPanel>
                   
                   
                           <!-- HISTORY FILTER PANEL END-->
                   
                           <!-- HISTORY OPEN & MANIFEST LIST DIALOG  openorderloading-->
                           <p-dialog [(visible)]="history_Dialog" [modal]="true"
                           [style]="{'width': '77%', 'height': '30vh'}" showHeader="false"  styleClass="p-fluid shipHistoryPopup">
                         
                            <div class="dialog-header">
                                <div class="grid m-0">
                                    <div class="col-10 py-0">
                                        <h4 class="mb-0">Shipment History</h4>
                                    </div>
                                    <div class="col-2 py-0 text-right">
                                        <img src="../../../../assets/images/close-icon.png" class="dialog-close" (click)="history_Dialog = false" />
                                    </div>
                                </div>
                            </div>
<!--                                                 
                         
                           <div class="sidebar-header-history">
                            <div class="flex" style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                              <h1 class="popTittle mb-0">Shipment History</h1>
                              <button pButton pRipple type="button" icon="pi pi-times" class="p-button-text"
                                  [ngClass]="{'active-btn': activeButton === 'shiprequest'}" [loading]="openorderloading"
                                  [disabled]="shiphistoryloading || openorderloading" (click)="history_Dialog = false">
                              </button>
                            </div>
                          </div> -->
                           

                          <div style="overflow-y: auto !important; height: 100%; overflow-x: hidden;">

                                                                             
                           <!-- MANIFEST TABLE -->
                           <!-- <p-table #manifestdt [value]="displayedArray"
                               [tableStyle]="{ 'min-width': '50rem', 'height': '25vh', 'overflow': 'scrollable' }"
                               [(selection)]="selectedHistoryOrder" selectionMode="single" [paginator]="true"
                               [scrollable]="true" responsiveLayout="stack"
                               styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
                               [rows]="rows" [first]="page * rows"
                               [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [rowHover]="true"
                               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" dataKey="id"> -->
                               <div [formGroup]="filterForm" >
                                <!-- <p-button icon="pi pi-filter" (click)="historyop.toggle($event)"></p-button> -->
                              
                                <div class="left d-flex py-1 px-3">
                                <!-- Location Name Dropdown with Label -->
                                <div class="flex flex-column col-2 px-2">
                                  <label for="location">Location Name</label>
                                  <!-- <input
                                  id="feederSystem"
                                  type="text"
                                  pInputText
                                  formControlName="locationid"
                                  placeholder="Enter location name"
                                  [style]="{'width': '150px !important'}"
                                /> -->
                                <p-dropdown
                                id="locationid"
                                formControlName="locationid"
                                [options]="locationIdData"
                                optionLabel="LocationName"
                                optionValue="LocationId"
                                placeholder="Select Location"

                              ></p-dropdown>
                                </div>
                              
                                <!-- Carrier Dropdown with Label -->
                                <div class="flex flex-column col-2 px-3">
                                  <label for="carrier">Carrier</label>
                                  <p-dropdown
                                    id="carrier"
                                    formControlName="carrier"
                                    [options]="filteredCarrierOptions"
                                    optionLabel="CarrierName"
                                    optionValue="CarrierName"
                                    placeholder="Select"
                                  ></p-dropdown>
                                </div>
                              
                                <!-- Ship From Date Input with Label -->
                                <div class="flex flex-column col-2 px-3">
                                  <label for="shipFromDate">Ship From Date</label>
                                  <p-calendar
                                    id="shipFromDate"
                                    formControlName="fromdate"
                                    placeholder="Select From Date"
                                    dateFormat="mm/dd/yy"
                                    (onSelect)="onFromDateSelect($event)"
                                  ></p-calendar>
                                </div>
                              
                                <!-- Ship To Date Input with Label -->
                                <div class="flex flex-column col-2 px-3">
                                  <label for="shipToDate">Ship To Date</label>
                                  <p-calendar
                                    id="shipToDate"
                                    formControlName="todate"
                                    placeholder="Select To Date"
                                    dateFormat="mm/dd/yy"
                                    [minDate]="minShipToDate"
                                  ></p-calendar>
                                </div>
                                 <!-- Feeder System Input with Label -->
                                 <div class="flex flex-column col-2 px-3" >
                                  <label for="feederSystem">Feeder System</label>
                                  <p-dropdown
                                  id="location"
                                  formControlName="feedersystem"
                                  [options]="filteredERPOptions"
                                  optionLabel="Name"
                                  optionValue="Name"
                                  placeholder="Select"
                                ></p-dropdown>
                                </div>
                              
                                 <!-- Delivery Number Input with Label -->
                                 <div class="flex flex-column col-2 pl-3 pr-2" >
                                  <label class="d-block" for="deliveryNumber">Delivery Number</label>

                                  <input
                                    id="deliveryno"
                                    type="text"
                                    pInputText
                                    formControlName="deliveryno"
                                    placeholder="Enter Delivery Number"
                                  />
                                </div>
                                </div>
                                <div class="right d-flex px-3">

                      
                                <!-- Ship to Company Input with Label -->
                                <div class="flex flex-column col-2 px-2" >
                                  <label for="shipToCompany">Ship to Company</label>
                                  <input
                                    id="shipToCompany"
                                    type="text"
                                    pInputText
                                    formControlName="shiptocompany"
                                    placeholder="Enter Ship to Company"
                                  />
                                </div>
                              
                                <!-- Status Dropdown with Label -->
                                <div class="flex flex-column col-2 px-3">
                                  <label for="status">Status</label>
                                  <p-dropdown
                                    id="status"
                                    formControlName="status"
                                    [options]="statusOptions"
                                    optionLabel="description"
                                    optionValue="description"
                                    placeholder="Select Status"
                                  ></p-dropdown>
                                </div>
                                <div class="button">
                                  <span class="d-flex align-items-center px-3 py-3"> 
                                   <button  (click)="applyFilter()" class="applyButton  mt-3" [style]="{'width': '120px','padding':'10px','margin-top':'8px','justify-content':'center'}">Apply</button>
                                   <button  (click)="removeFilter()" class="clearButton  mt-3" [style]="{'width': '120px','padding':'10px','margin-top':'8px','justify-content':'center'}">Clear</button>
                                  </span>
                                  </div>
                              </div>
                                <!-- Search Input and Settings Button -->
                                <!-- <div class="flex align-items-center ml-auto mr-2">
                                  <i class="pi pi-search" style="margin-right: 8px; font-size: 1.2em;"></i>
                                  <input
                                    type="text"
                                    pInputText
                                    size="50"
                                    placeholder="Global Filter"
                                    (input)="applyManifestFilterGlobal($event, 'contains')"
                                    style="width:auto; margin-right: 8px;"
                                  />
                                </div>               -->
                                <!-- Apply and Remove Filter Buttons -->
                                 
                                </div>

                                <app-dialog-table [displayedData]="historyOrders"
                                 [sharedViewFunction]="selectOrderorShipment.bind(this)" [snoFlag]="false"
                                 [columns]="columns"></app-dialog-table>
                              
                           </div>  
                       </p-dialog>                                       
                       <!-- HISTORY OPEN & MANIFEST LIST DIALOG END HERE-->
                       <!-- testend -->
                   
                       <!-- Dialog Popup -->
                       <p-dialog header="Search" [(visible)]="searchDialogVisible" [modal]="true" [style]="{width: '50vw'}"
                         [draggable]="false" [resizable]="false" (onHide)="onDialogHide()">
                         <!-- Your search form or content goes here -->
                         <input type="text" formControlName="searchTermPopup" placeholder="Search in Popup..."
                           class="p-inputtext p-component" />
                         <button pButton label="Search" (click)="performSearch()"></button>
                       </p-dialog>
                     </div>
                        </div>
                   </div>
                    </div>
                    <div class="col-2 pt-0 pb-0">
                      <button type="button" (click)="searchFilter()" class="btn-default-css shipheadergetbutton">Get</button>
                    </div>
                </div>            
              </div>
              <div class="col-6 p-0">
                  <div class="grid m-0">
                    <div class="col-2 pt-0 pb-0 pr-0">
                      <button type="button" class="btn-default-css shipheaderconsolidationbutton">Consolidation</button>
                    </div>
                      <div class="col-5 p-0">
                          <div class="grid m-0">
                              <div class="col-4 p-0 text-right pr-3">
                                <span class="delivery-title lbl-css">Pickup Date</span>
                              </div>
                              <div class="col-8 p-0">
                                <p-calendar formControlName="pickupDate" [iconDisplay]="'input'" [showIcon]="true" [dateFormat]="'mm/dd/yy'"
                                inputId="icondisplay" />
                              </div>
                          </div>
                      </div>
                      <div class="col-5 p-0">
                        <div class="grid m-0">
                          <div class="col-4 p-0 text-right pr-3">
                            <span class="delivery-title lbl-css">Ship Date</span>
                          </div>
                          <div class="col-8 p-0">
                            <p-calendar formControlName="shipDate" [iconDisplay]="'input'" [showIcon]="true" [minDate]="today"
                            [dateFormat]="'mm/dd/yy'" inputId="icondisplay" />
                          </div>
                      </div>
                      </div>
                  </div>
              </div>              
              <div class="col-1 p-0 text-right">
                <button type="button" (click)="refreshForm()" class="btn-default-css btn-new">New</button>
              </div>
           </div>
      </div>
  </div>
</form>




<p-overlayPanel  #ot header="Configure Fields" [showCloseIcon]="true" [dismissable]="true">

  <ng-template pTemplate="headless">
      <div class="p-2" style="width: 70vh;height: 60vh;">
          <div class="sidebar-header">
            <h3>Recent Shipments</h3>
          </div>

          <div class="shipment-type-dropdown p-1">
              <p-dropdown [(ngModel)]="selectedShipmentType" (onChange)="onShipmentTypeChange($event)" [options]="shipmentTypes"
                  placeholder="Select a shipment type">
              </p-dropdown> 
          </div>
          <!-- Show shipments if available -->
          <div class="shipment-card-container"
              *ngIf="(filteredShipments!=undefined && filteredShipments.length > 0); else noData">
              <div class="shipment-card" *ngFor="let shipment of filteredShipments">
                  <span style="cursor: pointer;" (click)="selectHistory(shipment)">
                      {{shipment.details.HeaderInfo.DocumentNumber}}
                  </span>
                  <span>{{shipment.details.HeaderInfo.CreatedDate | date:'MM/dd/yyyy'}}</span>
                  <span>{{shipment.details.ShipTo.COMPANY}}</span>
                  <p>{{shipment.details.CarrierDetails.Carrier}}</p>
              </div>
          </div>

          <!-- Template to display when there is no data -->
          <ng-template #noData>
              <div class="no-data-message">
                  <p>No data available</p>
              </div>
          </ng-template>
      </div>
  </ng-template>
  <!-- </p-dialog> -->
</p-overlayPanel>
<app-warning-popup></app-warning-popup>
