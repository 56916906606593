<!-- Message Service code -->
<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<p-toast position="bottom-center" key="c" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{ message.summary }}</h4>
                <p>{{ message.detail }}</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" pButton label="Yes" class="p-button-success"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton label="No" class="p-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>

<!-- Message Service end -->

<div class="card" *ngIf="isDataLoaded">

  <p-toolbar styleClass="p-mb-4 master_css">
    <ng-template pTemplate="right">
      <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-primary p-mr-2"
      (click)="openNew()"></button>
    </ng-template>

    <ng-template pTemplate="left">
      <i class="pi + {{ ' ' + xformMetaData[0].details.icon  }} mr-2 " style="font-size: 2rem"></i>
      <!--<label class="mr-2 titlefont">{{ xformMetaData[0].details.formTitle }}</label>-->
      <label class="mr-2 " style="font-size: 2rem">{{ xformMetaData[0].details.formTitle }}</label>

    </ng-template>
  </p-toolbar>

  <p-table #dt [columns]="xfields" [value]="xFormRecords" sortMode="single" [paginator]="true" [rows]="5" [showCurrentPageReport]="true" [rowsPerPageOptions]="[5,10,25,50]"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" dataKey="id"  [rowHover]="true"
  responsiveLayout="stack"   [resizableColumns]="true"  styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
    [(selection)]="selectedRecords" [globalFilterFields]="xcolumns">
    <ng-template pTemplate="caption">
      <div class="flex">
        <!-- <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-primary p-mr-2"
          (click)="openNew()"></button> (click)="op.toggle($event)"-->
          <p-button icon="pi pi-filter"  (click)="op.toggle($event)"></p-button>
        <div class="ml-auto mr-2">
          <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
          <input type="text" pInputText size="50" placeholder="Global Filter"
            (input)="applyFilterGlobal($event, 'contains')" style="width:auto">
        </div>

        <p-splitButton class="mr-3" label="Download" icon="pi pi-file" (onClick)="downloadFile()"
          [model]="items"></p-splitButton>
        <!--<button type="button" pButton pRipple icon="pi pi-file" (click)="downloadFile()" class="mr-2" pTooltip="CSV"
            tooltipPosition="bottom"></button>-->


        <i class="pi pi-cog  right-wrapper" style="font-size: 20px"
          (click)="openTableProperties(xfields , 'xformtitle')" *ngIf="getUserDetails() ==='SystemAdmin'"></i>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <ng-container *ngFor="let xf of xfields; let i = index">
          <ng-template [ngIf]="xf.visible === true">
            <th pResizableColumn pSortableColumn="{{xf.fieldName }}">
              {{ xf.fieldHeader }}
              <p-sortIcon field="{{ xf.fieldName }}"></p-sortIcon>
            </th>
          </ng-template>
        </ng-container>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="xfields">
      <tr>
        <td>
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <ng-template ngFor let-xf [ngForOf]="xfields" let-i="index">
          <ng-template [ngIf]="xf.visible === true">
            <td> {{ rowData[xf.fieldName] }}</td>
          </ng-template>
        </ng-template>
        <td>
          <button pButton pRipple icon="pi pi-pencil"  class="p-button-sm"
          (click)="editRecord(rowData)"></button>
        
          <!-- <p-button pButton pRipple icon="pi pi-pencil"  size="small" [outlined]="false"
            (click)="editRecord(rowData)"></p-button> -->
        </td>
      </tr>
    </ng-template>
  </p-table>


  <!-- NEW DIALOG RESPONSIVE START HERE -->



  <!-- TABLE END -->
  <p-dialog [(visible)]="formDialogNew" [style]="{ width: '70%' }" [header]="xformMetaData[0].details.formTitle"
    [modal]="true" styleClass="p-fluid">
    <div>
      <i class="pi pi-cog  right-wrapper" style="font-size: 20px" (click)="openBlockProperties()"
        *ngIf="getUserDetails() ==='SystemAdmin'"></i>

    </div>
    <!-- NEW START -->
    <form [formGroup]="masterForm" (ngSubmit)="onFormSubmit()">
      <div class="grid p-fluid mt-3">

        <ng-template ngFor let-field [ngForOf]="xfields" let-i="index">





          <!--TEXTBOX [required]="field.required"-->
          <ng-template [ngIf]="field.fieldType == 'ColorPicker'">
            <div class="field col-12  {{ 'md:' + field.width }}  " [hidden]="field.fieldName === 'id'">
              <span class="p-float-label p-input-icon-{{ field.iconPosition }}">
                <label>{{ field.fieldHeader }}</label>
                <p-colorPicker [formControlName]="field.fieldName"  [inline]="true"></p-colorPicker>
                <!-- <input [type]="field.datatype" pInputText [formControlName]="field.fieldName" /> -->
            
                <ng-template [ngIf]="field.hasIcon == true">
                  <i class="pi {{' ' + field.icon}}"></i>
                </ng-template>
              </span>

              <div
                *ngIf="masterForm.get(field.fieldName).invalid && (masterForm.get(field.fieldName).dirty || masterForm.get(field.fieldName).touched)"
                class="alert alert-danger">
                <p-message *ngIf="masterForm.get(field.fieldName).errors.required" severity="error"
                  [text]="field.fieldName + ' is Required'"></p-message>
                <p-message *ngIf="masterForm.get(field.fieldName).errors.minlength" severity="error"
                  [text]="field.fieldName + '  must be at least ' + field.minLength + ' characters long' "></p-message>
                <p-message *ngIf="masterForm.get(field.fieldName).errors.maxlength" severity="error"
                  [text]="field.fieldName + '  must be at most ' + field.maxLength + ' characters long' "></p-message>
              </div>



            </div>
          </ng-template>


          <!--TEXTBOX  [required]="field.required"  -->
          <ng-template [ngIf]="field.fieldType == 'Textbox'">
            <div class="field col-12  {{ 'md:' + field.width }}  " [hidden]="field.fieldName === 'id'">
              <span class="p-float-label p-input-icon-{{ field.iconPosition }} ">
                <input [type]="field.datatype" pInputText [formControlName]="field.fieldName" />
                <label>{{ field.fieldHeader }}</label>
                <ng-template [ngIf]="field.hasIcon == true">
                  <i class="pi {{' ' + field.icon}}"></i>
                </ng-template>
              </span>

              <ng-template [ngIf]="field">
              <div
                *ngIf="masterForm.get(field['fieldName']).invalid && (masterForm.get(field['fieldName']).dirty || masterForm.get(field['fieldName']).touched)"
                class="alert alert-danger">
                <p-message *ngIf="masterForm.get(field.fieldName).errors.required" severity="error"
                  [text]="field.fieldName + ' is Required'"></p-message>
                <p-message *ngIf="masterForm.get(field.fieldName).errors.minlength" severity="error"
                  [text]="field.fieldName + '  must be at least ' + field.minLength + ' characters long' "></p-message>
                <p-message *ngIf="masterForm.get(field.fieldName).errors.maxlength" severity="error"
                  [text]="field.fieldName + '  must be at most ' + field.maxLength + ' characters long' "></p-message>

                <p-message *ngIf="masterForm.get(field.fieldName).errors.Email" severity="error"
                  [text]="field.fieldName + '  must be at most ' + field.maxLength + ' characters long' "></p-message>
              </div>
              </ng-template>
            </div>
          </ng-template>


          <!--TEXTBOX [required]="field.required"-->
          <ng-template [ngIf]="field.fieldType == 'TextBox'">
            <div class="field col-12  {{ 'md:' + field.width }}  " [hidden]="field.fieldName === 'id'">
              <span class="p-float-label p-input-icon-{{ field.iconPosition }}">
                <input [type]="field.datatype" pInputText [formControlName]="field.fieldName" />
                <label>{{ field.fieldHeader }}</label>
                <ng-template [ngIf]="field.hasIcon == true">
                  <i class="pi {{' ' + field.icon}}"></i>
                </ng-template>
              </span>

              <div
                *ngIf="masterForm.get(field.fieldName).invalid && (masterForm.get(field.fieldName).dirty || masterForm.get(field.fieldName).touched)"
                class="alert alert-danger">
                <p-message *ngIf="masterForm.get(field.fieldName).errors.required" severity="error"
                  [text]="field.fieldName + ' is Required'"></p-message>
                <p-message *ngIf="masterForm.get(field.fieldName).errors.minlength" severity="error"
                  [text]="field.fieldName + '  must be at least ' + field.minLength + ' characters long' "></p-message>
                <p-message *ngIf="masterForm.get(field.fieldName).errors.maxlength" severity="error"
                  [text]="field.fieldName + '  must be at most ' + field.maxLength + ' characters long' "></p-message>
              </div>



            </div>
          </ng-template>
          <!---- DROPDOWN -->

          <ng-template [ngIf]="field.fieldType == 'Dropdown'">
            <div class="field col-12 {{'md:' + field.width }}">
              <span class="p-float-label">

                <p-dropdown appendTo="body" [options]="formMasterData[field.source]" [formControlName]="field.fieldName"
                  [autoDisplayFirst]="false" [optionLabel]="field.optionName" [optionValue]="field.optionValue"
                  [filter]="true" [style]="{ width: '100%' }"
                  (onChange)="processChange(field.fieldName,field.RelatedFields,field.ConditionFieldName , field.BindingFields)">
                </p-dropdown>
                <label>{{ field.fieldHeader + ' : ' + (masterForm.value[field.fieldName] || '')}} </label>
              </span>


              <div
                *ngIf="masterForm.get(field.fieldName).invalid && (masterForm.get(field.fieldName).dirty || masterForm.get(field.fieldName).touched)"
                class="alert alert-danger">
                <p-message *ngIf="masterForm.get(field.fieldName).errors.required" severity="error"
                  [text]="field.fieldName + ' is Required'"></p-message>
                <p-message *ngIf="masterForm.get(field.fieldName).errors.minlength" severity="error"
                  [text]="field.fieldName + '  must be at least ' + field.minLength + ' characters long' "></p-message>
                <p-message *ngIf="masterForm.get(field.fieldName).errors.maxlength" severity="error"
                  [text]="field.fieldName + '  must be at most ' + field.maxLength + ' characters long' "></p-message>
              </div>


            </div>
          </ng-template>


          <!---- CHECKBOX -->

          <ng-template [ngIf]="field.fieldType == 'Checkbox'">
            <div class="field col-12 {{'md:' + field.width }}">
              <span><label [for]="field.fieldName">{{ field.fieldHeader }} </label> </span>
              <span class="p-float-label">
                <p-checkbox [formControlName]="field.fieldName" [binary]="true" inputId="binary" [id]="field.fieldName">
                </p-checkbox>

              </span>

            </div>
          </ng-template>









        </ng-template>
      </div>
      <button [disabled]="masterForm.invalid" type="submit" pButton pRipple [label]="formOperationType"
        icon="pi pi-check" class="p-button-sm"></button>
      <!-- <button [disabled]="masterForm.invalid" type="submit" pButton pRipple [label]="formOperationType"
          icon="pi pi-check" class="p-button-sm"></button> -->
    </form>
  </p-dialog>





  <!-- NEW DIALOG RESPONSIVE END HERE -->






  <!-- TABLE PROPERTIES  DIALOG START-->
  <p-dialog [(visible)]="tableProperties_dialog" [style]="{ width: '70%' }" [modal]="true" styleClass="p-fluid">
    <p-header>{{ " Properties"}}</p-header>
    <div class="card" [style]="{
              padding: '0px',
              height: '70vh',
              width: '99%',
              float: 'left',
              overflow: 'scroll'
            }">

      <p-table [value]="xfields">
        <ng-template pTemplate="header">
          <tr>
            <th>Visible</th>

            <th>Coulm Header</th>
            <th>Field Name</th>
            <th>Add To Filters</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-fielddata>
          <tr>
            <td>
              <p-checkbox [(ngModel)]="fielddata.visible" [binary]="true" [disabled]="false"></p-checkbox>{{
              fielddata.visible }}
            </td>

            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [style]="{ border: '0px' }" [readonly]="false"
                    [(ngModel)]="fielddata.fieldHeader" />
                </ng-template>
                <ng-template pTemplate="output">
                  {{ fielddata.fieldHeader }}
                </ng-template>
              </p-cellEditor>
            </td>


            <td>{{fielddata.fieldName}}</td>


            <td>
              <p-checkbox [(ngModel)]="fielddata['FilterVisible']" [binary]="true" [disabled]="false"></p-checkbox>{{
                fielddata['FilterVisible'] }}
            </td>

          </tr>
        </ng-template>
      </p-table>



    </div>
    <button type="button" pButton pRipple label="Save Changes" icon="pi pi-check" class="p-button-sm"
      (click)="SaveTemplate()"></button>
  </p-dialog>
  <!-- TABLE PROPERTIES DIALOG  END-->



  <!-- FIELD PROPERTY DIALOG START-->


  <p-sidebar [(visible)]="property_dialog" position="right" [baseZIndex]="10000">


    <h4>Properties</h4>


    <div class="grid p-fluid mt-3">
      <div class="field  md:col-6">
        <button pButton icon="pi pi-bars" class="p-button-sm" (click)="showReorderTable=true"
          label="ReArrange"></button>
      </div>
      <div class="field  md:col-6">
        <button pButton icon="pi pi-plus" class="p-button-sm" (click)="showNewField=true" label="New"></button>
      </div>
      <div class="field  md:col-12">
        <span class="p-float-label">
          <p-dropdown id="fields" [autoDisplayFirst]="false" [options]="xfields" optionLabel="fieldName"
            optionValue="fieldName" [(ngModel)]="selectedField" (onChange)="loadFieldProperties()"
            [style]="{ width: '100%' }">
          </p-dropdown>
          <label for="fields">Fields :</label>
        </span>
      </div>






      <div class="field col-12 md:col-12">
        <p-accordion>

          <p-accordionTab header="Field Properties" [selected]="propertyFieldSelected">
            <div class="grid p-fluid mt-3">

              <!-- FIELD HEADER-->
              <div class="field col-12 md:col-12">
                <span class="p-float-label">
                  <input type="text" id="title" pInputText [(ngModel)]="this.currentSelctedField.fieldHeader"
                    [disabled]="false" />
                  <label for="title">Title</label>
                </span>
              </div>
              <!-- FIELD UITYPE-->
              <div class="field col-12 md:col-12">
                <span class="p-float-label">
                  <p-dropdown id="UITYPE" [autoDisplayFirst]="false" [options]="fieldUITypes"
                    [(ngModel)]="this.currentSelctedField.fieldType" optionLabel="name" optionValue="name"
                    [style]="{ width: '100%' }">
                  </p-dropdown>
                  <label for="UITYPE">UI Type</label>
                </span>
              </div>
              <!-- FIELD DATATYPE-->
              <div class="field col-12 md:col-12">
                <span class="p-float-label">
                  <p-dropdown id="datatype" [options]="fieldDataTypes" [(ngModel)]="this.currentSelctedField.datatype"
                    optionLabel="name" optionValue="name" [style]="{ width: '100%' }">
                  </p-dropdown>
                  <label for="datatype">Data Type</label>
                </span>
              </div>
              <!-- FIELD WIDTH-->
              <div class="field col-12 md:col-12">
                <span class="p-float-label">
                  <input type="text" id="width" pInputText [(ngModel)]="this.currentSelctedField.width"
                    [disabled]="false" />
                  <label for="width">Field Width (Ex: col=3, co-4)</label>
                </span>
              </div>
              <!-- FIELD SOURCE-->
              <div class="field col-12 md:col-12">
                <span class="p-float-label   p-input-icon-right">

                  <p-dropdown id="source" [options]="getDataSourceList()" [(ngModel)]="this.currentSelctedField.source"
                    [autoDisplayFirst]="false" optionLabel="name" optionValue="name" [style]="{ width: '100%' }">
                  </p-dropdown>
                  <label for="source">Data Source</label>

                </span>
              </div>
              <!-- FIELD OPTIONNAME-->
              <div class="field col-12 md:col-6">
                <span class="p-float-label">


                  <p-dropdown id="optionName" [options]="getOptioneNames()"
                    [(ngModel)]="this.currentSelctedField.optionName" [autoDisplayFirst]="false" optionLabel="name"
                    optionValue="name" [style]="{ width: '100%' }">
                  </p-dropdown>
                  <label for="optionName">Option Name</label>


                </span>
              </div>


              <!-- FIELD OPTIONVALUE-->
              <div class="field col-12 md:col-6">
                <span class="p-float-label ">


                  <p-dropdown appendTo="body" id="optionValues" [options]="getOptioneValues()"
                    [(ngModel)]="this.currentSelctedField.optionValue" [autoDisplayFirst]="false" optionLabel="name"
                    optionValue="name" [style]="{ width: '100%' }">
                  </p-dropdown>
                  <label for="optionValues">Option Values</label>


                </span>
              </div>

              <!-- FIELD DEFAULT VALUE-->
              <div class="field col-12 md:col-12">
                <span class="p-float-label">
                  <input type="text" id="DefaultValue" pInputText [(ngModel)]="this.currentSelctedField.defaultValue"
                    [disabled]="false" />
                  <label for="DefaultValue">Default Value</label>
                </span>
              </div>
              <!-- FIELD ERROR MESSAGE-->
              <div class="field col-12 md:col-12">
                <span class="p-float-label">
                  <input type="text" id="errormessage" pInputText [(ngModel)]="this.currentSelctedField.errorMessage"
                    [disabled]="false" />
                  <label for="errormessage">Error Message</label>
                </span>
              </div>


              <!-- Is Editable-->
              <div class="field col-12 md:col-6">
                <span> <label for="editable">Is Editable</label></span>
                <span class="p-float-label">
                  <p-checkbox id="editable" [(ngModel)]="this.currentSelctedField.editable" [binary]="true"
                    [disabled]="false"></p-checkbox>
                </span>
              </div>
              <!-- IS REQUIRED -->
              <div class="field col-12 md:col-6">
                <span> <label for="editable">Is Required</label></span>
                <span class="p-float-label">
                  <p-checkbox id="required" [(ngModel)]="this.currentSelctedField.isrequired" [binary]="true"
                    [disabled]="false"></p-checkbox>
                </span>
              </div>
              <!-- IS VISIBLE-->
              <div class="field col-12 md:col-6">
                <span><label for="visible">Visible</label></span>
                <span class="p-float-label">
                  <p-checkbox id="visible" [(ngModel)]="this.currentSelctedField.visible" [binary]="true"
                    [disabled]="false">
                  </p-checkbox>
                </span>
              </div>
              <!-- IS STANDARD FIELD-->
              <div class="field col-12 md:col-6">
                <span><label for="visible">Standard Field</label></span>
                <span class="p-float-label">
                  <p-checkbox id="visible" [(ngModel)]="this.currentSelctedField.standard" [binary]="true"
                    [disabled]="true">
                  </p-checkbox>
                </span>
              </div>
              <!-- IS UDF -->
              <div class="field col-12 md:col-6">
                <span><label for="visible">UDF</label></span>
                <span class="p-float-label">
                  <p-checkbox id="visible" [(ngModel)]="this.currentSelctedField.UDF" [binary]="true" [disabled]="true">
                  </p-checkbox>
                </span>
              </div>
              <div class="field md:col-6" [(hidden)]="!this.currentSelctedField.UDF">
                <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-sm"
                  (click)="deleteField()"></button>
              </div>



            </div>
          </p-accordionTab>


          <!-- ICON PROPERTY-->
          <p-accordionTab header="Icon">
            <div class="grid p-fluid mt-3">

              <!-- HAS ICON-->
              <div class="field col-12 md:col-12">
                <span><label for="visible">Has Icon</label></span>
                <span class="p-float-label">
                  <p-checkbox id="visible" [(ngModel)]="this.currentSelctedField.hasIcon" [binary]="true"
                    [disabled]="false">
                  </p-checkbox>
                </span>
              </div>

              <!-- ICON POSITION-->
              <div class="field col-12 md:col-12">
                <span class="p-float-label">

                  <p-dropdown id="iconposition" [options]="iconPositions" [autoDisplayFirst]="false" [showClear]="true"
                    [(ngModel)]="this.currentSelctedField.iconPosition" optionLabel="name" optionValue="name"
                    [style]="{ width: '100%' }">
                  </p-dropdown>
                  <label for="iconposition">Icon Position</label>
                </span>
              </div>

              <!-- ICON NAME-->
              <div class="field col-12 md:col-12">
                <span class="p-float-label">
                  <input type="text" id="iconname" pInputText [(ngModel)]="this.currentSelctedField.icon"
                    [disabled]="false" />

                  <label for="iconname">Icon </label>
                </span>
              </div>


              <!-- ICON POPUP OBJECT-->
              <div class="field col-12 md:col-12">
                <span class="p-float-label">
                  <input type="text" id="icompopupobj" pInputText [(ngModel)]="this.currentSelctedField.Popupdata"
                    [disabled]="false" />

                  <label for="icompopupobj">Popup Data </label>
                </span>
              </div>




            </div>
          </p-accordionTab>
          <!-- VALIDATION PROPERTY -->
          <p-accordionTab header="Validations">

            <div class="grid p-fluid mt-3">
              <div class="field col-12 md:col-12">
                <span class="p-float-label">
                  <input type="number" id="MINLENGTH" pInputText [(ngModel)]="this.currentSelctedField.minLength"
                    [disabled]="false" />
                  <label for="MINLENGTH">Minimum Length</label>
                </span>
              </div>



              <div class="field col-12 md:col-12">
                <span class="p-float-label">
                  <input type="number" id="MAXLENGTH" pInputText [(ngModel)]="this.currentSelctedField.maxLength"
                    [disabled]="false" />
                  <label for="MAXLENGTH">Maximum Length</label>
                </span>
              </div>



              <div class="field col-12 md:col-12">
                <span> <label for="editable">Email Validation</label></span>
                <span class="p-float-label">
                  <p-checkbox id="emailvalidator" [(ngModel)]="this.currentSelctedField.emailValidate" [binary]="true"
                    [disabled]="false"></p-checkbox>
                </span>
              </div>



              <div class="field col-12 md:col-12">
                <span class="p-float-label">
                  <input type="text" id="validationpattern" pInputText
                    [(ngModel)]="this.currentSelctedField.ValidationPattern" [disabled]="false" />
                  <label for="validationpattern">Custom Pattern</label>
                </span>
              </div>
            </div>
          </p-accordionTab>

          <!-- RELEATED FIEDLS-->
          <p-accordionTab header="Releated/Child fields">
            <div class="grid p-fluid mt-3">
              <div class="field col-12 md:col-12">

                <span class="p-float-label">
                  <textarea id="Releated" [rows]="5" [cols]="30" pInputTextarea [autoResize]="false"
                    [(ngModel)]="this.currentSelctedField.RelatedFields"></textarea>


                  <label for="Releated">Related Fields</label>
                </span>

              </div>


              <div class="field col-12 md:col-12">

                <span class="p-float-label">

                  <input type="text" id="ConditionFieldName" pInputText
                    [(ngModel)]="this.currentSelctedField.ConditionFieldName" [disabled]="false" />
                  <label for="ConditionFieldName">Condition Fields Name</label>
                </span>

              </div>
            </div>

          </p-accordionTab>


          <!-- BINDING FIELDS -->
          <p-accordionTab header="Binding Fields">

            <button pButton pRipple label="Binding Fields" icon="pi pi-plus-circle" class="p-button-sm"
              (click)="openBindingFieldForm()"></button>
            <div class="grid p-fluid mt-3">
              <div class="field col-12 md:col-12">
                <span class="p-float-label">
                  <textarea id="Releated" [rows]="5" [cols]="30" pInputTextarea [autoResize]="false" [disabled]="true"
                    [(ngModel)]="this.currentSelctedField.BindingFields"></textarea>
                  <label for="Releated">Binding Fields</label>
                </span>
              </div>
            </div>
          </p-accordionTab>



        </p-accordion>
      </div>
      <button pButton pRipple label="Update" icon="pi pi-check" class="p-button-sm" (click)="SaveTemplate()"></button>

    </div>

  </p-sidebar>



  <!-- Binding Field Property -->

  <p-dialog [(visible)]="bindindDialog" [style]="{ width: '70%' }" header="Binding Field Mapping" [modal]="true"
    styleClass="p-fluid">

    <p-table [value]="bindingFieldData" [tableStyle]="{'min-width': '50rem'}"
      styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " responsiveLayout="stack">
      <ng-template pTemplate="header">
        <tr>
          <th>Target Field</th>
          <th>Source Field</th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowdata>


        <tr>
          <td>{{rowdata.targetField}}</td>
          <td>
            <p-dropdown appendTo="body" [options]="getSourceFieldsList()" [autoDisplayFirst]="false" [showClear]="true"
              [(ngModel)]="rowdata.sourceField" optionLabel="name" [filter]="true" optionValue="name"
              [style]="{ width: '100%' }">
            </p-dropdown>
          </td>

        </tr>
      </ng-template>
    </p-table>

    <button pButton pRipple label="Apply" icon="pi pi-check" class="p-button-sm"
      (click)="SaveBindingFieldData()"></button>

  </p-dialog>


</div>

<!-- Fields ReOrder -->

  <p-dialog header="ReArrange fields" [(visible)]="showReorderTable" [style]="{width: '40vw'}">
    <p-table [value]="xfields" [tableStyle]="{'min-width': '20rem'}">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width:3rem"></th>
          <th>
            FieldName
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-field let-index="rowIndex">
        <tr [pReorderableRow]="index">
          <td>
            <span class="pi pi-bars"></span>
          </td>
          <td>
            {{ field.fieldName }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-dialog>



  <p-dialog header="Add New Control" [(visible)]="showNewField" [style]="{width: '40vw'}">
    <div class="grid p-fluid mt-3">
      <div class="field  md:col-6">
        <span class="p-float-label">
          <input type="text" id="name" pInputText [(ngModel)]="newField" />
          <label for="name">Name</label>
        </span>
      </div>
      <div class="field  md:col-6">
        <button pButton pRipple label="AddNewControl" icon="pi pi-check" class="p-button-sm"
          (click)="SaveControl()"></button>
      </div>
    </div>



  </p-dialog>



  <p-overlayPanel #op [showCloseIcon]="true" [dismissable]="true" [style]="{width: '450px'}">
<div *ngIf="isDataLoaded">
    <form [formGroup]="filterForm" (ngSubmit)="onFilterSubmit( $event , op)">
      <div class="grid p-fluid mt-3">

        <ng-template ngFor let-field [ngForOf]="xfields" >
          <!--TEXTBOX  [required]="field.required"  -->

          <ng-template [ngIf]="field['FilterVisible']">

            <ng-template [ngIf]="field.fieldType == 'Textbox'">
              <div class="field col-12  {{ 'md:' + field.width }}  " [hidden]="field.fieldName === 'id'">
                <span class="p-float-label p-input-icon-{{ field.iconPosition }} ">
                  <input [type]="field.datatype" pInputText [formControlName]="field.fieldName" />
                  <label>{{ field.fieldHeader }}</label>
                  <ng-template [ngIf]="field.hasIcon == true">
                    <i class="pi {{' ' + field.icon}}"></i>
                  </ng-template>
                </span>
  
  
                
              </div>
            </ng-template>
  
  
            <!--TEXTBOX [required]="field.required"-->
            <ng-template [ngIf]="field.fieldType == 'TextBox'">
              <div class="field col-12  {{ 'md:' + field.width }}  " [hidden]="field.fieldName === 'id'">
                <span class="p-float-label p-input-icon-{{ field.iconPosition }}">
                  <input [type]="field.datatype" pInputText [formControlName]="field.fieldName" />
                  <label>{{ field.fieldHeader }}</label>
                  <ng-template [ngIf]="field.hasIcon == true">
                    <i class="pi {{' ' + field.icon}}"></i>
                  </ng-template>
                </span>
  
                
  
  
  
              </div>
            </ng-template>
            <!---- DROPDOWN -->
  
            <ng-template [ngIf]="field.fieldType == 'Dropdown'">
              <div class="field col-12 {{'md:' + field.width }}">
                <span class="p-float-label">
  
                  <p-dropdown appendTo="body" [options]="formMasterData[field.source]" [formControlName]="field.fieldName"
                    [autoDisplayFirst]="false" [optionLabel]="field.optionName" [optionValue]="field.optionValue"
                    [filter]="true" [style]="{ width: '100%' }"
                    (onChange)="processChange(field.fieldName,field.RelatedFields,field.ConditionFieldName , field.BindingFields)">
                  </p-dropdown>
                  <label>{{ field.fieldHeader + ' : ' + (masterForm.value[field.fieldName] || '')}} </label>
                </span>
  
  
               
  
  
              </div>
            </ng-template>
  
  
            <!---- CHECKBOX -->
  
            <ng-template [ngIf]="field.fieldType == 'Checkbox'">
              <div class="field col-12 {{'md:' + field.width }}">
                <span><label [for]="field.fieldName">{{ field.fieldHeader }} </label> </span>
                <span class="p-float-label">
                  <p-checkbox [formControlName]="field.fieldName" [binary]="true" inputId="binary" [id]="field.fieldName">
                  </p-checkbox>
  
                </span>
  
              </div>
            </ng-template>
  
  
  
  
  
  
  



          </ng-template>
          


        </ng-template>
      </div>
      <button  type="submit" pButton pRipple label="Apply"
        icon="pi pi-check" class="p-button-sm"></button>
  
    </form></div>
  </p-overlayPanel>
<app-warning-popup></app-warning-popup>