import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LoadingService } from './services/loading-service.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ShipV1';
  layoutMode = 'horizontal';
  darkMenu = false;
  profileMode = 'popup';
  inputStyle = 'outlined';
  ripple: boolean = false;
  isLoading$: Observable<boolean>;
  loaderText: string = 'Please wait...';
  constructor(
    private primengConfig: PrimeNGConfig,
    private loadingService: LoadingService
  ) {
    this.isLoading$ = this.loadingService.loading$;
    try {
      var config: any = localStorage.getItem('configuration');
      var configSettings: any = JSON.parse(config);

      this.title = configSettings.title;
      // this.layoutMode = configSettings['layoutMode']
      // this.darkMenu = configSettings['darkMenu']
      // this.app.profileMode = configSettings['profileMode']
      this.inputStyle = configSettings['inputStyle'];
      this.ripple = configSettings['ripple'];
    } catch (e) {}
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.loadingService.loaderText$.subscribe((text: string) => {
      this.loaderText = text;
    });
  }
  get formattedLoaderText(): string {
    return this.loaderText.replace(/\n/g, '<br>');
  }
}
