import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  private secretKey = 'c360eship'; // Use a strong secret key for encryption

  constructor(private cookieService: CookieService) { }

  // Method to store encrypted data in a cookie
  setEncryptedCookie(key: string, value: any, expirationDays: number): void {
    const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), this.secretKey).toString();

    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + (expirationDays * 24 * 60 * 60 * 1000));

    this.cookieService.set(key, encryptedValue, { expires: expirationDate, path: '/' });
  }

  // Method to retrieve and decrypt data from a cookie
  getDecryptedCookie(key: string): any {
    const encryptedValue = this.cookieService.get(key);
    if (encryptedValue) {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, this.secretKey);
      const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedValue);
    }
    return null;
  }

  // Method to delete a cookie
  deleteCookie(key: string): void {
    this.cookieService.delete(key, '/');
  }
}
