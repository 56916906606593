import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { GetAllDataService } from 'src/app/services/ship-now/get-all-data.service';
import { SharedService } from 'src/app/services/ship-now/shared.service';
import { ShipNowService } from 'src/app/services/ship-now/ship-now.service';

@Component({
  selector: 'app-international-details',
  templateUrl: './international-details.component.html',
  styleUrl: './international-details.component.scss'
})
export class InternationalDetailsComponent implements OnInit {
  constructor(private dataService : GetAllDataService,private sharedService: SharedService, private cdr:ChangeDetectorRef){}
  @Input() shipment!: any;
  ngOnInit(): void {
    this.sharedService.shipment$.subscribe(updatedShipment => {
      console.log(this.shipment);
      console.log(updatedShipment);

      if (updatedShipment) {
        this.shipment = updatedShipment;
        // Log the received shipment data
        const shipFromCountry = this.shipment?.ShipFrom?.COUNTRY ?? "";
        const shipToCountry = this.shipment?.ShipTo?.COUNTRY ?? "";
        if (shipFromCountry !== shipToCountry) {
          const packItem = this.shipment.Packages || [];
    
          for (let i = 0; i < packItem.length; i++) {
            const packProduct = packItem[i].ItemsInfo || [];
            const packProductList = [];
      
            for (let j = 0; j < packProduct.length; j++) {
              const newPack = {
                product: packProduct[j]['ProductNo'] ?? '',
                description: packProduct[j]['Description'] ?? '',
                totalQuantity: packProduct[j]['Quantity'] ?? 0,
                balanceQuantity: packProduct[j]['Balance'] ?? 0,
                partialQuantity: packProduct[j]['Partial'] ?? 0,
                isSerial: packProduct[j]['IsSerial'] ?? false,
                isDG: packProduct[j]['IsDG'] ?? false,
              };
              packProductList.push(newPack);
            }
      
            const newPack = {
              hu: packItem[i]['HU'] ?? '',
              Weight: packItem[i]['Weight'] ?? 0,
              WeightUnits: packItem[i]['Weightunits'] ?? '',
              dimension: packItem[i]['Dimension'] ?? '',
              dimensionUnits: packItem[i]['DimensionUnits'] ?? '',
              class: '',
              tracking: packItem[i]['TrackingNumber'] ?? '',
              ItemsInfo: packProductList,
            };
            this.packedItems.push(newPack);
            // this.cdr.detectChanges();
          }
          this.sharedService.internationalHU$.subscribe(internationalHU => {
            console.log(internationalHU)
            const shipFromCountry = this.shipment?.ShipFrom?.COUNTRY ?? "";
            const shipToCountry = this.shipment?.ShipTo?.COUNTRY ?? "";
            // Assuming packedItems is initialized as an empty array
            this.packedItems = [];
            if (shipFromCountry !== shipToCountry) {
                   // Prepare the new pack item based on the data received
            const newPackItem = {
              HU: internationalHU.HU ?? '',
              ItemsInfo: internationalHU.ItemsInfo || [], // Use the provided ItemsInfo array
              licenseNo: internationalHU.licenseNo ?? '', // Example property, replace with correct data if needed
              ecc: internationalHU.ecc ?? '', // Example property, replace with correct data if needed
              uom: internationalHU.uom ?? '', // Example property, replace with correct data if needed
              currency: internationalHU.currency ?? '', // Example property, replace with correct data if needed
              countryOfMFR: internationalHU.countryOfMFR ?? '', // Example property, replace with correct data if needed
              harmonized: internationalHU.harmonized ?? '', // Example property, replace with correct data if needed
              weight: internationalHU.Weightunits ?? '', // Example property, replace with correct data if needed
              unitValue: internationalHU.unitValue ?? '', // Example property, replace with correct data if needed
            };
          
            // Push the new pack item to the packedItems array
            this.packedItems.push(newPackItem);
            }
          });
        }
        // Handle the updated shipment
      }
    });

    this.sharedService.internationalHU$.subscribe(internationalHU => {
      console.log(internationalHU)
      const shipFromCountry = this.shipment?.ShipFrom?.COUNTRY ?? "";
      const shipToCountry = this.shipment?.ShipTo?.COUNTRY ?? "";
      // Assuming packedItems is initialized as an empty array
      this.packedItems = [];
      if (shipFromCountry !== shipToCountry) {
             // Prepare the new pack item based on the data received
      const newPackItem = {
        HU: internationalHU.HU ?? '',
        ItemsInfo: internationalHU.ItemsInfo || [], // Use the provided ItemsInfo array
        licenseNo: internationalHU.licenseNo ?? '', // Example property, replace with correct data if needed
        ecc: internationalHU.ecc ?? '', // Example property, replace with correct data if needed
        uom: internationalHU.uom ?? '', // Example property, replace with correct data if needed
        currency: internationalHU.currency ?? '', // Example property, replace with correct data if needed
        countryOfMFR: internationalHU.countryOfMFR ?? '', // Example property, replace with correct data if needed
        harmonized: internationalHU.harmonized ?? '', // Example property, replace with correct data if needed
        weight: internationalHU.Weightunits ?? '', // Example property, replace with correct data if needed
        unitValue: internationalHU.unitValue ?? '', // Example property, replace with correct data if needed
      };
    
      // Push the new pack item to the packedItems array
      this.packedItems.push(newPackItem);
      }
    });
    

  }
  shipMethods = [
    { label: 'Ship Method 1', value: 'Ship Method 1' },
    { label: 'Ship Method 2', value: 'Ship Method 2' }
  ];
  TermsOfShipment = [{ value: '', label: 'select' },
    { value: 'CFR', label: 'Cost and Freight' },
    { value: 'CIF', label: 'Cost Insurance and Freight' },
    { value: 'CIP', label: 'Carraige Insurance Paid' },
    { value: 'DDP', label: 'Delivered Duty Paid' },
    { value: 'DDU', label: 'Delivered Duty Unpaid' },
    { value: 'EXW', label: 'Ex Works' },
    { value: 'CPT', label: 'Carriage Paid To' },
    { value: 'DAP', label: 'Delivered at Place' },
    { value: 'DPU', label: 'Delivered at Place Unloaded' },
    { value: 'FAS', label: 'Free Alongside Ship' },
    { value: 'FOB', label: 'Free On Board' },
    { value: 'FCA', label: 'Free Carrier' }
  ];

  DocumentsContentType = [{ value: '', label: 'select' },
    { value: 'DERIVED', label: 'Derived' },
    { value: 'DOCUMENTS_ONLY', label: 'Documents_Only' },
    { value: 'NON_DOCUMENTS', label: 'Non_Documents' }
  ];

  ReasonForExport = [{ value: '', label: 'select' },
    { value: 'SOLD', label: 'Sold' },
    { value: 'SAMPLE', label: 'Sample' },
    { value: 'NOT_SOLD', label: 'Not_Sold' },
    { value: 'GIFT', label: 'Gift' },
    { value: 'PERSONAL_EFFECTS', label: 'Personal_Effects' },
    { value: 'REPAIR_AND_RETURN', label: 'Repair_And_Return' },
    { value: 'MERCHANDISE', label: 'Merchandise' }
  ];
  INTDutiesTaxes = [{ value: '', label: 'select' },
    { value: 'RECIPIENT', label: 'Recipient' },
    { value: 'SENDER', label: 'Sender' },
    { value: 'THIRDPARTY', label: 'ThirdParty' },
    { value: 'COLLECT', label: 'Collect' }
  ];
  B13AFilingOption = [{ value: '', label: 'select' },
    { value: 'NOT_REQUIRED', label: 'Not_Required' },
    { value: 'FEDEX_TO_STAMP', label: 'Fedex_To_Stamp' },
    { value: 'FILED_ELECTRONICALLY', label: 'Filed_ElectronicallY' },
    { value: 'MANUALLY_ATTACHED', label: 'Manually_Attached' },
    { value: 'SUMMARY_REPORTING', label: 'Summary_Reporting' }
  ];


  serviceTypes = [
    { label: 'Service 1', value: 'Service 1' },
    { label: 'Service 2', value: 'Service 2' }
  ];

  paymentTypes = [
    { label: 'Payment 1', value: 'Payment 1' },
    { label: 'Payment 2', value: 'Payment 2' }
  ];

  costCenters = [
    { label: 'Cost Centre 1', value: 'Cost Centre 1' },
    { label: 'Cost Centre 2', value: 'Cost Centre 2' }
  ];

  selectedShipMethod: string = "";
  selectedServiceType: string = "";
  carrierId: string = "";
  accountNumber: string = "";
  selectedPaymentType: string = "";
  selectedCostCenter: string = "";
  poNo: string = "";
  invoiceNo: string = "";
  notes: string = "";
  packedItems :any
  Dimensions : any;
  productDetails;
  displayDialog;
  showOverlay;
  selectedProduct;
  displayProductDetails;

  deleteAll() {
    this.packedItems = [];
  }

  onInputChange() {
    this.dataService.setPackingInfoData(this.packedItems);
  }

  // searchProducts(): void {
  //   this.ShipNowService.getProducts().subscribe((data) => {
  //     this.productDetails = data;
  //     this.displayDialog = true;
  //     this.showOverlay=true;
  //   });
  // }
  getProducts(item)
  {
    this.selectedProduct = item;
    this.displayProductDetails = true; 
    this.showOverlay=true;

  }

  deleteProductHU(i: number, hu: number | string) {
    // Find the package by HU
    const index = this.shipment.Packages.findIndex(pkg => pkg.HU == hu);
    if (index !== -1) {
        // Extract ItemsInfo from the package before deletion
        const itemsInfo = this.shipment.Packages[index].ItemsInfo || [];
 // Remove from packedItems
 const packedItemIndex = this.packedItems.findIndex(item => item.hu === hu);
 if (packedItemIndex !== -1) {
     this.packedItems.splice(packedItemIndex, 1);
 } else {
 }
        // Remove the package from the array by creating a new array reference
        this.shipment.Packages = [
            ...this.shipment.Packages.slice(0, index),
            ...this.shipment.Packages.slice(index + 1)
        ];

        // Bind ItemsInfo data to this.products
        this.bindItemsInfoToProducts(itemsInfo);

        this.onInputChange();
        this.dataService.setPackingInfoData(this.shipment.Packages);
    } else {
    }
}

// Method to bind ItemsInfo to this.products
bindItemsInfoToProducts(itemsInfo: any[]): void {
  itemsInfo.forEach(item => {
      const newProduct = {
          product: item.ProductNo || '',
          description: item.Description || '',
          totalQuantity: item.Quantity || 0,
          balanceQuantity: item.Quantity || 0,
          partialQuantity: item.Partial || 0,
          s: item.s || false,
          isDG: item.isDG || false,
      };
      //this.products.push(newProduct);
  });
}

ngOnChanges(changes: SimpleChanges): void {
  if (changes['shipment'] && this.shipment) {
    console.log(this.shipment);
    this.packedItems = []; // Reset packedItems
    const shipFromCountry = this.shipment?.ShipFrom?.COUNTRY ?? "";
    const shipToCountry = this.shipment?.ShipTo?.COUNTRY ?? "";
    if (shipFromCountry !== shipToCountry) {
      const packItem = this.shipment.Packages || [];

      for (let i = 0; i < packItem.length; i++) {
        const packProduct = packItem[i].ItemsInfo || [];
        const packProductList = [];
  
        for (let j = 0; j < packProduct.length; j++) {
          const newPack = {
            product: packProduct[j]['ProductNo'] ?? '',
            description: packProduct[j]['Description'] ?? '',
            totalQuantity: packProduct[j]['Quantity'] ?? 0,
            balanceQuantity: packProduct[j]['Balance'] ?? 0,
            partialQuantity: packProduct[j]['Partial'] ?? 0,
            isSerial: packProduct[j]['IsSerial'] ?? false,
            isDG: packProduct[j]['IsDG'] ?? false,
          };
          packProductList.push(newPack);
        }
  
        const newPack = {
          hu: packItem[i]['HU'] ?? '',
          Weight: packItem[i]['Weight'] ?? 0,
          WeightUnits: packItem[i]['Weightunits'] ?? '',
          dimension: packItem[i]['Dimension'] ?? '',
          dimensionUnits: packItem[i]['DimensionUnits'] ?? '',
          class: '',
          tracking: packItem[i]['TrackingNumber'] ?? '',
          ItemsInfo: packProductList,
        };
        this.packedItems.push(newPack);
        // this.cdr.detectChanges();
      }

    }
    else{
    }


  }
}



}
