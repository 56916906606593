import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  private loaderTextSubject = new BehaviorSubject<string>('Please wait...');
  loaderText$: Observable<string> = this.loaderTextSubject.asObservable();

  show(): void {
    this.loadingSubject.next(true);
  }

  hide(): void {
    this.loadingSubject.next(false);
  }

  updateLoaderText(text: string): void {
    this.loaderTextSubject.next(text);
  }
}
