import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShipNowService {
  private mainUrl = environment.shipapiurl +'/';
  private fedExUrl = environment.shipapiurl +'/FedEx';
  private UPSUrl = environment.shipapiurl +'/UPS';
  private rateAll = environment.shipapiurl +'/rateall';
  private AbfUrl = environment.shipapiurl +'/ABF';
  private rlUrl = environment.shipapiurl +'/RL';
  private fedExFreightUrl = environment.shipapiurl +'/FedExFreight';

  private baseUrl: string = environment.apiUrl;
  private deleteurl : string = environment.deleteAPI;
  
  freightShopRowData = new Subject<any>();

  constructor(private http: HttpClient) { }

  getShippingData(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/LocationMaster`);
  }

  createShipment(data: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/your-endpoint`, data);
  }

  getProducts(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/MasterData/products`);
  }
  packItem(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/hu`, payload);
  }

  deleteItem(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/clouddeletehu`, payload);
  }
  
  autoPack(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/autopack`, payload);
  }
  packAllItems(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/hu`, payload);
  }

  deleteItems(payload: any): Observable<any> {
    return this.http.post<any>(`${this.deleteurl}`, payload);
  }

  getTimelineData(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/formOperations/shipments`);
  }

  sendFedExRequest(payload:any): Observable<any> {
    return this.http.post<any>(`${this.fedExUrl}`,payload);
  }
  sendUPSRequest(payload: any,connectionStr = ""): Observable<any> {
    return this.http.post<any>(`${this.UPSUrl}`, payload);
  }
  sendShipRequest(payload:any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/formOperations/shipments`,payload);
  }

  sendShipRequestCarrier(payload:any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/shipments`,payload);
  }
  sendFreightShopRequest(payload:any): Observable<any> {
    return this.http.post<any>(`${this.rateAll}`,payload);
  }
  sendAbfRequest(payload:any): Observable<any> {
    return this.http.post<any>(`${this.AbfUrl}`,payload);
  }

  getHistoryData(payload:any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/shipments/gethistoryshipments`, payload);
  }
  sendRLRequest(payload:any): Observable<any> {
    return this.http.post<any>(`${this.rlUrl}`,payload);
  }
  sendRateRequest(payload:any,conStr): Observable<any> {
    return this.http.post<any>(`${this.mainUrl+conStr}rate`,payload);
  }
  sendFedExFreightRequest(payload:any): Observable<any> {
    return this.http.post<any>(`${this.fedExFreightUrl}`,payload);
  }
  sendVoidRequest(payload:any,constr = ""): Observable<any> {
    return this.http.post<any>(`${this.mainUrl+constr}void`,payload);
  }
  sendCarrierRequest(payload:any,carrier = ""): Observable<any> {
    return this.http.post<any>(`${this.mainUrl+carrier}`,payload);
  }
  sendMail(payload:any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/sendmail`,payload);
  }

    //Save Profile
    saveProfile(record: any): Observable<any> {
      const headers = {
        'content-type': 'application/json',
        'X-CSRF-Token': 'Fetch',
      };
      return this.http.get(this.baseUrl + `/profile/${record}`, {
        headers,
      });
    }

    private cubiscanUrl = 'http://10.1.100.100';

    // Method to trigger the measurement
    triggerMeasurement(): Observable<any> {
    const url = `${this.cubiscanUrl}/csMeasure`; // Replace with the actual endpoint route
    return this.http.get(url);
    }

    // Method to stop a process or interact with other Cubiscan endpoints
    stopProcess(): Observable<any> {
    const url = `${this.cubiscanUrl}/csStop`; // Replace with the actual stop endpoint route
    return this.http.get(url);
    }

  /////////////////////////////Paccurate Changes//////////////////////////////////////
  activeBoxes: number[] = [];
  toggleBox(id: number): void {
    const boxElems = document.querySelectorAll(`[data-volume-index="${id}"]`);
    boxElems.forEach((elem) => {
      elem.classList.toggle('shape-hidden');
    });
  }

  incomingitems:  any = [];
  totitemsandboxes = [];
  getpaccurateinfo(packInfo: any): Observable<void> {
    this.totitemsandboxes = [];
    this.incomingitems = [];
    // Example of returning an Observable
    return new Observable((observer) => {
      // Simulate async call
      setTimeout(() => {
        
        interface Dimensions {
          x: number;
          y: number;
          z: number;
        }
        
        interface ItemSet {
          refId: number;
          color: string;
          weight: number;
          name: string;
          dimensions: Dimensions;
          quantity: number;
        }
        
        interface Config {
          layFlat: boolean;
          interlock: boolean;
          corners: boolean;
          itemSets: ItemSet[];
          usableSpace: number;
          boxTypeSets: string[];
          eye: Dimensions;
          packOrigin: Dimensions;
          zone: number;
          random: boolean;
          n: number;
          randomMaxDimension: number;
          randomMaxWeight: number;
          seed: boolean;
          imgSize: number;
          template: string;
          coordOrder: number[];
          cohortPacking: boolean;
          cohortMax: number;
          allowableOverhang: number;
          placementStyle: string;
        }
        
        
        (packInfo.products || []).forEach((item, index) => { 
          let i = index;
          let dimname = '';
          let dimvalue = {};
          let color = '';
          
          //const dims: string[] = item.dimension.split("X");
          if(item.dimension == "Small Box" || item.dimension == "Box A" || item.dimension == "10X10X10" || item.dimension == "50X21X142" || item.dimension == "50x21x142")
            {
              dimname = item.description
              dimvalue = {x: 1, y: 2, z: 1}
              color = "darkorange"
            }
            else if(item.dimension == "Medium Box" || item.dimension == "Box B" || item.dimension == "15X10X15" || item.dimension == "52X22X145" || item.dimension == "52x22x145")
            {
                dimname = item.description
                dimvalue = {x: 4, y: 4, z: 4}
                color = "green"
            }
            else if(item.dimension == "Large Box" || item.dimension == "Box C" || item.dimension == "40X30X40" || item.dimension == "48X20X145" || item.dimension == "48x20x145")
            {
                dimname = item.description
                dimvalue = {x: 10, y: 10, z: 10}
                color = "indigo"
            }
            else{
              dimname = item.description
              dimvalue = {x: 1, y: 2, z: 1}
              color = "darkorange"
            }

          
            this.incomingitems.push({
              refId: i,
              color: color,
              weight: parseInt(packInfo.UnitWeight),
              name: dimname,
              dimensions:  dimvalue,
              quantity: Number(item.partialQuantity) === 0 ? (Number(item.totalQuantity) > Number(item.balanceQuantity) ? Number(item.balanceQuantity) : Number(item.totalQuantity)) : Number(item.partialQuantity)
            });
           // Calculate total quantity from incoming items
          let totalQuantity = this.incomingitems.reduce((total, currentItem) => {
              return currentItem.quantity;  // Accumulate the quantity
          }, 0);

          // Determine the starting point for the next set of numbers
          let currentMax = this.totitemsandboxes.length > 0 ? this.totitemsandboxes[this.totitemsandboxes.length - 1] : 0;

          // Push values from currentMax + 1 to currentMax + totalQuantity into totitemsandboxes
          for (let i = currentMax + 1; i <= currentMax + totalQuantity; i++) {
              this.totitemsandboxes.push(i);
          }

          // Update the currentMax after pushing the first set of numbers
          currentMax = this.totitemsandboxes[this.totitemsandboxes.length - 1];
            });
        // After the loop, continue with delitems.length
        let currentMax = this.totitemsandboxes.length > 0 ? this.totitemsandboxes[this.totitemsandboxes.length - 1] : 0;

        // Push values from currentMax + 1 to currentMax + delitems.length
        for (let i = currentMax + 1; i <= currentMax + packInfo.products.length + 1; i++) {
            this.totitemsandboxes.push(i);
        }
        const config: Config = {
          layFlat: false,
          interlock: false,
          corners: true,
          // itemSets: [
          //   {
          //     refId: 0,
          //     color: "darkorange",
          //     weight: 1,
          //     name: "smaller box",
          //     dimensions: { x: 1, y: 2, z: 1 },
          //     quantity: 15,
          //   },
          //   {
          //     refId: 1,
          //     color: "indigo",
          //     weight: 10,
          //     name: "larger box",
          //     dimensions: { x: 6, y: 9, z: 3 },
          //     quantity: 5,
          //   },
          // ],
          itemSets: this.incomingitems,
          usableSpace: 0.5,
          boxTypeSets: ["usps", "fedex"],
          eye: { x: 1, y: 1, z: 1 },
          packOrigin: { x: 0, y: 0, z: 0 },
          zone: 0,
          random: false,
          n: 5,
          randomMaxDimension: 30,
          randomMaxWeight: 30,
          seed: false,
          imgSize: 400,
          template: "demo.tmpl",
          coordOrder: [0, 1, 2],
          cohortPacking: false,
          cohortMax: 2,
          allowableOverhang: -1,
          placementStyle: "default",
        };
        
        console.log("ListofItems: " + JSON.stringify(config));
        
        const hideBoxes = (): void => {
          const parents = document.querySelectorAll<SVGSVGElement>('svg');
          parents.forEach((svg) => {
            svg.childNodes.forEach((element) => {
              if ((element as Element).tagName === "polygon") {
                (element as Element).classList.add('shape-hidden');
              }
            });
          });
        };
       
        interface BoxItem {
          id: string;
          index: number;
          color: string;
        }
        
        interface Sku {
          refId: number;
          name: string;
          weight: number;
          dimensions: number[];
          boxItems: BoxItem[];
        }
        
        const parsedItems = (arr: any[]): Sku[] => {
          const skus: { [key: string]: Sku } = {};
          arr.forEach((element) => {
            element = element.item;
            const id = element.name || element.refId;
            if (typeof skus[id] === 'undefined') {
              skus[id] = {
                refId: element.refId,
                name: element.name,
                weight: element.weight,
                dimensions: element.dimensions ? [element.dimensions.x, element.dimensions.y, element.dimensions.z] : [1, 1, 1],
                boxItems: [{ id: element.uniqueId, index: element.index, color: element.color }],
              };
            } else {
              skus[id].boxItems.push({ id: element.uniqueId, index: element.index, color: element.color });
            }
          });
          return Object.keys(skus).map((key) => skus[key]);
        };
        
        const keyItems = (box: Sku, index: number): string => {
          return `
            <tr>
              <td>
                <ul style="list-style-type:none; margin:0; padding:0;" class="legend">
                  ${box.boxItems
                    .map((item) => `<li data-box-index="${index}" data-volume-index="${item.index}" style="width:20px; height:20px; margin:0 5px 5px 0; float:left; background-color:${item.color}"></li>`)
                    .join('')}
                </ul>
              </td>
              <td>${box.name || box.refId}</td>
              <td>${box.dimensions.join('x')}</td>
              <td>${box.weight}</td>
              <td>${box.boxItems.length}</td>
            </tr>
          `;
        };

        
        
        // const generateMarkup = (svg: string, boxes: any): HTMLElement => {
        //   const parsed = parsedItems(boxes.box.items);
        //   const boxId = boxes.box.id;
        //   const layout = document.createElement('div');
        //   const svgWrap = document.createElement('div');
        //   const itemKey = document.createElement('table');
        //   itemKey.innerHTML = `
        //     <tr>
        //       <th>item</th>
        //       <th>name/id</th>
        //       <th>dims</th>
        //       <th>weight</th>
        //       <th>qty</th>
        //     </tr>
        //     ${parsed.map((item) => keyItems(item, boxId)).join('')}
        //   `;
        //   svgWrap.innerHTML = svg;
        //   layout.appendChild(svgWrap);
        //   layout.appendChild(itemKey);
        //   return layout;
        // };

        const generateMarkup = (svg: string, boxes: any, boxLength, currentIndex): HTMLElement => {
          const parsed = parsedItems(boxes.box.items);
          const boxId = boxes.box.id;
        
          // Create the main layout container
          const layout = document.createElement('div');
          layout.style.display = 'flex';
          layout.style.flexDirection = 'column';
          layout.style.alignItems = 'flex-start';
          layout.style.marginBottom = '40px';  // Add some space below each section
        
          // Create a wrapper for the svg and table
          const boxWrapper = document.createElement('div');
          boxWrapper.style.display = 'flex';
          boxWrapper.style.justifyContent = 'space-between';  // Align SVG and table side by side
          boxWrapper.style.width = '100%';
          boxWrapper.style.alignItems = 'center'; // Ensure the SVG and table align at the top
          boxWrapper.style.position = 'relative';
        
          // Create the SVG wrapper
          const svgWrap = document.createElement('div');
          svgWrap.style.flex = '1';  // Allow the SVG to take up part of the space
          svgWrap.innerHTML = svg;

          // Create the Index Number wrapper
          const boxNumWrap = document.createElement('div');
          boxNumWrap.innerHTML = currentIndex + 1;
          boxNumWrap.classList.add('pack-num');
        
          // Create the table for item details
          const itemKey = document.createElement('table');
          itemKey.style.flex = '1';  // Allow the table to take up the remaining space
          itemKey.style.borderCollapse = 'collapse';
          itemKey.style.width = '100%';
          itemKey.style.height = 'auto'; // Ensure table height is based on content
          itemKey.style.margin = '0';  // Prevent margins
          
        
          // Create table headers and rows
          itemKey.innerHTML = `
            <thead>
              <tr>
                <th>Item</th>
                <th>Item Desc</th>
                <th>Dims</th>
                <th>Weight</th>
                <th>Qty</th>
              </tr>
            </thead>
            <tbody>
              ${parsed.map((item) => keyItems(item, boxId)).join('')}
            </tbody>
          `;
        
          // Add both elements (SVG and table) to the wrapper
          boxWrapper.appendChild(svgWrap);
          boxWrapper.appendChild(boxNumWrap);
          boxWrapper.appendChild(itemKey);
        
          // Add the box wrapper to the layout
          layout.appendChild(boxWrapper);
        
          // Add a horizontal line (separator) between SVG images
          if (currentIndex !== boxLength) {
            const separator = document.createElement('hr');
            separator.style.width = '100%';
            separator.style.margin = '10px 0'; // Control spacing around the line
            separator.style.border = '1px solid #003366';  // Customize the color of the line if necessary
            layout.appendChild(separator);  // Add the separator between the sections
          }
        
          return layout;
        };

        const activateBox = (boxId: string, itemId: string, toggle: boolean): void => {
          const elems = document.querySelectorAll<SVGElement>(`figure[data-box-index="${boxId}"] polygon[data-volume-index="${itemId}"]`);
          const parent = document.querySelector<HTMLElement>(`figure[data-box-index="${boxId}"]`);
          if (toggle) {
            parent?.classList.add('x-ray');
            elems.forEach((item) => item.classList.add('active'));
          } else {
            parent?.classList.remove('x-ray');
            elems.forEach((item) => item.classList.remove('active'));
          }
        };
        
        const addLegendListeners = (): void => {
          document.querySelectorAll('ul.legend li').forEach((element) => {
            element.addEventListener('mouseenter', (e) => {
              const box = (e.target as HTMLElement).getAttribute('data-box-index');
              const item = (e.target as HTMLElement).getAttribute('data-volume-index');
              if (box && item) activateBox(box, item, true);
            });
            element.addEventListener('mouseleave', (e) => {
              const box = (e.target as HTMLElement).getAttribute('data-box-index');
              const item = (e.target as HTMLElement).getAttribute('data-volume-index');
              if (box && item) activateBox(box, item, false);
            });
          });
        };
        
     
        const writeLegend = (json: any): void => {
          const packData = json;
          const target = document.querySelector<HTMLElement>('#pack-list');
          const svgs = packData.svgs;
          const boxes = packData.boxes;
          boxes.forEach((box: any, index: number) => {
            target?.appendChild(generateMarkup(svgs[index], box, boxes.length - 1, index));
          });
          // try{
            target.classList.add("svg-viewer");
          // }catch{}
          
    
          
          hideBoxes();
    
          
          addLegendListeners();
          
        };
        
          const request = new XMLHttpRequest();
          const method = 'POST';
          const url = 'https://api.paccurate.io/';
          const packObj = config;
          request.open(method, url, true);
          request.setRequestHeader('Authorization', 'apikey 6UZlPsHAqp43MMUUU4Zg83yCuiJHTvbz');
          request.setRequestHeader('Content-Type', 'application/json');
          request.onreadystatechange = () => {
            if (request.readyState === XMLHttpRequest.DONE) {
              const status = request.status;
              if (status === 0 || (status >= 200 && status < 400)) {
                try {
                  const packResponse = JSON.parse(request.responseText);
                  
                  writeLegend(packResponse);
                  this.getsetpbystepboxinfo();
                  //alert("Before");
                 // for (let i = 1; i < this.activeBoxes.length-1; i++) {
                    //const boxId = this.activeBoxes[i];

                    
                    // setTimeout(()=>{
                    //   this.conversation.next([botMessage]);
                    // }, 300);


                    

                    //alert("After");

                    // setTimeout(() => {
                    //   this.toggleBox(1);
                    // },  1000); 

                    // setTimeout(() => {
                    //   this.toggleBox(2);
                    // },  2500); 

                    // setTimeout(() => {
                    //   this.toggleBox(3);
                    // },  4000); 

                    // setTimeout(() => {
                    //   this.toggleBox(4);
                    // },  6500); 

                    // setTimeout(() => {
                    //   this.toggleBox(5);
                    // },  8000); 

                    // setTimeout(() => {
                    //   this.toggleBox(6);
                    // },  9500); 
                  //}
                } catch (e) {
                  console.error(e);
                }
              } else {
                console.log(status);
              }
            }
          };
          request.send(JSON.stringify(packObj));
         
        // document.addEventListener('DOMContentLoaded', function () {});
        // observer.next();
        // observer.complete();
      }, 2000);
      
    });
  }

  getsetpbystepboxinfo(){
    //const newItems = [1, 2, 3, 4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];  
    
    // newItems.forEach(item => {
    //   this.activeBoxes.push(item);
    // });
    this.totitemsandboxes.forEach(item => {
      this.activeBoxes.push(item);
    });
    for (let i = 1; i < this.activeBoxes.length; i++) {
      setTimeout(() => {
        this.toggleBox(i);
      }, i * 300); 
    }
 
 
  }

  ////////////////////////////End Paccurate Changes///////////////////////////////////
}
