let openAndDistributeFacType = [
     
    { label: "SCF", value: "SCF" },
    { label: "DDU", value: "DDU" },
    { label: "NDC", value: "NDC" },
    { label: "ADC", value: "ADC" },
    { label: "ASF", value: "ASF" },
    { label: "NDC", value: "NDC" },
  ],
  openAndDistMailClass = [
     
    { label: "Marketing Mail Letters", value: "MarketingMailLetters" },
    { label: "Marketing Mail Flats", value: "MarketingMailFlats" },
    { label: "Marketing Mail Parcels", value: "MarketingMailParcels" },
    { label: "Marketing Mail Mix", value: "MarketingMailMix" },
    { label: "Other", value: "Other" },
  ],
  packageType = [
     
    { label: "Card", value: "Card" },
    { label: "Letter", value: "Letter" },
    { label: "Flat", value: "Flat" },
    { label: "Parcel", value: "Parcel" },
    { label: "LargeParcel", value: "LargeParcel" },
    { label: "IrregularParcel", value: "IrregularParcel" },
  ];
let uspsFields = [
  {
    name: "codAmount",
    label: "COD Amount",
    type: "text",
    cls: "",
  },
  {
    name: "insuredAmount",
    label: "Insured Amount",
    type: "text",
    cls: "",
  },
  {
    name: "packageType",
    label: "Package Type",
    type: "dropdown",
    cls: "",
    options:packageType
  },
  {
    name: "openAndDistributeFacType",
    label: "Open and Distri. Faci. Type",
    type: "dropdown",
    cls: "",
    options:openAndDistributeFacType
  },
  {
    name: "openAndDistMailClass",
    label: "Open and Distri. Mail Class Enclo",
    type: "dropdown",
    cls: "",
    options:openAndDistMailClass
  },
  {
    name: "openAndDistMailClassOther",
    label: "Open and Distri. Mail Class Other",
    type: "text",
    cls: "",
  },
  {
    name: "noWeekendDelivery",
    label: "No Weekend Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "holidayDelivery",
    label: "Holiday Delivery",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "signatureWaiver",
    label: "Signature Waiver",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "certifiedMail",
    label: "certifiedMail",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "electronicReturnReceipt",
    label: "Electronic Return Receipt",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "signatureConfirmation",
    label: "Signature Confirmation",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "openAndDistribute",
    label: "Open and Distribute",
    type: "checkbox",
    cls: "check-container",
  },
  {
    name: "emailNotification",
    label: "Email Notification",
    type: "checkbox",
    cls: "check-container",
  },
];

export default uspsFields;