import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { LoadingService } from './services/loading-service.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private notificationShown = false; // Flag to track if notification has been shown
  private activeRequests = 0; // Track active API requests

  constructor(
    private loadingService: LoadingService,
    private messageService: MessageService,
    private router: Router 
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.activeRequests++; // Increment active requests count

    // Show loader only when the first request starts
    if (this.activeRequests === 1) {
      this.loadingService.show();
    }

    const token: any = window.sessionStorage.getItem('X-Token');
    let req = request;

    if (token) {
      req = request.clone({
        headers: request.headers.set('X-Token', token),
      });
    }

    return next.handle(req).pipe(
      // Hide loader only when all requests are completed
      finalize(() => {
        this.activeRequests--; // Decrement active requests count
        if (this.activeRequests === 0) {
          this.loadingService.hide();
        }
      }),
      // Catch errors
      catchError((error: HttpErrorResponse) => {
        // Check if the error is a 401 and if it's from the specific API URL
        const isLoginApi = request.url === `${environment.apiUrl}\User`;

        if ((error.status === 401 || error.status === 403) && !isLoginApi) {
          // Handle 401 errors only if it's not from the login API
          if (!this.notificationShown) {
            this.notificationShown = true; // Set flag to prevent duplicate notifications
            // this.messageService.add({
            //   severity: 'error',
            //   summary: 'Failure',
            //   detail: 'Session expired. Please log in again.',
            //   life: 3000
            // });

            // Reset the flag after a timeout
            setTimeout(() => this.notificationShown = false, 3000);

            this.router.navigate(['/']); // Redirect to login page
            window.sessionStorage.removeItem('X-Token'); // Clear expired token
          }
        }

        // Return the error to the component to handle it appropriately
        return throwError(error);
      })
    );
  }
}
