<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<p-overlayPanel #ot header="Configure Fields" [showCloseIcon]="true" [dismissable]="true">

    <ng-template pTemplate="headless">
        <div class="p-2">
            <div class="sidebar-header">
                <h5>Recent Ship Requests</h5>
            </div>

            <!-- <div class="shipment-type-dropdown p-1">
                <p-dropdown [(ngModel)]="selectedShipmentType" [options]="shipmentTypes"
                    placeholder="Select a shipment type">
                </p-dropdown> 

            </div> -->
            <!-- Show shipments if available -->
            <div class="shipment-card-container"
                *ngIf="(filteredShipments!=undefined && filteredShipments.length > 0); else noData">
                <div class="shipment-card" *ngFor="let shipment of filteredShipments">
                    <span style="cursor: pointer;" (click)="selectHistory(shipment)">
                        {{shipment.details.HeaderInfo.DocumentNumber}}
                    </span>
                    <span>{{shipment.details.HeaderInfo.CreatedDate | date:'MM/dd/yyyy'}}</span>
                    <span>{{shipment.details.ShipTo.COMPANY}}</span>
                    <p>{{shipment.details.CarrierDetails.Carrier}}</p>
                </div>
            </div>

            <!-- Template to display when there is no data -->
            <ng-template #noData>
                <div class="no-data-message">
                    <p>No data available</p>
                </div>
            </ng-template>
        </div>
    </ng-template>
    <!-- </p-dialog> -->
</p-overlayPanel>
<!-- <p-toast position="bottom-center" key="c" [baseZIndex]="5000"> -->
<!-- Header Section Starts Here -->
<div class="transform">
    <div class="bodyshadow main-dev oascroll">
        <div class="add-ship-request-title">
            <span class="tittle-icon-css vertical-align-middle">
                <img src="../../assets/images/timeline-icon.png" (click)="showOverlay($event)" class="timeline-img" />
            </span>
            <span class="main-tittle-css vertical-align-middle">
                Routing Guide
            </span>
            <!-- <i class="pi pi-clock timeline-icon" (click)="showOverlay($event)" style="cursor: pointer; padding-left: 5px;"></i> -->
            <span>
                <img src="../../assets/images/close-icon.png" class="close-icon-img vertical-align-middle"
                    (click)="closeShipScreen()" />
            </span>
        </div>
        <div >
            <div class="routing-header-form-sec">
                <div class="p-field d-flex">
                    <label for="applicableDays">Applicable Days</label>
                    <p-dropdown class="mx-3"
                      id="applicableDays"
                      [(ngModel)]="routingGuide.Header.applicableDays"
                      optionLabel="label"
                      [options]="" [filter]="true"
                      name="applicableDays"
                      placeholder="Select a Day"
                    ></p-dropdown>
                  </div>
                
                  <!-- Priority Input (PrimeNG InputNumber) -->
                  <div class="p-field">
                    <label for="priority">Priority</label>
                    <p-inputNumber
                      id="priority"
                      [(ngModel)]="routingGuide.Header.priority"
                      name="priority"
                      mode="decimal"
                      [min]="1"
                      [max]="100"
                    ></p-inputNumber>
                  </div>
                
                  <!-- Status/Rule Type Dropdown (PrimeNG Dropdown) -->
                  <div class="p-field d-flex">
                    <label for="statusRuleType">Status/Rule Type</label>
                    <p-dropdown
                      id="statusRuleType"
                      [(ngModel)]="routingGuide.Header.statusRuleType"
                      optionLabel="label"
                      name="statusRuleType"
                      placeholder="Select Status"
                    ></p-dropdown>
                  </div>
                
                  <!-- Notes (PrimeNG Textarea) -->
                  <div class="p-field">
                    <label for="notes">Notes</label>
                    <textarea
                      id="notes"
                      [(ngModel)]="routingGuide.Header.notes"
                      name="notes"
                      rows="1"
                      class="p-inputtextarea"
                    ></textarea>
                  </div>
                
            </div>
            <div class="shipment-deatils w-100">
                <div class="ship border w-100">
                    <div class="shipfrom w-50 mx-2">
                        <div class="border">
                            <span>Ship From</span>
                        </div>
                        <div class="form-group pt-3">
                            <label for="fromCity" class="pt-2 pb-2">From City</label>
                            <input pInputText maxlength="35" id="fromCity" [(ngModel)]="routingGuide.ShipFrom.fromCity"
                                class="addressline-txtbox" />
                        </div>
                        <div class="form-group pt-3">
                            <label for="fromRegion" class="pt-2 pb-2">From Region</label>
                            <input pInputText maxlength="35" id="fromRegion" [(ngModel)]="routingGuide.ShipFrom.fromRegion"
                                class="addressline-txtbox" />
                        </div>
                        <div class="form-group pt-3">
                            <label for="fromState" class="pt-2 pb-2">From State</label>
                            <input pInputText maxlength="35" id="fromState" [(ngModel)]="routingGuide.ShipFrom.fromState"
                                class="addressline-txtbox" />
                        </div>
                        <div class="form-group pt-3">
                            <label for="fromMinZip" class="pt-2 pb-2">From Min Zip</label>
                            <input pInputText maxlength="35" id="fromMinZip" [(ngModel)]="routingGuide.ShipFrom.fromMinZip"
                                class="addressline-txtbox"/>
                        </div>
                        <div class="form-group pt-3">
                            <label for="fromMaxZip" class="pt-2 pb-2">From Max Zip</label>
                            <input pInputText maxlength="35" id="fromMaxZip" [(ngModel)]="routingGuide.ShipFrom.fromMaxZip"
                                class="addressline-txtbox"/>
                        </div>
                        <div class="form-group pt-3">
                            <label for="fromCountry" class="pt-2 pb-2">From Country</label>
                            <input pInputText maxlength="35" id="fromCountry" [(ngModel)]="routingGuide.ShipFrom.fromCountry"
                                class="addressline-txtbox" />
                        </div>
                    </div>
                    <div class="ship-to w-50">
                        <div class="border">
                            <span>Ship To</span>
                        </div>
                        <div class="form-group pt-3">
                            <label for="toCity" class="pt-2 pb-2">To City</label>
                            <input pInputText maxlength="35" id="toCity" [(ngModel)]="routingGuide.ShipTo.toCity"
                                class="addressline-txtbox"/>
                        </div>
                        <div class="form-group pt-3">
                            <label for="toRegion" class="pt-2 pb-2">To Region</label>
                            <input pInputText maxlength="35" id="toRegion" [(ngModel)]="routingGuide.ShipTo.toRegion"
                                class="addressline-txtbox" />
                        </div>
                        <div class="form-group pt-3">
                            <label for="toState" class="pt-2 pb-2">To State</label>
                            <input pInputText maxlength="35" id="toState" [(ngModel)]="routingGuide.ShipTo.toState"
                                class="addressline-txtbox" />
                        </div>
                        <div class="form-group pt-3">
                            <label for="toMinZip" class="pt-2 pb-2">TO Min Zip</label>
                            <input pInputText maxlength="35" id="toMinZip" [(ngModel)]="routingGuide.ShipTo.toMinZip"
                                class="addressline-txtbox" />
                        </div>
                        <div class="form-group pt-3">
                            <label for="toMaxZip" class="pt-2 pb-2">To Max Zip</label>
                            <input pInputText maxlength="35" id="toMaxZip" [(ngModel)]="routingGuide.ShipTo.toMaxZip"
                                class="addressline-txtbox"/>
                        </div>
                        <div class="form-group pt-3">
                            <label for="toCountry" class="pt-2 pb-2">To Country</label>
                            <input pInputText maxlength="35" id="toCountry" [(ngModel)]="routingGuide.ShipTo.toCountry"
                                class="addressline-txtbox"/>
                        </div>
                    </div>
    
                </div>
                
                <div class="ship-criteria row col-lg-12 col-12 w-50 py-0">
                    <div class="border">
                        <span>Ship Criteria</span>
                    </div>
                    <div class="form-group pt-3 col-6">
                        <label for="shippingPoint" class="pt-2 pb-2">Shipping Point</label>
                        <input pInputText maxlength="35" id="shippingPoint" [(ngModel)]="routingGuide.ShipmentCritieria.shippingPoint"
                            class="addressline-txtbox"/>
                    </div>
                    <div class="form-group pt-3 col-6">
                        <label for="vendorId" class="pt-2 pb-2">Vendor ID</label>
                        <input pInputText maxlength="35" id="vendorId" [(ngModel)]="routingGuide.ShipmentCritieria.vendorId"
                            class="addressline-txtbox"/>
                    </div>
                    <div class="form-group pt-3 col-6">
                        <label for="shipToPlantId" class="pt-2 pb-2">Ship to Plant ID</label>
                        <input pInputText maxlength="35" id="shipToPlantId" [(ngModel)]="routingGuide.ShipmentCritieria.shipToPlantId"
                            class="addressline-txtbox"/>
                    </div>
                    <div class="form-group pt-3 col-6">
                        <label for="customerId" class="pt-2 pb-2">Customer ID</label>
                        <input pInputText maxlength="35" id="customerId" [(ngModel)]="routingGuide.ShipmentCritieria.customerId"
                            class="addressline-txtbox"/>
                    </div>
                    <div class="form-group pt-3 col-6">
                        <label for="serviceLevel" class="pt-2 pb-2">Service Level</label>
                        <input pInputText maxlength="35" id="servicelevel" [(ngModel)]="routingGuide.ShipmentCritieria.serviceLevel"
                            class="addressline-txtbox"/>
                    </div>
                    <div class="form-group pt-3 d-flex col-6">
                        <div class="col-3" >
                            <label for="hazmatFlag" class="pt-2 pb-2">Hazmat Flag</label>
                                <label class="hazmatflagcheckbox">
                                    <input type="checkbox" id="hazmatFlag" [(ngModel)]="routingGuide.ShipmentCritieria.hazmatFlag" >
                                    <!-- <span class="checkmark"></span> -->
                                </label>
                        </div>
                                
                        <div class="col-3">
                            <label for="FTZFlag" class="pt-2 pb-2">FTZ Flag</label>
                            <label class="hazmatflagcheckbox">
                                <input type="checkbox" id="FTZFlag" [(ngModel)]="routingGuide.ShipmentCritieria.FTZFlag" >
                                <!-- <span class="checkmark"></span> -->
                            </label>
                        </div>
                               
                    </div>
                    <div class="form-group pt-3 col-6" >
                        <label for="min-max-weight" class="pt-2 pb-2">Min/Max Weight</label>
                        <div class="d-flex col-6">
                            <input pInputText maxlength="35" id="minWeight" [(ngModel)]="routingGuide.ShipmentCritieria.minWeight"
                            class="addressline-txtbox col-3 mx-1"/>
                            <input pInputText maxlength="35" id="maxWeight" [(ngModel)]="routingGuide.ShipmentCritieria.maxWeight"
                                class="addressline-txtbox col-3 mx-1"/>

                        </div>
                        
                    </div>
                    <div class="form-group pt-3 col-6">
                        <label for="min-max-length" class="pt-2 pb-2">Min/Max Length</label>
                        <div class="d-flex col-6">
                            <input pInputText maxlength="35" id="minLength" [(ngModel)]="routingGuide.ShipmentCritieria.minLength"
                            class="addressline-txtbox col-3 mx-1"/>
                        <input pInputText maxlength="35" id="maxLength" [(ngModel)]="routingGuide.ShipmentCritieria.maxLength"
                            class="addressline-txtbox col-3 mx-1"/>
                        </div>
                       
                    </div>
                    <div class="form-group pt-3 col-6">
                        <label for="min-max-width" class="pt-2 pb-2">Min/Max Width</label>
                        <div class="d-flex col-6">
                            <input pInputText maxlength="35" id="minWidth" [(ngModel)]="routingGuide.ShipmentCritieria.minWidth"
                            class="addressline-txtbox col-3 mx-1"/>
                        <input pInputText maxlength="35" id="maxWidth" [(ngModel)]="routingGuide.ShipmentCritieria.maxWidth"
                            class="addressline-txtbox col-3 mx-1"/>
                        </div>
                        
                    </div>
                    <div class="form-group pt-3 col-6">
                        <label for="min-max-height" class="pt-2 pb-2">Min/Max Height</label>
                        <div class="d-flex col-6">
                            <input pInputText maxlength="35" id="minHeight" [(ngModel)]="routingGuide.ShipmentCritieria.minHeight"
                            class="addressline-txtbox col-3 mx-1"/>
                        <input pInputText maxlength="35" id="maxHeight" [(ngModel)]="routingGuide.ShipmentCritieria.maxHeight"
                            class="addressline-txtbox col-3 mx-1"/>

                        </div>
                        
                    </div>
                    <div class="form-group pt-3 col-6">
                        <label for="min-max-girth-height" class="pt-2 pb-2">Min/Max Girth Length</label>
                        <div class="d-flex col-6">
                            <input pInputText maxlength="35" id="minGirthHeight" [(ngModel)]="routingGuide.ShipmentCritieria.minGirthHeight"
                            class="addressline-txtbox col-3 mx-1"/>
                        <input pInputText maxlength="35" id="maxGirthHeight" [(ngModel)]="routingGuide.ShipmentCritieria.maxGirthHeight"
                            class="addressline-txtbox col-3 mx-1"/>

                        </div>
                        
                    </div>
                    <div class="form-group pt-3 col-6">
                        <label for="min-max-volume" class="pt-2 pb-2">Min/Max Volume</label>
                        <div class="d-flex col-6">
                            <input pInputText maxlength="35" id="minVolume" [(ngModel)]="routingGuide.ShipmentCritieria.minVolume"
                            class="addressline-txtbox col-3 mx-1"/>
                        <input pInputText maxlength="35" id="maxVolume" [(ngModel)]="routingGuide.ShipmentCritieria.maxVolume"
                            class="addressline-txtbox col-3 mx-1"/>

                        </div>
                        
                    </div>
                    <div class="form-group pt-3 col-6">
                        <label for="incoTerms" class="pt-2 pb-2">Inco Terms</label>
                        <input pInputText maxlength="35" id="incoTerms" [(ngModel)]="routingGuide.ShipmentCritieria.incoTerms"
                            class="addressline-txtbox"/>
                    </div>
                    <div class="form-group pt-3 col-6">
                        <label for="orderType" class="pt-2 pb-2">Order Types</label>
                        <input pInputText maxlength="35" id="orderType" [(ngModel)]="routingGuide.ShipmentCritieria.orderType"
                            class="addressline-txtbox"/>
                    </div>
                </div>


            </div>
            <div class="border">
                <div class="shipment-method-details row col-lg-12">
                    <div class="border">
                        <span>Shipment Method Details</span>
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="primaryCarrier">Primary Carrier</label>
                        <p-dropdown
                          id="primaryCarrier"
                          optionLabel="label"
                          name="primaryCarrier"
                          placeholder="Select"
                          [(ngModel)]="routingGuide.ShipmentMethodDetails.primaryCarrier"
                        ></p-dropdown>
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="primaryServiceLevel">Primary Service Level</label>
                        <p-dropdown
                          id="primaryServiceLevel"
                          optionLabel="label"
                          name="primaryServiceLevel"
                          placeholder="Select"
                          [(ngModel)]="routingGuide.ShipmentMethodDetails.primaryServiceLevel"
                        ></p-dropdown>
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="primaryTransitTime">Primary Transit Time</label>
                        <p-dropdown
                          id="primaryTransitTime"
                          optionLabel="label"
                          name="primaryTransitTime"
                          placeholder="Select"
                          [(ngModel)]="routingGuide.ShipmentMethodDetails.primaryTransitTime"
                        ></p-dropdown>
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="paymentType">Payment Type</label>
                        <p-dropdown
                          id="paymentType"
                          optionLabel="label"
                          name="paymentType"
                          placeholder="Select"
                          [(ngModel)]="routingGuide.ShipmentMethodDetails.paymentType"
                        ></p-dropdown>
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="firstLegCarrier">First Leg Carrier</label>
                        <p-dropdown
                          id="firstLegCarrier"
                          optionLabel="label"
                          name="firstLegCarrier"
                          placeholder="Select"
                          [(ngModel)]="routingGuide.ShipmentMethodDetails.firstLegCarrier"
                        ></p-dropdown>
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="firstLegServiceLevel">First Leg Service Level</label>
                        <p-dropdown
                          id="firstLegServiceLevel"
                          optionLabel="label"
                          name="firstLegServiceLevel"
                          placeholder="Select"
                          [(ngModel)]="routingGuide.ShipmentMethodDetails.firstLegServiceLevel"
                        ></p-dropdown>
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="firstLegTransitTime">First Leg Transit Time</label>
                        <p-dropdown
                          id="firstLegTransitTime"
                          optionLabel="label"
                          name="firstLegTransitTime"
                          placeholder="Select"
                          [(ngModel)]="routingGuide.ShipmentMethodDetails.firstLegTransitTime"
                        ></p-dropdown>
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="freightAccNum">Freight Acc Num</label>
                        <p-dropdown
                          id="freightAccNum"
                          optionLabel="label"
                          name="freightAccNum"
                          placeholder="Select"
                          [(ngModel)]="routingGuide.ShipmentMethodDetails.freightAccNum"
                        ></p-dropdown>
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="exportBroker">Export Broker</label>
                        <p-dropdown
                          id="exportBroker"
                          optionLabel="label"
                          name="exportBroker"
                          placeholder="Select"
                          [(ngModel)]="routingGuide.ShipmentMethodDetails.exportBroker"
                        ></p-dropdown>
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="exportBrokerService">Export Broker Service</label>
                        <p-dropdown
                          id="exportBrokerService"
                          optionLabel="label"
                          name="exportBrokerService"
                          placeholder="Select"
                          [(ngModel)]="routingGuide.ShipmentMethodDetails.exportBrokerService"
                        ></p-dropdown>
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="exportBrokerTransitTime" class="pt-2 pb-2">Export Broker Transit Time</label>
                        <input pInputText maxlength="35" id="exportBrokerTransitTime" [(ngModel)]="routingGuide.ShipmentMethodDetails.exportBrokerTransitTime"
                            class="addressline-txtbox" />
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="primaryMOT" class="pt-2 pb-2">Primary MOT</label>
                        <input pInputText maxlength="35" id="primaryMOT" [(ngModel)]="routingGuide.ShipmentMethodDetails.primaryMOT"
                            class="addressline-txtbox" />
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="importBroker">Import Broker</label>
                        <p-dropdown
                          id="importBroker"
                          optionLabel="label"
                          name="importBroker"
                          placeholder="Select"
                          [(ngModel)]="routingGuide.ShipmentMethodDetails.importBroker"
                        ></p-dropdown>
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="importBrokerServiceLevel">Import Broker Service Level</label>
                        <p-dropdown
                          id="importBrokerServiceLevel"
                          optionLabel="label"
                          name="importBrokerServiceLevel"
                          placeholder="Select"
                          [(ngModel)]="routingGuide.ShipmentMethodDetails.importBrokerServiceLevel"
                        ></p-dropdown>
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="importBrokerTransitTime" class="pt-2 pb-2">Import Broker Transit Time</label>
                        <input pInputText maxlength="35" id="importBrokerTransitTime" [(ngModel)]="routingGuide.ShipmentMethodDetails.importBrokerTransitTime"
                            class="addressline-txtbox" />
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="primaryDimfactory" class="pt-2 pb-2">Primary Dimfactory</label>
                        <input pInputText maxlength="35" id="primaryDimfactory" [(ngModel)]="routingGuide.ShipmentMethodDetails.primaryDimfactory"
                            class="addressline-txtbox" />
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="lastLegCarrier">Last Leg Carrier</label>
                        <p-dropdown
                          id="lastLegCarrier"
                          optionLabel="label"
                          name="lastLegCarrier"
                          placeholder="Select"
                          [(ngModel)]="routingGuide.ShipmentMethodDetails.lastLegCarrier"
                        ></p-dropdown>
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="lastLegServiceLevel">Last Leg Service Level</label>
                        <p-dropdown
                          id="lastLegServiceLevel"
                          optionLabel="label"
                          name="lastLegServiceLevel"
                          placeholder="Select"
                          [(ngModel)]="routingGuide.ShipmentMethodDetails.lastLegServiceLevel"
                        ></p-dropdown>
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="lastLegCarrierTransitTime" class="pt-2 pb-2">Last Leg Carrier Transit Time</label>
                        <input pInputText maxlength="35" id="lastLegCarrierTransitTime" [(ngModel)]="routingGuide.ShipmentMethodDetails.lastLegCarrierTransitTime"
                            class="addressline-txtbox" />
                    </div>
    
                </div>
                <div class="billing-details border row col-lg-12">
                    <div class="form-group pt-3 col-3">
                        <label for="billToContact" class="pt-2 pb-2">Bill To Contact</label>
                        <input pInputText maxlength="35" id="billToContact" [(ngModel)]="routingGuide.BillingDetails.billToContact"
                            class="addressline-txtbox" />
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="billToAddress1" class="pt-2 pb-2">Bill To Address1</label>
                        <input pInputText maxlength="35" id="billToAddress1" [(ngModel)]="routingGuide.BillingDetails.billToAddress1"
                            class="addressline-txtbox" />
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="billToAddress2" class="pt-2 pb-2">Bill To Address2</label>
                        <input pInputText maxlength="35" id="billToAddress2" [(ngModel)]="routingGuide.BillingDetails.billToAddress2"
                            class="addressline-txtbox" />
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="billToCity" class="pt-2 pb-2">Bill To City</label>
                        <input pInputText maxlength="35" id="billToCity" [(ngModel)]="routingGuide.BillingDetails.billToCity"
                            class="addressline-txtbox" />
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="billToState" class="pt-2 pb-2">Bill To State</label>
                        <input pInputText maxlength="35" id="billToState" [(ngModel)]="routingGuide.BillingDetails.billToState"
                            class="addressline-txtbox" />
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="billToCountry" class="pt-2 pb-2">Bill To Country</label>
                        <input pInputText maxlength="35" id="billToCountry" [(ngModel)]="routingGuide.BillingDetails.billToCountry"
                            class="addressline-txtbox" />
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="billToZip" class="pt-2 pb-2">Bill To Zip</label>
                        <input pInputText maxlength="35" id="billToZip" [(ngModel)]="routingGuide.BillingDetails.billToZip"
                            class="addressline-txtbox" />
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="billToPhone" class="pt-2 pb-2">Bill To Phone</label>
                        <input pInputText maxlength="35" id="billToPhone" [(ngModel)]="routingGuide.BillingDetails.billToPhone"
                            class="addressline-txtbox" />
                    </div>
                    <div class="form-group pt-3 col-3">
                        <label for="billToCompany" class="pt-2 pb-2">Bill To Company</label>
                        <input pInputText maxlength="35" id="billToCompany" [(ngModel)]="routingGuide.BillingDetails.billToCompany"
                            class="addressline-txtbox" />
                    </div>
                </div>
            </div>
             <!-- Footer Seaction Starts here -->
    <div class="footer-dialog-css">
        <div class="grid m-0 pt-8 pb-10" *ngIf="isFooterButtonEnable">
            <div class="col p-0">
                <!-- <button type="button" class="btn-common-css btn-ship-cancel" (click)="closeShipScreen()"
                    label="Cancel">Cancel</button> -->
            </div>
            <div class="col p-0 text-center">
                <button type="button"  class="btn-get">Get Shipping Rates</button>
                <button type="button" class="ml-3 btn-get">Buy Shipping Label</button>
            </div>
            <div class="col p-0">
                <!-- <button type="button" class="btn-save" (click)="Save()">Save</button> -->

                <p-splitButton label="{{saveButtontitle}}" class="btnSave"
                    [model]="saveOptions" (onClick)="onSave()" />Save

            </div>
        </div>
    </div>
            
        </div>
       
    </div>


   
</div>

<app-address-book-popup #popup (onSelect)="handleAddressSelect($event)" (onClose)="handlePopupClose()">


</app-address-book-popup>
<app-warning-popup></app-warning-popup>

<script>

</script>
 
