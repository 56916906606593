import { Component, OnInit,ViewChild  } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ShipNowService } from '../../../../services/ship-now/ship-now.service';
import { ShipNowPackComponent } from '../ship-now-pack.component';
@Component({
  selector: 'app-pack-info',
  templateUrl: './pack-info.component.html',
  styleUrl: './pack-info.component.scss'
})
export class PackInfoComponent implements OnInit{
  @ViewChild(ShipNowPackComponent) packship!: ShipNowPackComponent;
  constructor(
    private ref: DynamicDialogRef,
    private shipNowService: ShipNowService,
    private config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    // if(this.config.data) {
      setTimeout(()=>{
        this.shipNowService.getpaccurateinfo(this.config.data).subscribe(() => {});
      },2000);
    // }
  }

  onContinue() {
   
      this.ref.close({ pack: true });
    
  }

  onCancel() {
    this.ref.close();
  }

}
