import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ViewEncapsulation,ViewChild, SimpleChanges } from '@angular/core';
import { ShipToFromComponent } from '../pages/dashboard/ship-to-from/ship-to-from.component';
import { WarningPopupComponent } from '../popup/warning-popup/warning-popup.component';
import { GetAllDataService } from '../services/ship-now/get-all-data.service';
import { ShipNowService } from '../services/ship-now/ship-now.service';
import { MessageService } from 'primeng/api';
import { StorageService } from '../services/storage.service';
import { ERPSHIPService } from '../erpship.service';
import { SharedService } from '../services/ship-now/shared.service';
import { ShipNowPackComponent } from '../pages/dashboard/ship-now-pack/ship-now-pack.component';
import { RoutingBillingDetailComponent } from '../pages/dashboard/routing-billing-detail/routing-billing-detail.component';
import { DashboardHeaderComponent } from '../pages/dashboard/dashboard-header/dashboard-header.component';
import { UserDataService } from '../services/user-data.service';
import { ShipRequestToShipNowService } from '../services/ship-now/shiprequesttoshipnow.service';
import { ColumnConfigService } from '../services/column-config-service';
import { retry } from 'rxjs';
import {CookieService} from 'ngx-cookie-service';

import { OverlayPanel } from 'primeng/overlaypanel';
import { ConfirmPopupComponent } from '../popup/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-ship-now',
  templateUrl: './ship-now.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./ship-now.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ShipNowComponent {
  @ViewChild(ShipToFromComponent) shipToFromComponent: ShipToFromComponent;
  @ViewChild(ShipNowPackComponent) shipNowPackComponent: ShipNowPackComponent;
  @ViewChild(RoutingBillingDetailComponent)
  RoutingBillingComponent: RoutingBillingDetailComponent;
  @ViewChild(DashboardHeaderComponent)
  dashboardHeaderComponent: DashboardHeaderComponent;
  @ViewChild('ot') overlayPanel: OverlayPanel;

  @ViewChild(WarningPopupComponent) warningPopup: WarningPopupComponent;
  @ViewChild(ConfirmPopupComponent) confirmPopup: ConfirmPopupComponent;
  @ViewChild('fsdt') freightdt: any;
  showWarning: boolean = false;
  warningMessage: string = '';
  selectedOrder: any;
  pickupDate: any;
  shipDate: any;
  selectedConnection: any;
  connections: Array<any> = [
    { id: 1, name: 'd365' },
    { id: 2, name: 'Salesforce' },
    { id: 3, name: 'snowflake' },
  ];
  selectedFilter: any;
  filters: Array<any> = [
    { id: 1, name: 'Fedex' },
    { id: 2, name: 'BlueDart' },
    { id: 3, name: 'Delihvery' },
  ];
  inputSearch: string = '';
  clearShip: any = {};
  shipment: any = {}; //super master data
  Original_LocationMasterData: any;
  carrierAccounts: any[] = [];
  Userid: string = '';
  Password: string = '';
  trackingStatusFlag: boolean = false;

  refreshFlag = false;
  FreightShop_dialog: boolean = false;
  FreightShopResult: any = [];
  selectedFreightProduct: any = {};
  userContext: any;
  carrierServices: any = [];
  selectAllTabs: boolean = false;
  isFooterEnabled: boolean = true;
  locationId: any;
  isShippedRecord: boolean = false;
  voidPopUpVisible: boolean = false;
  voidPayload: any = {};
  dataFromShipRequest: any = {};
  constructor(
    private cdr: ChangeDetectorRef,
    private xfservice: ERPSHIPService,
    private dataService: GetAllDataService,
    private shipNowService: ShipNowService,
    private messageService: MessageService,
    private storageService: StorageService,
    private sharedService: SharedService,
    private userdata: UserDataService,
    private rowDataFromShipRequest: ShipRequestToShipNowService,
    private columnConfigService: ColumnConfigService,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.locationId = JSON.parse(this.cookieService.get('role'))?.LocationId;
    this.userContext = this.userdata.getDecryptedCookie('usercontext');
    if (this.userContext !== undefined && this.userContext.body.user_id) {
      // this.saveProfile();
    }
    this.sharedService.trackingStatus$.subscribe((trackingStatus) => {
      if (trackingStatus === 'Shipped') {
        this.trackingStatusFlag = true;
      } else {
        this.trackingStatusFlag = false;
      }
    });
    this.sharedService.fromUrlSubject$.subscribe((fromUrlSubject) => {
      if (fromUrlSubject) {
        if (fromUrlSubject === 'shiprequest') {
          this.selectedTabIndex = 3;
        }
      }
    });
    this.xfservice.getLocationMasterData().subscribe((data) => {
      this.Original_LocationMasterData = JSON.parse(JSON.stringify(data));
      this.carrierAccounts = this.Original_LocationMasterData?.carrieraccounts;
      this.carrierServices = this.Original_LocationMasterData?.carrierservices;
    });
    const savedTabIndex: any = this.storageService.getItem('selectedTabIndex');
    this.selectedTabIndex = savedTabIndex ? parseInt(savedTabIndex, 10) : 0;
    this.selectedFilter = this.filters[0];

    this.shipment = {
      HeaderInfo: {
        FeederSystem: '',
        DocumentNumber: '',
        DocumentType: '',
        ShipDate: '',
        CreatedDate: '',
        ShipmentType: '',
        Location: '',
        ERP: '',
        TotalWeight: '',
      },
      Shipper: {
        COMPANY: '',
        CONTACT: '',
        ADDRESS_LINE1: '',
        address_line2: '',
        address_line3: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        phone: '',
        email: '',
      },
      ShipTo: {
        COMPANY: '',
        CONTACT: '',
        ADDRESS_LINE1: '',
        address_line2: '',
        address_line3: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        phone: '',
        email: '',
        TAXID: '',
      },
      ShipFrom: {
        COMPANY: '',
        CONTACT: '',
        ADDRESS_LINE1: '',
        address_line2: '',
        address_line3: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        phone: '',
        email: '',
      },
      SoldTo: {
        COMPANY: '',
        CONTACT: '',
        ADDRESS_LINE1: '',
        ADDRESS_LINE2: '',
        ADDRESS_LINE3: '',
        CITY: '',
        STATE: '',
        ZIPCODE: '',
        COUNTRY: '',
        PHONE: '',
        EMAIL: '',
      },
      CarrierDetails: {
        Carrier: '',
        ServiceName: '',
        PaymentType: '',
        ShippingAccount: '',
        BillingAccount: '',
        BillingCountry: '',
        BillingZipCode: '',
        Reference1: '',
        Reference2: '',
        Reference3: '',
        Reference4: '',
        Note: '',
        UserId: '',
        Password: '',
        AccessKey: '',
      },
      Items: [],
      Packages: [
        {
          ItemsInfo: [
            {
              ItemNo: '',
              ProductNo: '',
              Description: '',
              IsDG: null,
              UnitCost: '',
              UnitWeight: '',
              Dimension: '',
              HTSCode: '',
              ECCN: '',
              UN: '',
              Class: '',
              NMFC: '',
              Category: '',
              IsSerial: null,
              IsBatch: null,
              IsStackable: null,
              IsActive: true,
              LocationId: '',
              id: 1,
              Sno: 1,
              Quantity: '',
              Partial: '',
              Balance: '',
            },
          ],
          SpecialServices: {},
          Weightunits: '',
          DimensionUnits: '',
          Sno: 1,
          HU: null,
          Weight: '',
          Dimension: '',
          PackageSpecialServiceTyeps: [],
        },
      ],
      ShipmentLevelServices: {},
      InternationalDetails: {
        ItemInfo: [
          {
            ItemNo: '',
            ProductNo: '',
            Description: '',
            IsDG: null,
            UnitCost: '',
            UnitWeight: '',
            Dimension: '',
            HTSCode: '',
            ECCN: '',
            UN: '',
            Class: '',
            NMFC: '',
            Category: '',
            IsSerial: null,
            IsBatch: null,
            IsStackable: null,
            IsActive: null,
            LocationId: '',
            id: 1,
            Sno: 1,
            Quantity: '',
            Partial: '',
            Balance: '',
          },
        ],
      },
      id: 86,
      ShipmentSpecialServiceTyeps: [],
    };
    // this.triggerRefresh()
    let dataFromShipRequest = this.rowDataFromShipRequest.getShipFromData(),
      dataFromShipRequestCarrierDetails =
        dataFromShipRequest?.CarrierDetails ?? {};
    this.dataFromShipRequest = dataFromShipRequest;
    this.isFooterEnabled =
      dataFromShipRequest?.isFooterEnabled ?? this.isFooterEnabled;
    dataFromShipRequest.isFooterEnabled = true;
    this.rowDataFromShipRequest.setShipFromData(dataFromShipRequest);
    let fromURL = this.rowDataFromShipRequest.getFromURLData();
    this.isShippedRecord = !(
      dataFromShipRequest?.isOpenStatus ?? dataFromShipRequest?.isFooterEnabled
    );
    this.isFooterEnabled = !this.isShippedRecord;

    this.voidPayload = this.isShippedRecord
      ? this.constructVoidPayload(
          dataFromShipRequestCarrierDetails,
          dataFromShipRequest
        )
      : {};
    if (fromURL === 'shiprequest') {
      this.selectedTabIndex = 3;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['shipment'] && this.shipment) {
    }
  }
  selectedTabIndex = 0;
  ConfigKey='shipnow'

  mandatoryTabs=['Ship From/To','Routing & Billing Details','Pack','Additional Info','International Details','Charges','Documents','Track']
  tabs = [
    {
      header: 'Ship From/To',
      label: 'Ship From/To',
      content: 'ship-to-from',
      visible: true,
      default: true,
    },
    {
      header: 'Routing & Billing Details',
      label: 'Routing & Billing Details',
      content: 'routing-billing-detail',
      visible: true,
      default: true,
    },
    {
      header: 'Additional Info',
      label: 'Additional Info',
      content: 'additional-info',
      visible: true,
      default: true,
    },
    { header: 'Pack', label: 'Pack', content: 'ship-now-pack',  visible: true, default: true },
    {
      header: 'International Details',
      label: 'International Details',
      content: 'international-details',
      visible: true,
      default: true,
    },
    { header: 'DG', label: 'DG', content: 'dg',  visible: true, default: false },
    { header: 'Charges', label: 'Charges', content: 'charges',  visible: true, default: true },
    { header: 'Documents', label: 'Documents', content: 'documents',  visible: true, default: true },
    { header: 'Track', label: 'Track', content: 'track',  visible: true, default: false },
    { header: 'AutoPack', label: 'AutoPack', content: 'AutoPack', visible: false, default: false },
    { header: 'AutoScan', label: 'AutoScan', content: 'AutoScan', visible: false, default: false },
  ];

  drop(event: CdkDragDrop<string[]>) {
    const prevIndex = event.previousIndex;
    const newIndex = event.currentIndex;

    // Move the item in the array
    moveItemInArray(this.tabs, prevIndex, newIndex);

    // Update the selected tab index
    this.selectedTabIndex = newIndex;
  }

  // Method to handle the data received from the child component
  receiveData(data:any) {
    this.selectedOrder = data;
    try{
      let carrierDetails = data?.CarrierDetails || {},
        packages = data?.Packages,
        status = data?.HeaderInfo?.Status || data.TrackingStatus;
      packages = packages?.length && packages[0];
      this.isShippedRecord = (status == "Shipped");
      this.isFooterEnabled = !this.isShippedRecord;
      this.voidPayload = this.constructVoidPayload(carrierDetails, packages);
    }catch(err){
      console.log(err);
    }
  }

  onTabChange(value) {
    this.selectedTabIndex = value;
  }

  validateForm(formValues: any): boolean {
    if (!formValues?.ShipTo?.COUNTRY) {
      this.warningPopup.showWarning('Please Enter Ship To Country');
      return false;
    }
    if (!formValues?.ShipTo?.ADDRESS_LINE1) {
      this.warningPopup.showWarning('Please Enter Address Line 1');
      return false;
    }
    if (!formValues?.ShipTo?.CITY) {
      this.warningPopup.showWarning('Please Enter Ship To City');
      return false;
    }
    if (!formValues?.ShipTo?.ZIPCODE) {
      this.warningPopup.showWarning('Please Enter Ship To ZipCode');
      return false;
    }
    if (!formValues?.ShipTo?.STATE) {
      this.warningPopup.showWarning('Please Enter Ship To State');
      return false;
    }
    if (!formValues?.ShipTo?.COMPANY) {
      this.warningPopup.showWarning('Please Enter Company Name');
      return false;
    }
    if (!formValues?.CarrierDetails?.Carrier) {
      this.warningPopup.showWarning('Please select Ship Method');
      return false;
    }
    if (!formValues?.HeaderInfo?.documentNumber) {
      this.warningPopup.showWarning('Enter Delivery/Shipment #');
      return false;
    }
    //   for (let i = 0; i < formValues.Packages.length; i++){
    //   if (!formValues.Packages[i].Weight) {
    //     this.warningPopup.showWarning('Enter Package Weight');
    //     return false;
    //   }
    // }
    return true;
  }

  onSubmit() {
    const formValues1 = this.dashboardHeaderComponent.getFormValues();
    const formValues2 = this.shipToFromComponent.getFormValues();
    const formValues3 = this.RoutingBillingComponent.getFormValues();
    const formValues4 = this.shipNowPackComponent.getFormValues();
    var res = {
      HeaderInfo: formValues1?.HeaderInfo,
      ShipTo: formValues2?.ShipTo,
      ShipFrom: formValues2?.ShipFrom,
      CarrierDetails: formValues3?.CarrierDetails,
      Items: formValues4?.Items,
      Packages: formValues4?.Packages,
      InternationalDetails:
        formValues2?.InternationalDetails ||
        formValues3?.InternationalDetails ||
        formValues4?.InternationalDetails,
    };
    if (res) {
      this.showWarning = true;

      // Validate form values
      const isValid = this.validateForm(res);

      if (isValid) {
        // If form values are valid, proceed with the API call
        this.makeAPICall();
      } else {
        // If form values are not valid, set showWarning to false
        this.showWarning = false;
      }
    }
  }

  // collect ALl Data from Tabs

  makeAPICall() {
    if (this.trackingStatusFlag) {
      this.warningPopup.showWarning('Shipment is already proccessed for this order!!!');
      return;
    }
    const allData = this.dataService.getAllData();
    let dataFromShipRequest = this.rowDataFromShipRequest.getShipFromData();
    let packageClass = '',
      accessKey = '',
      shipFromAddr = {
        CITY: 'Harrison',
        STATE: 'AR',
        ZIPCODE: '72601',
      };
    // Extract data from the service
    const {
      dashboardData,
      shipToFromData,
      routingAndBillingData,
      packNowData,
    } = allData;
    const todayDate = new Date().toISOString();
    let billingAcc = '',
      shipAcc = '';
    (this.Userid = 'l70c717f3eaf284dc9af42169e93874b6e'),
      (this.Password = '7f271bf486084e8f8073945bb7e6a020');
   
      this.carrierAccounts.forEach((xitem) => {
        if (xitem.CarrierName == 'FedEx' || xitem.CarrierName == 'UPS' || xitem.CarrierName == 'ABF') {
          this.Userid = xitem.UserId;
          this.Password = xitem.Password;
          if(xitem.CarrierName == 'FedEx'){
            shipAcc = routingAndBillingData?.CarrierDetails?.Carrier?.CarrierName ==
            'FedEx Freight' ? xitem.Account : "";
          }
        }
        if (xitem.CarrierName == 'RL') {
          accessKey = xitem.AccessKey || '';
        }
        if (xitem.CarrierName == 'FedEx Freight') {
          billingAcc = routingAndBillingData?.CarrierDetails?.Carrier?.CarrierName ==
          'FedEx Freight' ? xitem.Account : "";
        }
      });
    if (routingAndBillingData?.CarrierDetails?.Carrier?.CarrierName == 'ABF' || routingAndBillingData?.CarrierDetails?.Carrier?.CarrierName == 'RL') {
      packageClass = '50';
    }
    let payload: any = {};

    payload = {
      HeaderInfo: {
        FeederSystem: dashboardData?.FeederSystem ?? '',
        DocumentNumber: dashboardData?.DocumentNumber ?? '',
        DocumentType: dashboardData?.DocumentType ?? '',
        ShipDate: todayDate,
        CreatedDate: todayDate,
        ShipmentType: dashboardData?.ShipmentType ?? '',
        Location: dashboardData?.Location ?? '',
        ERP: dashboardData?.ERP ?? '',
        TotalWeight: dashboardData?.TotalWeight ?? '',
      },
      Shipper: {
        COMPANY: shipToFromData?.ShipFrom?.COMPANY ?? null,
        CONTACT: shipToFromData?.ShipFrom?.CONTACT ?? null,
        ADDRESS_LINE1: shipToFromData?.ShipFrom?.ADDRESS_LINE1 ?? null,
        ADDRESS_LINE2: shipToFromData?.ShipFrom?.ADDRESS_LINE2 ?? null,
        ADDRESS_LINE3: shipToFromData?.ShipFrom?.ADDRESS_LINE3 ?? null,
        CITY: shipToFromData?.ShipFrom?.CITY ?? null,
        STATE: shipToFromData?.ShipFrom?.STATE ?? null,
        ZIPCODE: shipToFromData?.ShipFrom?.ZIPCODE ?? null,
        COUNTRY: shipToFromData?.ShipFrom?.COUNTRY ?? null,
        PHONE: shipToFromData?.ShipFrom?.PHONE ?? null,
        EMAIL: shipToFromData?.ShipFrom?.EMAIL ?? null,
      },
      ShipTo: {
        COMPANY: shipToFromData?.ShipTo?.COMPANY ?? null,
        CONTACT: shipToFromData?.ShipTo?.CONTACT ?? null,
        ADDRESS_LINE1: shipToFromData?.ShipTo?.ADDRESS_LINE1 ?? null,
        ADDRESS_LINE2: shipToFromData?.ShipTo?.ADDRESS_LINE2 ?? 'test',
        ADDRESS_LINE3: shipToFromData?.ShipTo?.ADDRESS_LINE3 ?? null,
        CITY: shipToFromData?.ShipTo?.CITY ?? null,
        STATE: shipToFromData?.ShipTo?.STATE ?? null,
        ZIPCODE: shipToFromData?.ShipTo?.ZIPCODE ?? null,
        COUNTRY: shipToFromData?.ShipTo?.COUNTRY ?? null,
        PHONE: shipToFromData?.ShipTo?.PHONE ?? null,
        EMAIL: shipToFromData?.ShipTo?.EMAIL ?? null,
        TAXID: shipToFromData?.ShipTo?.TAXID ?? null,
      },
      ShipFrom: {
        COMPANY: shipToFromData?.ShipFrom?.COMPANY ?? null,
        CONTACT: shipToFromData?.ShipFrom?.CONTACT ?? null,
        ADDRESS_LINE1: shipToFromData?.ShipFrom?.ADDRESS_LINE1 ?? null,
        ADDRESS_LINE2: shipToFromData?.ShipFrom?.ADDRESS_LINE2 ?? 'test',
        ADDRESS_LINE3: shipToFromData?.ShipFrom?.ADDRESS_LINE3 ?? null,
        CITY: shipToFromData?.ShipFrom?.CITY ?? null,
        STATE: shipToFromData?.ShipFrom?.STATE ?? null,
        ZIPCODE: shipToFromData?.ShipFrom?.ZIPCODE ?? null,
        COUNTRY: shipToFromData?.ShipFrom?.COUNTRY ?? null,
        PHONE: shipToFromData?.ShipFrom?.PHONE ?? null,
        EMAIL: shipToFromData?.ShipFrom?.EMAIL ?? null,
      },
      SoldTo: {
        COMPANY: '',
        CONTACT: '',
        ADDRESS_LINE1: '',
        ADDRESS_LINE2: '',
        ADDRESS_LINE3: '',
        CITY: '',
        STATE: '',
        ZIPCODE: '',
        COUNTRY: '',
        PHONE: '',
        EMAIL: '',
      },
      Items: dataFromShipRequest?.Items,
      Packages:
        packNowData?.map((pkg) => ({
          ItemsInfo:
            pkg?.ItemsInfo?.map((item) => ({
              ItemNo: item?.ItemNo || item?.product || '',
              ProductNo: item?.product ?? '',
              Description: item?.description ?? '',
              IsDG: item?.isDG ?? null,
              UnitCost: item?.unitCost ?? '',
              UnitWeight: item?.unitWeight || pkg?.Weight || '',
              Dimension: pkg?.dimension ?? '',
              HTSCode: item?.HTSCode ?? '',
              ECCN: item?.ECCN ?? '',
              UN: item?.UN ?? '',
              Class: item?.class || packageClass || 50,
              NMFC: item?.NMFC ?? '',
              Category: item?.Category ?? '',
              IsSerial: item?.IsSerial ?? null,
              IsBatch: item?.IsBatch ?? null,
              IsStackable: item?.IsStackable ?? null,
              IsActive: item?.IsActive ?? true,
              LocationId: item?.LocationId ?? '',
              id: item?.id ?? 1,
              Sno: item?.Sno ?? 1,
              Quantity: item?.totalQuantity ?? '',
              Partial: item?.partialQuantity ?? '',
              Balance: item?.balanceQuantity ?? '',
            })) ?? [],
          SpecialServices: pkg?.SpecialServices ?? {},
          Weightunits: pkg?.Weightunits || 'LBS',
          DimensionUnits: pkg?.DimensionUnits || 'IN' || '',
          Sno: pkg?.Sno ?? 1,
          HU: pkg?.HU ?? null,
          Weight: pkg?.Weight ?? '',
          Dimension: pkg?.dimension ?? '',
          PackageSpecialServiceTyeps: pkg?.PackageSpecialServiceTyeps ?? [],
        })) ?? [],
      ShipmentLevelServices: packNowData?.ShipmentLevelServices ?? {},
      id: packNowData?.id ?? 86,
      ShipmentSpecialServiceTyeps:
        packNowData?.ShipmentSpecialServiceTyeps ?? [],
      InternationalDetails: {
        ItemInfo: [
          {
            ItemNo: '',
            ProductNo: '',
            Description: '',
            IsDG: null,
            UnitCost: '',
            UnitWeight: '',
            Dimension: '',
            HTSCode: '',
            ECCN: '',
            UN: '',
            Class: '',
            NMFC: '',
            Category: '',
            IsSerial: null,
            IsBatch: null,
            IsStackable: null,
            IsActive: true,
            LocationId: this.locationId,
            id: 1,
            Sno: 1,
            Quantity: null,
            Partial: 1,
            Balance: null,
            UOM: null,
          },
        ],
        termsofShipment: '',
        reasonforExport: '',
        customDecVal: '',
        documentsContentType: '',
        iNTDutiesTaxes: '',
        dutiesAccountnumber: '',
        bookingConfirmnumber: '',
        B13AFilingOption: '',
        Permitnumber: '',
        ITN: '',
        compliStatement: '',
        payorCountryCode: '',
      },
    };
    const CarrierDetails = {
      Carrier:
        routingAndBillingData?.CarrierDetails?.Carrier?.CarrierName ?? '',
      ServiceName: routingAndBillingData?.CarrierDetails?.ServiceName ?? '',
      PaymentType:
        routingAndBillingData?.CarrierDetails?.PaymentType?.value ?? '',
      ShippingAccount:
        shipAcc || routingAndBillingData?.CarrierDetails?.ShippingAccount || '',
      BillingAccount:
        routingAndBillingData?.CarrierDetails?.BillingAccount || billingAcc,
      BillingCountry:
        routingAndBillingData?.CarrierDetails?.BillingCountry ?? '',
      BillingZipCode:
        routingAndBillingData?.CarrierDetails?.BillingZipCode ?? '',
      Reference1: routingAndBillingData?.CarrierDetails?.Reference1 ?? '',
      Reference2: routingAndBillingData?.CarrierDetails?.Reference2 ?? '',
      Reference3: routingAndBillingData?.CarrierDetails?.Reference3 ?? '',
      Reference4: routingAndBillingData?.CarrierDetails?.Reference4 ?? '',
      Note: routingAndBillingData?.CarrierDetails?.Note ?? '',
      UserId: this.Userid, //"l70c717f3eaf284dc9af42169e93874b6e",
      Password: this.Password, //"7f271bf486084e8f8073945bb7e6a020",
      AccessKey: routingAndBillingData?.CarrierDetails?.AccessKey || accessKey,
    };
    payload = { ...payload, CarrierDetails };
    if (this.autoPackEnabled) {
      if (dataFromShipRequest.Packages.length === 0) {
        this.packAllItems(dataFromShipRequest);

        return;
      }
    }
    // If both aut    console.log(this.autoPackEnabled);
    if (this.validateData(payload)) {
      // If autoScan is enabled, call autoScan()
      // Make the first API call based on Carrier
      let carrierName = payload.CarrierDetails.Carrier;
      if (payload.CarrierDetails.Carrier === 'FedEx Freight') {
        payload.ShipFrom = { ...payload.ShipFrom, ...shipFromAddr };
        carrierName = 'FedExFreight';
      }
      this.shipNowService.sendCarrierRequest(payload,carrierName).subscribe({
        next: (response) => {
          // Check if the status is 'Error'
          if (response.status === 'Error') {
            const errorMessage = response.Errors
              ? response.Errors.join(', ')
              : 'Unknown error occurred.';
            this.warningPopup.showWarning(errorMessage);
            return; // Exit the function without making the second API call
          }

          // If no error, proceed to update the status and make the second API call
          this.prepareAndSendShipRequest(response);
        },
        error: (err) => {
          console.error('Error in FedEx API call:', err);
          this.warningPopup.showWarning(`Error processing ${carrierName} request. Please try again.`);
        },
      });
    }
  }

  // Helper function to prepare response and send the second API request
  prepareAndSendShipRequest(response: any, givenStatus = '') {
    console.log(response);
    if (response.HeaderInfo) {
      response.HeaderInfo.Status = givenStatus || 'Shipped';
      response.HeaderInfo.CreatedUser = this.userContext.body.user_id;
    } else {
      response.HeaderInfo = {
        Status: givenStatus || 'Shipped',
        CreatedUser: this.userContext.body.user_id,
      };
    }

    this.shipNowService.sendShipRequestCarrier(response).subscribe({
      next: (shipRequestResponse) => {
       // this.warningPopup.showWarning('Shipment processed successfully!');
        window.location.reload(); // Refresh the component or reload the page
      },
      error: (err) => {
        console.error('Error in Ship Request API call:', err);
        this.warningPopup.showWarning('Error processing ship request. Please try again.');
      },
    });
  }
  products: any = [];
  idforHU: any;
  shipmentData;
  packAllItems(shipmentData): void {
    this.products = shipmentData.Items;
    // Convert weight to the common unit and calculate the total weight
    const totalWeight = this.products.reduce((sum, item) => {
      const unitWeight = parseFloat(item.UnitWeight || '0');
      return sum + unitWeight;
    }, 0);

    // Get dimension units from the first valid product, or default if not present
    const dimensionUnits =
      this.products.length > 0 ? this.products[0].UnitWeight : '';

    // Prepare the payload
    const payload = {
      ItemsInfo: this.products.map((product) => ({
        ...product,
        partialQuantity: product.Quantity,
        totalQuantity: product.Quantity,
        balanceQuantity: 0,
      })),
      SpecialServices: {},
      Weightunits: totalWeight.toFixed(2), // Set the total weight, rounded to 2 decimal places
      DimensionUnits: dimensionUnits, // Use dimension units from the first product
      id: this.idforHU,
      IsInternal: true,
    };

    this.shipNowService.packAllItems(payload).subscribe(
      (response) => {
        let res = response;
        if (res && res.ItemsInfo) {
          // Mapping ItemsInfo from response to package structure
          // const newPackages = res.ItemsInfo.map(item => ({
          //   ItemsInfo: [{
          //     ItemNo: '',  // Update with your logic
          //     ProductNo: item.ProductNo,
          //     product:item.ProductNo,
          //     partialQuantity :item.partialQuantity,
          //     totalQuantity : item.totalQuantity,
          //     Description: item.Description || '',  // Map Description if available
          //     IsDG: false,
          //     UnitCost: '',  // Map UnitCost if needed
          //     UnitWeight: item.UnitWeight,
          //     Dimension: item.Dimension || '',  // Map Dimension if available
          //     HTSCode: '',
          //     ECCN: '',
          //     UN: '',
          //     Class: '',
          //     NMFC: '',
          //     Category: '',
          //     IsSerial: null,
          //     IsBatch: null,
          //     IsStackable: null,
          //     IsActive: true,
          //     LocationId: '',
          //     id: 1,
          //     Sno: 1,
          //     Quantity: item.totalQuantity,
          //     Partial: item.partialQuantity,
          //     Balance: 0
          //   }],
          //   SpecialServices: {},
          //   Weightunits: item.UnitWeight,
          //   DimensionUnits: res.DimensionUnits,
          //   Sno: 1,
          //   HU: res.HU,  // Example HU, map as needed
          //   Weight: totalWeight.toString(),
          //   Dimension: item.Dimension || '',  // Add dimension from response if available
          //   PackageSpecialServiceTyeps: []
          // }));
          const aggregatedItems = res.ItemsInfo.map((item, index) => ({
            ItemNo: '', // Update with your logic
            ProductNo: item.ProductNo,
            product: item.ProductNo,
            partialQuantity: item.partialQuantity,
            totalQuantity: item.totalQuantity,
            Description: item.Description || '', // Map Description if available
            IsDG: false,
            UnitCost: '', // Map UnitCost if needed
            UnitWeight: item.UnitWeight,
            Dimension: item.Dimension || '', // Map Dimension if available
            HTSCode: '',
            ECCN: '',
            UN: '',
            Class: '',
            NMFC: '',
            Category: '',
            IsSerial: null,
            IsBatch: null,
            IsStackable: null,
            IsActive: true,
            LocationId: '',
            id: index + 1,
            Sno: index + 1,
            Quantity: item.totalQuantity,
            Partial: item.partialQuantity,
            Balance: 0,
          }));

          const newPackages: any = {
            ItemsInfo: aggregatedItems, // Single array of items
            SpecialServices: {}, // Use the original SpecialServices or add your logic
            Weightunits: res.Weightunits, // Use total weight if needed
            DimensionUnits: res.DimensionUnits, // Use original DimensionUnits
            Sno: 1, // Example Sno, update as needed
            HU: res.HU, // Use original HU value
            Weight: aggregatedItems
              .reduce((total, item) => total + item.UnitWeight, 0)
              .toString(), // Calculate total weight
            Dimension: aggregatedItems
              .map((item) => item.Dimension)
              .join(' | '), // Combine dimensions if needed
            PackageSpecialServiceTypes: [], // Use original or customize
          };
          // Append the new packages to the existing Packages
          // shipmentData.Packages = [...shipmentData.Packages, ...newPackages];
          shipmentData.Packages.push(newPackages);
          // Ensure Items (products) stay the same unless specifically updated
          delete shipmentData.Items;
          this.shipmentData = shipmentData;
          this.sharedService.setShipmentData(shipmentData);
          if (this.autoScanEnabled) {
            payload;
            this.autoScanItems(this.shipmentData);
            this.sharedService.setAutoscan(this.autoScanEnabled);
          }
        }
        return;
        // Show success toast
        //  this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Items packed successfully!', life: 3000 });
      },
      (error) => {
        // Show error toast
      }
    );
  }

  autoScanItems(payload) {
    if (this.validateData(payload)) {
      // If autoScan is enabled, call autoScan()
      // Make the first API call based on Carrier
      let carrierName = payload.CarrierDetails.Carrier;
      if (payload.CarrierDetails.Carrier === 'FedEx Freight') {
        carrierName = 'FedExFreight';
      }
        this.shipNowService.sendCarrierRequest(payload,carrierName).subscribe({
          next: (response) => {
            // Check if the status is 'Error'
            if (response.status === 'Error') {
              const errorMessage = response.Errors
                ? response.Errors.join(', ')
                : 'Unknown error occurred.';
              this.warningPopup.showWarning(errorMessage);
              return; // Exit the function without making the second API call
            }

            // If no error, proceed to update the status and make the second API call
            this.prepareAndSendShipRequest(response);
          },
          error: (err) => {
            console.error('Error in FedEx API call:', err);
            this.warningPopup.showWarning(`Error processing ${carrierName} request. Please try again.`);
          },
        });
    }
  }

  validateData(data: any,checkServiceName = false): boolean {
    const errorMessages: string[] = [];

    // Validate ServiceName
    if (
      (Array.isArray(data?.CarrierDetails) && data?.CarrierDetails?.length == 0) ||
      (!Array.isArray(data?.CarrierDetails) && !(data?.CarrierDetails?.ServiceName || checkServiceName))
    ) {
      errorMessages.push(
        'Please select a service type from routing and billing details.'
      );
      this.displayErrorsSequentially(errorMessages);
      return false;
    }

    // Check if Packages is empty
    if (!data.Packages || data.Packages.length === 0) {
      errorMessages.push('Please create a package in the packs tab.');
      this.displayErrorsSequentially(errorMessages);
      return false;
    }

    // Validate each package
    for (let i = 0; i < data.Packages.length; i++) {
      const pkg = data.Packages[i];

      // Validate Weight
      if (
        !pkg?.Weight ||
        isNaN(Number(pkg.Weight)) ||
        Number(pkg.Weight) <= 0
      ) {
        errorMessages.push(`Please enter a valid weight for package ${i + 1}.`);
        this.displayErrorsSequentially(errorMessages);
        return false;
      }
    }

    // If all validations pass
    return true;
  }

  triggerRefresh() {
    this.refreshFlag = !this.refreshFlag; // Toggle to trigger change detection
  }
  displayErrorsSequentially(messages: string[]): void {
    if (messages.length === 0) return;

    let index = 0;

    const showNextMessage = () => {
      if (index >= messages.length) return;

      this.warningPopup.showWarning(messages[index]);
      index++;
      setTimeout(showNextMessage, 3000); // Adjust timeout as needed
    };

    showNextMessage();
  }

  applyFilterGlobal_FreightShop($event: any, stringVal: any): void {
    this.freightdt.filterGlobal(
      ($event.target as HTMLInputElement).value,
      stringVal
    );
  }
  assigneServiceFromFreightshop(data: any) {
    this.shipNowService.freightShopRowData.next(data);
    this.FreightShop_dialog = false;
  }
  showSettingsMenu = false;

  toggleSettingsMenu() {
    this.showSettingsMenu = !this.showSettingsMenu;
  }
  showSettingsDialog = false;

  // Close the settings dialog
  closeSettingsDialog() {
    this.showSettingsDialog = false;
  }

  // Toggle all tabs' visibility based on "Select All" checkbox
  toggleSelectAllTabs() {
    this.tabs.forEach((tab) => {
      if (!tab.default) {
        tab.visible = this.selectAllTabs;
      }
    });

    this.checkSelectAllState(); // Re-check "Select All" checkbox state if needed
  }
  // Temporary state for tab visibility
  tempTabs: any[] = [];

  // Update the checkbox state without modifying the actual tabs
  onTabVisibilityChange(checked: boolean, index: number): void {
    const tab = this.tempTabs[index]; // Work with tempTabs instead of tabs
    tab.visible = checked; // Set visibility based on the checkbox state
    this.checkSelectAllState(); // Update the "Select All" checkbox state if needed
  }

  // Apply filter to update the actual tabs array
  applyFilter() {
    const allUnchecked = this.tempTabs.every((tab) => !tab.visible);

    if (allUnchecked) {
      this.resetToDefaultFilters(); // If all are unchecked, reset to defaults
    } else {
      this.tabs = JSON.parse(JSON.stringify(this.tempTabs)); // Apply changes to the actual tabs
      console.log(this.tabs);
      this.postDefaultColumnConfiguration(); // Any further logic you want to implement
    }

    this.showSettingsDialog = false; // Close the settings dialog
  }

  // Method to reset all checkboxes to their default state
  resetToDefaultFilters() {
    this.tempTabs.forEach((tab) => {
      tab.visible = tab.default; // Reset visibility to default state
    });
    this.tabs = this.tempTabs;
  }

  // Handle "Cancel" button click
  removeFilter() {
    this.tempTabs = JSON.parse(JSON.stringify(this.tabs)); // Reset tempTabs to the current tabs state
    this.showSettingsDialog = false; // Close the settings dialog
  }

  // Re-check the "Select All" checkbox state based on individual checkboxes
  checkSelectAllState() {
    this.selectAllTabs = this.tempTabs.every((tab) => tab.visible);
  }
  saveProfile() {
    const payload = this.userContext.body.user_id;

    this.shipNowService.saveProfile(payload).subscribe(
      (response) => {
        let data = response?.[0]?.details;
        this.checkForShipNowConfiguration(data.columnconfig);
      },
      (error) => {
        this.warningPopup.showWarning('Failed to pack items. Please try again.');
      }
    );
  }

  autoPackEnabled: boolean = false;
  autoScanEnabled: boolean = false;
  checkForShipNowConfiguration(data) {
    // Check if 'shipnow' key exists in the data
    // const shipNowExists = data.some((item) => item.hasOwnProperty('shipnow'));

    // if (!shipNowExists) {
    //   // If 'shipnow' does not exist, call the API to post default column configuration
    //   this.postDefaultColumnConfiguration();
    // } else {
    //   // Find the object containing 'shipnow' and map it directly to 'this.tabs'
    //   const shipNowConfig = data.find((item) => item.hasOwnProperty('shipnow'));
      if (data) {
        this.tabs = data;
        this.tempTabs = JSON.parse(JSON.stringify(this.tabs)); // Temporary array for managing settings dialog changes
        this.autoPackEnabled = this.isAutoPackEnabled(this.tabs);
        this.autoScanEnabled = this.isAutoScanEnabled(this.tabs);
        let dataFromShipRequest = this.rowDataFromShipRequest.getShipFromData();
        if (this.autoPackEnabled) {
          this.sharedService.setAutopack(this.autoPackEnabled);

          if (dataFromShipRequest.Packages.length === 0) {
            this.packAllItems(dataFromShipRequest);

            return;
          } else {
            this.warningPopup.showWarning('Auto Pack cannot be done as Handling Units are already created');
            return;
          }
        }
      }
  }

  isAutoPackEnabled(tabs: any[]): boolean {
    const autoPackTab = tabs.find((tab) => tab.label === 'AutoPack');
    return autoPackTab ? autoPackTab.visible : false;
  }

  isAutoScanEnabled(tabs: any[]): boolean {
    const autoPackTab = tabs.find((tab) => tab.label === 'AutoScan');
    return autoPackTab ? autoPackTab.visible : false;
  }

  onFreightShopClick() {
    const formValues1 = this.dashboardHeaderComponent.getFormValues();
    const formValues2 = this.shipToFromComponent.getFormValues();
    const formValues3 = this.RoutingBillingComponent.getFormValues();
    const formValues4 = this.shipNowPackComponent.getFormValues();
    var res = {
      HeaderInfo: formValues1?.HeaderInfo,
      ShipTo: formValues2?.ShipTo,
      ShipFrom: formValues2?.ShipFrom,
      CarrierDetails: formValues3?.CarrierDetails,
      Items: formValues4?.Items,
      Packages: formValues4?.Packages,
      InternationalDetails:
        formValues2?.InternationalDetails ||
        formValues3?.InternationalDetails ||
        formValues4?.InternationalDetails,
    };
    if (res) {
      this.showWarning = true;

      // Validate form values
      const isValid = this.validateForm(res);

      if (isValid) {
        // If form values are valid, proceed with the API call
        this.makeFreightShopAPICall();
      } else {
        // If form values are not valid, set showWarning to false
        this.showWarning = false;
      }
    }
  }

  // collect ALl Data from Tabs

  makeFreightShopAPICall() {
    // if (this.trackingStatusFlag) {
    //   this.messageService.add({
    //     severity: 'error',
    //     summary: 'Error',
    //     detail: 'Shipment is already proccessed for this order!!!',
    //     life: 3000,
    //   });
    //   return;
    // }
    const allData = this.dataService.getAllData();
    // Extract data from the service
    const { dashboardData, shipToFromData, packNowData } = allData;
    const todayDate = new Date().toISOString();
    let location = this.Original_LocationMasterData,
      locationId = location?.xLocationId,
      fedExAcc = '',
      CarrierDetails = [],
      fedExCarrierData: any = {},
      shipFromAddr = {
        CITY: 'Harrison',
        STATE: 'AR',
        ZIPCODE: '72601',
      };
    this.carrierAccounts.forEach((xitem) => {
      if (xitem.CarrierName == 'FedEx Freight') {
        fedExAcc = xitem.Account;
      }
      if (xitem.CarrierName == 'FedEx') {
        fedExCarrierData = { ...xitem };
      }
    });
    this.carrierAccounts.forEach((xitem) => {
      if (
        xitem.CarrierName == 'FedEx Freight' &&
        fedExCarrierData.CarrierName == 'FedEx'
      ) {
        CarrierDetails.push(
          this.createCarrierDetailsObject(xitem, fedExCarrierData)
        );
      } else {
        if (this.validateRateShopCarrier(xitem, locationId)) {
          CarrierDetails.push(this.createCarrierDetailsObject(xitem, {}));
        }
      }
    });
    let freightShopPayload: any = {};

    freightShopPayload = {
      HeaderInfo: {
        FeederSystem: dashboardData?.FeederSystem ?? '',
        DocumentNumber: dashboardData?.DocumentNumber ?? '',
        DocumentType: dashboardData?.DocumentType ?? '',
        ShipDate: todayDate,
        CreatedDate: todayDate,
        ShipmentType: dashboardData?.ShipmentType ?? '',
        Location: dashboardData?.Location ?? '',
        ERP: dashboardData?.ERP ?? '',
        TotalWeight: dashboardData?.TotalWeight ?? '',
      },
      Shipper: {
        COMPANY: shipToFromData?.ShipFrom?.COMPANY ?? null,
        CONTACT: shipToFromData?.ShipFrom?.CONTACT ?? null,
        ADDRESS_LINE1: shipToFromData?.ShipFrom?.ADDRESS_LINE1 ?? null,
        ADDRESS_LINE2: shipToFromData?.ShipFrom?.ADDRESS_LINE2 ?? null,
        ADDRESS_LINE3: shipToFromData?.ShipFrom?.ADDRESS_LINE3 ?? null,
        CITY: shipToFromData?.ShipFrom?.CITY ?? null,
        STATE: shipToFromData?.ShipFrom?.STATE ?? null,
        ZIPCODE: shipToFromData?.ShipFrom?.ZIPCODE ?? null,
        COUNTRY: shipToFromData?.ShipFrom?.COUNTRY ?? null,
        PHONE: shipToFromData?.ShipFrom?.PHONE ?? null,
        EMAIL: shipToFromData?.ShipFrom?.EMAIL ?? null,
      },
      ShipTo: {
        COMPANY: shipToFromData?.ShipTo?.COMPANY ?? null,
        CONTACT: shipToFromData?.ShipTo?.CONTACT ?? null,
        ADDRESS_LINE1: shipToFromData?.ShipTo?.ADDRESS_LINE1 ?? null,
        ADDRESS_LINE2: shipToFromData?.ShipTo?.ADDRESS_LINE2 ?? 'test',
        ADDRESS_LINE3: shipToFromData?.ShipTo?.ADDRESS_LINE3 ?? null,
        CITY: shipToFromData?.ShipTo?.CITY ?? null,
        STATE: shipToFromData?.ShipTo?.STATE ?? null,
        ZIPCODE: shipToFromData?.ShipTo?.ZIPCODE ?? null,
        COUNTRY: shipToFromData?.ShipTo?.COUNTRY ?? null,
        PHONE: shipToFromData?.ShipTo?.PHONE ?? null,
        EMAIL: shipToFromData?.ShipTo?.EMAIL ?? null,
        TAXID: shipToFromData?.ShipTo?.TAXID ?? null,
      },
      SoldTo: {
        COMPANY: '',
        CONTACT: '',
        ADDRESS_LINE1: '',
        ADDRESS_LINE2: '',
        ADDRESS_LINE3: '',
        CITY: '',
        STATE: '',
        ZIPCODE: '',
        COUNTRY: '',
        PHONE: '',
        EMAIL: '',
      },
      Packages:
        packNowData?.map((pkg) => ({
          ItemsInfo:
            pkg?.ItemsInfo?.map((item) => ({
              ItemNo: item?.ItemNo || item?.product || '',
              ProductNo: item?.product ?? '',
              Description: item?.description ?? '',
              IsDG: item?.isDG ?? null,
              UnitCost: item?.unitCost ?? '',
              UnitWeight: item?.unitWeight || pkg?.Weight || '',
              Dimension: pkg?.dimension ?? '',
              HTSCode: item?.HTSCode ?? '',
              ECCN: item?.ECCN ?? '',
              UN: item?.UN ?? '',
              Class: item?.class || 50,
              NMFC: item?.NMFC ?? '',
              Category: item?.Category ?? '',
              IsSerial: item?.IsSerial ?? null,
              IsBatch: item?.IsBatch ?? null,
              IsStackable: item?.IsStackable ?? null,
              IsActive: item?.IsActive ?? true,
              LocationId: item?.LocationId ?? '',
              id: item?.id ?? 1,
              Sno: item?.Sno ?? 1,
              Quantity: item?.totalQuantity ?? '',
              Partial: item?.partialQuantity ?? '',
              Balance: item?.balanceQuantity ?? '',
            })) ?? [],
          SpecialServices: pkg?.SpecialServices ?? {},
          Weightunits: pkg?.Weightunits || 'LBS' || '',
          DimensionUnits: pkg?.DimensionUnits || 'IN' || '',
          Sno: pkg?.Sno ?? 1,
          HU: pkg?.HU ?? null,
          Weight: pkg?.Weight ?? '',
          Dimension: pkg?.dimension ?? '',
          PackageSpecialServiceTyeps: pkg?.PackageSpecialServiceTyeps ?? [],
        })) ?? [],
      ShipmentLevelServices: packNowData?.ShipmentLevelServices ?? {},
      id: packNowData?.id ?? 86,
      items: [],
      ShipmentSpecialServiceTyeps:
        packNowData?.ShipmentSpecialServiceTyeps ?? [],
      InternationalDetails: {
        ItemInfo: [
          {
            ItemNo: '',
            ProductNo: '',
            Description: '',
            IsDG: null,
            UnitCost: '',
            UnitWeight: '',
            Dimension: '',
            HTSCode: '',
            ECCN: '',
            UN: '',
            Class: '',
            NMFC: '',
            Category: '',
            IsSerial: null,
            IsBatch: null,
            IsStackable: null,
            IsActive: true,
            LocationId: this.locationId,
            id: 1,
            Sno: 1,
            Quantity: null,
            Partial: 1,
            Balance: null,
            UOM: null,
          },
        ],
        termsofShipment: '',
        reasonforExport: '',
        customDecVal: '',
        documentsContentType: '',
        iNTDutiesTaxes: '',
        dutiesAccountnumber: '',
        bookingConfirmnumber: '',
        B13AFilingOption: '',
        Permitnumber: '',
        ITN: '',
        compliStatement: '',
        payorCountryCode: '',
      },
    };
    for (let i = 0; i < CarrierDetails.length; i++) {
      let ShipFrom = {
          COMPANY: shipToFromData?.ShipFrom?.COMPANY ?? null,
          CONTACT: shipToFromData?.ShipFrom?.CONTACT ?? null,
          ADDRESS_LINE1: shipToFromData?.ShipFrom?.ADDRESS_LINE1 ?? null,
          ADDRESS_LINE2: shipToFromData?.ShipFrom?.ADDRESS_LINE2 ?? 'test',
          ADDRESS_LINE3: shipToFromData?.ShipFrom?.ADDRESS_LINE3 ?? null,
          CITY: shipToFromData?.ShipFrom?.CITY ?? null,
          STATE: shipToFromData?.ShipFrom?.STATE ?? null,
          ZIPCODE: shipToFromData?.ShipFrom?.ZIPCODE ?? null,
          COUNTRY: shipToFromData?.ShipFrom?.COUNTRY ?? null,
          PHONE: shipToFromData?.ShipFrom?.PHONE ?? null,
          EMAIL: shipToFromData?.ShipFrom?.EMAIL ?? null,
        },
        carrierName = CarrierDetails[i].Carrier;
      if (CarrierDetails[i].Carrier == 'FedEx Freight') {
        ShipFrom = { ...ShipFrom, ...shipFromAddr };
        carrierName = 'FedExFreight';
        CarrierDetails[i].BillingAccount = fedExAcc;
      }
      freightShopPayload = {
        ...freightShopPayload,
        CarrierDetails: CarrierDetails[i],
        ShipFrom,
      };
      if (this.validateData(freightShopPayload,true) && this.validateRateShopCarrier(freightShopPayload.CarrierDetails)) {
      this.shipNowService
        .sendRateRequest(freightShopPayload, carrierName)
        .subscribe({
          next: (response) => {
            // Check if the status is 'Error'
            if (response.status === 'Error') {
              // If there is an error, display the error messages
              const errorMessage = response.Errors
                ? response.Errors.join(', ')
                : 'Unknown error occurred.';
                this.warningPopup.showWarning(errorMessage);
              return; // Exit the function without making the second API call
            } else {
              response.forEach((rec) => this.FreightShopResult.push(rec));
              console.log(response);
              if (this.FreightShopResult.length && !this.FreightShop_dialog) {
                this.FreightShop_dialog = true;
              }
            }
          },
          error: (err) => {
            console.error('Error in UPS API call:', err);
            this.warningPopup.showWarning('Error processing the request. Please try again.');
          },
        });
    }
  }
  }

  postDefaultColumnConfiguration() {
    const userId = this.userContext.body.user_id; // Replace with actual user ID
    this.columnConfigService
      .postColumnConfig(userId, 'shipnow', this.tabs)
      .subscribe(
        (response) => {
          console.log(
            'Default column configuration posted successfully:',
            response
          );
          // this.columnConfig = this.columns; // Set the posted data as the current config
        },
        (error) => {
          console.error('Error posting default column configuration:', error);
        }
      );
  }

  openSettingsDialog(event: Event) {
    this.showSettingsDialog = true;

    // Wait for Angular to render the overlay before toggling it
    setTimeout(() => {
      this.showOverlay(event);
    }, 0);
  }

  showOverlay(event: Event) {
    if (this.overlayPanel) {
      this.overlayPanel.toggle(event); // Pass the event to correctly position it
    } else {
      console.error('Overlay panel is not available yet');
    }
  }
  validateRateShopCarrier(xitem: any, locationId?) {
    if (
      ((xitem.CarrierName == 'FedEx' || xitem.CarrierName == 'UPS') || (xitem.Carrier == 'FedEx' || xitem.Carrier == 'UPS')) &&
      xitem.Password &&
      xitem.UserId &&
      xitem.Account &&
      xitem.LocationId == locationId
    ) {
      return true;
    }
    if (
      (xitem.CarrierName == 'ABF' || xitem.Carrier == 'ABF') &&
      xitem.Password &&
      xitem.UserId &&
      xitem.LocationId == locationId
    ) {
      return true;
    }
    if (
      (xitem.CarrierName == 'RL' || xitem.Carrier == 'RL') &&
      xitem.AccessKey &&
      xitem.LocationId == locationId
    ) {
      return true;
    }
    if (xitem.CarrierName == 'FedEx Freight' || xitem.Carrier == 'FedEx Freight') {
      return true;
    }
    return false;
  }
  createCarrierDetailsObject(xitem, data) {
    return {
      Carrier: xitem.CarrierName,
      ServiceName: '',
      PaymentType: 'Sender',
      ShippingAccount: xitem.Account,
      BillingAccount: xitem.BillingAccount || data?.Account || '',
      BillingCountry: xitem.BillingCountry,
      BillingZipCode: '',
      Reference1: '',
      Reference2: '',
      Reference3: '',
      Reference4: '',
      Note: '',
      UserId: xitem.UserId || data.UserId,
      Password: xitem.Password || data.Password,
      AccessKey: xitem.AccessKey || data.AccessKey,
    };
  }

  ConfirmPopup = () => {
    this.confirmPopup.showConfirm(
      'Are you sure. You want to cancel the Order ?',
      ()=>{
        this.onVoidConfirm();
      },
      ()=>{

      }
    )
  }
  onVoidConfirm() {
    this.shipNowService
      .sendVoidRequest(
        this.voidPayload,
        this.voidPayload?.CarrierDetails?.Carrier
      )
      .subscribe({
        next: (response) => {
          // Check if the status is 'Error'
          if (response.status === 'Error') {
            const errorMessage = response.Errors
              ? response.Errors.join(', ')
              : 'Unknown error occurred.';
this.warningPopup.showWarning(errorMessage);
            return; // Exit the function without making the second API call
          } else {
            this.prepareAndSendShipRequest(
              this.dataFromShipRequest,
              'Cancelled'
            );
          }
          this.voidPopUpVisible = false;

          // If no error, proceed to update the status and make the second API call
        },
        error: (err) => {
          console.error('Error in API call:', err);
          this.warningPopup.showWarning('Error processing request. Please try again.');
          this.voidPopUpVisible = false;
        },
      });
  }
  constructVoidPayload(dataFromShipRequestCarrierDetails, trackNumber?) {
    let carrier = dataFromShipRequestCarrierDetails?.Carrier;
    carrier = typeof carrier == 'string' ? carrier : typeof carrier == 'object' ? (carrier?.CarrierName || "") : carrier;
    return {
      CarrierDetails: {
        Carrier: carrier,
        UserId: dataFromShipRequestCarrierDetails?.UserId,
        Password: dataFromShipRequestCarrierDetails?.Password,
        Reference1: dataFromShipRequestCarrierDetails?.Reference1,
        ShippingAccount: dataFromShipRequestCarrierDetails?.ShippingAccount,
      },
      Packages: [
        {
          TrackingNumber: trackNumber?.TrackingNumber || '',
        },
      ],
    };
  }
  colConfigReceiveData(data){
    this.checkForShipNowConfiguration(data)
  }
}