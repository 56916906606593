<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<p-toast position="bottom-center" key="c" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{ message.summary }}</h4>
                <p>{{ message.detail }}</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" pButton label="Yes" class="p-button-success"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton label="No" class="p-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<div class="card">
    <p-toolbar styleClass="p-mb-4" *ngIf="isDataLoaded">
        <ng-template pTemplate="left">
            <button pButton label="New" icon="pi pi-plus" (click)="newconsolidationflag=true"></button>
        </ng-template>

    </p-toolbar>
    <p-table  #dt [value]="consolidationTemplateData"  responsiveLayout="scroll"
    
    *ngIf="isDataLoaded" >
    <ng-template pTemplate="header">
        <tr>
            <th>Name</th>
            <th>Fields</th>
            <th></th>
            
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-record>
        <tr>
            <td>{{record['name']}}</td>
            <td>{{ record['fields'] }}</td>
            <td><button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary p-mr-2"
                (click)="editRecord(record)"></button></td>
        </tr>
    </ng-template>
</p-table>
</div>
<p-dialog [(visible)]="newconsolidationflag">
   
        <div class="grid p-fluid mt-2">
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input type="text" pInputText id="name" [(ngModel)]="newRecord['name']">
                    <label for="name">Name</label>
                </span>
                
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    
                    <p-multiSelect [style]="{width:'200px'}" class="mr-1" [options]="xcolumns" optionLabel="name"
                    optionValue="name" [(ngModel)]="newRecord['fields']" appendTo="body"></p-multiSelect>
                </span>

            </div>
            <button type="button" pButton [label]="buttonTitle"  (click)="SaveTemplate()"></button>
        </div>
 
</p-dialog>