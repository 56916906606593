import { Component, HostListener, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-config-table',
  templateUrl: './config-table.component.html',
  styleUrl: './config-table.component.scss'
})
export class ConfigTableComponent {
  @Input() displayedData: any[] = [];
  @Input() columns: any[] = [];
  @Input() shipmentState:any;
  @Input() ConfigKey:any;
  
  @Input() sharedViewFunction!: (data: any) => void;
  @Input() OpenStatusbar!:(shipRequest: any)=> void;
  @Input() sharedShipFunction!: (data: any) => void;
  page: number = 0; // Current page index
  rows = 50;
  displayedArray: any;
  totalRecords = 0;
 scrollHeight = '400px';
 ngOnInit(){
  this.setScrollHeight();

 }

 ngOnChanges(changes: SimpleChanges): void {
  if (changes['displayedData'] && this.displayedData) {
    this.updateDisplayedData()
  
  }
}

  getValueFromPath(
    object: any,
    path: string,
    defaultValue: any = undefined
  ): any {
    const data = path
      .split('.')
      .reduce((o, i) => {
        if (o === undefined || o === null) {
          return defaultValue;
        }
        const match = i.match(/^(.+?)\[(\d+)\]$/); // Check if key is an array index
        if (match) {
          // If it's an array index
          const [_, arrayKey, index] = match;
          return o[arrayKey]?.[parseInt(index)] ?? defaultValue;
        }
        return o[i] !== undefined ? o[i] : defaultValue;
      }, object);
    return data;
  }
  

  // Update the displayed data based on the current page
  updateDisplayedData() {
    const start = this.page * this.rows;
    const end = Math.min(this.displayedData.length, start + this.rows);
    this.displayedArray = this.displayedData.slice(start, end);
    this.totalRecords=this.displayedArray.length

  }

  
  // Handle page change event from paginator
  onPageChange(page: number) {
    this.page = page;
    this.updateDisplayedData();
  }

  callParentView(data): void {
    if (this.sharedViewFunction) {
      this.sharedViewFunction(data); 
    }
  }
  callParentOpenStatusbar(data){
    if (this.OpenStatusbar) {
      this.OpenStatusbar(data); 
    }

  }
  callParentShip(data){
    if (this.sharedShipFunction) {
      this.sharedShipFunction(data); 
    }

  }
    
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.setScrollHeight();
  }

  setScrollHeight() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width >= 1200 && height <= 800) {
      this.scrollHeight = '430px';
    } else {
      this.scrollHeight = '580px';
    }
  }

  customSort(event) {
    const field = event.field;
    const order = event.order;

    this.displayedArray.sort((data1, data2) => {
      const value1 = this.getValueFromPath(data1, field);
      const value2 = this.getValueFromPath(data2, field);

      // Sorting logic
      if (typeof value1 === 'string' && typeof value2 === 'string') {
        return (value1 < value2 ? -1 : 1) * order;
      } else if (typeof value1 === 'number' && typeof value2 === 'number') {
        return (value1 - value2) * order;
      }
      return 0;
    });
  }
}
