import { Component } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EditDashboardComponent } from './edit-dashboard/edit-dashboard.component';

@Component({
  selector: 'app-dash-boards',
  templateUrl: './dash-boards.component.html',
  styleUrls: ['./dash-boards.component.scss'],
  providers: [DialogService]
})
export class DashBoardsComponent {

  isOpen: boolean = true;
  showButton: boolean = true;
  showButton1: boolean = true;
  showButton2: boolean = true;
  dashboardList : any[] = [];

  constructor(
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.dashboardList = [
      {
        icon: '/assets/images/quotenow.png',
        label: 'Quote Now',
        routePath: '/home/quotenow',
        active: false
      },
      {
        icon: '/assets/images/ship.png',
        label: 'Ship Now',
        routePath: '/home/shipnow',
        active: true
      },
      {
        icon: '/assets/images/track.png',
        label: 'Track Now',
        routePath: '/home/TrackShipment',
        active: false
      },
    ]
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  editDashboard() {
    const ref = this.dialogService.open(EditDashboardComponent, {
      data: this.dashboardList,
      header: "Customise your shortcuts",
      width: '400px',
      height: '500px',
      baseZIndex: 999,
      closeOnEscape: false,
      contentStyle: {height: 'calc(100% - 83px)'}
    });
    ref.onClose.subscribe((data) => {
      if(data) {

      }
    })
  }
}


