import { Component, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERPSHIPService } from '../erpship.service';
import { ChangeDetectorRef } from '@angular/core';
import { saveAs } from 'file-saver-es';
import {
  FormGroup,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ShipRequestToShipNowService } from '../services/ship-now/shiprequesttoshipnow.service';
import {ColumnConfigService} from '../services/column-config-service'
import { OverlayPanel } from 'primeng/overlaypanel';
import { OrderService } from '../services/order/order.service';
import { MessageService } from 'primeng/api';
import { StorageService } from '../services/storage.service';
interface ModuleConfig {
  [key: string]: string[];
}
@Component({
  selector: 'app-orders',
  templateUrl: './newcarrieraccounts.component.html',
  styleUrl: './newcarrieraccounts.component.scss'
})
export class NewCarrieraccountsComponent {
  @ViewChild('ot') overlayPanel: OverlayPanel;
  @ViewChild('op') filterOverlayPanel: OverlayPanel;
  scrollHeight = '400px';
  countVisibleTrue: number = 0;

  progressDetails: any={pageTitle:'Carrier Account',buttonTitle:'',isButtonAvl:false};
  isProgressDataAvl: boolean=false;
  first = 0;
  rows = 50;
  isDataLoaded: boolean = false;
  passingid: any = '';
  shipRequests: any[] = [];
  selectedShipRequest: any[] = [];
  items: any[] = [];
  totalRecords = 0;

  locationName: any;
  Carrier: any;
  shipFromDate: Date;
  shipToDate: any;
  costCenter: any;
  user: any;
  Status: any;
  feederSystemOptions = [];
  displayedShipRequests: any[] = []; // Data to display in the table
  page: number = 0; // Current page index
  displayDialog: boolean = false;
  searchQuery: string = '';
  selectedColumns: any[] = [];
  feederSystem:any;

  displayColumnPopup: boolean = false;
  
  columns = [
    {
      header: 'Order ID',
      fieldName: 'HeaderInfo.DocumentNumber',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Created Date',
      fieldName: 'HeaderInfo.CreatedDate',
      fieldType: 'Date',
      editable: false,
      visible: true,
      datatype: 'date',
    },
    {
      header: 'Ship Date',
      fieldName: 'HeaderInfo.ShipDate',
      fieldType: 'Date',
      editable: false,
      visible: true,
      datatype: 'date',
    },
    {
      header: 'Shipment Type',
      fieldName: 'HeaderInfo.ShipmentType',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Carrier Name',
      fieldName: 'CarrierDetails.Carrier',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Service Level',
      fieldName: 'CarrierDetails.ServiceName',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Tracking Number',
      fieldName: 'Packages[0].TrackingNumber',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Status',
      fieldName: 'HeaderInfo.Status',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Ship To Company',
      fieldName: 'ShipTo.COMPANY',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Contact',
      fieldName: 'ShipTo.CONTACT',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
   
    {
      header: 'Ship To Address Line 1',
      fieldName: 'ShipTo.ADDRESS_LINE1',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To City',
      fieldName: 'ShipTo.CITY',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To State',
      fieldName: 'ShipTo.STATE',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Country',
      fieldName: 'ShipTo.COUNTRY',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Zipcode',
      fieldName: 'ShipTo.ZIPCODE',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Phone',
      fieldName: 'ShipTo.PHONE',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Email',
      fieldName: 'ShipTo.EMAIL',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Requester Name',
      fieldName: 'HeaderInfo.CreatedUser',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Connected To',
      fieldName: 'HeaderInfo.FeederSystem',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Order Type',
      fieldName: 'HeaderInfo.DocumentType',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    }, 
    {
      header: 'Priority Level',
      fieldName: 'HeaderInfo.PriorityLevel',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    }
  ];
  mandatoryColumns = [
    'Order ID',
    'Created Date',
    'Ship Date',
    'Carrier Name',
    'Service Level',
    'Tracking Number',
    'Status',
    'Ship To Company',
    'Ship To Contact'
  ];
  postColumns: any[] = [];
  locationStatusOptions: any;
  locationOption: any;
  locationData: any;
  openRecordsCount: number;
  shippedRecordsCount: number;
  cancelledRecordsCount: number;
  finalColumns: any;
  ConfigKey='orders';

  toggleColumnVisibility(
    col: any,
    columnName: string,
    isVisible: boolean
  ): void {
    col.visible = isVisible; // Directly update the visibility in filteredColumns
  }

  filteredColumns: any[] = []; // Start with all columns
  backupColumns: any[] = [];
  showColumnConfig() {
    this.backupColumns = this.filteredColumns.map((col) => ({ ...col }));
    this.displayDialog = true;
  }
  
  filterColumns() {
    console.log('Search Query:', this.searchQuery); // Debugging
    if (this.searchQuery) {
      this.filteredColumns = this.columns.filter((col) =>
        col.header.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    } else {
      this.filteredColumns = this.columns;
    }
    console.log('Filtered Columns:', this.filteredColumns); // Debugging
  }

  constructor(
    private router: Router,
    private xfservice: ERPSHIPService,
    private cdr: ChangeDetectorRef,
    private rowDataService: ShipRequestToShipNowService,
    private columnConfigService: ColumnConfigService,
    private orderService: OrderService,
    public messageService: MessageService,
    private renderer: Renderer2,
    private storageService : StorageService

  ) {
    this.xfservice.processAuth().subscribe(
      (data) => {
       
      },
      (error) => {
        console.log('No token found');
        this.router.navigate(['']);
      }
    );
  }
  viewShipRequest(shipRequest){
    this.router.navigate(['/home/shiprequestmodal','View-'+ shipRequest.id]);

  }
  viewShipNow(shipRequest){
    shipRequest.isFooterEnabled=false;
    this.rowDataService.setData(shipRequest.HeaderInfo.DocumentNumber);
    this.rowDataService.setShipFromData(shipRequest);
    this.router.navigate(['/home/shipnow'], {
      state: { data: JSON.stringify(shipRequest) },
    });
  }
  //Action from Table
 
  copy(shipRequest) {
    this.router.navigate(['/home/shiprequestmodal', 'COPY-' + shipRequest.id]);
  }
  print(shipRequest) {
    console.log('delete functionality ');
  }

  ship(shipRequest) {
    this.rowDataService.setData(shipRequest.HeaderInfo.DocumentNumber);
    this.rowDataService.setShipFromData(shipRequest);
    this.router.navigate(['/home/shipnow'], {
      state: { data: JSON.stringify(shipRequest) },
    });
  }

  generateOpenItems = (shipRequest) => {
    // this.cdr.detectChanges();
    return [
      {
        label: 'View',
        command: () => {
          this.viewShipRequest(shipRequest);
        },
      }
    ];
  };

  generateItems = (shipRequest) => {
    // this.cdr.detectChanges();
    return [
      
      {
        label: 'Copy',
        command: () => {
          this.copy(shipRequest);
        },
      },
      {
        label: 'Print',
        command: () => {
          this.print(shipRequest);
        },
      },
    ];
  }
  columnConfig: any;
  userId: any;

  ngOnInit(): void {
   // this.locationData = this.storageService.getItem('data');
    this.filteredColumns = [...this.columns];

    // Call the service method to post the column conf
    this.xfservice.getLocationMasterData().subscribe((data) => {
   
      this.progressDetails.shipmentState=data.shipmentstatuses;
      this.isProgressDataAvl=true;
      this.feederSystemOptions=data.erp;
      this.locationStatusOptions=data.shipmentstatuses;
      this.locationOption=data.location;
      this.locationData = data;
      let rowdata = data.location.filter((item: any) => 
      item.LocationId == data['xLocationId'])
      this.locationName = rowdata[0];
      if(this.locationData != undefined)
        {
          this.xfservice
          .getxFormRecordsByFromName('ShipRequest')
          .subscribe((data: any) => {  
            data.sort((a, b) => b.id - a.id);
            const validData = data.filter((item: any) => {
              const details = item.details;
              return details && Object.keys(details).length > 1; // Ensures 'details' contains more than just 'id'
            });
            this.loadTableData(validData);
            return;
            // Initialize counters

          });
        }
    });
  }
  showSelectedRecords(): void {
    alert(this.selectedShipRequest.length);
    console.log(JSON.stringify(this.selectedShipRequest));
  }

  openNewShipRequest(): void {
    this.router.navigate(['/home/shipRequest']);
  }

  editShipRequest(data: any): void {}

  downloadFile(): void {
    alert('DOWNLAOD STARTED');
    console.log(this.toCsv(this.pivot(this.shipRequests)));
  }

  //DOWNLOAD NESTED OBJECT S

  pivot(arr: any) {
    var mp = new Map();

    function setValue(a: any, path: any, val: any) {
      if (Object(val) !== val) {
        // primitive value
        var pathStr = path.join('.');
        var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
        a[i] = val;
      } else {
        for (var key in val) {
          setValue(a, key == '0' ? path : path.concat(key), val[key]);
        }
      }

      return a;
    }

    var result = arr.map((obj: any) => setValue([], [], obj));
    return [[...mp.keys()], ...result];
  }

  toCsv(arr: any) {
    return arr
      .map((row: any) =>
        row
          .map((val: any) => (isNaN(val) ? JSON.stringify(val) : +val))
          .join(',')
      )
      .join('\n');
  }


  OrderFilter(){
    if(this.feederSystem?.Name=="Acumatica Cloud ERP"){
      this.orderService.getOrderAcumaticaData().subscribe((data) => {
        this.loadTableData(data);
      });

    }else{
    let res={
      "user_id": this.locationName?.LocationName,
      "filters":
     {
     "fromdate": this.shipFromDate,
     "todate": this.shipToDate,
     "feedersystem": this.feederSystem?.Name,
     "locationid": this.locationName?.LocationId,
     "rowstart":0,
     "rowend":50
     }
  }
 
  this.orderService.getOrderData(res).subscribe((data) => {
    
    this.loadTableData(data);
  });
  }
  }

  showFilterOverlay(event: Event) {
    if (this.filterOverlayPanel) {
      this.filterOverlayPanel.show(event);
    }
  }
  closeFilterOverlay() {
      this.filterOverlayPanel.hide();
  }
  documentCountList :any;

  loadTableData(validData){
    var filterData=[]
    let openCount = 0;

    validData.forEach((item: any) => {
      const details = item.details;
      item.details.isOpenStatus=true;
      item.details.buttonItem = this.generateOpenItems(item.details);
      if (item.details.HeaderInfo.Status=="Open" ){
        if (details?.CarrierDetails) {
          // Assuming details contains a ServiceCode you want to match dynamically
          const serviceCodeToMatch = details?.CarrierDetails.ServiceName;
          // Find the matching service based on ServiceCode
          const matchedService = this.locationData?.carrierservices.find(service => service.ServiceCode === serviceCodeToMatch);
  
          if (matchedService) {
            const serviceName = matchedService.ServiceName;
            // Push the details with the matched service name
            filterData.push({ ...details,
              CarrierDetails: {
                ...item.details.CarrierDetails, // Spread the existing CarrierDetails
                ServiceName: serviceName // Use the updated ServiceName
              }});
          }
        }
        openCount++;
      }
    });
    this.isDataLoaded = true;
    this.shipRequests=filterData;
    this.totalRecords = this.shipRequests.length;
    this.openRecordsCount = openCount;
    console.log(filterData);

    this.documentCountList = {
      'totalRecords': filterData.length || 0,
      'openRecords' : filterData.length|| 0,
      'shippedRecords' : this.shippedRecordsCount|| 0,
      'cancelledRecords': this.cancelledRecordsCount || 0
    }
    this.closeFilterOverlay();
    const today = new Date();
    const oneWeekBefore = new Date(today);
    oneWeekBefore.setDate(today.getDate() - 6);
    this.shipFromDate = oneWeekBefore;
    this.shipToDate=today;
  }

  receiveData(data:any) {
    this.finalColumns = data;
  }

}

