<div class="">
    <!-- <p-toast></p-toast>
    <p-toast position="top-left" key="tl"></p-toast>
    <p-toast position="top-center" key="tc"></p-toast>
    <p-toast position="bottom-center" key="bc"></p-toast> -->

    <div class="main-container pagedetails-header">
        <div class="titleContainer flex text-dark justify-content-left p-3">
            <div class="pageTitle" title="TrackShipment"><b>Track Shipments</b></div>
        </div>
        <div class="main-container1 p-grid p-align-center p-justify-between xcarr-filters__wrapper">
            <!-- Dashboard Filters Section -->
            <div class="xcarr-dashbrd-filters p-d-flex p-jc-start p-ai-center pageStatus" id="thumbdiv"
                style="overflow-x: auto; margin-bottom: 5px !important;">
                <div *ngFor="let dummy of ' '.repeat(3).split(''), let x = index">

                    <div *ngIf="x==0" class="flex justify-content-between grid col-12 ps-2"
                        style="padding-bottom: 0px !important;">
                        <ng-container *ngFor="let status of statusOptions">
                            <div class="col ps-0 border-round-sm totalRec-container">
                                <div class="w-6rem">
                                    <span class="count text-left font-bold text-base">{{status.count}}</span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                     <div *ngIf="x==1" class="flex justify-content-between grid col-12 ps-2 pt-0">
                        <ng-container *ngFor="let status of statusOptions">
                            <div class="col pt-0 ps-0 border-round-sm">
                                <div class="w-6rem">
                                    <span class="text-left font-bold thumbtitle">{{status.description}}</span>
                                </div>
                            </div>
                        </ng-container>
                    </div> 
                    <div *ngIf="x==2" class="flex justify-content-between grid col-12 ps-2"
                        style="padding-bottom: 10px !important;">
                        <ng-container *ngFor="let status of statusOptions">
                            <!-- <span>{{status.description}}</span> -->
                            <div class="totalProgressbar col pr-2 mr-1 border-round-sm {{status.active}}"
                                (click)="changeStatus(status)"
                                [ngClass]="{'shipped': status.code === 'SPD', 'tracking' : status.code === 'INT','delivered': status.code === 'DLV', 'cancelled' : status.code === 'CAN' }"
                                [ngStyle]="{'background-color': status.colorpicker, 'cursor': 'pointer'}">
                                <!-- <div class="p-1">
                                    
                                    <div class="thumbtitle" style="cursor: pointer; padding-left: 4px !important;">
                                        <div>
                                            <span class="text-left">{{status.description}}</span>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <p-toast position="bottom-center" key="c" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{ message.summary }}</h4>
                    <p>{{ message.detail }}</p>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" pButton label="Yes" class="p-button-success"></button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" pButton label="No" class="p-button-secondary"></button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
    <!-- [(visible)]="displaySidebar" -->
    <p-dialog  styleClass="h-screen overflow-auto" [modal]="true" [baseZIndex]="10000" styleClass="custom-sidebar">
        <div *ngIf="displaySidebar" class="custom-sideborder border-solid p-p-3">
            <div class="flex justify-content-between header h-3rem bg-purple-800">
                <div class="flex justify-content-between">
                    <div class="flex justify-items-center">
                        <span class="p-1">
                            <button pButton class="bg-button p-1">
                                <img style="width: 1.5rem;" src="../assets//images/copilotlogo.png" alt="Copilot Logo">
                            </button>
                        </span>
                    </div>
                    <div class="flex justify-items-center">
                        <span class="text-white p-1 pt-2">
                            {{this.selectedShippingStatusData[0].trackingNumber}}
                        </span>
                    </div>
                </div>
                <div class="w-5rem mt-1 mr-3">
                    <span class="flex justify-items-center bg-white m-1 p-1">
                        <i class="pi pi-print mr-1 ms-1 mt-1" id="printShippingStatus" style="font-size: 1.2rem"></i>
                        <span class="" for="printShippingStatus">Print</span>
                    </span>
                </div>
                <!-- <div [hidden]="true" class="sbh flex align-content-between align-items-center p-ai-center w-11rem">
                    <p-checkbox [(ngModel)]="checked" [binary]="true" inputId="binary" />
                    <label for="ny" class="p-d-flex p-ai-center text-white">Save tracking results</label>
                </div> -->
            </div>
            <!-- <div class="h-3rem">
                <div class="flex justify-content-end">
                    <div class="w-5rem mt-1">
                        <span class="flex justify-items-center mr-1 mt-1">
                            <i class="pi pi-print mr-1" id="printShippingStatus" style="font-size: 1.2rem"></i>
                            <span class="" for="printShippingStatus">Print</span>
                        </span>
                    </div>
                    <div class="w-5rem mt-1">
                        <span class="flex justify-items-center mt-1">
                            <i class="pi pi-question-circle mr-1" id="printShippingStatus"
                                style="font-size: 1.2rem"></i>
                            <span for="printShippingStatus" class="ml-1">Help</span>
                        </span>
                    </div>
                </div>
            </div> -->
            <!-- Sidebar Header -->
            <div class="flex justify-content-between mt-4">
                <div class="ml-3 w-3">
                    <div>
                        <label class="p-text-bold mr-1">Ship Date:
                            <i *ngIf="!this.selectedShippingStatusData[0].shipDate" class="pi pi-question-circle" style="font-size: 1.2rem"></i>
                        </label>
                        <label *ngIf="this.selectedShippingStatusData[0].shipDate">
                            {{this.selectedShippingStatusData[0].shipDate | date:'EEE MM/dd/yyyy'}}
                        </label>
                    </div>
                    <p-divider type="solid" />
                    <div>
                        <p>Location: NEW YORK, NY US</p>
                    </div>
                </div>
                <div class="col-3 justify-items-center text-center">
                    <div>{{this.selectedShippingStatusData[0].documentNumber}}</div>
                    <p-image
                      src={{this.selectedShippingStatusData[0].statusImgData.src}}
                      [style]="{cursor: 'default'}"
                      class={{this.selectedShippingStatusData[0].statusImgData.none}}
                      id={{this.selectedShippingStatusData[0].statusImgData.id}}
                      alt={{this.selectedShippingStatusData[0].statusImgData.alt}}
                      width={{this.selectedShippingStatusData[0].statusImgData.width}}
                      height={{this.selectedShippingStatusData[0].statusImgData.height}}
                    ></p-image>
                    <div class="mt-1">
                        <span class="text-bold text-success">{{this.shippingStatusData[0].status}}</span>
                    </div>
                    <div class="mt-1">
                        <span class="font-italic">Signed for by:</span>
                        <span class="font-italic">{{this.selectedShippingStatusData[0].shipperContact}}</span>
                    </div>
                </div>
                <div class="mr-3 w-3">
                    <div class="mt-2">
                        <label for="actualDelivery">Actual Delivery:</label>
                        <div>
                            <span>{{this.selectedShippingStatusData[0].DateTime | date:'EEE MM/dd/yyyy hh:mm a'}}</span>
                        </div>
                    </div>
                    <p-divider />
                    <div>
                        <span>{{this.selectedShippingStatusData[0].Location}}</span>
                    </div>
                </div>
            </div>
            <p-divider type="solid" />
            <div class="grid mb-3">
                <div class="col-12 md:col-4">
                    <div class="mr-1 ms-3">
                        <h6 class="font-bold text-left">Ship From</h6>
                        <div class="text-left"><span>{{this.selectedShippingStatusData[0]?.shipFrom?.COMPANY}}</span></div>
                        <div class="text-left"><span>{{this.selectedShippingStatusData[0]?.shipFrom?.CONTACT}}</span></div>
                    </div>
                    <!-- <input type="text" class="text-bold" pInputText value="Hold at {{this.selectedCarrierType?.toLocaleUpperCase()}} Location" [disabled]="true" /> -->
                </div>
                <div class="col-12 md:col-4">
                    <div class="mr-1 ms-2">
                        <h6 class="font-bold text-left">Ship To</h6>
                        <div class="text-left"><span>{{this.selectedShippingStatusData[0]?.shipTo?.COMPANY}}</span></div>
                        <div class="text-left"><span>{{this.selectedShippingStatusData[0]?.shipTo?.CONTACT}}</span></div>
                    </div>
                </div>
                <div class="col-12 md:col-4">
                    <div class="mr-1 ms-2">
                        <h6 class="font-bold text-left">Carrier Information</h6>
                        <div class="text-left"><span>{{this.selectedShippingStatusData[0]?.carrierDetails?.Carrier}}</span></div>
                        <div class="text-left"><span>{{this.selectedShippingStatusData[0]?.carrierDetails?.ServiceName}}</span></div>
                    </div>
                </div>
                <!-- <button pButton label="Request Notifications" class="text-white bg-purple-800 p-2 mr-4"></button>
                <button pButton label="Obtain Proof of Delivery" class="text-white bg-purple-800 p-2"></button> -->
                <!-- <div class="w-3 mr-5">
                    <div class="flex justify-content-end">
                        <p-toast />
                        <p-splitButton label="More actions" [model]="moreItems" (onClick)="save('info')" text
                            size="large" severity="secondary" />
                    </div>
                </div> -->
            </div>

            <div class="w-auto mr-3 ml-3 mt-4">
                <p-divider type="solid" styleClass="w-12" />
                <div class="flex justify-content-between">
                    <h6 class="font-bold">Shipment History</h6>
                    <div class="flex justify-content-end w-9rem mt-1">
                        <div hidden>
                            <span class="flex justify-items-center mt-1">
                                <i class="pi pi-question-circle mr-1" id="printShippingStatus"
                                    style="font-size: 1.2rem"></i>
                                <span for="printShippingStatus" class="text-solid ml-1">Help</span>
                            </span>
                        </div>
                        <div>
                            <p-button class="p-1" [plain]="true" [text]="true" (onClick)="toggleApplications()" label="Hide" />
                            <span class="p-1 bg-white">
                                <i (click)="toggleApplications()" class="pi pi-minus-circle mt-1 mr-1"
                                    id="printShippingStatus" style="font-size: 1.2rem"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <p-divider type="solid" styleClass="w-12" />
                <p-treeTable [value]="travelHistory" [scrollable]="true" [tableStyle]="{ 'min-width': '50rem' }"
                    styleClass="mt-4">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Date/Time</th>
                            <th>Activity</th>
                            <th>Location</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                        <tr [ttRow]="rowNode">
                            <td *ngIf="!rowNode.parent"><p-treeTableToggler [rowNode]="rowNode" />
                                {{ rowData.dateTime ? (rowData.dateTime | date: 'MM/dd/yyyy - EEEE') : '' }}
                            </td>
                            <td *ngIf="!rowNode.parent"></td>
                            <td *ngIf="!rowNode.parent"></td>

                            <td *ngIf="rowNode.parent">
                                {{ rowData.dateTime ? (rowData.dateTime | date: 'shortTime') : '' }}
                            </td>
                            <td *ngIf="rowNode.parent">{{ rowData.activity }}</td>
                            <td *ngIf="rowNode.parent">{{ rowData.location }}</td>
                        </tr>
                    </ng-template>
                </p-treeTable>
            </div>
        </div>
    </p-dialog>
    <!-- <div class="actions-container" [style]="{float:'right'}">
        <span
            style="display: flex; justify-content: flex-end; position: sticky; right: 0;">Actions</span>
        
        <button [style]="{float:'right'}" type="button" pButton pRipple icon="pi pi-file"
            pTooltip="CSV" tooltipPosition="bottom">
        </button>
        <button pButton [style]="{float:'right'}" type="button" icon="pi pi-cog"
            (click)="openTableProperties()">

        </button>
        <button pButton [style]="{float:'right'}" icon="pi pi-filter" type="button"
            (click)="op.toggle($event)">
        </button>
      
    </div> -->
    <div class="card gridCommonCss m-3 p-3 pt-0">
    <div class="filterSec col-12 pl-0 pr-0" *ngIf="isDataLoaded">
        <div class="icon-container">
            <!-- Filter icon on the left -->
            <div>
                <img class="filter-icon" src="assets/icon/filter.png" alt="Filter" style="font-size: 18px; vertical-align: middle;" (click)="op.toggle($event)" />
            </div>
        
            <!-- Share and settings icons on the right -->
            <div class="text-right">
                <img class="download-icon" src="assets/icon/download.png" alt="Download" style="font-size: 18px; margin-right: 5px; vertical-align: middle;" />
                <app-col-config [columns]="columns" [mandatoryColumns]="mandatoryColumns"
                (finalColumns)="receiveData($event)" [ConfigKey]="ConfigKey"></app-col-config>
            </div>
        </div> 
        <!-- <div class="filterSec icon-container">
            <div class="grid m-0 pt-1 pb-0">
              <div class="col pl-0 pb-0 pt-0">
                <i class="pi pi-filter mr-0" style="font-size: 18px; vertical-align: middle;" (click)="op.toggle($event)"></i>
              </div>
              <div class="col text-right pr-0 pb-0 pt-0">
                <i class="pi pi-file-excel" style="font-size: 18px;vertical-align: middle;"></i>
                <i class="fa fa-cog mr-0" style="font-size: 18px;vertical-align: middle;"
                  (click)="showOverlay($event)"></i>
              </div>
            </div>
          </div> -->
        <!-- Track Shipment TABLE-->
        <app-config-table *ngIf="isDataLoaded" [displayedData]="shipRequests" 
        [columns]="finalColumns" [OpenStatusbar]="OpenStatusbar.bind(this)"
        [ConfigKey]="ConfigKey"></app-config-table>
    </div>
    </div>

    
    <!-- TABLE PROPERTIES  DIALOG START-->
    <p-dialog [(visible)]="tableProperties_dialog" [style]="{ width: '70%' }" [modal]="true" styleClass="p-fluid">
        <p-header>{{ " Properties"}}</p-header>
        <div class="card" [style]="{padding: '0px',height: '70vh',width: '99%',float: 'left',overflow: 'scroll'}">
            <p-table [value]="xfields" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
                dataKey="fieldHeader" responsiveLayout="stack">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 5rem"></th>
                        <th>Visible</th>
                        <th>Coulm Header</th>
                        <th>Include Header Name</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-fielddata let-expanded="expanded">
                    <tr>
                        <td>
                            <button type="button" pButton pRipple [pRowToggler]="fielddata"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                        </td>
                        <td>
                            <p-checkbox [(ngModel)]="fielddata.visible" [binary]="true"
                                [disabled]="false"></p-checkbox>{{
                            fielddata.visible }}
                        </td>
                        <td> {{ fielddata.fieldHeader }}</td>
                        <td>
                            <span class="p-float-label">
                                <input pInputText [(ngModel)]="fielddata['IncludeHeader']" />
                            </span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-fielddata>
                    <tr>
                        <td colspan="3">
                            <div class="p-3">
                                <p-table [value]="fielddata.fields" dataKey="title">
                                    <ng-template pTemplate="header">
                    <tr>
                        <th>Visible</th>
                        <th>Field</th>
                        <th>Filter</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-field>
                    <tr>
                        <td>
                            <p-checkbox [(ngModel)]="field['TableVisible']" [binary]="true" [disabled]="false">
                            </p-checkbox>{{fielddata['TableVisible'] }}
                        </td>
                        <td>{{field.title}}</td>
                        <td>
                            <p-checkbox [(ngModel)]="field['filterField']" [binary]="true"
                                [disabled]="false">{{field['filterField']}}
                            </p-checkbox>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        </td>
        </tr>
        </ng-template>
        </p-table>
</div>
<button type="button" pButton pRipple label="Save Changes" icon="pi pi-check" class="p-button-sm"
    (click)="SaveTemplate('ManifestTable')">
</button>
</p-dialog>
<p-overlayPanel #op [showCloseIcon]="true" [dismissable]="true" [style]="{width: '50%'}">

        <div class="grid p-fluid">
            <div class="col-12 md:col-3">
                <div class="p-field">
                    <label for="location">Location Name <span class="p-error">*</span></label>
                    <p-dropdown id="ddlLocation" [options]="locationOptions" 
                    [(ngModel)]="selectedLocation" [filter]="true" [showClear]="true"
                    placeholder="Select Location" optionLabel="LocationName" optionValue="LocationId">
                </p-dropdown>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="carrier">Carrier</label>
                <p-dropdown id="ddlCarrier" [options]="carrierOptions" [(ngModel)]="selectedCarrier" [filter]="true"
                    [showClear]="true" placeholder="Select" optionLabel="CarrierName" optionValue="id">
                </p-dropdown>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="paymentType">Payment Type</label>
                <p-dropdown id="ddlPaymentType" [options]="paymentTypeOptions" [(ngModel)]="Track" optionLabel="label"
                    optionValue="value" placeholder="Select Payment Type"></p-dropdown>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <label for="poNumber">PO Number</label>
            <div class="p-inputgroup p-input-icon-right">
                <i class="serch-div pi pi-search"></i>
                <input id="poNumber" type="text" pInputText [(ngModel)]="Track" maxlength="30" />
            </div>
        </div>
    </div>
    <div class="grid p-fluid">
        <div class="col-12 md:col-3">
            <div class="p-inputgroup p-input-icon-right">
                <label for="fromDate">Ship From Date</label>
                <p-calendar inputId="from" [(ngModel)]="filters['shipDateFrom']" dateFormat="yy-mm-dd"
                    placeholder="Select Date"></p-calendar>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-inputgroup p-input-icon-right">
                <label for="fromDate">Ship From Date</label>
                <p-calendar inputId="to" [(ngModel)]="filters['shipDateTo']" dateFormat="yy-mm-dd"
                    placeholder="Select Date"></p-calendar>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-inputgroup p-input-icon-right">
                <label for="shipperName">Shipper Name</label>
                <div class="p-inputgroup p-input-icon-right">
                    <i class="serch-div pi pi-search"></i>
                    <input id="shipperName" [(ngModel)]="Track" type="text" pInputText placeholder="Shipper Name" />
                </div>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-inputgroup p-input-icon-right">
                <label for="shipToCompany">Ship To Company</label>
                <div class="p-inputgroup p-input-icon-right">
                    <i class="serch-div pi pi-search"></i>
                    <input id="shipToCompany" type="email" pInputText [(ngModel)]="Track" maxlength="30" />
                </div>
            </div>
        </div>
    </div>
    <div class="grid p-fluid">
        <div class="col-12 md:col-3">
            <div class="p-inputgroup p-input-icon-right">
                <label for="costCenter">Cost Center</label>
                <div class="p-inputgroup p-input-icon-right">
                    <i class="serch-div pi pi-search"></i>
                    <input id="costCenter" type="text" pInputText [(ngModel)]="Track" maxlength="30" />
                </div>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="feederSystem">Feeder System</label>
                <p-dropdown id="ddlFeederSystem" [options]="feederSystemOptions" [(ngModel)]="Track"
                    optionLabel="FEEDERSYSTEM_NAME" placeholder="Select Feeder System"></p-dropdown>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-inputgroup p-input-icon-right">
                <label for="deliveryNumber">Delivery Number</label>
                <input id="deliveryNumber" type="text" pInputText [(ngModel)]="Track" maxlength="30" />
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="trackingNumber">Tracking/Pro/BOL#</label>
                <input id="trackingNumber" type="text" pInputText [(ngModel)]="Track" maxlength="30" />
            </div>
        </div>
    </div>
    <div class="grid p-fluid">
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="country">Country</label>
                <p-dropdown id="ddlCountry" [options]="countryOptions" [(ngModel)]="selectedCountry" optionLabel="name"
                    placeholder="Select Country" optionValue="id" [filter]="true" [showClear]="true">
                </p-dropdown>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-field">
                <label for="status">Status</label>
                <p-dropdown id="ddlStatus" [options]="statusOptions" [(ngModel)]="selectedStatus"
                    optionLabel="description" optionValue="id" placeholder="Select Status">
                </p-dropdown>
            </div>
        </div>
        <div class="col-12 md:col-3">
            <div class="p-inputgroup p-input-icon-right">
                <label for="user">User</label>
                <div class="p-inputgroup p-input-icon-right">
                    <i class="serch-div pi pi-search"></i>
                    <input id="user" type="text" pInputText [(ngModel)]="Track" maxlength="30" />
                </div>
            </div>
        </div>
    </div>

    <div class="p-d-flex apply-div">
        <div class="footer-apply pr-2">
            <p-button label="Apply" (click)="applyFilters()"></p-button>
        </div>
        <div class="footer-reset">
            <p-button class="p-d-flex footer-cancel" label="Reset"></p-button>
        </div>
    </div>
</p-overlayPanel>

    <p-sidebar position="right" [modal]="true" [(visible)]="displaySidebar">
        <ng-template pTemplate="header">
            <span class="font-semibold text-xl">ShipperCopilot™ Tracking ID - {{ filterShipment?.trackingNumber }}</span>
        </ng-template>
        <div class="tracking-container">
            <div class="tracking-progress">
                <img [src]="getCurrentStepImage(filterShipment?.status)" alt="Tracking Progress" />
                <span>{{filterShipment?.status}}</span>
            </div>
        
            <div class="shipment-details">
                <div class="grid-row">
                <div class="grid-label"><strong>Carrier Name:</strong></div>
                <div class="grid-value">{{ filterShipment?.carrierName }}</div>
                </div>
                <!-- <div class="grid-row">
                <div class="grid-label"><strong>Tracking Number:</strong></div>
                <div class="grid-value">{{ filterShipment?.trackingNumber }}</div> -->
                <!-- </div> -->
                <div class="grid-row">
                <div class="grid-label"><strong>Service Name:</strong></div>
                <div class="grid-value">{{ filterShipment?.service }}</div>
                </div>
                <div class="grid-row">
                <div class="grid-label"><strong>Delivered To:</strong></div>
                <div class="grid-value">{{ filterShipment?.deliveredTo }}</div>
                </div>
                <div class="grid-row">
                <div class="grid-label"><strong>Ship Date:</strong></div>
                <div class="grid-value">{{ filterShipment?.shipDate }}</div>
                </div>
                <div class="grid-row">
                <div class="grid-label"><strong>Standard Transit:</strong></div>
                <div class="grid-value">{{ filterShipment?.standardTransit }}</div>
                </div>
                <div class="grid-row">
                <div class="grid-label"><strong>Delivered:</strong></div>
                <div class="grid-value">{{ filterShipment?.delivered }}</div>
                </div>
                <div class="grid-row">
                <div class="grid-label"><strong>Signed By:</strong></div>
                <div class="grid-value">{{ filterShipment?.signedBy }}</div>
                </div>
            </div>

            <div class="py-2 px-2" style="color: #4cace4;">
                <i class="pi pi-download"></i>
                <span class="px-2">Obtain proof of delivery</span>
            </div>
            
            <div class="track-table py-2">
                <span style="color: #ffc404; font-size: 20px;font-weight: bolder;">Shipment Travel History</span>
                <table class="tracking-table">
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th></th> <!-- Placeholder for the timeline -->
                        <th>Status</th>
                        <th>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let event of filterEventData; let i = index">
                        <ng-container *ngFor="let detail of event.details; let j = index">
                        <tr [ngStyle]="{'background-color': getRowBackgroundColor(event.date)}">
                            <td *ngIf="j === 0" [attr.rowspan]="event.details.length">{{ event.date }}</td>
                            <td>{{ detail.time }}</td>
                            <td class="timeline-bar">
                            <i  *ngIf="!(i === filterEventData.length -1 && j === event.details.length -1)" class="pi pi-circle timeline-icon"></i>
                            <i *ngIf="(i === filterEventData.length -1 && j === event.details.length -1 )" class="pi pi-check-circle timeline-check-circle"></i>
                            <span *ngIf="i === 0 && j === 0" class="timeline-line-top"></span>
                            <span *ngIf="(i !== 0 || j !== 0) && !(i === filterEventData.length -1 && j === event.details.length -1)" class="timeline-line"></span>
                            <span *ngIf="(i === filterEventData.length -1 && j === event.details.length -1 ) " class="timeline-line-bottom-check-circle"></span>
                            <!-- <span *ngIf="(i === filterEventData.length -1 && j === event.details.length -1 && detail.status==='DELIVERED' ) " class="timeline-line-bottom-check-circle"></span> -->
                            
                        </td>
                            <td>{{ detail.status }}</td>
                            <td>{{ detail.location }}</td>
                        </tr>
                        </ng-container>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </p-sidebar>
</div>