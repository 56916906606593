<img class="setting-icon" src="assets/icon/setting.png" alt="Setting" style="font-size: 18px; margin-right: 5px; vertical-align: middle; border-radius: 22px; padding: 4px;" (click)="showOverlay($event)"/>

<p-overlayPanel #ot header="Configure Fields" [showCloseIcon]="true" [dismissable]="true">
    <span style="font-weight: 600; font-size: 1.25rem;">Columns</span>
    <div class="columnConfigtxt p-inputgroup pt-2">
      <input type="text"  pInputText placeholder="Search Columns" [(ngModel)]="searchQuery"
        (input)="filterColumns()" />
    </div>

    <div style="margin-top: 10px;height: 324px !important;overflow: auto !important;">
      <p-checkbox class="pb-3 pt-2" [(ngModel)]="selectAll" label="SelectAll"  [binary]="true"
      (onChange)="SelectAll($event.checked)"></p-checkbox>
      <div *ngFor="let col of filteredColumns">
        <p-checkbox class="pb-3" [(ngModel)]="col.visible" [label]="col.header" [binary]="true"></p-checkbox>
      </div>
    </div>
    <div class="button-container">
      <div class="overlay-cancel">
        <button type="button" style="display: none;" class="btnColumnCancel" (click)="cancelColumnConfig()">Cancel</button>
      </div>
      <div class="overlay-apply">
        <button type="button" class="btnColumnApply" (click)="applyColumnConfig()">Apply</button>
      </div>
    </div>
    <!-- </p-dialog> -->
  </p-overlayPanel> 