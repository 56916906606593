import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  template: ``,
  styleUrls: ['./app-menu.component.scss'],
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];
  //"pi pi-fw pi-align-left
      ngOnInit() {
          this.model = [
              // {
              //     label: "Home",
              //     icon: "pi pi-home",
              //     routerLink: ["/"],
              //     items: [
              //         {
              //             label: "Dashboard",
              //             icon: "pi pi-fw pi-chart-bar",
              //             routerLink: ["/home/DashBoard"],
              //         }
              //     ],
              // },
              {
                  label: "Ship",
                  icon: "pi pi-car",
                  routerLink: ["/"],
                  items: [
                      
                    //   {
                    //       label: "Dynamic Forms",
                    //       icon: "pi pi-fw pi-file",
                    //       routerLink: ["/home/DynamicForms"],
                    //   },
                      {
                          label: "Ship Now",
                          icon: "pi pi-car",
                          routerLink: ["/home/ShipRequestList"],
                      },
                    //   {
                    //       label: "xShip",
                    //       icon: "pi pi-fw pi-file",
                    //       routerLink: ["/home/xShip"],
                    //   },
                    //   {
                    //     label: "Ship",
                    //     icon: "pi pi-fw pi-truck",
                    //     routerLink: ["/home/Ship"],
                    // },
                    {
                      label: "Track Now",
                      icon: "pi pi-fw pi-truck",
                      routerLink: ["/home/TrankShipment"],
                  },
  
          //   {
          //     label: "Pack & Ship V2",
          //     icon: "pi pi-fw pi-truck",
          //     routerLink: ["/home/PackShipV2"],
          // },

          {
            label: 'Quote Now',
            icon: 'pi pi-fw pi-table',
            routerLink: ['/home/Manifest'],
          },
          // {
          //     label: "consolidation",
          //     icon: "pi pi-fw pi-tags",
          //     routerLink: ["/home/consolidation"],
          // },
          //   {
          //     label: "Editor",
          //     icon: "pi pi-fw pi-file",
          //     routerLink: ["/home/Editor"],
          // },
          // {
          //     label: "Routing Guide",
          //     icon: "pi pi-fw pi-map",
          //     routerLink: ["/home/routingguide"],
          // }
        ],
      },
      //   {
      //       label: "Track",
      //       icon: "pi  map-marker",
      //       routerLink: ["/uikit"],
      //       items: [
      //           {
      //               label: "File",
      //               icon: "pi pi-fw pi-file",
      //               routerLink: ["/uikit/file"],
      //           },
      //           {
      //               label: "Chart",
      //               icon: "pi pi-fw pi-chart-bar",
      //               routerLink: ["/uikit/charts"],
      //           },
      //           {
      //               label: "Misc",
      //               icon: "pi pi-fw pi-circle-off",
      //               routerLink: ["/uikit/misc"],
      //           },
      //       ],
      //   },
      // {
      //     label: "Prime Blocks",
      //     icon: "pi pi-fw pi-prime",
      //     routerLink: ["/blocks"],
      //     items: [
      //         {
      //             label: "Free Blocks",
      //             icon: "pi pi-fw pi-eye",
      //             routerLink: ["/blocks"],
      //             badge: "NEW",
      //         },
      //         {
      //             label: "All Blocks",
      //             icon: "pi pi-fw pi-globe",
      //             url: ["https://www.primefaces.org/primeblocks-ng"],
      //             target: "_blank",
      //         },
      //     ],
      // },
      // {
      //     label: "Utilities",
      //     icon: "pi pi-fw pi-compass",
      //     routerLink: ["/utilities"],
      //     items: [
      //         {
      //             label: "PrimeIcons",
      //             icon: "pi pi-fw pi-prime",
      //             routerLink: ["utilities/icons"],
      //         },
      //         {
      //             label: "PrimeFlex",
      //             icon: "pi pi-fw pi-desktop",
      //             url: ["https://www.primefaces.org/primeflex/"],
      //             target: "_blank",
      //         },
      //     ],
      // },
      // {
      //     label: "Pages",
      //     icon: "pi pi-fw pi-copy",
      //     routerLink: ["/pages"],
      //     items: [
      //         {
      //             label: "Crud",
      //             icon: "pi pi-fw pi-pencil",
      //             routerLink: ["/pages/crud"],
      //         },
      //         {
      //             label: "Calendar",
      //             icon: "pi pi-fw pi-calendar-plus",
      //             routerLink: ["/pages/calendar"],
      //         },
      //         {
      //             label: "Timeline",
      //             icon: "pi pi-fw pi-calendar",
      //             routerLink: ["/pages/timeline"],
      //         },
      //         {
      //             label: "Landing",
      //             icon: "pi pi-fw pi-globe",
      //             url: "assets/pages/landing.html",
      //             target: "_blank",
      //         },
      //         {
      //             label: "Login",
      //             icon: "pi pi-fw pi-sign-in",
      //             routerLink: ["/login"],
      //         },
      //         {
      //             label: "Invoice",
      //             icon: "pi pi-fw pi-dollar",
      //             routerLink: ["/pages/invoice"],
      //         },
      //         {
      //             label: "Help",
      //             icon: "pi pi-fw pi-question-circle",
      //             routerLink: ["/pages/help"],
      //         },
      //         {
      //             label: "Wizard",
      //             icon: "pi pi-fw pi-star-fill",
      //             routerLink: ["/wizard"],
      //         },
      //         {
      //             label: "Error",
      //             icon: "pi pi-fw pi-times-circle",
      //             routerLink: ["/error"],
      //         },
      //         {
      //             label: "Not Found",
      //             icon: "pi pi-fw pi-exclamation-circle",
      //             routerLink: ["/notfound"],
      //         },
      //         {
      //             label: "Access Denied",
      //             icon: "pi pi-fw pi-lock",
      //             routerLink: ["/access"],
      //         },
      //         {
      //             label: "Empty",
      //             icon: "pi pi-fw pi-circle-off",
      //             routerLink: ["/pages/empty"],
      //         },
      //     ],
      // },
      //   {
      //       label: "Hierarchy",
      //       icon: "pi pi-fw pi-align-left",
      //       items: [
      //           {
      //               label: "Submenu 1",
      //               icon: "pi pi-fw pi-align-left",
      //               items: [
      //                   {
      //                       label: "Submenu 1.1",
      //                       icon: "pi pi-fw pi-align-left",
      //                       items: [
      //                           {
      //                               label: "Submenu 1.1.1",
      //                               icon: "pi pi-fw pi-align-left",
      //                           },
      //                           {
      //                               label: "Submenu 1.1.2",
      //                               icon: "pi pi-fw pi-align-left",
      //                           },
      //                           {
      //                               label: "Submenu 1.1.3",
      //                               icon: "pi pi-fw pi-align-left",
      //                           },
      //                       ],
      //                   },
      //                   {
      //                       label: "Submenu 1.2",
      //                       icon: "pi pi-fw pi-align-left",
      //                       items: [
      //                           {
      //                               label: "Submenu 1.2.1",
      //                               icon: "pi pi-fw pi-align-left",
      //                           },
      //                       ],
      //                   },
      //               ],
      //           },
      //           {
      //               label: "Submenu 2",
      //               icon: "pi pi-fw pi-align-left",
      //               items: [
      //                   {
      //                       label: "Submenu 2.1",
      //                       icon: "pi pi-fw pi-align-left",
      //                       items: [
      //                           {
      //                               label: "Submenu 2.1.1",
      //                               icon: "pi pi-fw pi-align-left",
      //                           },
      //                           {
      //                               label: "Submenu 2.1.2",
      //                               icon: "pi pi-fw pi-align-left",
      //                           },
      //                       ],
      //                   },
      //                   {
      //                       label: "Submenu 2.2",
      //                       icon: "pi pi-fw pi-align-left",
      //                       items: [
      //                           {
      //                               label: "Submenu 2.2.1",
      //                               icon: "pi pi-fw pi-align-left",
      //                           },
      //                       ],
      //                   },
      //               ],
      //           },
      //       ],
      //   },
      // {
      //     label: "Get Started",
      //     icon: "pi pi-fw pi-download",
      //     items: [
      //         {
      //             label: "Documentation",
      //             icon: "pi pi-fw pi-file",
      //             routerLink: ["/documentation"],
      //         },
      //         {
      //             label: "Buy Now",
      //             icon: "pi pi-fw pi-money-bill",
      //             url: ["https://www.primefaces.org/store"],
      //         },
      //     ],
      // },
    ];
  }
}
