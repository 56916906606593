import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ColumnConfigService {
  private configUrl = `${environment.apiUrl}/profile/config`; // API endpoint to fetch column config
  private profileDetailsSubject = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) {}

  getColumnConfig(): Observable<any> {
    return this.http.get(this.configUrl);
  }
  postColumnConfig(
    userId: string,
    module: string,
    columnConfig: any
  ): Observable<any> {
    const body = {
      user_id: userId,
      moduletype: module,
      [module]: columnConfig,
    };

    return this.http.post(this.configUrl, body);
  }
  // Getter method to get profile details stored in the service
  getProfileDetails(): Observable<any> {
    return this.profileDetailsSubject.asObservable();
  }

  // Setter method to update profile details in the service
  setProfileDetails(profileDetails: any): void {
    this.profileDetailsSubject.next(profileDetails);
  }
}
