
<!-- Message Service code -->
<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<p-toast position="bottom-center" key="c" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{ message.summary }}</h4>
                <p>{{ message.detail }}</p>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" pButton label="Yes" class="p-button-success"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton label="No" class="p-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>

<!-- Message Service end -->

<div class="card"  *ngIf="isDataLoaded">
    <p-toolbar styleClass="p-mb-4">
        <ng-template pTemplate="right">
            <button pButton label="Add Rule" icon="pi pi-plus" [routerLink]="['/home/newroutingrule']"></button>


        </ng-template>
        <ng-template pTemplate="left">
            <label class="mr-2 " style="font-size: 2rem "> Routing Guide</label>
        </ng-template>
        

    </p-toolbar>

    <p-table [value]="rules" responsiveLayout="scroll" [scrollable]="true" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped ">
        <ng-template pTemplate="header">
            <tr>
                <th>Id</th>
                <th>Rule Name</th>
                <th>Location Id</th>
                <th>Priority</th>
                <th>Valid From</th>
                <th>Valid To</th>
                <th>Is Active</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rule>
            <tr>
                <td>{{rule.id}}</td>
                <td>{{rule.RuleName}}</td>
                <td>{{rule.LocationId}}</td>
                <td>{{rule.Priority}}</td>
                <td>{{rule.ValidFrom}}</td>
                <td>{{rule.ValidTo}}</td>
                <td>{{rule.IsActive}}</td>
                <td> <button pButton  icon="pi pi-pencil" [routerLink]="['/home/newroutingrule',rule.id]"></button></td>
            </tr>
            
        </ng-template>
    </p-table> </div>
<!--
<p-dialog [style]="{width:'99%' , height:'100%'}" [(visible)]="newRule_flag" [modal]="true" styleClass="p-fluid">
    <div class="card">
        <div class="grid p-fluid mt-2">
            <div class="field col-12 md:col-2">
                <span class="p-float-label">
                    <input type="text" id="rule" pInputText [(ngModel)]=ruledata.RuleName>
                    <label for="rule">Rule Name</label>
                </span>
            </div>
            <div class="field col-12 md:col-2">
                <span class="p-float-label">
                    <p-dropdown [options]="locations" optionLabel="LocationName" [showClear]="true" optionValue="LocationId" [autoDisplayFirst]="false" [(ngModel)]="ruledata.LocationId" id="location"></p-dropdown>
                    <label for="location">Location</label>
                </span>
            </div>
            <div class="field col-12 md:col-1">
                <span class="p-float-label">
                    <input type="number" id="priority" [(ngModel)]="ruledata.Priority" pInputText>
                    <label for="priority">Priority</label>
                </span>
            </div>

            <div class="field col-12 md:col-2">
                <span class="p-float-label">
                    <p-calendar [showIcon]="true" id="validfrom" [(ngModel)]="ruledata.ValidForm"></p-calendar>
                    <label for="validfrom">Valid From</label>
                </span>
            </div>
            <div class="field col-12 md:col-2">
                <span class="p-float-label">
                    <p-calendar [showIcon]="true" id="validto" [(ngModel)]="ruledata.ValidTo"></p-calendar>
                    <label for="validto">Valid To</label>
                </span>
            </div>
            <div class="field col-12 md:col-2">
                <span>
                    <p-checkbox name="group1" [binary]="true" inputId="isActive" [(ngModel)]="ruledata.IsActive"></p-checkbox>
                    <label styleClass="mr-4" for="isActive">IsActive</label>
                </span>
            </div>
        </div>
   
       
       <div> <p-table [value]="tabledata" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped "
        responsiveLayout="scroll"  [scrollable]="true" scrollHeight="350px">
            <ng-template pTemplate="header">
                <tr>
                    <th>FieldName</th>
                    <th>Condition</th>
                    <th>From Range</th>
                    <th>To Range</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-record>
                <tr>
                    <td>{{record['fieldName']}}</td>
                    <td>
                        <p-dropdown [options]="condition" [autoDisplayFirst]="false" [(ngModel)]='record.Condition'></p-dropdown>
                       
                    </td>
                    <td><input type="text" pInputText [(ngModel)]="record.fromRange"></td>
                    <td><input type="text" pInputText [(ngModel)]="record.toRange"></td>
                </tr>
            </ng-template>



        </p-table>
    </div>
        <div>
            <button pButton label="Save"  (click)="saveRule()"></button>

        </div> -->

<!--       
    </div>
    <div>
        <button pButton label="newrulecomponent" [routerLink]="['/newroutingrule']"></button>
    </div>
    

</p-dialog> -->