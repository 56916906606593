<div class="shipToForm">
    <p-dialog showHeader="false" [(visible)]="showPopUp" modal="modal" 
        [style]="{ 'width': '25%' ,position: 'absolute', right: 0, top:0,'height':'200px','overflow':'auto' }">
        <div class="dialog-header">
            <div class="grid m-0">
                <div class="col-10 py-0">
                    <h4 class="mb-0">Create Address</h4>
                </div>
                <div class="col-2 py-0 text-right">
                    <img src="../../../../assets/images/close-icon.png" class="dialog-close" (click)="showPopUp = false" />
                </div>
            </div>
        </div>
        <div class="ship-to-form">
            <div class="row">
                <div class="form-group py-1">
                    <label for="locationFrom">Company Name</label>
                       <div class="p-inputgroup p-input-icon-right">
                           <input  pInputText id="companyNameTo" maxlength="35"
                                [(ngModel)]="shipment.ShipTo.COMPANY" [disabled]="isDisabled" />
                        </div>
                    </div>
                <div class="form-group py-1">
                    <label for="locationName">Contact Name</label>
                    <input pInputText id="displayName" maxlength="35" [(ngModel)]="shipment.ShipTo.CONTACT"
                         [disabled]="isDisabled" />
                </div>
                <div class="form-group py-1">
                    <label for="company">Address Line 1</label>
                    <input pInputText id="addressLine1" maxlength="35" [(ngModel)]="shipment.ShipTo.ADDRESS_LINE1"
                         [disabled]="isDisabled" />
                </div>
                <div class="form-group py-1">
                    <label for="contact">Address Line 2</label>
                    <input pInputText id="addressLine2" maxlength="35" [(ngModel)]="shipment.ShipTo.ADDRESS_LINE2"
                         [disabled]="isDisabled" />
                </div>
                <div class="form-group py-1" style="display: none;">
                    <label for="AddressLine 3">Address Line 3</label>
                    <input pInputText id="addressLine3" maxlength="35" [(ngModel)]="shipment.ShipTo.ADDRESS_LINE3"
                         [disabled]="isDisabled" />
                </div>
                <div class="form-group py-1">
                    <label for="city">City</label>
                    <input pInputText id="city" maxlength="35" [(ngModel)]="shipment.ShipTo.CITY"
                         [disabled]="isDisabled" />
                </div>
                <div class="form-group py-1">
                    <label for="stateFrom">State</label>
                    <!-- <p-dropdown [(ngModel)]="shipment.ShipTo.STATE" [options]="Master_states" [disabled]="isDisabled"></p-dropdown> -->
                    <input pInputText id="State" maxlength="3" [(ngModel)]="shipment.ShipTo.STATE"
                         [disabled]="isDisabled" />
    
                </div>
                <div class="form-group py-1">
                    <label for="Zipcode">Zip Code</label>
                    <input pInputText id="Zipcode" maxlength="10" [(ngModel)]="shipment.ShipTo.ZIPCODE"
                         [disabled]="isDisabled" />
                </div>
                <div class="form-group py-1">
                    <label for="countryFrom">Country</label>
                    <p-dropdown optionLabel="name" optionValue="code" id="countryTo" [style]="{'minWidth':'100px'}"
                        [(ngModel)]="shipment.ShipTo.COUNTRY" 
                        [options]="countries"  [filter]="true"
                        [disabled]="isDisabled"></p-dropdown>
                </div>
                <div class="form-group py-1">
                    <label for="city">Email</label>
                    <input pInputText id="email" maxlength="50" [(ngModel)]="shipment.ShipTo.EMAIL"
                         [disabled]="isDisabled" />
                </div>
                <div class="form-group py-1">
                    <label for="phone">Phone No</label>
                    <input pInputText id="phone" maxlength="20" [(ngModel)]="shipment.ShipTo.PHONE"
                         [disabled]="isDisabled" />
                </div>
                <div class="form-group py-1">
                    <label for="addressTypeFrom">Address Type</label>
                    <p-dropdown [options]="addressTypes" [(ngModel)]="shipment.ShipTo.ADDRESS_TYPE"
                         [disabled]="isDisabled"></p-dropdown>
                </div>
            </div>
        </div>
      
        <ng-template pTemplate="footer">
            <div class="row d-flex p-fluid pt-3">
                <div class="d-flex justify-content-between">
                    <div class="p-d-flex footer-reset">
                        <!-- <p-button label="Cancel" (click)="cancelShipTo()" ></p-button> -->
                    </div>
                    <div class="p-d-flex footer-save">
                    <button class="btn-default-css btnSave" (click)="saveShipTo()">Save</button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-dialog>
</div>