import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private shipmentSubject = new BehaviorSubject<any>(null);
  shipment$ = this.shipmentSubject.asObservable();

  private trackingStatusSubject = new BehaviorSubject<any>(null);
  trackingStatus$ = this.trackingStatusSubject.asObservable();

  private internationalHUSubject = new BehaviorSubject<any>(null);
  internationalHU$ = this.internationalHUSubject.asObservable();

  private shipmentDataSource = new BehaviorSubject<any>(null);
  currentShipmentData = this.shipmentDataSource.asObservable();

  private autopackSubject = new BehaviorSubject<boolean>(false);
  autopack$ = this.autopackSubject.asObservable();

  private autoscanSubject = new BehaviorSubject<boolean>(false);
  autoscan$ = this.autoscanSubject.asObservable();

  private fromUrlSubject = new BehaviorSubject<any>(null);
  fromUrlSubject$ = this.fromUrlSubject.asObservable();

  updateShipment(shipment: any): void {
    this.shipmentSubject.next(shipment);
  }

  updateTrackingStatus(trackingStatus: any): void {
    this.trackingStatusSubject.next(trackingStatus);
  }

  updateinternationalHU(internationalHU: any): void {
    this.internationalHUSubject.next(internationalHU);
  }
  setShipmentData(data: any) {
    this.shipmentDataSource.next(data);
  }

  getShipmentData() {
    return this.currentShipmentData;
  }

  setAutopack(status: boolean): void {
    this.autopackSubject.next(status);
  }

  setAutoscan(status: boolean): void {
    this.autoscanSubject.next(status);
  }

  updatefromURL(fromUrlSubject: any): void {
    this.fromUrlSubject.next(fromUrlSubject);
  }
}
