import { Component, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERPSHIPService } from '../erpship.service';
import { MenuItem, MessageService } from 'primeng/api';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-manifest',
  templateUrl: './manifest.component.html',
  styleUrls: ['./manifest.component.scss'], providers: [MessageService],
})
export class ManifestComponent implements OnInit {
  columns = [
    {
      header: 'Request ID',
      fieldName: 'HeaderInfo.DocumentNumber',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Created Date',
      fieldName: 'HeaderInfo.CreatedDate',
      fieldType: 'Date',
      editable: false,
      visible: true,
      datatype: 'date',
    },
    {
      header: 'Ship Date',
      fieldName: 'HeaderInfo.ShipDate',
      fieldType: 'Date',
      editable: false,
      visible: true,
      datatype: 'date',
    },
    {
      header: 'Shipment Type',
      fieldName: 'HeaderInfo.ShipmentType',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Carrier Name',
      fieldName: 'CarrierDetails.Carrier',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Service Level', 
      fieldName: 'CarrierDetails.ServiceName',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Tracking Number',
      fieldName: 'Packages[0].TrackingNumber',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Status',
      fieldName: 'HeaderInfo.Status',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Ship To Company',
      fieldName: 'ShipTo.COMPANY',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Contact',
      fieldName: 'ShipTo.CONTACT',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
   
    {
      header: 'Ship To Address Line 1',
      fieldName: 'ShipTo.ADDRESS_LINE1',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To City',
      fieldName: 'ShipTo.CITY',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To State',
      fieldName: 'ShipTo.STATE',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Country',
      fieldName: 'ShipTo.COUNTRY',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Zipcode',
      fieldName: 'ShipTo.ZIPCODE',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Phone',
      fieldName: 'ShipTo.PHONE',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Email',
      fieldName: 'ShipTo.EMAIL',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Requester Name',
      fieldName: 'HeaderInfo.CreatedUser',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Connected To',
      fieldName: 'HeaderInfo.FeederSystem',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Order Type',
      fieldName: 'HeaderInfo.DocumentType',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    }, 
    {
      header: 'Priority Level',
      fieldName: 'HeaderInfo.PriorityLevel',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    }
  ];
  mandatoryColumns = [
    'Request ID',
    'Created Date',
    'Ship Date',
    'Carrier Name',
    'Service Level',
    'Tracking Number',
    'Status',
    'Ship To Company',
    'Ship To Contact'
  ];
  ConfigKey='manifest';
  finalColumns: any;

  generateManiCancel() {
    console.log('Method not implemented.');
  }
  accounts: any[];
  selectedAccount: any;
  fromTime: any;
  toTime: any;
  scrollHeight = '345px';

  displayedArray: any[] = []; // Data to display in the table
  page: number = 0; // Current page index

  filterClear() {
    console.log('Method not implemented.');
  }
  filterCancel() {
    console.log('Method not implemented.');
  }
  CostCenterPopup() {
    console.log('Method not implemented.');
  }
  UserPopup() {
    console.log('Method not implemented.');
  }
  manifestIdPopup() {
    console.log('Method not implemented.');
  }
  ShipperNamePopup() {
    console.log('Method not implemented.');
  }
  ShipToCompanyPopup() {
    console.log('Method not implemented.');
  }
  Track: any;
  changeStatus(status) {
    this.thumbnailStatusOptions.forEach(item=>{
      item.active = '';
    })
    status.active = 'active'
  }
  
  showDialog: boolean = false;
  isDisabled: boolean = false;

  generateManifest() {
    // Logic to generate manifest
  }
  
  @ViewChild('dt') dt: any;
  template: any;
  first = 0;
  rows = 50;

  filters:any={};
  
  isDataLoaded: boolean = false;
  passingid: any = '';
  shipRequests: any = [];
  Org_shipRequests:any[]=[];
  selectedShipRequest: any[] = [];
  totalRecords = 0;
  tableProperties_dialog: boolean = false;

  xfields: any[] = [];
  xcolumns: any[] = [];
  Carrier='';
  shipDateFrom: Date= new Date();
  shipDateTo: Date=new Date();
  documentType='';
  conditions:any ={}
  filterdialogFlag=false;
  filterFields:any=[];
  statusOptions: any;
  items: MenuItem[];
  ManifestItems: MenuItem[];

  
  //Added fields
  filterForm: FormGroup | undefined;
  locationOptions: any[] = []; // Populate with real data
  supplierLocationOptions: any[] = []; // Populate with real data
  carrierOptions: any[] = []; // Populate with real data
  // statusOptions: any[] = []; // Populate with real data
  paymentTypeOptions: any[] = []; // Populate with real data
  shipperOptions: any[] = []; // Populate with real data
  costCenterOptions: any[] = []; // Populate with real data
  countryOptions: any[] = []; // Populate with real data
  manifestFlagOptions
  
  selectedLocation: any;
  selectedSupplierLocation: any;
  selectedCarrier: any;
  selectedRegion: any;
  selectedStatus: any;
  selectedPaymentType: any;
  selectedShipper: any;
  selectedCostCenter: any;
  selectedCountry: any;
  selectedFeederSystem: any;

  constructor(private router: Router, public messageService: MessageService, private xfservice: ERPSHIPService,private renderer: Renderer2) {
    this.xfservice.processAuth().subscribe((data) => { }, error => {
      console.log("No token found")
      this.router.navigate(['']);
    });

    this.items = [
      {
          label: 'Copy',
          command: () => {
              this.Copy();
          }
      },
      {
          label: 'View',
          command: () => {
              this.View();
          }
      },
      {
          label: 'Update Status',
          command: () => {
              this.Status();
          }
      }
    ];

    this.ManifestItems = [
      {
          label: 'Location Name',
          command: () => {
              this.Copy();
          }
      },
      {
          label: 'Carrier',
          command: () => {
              this.View();
          }
      },
      {
          label: 'Account #',
          command: () => {
              this.Status();
          }
      },
      {
          label: 'Ship From Date',
          command: () => {
              this.Status();
          }
      },
      {
          label: 'Ship To Date',
          command: () => {
              this.Status();
          }
      },
      {
          label: 'Ship Time (HH:MM)',
          command: () => {
              this.Status();
          }
      }
    ];
  }
  Copy() {
    console.log('Method not implemented.');
  }
  View() {
    throw new Error('Method not implemented.');
  }
  Status() {
    throw new Error('Method not implemented.');
  }
  serviceNames :any;
  ngOnInit(): void {
    this.setScrollHeight();
    this.xfields=[];

    this.xfservice.getLocationMasterData().subscribe((data) => {
      this.countryOptions = data.countrylist;
      this.statusOptions = data.shipmentstatuses;
      this.carrierOptions = data?.carriers;
      this.locationOptions = data?.location;
      this.serviceNames = data?.carrierservices;
    });

    this.xfservice.getScreenTemplateByName('ManifestTable')
    .subscribe((mdata:any)=>{
      if (mdata.length >0)
      {
        this.xfields = mdata[0].details.map(detail => ({
          ...detail,
          IncludeHeader: this.addSpacesToTitles(detail.IncludeHeader),
          fields: detail.fields.map(field => ({
            ...field,
            title: this.addSpacesToTitles(field.title)
          }))
        }));
      }
     //console.log(JSON.stringify(this.xfields))
      
    this.xfields.forEach((item)=>{
     // console.log(JSON.stringify(item))
     item.fields.forEach((field:any)=>{
       this.xcolumns.push(item.fieldHeader +'.' +field.fieldName)
       if(field['filterField']==true && field['fieldName']!= "ShipDate") 
       {
        this.filters[item.fieldHeader +'.' +field.fieldName]='';
       }
       else if(field['fieldName']=="ShipDate"){
        this.filters['shipDateFrom']='';
        this.filters['shipDateTo']='';
       }
     })
    })
   
 //this.xcolumns=["HeaderInfo.ERP","HeaderInfo.DocumentType"]


      //'----------------------------------------'

      this.xfservice.getScreenTemplateByName('ShipScreen')
      .subscribe((tdata: any) => {
        this.template = tdata[0].details;
        if( this.xfields.length < 1)
        {
          Object.keys(this.template).forEach((key: string) => { 
            if(key != 'FOOTER_LEFT' && key != 'FOOTER_RIGHT' && key!='MasterData'  && key!='Items'  && key!='Packages'  && key!='InternationalDetails' ){
              this.xfields.push({ visible: true, fieldHeader: key, fields: this.template[key].fields }) 
            }
          })
        }
        
console.log(this.xfields)
//ShipRequest //shipments
        this.xfservice
        .getxFormRecordsByFromName('ShipRequest')
        .subscribe((data: any) => {
          data.forEach((item: any) => {
            //  this.shipRequests.push(item.details)
            if (item?.details.CarrierDetails) {
              // Assuming details contains a ServiceCode you want to match dynamically
              const serviceCodeToMatch = item.details.CarrierDetails.ServiceName; // dynamically set ServiceCode            
              // Find the matching service based on ServiceCode
              const matchedService = this.serviceNames.find(service => service.ServiceCode === serviceCodeToMatch);
            
              if (matchedService) {
                // Display the dynamically matched ServiceName            
                const serviceName = matchedService.ServiceName;            
                // Push the details with the matched service name
                this.shipRequests.push({
                  ...item.details, // Spread the existing details object
                  CarrierDetails: {
                    ...item.details.CarrierDetails, // Spread the existing CarrierDetails
                    ServiceName: serviceName // Use the updated ServiceName
                  }
                });
                } else
                {
                  this.shipRequests.push({
                  ...item.details, // Spread the existing details object
                  CarrierDetails: {
                    ...item.details.CarrierDetails, // Spread the existing CarrierDetails
                    ServiceName: item.details?.CarrierDetails.ServiceName  // Use the updated ServiceName
                  }   }           
                );
                }
            }
          });

          this.Org_shipRequests = Array.from(this.shipRequests)
          this.totalRecords = data.length;
          this.shipRequests.forEach((item)=>{
            item.buttonItem=this.items
          })
          this.isDataLoaded = true;
          this.updateDisplayedData()
        });
      })
      //--------------------------------------------
    })
  }

  thumbnailStatusOptions: any = [
     
    {id: 1, code: 'OPN', description: 'Total Shipments', colorpicker: '#4499e3'},
    {id: 2, code: 'SPD', description: 'Total No. of Pieces', colorpicker: '#FFA500'},
    {id: 3, description: 'Total Weight', code: 'PLN', colorpicker: '#008000'}
  ]
  addSpacesToTitles(text: string): string {
    return text.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  save(arg0: string) {
    console.log('Method not implemented.',arg0);
  }

  //Open Table properties
  openTableProperties() {
    this.tableProperties_dialog = true;
  }

  //Save Manifest Table properties
  // Save/Update TemplateChanges
  SaveTemplate(templatename: any): void {
    this.xfields.forEach((item)=>{
      if (item.visible==true)
      {
        if(item.IncludeHeader ==undefined)
           item["IncludeHeader"]=""
      }
    })

    this.xfservice
      .saveScreenTemplateByName(templatename, this.xfields)
      .subscribe((data) => {

        this.tableProperties_dialog = false
        // this.messageService.add({
        //   key: 'tc',
        //   severity: 'success',
        //   summary: 'Template',
        //   detail: templatename + ' Properties are successfully saved.',
        // });


      });

  }

  applyFilterGlobal($event: any, stringVal: any): void {
    if (($event.target as HTMLInputElement).value) {
        this.shipRequests = this.filterNestedArray(this.Org_shipRequests, ($event.target as HTMLInputElement).value)
    } else {
        this.shipRequests = Array.from(this.Org_shipRequests)
      }
    this.updateDisplayedData()
   }

   filterNestedArray(jsonArray: any[], searchTerm: string): any[] {
    return jsonArray.filter(item => {
      if (typeof item === 'object' && item !== null) {
        return Object.values(item).some(value => this.filterNestedArray([value], searchTerm).length > 0);
      }
      return String(item).includes(searchTerm);
    });
  }

  getShipRequest(){
    if(this.Carrier != '')
    this.conditions["Carrier"]="details->'CarrierDetails'->>'Carrier'='" + this.Carrier +"'"

  
  
  if(this.documentType !='')
     this.conditions["DocumentType"]="details->'HeaderInfo'->>'DocumentType'='" + this.documentType +"'";
     console.log(JSON.stringify(this.conditions))

  }
  applyFilters(){
    var fromdate=''
    var toDate=''
    console.log(this.filters['shipDateFrom'])
    if(this.filters['shipDateFrom']!='' && this.filters['shipDateto']!=''){
    
      this.shipDateFrom=this.filters['shipDateFrom'];
      this.shipDateTo=this.filters['shipDateTo']
      console.log(this.shipDateFrom)
     
      fromdate += this.shipDateFrom.getFullYear();
      if((this.shipDateFrom.getMonth() + 1) < 10){
       fromdate += '-0' +  (this.shipDateFrom.getMonth()+1) 
      }
      else
       fromdate += '-' + (this.shipDateFrom.getMonth()+1)
      if(this.shipDateFrom.getDate()<10)
       fromdate += '-0' +this.shipDateFrom.getDate();
      else
       fromdate += '-' + this.shipDateFrom.getDate();
      toDate += this.shipDateTo.getFullYear() ;
      if((this.shipDateTo.getMonth() + 1) < 10){
       toDate += '-0' +  (this.shipDateTo.getMonth()+1) 
      }
      else
       toDate += '-' + (this.shipDateTo.getMonth()+1)
      if(this.shipDateTo.getDate()<10)
       toDate += '-0' +this.shipDateTo.getDate();
      else
       toDate += '-' + this.shipDateTo.getDate();  
   
    
    this.conditions['shipdate']="details->'HeaderInfo'->>'ShipDate' between '" + fromdate +"' and '" + toDate +"'"
    }
    var keys=Object.keys(this.filters);
    console.log(keys)
    keys.forEach((item,index)=>{
      var condition='';
      var fields: any =[];
      if(item !="shipDateFrom" && item !="shipDateTo"){
        if(this.filters[item]!=''){
          fields=item.split('.')
          condition += "details->'" + fields[0] + "'->>'" + fields[1] +"'='" + this.filters[item] +"'";
          console.log(condition)
          this.conditions["condition" + index]=condition
        }
      }
    })
    console.log(JSON.stringify(this.conditions))
    this.xfservice.getShipRequestByFilters(JSON.stringify(this.conditions)).subscribe((data:any)=>{ 
      this.shipRequests=data
    this.totalRecords = data.length;
    this.isDataLoaded = true;

    this.updateDisplayedData()
    })

  }   
    
   // Update the displayed data based on the current page
   updateDisplayedData() {
    const start = this.page * this.rows;
    const end = Math.min(this.shipRequests.length, start + this.rows);
    this.displayedArray = this.shipRequests.slice(start, end);
    this.totalRecords=this.shipRequests.length
  }

  // Handle page change event from paginator
  onPageChange(page: number) {
    this.page = page;
    this.updateDisplayedData();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.setScrollHeight();
  }

  setScrollHeight() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width >= 1200 && height <= 800) {
      this.scrollHeight = '345px';
    } else {
      this.scrollHeight = '560px';
    }
  }
  
  receiveData(data:any) {
    this.finalColumns = data;
  }
  
}
