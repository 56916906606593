import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERPSHIPService } from '../erpship.service';
import { ChangeDetectorRef } from '@angular/core';
import { saveAs } from 'file-saver-es';
import {
  FormGroup,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ShipRequestToShipNowService } from '../services/ship-now/shiprequesttoshipnow.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ColumnConfigService } from '../services/column-config-service';
import { MessageService } from 'primeng/api';
import { StorageService } from '../services/storage.service';
import { UserDataService } from '../services/user-data.service';
import { formatTrackingData, GetDateTimeFromNumber, GetStatusImageData, GetStatusPayload } from '../track-shipment/track-data/common-data';
import shippingStatus from '../track-shipment/track-data/shippingStatus.json';
interface ModuleConfig {
  [key: string]: string[];
}
@Component({
  selector: 'app-ship-requestlist',
  templateUrl: './ship-request.component.html',
  styleUrls: ['./ship-request.component.scss'],
})
export class ShipRequestComponent implements OnInit {
  @ViewChild('ot') overlayPanel: OverlayPanel;
  first = 0;
  rows = 50;
  isDataLoaded: boolean = false;
  passingid: any = '';
  shipRequests: any[] = [];
  selectedShipRequest: any[] = [];
  items: any[] = [];
  totalRecords = 0;

  locationName: any;
  Carrier: any;
  shipFromDate: any;
  shipToDate: any;
  costCenter: any;
  user: any;
  Status: any;
  feederSystemOptions = [];
  displayedShipRequests: any[] = []; // Data to display in the table
  page: number = 0; // Current page index
  displayDialog: boolean = false;
  searchQuery: string = '';
  selectedColumns: any[] = [];
  scrollHeight = '400px';

  displayColumnPopup: boolean = false;
  filterShipment: any;
  filterEventData: any;
  //status sidebar
  displaySidebar: boolean = false;
  shippingStatusData: any = [];
  moreItems: any = [];
  travelHistory: any;
  selectedShippingStatusData: any;
  selectedCarrierType: string;

  lastDate: string | null = null;
  useAlternateColor: boolean = false;
  colorCache: { [key: string]: string } = {};
  
  columns = [
    {
      header: 'Request ID',
      fieldName: 'HeaderInfo.DocumentNumber',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Created Date',
      fieldName: 'HeaderInfo.CreatedDate',
      fieldType: 'Date',
      editable: false,
      visible: true,
      datatype: 'date',
    },
    {
      header: 'Ship Date',
      fieldName: 'HeaderInfo.ShipDate',
      fieldType: 'Date',
      editable: false,
      visible: true,
      datatype: 'date',
    },
    {
      header: 'Shipment Type',
      fieldName: 'HeaderInfo.ShipmentType',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Carrier Name',
      fieldName: 'CarrierDetails.Carrier',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Service Level',
      fieldName: 'ServiceName',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Tracking Number',
      fieldName: 'Packages[0].TrackingNumber',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Status',
      fieldName: 'HeaderInfo.Status',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Ship To Company',
      fieldName: 'ShipTo.COMPANY',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Contact',
      fieldName: 'ShipTo.CONTACT',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
   
    {
      header: 'Ship To Address Line 1',
      fieldName: 'ShipTo.ADDRESS_LINE1',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To City',
      fieldName: 'ShipTo.CITY',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To State',
      fieldName: 'ShipTo.STATE',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Country',
      fieldName: 'ShipTo.COUNTRY',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Zipcode',
      fieldName: 'ShipTo.ZIPCODE',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Phone',
      fieldName: 'ShipTo.PHONE',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Email',
      fieldName: 'ShipTo.EMAIL',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Requester Name',
      fieldName: 'HeaderInfo.CreatedUser',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Connected To',
      fieldName: 'HeaderInfo.FeederSystem',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Order Type',
      fieldName: 'HeaderInfo.DocumentType',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    }, 
    {
      header: 'Priority Level',
      fieldName: 'HeaderInfo.PriorityLevel',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'RF ID',
      fieldName: 'HeaderInfo.Rfid',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },{
      header: 'Updated',
      fieldName: 'HeaderInfo.updated',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'boolean',
    }
  ];
  progressDetails: any = {
    pageTitle: 'Ship Requests',
    buttonTitle: 'Create Ship Request',
    isButtonAvl: true,
  };
  isProgressDataAvl: boolean = false;

  postColumns: any[] = [];
  mandatoryColumns = [
    'Request ID',
    'Created Date',
    'Ship Date',
    'Carrier Name',
    'Service Level',
    'Tracking Number',
    'Status',
    'Ship To Company',
    'Ship To Contact'
  ];
  finalColumns: any;
  ConfigKey='shiprequest';


  constructor(
    private router: Router,
    private xfservice: ERPSHIPService,
    private cdr: ChangeDetectorRef,
    private rowDataService: ShipRequestToShipNowService,
    private columnConfigService: ColumnConfigService,
    private messageService: MessageService,
    private storageService : StorageService,
    private userContext : UserDataService,
    private cd: ChangeDetectorRef
  ) {
    let data = this.userContext.getDecryptedCookie('usercontext');
    this.userId = data.body.user_id;
    this.xfservice.processAuth().subscribe(
      (data) => {},
      (error) => {
        console.log('No token found');
        this.router.navigate(['']);
      }
    );
  }
  viewShipRequest(shipRequest) {
    this.router.navigate(['/home/shiprequestmodal', 'View-' + shipRequest.id]);
  }
  viewShipNow(shipRequest) {
    shipRequest.isFooterEnabled = false;
    this.rowDataService.setData(shipRequest.HeaderInfo.DocumentNumber);
    this.rowDataService.setShipFromData(shipRequest);
    this.router.navigate(['/home/shipnow'], {
      state: { data: JSON.stringify(shipRequest) },
    });
  }
  //Action from Table
  edit(shipRequest) {
    this.router.navigate(['/home/shiprequestmodal', shipRequest.id]);
    throw new Error('Method not implemented.');
  }
  status(shipRequest) {
    throw new Error('Method not implemented.');
  }
  copy(shipRequest) {
    this.router.navigate(['/home/shiprequestmodal', 'COPY-' + shipRequest.id]);
  }
  delete(shipRequest) {
    console.log('delete functionality ');
  }
  print(shipRequest) {
    console.log('delete functionality ');
  }

  ship(shipRequest) {
    console.log("issue",shipRequest);
    this.rowDataService.setData(shipRequest.HeaderInfo.DocumentNumber);
    this.rowDataService.setFromURLData('shiprequest');
    this.rowDataService.setShipFromData(shipRequest);
    this.router.navigate(['/home/shipnow'], {
      state: { data: JSON.stringify(shipRequest) },
    });
  }

  generateOpenItems = (shipRequest) => {
    // this.cdr.detectChanges();
    return [
      {
        label: 'View',
        command: () => {
          this.viewShipRequest(shipRequest);
        },
      },
      {
        label: 'Edit',
        command: () => {
          this.edit(shipRequest);
        },
      },
      {
        label: 'Copy',
        command: () => {
          this.copy(shipRequest);
        },
      },
      {
        label: 'Delete',
        command: () => {
          this.delete(shipRequest);
        },
      },
      {
        label: 'Print',
        command: () => {
          this.print(shipRequest);
        },
      },
    ];
  };

  generateItems = (shipRequest) => {
    // this.cdr.detectChanges();
    return [
      {
        label: 'Copy',
        command: () => {
          this.copy(shipRequest);
        },
      },
      {
        label: 'Print',
        command: () => {
          this.print(shipRequest);
        },
      },
    ];
  };
  columnConfig: any;
  userId: string;
  countVisibleTrue: number = 0;
  locationData : any;
  openRecordsCount
  cancelledRecordsCount
  shippedRecordsCount
  documentCountList :any;
  ngOnInit(): void {
    console.log(this.userId);
   // this.locationData = this.storageService.getItem('data');
    // Call the service method to post the column conf
    this.xfservice.getLocationMasterData().subscribe((data) => {
      this.progressDetails.shipmentState = data.shipmentstatuses;
      this.isProgressDataAvl = true;
      this.locationData = data;
      if(this.locationData != undefined)
        {
          this.xfservice
          .getxFormRecordsByFromName('ShipRequest')
          .subscribe((data: any) => {  
            data.sort((a, b) => b.id - a.id);
            const validData = data.filter((item: any) => {
              const details = item.details;
              return details && Object.keys(details).length > 1; // Ensures 'details' contains more than just 'id'
            });
            // Initialize counters
            let openCount = 0;
            let shippedCount = 0;
            let cancelledCount = 0;
      
            validData.forEach((item: any) => {
              const details = item.details;
      
              // Handling Status and Open/Close logic
              if (details?.HeaderInfo?.Status === 'Open') {
                details.items = this.generateOpenItems(details);
                details.isOpenStatus = true;
                openCount++; // Increment openCount if status is 'Open'
              } else if (details?.HeaderInfo?.Status === 'Shipped') {
                details.items = this.generateItems(details);
                details.isOpenStatus = false;
                shippedCount++; // Increment shippedCount if status is 'Shipped'
              } 
              else if(details?.HeaderInfo?.Status === 'Cancelled'){
                details.items = this.generateItems(details);
                details.isOpenStatus = false;
                cancelledCount++; // Increment shippedCount if
              }
              else {
                details.items = this.generateItems(details);
                details.isOpenStatus = false;
              }
      
              if (details?.Packages && details?.Packages.length > 0) {
                // Loop through all packages to find the tracking number
                const trackingNumber = details.Packages.find(pkg => pkg.TrackingNumber)?.TrackingNumber;
                details.TrackingNumber = trackingNumber || '';  // Default if no tracking number is found
              }
      
              if (details?.CarrierDetails) {
                // Assuming details contains a ServiceCode you want to match dynamically
                const serviceCodeToMatch = details?.CarrierDetails.ServiceName;
                // Find the matching service based on ServiceCode
                const matchedService = this.locationData?.carrierservices.find(service => service.ServiceCode === serviceCodeToMatch);
      
                if (matchedService) {
                  const serviceName = matchedService.ServiceName;
                  // Push the details with the matched service name
                  this.shipRequests.push({
                    ...details,
                    TrackingNumber: details?.TrackingNumber,
                    ServiceName: serviceName // push the dynamically found ServiceName
                  });
                }
                else
                {
                  this.shipRequests.push({
                    ...details,
                    TrackingNumber: details?.TrackingNumber,
                    ServiceName: details?.CarrierDetails.ServiceName // push the dynamically found ServiceName
                  });
                }
        }
      });
      
            // Store the counts in variables or use them as needed
            this.totalRecords = validData.length;
            this.openRecordsCount = openCount; // Add this property to track open count
            this.shippedRecordsCount = shippedCount;
            this.cancelledRecordsCount = cancelledCount // Add this property to track shipped count
            this.documentCountList = {
              'totalRecords': this.totalRecords || 0,
              'openRecords' : this.openRecordsCount|| 0,
              'shippedRecords' : this.shippedRecordsCount|| 0,
              'cancelledRecords': this.cancelledRecordsCount || 0
            }
            this.isDataLoaded = true;
          });
        }
    });
  }

  showSelectedRecords(): void {
    alert(this.selectedShipRequest.length);
  }

  openNewShipRequest(): void {
    this.router.navigate(['/home/shipRequest']);
  }

  editShipRequest(data: any): void {}

  downloadFile(): void {
    alert('DOWNLAOD STARTED');
  }

  //DOWNLOAD NESTED OBJECT S

  pivot(arr: any) {
    var mp = new Map();

    function setValue(a: any, path: any, val: any) {
      if (Object(val) !== val) {
        // primitive value
        var pathStr = path.join('.');
        var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
        a[i] = val;
      } else {
        for (var key in val) {
          setValue(a, key == '0' ? path : path.concat(key), val[key]);
        }
      }

      return a;
    }

    var result = arr.map((obj: any) => setValue([], [], obj));
    return [[...mp.keys()], ...result];
  }

  toCsv(arr: any) {
    return arr
      .map((row: any) =>
        row
          .map((val: any) => (isNaN(val) ? JSON.stringify(val) : +val))
          .join(',')
      )
      .join('\n');
  }

  iconClick(event){
    let d = document.getElementById("columnConfig");
    d.style.display = 'block';

  }

  receiveData(data:any) {
    this.finalColumns = data;
  }

  modalOpenClick() {
    this.router.navigate(['/home/shiprequestmodal']);
  }

  trackingSteps = [
    { status: 'Cancelled', image: 'assets/images/statusProgress.png' },  // Arrow images for each status
    { status: 'Delivered', image: 'assets/images/statusProgress.png' },
    { status: 'In-Transit', image: 'assets/images/statusProgress.png' },
    { status: 'Shipped', image: 'assets/images/statusProgress.png' }
  ];
  
  getCurrentStepImage(status): string {
    const currentStep = this.trackingSteps.find(step => step.status === status);
    return currentStep ? currentStep.image : '';
  }
  
  getRowBackgroundColor(date: string): string {
    if (!this.colorCache[date]) {
      if (this.lastDate !== date) {
        this.useAlternateColor = !this.useAlternateColor;
        this.lastDate = date;
      }
      this.colorCache[date] = this.useAlternateColor ? '#f0f0f0' : '#ffffff';
    }
    return this.colorCache[date];
  }

  OpenStatusbar = (shipRequest: any) => {
    // console.log(shipRequest);
    //
    this.selectedCarrierType = shipRequest.CarrierDetails['Carrier']?.toLocaleLowerCase();
    //
    const payload = GetStatusPayload(this.selectedCarrierType);
   this.filterShipment = {
      carrierName: shipRequest.CarrierDetails.Carrier,
      trackingNumber: shipRequest.Packages[0]?.TrackingNumber,
      service: shipRequest.CarrierDetails.ServiceName,
      deliveredTo: shipRequest.ShipTo.COMPANY,
      shipDate: shipRequest.HeaderInfo.ShipDate ? this.TableformatDate(shipRequest.HeaderInfo.ShipDate): '',
      standardTransit: shipRequest.HeaderInfo.ShipDate ? this.TableformatDate(shipRequest.HeaderInfo.ShipDate)+ ' before '+ this.TableformatTime(shipRequest.HeaderInfo.ShipDate): '',
      delivered: shipRequest.HeaderInfo.ShipDate ? this.TableformatDate(shipRequest.HeaderInfo.ShipDate)+ ' at '+this.TableformatTime(shipRequest.HeaderInfo.ShipDate): '',
      signedBy: shipRequest.ShipTo.CONTACT,
      status: 'Delivered'
    };
    this.xfservice.getTrackShippingStatus(payload, this.selectedCarrierType).subscribe({
      next: (data: any) => {
        const dataResult = data[0].length > 0 ? data[0]: data;
        this.selectedShippingStatusData = dataResult && dataResult.length > 0 && dataResult.map((record: any) => ({
          ...record,
          documentNumber: shipRequest.HeaderInfo?.DocumentNumber,
          trackingNumber: shipRequest.Packages[0]?.TrackingNumber,
          carrierDetails: shipRequest.CarrierDetails,
          shipFrom: shipRequest.ShipFrom,
          shipTo: shipRequest.ShipFrom,
          shipperContact: shipRequest.Shipper['CONTACT'],
          shipDate: shipRequest.HeaderInfo['ShipDate'],
          statusImgData: GetStatusImageData(record.StatusCode),
          DateTime: GetDateTimeFromNumber(record.DateTime) // Update the 'date' column
        }));
        // this.selectedShippingStatusData = data[0];
        this.shippingStatusData = shippingStatus;
        this.filterEventData=this.transformDataList(this.selectedShippingStatusData)
        if(this.selectedShippingStatusData.length > 0){
          //
          Promise.resolve(formatTrackingData(this.selectedShippingStatusData)).then((x) => {
            this.travelHistory = x;
            this.cd.markForCheck();
          });
        }
        this.displaySidebar = true;
      },
      error: (err) => {
        console.error('Error fetching shipping status:', err);
      }
    });
  }

  TableformatDate(input: string): string {
    const date = new Date(input);
    const options: Intl.DateTimeFormatOptions = { year: '2-digit', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('en-US', options).replace(/\//g, '/');
  }
  
  TableformatTime(input: string): string {
    const date = new Date(input);
    const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    return date.toLocaleTimeString('en-US', options);
  }

  // Function to transform and sort data
transformDataList(inputList: any[]): any[] {
  const filteredInputList = inputList.filter(item => item.DateTime !== undefined);
  const groupedData = this.groupByDate(filteredInputList);

  return Object.keys(groupedData)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime()) // Sort by date
    .map(date => ({
      date: this.formatDate(new Date(date)),
      details: this.sortByTime(groupedData[date].map(entry => ({
        time: this.formatTime(new Date(entry.DateTime)),
        location: entry.Location || '', // Handle optional location
        status: entry.status
      })))
    }));
  }
  groupByDate(inputList: any[]): { [key: string]: any[] } {
    return inputList.reduce((acc, item) => {
      if (!this.isISO8601Format(item.DateTime)){
        item.DateTime=this.convertToISO8601(item.DateTime)
      }
      const dateKey = new Date(item.DateTime).toISOString().split('T')[0]; // Group by date in YYYY-MM-DD format
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(item);
      return acc;
    }, {} as { [key: string]: any[] });
  }
  convertTo24HourTime(time: string): Date {
    const [timePart, period] = time.split(' ');
    let [hours, minutes] = timePart.split(':').map(Number);
  
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    }
    if (period === 'AM' && hours === 12) {
      hours = 0;
    }
  
    return new Date(1970, 0, 1, hours, minutes);
  }
  
  sortByTime(details: any[]): any[] {
    return details.sort((a, b) => {
      const timeA = this.convertTo24HourTime(a.time);
      const timeB = this.convertTo24HourTime(b.time);
      return timeA.getTime() - timeB.getTime();
    });
  }
  isISO8601Format(dateStr) {
    // Regular expression to check for ISO 8601 format
    const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:Z|[+\-]\d{2}:\d{2})$/;
    return iso8601Pattern.test(dateStr);
  }
  
  convertToISO8601(dateStr) {
    // Parse the date and time string
    const [datePart, timePart, period] = dateStr.split(/[\s:]+/);
    const [month, day, year] = datePart.split('/').map(Number);
    const [hours, minutes] = [parseInt(timePart, 10), parseInt(timePart, 10)];
  
    // Adjust hours for AM/PM
    let hour24 = hours;
    if (period === 'PM' && hour24 !== 12) {
        hour24 += 12;
    } else if (period === 'AM' && hour24 === 12) {
        hour24 = 0;
    }
  
    // Create a Date object
    const date = new Date(year, month - 1, day, hour24, minutes);
  
    // Convert to ISO string
    let isoString = date.toISOString();
  
    // Adjust to local time zone offset (replace with actual timezone if known)
    const offset = new Date().getTimezoneOffset() * -1;
    const offsetHours = String(Math.floor(offset / 60)).padStart(2, '0');
    const offsetMinutes = String(offset % 60).padStart(2, '0');
    const offsetSign = offset >= 0 ? '+' : '-';
  
    // Format ISO 8601 with timezone offset
    isoString = isoString.replace('Z', `${offsetSign}${offsetHours}:${offsetMinutes}`);
  
    return isoString;
  }
  formatTime(date: Date): string {
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
  }
  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }
}
