<div class="table">

    <p-table #dt [value]="displayedArray"  [columns]="columns" id="track-grid"
      [paginator]="false" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
      [resizableColumns]="true" [rowHover]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" dataKey="id"
      responsiveLayout="stack" scrollable="true" [scrollHeight]="'300px'" 
      styleClass="dynamicHeight p-datatable-sm p-datatable-gridlines p-datatable-striped"
      [tableStyle]="{ 'min-width': '50rem', 'overflow': 'scrollable' }"  scrollable="true" (onSort)="customSort($event)" >
      <ng-template pTemplate="header">
        <tr>
          <th *ngIf="snoFlag" style="white-space: nowrap; width: 50px;">#</th>
          <th *ngFor="let col of columns" pSortableColumn="{{col.fieldName}}">
            {{ col.header | titlecase }}  <p-sortIcon field="{{col.fieldName}}"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-shipRequest let-i="rowIndex" let-expanded="expanded" let-rowData let-columns="columns">
        <tr  style="cursor: pointer;" (click)="callParentView(rowData)">
          <td *ngIf="snoFlag">{{ i + 1 }}</td>
          <td *ngFor="let col of columns">
                {{ getValueFromPath(rowData, col.fieldName) }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="10">There are no items to display.</td>
        </tr>
    </ng-template>
    </p-table>
    <app-paginator [page]="page" [rows]="rows" [totalRecords]="totalRecords" (pageChange)="onPageChange($event)">
    </app-paginator>
  </div>