<p-toast></p-toast>
<p-toast position="top-left" key="tl"></p-toast>
<p-toast position="top-center" key="tc"></p-toast>
<p-toast position="bottom-center" key="bc"></p-toast>

<p-overlayPanel #ot header="Configure Fields" [showCloseIcon]="true" [dismissable]="true">

    <ng-template pTemplate="headless">
        <div class="p-2">
            <div class="sidebar-header">
                <h5>Recent Ship Requests</h5>
            </div>

            <!-- <div class="shipment-type-dropdown p-1">
                <p-dropdown [(ngModel)]="selectedShipmentType" [options]="shipmentTypes"
                    placeholder="Select a shipment type">
                </p-dropdown> 

            </div> -->
            <!-- Show shipments if available -->
            <div class="shipment-card-container"
                *ngIf="(filteredShipments!=undefined && filteredShipments.length > 0); else noData">
                <div class="shipment-card" *ngFor="let shipment of filteredShipments">
                    <span style="cursor: pointer;" (click)="selectHistory(shipment)">
                        {{shipment.details.HeaderInfo.DocumentNumber}}
                    </span>
                    <span>{{shipment.details.HeaderInfo.CreatedDate | date:'MM/dd/yyyy'}}</span>
                    <span>{{shipment.details.ShipTo.COMPANY}}</span>
                    <p>{{shipment.details.CarrierDetails.Carrier}}</p>
                </div>
            </div>

            <!-- Template to display when there is no data -->
            <ng-template #noData>
                <div class="no-data-message">
                    <p>No data available</p>
                </div>
            </ng-template>
        </div>
    </ng-template>
    <!-- </p-dialog> -->
</p-overlayPanel>
<!-- <p-toast position="bottom-center" key="c" [baseZIndex]="5000"> -->
<!-- Header Section Starts Here -->
<div class="transform">
    <div class="bodyshadow main-dev">
        <div class="add-ship-request-title">
            <span class="tittle-icon-css vertical-align-middle">
                <img src="../../assets/images/timeline-icon.png" (click)="showOverlay($event)" class="timeline-img" />
            </span>
            <span class="main-tittle-css vertical-align-middle">
                Ship Request
                <span *ngIf="docNoDisplayFlag" class="header-tile-docno">{{'-
                    '+shipment.HeaderInfo.DocumentNumber}}</span>
            </span>
            <!-- <i class="pi pi-clock timeline-icon" (click)="showOverlay($event)" style="cursor: pointer; padding-left: 5px;"></i> -->
            <span>
                <img src="../../assets/images/close-icon.png" class="close-icon-img vertical-align-middle"
                    (click)="closeShipScreen()" />
            </span>
        </div>

        <div class="shiprequestform-sec">
            <div class="add-ship-request-header">
                <div class="grid m-0">
                    <div class="col-2 pb-0 pt-0 pl-3 pr-0">
                        <div class="col-12">
                            <div class="attachment-icon-css">
                                <img src="../../assets/images/attchment-icon.png" class="attachmentimagecss" />
                                <p-dropdown class="vertical-align-middle attachment-dropdown"
                                    [(ngModel)]="shipment.HeaderInfo.FeederSystem" [options]="ErpList" [filter]="true">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 pb-0 pt-0 header-item-width">
                        <div class="grid m-0">
                            <div class="col-4 pb-0">
                                <label class="lbl-css" for="ordertype"> Order Type</label>
                            </div>
                            <div class="col-8">
                                <p-dropdown id="ordertype" [filter]="true" [options]="OrderTypes"
                                    [(ngModel)]="shipment.HeaderInfo.DocumentType">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="col-3 pb-0 pt-0 header-item-width">
                        <div class="grid m-0">
                            <div class="col-4 pb-0">
                                <label class="lbl-css" for="shipmenttype"> Shipment Type</label>
                            </div>
                            <div class="col-8">
                                <p-dropdown id="shipmenttype" [options]="ShipmentTypes" [filter]="true"
                                    [(ngModel)]="shipment.HeaderInfo.ShipmentType" (onChange)="onShipmentType()">
                                </p-dropdown>
                            </div>
                        </div>

                    </div>
                    <div class="col-3 pb-0 pt-0 header-item-width">
                        <div class="grid m-0">
                            <div class="col-4 pb-0">
                                <label class="lbl-css" for="shipmenttype">Category</label>
                            </div>
                            <div class="col-8">
                                <p-dropdown id="shipmenttype" [options]="categoryOptions" [filter]="true"
                                    [(ngModel)]="shipment.HeaderInfo.category">
                                </p-dropdown>
                            </div>
                        </div>

                    </div>
                    <div class="col-3 pb-0 pt-0 header-item-width">
                        <div class="grid m-0">
                            <div class="col-4 pb-0">
                                <label class="lbl-css" for="shipmenttype"> Shipment Date </label>
                            </div>
                            <div class="col-8">
                                <p-calendar class="calendar-sec" [(ngModel)]="shipment.HeaderInfo.ShipDate"
                                    showIcon="true" />
                            </div>
                        </div>
                    </div>
                    <div class="col-1">

                    </div>

                    <!-- <button pButton label="New" class="p-button-primary"
            style="background-color: rgba(3, 14, 76, 1); color: white; border-radius: 5px;"></button>
          <button pButton icon="pi pi-refresh" class="p-button-secondary"></button> -->

                </div>
            </div>
            <!-- Tabs starts  -->
            <div class="mid-section-css">
                <div class="grid m-0">
                    <div class="col-6 pt-0 pb-0 pl-0 pr-0">
                        <!-- Ship from  -->
                        <p-tabView class="shipto-sec" [(activeIndex)]="activeTabIndex">
                            <p-tabPanel header="Ship From">
                                <div class="grid m-0">
                                    <div class="col-6 pl-0 pr-2 pt-0 pb-0">
                                        <div class="form-group">
                                            <label for="locationFrom" class="pt-2 pb-2">Company Name</label>
                                            <div class="p-inputgroup p-input-icon-right">
                                                <i class="pi pi-search" style="cursor: pointer;"
                                                    (click)="showPopup('ShipFrom')"></i>
                                                <input maxlength="35" pInputText id="locationFrom"
                                                    [(ngModel)]="shipment.ShipFrom.COMPANY" />
                                            </div>
                                        </div>
                                        <div class="form-group pt-3">
                                            <label for="addressLine1" class="pt-2 pb-2">Address Line 1</label>
                                            <input pInputText maxlength="35" id="addressLine1"
                                                class="addressline-txtbox"
                                                [(ngModel)]="shipment.ShipFrom.ADDRESS_LINE1" />
                                        </div>
                                        <div class="grid m-0 pt-3">
                                            <div class="col-6 pl-0 pt-0">
                                                <div class="form-group">
                                                    <label for="city" class="pt-2 pb-2">City</label>
                                                    <input pInputText maxlength="35" id="city"
                                                        [(ngModel)]="shipment.ShipFrom.CITY" />
                                                </div>
                                            </div>
                                            <div class="col-6 pr-0 pl-3 pt-0">
                                                <div class="form-group">
                                                    <label for="stateFrom" class="pt-2 pb-2">State</label>
                                                    <input pInputText maxlength="3" id="stateFrom"
                                                        [(ngModel)]="shipment.ShipFrom.STATE" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grid pt-3">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="email" class="pt-2 pb-2">Email</label>
                                                    <input pInputText maxlength="50" id="email"
                                                        [(ngModel)]="shipment.ShipFrom.EMAIL" />
                                                </div>
                                            </div>
                                            <div class="col-6 pl-3">
                                                <div class="form-group">
                                                    <label for="phone" class="pt-2 pb-2">Phone No</label>
                                                    <input pInputText id="phone" maxlength="20"
                                                        [(ngModel)]="shipment.ShipFrom.PHONE" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 pt-0 pr-0 pb-0 pl-3">
                                        <div class="form-group">
                                            <label for="contact" class="pt-2 pb-2">Contact Name</label>
                                            <input pInputText id="contact" maxlength="35"
                                                [(ngModel)]="shipment.ShipFrom.CONTACT" />
                                        </div>
                                        <div class="form-group pt-3">
                                            <label for="addressLine2" class="pt-2 pb-2">Address Line 2</label>
                                            <input pInputText id="addressLine2" maxlength="35"
                                                class="addressline-txtbox"
                                                [(ngModel)]="shipment.ShipFrom.ADDRESS_LINE2" />
                                        </div>

                                        <div class="grid m-0 pt-3">
                                            <div class="col-6 pl-0 pt-0">
                                                <div class="form-group">
                                                    <label for="Zipcode" class="pt-2 pb-2">Zip/Postal Code</label>
                                                    <input pInputText id="Zipcode" maxlength="15"
                                                        [(ngModel)]="shipment.ShipFrom.ZIPCODE" />
                                                </div>
                                            </div>
                                            <div class="col-6 pr-0 pt-0 pl-3">
                                                <div class="form-group">
                                                    <label for="countryFrom" class="pt-2 pb-2">Country</label>
                                                    <p-dropdown [options]="countries" [filter]="true"
                                                        [(ngModel)]="shipment.ShipFrom.COUNTRY"></p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grid m-0 pt-3">
                                            <div class="col-9 pl-0 pt-0">
                                                <div class="form-group">
                                                    <label for="addressTypeFrom" class="pt-2 pb-2">Address Type</label>
                                                    <p-dropdown [options]="addressTypes" optionLabel="label"
                                                        optionValue="value"
                                                        [(ngModel)]="ShipFromAddressType"></p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-3 pt-0 pr-0 pl-2">
                                                <div class="form-group">
                                                    <label for="RFID Tag" class="pt-2 pb-2">RFID Tag</label>

                                                    <label class="rfidcheckbox">
                                                        <input type="checkbox" [(ngModel)]="rfIdShipFrom">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <!-- <input type="checkbox" id="RFID Tag" class="pt-0 rfid-chk-box" name="RFID Tag" value="RFID Tag"> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel header="Ship To">
                                <div class="grid m-0">
                                    <div class="col-6 pl-0 pr-2 pt-0 pb-0">
                                        <div class="form-group">
                                            <label class="shipto_labels pt-2 pb-2" for="companyNameTo">Company
                                                Name</label>
                                            <div class="p-inputgroup p-input-icon-right">
                                                <i class="pi pi-search" style="cursor: pointer;"
                                                    (click)="showPopup('ShipTo')"></i>
                                                <input pInputText maxlength="35" id="companyNameTo"
                                                    [(ngModel)]="shipment.ShipTo.COMPANY" />
                                            </div>
                                        </div>
                                        <div class="form-group pt-3">
                                            <label class="shipto_labels pt-2 pb-2" for="addressLine1">Address Line 1
                                            </label>
                                            <input pInputText maxlength="35" id="addressLine1"
                                                class="addressline-txtbox"
                                                [(ngModel)]="shipment.ShipTo.ADDRESS_LINE1" />
                                        </div>

                                        <div class="grid m-0 pt-3">
                                            <div class="col-6 pl-0 pt-0">
                                                <div class="form-group">
                                                    <label class="shipto_labels pt-2 pb-2" for="city">City </label>
                                                    <input pInputText maxlength="35" id="city"
                                                        [(ngModel)]="shipment.ShipTo.CITY" />
                                                </div>
                                            </div>
                                            <div class="col-6 pr-0 pt-0 pl-3">
                                                <div class="form-group">
                                                    <label class="shipto_labels pt-2 pb-2" for="stateTo">State </label>
                                                    <input pInputText maxlength="3" id="stateTo"
                                                        [(ngModel)]="shipment.ShipTo.STATE" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grid m-0 pt-3">
                                            <div class="col-6 pl-0 pt-0">
                                                <div class="form-group">
                                                    <label class="shipto_labels pt-2 pb-2" for="email">Email </label>
                                                    <input pInputText id="email" maxlength="50"
                                                        [(ngModel)]="shipment.ShipTo.EMAIL" />
                                                </div>
                                            </div>
                                            <div class="col-6 pr-0 pt-0 pl-3">
                                                <div class="form-group">
                                                    <label class="shipto_labels pt-2 pb-2" for="phone">Phone No </label>
                                                    <input pInputText id="phone" maxlength="20"
                                                        [(ngModel)]="shipment.ShipTo.PHONE" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 pt-0 pr-0 pb-0 pl-3">
                                        <div class="form-group">
                                            <label class="shipto_labels pt-2 pb-2" for="displayName">Contact Name
                                            </label>
                                            <input pInputText id="displayName" maxlength="35"
                                                [(ngModel)]="shipment.ShipTo.CONTACT" />
                                        </div>
                                        <div class="form-group pt-3">
                                            <label class="shipto_labels pt-2 pb-2" for="addressLine2">Address Line 2
                                            </label>
                                            <input pInputText id="addressLine2" maxlength="35"
                                                class="addressline-txtbox"
                                                [(ngModel)]="shipment.ShipTo.ADDRESS_LINE2" />
                                        </div>

                                        <div class="grid m-0 pt-3">
                                            <div class="col-6 pt-0 pl-0">
                                                <div class="form-group">
                                                    <label class="shipto_labels pt-2 pb-2" for="Zipcode">Zip/Postal Code
                                                    </label>
                                                    <input pInputText id="Zipcode" maxlength="15"
                                                        [(ngModel)]="shipment.ShipTo.ZIPCODE" />
                                                </div>

                                            </div>
                                            <div class="col-6 pt-0 pr-0 pl-3">
                                                <div class="form-group">
                                                    <label class="shipto_labels pt-2 pb-2" for="countryTo">Country
                                                    </label>
                                                    <p-dropdown [options]="countries"
                                                        [(ngModel)]="shipment.ShipTo.COUNTRY"></p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grid m-0 pt-3">
                                            <div class="col-9 pt-0 pl-0">
                                                <div class="form-group">
                                                    <label class="shipto_labels pt-2 pb-2" for="addressTypeFrom">Address
                                                        Type</label>
                                                    <p-dropdown [options]="addressTypes" optionLabel="label"
                                                        optionValue="value"
                                                        [(ngModel)]="ShipToAddressType"></p-dropdown>
                                                </div>
                                            </div>
                                            <div class="col-3 pt-0 pr-0 pl-2">
                                                <div class="form-group">
                                                    <label for="RFID Tag" class="pt-2 pb-2">RFID Tag</label>
                                                    <label class="rfidcheckbox">
                                                        <input type="checkbox" [(ngModel)]="rfIdShipTO">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                        </p-tabView>
                    </div>
                    <!-- Tabs End  -->
                    <!-- ADDRESSBOOK  DIALOG START-->

                    <!-- ADDRESSBOOK DIALOG  END-->
                    <div class="col-6 pt-0 pb-0 pr-0 pl-2">
                        <p-tabView class="shipto-sec" [(activeIndex)]="activeTabIndex">
                            <p-tabPanel header="Routing & Billing">
                                <div class="grid m-0">
                                    <div class="col-6 pl-0 pr-2 pt-0 pb-2">
                                        <div class="form-group">
                                            <label for="shipMethod" class="pt-2 pb-2">Ship Method</label>
                                            <p-dropdown id="shipMethod" [options]="carriers"
                                                [(ngModel)]="shipment.CarrierDetails.Carrier" [filter]="true"
                                                (onChange)="processChange('CarrierDetails', 'Carrier', this.shipment.CarrierDetails['Carrier'], '', '')"></p-dropdown>

                                        </div>
                                        <div class="form-group pt-3">
                                            <label for="paymentType" class="pt-2 pb-2">Payment Type</label>
                                            <p-dropdown id="paymentType" [options]="paymentTypes" [filter]="true"
                                                [(ngModel)]="shipment.CarrierDetails.PaymentType"></p-dropdown>
                                        </div>
                                        <div class="form-group pt-3">
                                            <label for="costCenter" class="pt-2 pb-2">Cost Center</label>
                                            <p-dropdown id="costCenter" [options]="costCenters"
                                                optionValue="description" optionLabel="description"
                                                [(ngModel)]="shipment.CarrierDetails.CostCenter"></p-dropdown>
                                        </div>

                                    </div>
                                    <div class="col-6 col-6 pt-0 pr-0 pb-2 pl-3">
                                        <div class="form-group">
                                            <label for="serviceType" class="pt-2 pb-2">Service Name</label>
                                            <p-dropdown id="serviceType" [options]="ServiceList" [filter]="true"
                                                [(ngModel)]="shipment.CarrierDetails.ServiceName"
                                                placeholder="Select"></p-dropdown>
                                        </div>
                                        <div class="form-group pt-3">
                                            <label for="accountNumber" class="pt-2 pb-2">Account No</label>
                                            <input id="accountNumber" type="text" pInputText maxlength="30"
                                                [(ngModel)]="shipment.CarrierDetails.ShippingAccount" />
                                        </div>
                                        <div class="form-group pt-3">
                                            <label for="poNo" class="pt-2 pb-2">PO No</label>
                                            <input id="poNo" type="text" pInputText maxlength="50"
                                                [(ngModel)]="shipment.CarrierDetails.PoNo" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 p-0 pt-2">
                                    <div class="form-group">
                                        <label for="notes" class="pt-2 pb-2">Special Instructions</label>
                                        <textarea maxlength="200" id="notes" rows="4" class="special-txtbox"
                                            pInputTextarea [(ngModel)]="shipment.CarrierDetails.Note"></textarea>
                                    </div>
                                </div>
                            </p-tabPanel>
                        </p-tabView>
                    </div>
                </div>
                <div class="card shiprequest_grid_modal_css pl-3">
                    <div class="grid">
                        <div class="col-12 col-config-pos">
                            <app-col-config [columns]="columns" [mandatoryColumns]="mandatoryColumns"
                                (finalColumns)="receiveData($event)" [ConfigKey]="ConfigKey"></app-col-config>
                        </div>
                    </div>
                    <div class="item-info">
                        <p-confirmDialog [ngStyle]="{'height': '180px !important'}"></p-confirmDialog>
                        <p-table [value]="products" [scrollHeight]="scrollHeight" [scrollable]="true" editable="true"
                            class="productstable" *ngIf="finalColumns && selectedColumns.length > 0">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>#</th>
                                    <ng-container *ngFor="let col of finalColumns">
                                        <th *ngIf="isColumnVisible(col.fieldName)"
                                            [ngStyle]="getHeaderStyle(col.fieldName)">
                                            {{ col.header }}
                                        </th>
                                    </ng-container>
                                    <th>
                                        <span>Action</span>
                                        <p-dialog class="column-configure-popup" header="Configure Fields"
                                            [(visible)]="displayColumnConfig" [modal]="true">
                                            <div class="p-fluid">
                                                <!-- Select All Checkbox -->
                                                <p-checkbox label="Select All" binary="true" [(ngModel)]="selectAll"
                                                    (onChange)="onSelectAllChange($event.checked)"
                                                    class="select-all-checkbox"></p-checkbox>

                                                <!-- Individual Column Checkboxes -->
                                                <div *ngFor="let col of finalColumns">
                                                    <p-checkbox [label]="col.header" class="chk-configure-fileds"
                                                        binary="true" [ngModel]="isColumnVisible(col.fieldName)"
                                                        (onChange)="toggleColumnVisibility(col.fieldName, $event.checked)">
                                                    </p-checkbox>
                                                </div>
                                            </div>
                                            <div class="p-dialog-footer column-configure-footer">
                                                <button type="button" class="btn-common-css modal-btn-apply"
                                                    (click)="applyColumnConfiguration()">
                                                    <span class="pi pi-check"></span> Apply
                                                </button>
                                                <button type="button"
                                                    class="btn-common-css btn-column-config-ship-cancel"
                                                    (click)="displayColumnConfig=false">
                                                    <span class="pi pi-times"></span> Cancel
                                                </button>
                                            </div>
                                        </p-dialog>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
                                <tr>
                                    <td [ngStyle]="{ 'width': '50px'}">
                                        {{ rowIndex + 1 }}
                                    </td>
                                    <ng-container *ngFor="let col of finalColumns">
                                        <td *ngIf="isColumnVisible(col.fieldName)"
                                            [ngStyle]="getCellStyle(col.fieldName)" (click)="onRowClick(rowIndex)"
                                            pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <ng-container [ngSwitch]="col.fieldName">
                                                        <ng-container *ngSwitchCase="'Dimension'">

                                                            <div class="dimensionDropdown"><p-dropdown
                                                                    [(ngModel)]="product[col.fieldName]"
                                                                    [options]="dimensionOptions" [editable]="true"
                                                                    appendTo="body"
                                                                    placeholder="Select or Add Dimensions">
                                                                </p-dropdown>
                                                                <i class="pi pi-plus"
                                                                    (click)="addDimensionOpen($event,rowIndex)"
                                                                    style="cursor: pointer; color:gray; padding-top: 12px;"></i>
                                                            </div>



                                                        </ng-container>
                                                        <input *ngSwitchCase="'Quantity'" pInputText type="number"
                                                            min="0" (keypress)="validateInput($event)"
                                                            [(ngModel)]="product[col.fieldName]"
                                                            style="text-align: right !important; width: 100%; box-sizing: border-box;" />
                                                        <input *ngSwitchCase="'UnitWeight'" pInputText type="number"
                                                            min="0" (keypress)="validateInput($event)"
                                                            [(ngModel)]="product[col.fieldName]"
                                                            style="text-align: right !important; width: 100%; box-sizing: border-box;" />
                                                        <input *ngSwitchCase="'ProductNo'" pInputText maxlength="30"
                                                            [(ngModel)]="product[col.fieldName]"
                                                            style="text-align: left !important; width: 100%; box-sizing: border-box;" />
                                                        <input *ngSwitchCase="'Description'" pInputText maxlength="200"
                                                            [(ngModel)]="product[col.fieldName]"
                                                            style="text-align: left !important; width: 100%; box-sizing: border-box;" />
                                                        <input *ngSwitchCase="'HTSCode'" pInputText maxlength="200"
                                                            [(ngModel)]="product[col.fieldName]"
                                                            style="text-align: left !important; width: 100%; box-sizing: border-box;" />
                                                        <div *ngSwitchDefault class="overflow-container">
                                                            <input pInputText [(ngModel)]="product[col.fieldName]"
                                                                style="text-align: left !important; width: 100%; box-sizing: border-box;" />
                                                        </div>
                                                    </ng-container>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{ product[col.fieldName] }}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                    </ng-container>

                                    <!-- Actions Column -->
                                    <td [ngStyle]="{ 'width': '80px'}">
                                        <img (click)="confirmDelete(rowIndex)" class="btn-img"
                                            style="margin-left: 0px !important;"
                                            src="../../assets/icon/material-symbols_delete-outline.png">
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
                <div class="col-12 pl-3">
                    <button class="btn-addressbook" (click)="openProductDialog()"> Add Product </button>
                </div>
                <!-- Product Information Dialog -->
                <p-dialog showHeader="false" [(visible)]="displayProductDialog" [modal]="true" [style]="{width: '60vw'}"
                    scrollHeight="600px">
                    <div class="dialog-header">
                        <div class="grid m-0">
                            <div class="col-10 py-0">
                                <h4 class="mb-0">Products Information</h4>
                            </div>
                            <div class="col-2 py-0 text-right">
                                <img src="../../../../assets/images/close-icon.png" class="dialog-close"  (click)="displayProductDialog = false"   />
                            </div>
                        </div>
                    </div>
                    <!-- Product Search Table inside the Dialog -->
                    <div class="py-1 px-3">
                        <div class="col-5">
                            <div style="text-align: right;position: relative;">
                                <i class="pi pi-search"
                                    style="margin:4px 4px 0 0;position:absolute;top:9px;right:5px"></i>
                                <input type="text" pInputText size="50" placeholder="Global Filter" style="width:auto">
                            </div>
                        </div>
                    </div>


                    <app-dialog-table [displayedData]="allProducts" [sharedViewFunction]="selectProduct.bind(this)"
                        [snoFlag]="false" [columns]="finalColumns"></app-dialog-table>

                </p-dialog>


            </div>

        </div>
    </div>


    <!-- Footer Seaction Starts here -->
    <div class="footer-dialog-css">
        <div class="grid m-0 pt-8 pb-10" *ngIf="isFooterButtonEnable">
            <div class="col p-0">
                <!-- <button type="button" class="btn-common-css btn-ship-cancel" (click)="closeShipScreen()"
                    label="Cancel">Cancel</button> -->
            </div>
            <div class="col p-0 text-center">
                <button type="button" class="btn-get">Get Shipping Rates</button>
                <button type="button" *ngIf="businessCls" (click)="shippingRates()" class="ml-3 btn-get">Buy Shipping
                    Label</button>
            </div>
            <div class="col p-0">
                <!-- <button type="button" class="btn-save" (click)="Save()">Save</button> -->

                <p-splitButton label="{{saveButtontitle}}" class="btnSave" (onClick)="Save('save')"
                    [model]="saveOptions" />{{saveButtontitle}}

            </div>
        </div>
    </div>
</div>

<app-address-book-popup #popup (onSelect)="handleAddressSelect($event)" (onClose)="handlePopupClose()">


</app-address-book-popup>
<app-warning-popup></app-warning-popup>

<p-overlayPanel #adddimension (onHide)="restDimensionForm()" [showCloseIcon]="true" [dismissable]="true"
    [style]="{ width: '300px' }" [baseZIndex]="1000">
    <span style="font-weight: 600; font-size: 1.25rem;">Add Dimension</span>
    <div style="margin-top: 10px;">
        <label for="name">Name<p style="color: red; display: contents;">*</p></label>
        <input id="name" type="text" maxlength="30" pInputText [(ngModel)]="dimensionForm.Name" placeholder="Enter Name"
            (input)="isdimensionFormValid()" />
        <p *ngIf="dimensionNameFlag" style="color: red;">Dimension name already exist</p>
        <div class="dimensionform py-4">
            <input id="length" type="number" min="0" max="999" pInputText [(ngModel)]="dimensionForm.Length"
                placeholder="L" (input)="onInputChange('Length')" (keypress)="validateInput($event)" />

            <input id="width" type="number" min="0" max="999" pInputText [(ngModel)]="dimensionForm.Width"
                placeholder="W" (input)="onInputChange('Width')" (keypress)="validateInput($event)" />

            <input id="height" type="number" min="0" max="999" pInputText [(ngModel)]="dimensionForm.Height"
                placeholder="H" (input)="onInputChange('Height')" (keypress)="validateInput($event)" />
        </div>

    </div>
    <div class="button-container">
        <div class="overlay-apply">
            <button type="button" class="btnShipColumnApply" (click)="saveDimension()"
                [disabled]="!isdimensionFormValid()">Save</button>
        </div>
    </div>
</p-overlayPanel>

<p-sidebar [(visible)]="displaybuyPopup" [showCloseIcon]="false" showheader="false" [modal]="false" [baseZIndex]="10000"
    position="right" [style]="{'width': '35%', 'height': '100vh','padding-top':'0px'}" styleClass="shipHistoryPopup">

    <div #sidebarContent>
        <div class="sidebar-header-history"
            style="display: flex; justify-content: space-between; align-items: center; padding: 10px 0; border-bottom: 1px solid #e0e0e0; margin-bottom: 5px; margin-top: 0px;padding-top:0px;">
            <button class="cancel-button"
                style="cursor: pointer; font-size: 1rem; background: none; border: none; color: #020101; display: flex; align-items: center;"
                (click)="displaybuyPopup = false">
                <i class="pi pi-chevron-left" style="margin-right: 5px;"></i>
                Back
            </button>

            <h1 class="popTittle mb-0"
                style="font-size: 20px; color: #030927; font-weight: 400; font-family: AvenirNextCyrMedium;">Review
                Shipping and Label Details</h1>
                <img src="../../../../assets/images/close-icon.png" class="dialog-close" (click)="displaybuyPopup = false" />
        </div>
        
        <div class="dialog-content">
            <!-- From Shipment Details -->
            <div class="shipper-details" style="width: 80%;">
                <h5 style="margin-bottom: 1rem;">From
                    <i class="pi pi-pencil edit-icon" style="margin-left: 0.5rem; cursor: pointer;"
                        (click)="showFromDialog()"></i>
                </h5>
                <p>{{ shipment.ShipFrom.COMPANY }}</p>
                <p>{{ shipment.ShipFrom.ADDRESS_LINE1 }}, {{ shipment.ShipFrom.ADDRESS_LINE2 }}</p>
                <p>{{ shipment.ShipFrom.CITY }}, {{ shipment.ShipFrom.STATE }} {{ shipment.ShipFrom.ZIPCODE }}</p>
                <p>{{ shipment.ShipFrom.COUNTRY }}</p>
                <p>{{ shipment.ShipFrom.PHONE }}</p>
                <p>{{ shipment.ShipFrom.EMAIL }}</p>

                <hr style="border: 0; border-top: 1.5px solid gray; margin: 1rem 0;" />

                <h5 style="margin-bottom: 1rem;">To
                    <i class="pi pi-pencil edit-icon" style="margin-left: 0.5rem; cursor: pointer;"
                        (click)="showToDialog()"></i>
                </h5>
                <p>{{ shipment.ShipTo.COMPANY }}</p>
                <p>{{ shipment.ShipTo.ADDRESS_LINE1 }}, {{ shipment.ShipTo.ADDRESS_LINE2 }}</p>
                <p>{{ shipment.ShipTo.CITY }}, {{ shipment.ShipTo.STATE }} {{ shipment.ShipTo.ZIPCODE }}</p>
                <p>{{ shipment.ShipTo.COUNTRY }}</p>
                <p>{{ shipment.ShipTo.PHONE }}</p>
                <p>{{ shipment.ShipTo.EMAIL }}</p>

                <hr style="border: 0; border-top: 1.5px solid grey; margin: 1rem 0;" />

                <h5 style="margin-bottom: 1rem;">Package
                    <i class="pi pi-pencil edit-icon" style="margin-left: 0.5rem; cursor: pointer;"></i>
                </h5>
                <ul style="padding-left: 17px !important;">
                    <li *ngFor="let item of shipment.Packages" style="font-size: 12px !important;">
                        Quantity: {{ item.ItemsInfo.length }} - Weight: {{ item.Weight }} lbs
                    </li>
                </ul>
            </div>


            <div class="summary-card"
                style="width: 100%; background-color: #ffffff; padding: 1rem; border-radius: 10px; box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);">
                <h4 style="margin-bottom: 1rem; border-bottom: 1px solid #ddd; padding-bottom: 0.5rem; color:#030927;font-family:'AvenirNextCyrMedium' !important">Summary</h4>
                <div class="card-content">
                    <p style="display: flex; justify-content: space-between; align-items: center; font-size: 14px;">
                        <span style="color: #5A5C68;font-size:16px !important;font-family:'AvenirNextCyrMedium' !important">Carrier Name</span>
                        <span class="summaryView" style="font-weight: bold; color: #030927;">{{ mailService }}</span>
                    </p>
                    <p style="display: flex; justify-content: space-between; align-items: center; font-size: 14px;">
                        <span style="color: #5A5C68;font-family:'AvenirNextCyrMedium' !important">Service Name</span>
                        <span class="summaryView" style="font-weight: bold; color: #030927;">{{ serviceNameSummary
                            }}</span>
                    </p>
                    <p style="display: flex; justify-content: space-between; align-items: center; font-size: 14px;">
                        <span style="color: #5A5C68;font-family:'AvenirNextCyrMedium' !important">Estimated Arrival</span>
                        <span class="summaryView" style="font-weight: bold; color: #030927;">{{ estimatedArrival | date:
                            'M/d/yy (EEE)' }}</span>
                    </p>
                    <p style="display: flex; justify-content: space-between; align-items: center; font-size: 14px;">
                        <span style="color: #5A5C68;font-family:'AvenirNextCyrMedium' !important">Shipping rate</span>
                        <span class="summaryView" style="font-weight: bold; color: #030927;">${{ shippingRate }}</span>
                    </p>
                </div>
            </div>

        </div>
    </div>
    <div class="flex justify-content-between flex-wrap" style="border-top: 1px solid #E0E0E0;  display: flex; justify-content: space-between; align-items: center;padding: 10px;padding-top: 15px;">
        <div class="flex">
            <button (click)="displaybuyPopup = false" class="frieghtCancelBtn">Cancel
            </button>
        </div>
        <div class="flex"></div>
        <div class="flex">
            <button (click)="pay()" class="frieghtReviewBtn">Buy and Print
            </button>
        </div>
    </div>
</p-sidebar>

<p-sidebar [(visible)]="FreightShop_dialog" [showCloseIcon]="false" showheader="false" [modal]="false"
    [baseZIndex]="1000" position="right" [style]="{'width': '65%', 'height': '100vh'}" styleClass="shipHistoryPopup">

    <ng-template pTemplate="header">
        <div #sidebarContent>
            <div class="sidebar-header-history"
                style="display: flex; justify-content: space-between; align-items: center; padding: 10px 0; border-bottom: 1px solid #E0E0E0; margin-bottom: 5px; margin-top: 0px;width: 63vw;">
                <button class="cancel-button"
                    style="cursor: pointer; font-size: 1rem; background: none; border: none; color: #020101; display: flex; align-items: center;"
                    (click)="FreightShop_dialog = false">
                    <i class="pi pi-chevron-left" style="margin-right: 5px;"></i>
                    Back
                </button>
                <h1 class="pickTitle mb-0">Pick a Shipping Label</h1>
                <img src="../../../../assets/images/close-icon.png" class="dialog-close" (click)="FreightShop_dialog = false" />
            </div>
        </div>
    </ng-template>

    <div class="row p-4 dynamicHeight">
        <!-- Loader and message -->
        <!-- Loader and message -->
        <div *ngIf="loading" class="loading-container"
            style="text-align: center; padding: 2rem; z-index: 11000; position: relative; display: flex; flex-direction: column; justify-content: center; align-items: center; height: 80vh;">
            <p>Getting Shipping rates</p>
            <p>We’re finding the best rates based on your requirements</p>
            <img class="loader-logo" src="../assets/images/copilotlogo1.png" alt="loader"
                style="animation: rotate 2s linear infinite; width: 50px; height: 50px;"> <!-- Adjust size here -->
        </div>


        <!-- Display table only if data is available -->
        <p-table *ngIf="!loading && FreightShopResult.length" #fsdt [value]="FreightShopResult"
    [style]="{ width: '100%' }" [scrollable]="true" responsiveLayout="stack" selectionMode="single"
    [(selection)]="selectedFreightProduct" dataKey="serviceCode" [paginator]="false"
    [metaKeySelection]="false" class="freight-main-table">

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th style="width: 5%;"></th>
            <th style="width: 15%; color: #030927 !important" pSortableColumn="Carrier">
                Carrier<p-sortIcon field="Carrier"></p-sortIcon>
            </th>
            <th style="width: 20%; color: #030927 !important" pSortableColumn="serviceName">
                Service Name<p-sortIcon field="serviceName"></p-sortIcon>
            </th>
            <th style="width: 15%; color: #030927 !important; text-align: right;" pSortableColumn="publishedFreight">
                Published Rate $<p-sortIcon field="publishedFreight"></p-sortIcon>
            </th>
            <th style="width: 15%; color: #030927 !important; text-align: right;" pSortableColumn="discountFreight">
                Discount Rate $<p-sortIcon field="discountFreight"></p-sortIcon>
            </th>
            <th style="width: 15%; color: #030927 !important; text-align: right;" pSortableColumn="expectedDeliveryDate">
                Estimated Arrival<p-sortIcon field="expectedDeliveryDate"></p-sortIcon>
            </th>
            <th style="width: 15%; color: #030927 !important; text-align: right;padding-right:15px !important;" pSortableColumn="transitDay">
                In-Days<p-sortIcon field="transitDay"></p-sortIcon>
            </th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
        <tr [ngClass]="{'alt-row': i % 2 === 0, 'highlight-row': rowData['publishedFreight'] === minPublishedFreight}"
            style="height:60px !important">
            <td>
                <p-radioButton [value]="rowData" name="freightOptions" [(ngModel)]="selectedFreightProduct"
                    (onClick)="assigneServiceFromFreightshop(rowData)">
                </p-radioButton>
            </td>
            <td style="font-size: 14px;color:#5A5C68;font-weight: 500;">{{ rowData["Carrier"] }}</td>
            <td style="font-size: 14px;color:#5A5C68;font-weight: 500;">{{ rowData["serviceName"] }}</td>
            <td style="font-size: 14px;color:#5A5C68;font-weight: 500;text-align: right;">
                <span class="output-text-red" style="font-size: 13px;color:#5A5C68;font-weight: 500;">
                    {{ rowData["publishedFreight"] | currency : rowData["currency"] }}
                    <i *ngIf="rowData['publishedFreight'] === minPublishedFreight" class="pi pi-star"
                        style="color: red; margin-left: 5px;"></i>
                </span>
            </td>
            <td style="font-size: 14px;color:#5A5C68;font-weight: 500;text-align: right;">
                <span class="output-text-red" style="font-size: 13px;color:#5A5C68;font-weight: 500;">
                    {{ rowData["discountFreight"] | currency : rowData["currency"] }}
                </span>
            </td>
            <td style="font-size: 14px;color:#5A5C68;font-weight: 500;text-align: right;">
                {{ rowData["expectedDeliveryDate"] | date: 'M/d/yy (EEE)' }}
            </td>
            <td style="font-size: 14px;color:#5A5C68;font-weight: 500;text-align: right;padding-right:15px !important;">
                {{ rowData["transitDay"] }}
            </td>
        </tr>
    </ng-template>
</p-table>


        <div *ngIf="!loading && !FreightShopResult.length" style="text-align: center; padding: 2rem;">
            <p>No shipping rates available.</p>
        </div>

    </div>
    
    <div style="border-top: 1px solid #E0E0E0;  display: flex; justify-content: space-between; align-items: center;padding: 10px; margin-top: 4rem; padding-top: 15px;">
        <!-- Left aligned button -->
        <button (click)="FreightShop_dialog = false" class="frieghtCancelBtn">Cancel
        </button>
        <!-- Right aligned button -->
        <button (click)="openSummaryPopup()" class="frieghtReviewBtn">Review and Buy
        </button>
    </div>

</p-sidebar>

<!-- shipping-documents-dialog.component.html -->



<p-sidebar [(visible)]="displayLabel" [showCloseIcon]="false" showheader="false" [modal]="false"
    [baseZIndex]="9999999999999" position="right" [style]="{'width': '35%', 'height': '100vh'}"
    styleClass="shipHistoryPopup">

    <ng-template pTemplate="header">
        <div #sidebarContent>
            <div class="sidebar-header-history"
                style="display: flex; justify-content: space-between; align-items: center; padding: 10px 0; border-bottom: 1px solid #E0E0E0; margin-bottom: 5px; margin-top: 0px;width: 33vw;">
                <button class="cancel-button"
                    style="cursor: pointer; font-size: 1rem; background: none; border: none; color: #020101; display: flex; align-items: center;"
                    (click)="refreshpage()">
                    <i class="pi pi-chevron-left" style="margin-right: 5px;"></i>
                    Back
                </button>
                <h1 class="pickTitle mb-0">Print Label</h1>
                <img src="../../../../assets/images/close-icon.png" class="dialog-close" (click)="refreshpage()" />
            </div>
        </div>
    </ng-template>

    <div class="row p-4 dynamicHeight text-center" style="overflow:hidden !important">
        <div>
            <img *ngIf="this.shippingDocuments?.encodedLabel"
                [src]="'data:image/png;base64,' + this.shippingDocuments?.encodedLabel" alt="Label"
                style="max-width: 22vw; height: auto;" />

        </div>
    </div>

    <div style="border-top: 1px solid #E0E0E0;   display: flex; justify-content: space-between; align-items: center;padding: 10px; margin-top: 4rem; padding-top: 15px;">
        <!-- Left aligned button -->

        <button (click)="refreshpage()" class="frieghtCancelBtn">Close
        </button>

        <!-- Right ali  <button (click)="printDocument(this.shippingDocuments?.encodedLabel)">Print</button>gned button -->
        <button (click)="printDocument(this.shippingDocuments?.encodedLabel)" class="frieghtReviewBtn">Print
        </button>
    </div>

   

</p-sidebar>

<!-- From Dialog -->
<!-- <ng-container *ngIf="editableFrom != undefined">
    <p-dialog 
      header="Edit Ship From details" 
      [(visible)]="isFromDialogVisible" 
      [modal]="true" 
      [closable]="true" 
      showHeader="false"
      [style]="{ width: '700px' }"
      [resizable]="false"
    >
    <div class="row p-4" >
        <div class="custom-dialog-header">
            <h3>Edit Ship From details</h3>
        </div>
      <div class="container-fluid">
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="form-group">
            <label for="locationFrom" class="pt-2 pb-2">Company Name</label>
            <div class="p-inputgroup p-input-icon-right">
                <i class="pi pi-search" style="cursor: pointer;" (click)="showPopup('ShipFrom')"></i>
                <input maxlength="35" pInputText id="locationFrom"
                [(ngModel)]="editableFrom.COMPANY" />
            </div>
           </div> 
          </div>
          <div class="col-md-6">
            <div class="form-group">
                <label class="shipto_labels pt-2 pb-2" for="displayName">Contact Name
                </label>
                <input pInputText id="displayName" maxlength="35"
                    [(ngModel)]="editableFrom.CONTACT" />
            </div>
          </div>
        </div>
          <div class="row mb-3">
          <div class="col-md-6">
            <div class="form-group">
            <label for="addressLine1" class="pt-2 pb-2">Address Line 1</label>
            <input style="height: 65px !important;" pInputText maxlength="35" id="addressLine1"
            class="addressline-txtbox"
            [(ngModel)]="editableFrom.ADDRESS_LINE1" />     
          </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
            <label for="addressLine1" class="pt-2 pb-2">Address Line 2</label>
            <input  style="height: 65px !important;" pInputText maxlength="35" id="addressLine2"
                class="addressline-txtbox"
                [(ngModel)]="editableFrom.ADDRESS_LINE2" />
          </div>
        </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
            <div class="form-group">
                <label for="city" class="pt-2 pb-2">City</label>
                <input pInputText maxlength="35" id="city"
                    [(ngModel)]="editableFrom.CITY" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
                <label for="state" class="pt-2 pb-2">State</label>
                <input pInputText maxlength="35" id="city"
                    [(ngModel)]="editableFrom.STATE" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
                <label for="state" class="pt-2 pb-2">State</label>
                <input pInputText maxlength="35" id="city"
                    [(ngModel)]="editableFrom.ZIPCODE" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
            <label for="countryFrom" class="pt-2 pb-2">Country</label>
            <p-dropdown [options]="countries" [filter]="true"
            [(ngModel)]="editableFrom.COUNTRY"></p-dropdown>
          </div>
          </div>
        </div>
          <div class="row mb-3" >
          <div class="col-md-3">
            <div class="form-group">
                <label for="email" class="pt-2 pb-2">Email</label>
                <input pInputText maxlength="50" id="email"
                    [(ngModel)]="editableFrom.EMAIL" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
                <label for="phone" class="pt-2 pb-2">Phone No</label>
                <input pInputText id="phone" maxlength="20"
                    [(ngModel)]="editableFrom.PHONE" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
                <label class="shipto_labels pt-2 pb-2" for="addressTypeFrom">Address
                    Type</label>
                <p-dropdown [options]="addressTypes" optionLabel="label"
                    optionValue="value"
                    [(ngModel)]="editableFrom.ADDRESS_TYPE"></p-dropdown>
            </div>
          </div>  
          <div class="col-md-3 mt-5">
                <label for="RFIDTag" class="me-2 mb-0">RFID Tag</label>
                <input type="checkbox" id="RFIDTag" [(ngModel)]="editableFrom.RFID_TAG" class="form-check-input me-2" />
        </div>
        
        </div>
      </div>
            <div class="row mt-2 text-center p-2" style="border-top:1px solid #ddd;">
                <div class="col mt-2">
                    <button style="padding: 0.5rem 1rem; cursor: pointer;" type="button" class="btn btn-outline-secondary" (click)="cancelFromChanges()">Cancel</button>
                </div>
                <div class="col mt-2">
                    <button 
                    (click)="saveFromChanges()" 
                    class="p-button-text" 
                    style="background: #4ea732; padding: 0.5rem 1rem; border: none; cursor: pointer;" 
                    pButton 
                    label="Confirm">
                </button>
                </div>
            </div>
    </div>
    </p-dialog>
  </ng-container>
   -->

<!-- <ng-container *ngIf="editableTo != undefined">
    <p-dialog 
      header="Edit To" 
      [(visible)]="isToDialogVisible" 
      [modal]="true" 
      [closable]="true" 
      [style]="{ width: '700px' }" 
      [resizable]="false">
      <div class="row p-4">
        <div class="custom-dialog-header">
          <h3>Edit To</h3>
        </div>
        <div class="container-fluid">
          <div class="row mb-3">
            <div class="col-md-6">
              <div class="form-group">
                <label for="toCompany" class="pt-2 pb-2">Company:</label>
                <input type="text" class="form-control" id="toCompany" [(ngModel)]="editableTo.COMPANY" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="toPhone" class="pt-2 pb-2">Phone:</label>
                <input type="text" class="form-control" id="toPhone" [(ngModel)]="editableTo.PHONE" />
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6">
              <div class="form-group">
                <label for="toAddressLine1" class="pt-2 pb-2">Address Line 1:</label>
                <input type="text" class="form-control" id="toAddressLine1" [(ngModel)]="editableTo.ADDRESS_LINE1" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="toAddressLine2" class="pt-2 pb-2">Address Line 2:</label>
                <input type="text" class="form-control" id="toAddressLine2" [(ngModel)]="editableTo.ADDRESS_LINE2" />
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6">
              <div class="form-group">
                <label for="toCity" class="pt-2 pb-2">City:</label>
                <input type="text" class="form-control" id="toCity" [(ngModel)]="editableTo.CITY" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="toState" class="pt-2 pb-2">State:</label>
                <input type="text" class="form-control" id="toState" [(ngModel)]="editableTo.STATE" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="toZipcode" class="pt-2 pb-2">Zip Code:</label>
                <input type="text" class="form-control" id="toZipcode" [(ngModel)]="editableTo.ZIPCODE" />
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6">
              <div class="form-group">
                <label for="toCountry" class="pt-2 pb-2">Country:</label>
                <input type="text" class="form-control" id="toCountry" [(ngModel)]="editableTo.COUNTRY" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="toEmail" class="pt-2 pb-2">Email:</label>
                <input type="email" class="form-control" id="toEmail" [(ngModel)]="editableTo.EMAIL" />
              </div>
            </div>
          </div>
            <div class="row mt-2 text-center p-2" style="border-top:1px solid #ddd;">
            <div class="col mt-2">
              <button style="padding: 0.5rem 1rem; cursor: pointer;" type="button" class="btn btn-outline-secondary" (click)="cancelToChanges()">Cancel</button>
            </div>
            <div class="col mt-2">
              <button 
                (click)="saveToChanges()" 
                class="p-button-text" 
                style="background: #4ea732; padding: 0.5rem 1rem; border: none; cursor: pointer;" 
                pButton 
                label="Confirm">
              </button>
            </div>
          </div>
        </div>
      </div>
    </p-dialog>
  </ng-container>
   -->
   <app-warning-popup></app-warning-popup>
   <app-confirm-popup></app-confirm-popup>
   <p-dialog showHeader="false" class="notificationDialog" [(visible)]="shipLabelConfirmPopUpVisible" [modal]="true" [style]="{ width: '30%'}">
    
    <div class="dialog-header">
                                <div class="grid m-0">
                                    <div class="col-10 py-0">
                                        <h4 class="mb-0">Mail Notification</h4>
                                    </div>
                                    <div class="col-2 py-0 text-right">
                                        <img src="../../../../assets/images/close-icon.png" class="dialog-close" (click)="shipLabelConfirmPopUpVisible = false" />
                                    </div>
                                </div>
                            </div>
    
    <span class="p-text-secondary block mb-0" style="padding-left:20px;padding-top:10px;padding-bottom:10px;">Confirm with your order selection</span>
    
    <div class="flex align-items-center gap-3 mb-2" style="padding-left:20px;padding-right:20px">
        <label for="email" class="w-6rem">Email</label>
        <input pInputText name = "shipLableEmail" [(ngModel)] = "shipLabelConfirmFieldsData.email" class="flex-auto" autocomplete="off" />
    </div>
    <div class="flex align-items-center gap-3 mb-2" style="padding-left:20px;padding-right:20px">
        <label class="w-6rem">Message</label>
        <textarea maxlength="200" id="shiplabelReason" rows="4" style="height: 177px !important;" class="special-txtbox"
            pInputTextarea name = "shipLableMessage" [(ngModel)]="shipLabelConfirmFieldsData.message"></textarea>
    </div>
        <div class="flex justify-content-between flex-wrap" style="border-top: 1px solid #E0E0E0;  display: flex; justify-content: space-between; align-items: center;padding: 10px;padding-top: 15px;padding-left:20px !important;padding-right:20px !important">
        <div class="flex">
            <button (click)="shipLabelConfirmPopUpVisible = false"  class="frieghtCancelBtn">Cancel
            </button>
        </div>
        <div class="flex"></div>
        <div class="flex">
            <button (click)="shipLableOrderConfirm()" class="frieghtReviewBtn">Send
            </button>
        </div>
    </div>
    
</p-dialog>