import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShipRequestToShipNowService {
  private rowData: any;
  private shipData : any;
  private fromURL : any;

  setData(data: any) {
    this.rowData = data;
  }

  getData() {
    return this.rowData;
  }

  clearData() {
    this.rowData = null;
  }

  setShipFromData(data: any) {
    this.shipData = data;
  }

  getShipFromData() {
    return this.shipData;
  }

  setFromURLData(data: any) {
    this.fromURL = data;
  }

  getFromURLData() {
    return this.fromURL;
  }

}