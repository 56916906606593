
<!-- 
<p-toast 
  position="bottom-center" 
  key="confirm" 
  [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column align-items-start" style="flex: 1">
      <div class="flex align-items-center gap-2">
        
        <i class="fas fa-exclamation-triangle text-warning" style="font-size: 1.5rem;"></i>
        <span class="font-bold text-900">
          Warning
        </span>
      </div>
      <div class="font-medium text-lg my-3 text-900">
        {{ message.summary }}
      </div>
      <div class="font-medium text-lg my-3 text-900">
        {{ message.detail }}
      </div>
      <p-button size="small" label="ok" (click)="clearWarnings()" />
    </div>
  </ng-template>
</p-toast> -->


  <p-dialog
  showHeader="false"
    [(visible)]="isPopupVisible"
    modal="modal"
    [baseZIndex]="5000"
    (onHide)="clearWarnings()">

    <div class="dialog-header">
      <div class="grid m-0">
          <div class="col-10 py-0">
              <h4 class="mb-0">Warning</h4>
          </div>
          <div class="col-2 py-0 text-right">
              <img src="../../../../assets/images/close-icon.png" class="dialog-close" (click)="isPopupVisible = false" />
          </div>
      </div>
  </div>
    <div class="warningbtn flex flex-column align-items-start">
      <div class="flex align-items-center gap-2">
        <!-- Content goes here -->
      </div>
      <div class="flex justify-content-center font-medium text-lg  mt-4 m-3">
        <i class="pi pi-info-circle text-lg mt-1 mr-2 p-confirm-dialog-icon warnicon"></i>
        <div class="mt-0">{{ message }}</div>
      </div>
      <hr />
      <p-button label="OK"  class="custom-button btnbg" (click)="clearWarnings()" />
    </div>
  </p-dialog>

  




