
import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ERPSHIPService } from '../erpship.service';
import { RegisterCompanyService } from '../register-company.service';
import { UserDataService } from '../services/user-data.service';
import { MessageService } from 'primeng/api';
import { WarningPopupComponent } from '../popup/warning-popup/warning-popup.component';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private router: Router, private xfservice: ERPSHIPService,private regcomservise:RegisterCompanyService,private userContext : UserDataService,private messageService:MessageService) { }
  @ViewChild(WarningPopupComponent) warningPopup: WarningPopupComponent;
  loginForm: any;
  submitted = false;
  openRegisterFormDialog=false;
  newcompany:any={};
  isValid:boolean = true;
  showPassword: boolean = false;

  ngOnInit() {
    this.loginForm = new FormGroup({
      'login': new FormControl('', Validators.required),
      'password': new FormControl('', Validators.required)
    });
  }


  onSubmit() {
    this.submitted = true;
    this.xfservice
      .processLogin(this.loginForm.value)
      .subscribe((response) => {
        console.log(response);
        // response.headers.keys().map((key: any) => console.log(`${key}: ${response.headers.get(key)}`));
        // console.log('JWT : ' + response.headers.get('x-token'))
        window.sessionStorage.setItem('X-Token', response.headers.get('x-token'))

        const dtoken: any = JSON.stringify(JSON.parse(window.atob(response.headers.get('x-token').split('.')[1])))
        let date = new Date();
        date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = 'role' + "=" + dtoken + "; " + expires + "; path=/";
        this.router.navigate(['home/DashBoard'])
      }, error => {
        //console.log("Error is : " + JSON.stringify(error));
        this.isValid = false;

      });
  }
payload={login: "", password: ""}

  processlogin() {
    this.submitted = true;
    this.xfservice
      .processLogin(this.payload)
      .subscribe((response) => {
        // response.headers.keys().map((key: any) => console.log(`${key}: ${response.headers.get(key)}`));
        // console.log('JWT : ' + response.headers.get('x-token'))
        window.sessionStorage.setItem('X-Token', response.headers.get('x-token'))
        this.userContext.setEncryptedCookie('usercontext',response, 1);
        const dtoken: any = JSON.stringify(JSON.parse(window.atob(response.headers.get('x-token').split('.')[1])))
        let date = new Date();
        date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = 'role' + "=" + dtoken + "; " + expires + "; path=/";
        this.router.navigate(['home/DashBoards'])
      }, error => {
        //console.log("Error is : " + JSON.stringify(error));
        this.isValid = false;
        if(error.error.status === false)
        {
          this.warningPopup.showWarning('Failed to login.Please check username and password');
        }

      });
  }



  openRegister():void{
    this.openRegisterFormDialog=true
  }
  saveCompany(){   
    this.regcomservise.saveCompany(this.newcompany).subscribe(data=>alert("Company Created"), error=>{alert(error)});
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
}
