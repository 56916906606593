import { Component, OnInit, ViewChild } from '@angular/core';
import { ERPSHIPService } from '../erpship.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { WarningPopupComponent } from '../popup/warning-popup/warning-popup.component';
@Component({
  selector: 'app-carrier-special-service-config',
  templateUrl: './carrier-special-service-config.component.html',
  styleUrls: ['./carrier-special-service-config.component.scss'],
  providers: [MessageService],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class CarrierSpecialServiceConfigComponent implements OnInit {
  @ViewChild(WarningPopupComponent) warningPopup: WarningPopupComponent;
  saveButtonTitle: any = 'save'

  newFieldName: any = '';
  newFieldDialog_flag: boolean = false;

  specialServiceDialog_flag: boolean = false;
  carrierList: any[] = [];
  currentSelectedCarrier: any = '';
  currentSelectedSpecialServiceLevel: any = '';
  currentSpecialServiceName: any = '';
  currentSpecialServiceTitle:any='';
  currentSSname: any = '';
  displaytabsplservice = false
  displayFieldPropertiesFlag = false
  field: any = {}
  isActive=false

  specialServiceList: any[] = []

  specialServiceData: any = {
    Shipment: [],
    Package: [],

  }
  displaytabrearrange=false


  currentSelectedSSList: any[] = [];

  constructor(private xfservice: ERPSHIPService, private messageService: MessageService, private route: ActivatedRoute, private router: Router,) {
    this.xfservice.processAuth().subscribe((data) => { }, error => {
      this.router.navigate(['']);
    });
  }
  ngOnInit() {
    this.xfservice.getAllCarriers().subscribe((data: any) => {
      this.carrierList = data;
      this.xfservice.getSpecialServiceTemplate().subscribe((ssdata: any) => {
        this.specialServiceList = ssdata;
        console.log('List is : ' + JSON.stringify(this.specialServiceList))
      })
    })
  }
  openNewSpecialService() {

    this.saveButtonTitle = 'save'
    this.specialServiceData = {
      Shipment: [],
      Package: [],

    }
    this.specialServiceDialog_flag = true;
  }

  processCarrierChange() { }

  processSpecialServiceLevelChange() {
    // this.xfservice.getSpecialServiceTemplate(this.currentSelectedCarrier).subscribe(data=>{
    //   this.specialServiceData.Shipment=data.Shipment
    //   this.specialServiceData.Package=data.Package
    //   this.specialServiceData.id=data.id
    //   alert(JSON.stringify(this.specialServiceData))
    // })
  }
  //Add New Special Service
  addSpecialService() {
    this.specialServiceData[this.currentSelectedSpecialServiceLevel].push({
      'title': this.currentSpecialServiceTitle,
      'SSName': this.currentSpecialServiceName,
      'IsVisible': true,
      'isActive' :this.isActive,
      'editable':true,
      'fields': []
    })
    this.currentSpecialServiceName = '';
    this.currentSpecialServiceTitle='';
    console.log(JSON.stringify(this.specialServiceData))
  }

  //New Field Under Special Service 
  openNewFieldDialog(ssname: any) {
    this.currentSSname = ssname;
    this.newFieldDialog_flag = true;
  }
  //Add New Field Under Particular Special Service
  addNewFieldFor() {
    var ssName = this.specialServiceData[this.currentSelectedSpecialServiceLevel].filter((it: any) => it.SSName == this.currentSSname)
    if (ssName.length > 0) {
      ssName[0].fields.push({ 'title': this.newFieldName, 'fieldName': this.newFieldName, 'fieldType': 'Textbox', 'datatype': 'text' })
      this.newFieldName = '';
      this.newFieldDialog_flag = false;
    }

  }

  // SaveSpecialService
  SaveSpecialService() {
    if (this.field.source!="") {
      var sourcedata = this.field.source;
      sourcedata = sourcedata.replace('[', '').replace(']', '')
      this.field.source = [];
      var sourcearray = sourcedata.split(',');
      sourcearray.forEach((it: any) => {
        this.field.source.push(JSON.parse(it));
      })
    }

    this.xfservice.saveSpecialServiceTemplate(this.currentSelectedCarrier, this.specialServiceData).subscribe((data: any) => {
      this.warningPopup.showWarning('Special Services are Successfully saved');
      this.specialServiceDialog_flag = false;
    })
  }

  SaveTotalSpecialService(){
    this.xfservice.saveSpecialServiceTemplate(this.currentSelectedCarrier, this.specialServiceData).subscribe((data: any) => {
      this.warningPopup.showWarning('Special Services are Successfully saved');
      this.specialServiceDialog_flag = false;
    })
  }

  //Edit Special Service
  editSpecialService(ssdata: any) {
    this.saveButtonTitle = 'Update'
    this.currentSelectedCarrier = ssdata.carrier;
    this.specialServiceData = ssdata.details;
    this.specialServiceDialog_flag = true;
  }

  // Open Field Properties
  openFieldProperties(data: any) {
    this.displayFieldPropertiesFlag = true
    this.field = data
    if(this.field.source!=""){
       this.field.source = JSON.stringify(this.field.source)
    }
   
  }
  // Update Fiel Properties
  updateFieldProperties(data: any) {
    
    if (this.field.source) {
      var sourcedata = this.field.source;
      sourcedata = sourcedata.replace('[', '').replace(']', '')
      this.field.source = [];
      var sourcearray = sourcedata.split(',');
      sourcearray.forEach((it: any) => {
        this.field.source.push(JSON.parse(it));
      })
    }
    this.xfservice.saveSpecialServiceTemplate(this.currentSelectedCarrier, this.specialServiceData).subscribe((data: any) => {
      this.warningPopup.showWarning('Special Services are Successfully saved');
      this.displayFieldPropertiesFlag = false
    })
  }
  arrangeFields(){
   this.displaytabrearrange=true

  }
}
