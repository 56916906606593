import { Component , Injectable } from '@angular/core';
import { Observable, retry, Subject } from 'rxjs';
import { BehaviorSubject,forkJoin  } from 'rxjs';
import { ERPSHIPService } from '../erpship.service';
import { MessageService } from 'primeng/api';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { ShipNowService } from '../services/ship-now/ship-now.service';
export class Message {
  constructor(public author: string, public content: any, public type: string) {}
}
@Injectable({ providedIn: 'root' })
export class CopilotchatbotService {
    shiprequest: any = {};
    base64Image: string = '';
    showImage: boolean = false;
    imagecode: string = '';
    LocationMasterData: any;
    Original_LocationMasterData: any;
    shipment: any = {};
    FreightShopResult: any[] = [];
    currentSelectedLocationid: any = {};
    activeBoxes: number[] = [];
    private imageUrlSubject = new BehaviorSubject<string>(''); 
    public imageUrl$ = this.imageUrlSubject.asObservable();

    private imageshowsub = new BehaviorSubject<boolean>(false);
    public imageshow = this.imageshowsub.asObservable();
    
    private showratecard = new BehaviorSubject<any>([]);
    public showrate = this.showratecard.asObservable();
    messages: Message[] = []

  constructor(
    private xfservice: ERPSHIPService,public messageService: MessageService,private http: HttpClient,
    private sanitizer: DomSanitizer,private datePipe: DatePipe,private ShipNowService: ShipNowService
  ) {}
  conversation = new Subject<Message[]>();
  image = new Subject<any>();
  checkspc: Boolean;
  messageMap:any = {
    "Hi": "Hello",
    "Who are you?": "My name is Test Sat Bot",
    "What is your role?": "Just guide for the user",
    "defaultmsg": "I can't understand your text. Can you please repeat"
  }
  
  setRates(showrate: any[]): void{
    this.showratecard.next(showrate);
  }

  handleMissingTransitDays(data: any[]): any[] {
    return data.map(item => {
      if (!item.transitDay) {
        item.transitDay = '1'; // Set default value for missing transitDay
      }
      return item;
    });
  }

  formatDateTime(data: any[]): any[]  {
    return data.map(item => {
      //if (!item.expectedDeliveryDate) {
        item.expectedDeliveryDate = this.datePipe.transform(item?.expectedDeliveryDate, 'yyyy-MM-dd HH:mm:ss') || ''; // Set default value for missing transitDay
      
      //}
      //return item;
    });
    
  }
 
  /////////////////////////////Paccurate Changes//////////////////////////////////////
  toggleBox(id: number): void {
    const boxElems = document.querySelectorAll(`[data-volume-index="${id}"]`);
    boxElems.forEach((elem) => {
      elem.classList.toggle('shape-hidden');
    });
  }

  incomingitems:  any = [];
  totitemsandboxes = [];
  getpaccurateinfo(delitems: any[]): Observable<void> {
    this.incomingitems =[];
    // Example of returning an Observable
    return new Observable((observer) => {
      // Simulate async call
      setTimeout(() => {
        
        interface Dimensions {
          x: number;
          y: number;
          z: number;
        }
        
        interface ItemSet {
          refId: number;
          color: string;
          weight: number;
          name: string;
          dimensions: Dimensions;
          quantity: number;
        }
        
        interface Config {
          layFlat: boolean;
          interlock: boolean;
          corners: boolean;
          itemSets: ItemSet[];
          usableSpace: number;
          boxTypeSets: string[];
          eye: Dimensions;
          packOrigin: Dimensions;
          zone: number;
          random: boolean;
          n: number;
          randomMaxDimension: number;
          randomMaxWeight: number;
          seed: boolean;
          imgSize: number;
          template: string;
          coordOrder: number[];
          cohortPacking: boolean;
          cohortMax: number;
          allowableOverhang: number;
          placementStyle: string;
        }
        
        
        delitems.forEach((item, index) => { 
          let i = index;
          let dimname = '';
          let dimvalue = {};
          let color = '';
          // if(item.Dimension == "Small Box" || item.Dimension == "Box A")
          // {
          //   //dimname = "smaller box"
          //   dimname = item.Description
          //   dimvalue = {x: 1, y: 2, z: 1}
          //   color = "darkorange"
          // }
          // if(item.Dimension == "Medium Box" || item.Dimension == "Box B")
          // {
          //     //dimname = "medium box"
          //     dimname = item.Description
          //     dimvalue = {x: 4, y: 4, z: 4}
          //     color = "green"
          // }
          // if(item.Dimension == "Large Box" || item.Dimension == "Box C")
          // {
          //     //dimname = "larger box"
          //     dimname = item.Description
          //     dimvalue = {x: 10, y: 10, z: 10}
          //     color = "indigo"
          // }

          if(item.Dimension == "Small Box" || item.Dimension == "Box A" || item.Dimension == "10X10X10" || item.Dimension == "50X21X142" || item.Dimension == "50x21x142")
            {
              dimname = item.Description
              dimvalue = {x: 1, y: 2, z: 1}
              color = "darkorange"
            }
            else if(item.Dimension == "Medium Box" || item.Dimension == "Box B" || item.Dimension == "15X10X15" || item.Dimension == "52X22X145" || item.Dimension == "52x22x145")
            {
                dimname = item.Description
                dimvalue = {x: 4, y: 4, z: 4}
                color = "green"
            }
            else if(item.Dimension == "Large Box" || item.Dimension == "Box C" || item.Dimension == "40X30X40" || item.Dimension == "48X20X145" || item.Dimension == "48x20x145")
            {
                dimname = item.Description
                dimvalue = {x: 10, y: 10, z: 10}
                color = "indigo"
            }
            else{
              dimname = item.Description
              dimvalue = {x: 1, y: 2, z: 1}
              color = "darkorange"
            }
  
          
            this.incomingitems.push({
              refId: i,
              color: color,
              weight: Number(item.UnitWeight),
              name: dimname,
              dimensions:  dimvalue,
              quantity : Number(item.Quantity),
            });
           // Calculate total quantity from incoming items
          let totalQuantity = this.incomingitems.reduce((total, currentItem) => {
              return currentItem.quantity;  // Accumulate the quantity
          }, 0);

          // Determine the starting point for the next set of numbers
          let currentMax = this.totitemsandboxes.length > 0 ? this.totitemsandboxes[this.totitemsandboxes.length - 1] : 0;

          // Push values from currentMax + 1 to currentMax + totalQuantity into totitemsandboxes
          for (let i = currentMax + 1; i <= currentMax + totalQuantity; i++) {
              this.totitemsandboxes.push(i);
          }

          // Update the currentMax after pushing the first set of numbers
          currentMax = this.totitemsandboxes[this.totitemsandboxes.length - 1];
            });
        // After the loop, continue with delitems.length
        let currentMax = this.totitemsandboxes.length > 0 ? this.totitemsandboxes[this.totitemsandboxes.length - 1] : 0;

        // Push values from currentMax + 1 to currentMax + delitems.length
        for (let i = currentMax + 1; i <= currentMax + delitems.length + 2; i++) {
            this.totitemsandboxes.push(i);
        }
        const config: Config = {
          layFlat: false,
          interlock: false,
          corners: true,
          // itemSets: [
          //   {
          //     refId: 0,
          //     color: "darkorange",
          //     weight: 1,
          //     name: "smaller box",
          //     dimensions: { x: 1, y: 2, z: 1 },
          //     quantity: 15,
          //   },
          //   {
          //     refId: 1,
          //     color: "indigo",
          //     weight: 10,
          //     name: "larger box",
          //     dimensions: { x: 6, y: 9, z: 3 },
          //     quantity: 5,
          //   },
          // ],
          itemSets: this.incomingitems,
          usableSpace: 0.5,
          boxTypeSets: ["usps", "fedex"],
          eye: { x: 1, y: 1, z: 1 },
          packOrigin: { x: 0, y: 0, z: 0 },
          zone: 0,
          random: false,
          n: 5,
          randomMaxDimension: 30,
          randomMaxWeight: 30,
          seed: false,
          imgSize: 400,
          template: "demo.tmpl",
          coordOrder: [0, 1, 2],
          cohortPacking: false,
          cohortMax: 2,
          allowableOverhang: -1,
          placementStyle: "default",
        };
        
        //console.log("ListofItems: " + JSON.stringify(config));
        
        const hideBoxes = (): void => {
          const parents = document.querySelectorAll<SVGSVGElement>('svg');
          parents.forEach((svg) => {
            svg.childNodes.forEach((element) => {
              if ((element as Element).tagName === "polygon") {
                (element as Element).classList.add('shape-hidden');
              }
            });
          });
        };
       
        interface BoxItem {
          id: string;
          index: number;
          color: string;
        }
        
        interface Sku {
          refId: number;
          name: string;
          weight: number;
          dimensions: number[];
          boxItems: BoxItem[];
        }
        
        const parsedItems = (arr: any[]): Sku[] => {
          const skus: { [key: string]: Sku } = {};
          arr.forEach((element) => {
            element = element.item;
            const id = element.name || element.refId;
            if (typeof skus[id] === 'undefined') {
              skus[id] = {
                refId: element.refId,
                name: element.name,
                weight: element.weight,
                dimensions: element.dimensions ? [element.dimensions.x, element.dimensions.y, element.dimensions.z] : [1, 1, 1],
                boxItems: [{ id: element.uniqueId, index: element.index, color: element.color }],
              };
            } else {
              skus[id].boxItems.push({ id: element.uniqueId, index: element.index, color: element.color });
            }
          });
          return Object.keys(skus).map((key) => skus[key]);
        };
        
        const keyItems = (box: Sku, index: number): string => {
          return `
            <tr>
              <td>
                <ul style="list-style-type:none; margin:0; padding:0;" class="legend">
                  ${box.boxItems
                    .map((item) => `<li data-box-index="${index}" data-volume-index="${item.index}" style="width:20px; height:20px; margin:0 5px 5px 0; float:left; background-color:${item.color}"></li>`)
                    .join('')}
                </ul>
              </td>
              <td>${box.name || box.refId}</td>
              <td>${box.dimensions.join('x')}</td>
              <td>${box.weight}</td>
              <td>${box.boxItems.length}</td>
            </tr>
          `;
        };
        
        // const generateMarkup = (svg: string, boxes: any): HTMLElement => {
        //   const parsed = parsedItems(boxes.box.items);
        //   const boxId = boxes.box.id;
        //   const layout = document.createElement('div');
        //   const svgWrap = document.createElement('div');
        //   const itemKey = document.createElement('table');
        //   itemKey.innerHTML = `
        //     <tr>
        //       <th>item</th>
        //       <th>name/id</th>
        //       <th>dims</th>
        //       <th>weight</th>
        //       <th>qty</th>
        //     </tr>
        //     ${parsed.map((item) => keyItems(item, boxId)).join('')}
        //   `;
        //   svgWrap.innerHTML = svg;
        //   layout.appendChild(svgWrap);
        //   layout.appendChild(itemKey);
        //   return layout;
        // };

        const generateMarkup = (svg: string, boxes: any, boxLength, currentIndex): HTMLElement => {
          const parsed = parsedItems(boxes.box.items);
          const boxId = boxes.box.id;
        
          // Create the main layout container
          const layout = document.createElement('div');
          layout.style.display = 'flex';
          layout.style.flexDirection = 'column';
          layout.style.alignItems = 'flex-start';
          layout.style.marginBottom = '40px';  // Add some space below each section
        
          // Create a wrapper for the svg and table
          const boxWrapper = document.createElement('div');
          boxWrapper.style.display = 'flex';
          boxWrapper.style.justifyContent = 'space-between';  // Align SVG and table side by side
          boxWrapper.style.width = '100%';
          boxWrapper.style.alignItems = 'center'; // Ensure the SVG and table align at the top
          boxWrapper.style.position = 'relative';
        
          // Create the SVG wrapper
          const svgWrap = document.createElement('div');
          svgWrap.style.flex = '1';  // Allow the SVG to take up part of the space
          svgWrap.innerHTML = svg;

          // Create the Index Number wrapper
          const boxNumWrap = document.createElement('div');
          boxNumWrap.innerHTML = currentIndex + 1;
          boxNumWrap.classList.add('pack-num');
        
          // Create the table for item details
          const itemKey = document.createElement('table');
          itemKey.style.flex = '1';  // Allow the table to take up the remaining space
          itemKey.style.borderCollapse = 'collapse';
          itemKey.style.width = '100%';
          itemKey.style.height = 'auto'; // Ensure table height is based on content
          itemKey.style.margin = '0';  // Prevent margins
          
        
          // Create table headers and rows
          itemKey.innerHTML = `
            <thead>
              <tr>
                <th>Item</th>
                <th>Item Desc</th>
                <th>Dims</th>
                <th>Weight</th>
                <th>Qty</th>
              </tr>
            </thead>
            <tbody>
              ${parsed.map((item) => keyItems(item, boxId)).join('')}
            </tbody>
          `;
        
          // Add both elements (SVG and table) to the wrapper
          boxWrapper.appendChild(svgWrap);
          boxWrapper.appendChild(boxNumWrap);
          boxWrapper.appendChild(itemKey);
        
          // Add the box wrapper to the layout
          layout.appendChild(boxWrapper);
        
          // Add a horizontal line (separator) between SVG images
          if (currentIndex !== boxLength) {
            const separator = document.createElement('hr');
            separator.style.width = '100%';
            separator.style.margin = '10px 0'; // Control spacing around the line
            separator.style.border = '1px solid #003366';  // Customize the color of the line if necessary
            layout.appendChild(separator);  // Add the separator between the sections
          }
        
          return layout;
        };
        
        
        
        
        const activateBox = (boxId: string, itemId: string, toggle: boolean): void => {
          const elems = document.querySelectorAll<SVGElement>(`figure[data-box-index="${boxId}"] polygon[data-volume-index="${itemId}"]`);
          const parent = document.querySelector<HTMLElement>(`figure[data-box-index="${boxId}"]`);
          if (toggle) {
            parent?.classList.add('x-ray');
            elems.forEach((item) => item.classList.add('active'));
          } else {
            parent?.classList.remove('x-ray');
            elems.forEach((item) => item.classList.remove('active'));
          }
        };
        
        const addLegendListeners = (): void => {
          document.querySelectorAll('ul.legend li').forEach((element) => {
            element.addEventListener('mouseenter', (e) => {
              const box = (e.target as HTMLElement).getAttribute('data-box-index');
              const item = (e.target as HTMLElement).getAttribute('data-volume-index');
              if (box && item) activateBox(box, item, true);
            });
            element.addEventListener('mouseleave', (e) => {
              const box = (e.target as HTMLElement).getAttribute('data-box-index');
              const item = (e.target as HTMLElement).getAttribute('data-volume-index');
              if (box && item) activateBox(box, item, false);
            });
          });
        };
        
     
        const writeLegend = (json: any): void => {
          const packData = json;
          const index = this.messages.length - 1;
          const target = document.querySelector<HTMLElement>(`#pack-list-${index}`);
          const svgs = packData.svgs;
          const boxes = packData.boxes;
          boxes.forEach((box: any, index: number) => {
            target?.appendChild(generateMarkup(svgs[index], box, boxes.length - 1, index));
          });
          try{
            target.classList.add("svg-viewer");
          }catch{}
          
    
          
          hideBoxes();
    
          
          addLegendListeners();
          
        };
        
          const request = new XMLHttpRequest();
          const method = 'POST';
          const url = 'https://api.paccurate.io/';
          const packObj = config;
          request.open(method, url, true);
          request.setRequestHeader('Authorization', 'apikey 6UZlPsHAqp43MMUUU4Zg83yCuiJHTvbz');
          request.setRequestHeader('Content-Type', 'application/json');
          request.onreadystatechange = () => {
            if (request.readyState === XMLHttpRequest.DONE) {
              const status = request.status;
              if (status === 0 || (status >= 200 && status < 400)) {
                try {
                  const packResponse = JSON.parse(request.responseText);
                  
                  writeLegend(packResponse);
                  //alert("Before");
                 // for (let i = 1; i < this.activeBoxes.length-1; i++) {
                    //const boxId = this.activeBoxes[i];

                    const botMessage = new Message('bot', this.getsetpbystepboxinfo(),'svg-content');
                    // setTimeout(()=>{
                    //   this.conversation.next([botMessage]);
                    // }, 300);


                    

                    //alert("After");

                    // setTimeout(() => {
                    //   this.toggleBox(1);
                    // },  1000); 

                    // setTimeout(() => {
                    //   this.toggleBox(2);
                    // },  2500); 

                    // setTimeout(() => {
                    //   this.toggleBox(3);
                    // },  4000); 

                    // setTimeout(() => {
                    //   this.toggleBox(4);
                    // },  6500); 

                    // setTimeout(() => {
                    //   this.toggleBox(5);
                    // },  8000); 

                    // setTimeout(() => {
                    //   this.toggleBox(6);
                    // },  9500); 
                  //}
                } catch (e) {
                  alert(e);
                  console.error(e);
                }
              } else {
                console.log(status);
              }
            }
          };
          request.send(JSON.stringify(packObj));
         
        // document.addEventListener('DOMContentLoaded', function () {});
        // observer.next();
        // observer.complete();
      }, 2000);
      
    });
  }

  getsetpbystepboxinfo(){
    //const newItems = [1, 2, 3, 4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];  
    
    // newItems.forEach(item => {
    //   this.activeBoxes.push(item);
    // });
    this.totitemsandboxes.forEach(item => {
      this.activeBoxes.push(item);
    });
    for (let i = 1; i < this.activeBoxes.length; i++) {
      setTimeout(() => {
        this.toggleBox(i);
      }, i * 300); 
    }
 
 
  }

  private cubiscanUrl = 'http://10.1.100.100';

    // Method to trigger the measurement
    triggerMeasurement(): Observable<any> {
    const url = `${this.cubiscanUrl}/csMeasure`; // Replace with the actual endpoint route
    return this.http.get(url);
    }
  ////////////////////////////End Paccurate Changes///////////////////////////////////

  getBotAnswer(msg: string) {
    const msgwords: string[] = msg.split(" ");

    if(msg.includes('png')){
    
    }else if(msg.includes('RATE') || msg.includes('Rate') || msg.includes('rate')){
      
      if(msg.includes(' ')){
        this.checkspc = true;
      }

      if(this.checkspc){
        const words: string[] = msg.split(" ");
        const userMessage = new Message('user', msg,'text');
        setTimeout(()=>{
          this.conversation.next([userMessage]);
        }, 200);

        
        //this.messages.push({ author: 'user', content: custommsg ,type:'custom-text'});


        this.processFreightShop(words[1]);
      }else{
        const botMessage = new Message('bot', this.getShipReqByDocNo(msg,'RATE'),'text');
        setTimeout(()=>{
          this.conversation.next([botMessage]);
        }, 2000);
      }

    }
    else if (msgwords[0] == "SHIP" || msgwords[0] == "ship" || msgwords[0] == "Ship"){
      if(msg.includes(' ')){
        this.checkspc = true;
      }

      if(this.checkspc){
        const words: string[] = msg.split(" ");
        const userMessage = new Message('user', msg,'text');
        setTimeout(()=>{
          this.conversation.next([userMessage]);
        }, 200);

        
        this.getShipReqByDocNo(words[1],'SHIP');
        
      }else{
        const botMessage = new Message('bot', this.getShipReqByDocNo(msg,'SHIP'),'text');
        setTimeout(()=>{
          this.conversation.next([botMessage]);
        }, 2000);
      }


     }
    else if(msgwords[0] == 'PACKSHIP' || msgwords[0] == 'Pack&Ship' || msgwords[0] == 'PackShip' || msgwords[0] == 'PackAndShip' ){
      if(msg.includes(' ')){
        this.checkspc = true;
      }

      if(this.checkspc){
        const words: string[] = msg.split(" ");
        const userMessage = new Message('user', msg,'text');
        setTimeout(()=>{
          this.conversation.next([userMessage]);
        }, 200);

       
        setTimeout(()=>{
          const botMessage = new Message('bot', '','svg-content');
          this.conversation.next([botMessage]);
        }, 1000);
        
        const abc: any[]=[];
        setTimeout(()=>{
          //this.getpaccurateinfo(abc).subscribe(() => {});
          this.getShipReqByDocNo(words[1],'PACKSHIP');
        },2000);
        
      }else{
        const botMessage = new Message('bot', 'Invalid Entry!','text');
        setTimeout(()=>{
          this.conversation.next([botMessage]);
        }, 2000);
      }
    }
    else if(msgwords[0] == "PACK" || msgwords[0] == "Pack"){
      if(msg.includes(' ')){
        this.checkspc = true;
      }

      if(this.checkspc){
        const words: string[] = msg.split(" ");
        const userMessage = new Message('user', msg,'text');
        setTimeout(()=>{
          this.conversation.next([userMessage]);
        }, 200);
        setTimeout(()=>{
          const botMessage = new Message('bot', '','svg-content');
          this.conversation.next([botMessage]);
        }, 1000);
        
        const abc: any[]=[];
        setTimeout(()=>{
          //this.getpaccurateinfo(abc).subscribe(() => {});
          this.getShipReqByDocNo(words[1],'PACK');
        },2000);
        
      }else{
        const botMessage = new Message('bot', 'Invalid Entry!','text');
        setTimeout(()=>{
          this.conversation.next([botMessage]);
        }, 2000);
      }
    }
    else{
      
      const userMessage = new Message('user', msg,'text');
      setTimeout(()=>{
        this.conversation.next([userMessage]);
      }, 200);
      
      if(msg == 'Hello' || msg == 'hello' || msg == 'Hi' || msg == 'hi'){
        const botMessage = new Message('bot', 'Welcome to ShipperCopilot','text');
        setTimeout(()=>{
          this.conversation.next([botMessage]);
        }, 2000);
      }else{
        const botMessage = new Message('bot', 'Invalid Entry!','text');
        setTimeout(()=>{
          this.conversation.next([botMessage]);
        }, 2000);
      }

      
    }
    
    
  }


  getBotMessage(question: string){
    let answer = this.messageMap[question];
    return answer || this.messageMap['defaultmsg'];
    
  }

  
  idforHU : any;
  getShipReqByDocNo(docno: string,type: string) {
    let messagereturn: string = '';
    this.xfservice.getShipRequest(docno).subscribe(data => {
      let error = "";       
      try {
        if (data.length === 0) {            
          error = "Invalid Doc Number";            
          messagereturn = error;

          
          if(type == "PACK"){
            let custommsg: any = "ShipperCopilot can perform cartonization and autopack for delivery number "+ docno +", but I am unable to proceed as I could not find the associated order.";
          const usercustomMessage = new Message('user', custommsg,'custom-text');
          setTimeout(()=>{
            this.conversation.next([usercustomMessage]);
          }, 200);
          }
          else if(type == "PACKSHIP"){
            let custommsg: any = "I am unable to perform the pack and ship process for delivery number "+ docno +".";
          const usercustomMessage = new Message('user', custommsg,'custom-text');
          setTimeout(()=>{
            this.conversation.next([usercustomMessage]);
          }, 200);
          }
          else{
            let custommsg: any = "I am unable to process the shipment for delivery number "+ docno +", as it appears to be an invalid number.";
          const usercustomMessage = new Message('user', custommsg,'custom-text');
          setTimeout(()=>{
            this.conversation.next([usercustomMessage]);
          }, 200);
          }
          // const botMessage = new Message('bot', messagereturn, 'text');
          // setTimeout(() => {
          //   this.conversation.next([botMessage]);
          // }, 2000);
        } else {
          this.shiprequest = data?.[0]?.details;
          this.idforHU =  data?.[0]?.id;
          console.log(this.shiprequest);

          // Extract ItemsInfo from Packages or InternationalDetails
          const incomingItemsInfo = data?.[0]?.details.Items || [];

          if(type == "PACK"){
            let custommsg: any = "Delivery "+ docno +" with "+ incomingItemsInfo.length +" items has been auto-packed by ShipperCopilot for enhanced optimization.";
            const usercustomMessage = new Message('user', custommsg,'custom-text');
            setTimeout(()=>{
              this.conversation.next([usercustomMessage]);
            }, 200);

            setTimeout(()=>{
              const botMessage = new Message('bot', '','svg-content');
              this.conversation.next([botMessage]);
            }, 1000);
            this.getpaccurateinfo(incomingItemsInfo).subscribe(paccurresponse => {});
            
          }else if(type == "PACKSHIP"){
            
            let custommsg: any = "Pack and ship completed for Delivery  "+ docno +" by ShipperCopilot.";
            const usercustomMessage = new Message('user', custommsg,'custom-text');
            setTimeout(()=>{
              this.conversation.next([usercustomMessage]);
            }, 200);
            setTimeout(()=>{
              const botMessage = new Message('bot', '','svg-content');
              this.conversation.next([botMessage]);
            }, 1000);
            this.getpaccurateinfo(incomingItemsInfo).subscribe(paccurresponse => {});
            incomingItemsInfo.forEach(item => {
                this.products.push({
                  product: item.ProductNo,
                  totalQuantity: parseInt(item.Quantity),
                  balanceQuantity: 0,
                  partialQuantity: parseInt(item.Quantity), // Initialize partial quantity
                  deducted: false, // Add any other necessary fields
                  Quantity : item.Quantity,
                  UnitWeight : item.UnitWeight
                });
            });
            this.packAllItems(this.shiprequest,'SHIP');
          }
          else{

            let custommsg: any = "Delivery "+ docno +" has been processed and shipped by ShipperCopilot";
        const usercustomMessage = new Message('user', custommsg,'custom-text');
        setTimeout(()=>{
          this.conversation.next([usercustomMessage]);
        }, 200);

            incomingItemsInfo.forEach(item => {
              // const existingProduct = this.products.find(p => p.product === item.ProductNo);
              // if (existingProduct) {
              //   // If the product exists, update its balance quantity
              //   existingProduct.balanceQuantity += parseInt(item.Balance, 10);
              // } else {
                // If it doesn't exist, add it to products
                this.products.push({
                  product: item.ProductNo,
                  totalQuantity: parseInt(item.Quantity),
                  balanceQuantity: 0,
                  partialQuantity: parseInt(item.Quantity), // Initialize partial quantity
                  deducted: false, // Add any other necessary fields
                  Quantity : item.Quantity,
                  UnitWeight : item.UnitWeight
                });
            });
            this.packAllItems(this.shiprequest,'SHIP');
          }
          
       
          
        }
      } catch (e) {
        messagereturn = "Invalid Doc Number";    
        const userMessage = new Message('bot', messagereturn, 'text');
        this.conversation.next([userMessage]);
      }
    });
    return messagereturn;
  }
  products : any = [];
  packedItems : any = [];
  messagereturn: string = '';
  packAllItems(shipmentData,type): void {
   
    // Prepare valid products by updating partialQuantity to balanceQuantity
    if(type === "RATE")
    {
      const validProducts = this.products
      .filter(product => 
        product.product &&
        product.totalQuantity > 0 &&
        product.balanceQuantity >= 0 &&
        product.partialQuantity >= 0
      )
      .map(product => {
        // Update partialQuantity to balanceQuantity for payload
        return {
          ...product,
          partialQuantity: product.balanceQuantity,
        };
      });
      const commonUnit:any = 'kg'; // You can choose 'kg' or 'lbs' as the common unit
      const conversionFactor = 2.20462; // 1 kg = 2.20462 lbs
    
      // Convert weight to the common unit and calculate the total weight
      const totalWeight = this.products
        .filter(product => product.product && product.totalQuantity > 0)
        .reduce((sum, product) => {
          const unitWeight = parseFloat(product.UnitWeight || 0);
    
          // Convert to common unit (e.g., kg) if needed
          if (product.WeightUnits && product.WeightUnits.toLowerCase() === 'lbs') {
            // Convert lbs to kg if the common unit is kg
            return commonUnit === 'kg' ? sum + (unitWeight / conversionFactor) : sum + unitWeight;
          } else {
            // Convert kg to lbs if the common unit is lbs
            return commonUnit === 'lbs' ? sum + (unitWeight * conversionFactor) : sum + unitWeight;
          }
        }, 0);
    
      // Get dimension units from the first valid product, or default if not present
      const dimensionUnits = this.products.length > 0 ? this.products[0].UnitWeight : '';
    
      // Prepare the payload
      const payload = {
        ItemsInfo: this.products.map(product => ({
          ...product,
          partialQuantity: product.balanceQuantity,
        })),
        SpecialServices: {},
        Weightunits: totalWeight.toFixed(2), // Set the total weight, rounded to 2 decimal places
        DimensionUnits: dimensionUnits, // Use dimension units from the first product
        id: this.idforHU,
        IsInternal: true,
      };

    this.ShipNowService.packAllItems(payload).subscribe((response) => {
      console.log(response);
      let res = JSON.parse(response)
      if (res && res.ItemsInfo) {
        // Mapping ItemsInfo from response to package structure
        const newPackages = res.ItemsInfo.map(item => ({
          ItemsInfo: [{
            ItemNo: '',  // Update with your logic
            ProductNo: item.product,
            Description: item.Description || '',  // Map Description if available
            IsDG: false,
            UnitCost: '',  // Map UnitCost if needed
            UnitWeight: item.UnitWeight,
            Dimension: item.Dimension || '10X10X10',  // Map Dimension if available
            HTSCode: '',
            ECCN: '',
            UN: '',
            Class: '50',
            NMFC: '',
            Category: '',
            IsSerial: null,
            IsBatch: null,
            IsStackable: null,
            IsActive: true,
            LocationId: '',
            id: 1,
            Sno: 1,
            Quantity: item.totalQuantity,
            Partial: item.partialQuantity,
            Balance: item.balanceQuantity
          }],
          SpecialServices: {},
          Weightunits: item.UnitWeight || '',
          DimensionUnits: res.DimensionUnits,
          Sno: 1,
          HU: res.HU,  // Example HU, map as needed
          Weight: totalWeight.toString(),
          Dimension: item.Dimension || '',  // Add dimension from response if available
          PackageSpecialServiceTyeps: []
        }));
    
        // Copying current shipment data
        console.log(shipmentData);
        // Append the new packages to the existing Packages
        shipmentData.Packages = [...shipmentData.Packages, ...newPackages];
    
        // Ensure Items (products) stay the same unless specifically updated
        delete shipmentData.Items;

        // Assign the updated shipmentData back to this.shipment
        this.shipment = shipmentData;
    
        console.log('Updated Shipment Data:', this.shipment);
           // Call BookShipment as before
        this.makeAPIcallFeight(this.shipment)
  
       console.log(this.shipment);
      }
      return;
      // Show success toast
    //  this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Items packed successfully!', life: 3000 });
    }, (error) => {
      // Show error toast
    });
    }
    else{
      const validProducts = this.products
      .filter(product => 
        product.product &&
        product.totalQuantity > 0 &&
        product.balanceQuantity >= 0 &&
        product.partialQuantity >= 0
      )
      .map(product => {
        // Update partialQuantity to balanceQuantity for payload
        return {
          ...product,
          partialQuantity: product.balanceQuantity,
        };
      });
      const commonUnit:any = 'kg'; // You can choose 'kg' or 'lbs' as the common unit
      const conversionFactor = 2.20462; // 1 kg = 2.20462 lbs
    
      // Convert weight to the common unit and calculate the total weight
      const totalWeight = this.products
        .filter(product => product.product && product.totalQuantity > 0)
        .reduce((sum, product) => {
          const unitWeight = parseFloat(product.UnitWeight || 0);
    
          // Convert to common unit (e.g., kg) if needed
          if (product.WeightUnits && product.WeightUnits.toLowerCase() === 'lbs') {
            // Convert lbs to kg if the common unit is kg
            return commonUnit === 'kg' ? sum + (unitWeight / conversionFactor) : sum + unitWeight;
          } else {
            // Convert kg to lbs if the common unit is lbs
            return commonUnit === 'lbs' ? sum + (unitWeight * conversionFactor) : sum + unitWeight;
          }
        }, 0);
    
      // Get dimension units from the first valid product, or default if not present
      const dimensionUnits = this.products.length > 0 ? this.products[0].UnitWeight : '';
    
      // Prepare the payload
      const payload = {
        ItemsInfo: this.products.map(product => ({
          ...product,
          partialQuantity: product.balanceQuantity,
        })),
        SpecialServices: {},
        Weightunits: totalWeight.toFixed(2), // Set the total weight, rounded to 2 decimal places
        DimensionUnits: dimensionUnits, // Use dimension units from the first product
        id: this.idforHU,
        IsInternal: true,
      };

    this.ShipNowService.packAllItems(payload).subscribe((response) => {
      console.log(response);
      let res = JSON.parse(response)
      if (res && res.ItemsInfo) {
        // Mapping ItemsInfo from response to package structure
        const newPackages = res.ItemsInfo.map(item => ({
          ItemsInfo: [{
            ItemNo: '',  // Update with your logic
            ProductNo: item.product,
            Description: item.Description || '',  // Map Description if available
            IsDG: false,
            UnitCost: '',  // Map UnitCost if needed
            UnitWeight: item.UnitWeight || '',
            Dimension: item.Dimension || '',  // Map Dimension if available
            HTSCode: '',
            ECCN: '',
            UN: '',
            Class: '50',
            NMFC: '',
            Category: '',
            IsSerial: null,
            IsBatch: null,
            IsStackable: null,
            IsActive: true,
            LocationId: '',
            id: 1,
            Sno: 1,
            Quantity: item.totalQuantity,
            Partial: item.partialQuantity,
            Balance: item.balanceQuantity
          }],
          SpecialServices: {},
          Weightunits: item.UnitWeight || '',
          DimensionUnits: res.DimensionUnits,
          Sno: 1,
          HU: res.HU,  // Example HU, map as needed
          Weight: totalWeight.toString(),
          Dimension: item.Dimension || '',  // Add dimension from response if available
          PackageSpecialServiceTyeps: []
        }));
    
        // Copying current shipment data
        console.log(shipmentData);
        // Append the new packages to the existing Packages
        shipmentData.Packages = [...shipmentData.Packages, ...newPackages];
    
        // Ensure Items (products) stay the same unless specifically updated
        delete shipmentData.Items;

        // Assign the updated shipmentData back to this.shipment
        this.shipment = shipmentData;
    
        console.log('Updated Shipment Data:', JSON.stringify(this.shipment));
           // Call BookShipment as before
           let messagereturn: string = '';
           this.xfservice.BookShipment(this.shiprequest, this.shiprequest.CarrierDetails.Carrier).subscribe(shipresponse => {
            // Existing BookShipment logic
            console.log("Shipment_Response: " + JSON.stringify(shipresponse));
            let error = "";
        const shippingDocument = shipresponse.shippingDocuments.find((doc: any) => doc.contentType === 'LABEL');
        if (shippingDocument && shippingDocument.encodedLabel) {
            this.imagecode = `data:image/png;base64,${shippingDocument.encodedLabel}`;
            const userMessage = new Message('bot', this.imagecode, 'image');
             setTimeout(()=>{
             this.conversation.next([userMessage]);
             }, 200);
             
          }
          if (shipresponse.status == "Error") {
            shipresponse.Errors.forEach((err: any) => {
                error = err;
                messagereturn = err;
                const userMessage = new Message('bot', messagereturn,'text');
            this.conversation.next([userMessage]);   
              return error;
            })
          }else if (shipresponse.status == "Success") {
            this.shiprequest = shipresponse;

            Object.keys(this.shiprequest.HeaderInfo).forEach((key) => {

                console.log(key + '   ' + this.shiprequest.HeaderInfo[key])
                this.shiprequest[key] = this.shiprequest.HeaderInfo[key]
    
                console.log(this.shiprequest[key])
              })

              Object.keys(this.shiprequest.CarrierDetails).forEach((key) => {

                console.log(key + '   ' + this.shiprequest.CarrierDetails[key])
                this.shiprequest[key] = this.shiprequest.CarrierDetails[key]
    
                console.log(this.shiprequest[key])
              })


              var manifestship = JSON.parse(JSON.stringify(this.shiprequest));
          
          this.xfservice
            .SaveShipment(manifestship)
            .subscribe((sdata) => {

              this.messageService.add({
                key: 'tc',
                severity: 'success',
                summary: 'Shipment',
                detail: 'Shipment Processed Successfully',
              });
              let successmsg = 'Shipment Processed Successfully';
              messagereturn = successmsg;
              const botMessage = new Message('bot', messagereturn,'text');
              setTimeout(()=>{
                this.conversation.next([botMessage]);
              }, 2000); 
                 

            });
          }
            
            
            
            console.log("Shipment_Response: " + JSON.stringify(shipresponse));
          });
  
       console.log(this.shipment);
      }
      return;
      // Show success toast
    //  this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Items packed successfully!', life: 3000 });
    }, (error) => {
      // Show error toast
    });
    }

  }
  

  processFreightShop(docno: string):void {

    
     
        this.xfservice.getShipRequest(docno).subscribe(docdata => {
          let error = "";      
          if(docdata.length == ''){            
            error = "Invalid Doc Number";  
            let custommsg: any = "I am unable to perform rate shopping for order "+ docno +", as I could not locate the necessary details.";
            const usercustomMessage = new Message('user', custommsg,'custom-text');
            setTimeout(()=>{
              this.conversation.next([usercustomMessage]);
            }, 200);          
            
           }else{


            let custommsg: any = "Rate shopping for Delivery "+ docno +" provided by ShipperCopilot offered optimal recommendations.";
        const usercustomMessage = new Message('user', custommsg,'custom-text');
        setTimeout(()=>{
          this.conversation.next([usercustomMessage]);
        }, 200);


            this.shipment = docdata[0].details;
            this.shiprequest = docdata?.[0]?.details;
          this.idforHU =  docdata?.[0]?.id;
          console.log(this.shiprequest);

          // Extract ItemsInfo from Packages or InternationalDetails
          const incomingItemsInfo = docdata?.[0]?.details.Items || [];
          console.log(incomingItemsInfo);
  
          // Append incoming ItemsInfo to existing products
          incomingItemsInfo.forEach(item => {
            // const existingProduct = this.products.find(p => p.product === item.ProductNo);
            // if (existingProduct) {
            //   // If the product exists, update its balance quantity
            //   existingProduct.balanceQuantity += parseInt(item.Balance, 10);
            // } else {
              // If it doesn't exist, add it to products
              this.products.push({
                product: item.ProductNo,
                totalQuantity: parseInt(item.Quantity),
                balanceQuantity: 0,
                partialQuantity: parseInt(item.Quantity), // Initialize partial quantity
                deducted: false, // Add any other necessary fields
                Quantity : item.Quantity,
                UnitWeight : item.UnitWeight
              });
          });
          this.packAllItems(this.shiprequest,'RATE');
        
            }
           });
           
  }

  makeAPIcallFeight(shipment):void
  {
    this.FreightShopResult = [];
    this.xfservice.getLocationMasterData().subscribe((data) => {
    
              
      this.LocationMasterData = data;
      
      this.Original_LocationMasterData = JSON.parse(JSON.stringify(data));
      let rowdata = this.Original_LocationMasterData.location.filter((item: any) => item.LocationId == data['xLocationId'])
      this.currentSelectedLocationid = rowdata[0]
      
      var activeCarriers = this.LocationMasterData.carriers.filter((carrierDetails: any) => carrierDetails.IsActive == true)
      
    if (activeCarriers.length > 0) {
      this.LocationMasterData.carrieraccounts = this.Original_LocationMasterData.carrieraccounts.filter((ca: any) => ca.LocationId == this.currentSelectedLocationid['LocationId'] && ca.IsActive == true)

      const activeCarrierAccounts = this.LocationMasterData.carrieraccounts.filter(
        (accDetails: any) => activeCarriers.some((carrierDetails: any) => carrierDetails.CarrierName == accDetails.CarrierName && accDetails.IsActive == true));
      
        if (activeCarrierAccounts.length > 0) {
        var carrieslist: string[] = [];
        var rateRequest: any[] = [];//JSON.parse(JSON.stringify(this.shipment))//Object.assign({},this.shipment);
        var rateResponse: Observable<any>[] = []
        //activeCarrierAccounts.forEach((acc: any, index: number) => {
          // if(acc.CarrierName == "FedEx" || acc.CarrierName == "UPS" || acc.CarrierName == "ABF" || acc.CarrierName == "RL"){

          // }
          rateRequest.push(shipment)
          
          // rateRequest[index].CarrierDetails.Carrier = acc.CarrierName;
          // rateRequest[index].CarrierDetails.UserId = acc.UserId;
          // rateRequest[index].CarrierDetails.Password = acc.Password;
          // rateRequest[index].CarrierDetails.ShippingAccount = acc.Account;
          // rateRequest[index].CarrierDetails.PaymentType = 'Sender';

          rateRequest[0].CarrierDetails.Carrier = "FedEx";
          rateRequest[0].CarrierDetails.UserId = "l70c717f3eaf284dc9af42169e93874b6e";
          rateRequest[0].CarrierDetails.Password = "7f271bf486084e8f8073945bb7e6a020";
          rateRequest[0].CarrierDetails.ShippingAccount = "740561073";
          rateRequest[0].CarrierDetails.PaymentType = 'Sender';

          //console.log("Rate_Request_" + "FedEx" + "_" + JSON.stringify(rateRequest[index]));

          //rateResponse.push(this.xfservice.processRateShop(rateRequest[index], rateRequest[index].CarrierDetails['Carrier'] + 'Rate'))
          const request = new XMLHttpRequest();
          const method = 'POST';
          const url = 'https://shipapiv2.cfapps.eu10-004.hana.ondemand.com/' + rateRequest[0].CarrierDetails['Carrier'] + 'Rate';
          const packObj = rateRequest[0];
          request.open(method, url, true);
          request.setRequestHeader('Content-Type', 'application/json');
          request.onreadystatechange = () => {
            if (request.readyState === XMLHttpRequest.DONE) {
              const status = request.status;
              if (status === 0 || (status >= 200 && status < 400)) {
                try {
                  const packResponse = JSON.parse(request.responseText);
                  rateResponse.push(packResponse);

                  rateRequest[0].CarrierDetails.Carrier = "UPS";
                  rateRequest[0].CarrierDetails.UserId = "6ljUpEbuu1OlOk7ow932lsxXHISUET0WKjTn59GzQ5MRdEbA";
                  rateRequest[0].CarrierDetails.Password = "ioZmsfcbrzlWfGh7wGMhqHL6sY4EAaKzZObullipni0cEGJGChjFmGpkcdCWQynK";
                  rateRequest[0].CarrierDetails.ShippingAccount = "B24W72";
                  rateRequest[0].CarrierDetails.PaymentType = 'Sender';

                  const upsrequest = new XMLHttpRequest();
                  const method = 'POST';
                  const url = 'https://shipapiv2.cfapps.eu10-004.hana.ondemand.com/' + 'UPSRate';
                  const upspackObj = rateRequest[0];
                  upsrequest.open(method, url, true);
                  upsrequest.setRequestHeader('Content-Type', 'application/json');
                  upsrequest.onreadystatechange = () => {
                    if (upsrequest.readyState === XMLHttpRequest.DONE) {
                      const upsstatus = upsrequest.status;
                      if (upsstatus === 0 || (upsstatus >= 200 && upsstatus < 400)) {
                        const upspackResponse = JSON.parse(upsrequest.responseText);
                        rateResponse.push(upspackResponse);
                      

                
                        let ABFdata: any[] = [];
                        ABFdata = [{"Carrier":"ABF Freight","AccountNumber":"","serviceCode":"STND","serviceName":"Standard","publishedFreight":"206.13","discountFreight":"206.13","expectedDeliveryDate":"2024-09-17","transitDays":"1 Day"},{"Carrier":"ABF Freight","AccountNumber":"","serviceCode":"GTD_NOON","serviceName":"Time Critical by noon","publishedFreight":"407.00","discountFreight":"407.00","expectedDeliveryDate":"2024-09-17","transitDays":"2024-09-17"},{"Carrier":"ABF Freight","AccountNumber":"","serviceCode":"GTD_PM","serviceName":"Time Critical by 5pm","publishedFreight":"370.00","discountFreight":"370.00","expectedDeliveryDate":"2024-09-17","transitDays":"2024-09-17"}];
                        
                        
                        // Loop through ABFdata and push each item to rateResponse
                        ABFdata.forEach((item) => {
                         rateResponse.push(item);
                        });

                        let RLData: any[] = [];
                        RLData = [{"Carrier":"RandL Freight","AccountNumber":"","serviceCode":"STD","serviceName":"Standard Service","publishedFreight":670.62,"discountFreight":670.62,"expectedDeliveryDate":"2024-09-17T00:00:00.000Z","transitDays":1,"surCharges":[{"type":"","description":"Class: 50","amount":"$3.42"},{"type":"MINIMUM","description":"Minimum Charge","amount":"$670.62"},{"type":"DISCNT","description":"R+L Discount Saves This Much","amount":"$435.90"},{"type":"DISCNF","description":"Discounted Freight Charge","amount":"$234.72"},{"type":"FUEL","description":"Fuel Surcharge","amount":"$71.12"},{"type":"NET","description":"Net Charge","amount":"$305.84"}]},{"Carrier":"RandL Freight","AccountNumber":"","serviceCode":"GSDS","serviceName":"Guaranteed Service","publishedFreight":729.22,"discountFreight":729.22,"expectedDeliveryDate":"2024-09-17T00:00:00.000Z","transitDays":1,"surCharges":[{"type":"","description":"Class: 50","amount":"$3.42"},{"type":"MINIMUM","description":"Minimum Charge","amount":"$670.62"},{"type":"DISCNT","description":"R+L Discount Saves This Much","amount":"$435.90"},{"type":"DISCNF","description":"Discounted Freight Charge","amount":"$234.72"},{"type":"FUEL","description":"Fuel Surcharge","amount":"$71.12"},{"type":"NET","description":"Net Charge","amount":"$305.84"}]}];
                        RLData.forEach((item) => {
                          rateResponse.push(item);
                         });
 
                        rateResponse.forEach(rateres => {
                                    this.FreightShopResult = this.FreightShopResult.concat(rateres);
                                    })
                                    const jsonarr: any = this.handleMissingTransitDays(this.FreightShopResult);
                                    (jsonarr || []).forEach((item: any) => {
                                      if(item.expectedDeliveryDate) {
                                        item.expectedDeliveryDate = this.datePipe.transform(item?.expectedDeliveryDate, 'yyyy-MM-dd HH:mm:ss') || '';
                                      }
                                    })
                                    const lowestDiscountFreight = jsonarr.reduce((prev, curr) => {
                                      return curr.discountFreight < prev.discountFreight ? curr : prev;
                                    }).discountFreight;
                                    const updatedjsonarr = jsonarr.map(item => ({
                                      ...item,
                                      isLowestlowestDiscountFreight: item.discountFreight === lowestDiscountFreight
                                    }));
                                    const botMessage = new Message('bot', updatedjsonarr,'json-array');
                                      setTimeout(()=>{
                                      this.conversation.next([botMessage]);
                                      }, 1000);
                        // rateRequest[0].CarrierDetails.Carrier = "ABF";
                        // rateRequest[0].CarrierDetails.UserId = "ABFESHIPJET";
                        // rateRequest[0].CarrierDetails.Password = "Legacy!@3";
                        // rateRequest[0].CarrierDetails.AccessKey = "JVG9SX85";
                        // rateRequest[0].CarrierDetails.ShippingAccount = "";
                        // rateRequest[0].CarrierDetails.PaymentType = 'Sender';
                        // const abfrequest = new XMLHttpRequest();
                        // const method = 'POST';
                        // const url = 'https://shipapiv2.cfapps.eu10-004.hana.ondemand.com/' + 'abfRate';
                        // const abfpackObj = rateRequest[0];
                        // abfrequest.open(method, url, true);
                        // abfrequest.setRequestHeader('Content-Type', 'application/json');
                        // abfrequest.onreadystatechange = () => {
                        //   if (abfrequest.readyState === XMLHttpRequest.DONE) {
                        //     const abfstatus = abfrequest.status;
                        //     if (abfstatus === 0 || (abfstatus >= 200 && abfstatus < 400)) {
                        //       const abfpackResponse = JSON.parse(abfrequest.responseText);
                        //       rateResponse.push(abfpackResponse);
                              
                        //       rateRequest[0].CarrierDetails.Carrier = "RL";
                        //       rateRequest[0].CarrierDetails.AccessKey = "ItO0YzFzIxZ1Q0MWQtZWNlZi00MDVkLTgxYzNGNiQlMzMTkTC";
                        //       rateRequest[0].CarrierDetails.PaymentType = 'Sender';


                        //       const rlrequest = new XMLHttpRequest();
                        //       const method = 'POST';
                        //       const url = 'https://shipapiv2.cfapps.eu10-004.hana.ondemand.com/' + 'RLRate';
                        //       const rlpackObj = rateRequest[0];
                        //       rlrequest.open(method, url, true);
                        //       rlrequest.setRequestHeader('Content-Type', 'application/json');
                        //       rlrequest.onreadystatechange = () => {
                        //       if (rlrequest.readyState === XMLHttpRequest.DONE) {
                        //         const rlstatus = rlrequest.status;
                        //       if (rlstatus === 0 || (rlstatus >= 200 && rlstatus < 400)) {
                        //         const rlpackResponse = JSON.parse(rlrequest.responseText);
                        //         rateResponse.push(rlpackResponse);

                        //         // rateResponse.forEach(rateres => {
                        //         // this.FreightShopResult = this.FreightShopResult.concat(rateres);
                        //         // })
                        //         // const jsonarr: Array<{}> = this.handleMissingTransitDays(this.FreightShopResult);
                        //         // const botMessage = new Message('bot', jsonarr,'json-array');
                        //         //   setTimeout(()=>{
                        //         //   this.conversation.next([botMessage]);
                        //         //   }, 1000);


                        //         rateResponse.forEach(rateres => {
                        //           this.FreightShopResult = this.FreightShopResult.concat(rateres);
                        //           })
                        //           const jsonarr: any = this.handleMissingTransitDays(this.FreightShopResult);
                        //           (jsonarr || []).forEach((item: any) => {
                        //             if(item.expectedDeliveryDate) {
                        //               item.expectedDeliveryDate = this.datePipe.transform(item?.expectedDeliveryDate, 'yyyy-MM-dd HH:mm:ss') || '';
                        //             }
                        //           })
                        //           const lowestDiscountFreight = jsonarr.reduce((prev, curr) => {
                        //             return curr.discountFreight < prev.discountFreight ? curr : prev;
                        //           }).discountFreight;
                        //           const updatedjsonarr = jsonarr.map(item => ({
                        //             ...item,
                        //             isLowestlowestDiscountFreight: item.discountFreight === lowestDiscountFreight
                        //           }));
                        //           const botMessage = new Message('bot', updatedjsonarr,'json-array');
                        //             setTimeout(()=>{
                        //             this.conversation.next([botMessage]);
                        //             }, 1000);
        
                                    

                        //           }
                        //         }
                        //       };rlrequest.send(JSON.stringify(rlpackObj));
                              
                        //     }
                        //   }
                        // };abfrequest.send(JSON.stringify(abfpackObj));






                     
                     }
                    }
                  };upsrequest.send(JSON.stringify(upspackObj));
                  
                  } catch (e) {
                  alert(e);
                  console.error(e);
                }
              } else {
                console.log(status);
              }
            }
          };
          request.send(JSON.stringify(packObj));
          
          
          
          
          
         // console.log("Rate_Response_" + acc.CarrierName + "_" + JSON.stringify(rateResponse));
       // });

        forkJoin(rateResponse).subscribe((res) => {
         
          this.FreightShopResult = [];
          rateResponse.forEach(rateres => {
            this.FreightShopResult = this.FreightShopResult.concat(rateres);
          })
          const jsonarr: Array<{}> = this.handleMissingTransitDays(this.FreightShopResult);
          const botMessage = new Message('bot', jsonarr,'json-array');
            setTimeout(()=>{
              this.conversation.next([botMessage]);
            }, 1000);
        
        }, error => {
          // alert('An error occurred:' + error);
        });
        // forkJoin(rateResponse).subscribe((res) => {
         
        //   this.FreightShopResult = [];
        //   res.forEach(rateres => {
        //     this.FreightShopResult = this.FreightShopResult.concat(rateres);
        //   })
        //   //console.log(this.handleMissingTransitDays(this.FreightShopResult));
          
        //   // this.FreightShopResult = this.FreightShopResult.map((item) => {
        //   //   return {
        //   //     ...item, // Spread the original properties
        //   //     expectedDeliveryDate: item.expectedDeliveryDate.replace('T', ' ') // Replace "T" with a space
        //   //   };
        //   // });
        //   // console.log(this.FreightShopResult);
        //   const jsonarr: Array<{}> = this.handleMissingTransitDays(this.FreightShopResult);
        //   //const finaljsonarr: Array<{}> = this.formatDateTime(jsonarr);
          
        //   const botMessage = new Message('bot', jsonarr,'json-array');
        //     setTimeout(()=>{
        //       this.conversation.next([botMessage]);
        //     }, 1000);
        
        // }, error => {
        //   // alert('An error occurred:' + error);
        // });


      }



    }
      
      
      
    });
  }
}