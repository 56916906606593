import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ERPSHIPService } from '../erpship.service';
import { ChangeDetectorRef } from '@angular/core';
import { saveAs } from 'file-saver-es';
import {
  FormGroup,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ShipRequestToShipNowService } from '../services/ship-now/shiprequesttoshipnow.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ColumnConfigService } from '../services/column-config-service';
import { MessageService } from 'primeng/api';
import { StorageService } from '../services/storage.service';
import { UserDataService } from '../services/user-data.service';
interface ModuleConfig {
  [key: string]: string[];
}
@Component({
  selector: 'app-routing-guide',
  templateUrl: './routing-guide.component.html',
  styleUrl: './routing-guide.component.scss'
})
export class RoutingGuideComponent {
  @ViewChild('ot') overlayPanel: OverlayPanel;
  first = 0;
  rows = 50;
  isDataLoaded: boolean = false;
  passingid: any = '';
  shipRequests: any[] = [];
  selectedShipRequest: any[] = [];
  items: any[] = [];
  totalRecords = 0;

  locationName: any;
  Carrier: any;
  shipFromDate: any;
  shipToDate: any;
  costCenter: any;
  user: any;
  Status: any;
  feederSystemOptions = [];
  displayedShipRequests: any[] = []; // Data to display in the table
  page: number = 0; // Current page index
  displayDialog: boolean = false;
  searchQuery: string = '';
  selectedColumns: any[] = [];
  scrollHeight = '400px';

  displayColumnPopup: boolean = false;

  columns = [
    {
      header: 'Request ID',
      fieldName: 'HeaderInfo.DocumentNumber',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Created Date',
      fieldName: 'HeaderInfo.CreatedDate',
      fieldType: 'Date',
      editable: false,
      visible: true,
      datatype: 'date',
    },
    {
      header: 'Ship Date',
      fieldName: 'HeaderInfo.ShipDate',
      fieldType: 'Date',
      editable: false,
      visible: true,
      datatype: 'date',
    },
    {
      header: 'Shipment Type',
      fieldName: 'HeaderInfo.ShipmentType',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Carrier Name',
      fieldName: 'CarrierDetails.Carrier',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Service Level',
      fieldName: 'ServiceName',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Tracking Number',
      fieldName: 'Packages[0].TrackingNumber',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Status',
      fieldName: 'HeaderInfo.Status',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    },
    {
      header: 'Ship To Company',
      fieldName: 'ShipTo.COMPANY',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Contact',
      fieldName: 'ShipTo.CONTACT',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
   
    {
      header: 'Ship To Address Line 1',
      fieldName: 'ShipTo.ADDRESS_LINE1',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To City',
      fieldName: 'ShipTo.CITY',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To State',
      fieldName: 'ShipTo.STATE',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Country',
      fieldName: 'ShipTo.COUNTRY',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Zipcode',
      fieldName: 'ShipTo.ZIPCODE',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Phone',
      fieldName: 'ShipTo.PHONE',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Ship To Email',
      fieldName: 'ShipTo.EMAIL',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Requester Name',
      fieldName: 'HeaderInfo.CreatedUser',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Connected To',
      fieldName: 'HeaderInfo.FeederSystem',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    },
    {
      header: 'Order Type',
      fieldName: 'HeaderInfo.DocumentType',
      fieldType: 'Textbox',
      editable: false,
      visible: false,
      datatype: 'string',
    }, 
    {
      header: 'Priority Level',
      fieldName: 'HeaderInfo.PriorityLevel',
      fieldType: 'Textbox',
      editable: false,
      visible: true,
      datatype: 'string',
    }
  ];
  progressDetails: any = {
    pageTitle: 'Routing Guide',
    buttonTitle: 'Create Routing Guide',
    isButtonAvl: true,
  };
  isProgressDataAvl: boolean = false;

  postColumns: any[] = [];
  mandatoryColumns = [
    'Request ID',
    'Created Date',
    'Ship Date',
    'Carrier Name',
    'Service Level',
    'Tracking Number',
    'Status',
    'Ship To Company',
    'Ship To Contact'
  ];
  finalColumns: any;
  ConfigKey='shiprequest';


  constructor(
    private router: Router,
    private xfservice: ERPSHIPService,
    private cdr: ChangeDetectorRef,
    private rowDataService: ShipRequestToShipNowService,
    private columnConfigService: ColumnConfigService,
    private messageService: MessageService,
    private storageService : StorageService,
    private userContext : UserDataService
  ) {
    let data = this.userContext.getDecryptedCookie('usercontext');
    this.userId = data.body.user_id;
    this.xfservice.processAuth().subscribe(
      (data) => {},
      (error) => {
        console.log('No token found');
        this.router.navigate(['']);
      }
    );
  }
  viewShipRequest(shipRequest) {
    this.router.navigate(['/home/shiprequestmodal', 'View-' + shipRequest.id]);
  }
  viewShipNow(shipRequest) {
    shipRequest.isFooterEnabled = false;
    this.rowDataService.setData(shipRequest.HeaderInfo.DocumentNumber);
    this.rowDataService.setShipFromData(shipRequest);
    this.router.navigate(['/home/shipnow'], {
      state: { data: JSON.stringify(shipRequest) },
    });
  }
  //Action from Table
  edit(shipRequest) {
    this.router.navigate(['/home/shiprequestmodal', shipRequest.id]);
    throw new Error('Method not implemented.');
  }
  status(shipRequest) {
    throw new Error('Method not implemented.');
  }
  copy(shipRequest) {
    this.router.navigate(['/home/shiprequestmodal', 'COPY-' + shipRequest.id]);
  }
  delete(shipRequest) {
    console.log('delete functionality ');
  }
  print(shipRequest) {
    console.log('delete functionality ');
  }

  ship(shipRequest) {
    console.log("issue",shipRequest);
    this.rowDataService.setData(shipRequest.HeaderInfo.DocumentNumber);
    this.rowDataService.setFromURLData('shiprequest');
    this.rowDataService.setShipFromData(shipRequest);
    this.router.navigate(['/home/shipnow'], {
      state: { data: JSON.stringify(shipRequest) },
    });
  }

  generateOpenItems = (shipRequest) => {
    // this.cdr.detectChanges();
    return [
      {
        label: 'View',
        command: () => {
          this.viewShipRequest(shipRequest);
        },
      },
      {
        label: 'Edit',
        command: () => {
          this.edit(shipRequest);
        },
      },
      {
        label: 'Copy',
        command: () => {
          this.copy(shipRequest);
        },
      },
      {
        label: 'Delete',
        command: () => {
          this.delete(shipRequest);
        },
      },
      {
        label: 'Print',
        command: () => {
          this.print(shipRequest);
        },
      },
    ];
  };

  generateItems = (shipRequest) => {
    // this.cdr.detectChanges();
    return [
      {
        label: 'Copy',
        command: () => {
          this.copy(shipRequest);
        },
      },
      {
        label: 'Print',
        command: () => {
          this.print(shipRequest);
        },
      },
    ];
  };
  columnConfig: any;
  userId: string;
  countVisibleTrue: number = 0;
  locationData : any;
  openRecordsCount
  cancelledRecordsCount
  shippedRecordsCount
  documentCountList :any;
  ngOnInit(): void {
    console.log(this.userId);
   // this.locationData = this.storageService.getItem('data');
    // Call the service method to post the column conf
    this.xfservice.getLocationMasterData().subscribe((data) => {
      this.progressDetails.shipmentState = data.shipmentstatuses;
      this.isProgressDataAvl = true;
      this.locationData = data;
      if(this.locationData != undefined)
        {
          this.xfservice
          .getxFormRecordsByFromName('ShipRequest')
          .subscribe((data: any) => {  
            data.sort((a, b) => b.id - a.id);
            const validData = data.filter((item: any) => {
              const details = item.details;
              return details && Object.keys(details).length > 1; // Ensures 'details' contains more than just 'id'
            });
            // Initialize counters
            let openCount = 0;
            let shippedCount = 0;
            let cancelledCount = 0;
      
            validData.forEach((item: any) => {
              const details = item.details;
      
              // Handling Status and Open/Close logic
              if (details?.HeaderInfo?.Status === 'Open') {
                details.items = this.generateOpenItems(details);
                details.isOpenStatus = true;
                openCount++; // Increment openCount if status is 'Open'
              } else if (details?.HeaderInfo?.Status === 'Shipped') {
                details.items = this.generateItems(details);
                details.isOpenStatus = false;
                shippedCount++; // Increment shippedCount if status is 'Shipped'
              } 
              else if(details?.HeaderInfo?.Status === 'Cancelled'){
                details.items = this.generateItems(details);
                details.isOpenStatus = false;
                cancelledCount++; // Increment shippedCount if
              }
              else {
                details.items = this.generateItems(details);
                details.isOpenStatus = false;
              }
      
              if (details?.Packages && details?.Packages.length > 0) {
                // Loop through all packages to find the tracking number
                const trackingNumber = details.Packages.find(pkg => pkg.TrackingNumber)?.TrackingNumber;
                details.TrackingNumber = trackingNumber || '';  // Default if no tracking number is found
              }
      
              if (details?.CarrierDetails) {
                // Assuming details contains a ServiceCode you want to match dynamically
                const serviceCodeToMatch = details?.CarrierDetails.ServiceName;
                // Find the matching service based on ServiceCode
                const matchedService = this.locationData?.carrierservices.find(service => service.ServiceCode === serviceCodeToMatch);
      
                if (matchedService) {
                  const serviceName = matchedService.ServiceName;
                  // Push the details with the matched service name
                  this.shipRequests.push({
                    ...details,
                    TrackingNumber: details?.TrackingNumber,
                    ServiceName: serviceName // push the dynamically found ServiceName
                  });
                }
        }
      });
      
            // Store the counts in variables or use them as needed
            this.totalRecords = validData.length;
            this.openRecordsCount = openCount; // Add this property to track open count
            this.shippedRecordsCount = shippedCount;
            this.cancelledRecordsCount = cancelledCount // Add this property to track shipped count
            this.documentCountList = {
              'totalRecords':  0,
              'openRecords' :  0,
              'shippedRecords' : 0,
              'cancelledRecords':  0
            }
            this.isDataLoaded = true;
          });
        }
    });
  }

  showSelectedRecords(): void {
    alert(this.selectedShipRequest.length);
  }

  openNewShipRequest(): void {
    this.router.navigate(['/home/shipRequest']);
  }

  editShipRequest(data: any): void {}

  downloadFile(): void {
    alert('DOWNLAOD STARTED');
  }

  //DOWNLOAD NESTED OBJECT S

  pivot(arr: any) {
    var mp = new Map();

    function setValue(a: any, path: any, val: any) {
      if (Object(val) !== val) {
        // primitive value
        var pathStr = path.join('.');
        var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
        a[i] = val;
      } else {
        for (var key in val) {
          setValue(a, key == '0' ? path : path.concat(key), val[key]);
        }
      }

      return a;
    }

    var result = arr.map((obj: any) => setValue([], [], obj));
    return [[...mp.keys()], ...result];
  }

  toCsv(arr: any) {
    return arr
      .map((row: any) =>
        row
          .map((val: any) => (isNaN(val) ? JSON.stringify(val) : +val))
          .join(',')
      )
      .join('\n');
  }

  iconClick(event){
    let d = document.getElementById("columnConfig");
    d.style.display = 'block';

  }

  receiveData(data:any) {
    this.finalColumns = data;
  }

  modalOpenClick() {
    this.router.navigate(['/home/routingguidemodal']);
  }
 
}
